import React, {useEffect} from "react";
import {Typography, Container, Button, FormControl,
InputLabel, Select, Chip, MenuItem, TextField} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
import { firestore } from '../../../../../config/fbConfig';

const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '100%'
    },
    question: {
        color: 'gray',
        marginTop:20,
        marginBottom: 4,
      },
      chip: {
        margin: 2,
      },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

export const ProDetails = ({ formData, setForm, navigation, pro }) => {
  const { isLicensed, services } = formData;
  const classes = useStyles();
  const typesOfServices = useSelector(state => state.services.typesOfServices)
  const titles = useSelector(state => state.services.titles)
  const dispatch = useDispatch()
  
  const [selectedServices, setServices] = React.useState([]);
  const [selectedTitles, setTitles] = React.useState([]);
  
  const disabled = (selectedTitles.length === 0) ? true : false;
  
  const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const formatServices = () => {
  formData.services = pro.services.map(service => ({
    addOn: service.addOn,
    details: service.details,
    name: service.name,
    price: service.price.includes('.') ? service.price.split('$')[1] : service.price.split('$')[1]+".00",
    reqDeposit: service.reqDeposit,
    time: service.time,
    serviceType: service.serviceType
  }))

  navigation.next()
}


const handleServiceChange = (event) => {
  let t = [] 
  setServices(event.target.value);
  formData.typesOfServices = event.target.value
  if(formData.typesOfServices.length <= 0 ){
    dispatch(
      dispatch({
        type: 'SET_TITLES',
            payload: []
      })
    )
  } else {
    formData.typesOfServices.forEach(service => {
      firestore.collection('typesOfServices').doc(service)
      .get().then((doc) => {
        t.push(doc.data().titles)
        // console.log("this is T:", t)
        dispatch({
          type: 'SET_TITLES',
              payload: t.flat()
            })
          })
    })
  }
};



const handleTitleChange = (event) => {
  setTitles(event.target.value);
  formData.title = event.target.value
};


  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Pro Details</Typography>

      <Typography variant='body2' className={classes.question}>What type(s) of professional services do you provide?</Typography>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="service">Select A Service</InputLabel>
        <Select
          labelId="service"
          id="select-service"
          name="typesOfServices"
          multiple
          value={formData.typesOfServices || selectedServices}
          onChange={(e) => {handleServiceChange(e); setForm(e);}}
          label="Select A Service"
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {typesOfServices.map(service => {
            return(
              <MenuItem key={service} value={service}>{service}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

      {titles.length <= 0 ? 
      null
      :
      <>   
      <Typography variant='body2' className={classes.question}>Professional Title:</Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="title">Select Your Title(s)</InputLabel>
        <Select
          labelId="title"
          id="select-title"
          name="title"
          multiple
          value={selectedTitles}
          onChange={(e) => {handleTitleChange(e); setForm(e);}}
          label="Select Your Title(s)"
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {titles.map(title => {
            return(
              <MenuItem key={title} value={title}>{title}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
      </>
    }

      <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={formatServices}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};