import React, {useState, useEffect} from 'react'
import {Grid,  Typography, Button, TextField, CircularProgress, Tooltip,
    Paper, InputBase, InputAdornment, FormControl, FormGroup, Modal} from '@material-ui/core';
    import { BrowserRouter as Router, Link} from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CompareArrowsRoundedIcon from '@material-ui/icons/CompareArrowsRounded';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import ProviderCard from './ProviderCard'
import {urlPaths} from './data'
import { makeStyles } from "@material-ui/core/styles";
import PlacesAutoComplete, {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { firestore } from '../../../config/fbConfig';
import LoginModal from '../../modals/LoginModal'
import ComingSoonModal from '../../modals/ComingSoonModal';
// import { ServiceSelect } from '../../dashboard/home/scheduleAppointment/01ServiceSelect';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_KEY);
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 12, 
      border:'1px solid #e0e0e0',
      position: "relative",
      marginTop: "20px",
      minHeight: 510,
      boxShadow: "5px 5px 7px -1px rgba(0,0,0,0.26)",
      textAlign: "left",
      },
      titleWrapper:{
        borderRadius: '8px 8px 0 0',
        background: theme.palette.primary.main,
        height: "50px"
      },
      title: {
        color:"white",
        padding: "8px 0 0 20px"
      },
      question: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))"
      },
    input: {
      flex: 1,
      display: 'flex',
      height: 50,
      alignItems: 'center',
      width: '100%',
      paddingTop:3,

    },
    iconButton: {
    //   color: theme.palette.primary.main,
    //   fontSize: "40px",
        margin: 'auto 10px',
      "&:hover":{
        cursor: 'pointer',
        opacity: '80%',
      },
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {
      fontWeight: "bold",
      letterSpacing: "2px",
      marginTop: "1rem",
      height: "48px",
      width: 160,
    },
  }));




const ServiceSearch = () => {
    const classes = useStyles();
    const path = window.location.pathname.split('/')
    const pathName = path[2]
    const data = urlPaths.filter((service) => service.name === pathName)
    const backgroundImg = data[0].imgUrl
    const titles = data[0].titles
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [coordinates, setCoordinates] = useState([{lat:null, lng:null}]);
    const [address, setAddress] = useState("");
    const [unformattedAddress, setUnformattedAddress] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [services,setServices] = useState([])
    const [showLoginModal, setShowLoginModal] = useState(false)
    const disabled = (value === '' || address === '') ? true : false






    useEffect(() => {
        window.scrollTo(0, 0)
        const idx = () => setIndex(i => i + 1);
        const id = setInterval(idx, 3000);

        if((services.length > 0 && services[0].typeOfService.split(' ')[0].toLowerCase() !== pathName.split('-')[0].toLowerCase()) || services.length === 0){
            firestore.collection('services').doc('allServices').get().then((doc) => {
                if (doc.exists) {
                    setServices(doc.data().listOfServices.filter(service => service.typeOfService.split(' ')[0].toLowerCase() === pathName.split('-')[0].toLowerCase()))
                } else {
                    // doc.data() will be undefined in this case
                    // console.log("No such document!");
                }
            }).catch((error) => {
                // console.log("Error getting document:", error);
            });
        
        }

        if(coordinates !== {lat:null, lng:null} && loading===true){
            // console.log('1')
            const {lat, lng} = coordinates
            Geocode.fromLatLng(`${lat}`, `${lng}`).then(
              (response) => {
                const address = response.results[0].formatted_address;
                const addressArr = address.split(', ')
                const state = addressArr.slice(-2, -1).join('').split(' ').slice(0,1).join()
                if(addressArr.length === 4){
                  addressArr.splice(2, 1, state)
                  setUnformattedAddress(addressArr)
                }
                else if(addressArr.length === 5){
                  addressArr.splice(3, 1, state)
                  setUnformattedAddress(addressArr)
                }
                else if(addressArr.length === 6){
                  addressArr.splice(4, 1, state)
                  setUnformattedAddress(addressArr)
                }
              },
              (error) => {
                console.error(error);
              }
              );
            }

            if(unformattedAddress.length !== 0 && loading===true){
                // console.log('2')
                let formatted = unformattedAddress.join(', ')
                setAddress(formatted)
              }
              
              if(address !== '' && loading===true){
                // console.log('3')
                setLoading(null)
              }


        console.log('services:', services)

        return () => clearInterval(id);


      }, [coordinates, address, anchorEl, loading, unformattedAddress, services, pathName])

      const setLocation = () => {
        if(navigator.geolocation){
            console.log('geolocation:', navigator.geolocation)
          setLoading(true)

          var options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          };
          
          function success(pos) {
            var crd = pos.coords;

            setCoordinates({lat: crd.latitude, lng: crd.longitude})
          
            console.log('Your current position is:');
            console.log(`Latitude : ${crd.latitude}`);
            console.log(`Longitude: ${crd.longitude}`);
            console.log(`More or less ${crd.accuracy} meters.`);
          }
          
          function error(err) {
            console.warn(`ERROR(${err.code}): ${err.message}`);
          }

          navigator.geolocation.getCurrentPosition(success, error, options);


        //   navigator.geolocation.getCurrentPosition(position => {
        //       console.log('we are here:', position)
        //     setCoordinates({lat: position.coords.latitude, lng: position.coords.longitude})
        //   })
        }
    
      }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = async (value) => {
        setAddress(value);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };    
    
    const handleSelect = async (value) => {
        const result = await geocodeByAddress(value)
        const latLng = await getLatLng(result[0])
    
        setAddress(value);
        setCoordinates(latLng)
    };

    const handleServiceChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleCloseModal = () => {
        setShowLoginModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowLoginModal(true)
    }



    return (
        <Grid item xs={12} container style={{background:"white"}}>
            <Grid item xs={12} container direction="column" alignItems="center" justify="space-evenly" style={{paddingTop: '30px', paddingBottom: '30px', backgroundImage: `url(${backgroundImg})`, backgroundSize:'cover', backgroundPosition:'center', height: "500px"}}>
                <Grid item xs={11} sm={10} md={6} lg={5} xl={4} container direction="column" alignItems="center" justify="center" style={{padding: '20px 10px', textAlign: 'center', background: "white",  borderRadius: '18px'}}>
                    <Typography variant="h5" style={{fontWeight: "bold"}}>Find a  
                        <span id='Title'> {titles[index % titles.length]}
                         </span> in your area! 
                    </Typography>
                    <Typography varient="subtitle1">Locate & Book service providers in your local community.</Typography>
                    <br />
                    <form style={{width: 'inherit', paddingLeft: '20px', paddingRight: '20px'}} onSubmit={(e) => handleSubmit(e)}>
                        {/* <TextField
                        variant="outlined"
                        id="outlined-basic" 
                        label="Search a service?"
                        style={{paddingBottom: '20px'}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                          }}
                        ></TextField> */}
                        <Autocomplete
                            value={value}
                            onChange={handleServiceChange}
                            options={services.map(option => option.name)}
                                renderInput={(params) => 
                                <TextField
                                {...params}
                                label="Search s Service..."
                                name="service"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>,
                                    ...params.InputProps, type:'search'}}
                                />}
                                //getOptionLabel = {(option) => option.name}
                                required
                                fullWidth
                                style={{paddingBottom: '20px'}}
                            />

                       {loading === true ? 
                        <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
                        <CircularProgress style={{color: '#413e68'}}/>
                        </div> 
                        :
                        <PlacesAutoComplete
                            required
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            style={{width:'inherit'}}
                        >
                            {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                            }) => (
                            <div style={{width:'inherit', border:'1px solid #c4c4c4', borderRadius:4}}>
                                <Paper component="form" className={classes.input} style={{boxShadow:'none'}}>
                                
                                    <Tooltip title='Use my location'>
                                        <LocationOnOutlinedIcon className={classes.iconButton} onClick={setLocation}/>
                                    </Tooltip>
                                    <InputBase aria-controls="simple-menu" onClick={handleClick}
                                    {...getInputProps({
                                    placeholder: 'Enter Your Address...',
                                    })}
                                    className={classes.input}
                                />
                                </Paper>

                                <div id="simple-menu" anchorEl={anchorEl} style={{width:'98%', zIndex:1, marginLeft:10,maxHeight:150, overflowY:'scroll', boxShadow: '4px 4px 6px -1px rgba(0,0,0,0.20)', borderRadius:4}}>

                                    {loading && <Typography>Loading...</Typography>}
                                    {suggestions.map((suggestion,) => {
                                    const style = suggestion.active
                                        ? {
                                            backgroundColor: "#413e68",
                                            color: "white",
                                            cursor: "pointer",
                                        }
                                        : { backgroundColor: "white", borderBottom:'1px solid #e6e6e6'};

                                    return (
                                        <Typography onClick={handleClose} key={suggestion.description} {...getSuggestionItemProps(suggestion, { style })}>
                                        {suggestion.description}
                                        </Typography>
                                    );
                                    })}

                                </div>

                            </div>
                            )}
                        </PlacesAutoComplete>
                        }
                        <Button disabled={disabled} variant="contained" type="submit" color="primary" fullWidth="false" style={{marginTop:20}}>
                            Let's go!
                        </Button>
                    </form>
                </Grid>
            </Grid>

            <Grid item xs={12} container alignItems="center"  style={{paddingTop: '30px', paddingBottom: '30px', background: 'white', justifyContent:"center"}}>
                <Grid item xs={12} container alignItems="center" justify="center">  
                    <Grid item container xs={11} sm={10} md={8} lg={7} style={{padding:15, height:300, borderRadius:'12px', backgroundImage:"url('http://cen.acs.org/content/dam/cen/98/web/3/WEB/20200313lnp1-spray.jpg')", backgroundSize: 'cover', backgroundPosition: '0% 24%', marginBottom:100, display:'flex', alignItems:'center'}}>
                        <Typography variant='h4' style={{fontWeight:'bold', width:'inherit', color:'white', textShadow:'4px 4px 4px #0000009c'}}>Our COVID 19 Health & Safety Guidelines</Typography>
                        <Typography style={{width:'inherit', marginTop:'-30px'}}>We’ve provided guidelines so clients can book with peace of mind.</Typography>
                        <Button variant="contained" color='primary' component={Link} to='/ip/covid-19' style={{width:200, height: 40}}>Learn More</Button>
                    </Grid>
                
                </Grid>
                
                <Grid item xs={12} md={8} container alignItems="center" direction="column" style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    <Typography variant="h4" style={{ textAlign: 'center', fontWeight: "bold"}}> 
                        How Cuti App Works for You
                    </Typography>
                    <Typography variant="body1">
                        Find a wide variety of Beauty & Wellness service providers with specialized skills anytime wherever you are.
                    </Typography>
                    <Grid item container direction="row" xs={12} alignItems="center" justify="space-evenly" style={{paddingTop: '70px'}}>
                        <Grid item container direction="column" alignItems="center" justify="center" style={{backgroundColor:'lightgray', height:240, width:240, margin:20, borderRadius:'50%'}}>
                            <SearchIcon style={{fontSize:"80px", color:'#413e68'}}/> <p style={{width:'inherit', textAlign:'center', padding:'0px 10px'}}>Browse service providers in your area</p>
                        </Grid>
                        <Grid item container direction="column" alignItems="center" justify="center" style={{backgroundColor:'lightgray', height:240, width:240, margin:20, borderRadius:'50%'}}>
                            <CompareArrowsRoundedIcon style={{fontSize:"80px", color:'#413e68'}}/><p style={{width:'inherit', textAlign:'center', padding:'0px 10px'}}>Compare prices & ratings</p>
                        </Grid>
                        <Grid item container direction="column" alignItems="center" justify="center" style={{backgroundColor:'lightgray', height:240, width:240, margin:20, borderRadius:'50%'}}>
                            <ScheduleRoundedIcon style={{fontSize:"80px", color:'#413e68'}}/><p style={{width:'inherit', textAlign:'center', padding:'0px 10px'}}>Schedule your appointment</p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} alignItems="center"  style={{paddingTop: '50px', paddingBottom: '50px', position:'relative'}}>
               <div style={{position:'absolute', top:'50%', left: '50%', transform:'translate(-50%, -50%)'}}>
                -------------------------
               </div>
            </Grid>

            {/* <Grid item container alignItems="center" direction="column" justify="space-evenly" style={{paddingTop: '30px', paddingBottom: '50px'}}>
                <Grid item style={{paddingBottom: '40px'}}>
                    <Typography variant="h4" style={{ textAlign: 'center', fontWeight: "bold"}}>
                        Highlighted Service Providers
                    </Typography>
                 </Grid>
                 <Grid item container direction="row" alignItems="center" justify="space-evenly" >
                    <ProviderCard />
                    <ProviderCard />
                    <ProviderCard />
                </Grid>
            </Grid> */}

            <Grid item container direction="column" alignItems="center" justify="center" xs={12} style={{paddingBottom: '50px'}}>
                <Grid item style={{paddingBottom: '20px'}}>
                    <Typography variant="h5" style={{ textAlign: 'center', fontWeight: "bold"}}>Find a provider in your area! </Typography>
                </Grid>
                <Grid item container direction='row' alignItems="center" justify="center">
                    <FormControl>
                        <FormGroup row="true">
                            <TextField 
                            variant="outlined"
                            id="outlined-basic" 
                            label="Search a service?"
                            style={{paddingRight: '20px'}}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }}
                            ></TextField>
                            <TextField 
                            variant="outlined"
                            id="outlined-basic" 
                            label="Where?"
                            style={{paddingRight: '20px'}}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    <LocationOnOutlinedIcon />
                                </InputAdornment>
                            }}
                            ></TextField>
                            <Button variant="contained" color="primary">Let's go!</Button>
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>

            <Modal
                open={showLoginModal}
                onClose={handleCloseModal}>
                <LoginModal/>
            </Modal>
        </Grid>
    )
}

export default ServiceSearch