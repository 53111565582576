import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles} from '@material-ui/core/styles';
import {Grid, Typography, Button, CssBaseline, Popper, ClickAwayListener, MenuItem, Menu,
  MenuList, Grow, Paper, Hidden, IconButton, Drawer, List, ListItem, Divider, Avatar} from '@material-ui/core';
import LoggedInMenu from '../dashboard/Menu'
import {AccountCircle, ExitToApp, KeyboardArrowDown, KeyboardArrowUp, Notifications, NotificationsActive, Share, WorkOutline} from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import SignedOutLinks from '../auth/SignedOutLinks';
import SignedInLinks from '../auth/SignedInLinks';
import {Link, NavLink} from 'react-router-dom'
import {useHistory} from 'react-router'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import MenuIcon from '@material-ui/icons/Menu';
import ComingSoonModal from '../modals/ComingSoonModal';
import { firestore, auth } from '../../config/fbConfig';
import firebase from 'firebase';
import Notification from '../modals/Notification';
import BusinessCardModal from '../modals/BusinessCardModal';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    navBtn: {
      padding: "0",
      height: "100%",
      borderRadius: '0'
    },
    menuButton:{
      marginLeft: 10,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      color: 'white',
    },
    drawerOpen: {
      background: '#413e68',
      width: drawerWidth,
      color: 'white',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      background: '#413e68',
      color: 'white',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

  }));
  


const Navbar = (props) => {
  const history = useHistory()
  const {currentUser, userData, width} = props;
  const [modalOpen, setModalOpen] = useState(false)
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const anchorRef = React.useRef(null);
  const [notifications, setNotifications] = useState([])
  const [notifAnchorEl, setNotifAnchorEl] = React.useState(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = React.useState(null);
  const hasUnread = notifications && notifications.some(notif => notif.docData.read === false)
  const [openedImage, setOpenedImage] = useState('')
  const [bcModalOpen, setBcModalOpen] =useState(false)


  const handleOpenBizCard = (e) => {
    setOpenedImage(e.target.src)
    setBcModalOpen(!bcModalOpen)
  }

  const handleOpen = (e) => {
    setModalOpen(!modalOpen)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDrawer = () => {
    if(drawer === false){
      setDrawer(true)
    } else {
      setDrawer(false)
    }
  }

  const handleClose = (event) => {
    setSettingsAnchorEl(null);
    setNotifAnchorEl(null);
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleMarkAllRead = () => {
    if(userData.collection === 'admins'){
      notifications.filter(notif => {
        const id = notif.id
        const data = notif.docData
        if(data.read === false){
          firestore.collection('adminNotifications').doc(id).update({read: true})
        }
      })
    }else{
      notifications.filter(notif => {
        const id = notif.id
        const data = notif.docData
        if(data.read === false){
          firestore.collection(userData.collection).doc(userData.userId).collection('notifications')
          .doc(id).update({read: true})
        }else{
        }
      })
    }
  }

  const handleClickNotif = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleClickSettings = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSignOut = () => {
      handleClose()
    if(userData.collection === 'professionals'){
      if(userData.online){
        // console.log('Pro sign out')
        firestore.collection(userData.collection).doc(userData.userId).update({
          online: false
        }).then(() => {
          auth.signOut()
        }).then(() => {
          history.push('/signin')
        })
      }else{
        // console.log('Pro sign out')
        auth.signOut()
        .then(() => {
          history.push('/signin')
        })
      }
    }else if(userData.collection === 'customers'){
      // console.log('customer signed out')
      auth.signOut()
      .then(() => {
        history.push('/signin')
      })
    }else{
      // console.log('other user type signed out')
      auth.signOut()
      .then(() => {
        history.push('/signin')
      })
    }
   
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    if(userData.collection === 'admins'){
      console.log('somethings happening here')
      firestore.collection(`adminNotifications`).orderBy('createdAt', "desc").limit(100)
      .onSnapshot((snapshot) => {
          setNotifications(snapshot.docs.map(doc => 
            {return({id:doc.id, docData:doc.data()})}
            )
          )
        })
    }else{
      firestore.collection(`${userData.collection}`).doc(`${userData.userId}`).collection('notifications').orderBy('createdAt', "desc").limit(50)
      .onSnapshot((snapshot) => {
          
          setNotifications(snapshot.docs.map(doc => 
            {return({id:doc.id, docData:doc.data()})}
            )
          )


        })
    }

    prevOpen.current = open;
    console.log('notifications: ', notifications)


  }, [userData, open]);

  const logoPaddingLeft = (width <= 'lg') ? 60 : 20


    return(
        <div style={{height: 50, position:'sticky', top:0, zIndex:10, backgroundColor:'#ffffff'}}>
          <CssBaseline/>
          {currentUser && currentUser ?
            <Grid container item xs={12} justify="center" style={{background: 'white', height: 'inherit'}}>

                
                <Grid container item xs={6} style={{display:'flex', alignItems:'center', paddingLeft:logoPaddingLeft}} >
                        <Typography component={NavLink} to='/home' variant="h3" color="primary" style={{display: 'block', fontSize: "26px", textDecoration: 'none', fontFamily: 'sarina,cursive', color: '#413e68'}}>
                          <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Flogo.png?alt=media&token=c347c655-de6c-4791-a643-16e83f23d619' alt='' style={{width:90, height:30}}/>
                        </Typography> {/*  replace with logo */}
                </Grid>

                <Grid item xs={6} container style={{position:'relative', margin: 'auto 0', display:'flex', flexFlow:'row', justifyContent:'flex-end'}}>
                  {width <= 'lg' &&
                  <Button onClick={handleSignOut} style={{height:50, padding:'0px 10px', backgroundColor:'#413e68', color:'white', borderRadius:0}}>Sign Out</Button>
                  }
                  
                  {((width === 'xs' || width === 'sm' || width === 'md') &&  userData.collection==='professionals') && 
                    <img src="https://img.icons8.com/fluency-systems-regular/24/757575/qr-code--v1.png" alt='qr' style={{width:24, height:24, marginTop:2}} onClick={handleOpenBizCard}/>
                  }
                  { (width >= 'md' && hasUnread) &&
                    <div style={{border:'2px solid white', borderRadius:10, height:12, width:12, backgroundColor:'red', position:'absolute', top:3, right:55, zIndex:10}}></div> 
                  }
                  {(width === 'xs' || width === 'sm' || width === 'md') &&
                  <IconButton aria-controls="simple-menu-notifications" aria-haspopup="true" onClick={handleClickNotif} style={{width:30, height:30, margin:'0px 10px'}}>
                   {hasUnread ?
                      <NotificationsActive style={{color:'#413e68', transform: 'rotate(-0.05turn)'}}></NotificationsActive>
                      :
                      <Notifications></Notifications>
                    }
                  </IconButton>
                  }


                  

                  {((width === 'xs' || width === 'sm' || width === 'md') && userData.images && userData.collection !== 'admins') &&
                  <>
                  {userData.collection === 'professionals' && userData.online === true &&
                  <div style={{width:12, height:12, border:'2px solid white', borderRadius:10, backgroundColor:'green', position:'absolute', right:15, top: 20, zIndex:1}}></div>
                  }
                  <Avatar alt='av' src={userData.images.avatar} style={{width:30, height:30, marginRight:20}} aria-controls="simple-menu-settings" aria-haspopup="true" onClick={handleClickSettings}></Avatar>
                  </>
                  }
                
                </Grid>


                {/* <SignedInLinks userData={userData}/>  */}

            </Grid>    
          :
          <Grid container item justify="center" style={{background: 'white' }}>
            <Hidden lgUp>
              <Grid item xs={4} container justify='flex-start'>
                <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawer} className={classes.menuButton}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>

            <Hidden mdDown>
                <Grid item xs={1} > 
                  <Button ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true"
                  onClick={handleToggle} fullWidth className={classes.navBtn}>
                    Services 
                    <KeyboardArrowDown />
                  </Button>
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:10}}>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/nails'>Nails</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/hair'>Hair</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/makeup'>Makeup</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/brow-lashes'>Brow & Lashes</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/facials'>Facials</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/massage'>Massage</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/waxing'>Waxing Services</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/health-fitness'>Health & Fitness</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/home-car-care'>Home & Car Care</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/pet-care'>Pet Care</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/tattoo-piercing'>Tattoo & Piercing</MenuItem>
                            <MenuItem onClick={handleClose} component={NavLink} to='/services/other'>Other Services</MenuItem>
                            
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                </Grid>

                <Grid item xs={1} > 
                  {/* <Button component={NavLink} to='/products' fullWidth className={classes.navBtn}>Products</Button> */}
                  <Button onClick={()=>setModalOpen(true)} fullWidth className={classes.navBtn}>Products</Button>
                </Grid>

                <Grid item xs={2}></Grid>
            </Hidden>

                <Grid container item xs={4} style={{textAlign: "center"}} justify='center' direction="column" >
                    <Grid>
                        <Typography component={NavLink} to='/' variant="h3" color="primary" style={{display: 'block', fontSize: "26px", textDecoration: 'none', fontFamily: 'sarina,cursive', color: '#413e68'}}>
                          <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Flogo.png?alt=media&token=c347c655-de6c-4791-a643-16e83f23d619' alt='' style={{width:90, height:30, marginBottom:-9}}/>  
                        </Typography> {/*  replace with logo */}
                        <Hidden mdDown>
                        <Typography style={{fontSize: "10px"}}>APPOINTMENTS ON DEMAND</Typography>
                        </Hidden>
                    </Grid>
                </Grid>
            <Hidden mdDown>
                <SignedOutLinks/>  
            </Hidden>

            <Hidden lgUp>
              <Grid item xs={4}></Grid>
            </Hidden>

            </Grid>
          }

          <Hidden lgUp>
            {/* <ClickAwayListener onClickAway={()=> setDrawer(!drawer)}> */}
            <Drawer variant='temporary' open={drawer} classes={{paper: clsx({[classes.drawerOpen]: drawer,[classes.drawerClose]: !drawer,}),}}>
              <List style={{position:'relative'}}>
            <IconButton onClick={handleDrawer} style={{position:'absolute', right:0, color:'white', zIndex:1}}>
                            <ClearIcon/>
                          </IconButton>
              <ListItem button className={classes.listItem} component={NavLink} to='/' onClick={handleDrawer}>
                  <Typography className={classes.listItemIconAndText}>Home</Typography>
                </ListItem>

               <ListItem button className={classes.listItem} ref={anchorRef} onClick={handleToggle}>
                  <Typography className={classes.listItemIconAndText}>Services</Typography>
                  {!open ? <KeyboardArrowDown /> : <KeyboardArrowUp/>}
                </ListItem>
                        {open === true &&
                        <>
                          <MenuList autoFocusItem={open} anchorEl={anchorRef.current} id="menu-list-grow" onKeyDown={handleListKeyDown} style={{marginLeft:20}}>
                            <MenuItem component={NavLink} to='/services/nails' onClick={handleDrawer}>Nails</MenuItem>
                            <MenuItem component={NavLink} to='/services/hair' onClick={handleDrawer}>Hair</MenuItem>
                            <MenuItem component={NavLink} to='/services/makeup' onClick={handleDrawer}>Makeup</MenuItem>
                            <MenuItem component={NavLink} to='/services/brow-lashes' onClick={handleDrawer}>Brow & Lashes</MenuItem>
                            <MenuItem component={NavLink} to='/services/facials' onClick={handleDrawer}>Facials</MenuItem>
                            <MenuItem component={NavLink} to='/services/massage' onClick={handleDrawer}>Massage</MenuItem>
                            <MenuItem component={NavLink} to='/services/waxing' onClick={handleDrawer}>Waxing Services</MenuItem>
                            <MenuItem component={NavLink} to='/services/health-fitness' onClick={handleDrawer}>Health & Fitness</MenuItem>
                            
                          </MenuList>
                          </>
                        }
                {/* <ListItem button className={classes.listItem} component={NavLink} to='/products' onClick={handleDrawer}>
                  <Typography className={classes.listItemIconAndText}>Products</Typography>
                </ListItem> */}
                <ListItem button className={classes.listItem} onClick={() => {handleDrawer(); setModalOpen(true)}}>
                  <Typography className={classes.listItemIconAndText}>Products</Typography>
                </ListItem>

                <ListItem button className={classes.listItem} component={NavLink} to='/ip/about' onClick={handleDrawer}>
                  <Typography className={classes.listItemIconAndText}>About</Typography>
                </ListItem>

              <Divider />

                <ListItem button className={classes.listItem} component={NavLink} to='/signup' onClick={handleDrawer}>
                  <Typography className={classes.listItemIconAndText}>Sign Up</Typography>
                </ListItem>

                <ListItem button className={classes.listItem} component={NavLink} to='/signin' onClick={handleDrawer}>
                  <Typography className={classes.listItemIconAndText}>Sign In</Typography>
                </ListItem>
              </List>
            </Drawer>
            {/* </ClickAwayListener> */}
          </Hidden>


          {currentUser && currentUser ?
          <LoggedInMenu currentUser={currentUser} userData={userData}/>
          : 
          null
          }

        <ComingSoonModal modalOpen={modalOpen} handleModalOpen={handleOpen}/>

        <Menu PaperProps={{
          style: {
            maxHeight: 500,
            width: '40ch',
          },}} id="simple-menu-notifications" anchorEl={notifAnchorEl}
            keepMounted open={Boolean(notifAnchorEl)} onClose={handleClose} style={{}}>
              <Grid item xs={12} style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
              {!!notifications && notifications.length !== 0 ?
                <Button onClick={handleMarkAllRead}>Mark All as Read</Button>
                :
                <Typography style={{color:'darkgrey'}}>You have no notifications!</Typography>
              }
              </Grid>
              
              {notifications && notifications.sort((a,b) => b.docData.createdAt > a.docData.createdAt && 1 || -1).map((notif) => {
                return(
                  <Notification key={notif.id} notif={notif.docData} id={notif.id} userData={userData}/>
                  
                  )
                })
              }
        </Menu>

        <Menu id="simple-menu-settings" anchorEl={settingsAnchorEl} keepMounted open={Boolean(settingsAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {}}>
          {/* <MenuItem component={Link} to={`/settings`}>General Settings</MenuItem> */}
          {/* <MenuItem onClick={handleClose} component={Link} to='/settings/general'>Gengeral Settings</MenuItem> */}
          <MenuItem onClick={handleClose} component={Link} to='/settings/account'><AccountCircle/> My Account</MenuItem>
          
          {(userData.collection === 'professionals' && userData.accountStatus!=='onboarding') &&
            <div>
              <MenuItem onClick={handleClose} component={Link} to={`/pro/profile/${userData.url}`}><WorkOutline/> Profile</MenuItem>
              {/* <MenuItem onClick={handleClose} component={Link} to='/settings/availability'>Availability</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/settings/services'>Services</MenuItem> */}
            </div>
          }

          {/* {userData.accountStatus!=='onboarding' &&
          <MenuItem onClick={handleClose} component={Link} to='/settings/notifications'>Notifications</MenuItem>
          } 

          {userData.collection === 'professionals' &&
          <MenuItem onClick={handleClose} component={Link} to='/settings/payment-info'>Payment Info</MenuItem>
          } */}

          <MenuItem onClick={handleSignOut}><ExitToApp/> Logout</MenuItem>
        </Menu>


        
        {userData.collection==='professionals' &&
        <BusinessCardModal handleOpenBizCard={handleOpenBizCard} bcModalOpen={bcModalOpen} pro={userData}/>
        }

        </div>

       
        
    )
}

const mapState = state => ({
  currentUser: state.auth.currentUser,
  userData: state.auth.userData
})

Navbar.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default compose(withWidth(),connect(mapState, null))(Navbar);