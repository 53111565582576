import authReducer from './authReducer'
import appointmentReducer from './appointmentsReducer'
import servicesReducer from './servicesReducer'

import { combineReducers } from 'redux'
//import {firestoreReducer} from 'redux-firestore'
//import {firebaseReducer} from 'react-redux-firebase'

const rootReducer = combineReducers({
    auth: authReducer,
    appt: appointmentReducer,
    services: servicesReducer,
    //firestore: firestoreReducer,
    // firebase: firebaseReducer,
})

export default rootReducer