import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withWidth from "@material-ui/core/withWidth";
import {
  Grid,
  Typography,
  Button,
  Paper,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  IconButton,
  Snackbar,
  Modal,
  TextField,
  Menu,
  MenuItem,
  TextareaAutosize,
  Hidden,
  Switch,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import ServiceCard from "../settings/Account/ServiceCard";
import { firestore } from "../../../config/fbConfig";
import firebase from "firebase";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ClearIcon from "@material-ui/icons/Clear";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
const { ms, s, m, h, d } = require("time-convert");
var badWords = require("badwords-list");

const useStyles = makeStyles((theme) => ({
  input: {
    flex: "1 0 auto",
    paddingLeft: "10px",
  },
  icon: {
    flex: "0 1 40px",
    // color: '#413e68',
    cursor: "pointer",
  },
  inputField: {
    border: "2px solid lightgray",
    borderRadius: 20,
    height: 40,
    flex: "1 1 auto",
    paddingTop: 2,
    margin: "10px 5px 5px",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
  },
  selectedServiceDetails: {
    padding: "0px 30px",
    marginTop: 20,
  },
  addAnother: {
    width: "100%",
    textAlign: "center",
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
      fontSize: 13,
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  modalBtn: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Services(props) {
  const classes = useStyles();
  const { width, userData } = props;
  const [allServices, setAllServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [category, setCategory] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [hours, setHours] = useState("");
  const [mins, setMins] = useState("");
  const [price, setPrice] = useState("");
  const [details, setDetails] = useState("");

  const [newPrice, setNewPrice] = useState("");
  const [time, setTime] = useState([]);
  const [newHrs, setNewHours] = useState([]);
  const [newMins, setNewMins] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isAddServiceModalOpen, setAddServiceModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [addOnsClicked, setAddOnsClicked] = useState(false);
  // const [editChecked, setEditChecked] = useState((selectedService !== null && (selectedService.reqDeposit !== undefined || selectedService.reqDeposit !== null)) ? selectedService.reqDeposit : false )
  const [editChecked, setEditChecked] = useState(true);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [containsProfaninty, setContainsProfanity] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [filterBy, setFilterBy] = React.useState("");
  const [filterByy, setFilterByy] = React.useState("");
  const [saveClicked, setSaveClicked] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openErr, setOpenErr] = useState();
  const [addOnChecked, setAddOnChecked] = useState(false);
  const [depositChecked, setDepositChecked] = useState(true);
  const [pricesVary, setPricesVary] = useState(false);
  const [virtual, setVirtual] = useState(false);
  const [zoomLink, setZoomLink] = useState("");
  const [reasonInvalid, setReasonInvalid] = useState("");

  const severity = ["error", "success"];
  const switchLabel = checked === true ? "Yes" : "No";
  const switchLabelEdit = editChecked === true ? "Yes" : "No";
  const addOnSwitchLabel = addOnChecked === true ? "Yes" : "No";
  const depositSwitchLabel = depositChecked === true ? "Yes" : "No";

  // const reqDepositEdit = (selectedService !== null && selectedService.reqDeposit !== undefined) ? selectedService.reqDeposit : false

  const disabled = selectedService === null ? true : false;
  const disabledAddNew =
    price === "" ||
    hours === "" ||
    mins === "" ||
    serviceName === "" ||
    containsProfaninty === true ||
    saveClicked === true ||
    reasonInvalid.length !== 0 ||
    (virtual === true && zoomLink.length === 0)
      ? true
      : false;

  const handleChange = (event) => {
    const name = event.target.value;
    setFilterBy(name);
    setFilterByy(name);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
    setOpenErr(false);
    setSaveClicked(true);
  };

  const handleAddAnotherCliked = (event, reason) => {
    setCategory("");
    setServiceName("");
    setHours("");
    setMins("");
    setPrice("");
    setDetails("");
    setPricesVary(false);
    setSaveClicked(false);
  };

  const handleSelectFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectSort = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (typeOfService) => {
    console.log(typeOfService);
    setFilteredServices(
      allServices.filter((service) => service.typeOfService === typeOfService)
    );
    setAnchorEl(null);
  };

  const sortAtoZ = () => {
    filteredServices.sort(
      (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() && 1) || -1
    );
    setAnchorEl2(null);
  };

  const sortZtoA = () => {
    filteredServices.sort(
      (a, b) => (b.name.toLowerCase() > a.name.toLowerCase() && 1) || -1
    );
    setAnchorEl2(null);
  };
  //const [typeSearch, setTypeSearch] = useState('')

  const categories = [
    "Brow & Lashes",
    "Facials & Skincare",
    "Hair",
    "Health & Wellness",
    "Home & Car Care",
    "Makeup",
    "Massage",
    "Nails",
    "Pet Care",
    "Tattoo & Piercing",
    "Waxing Services",
    "Other",
  ];

  //conditional design
  const listHgt =
    width === "xs" || width === "sm" || width === "md" ? "70%" : "85%";
  const boxHgt =
    width === "xs" || width === "sm" || width === "md" ? "40vh" : "65vh";
  const padding = width >= "md" ? "0px 10px 0px 60px" : 0;

  const numsOnly = (e) => {
    const re = /[0-9]+/g;
    const reg = /[0-9.]+/g;
    if (e.target.name !== "price") {
      if (!re.test(e.key)) {
        e.preventDefault();
      }
      if (e.target.value.length >= 2) {
        e.preventDefault();
      }
    } else {
      if (!reg.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  const array = badWords.array;

  const handleAddServiceModalOpen = () => {
    setAddServiceModalOpen(!isAddServiceModalOpen);
  };

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleModal2Open = () => {
    setIsModal2Open(!isModal2Open);
  };
  const handleModal2Close = () => {
    setIsModal2Open(!isModal2Open);
    setSelectedService(null);
  };

  const toggleAddOnChecked = () => {
    setAddOnChecked(!addOnChecked);
    setDepositChecked(false);
    setPricesVary(false);
  };

  const toggleDepositChecked = () => {
    setDepositChecked(!depositChecked);
  };

  const handleRemove = () => {
    var serviceRef = firestore
      .collection("professionals")
      .doc(`${userData.userId}`);
    serviceRef
      .update({
        services: firebase.firestore.FieldValue.arrayRemove(selectedService),
      })
      .then(
        () => {} // console.log('Successfully deleted document!')
      )
      .then(() => window.location.reload())
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const handleUpdateService = () => {
    const newTime =
      parseFloat(h.to(m)(parseFloat(newHrs))) + parseFloat(newMins);

    var serviceRef = firestore
      .collection("professionals")
      .doc(`${userData.userId}`);

    serviceRef.update({
      services: firebase.firestore.FieldValue.arrayRemove(selectedService),
    });
    serviceRef
      .update({
        services: firebase.firestore.FieldValue.arrayUnion({
          name: selectedService.name,
          serviceType: selectedService.serviceType,
          time: isNaN(newTime) ? selectedService.time : newTime,
          price: newPrice === "" ? selectedService.price : newPrice,
          details:
            details === "" && selectedService.details
              ? selectedService.details
              : details,
          reqDeposit: addOnChecked === true ? false : depositChecked,
          addOn: depositChecked === true ? false : addOnChecked,
          priceVaries: pricesVary,
          virtual: virtual,
          zoomLink: zoomLink,
        }),
      })
      //.then(() => console.log('Successfully updated document!'))
      .then(() => window.location.reload())
      .catch((error) => {
        // The document probably doesn't exist.
        // console.error("Error updating document: ", error);
        setOpenErr(true);
      });
  };

  const toggleChecked = () => {
    setChecked(!checked);
  };

  const toggleEditChecked = () => {
    setEditChecked(!editChecked);
  };

  const handleAddNewService = () => {
    const time = parseFloat(h.to(m)(parseFloat(hours))) + parseFloat(mins);

    // firestore
    //   .collection("services")
    //   .doc("allServices")
    //   .update({
    //     listOfServices: firebase.firestore.FieldValue.arrayUnion({
    //       name: serviceName,
    //       typeOfService: category,
    //     }),
    //   })
    //   .then(() => {
    firestore
      .collection("professionals")
      .doc(`${userData.userId}`)
      .update({
        services: firebase.firestore.FieldValue.arrayUnion({
          name: serviceName,
          serviceType: category,
          time: time,
          price: price,
          details: details,
          reqDeposit: checked,
          addOn: false,
          priceVaries: pricesVary,
          virtual: virtual,
          zoomLink: zoomLink,
        }),
      })
      .then(() => {
        setOpenSuccess(true);
        // window.location.reload()
      })
      .catch((error) => {
        console.log("Error updating document: ", error);
        setSaveClicked(false);
        // The document probably doesn't exist.
        // console.error("Error updating document: ", error);
        setOpenErr(true);
      });
  };

  useEffect(() => {
    var docRef = firestore.collection("services").doc("allServices");

    if (allServices.length === 0) {
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setAllServices(doc.data().listOfServices);
            setFilteredServices(doc.data().listOfServices);
            // console.log(doc.data())
          } else {
            // console.log("No such document!");
          }
        })
        .catch((error) => {
          // console.log("Error getting document:", error);
        });
    } else {
      allServices.sort(
        (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() && 1) || -1
      );
      // filteredServices.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1)
    }

    if (selectedService) {
      const t = m.to(h, m)(selectedService.time);
      setTime(t);
      setPricesVary(
        selectedService.priceVaries !== undefined
          ? selectedService.priceVaries
          : false
      );
    }

    if (serviceName !== "") {
      array.map((badWord) => {
        if (serviceName === badWord) {
          alert(
            "To help keep our community safe, profanity is absolutely prohibited. Services are checked daily by our staff. Thanks!"
          );
          setServiceName("");
        }
      });
    }

    if (zoomLink.length !== 0 && reasonInvalid === "") {
      if (zoomLink.includes("https") === false) {
        setReasonInvalid("Please include: https://");
      } else if (zoomLink.includes("zoom.us") === false) {
        setReasonInvalid("Please use a Zoom link");
      }
    } else if (
      reasonInvalid.length !== 0 &&
      zoomLink.includes("https") &&
      zoomLink.includes("zoom.us")
    ) {
      setReasonInvalid("");
    }
  }, [
    allServices,
    selectedService,
    serviceName,
    containsProfaninty,
    disabled,
    filteredServices,
    reasonInvalid,
    zoomLink,
  ]);

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      style={{ padding: "0px 10px", alignContent: "center" }}
    >
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          marginBottom: 60,
        }}
      >
        <Grid
          item
          xs={12}
          md={10}
          container
          style={{
            margin: "20px 0 0 0",
            borderRadius: 12,
            boxShadow: "5px 5px 7px -1px rgba(0,0,0,0.26)",
            marginBottom: 20,
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              height: 50,
              backgroundColor: "#413e68",
              padding: "0px 12px 0 20px",
              borderRadius: "12px 12px 0 0",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              textAlign: "left",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Services{" "}
              <Tooltip title={"Set the services that you provide."}>
                <HelpOutlineIcon
                  style={{
                    fontSize: "20px",
                    color: "white",
                    verticalAlign: "middle",
                  }}
                />
              </Tooltip>
            </Typography>
            <Hidden mdUp>
              <AddCircleOutlineIcon
                className={classes.modalBtn}
                style={{ color: "white" }}
                onClick={handleAddServiceModalOpen}
              />
            </Hidden>
          </Grid>

          <Grid
            item
            md={5}
            container
            style={{
              padding: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography>My Services</Typography>
            {addOnsClicked === false ? (
              <Button
                variant="outlined"
                onClick={() => setAddOnsClicked(!addOnsClicked)}
              >
                View Add Ons
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={() => setAddOnsClicked(!addOnsClicked)}
              >
                View Services
              </Button>
            )}
            <Grid
              item
              container
              xs={12}
              style={{
                border: "1px solid #878787",
                height: boxHgt,
                borderRadius: "8px",
                marginTop: 10,
              }}
            >
              {addOnsClicked === false ? (
                <SimpleBarReact
                  autoHide={false}
                  style={{
                    maxHeight: "99%",
                    width: "inherit",
                    padding: "0px 15px 5px 5px",
                  }}
                >
                  {userData.services &&
                    userData.services
                      .filter((s) => s.addOn === false || s.addOn === undefined)
                      .sort((a, b) => (a.name > b.name && 1) || -1)
                      .map((service) => {
                        return (
                          <ServiceCard
                            service={service}
                            setSelectedService={setSelectedService}
                          />
                        );
                      })}
                </SimpleBarReact>
              ) : (
                <SimpleBarReact
                  autoHide={false}
                  style={{
                    maxHeight: "99%",
                    width: "inherit",
                    padding: "0px 15px 5px 5px",
                  }}
                >
                  {userData.services &&
                    userData.services
                      .filter((s) => s.addOn === true)
                      .sort((a, b) => (a.name > b.name && 1) || -1)
                      .map((service) => {
                        return (
                          <ServiceCard
                            service={service}
                            setSelectedService={setSelectedService}
                          />
                        );
                      })}
                </SimpleBarReact>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            md={2}
            container
            direction="column"
            style={{ padding: 20, justifyContent: "center" }}
          >
            <Button
              style={{ border: "1px solid #878787", marginBottom: 20 }}
              onClick={handleModal2Open}
              disabled={disabled}
            >
              Edit
            </Button>
            <Button
              style={{ border: "1px solid #878787" }}
              onClick={handleRemove}
              disabled={disabled}
            >
              Remove
            </Button>
          </Grid>

          <Hidden smDown>
            <Grid
              item
              md={5}
              container
              justifyContent="flex-end"
              style={{ padding: 20 }}
            >
              <Typography>Add A Service</Typography>
              <Grid
                item
                container
                xs={12}
                className={classes.service}
                style={{
                  border: "1px solid #878787",
                  height: boxHgt,
                  borderRadius: "8px",
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Paper component="form" className={classes.inputField}>
                  <InputBase
                    name="search"
                    //value={}
                    onChange={(e) => {
                      setNameSearch(e.target.value);
                    }}
                    placeholder="Search..."
                    className={classes.input}
                    //autoFocus
                  />

                  <SearchIcon
                    className={classes.icon}
                    style={{ color: "lightgrey" }}
                  />
                </Paper>

                <IconButton
                  aria-label="filter"
                  component="span"
                  aria-haspopup="true"
                  onClick={handleSelectFilter}
                  style={{ margin: "10px 5px 0px 0px", height: 40, width: 40 }}
                >
                  <FilterListIcon />
                </IconButton>
                <Menu
                  id="simple-menu-filter"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => setFilteredServices(allServices)}>
                    All
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Brow & Lashes")}>
                    Brow & Lashes
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Facials & Skincare")}>
                    Facials & Skincare
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Hair")}>Hair</MenuItem>
                  <MenuItem onClick={() => handleFilter("Health & Wellness")}>
                    Health & Wellness
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Home & Car Care")}>
                    Home & Car Care
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Makeup")}>
                    Makeup
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Massage")}>
                    Massage
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Nails")}>
                    Nails
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Pet Care")}>
                    Pet Care
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Waxing Services")}>
                    Waxing Services
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                </Menu>
                <IconButton
                  aria-label="sort"
                  component="span"
                  aria-haspopup="true"
                  onClick={handleSelectSort}
                  style={{ margin: "10px 5px 0px 0px", height: 40, width: 40 }}
                >
                  <SortByAlphaIcon />
                </IconButton>
                <Menu
                  id="simple-menu-sort"
                  anchorEl={anchorEl2}
                  keepMounted
                  open={Boolean(anchorEl2)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={sortAtoZ}>Sort A - Z</MenuItem>
                  <MenuItem onClick={sortZtoA}>Sort Z - A</MenuItem>
                  {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                </Menu>

                <SimpleBarReact
                  autoHide={false}
                  style={{
                    height: listHgt,
                    width: "inherit",
                    padding: "0px 15px 5px 5px",
                  }}
                >
                  {filteredServices
                    .filter((service) => {
                      const name = service.name;
                      if (nameSearch === "") {
                        return service;
                      } else if (
                        name.toLowerCase().includes(nameSearch.toLowerCase())
                      ) {
                        return service;
                      }
                    })
                    .map((service) => {
                      return (
                        <ServiceCard service={service} userData={userData} />
                      );
                    })
                    .sort()}

                  {filteredServices
                    .filter((service) => {
                      const name = service.name;
                      if (
                        nameSearch !== "" &&
                        !name.toLowerCase().includes(nameSearch.toLowerCase())
                      ) {
                        return service;
                      }
                    })
                    .map((service, idx) => {
                      if (idx === 0) {
                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            alignItems="center"
                            style={{ marginTop: 5 }}
                          >
                            <Grid item xs={12}>
                              <Typography>
                                Don't see what you're looking for?
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleModalOpen}
                              >
                                Add A New Service!
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      }
                    })}
                </SimpleBarReact>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <Hidden mdUp>
        <Modal
          open={!!isAddServiceModalOpen}
          onClose={handleAddServiceModalOpen}
        >
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            container
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: 20,
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "14px",
            }}
          >
            <Typography>Add A Service</Typography>
            <HighlightOffIcon
              className={classes.modalBtn}
              onClick={handleAddServiceModalOpen}
            />
            <Grid
              item
              container
              xs={12}
              className={classes.service}
              style={{
                border: "1px solid #878787",
                height: "70vh",
                borderRadius: "8px",
                marginTop: 10,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Paper component="form" className={classes.inputField}>
                <InputBase
                  name="search"
                  //value={}
                  onChange={(e) => {
                    setNameSearch(e.target.value);
                  }}
                  placeholder="Search..."
                  className={classes.input}
                  //autoFocus
                />

                <SearchIcon
                  className={classes.icon}
                  style={{ color: "lightgrey" }}
                />
              </Paper>

              <IconButton
                aria-label="filter"
                component="span"
                aria-haspopup="true"
                onClick={handleSelectFilter}
                style={{ margin: "10px 5px 0px 0px", height: 40, width: 40 }}
              >
                <FilterListIcon />
              </IconButton>
              <Menu
                id="simple-menu-filter"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => setFilteredServices(allServices)}>
                  All
                </MenuItem>
                <MenuItem onClick={() => handleFilter("Brow & Lashes")}>
                  Brow & Lashes
                </MenuItem>
                <MenuItem onClick={() => handleFilter("Facials & Skincare")}>
                  Facials & Skincare
                </MenuItem>
                <MenuItem onClick={() => handleFilter("Hair")}>Hair</MenuItem>
                <MenuItem onClick={() => handleFilter("Health & Wellness")}>
                  Health & Wellness
                </MenuItem>
                <MenuItem onClick={() => handleFilter("Makeup")}>
                  Makeup
                </MenuItem>
                <MenuItem onClick={() => handleFilter("Massage")}>
                  Massage
                </MenuItem>
                <MenuItem onClick={() => handleFilter("Nails")}>Nails</MenuItem>
                <MenuItem onClick={() => handleFilter("Waxing Services")}>
                  Waxing Services
                </MenuItem>
                <MenuItem onClick={() => handleFilter("Tattoo & Piercing")}>
                  Tattoo & Piercing
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
              </Menu>
              <IconButton
                aria-label="sort"
                component="span"
                aria-haspopup="true"
                onClick={handleSelectSort}
                style={{ margin: "10px 5px 0px 0px", height: 40, width: 40 }}
              >
                <SortByAlphaIcon />
              </IconButton>
              <Menu
                id="simple-menu-sort"
                anchorEl={anchorEl2}
                keepMounted
                open={Boolean(anchorEl2)}
                onClose={handleClose}
              >
                <MenuItem onClick={sortAtoZ}>Sort A - Z</MenuItem>
                <MenuItem onClick={sortZtoA}>Sort Z - A</MenuItem>
                {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
              </Menu>

              <SimpleBarReact
                autoHide={false}
                style={{
                  height: "80%",
                  width: "inherit",
                  padding: "0px 15px 5px 5px",
                }}
              >
                {filteredServices
                  .filter((service) => {
                    const name = service.name;
                    if (nameSearch === "") {
                      return service;
                    } else if (
                      name.toLowerCase().includes(nameSearch.toLowerCase())
                    ) {
                      return service;
                    }
                  })
                  .map((service) => {
                    return (
                      <ServiceCard service={service} userData={userData} />
                    );
                  })
                  .sort()}

                {filteredServices
                  .filter((service) => {
                    const name = service.name;
                    if (
                      nameSearch !== "" &&
                      !name.toLowerCase().includes(nameSearch.toLowerCase())
                    ) {
                      return service;
                    }
                  })
                  .map((service, idx) => {
                    if (idx === 0) {
                      return (
                        <Grid
                          item
                          container
                          xs={12}
                          alignItems="center"
                          style={{ marginTop: 5 }}
                        >
                          <Grid item xs={12}>
                            <Typography>
                              Don't see what you're looking for?
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={handleModalOpen}
                            >
                              Add A New Service!
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    }
                  })}
              </SimpleBarReact>
            </Grid>
          </Grid>
        </Modal>
      </Hidden>

      <Modal open={!!isModalOpen} onClose={handleModalOpen}>
        <Grid
          container
          xs={10}
          sm={6}
          md={5}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "15px",
          }}
        >
          <Grid
            item
            container
            justify="space-between"
            style={{
              backgroundColor: "#413e68",
              color: "white",
              height: "50px",
              borderRadius: "14px 14px 0px 0px",
            }}
          >
            <Typography variant="h6" style={{ padding: "10px" }}>
              Add a Service!
            </Typography>
            <IconButton
              onClick={() => window.location.reload()}
              style={{ color: "white" }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={12}
            container
            style={{ maxHeight: "65vh", overflowY: "scroll" }}
          >
            <Grid item xs={12} className={classes.selectedServiceDetails}>
              <Typography>Category & Name:</Typography>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="select-category">Category</InputLabel>
                <Select
                  labelId="select-category"
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Category"
                >
                  <MenuItem value="">
                    <em>--Selected One--</em>
                  </MenuItem>
                  {categories.map((category, idx) => {
                    return (
                      <MenuItem key={idx} value={category}>
                        {category}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} className={classes.selectedServiceDetails}>
              <TextField
                label="Name of Service"
                name="serviceName"
                // defaultValue={Math.floor(selectedService.time/60)}
                value={serviceName}
                placeholder="ex: Haircut"
                // onKeyPress={(e) => numsOnly(e)}
                onChange={(e) => {
                  setServiceName(e.target.value);
                }}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                required
                fullWidth
              />
            </Grid>

            <Grid
              item
              container
              xs={12}
              alignItems="center"
              className={classes.selectedServiceDetails}
            >
              <Grid item xs={12} md={6}>
                <Typography>Appointment Time:</Typography>
              </Grid>

              <Grid item container xs={12} md={6} style={{ paddingRight: 10 }}>
                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <TextField
                    label="Hrs"
                    name="hours"
                    // defaultValue='0'
                    value={hours}
                    placeholder="00"
                    onKeyPress={(e) => numsOnly(e)}
                    onChange={(e) => {
                      setHours(e.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6} style={{ paddingLeft: 10 }}>
                  <TextField
                    label="Mins"
                    name="mins"
                    // defaultValue={selectedService.time - (Math.floor(selectedService.time/60)*60)}
                    // value={newMins}
                    placeholder="00"
                    onKeyPress={(e) => numsOnly(e)}
                    onChange={(e) => {
                      setMins(e.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              alignItems="center"
              className={classes.selectedServiceDetails}
              style={{ marginTop: 20 }}
            >
              <Grid item xs={6}>
                <Typography style={{ width: "100%" }}>
                  Price
                  <Tooltip title="Selecting the 'Varies' checkbox allows you to create a general service price, and update the price later after confirming exactly what your client needs.">
                    <HelpOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "grey",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                  :
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pricesVary}
                        color="primary"
                        onChange={() => setPricesVary(!pricesVary)}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="vary"
                      />
                    }
                    label="Varies"
                  />
                </FormGroup>
              </Grid>

              <Grid
                item
                container
                xs={6}
                style={{
                  padding: "0px 10px",
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: 10, fontSize: 18 }}>$</div>
                {pricesVary === false ? (
                  <TextField
                    label="Price"
                    name="price"
                    // defaultValue={selectedService.price}
                    value={price}
                    placeholder="100.00"
                    onKeyPress={(e) => numsOnly(e)}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    required
                    fullWidth
                  />
                ) : (
                  <div
                    style={{
                      width: "inherit",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      label="Price"
                      name="price"
                      style={{ width: "70%" }}
                      // defaultValue={selectedService.price}
                      value={price}
                      placeholder="100.00"
                      onKeyPress={(e) => numsOnly(e)}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      required
                    />
                    <Typography style={{ fontSize: 10 }}> and up</Typography>
                  </div>
                )}
              </Grid>

              <Typography style={{ width: "inherit" }}>Details:</Typography>
              <TextareaAutosize
                style={{
                  padding: 5,
                  fontSize: 16,
                  font: "inherit",
                  borderColor: "silver",
                  borderRadius: 4,
                  width: "inherit",
                }}
                rowsMax={3}
                rowsMin={3}
                label="details"
                name="details"
                // defaultValue={selectedService.details ? selectedService.details : ""}
                value={details}
                //placeholder={time[1]}
                onChange={(e) => {
                  setDetails(e.target.value);
                }}
                required
                fullWidth
              />
            </Grid>

            <Grid
              item
              container
              xs={12}
              alignItems="center"
              className={classes.selectedServiceDetails}
              style={{ marginTop: 20 }}
            >
              <Grid item xs={6}>
                <Typography>
                  Require Deposit?
                  <Tooltip title="Require your customer to pay 25% down when booking.">
                    <HelpOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "grey",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={6}
                style={{
                  padding: "0px 10px",
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: 20,
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      size="normal"
                      color="primary"
                      checked={checked}
                      onChange={toggleChecked}
                    />
                  }
                  label={switchLabel}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={virtual}
                        color="primary"
                        onChange={() => setVirtual(!virtual)}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        name="vary"
                      />
                    }
                    label="Virtual Service"
                  />
                </FormGroup>
              </Grid>

              {virtual === true && (
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  style={{
                    padding: "0px 10px",
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    label="Zoom Link"
                    name="zoomlLink"
                    // defaultValue={selectedService.price}
                    value={zoomLink}
                    placeholder="https://us05web.zoom.us/j/{PERSONAL_METTING_ID}}"
                    onChange={(e) => {
                      setZoomLink(e.target.value);
                    }}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    required
                    fullWidth
                  />

                  {reasonInvalid.length !== 0 && (
                    <Typography
                      style={{
                        width: "100%",
                        color: "red",
                        fontSize: 14,
                        lineHeight: "1",
                        marginBottom: 30,
                      }}
                    >
                      Invalid URL: {reasonInvalid}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} container style={{ marginTop: 20 }}>
              <Typography
                className={classes.addAnother}
                onClick={handleAddAnotherCliked}
              >
                Add another
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              container
              className={classes.selectedServiceDetails}
              style={{ marginBottom: 40 }}
            >
              <Grid item xs={6} style={{ padding: "0px 10px" }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={6} style={{ padding: "0px 10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleAddNewService}
                  disabled={disabledAddNew}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Snackbar
            open={openSuccess}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert onClose={handleCloseAlert} severity={severity[1]}>
              {serviceName} has successfull been added!
            </Alert>
          </Snackbar>

          <Snackbar
            open={openErr}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert onClose={handleCloseAlert} severity={severity[0]}>
              Oops, something went wrong...
            </Alert>
          </Snackbar>
        </Grid>
      </Modal>

      {selectedService && (
        <Modal open={!!isModal2Open} onClose={handleModal2Close}>
          <Grid
            container
            xs={11}
            sm={6}
            md={5}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              borderRadius: "15px",
            }}
          >
            <Grid
              item
              container
              justify="space-between"
              style={{
                backgroundColor: "#413e68",
                color: "white",
                height: "50px",
                borderRadius: "14px 14px 0px 0px",
              }}
            >
              <Typography variant="h6" style={{ padding: "10px" }}>
                Edit Service Details
              </Typography>
              <IconButton
                onClick={handleModal2Close}
                style={{ color: "white" }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={12}
              container
              style={{ maxHeight: "65vh", overflowY: "scroll" }}
            >
              <Grid item xs={12} className={classes.selectedServiceDetails}>
                <Typography>
                  Category:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {selectedService.serviceType}
                  </span>
                </Typography>
              </Grid>

              <Grid item xs={12} className={classes.selectedServiceDetails}>
                <Typography>
                  Name:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {selectedService.name}
                  </span>
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.selectedServiceDetails}
              >
                <Grid item xs={12} md={6}>
                  <Typography>Appointment Time:</Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  style={{ paddingRight: 10 }}
                >
                  <Grid item xs={6} style={{ paddingLeft: 10 }}>
                    <TextField
                      label="Hrs"
                      name="hours"
                      defaultValue={Math.floor(selectedService.time / 60)}
                      // value={newHrs}
                      //placeholder={time[0]}
                      onKeyPress={(e) => numsOnly(e)}
                      onChange={(e) => {
                        setNewHours(e.target.value);
                      }}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} style={{ paddingLeft: 10 }}>
                    <TextField
                      label="Mins"
                      name="mins"
                      defaultValue={
                        selectedService.time -
                        Math.floor(selectedService.time / 60) * 60
                      }
                      // value={newMins}
                      //placeholder={time[1]}
                      onKeyPress={(e) => numsOnly(e)}
                      onChange={(e) => {
                        setNewMins(e.target.value);
                      }}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.selectedServiceDetails}
                style={{ marginTop: 20 }}
              >
                <Grid item xs={6}>
                  <Typography style={{ width: "100%" }}>
                    Price
                    <Tooltip title="Selecting the 'Varies' checkbox allows you to create a general service price, and update the price later after confirming exactly what your client needs.">
                      <HelpOutlineIcon
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          verticalAlign: "middle",
                        }}
                      />
                    </Tooltip>
                    :
                  </Typography>

                  {addOnChecked === false && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pricesVary}
                            color="primary"
                            onChange={() => setPricesVary(!pricesVary)}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            name="vary"
                          />
                        }
                        label="Varies"
                      />
                    </FormGroup>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  xs={6}
                  style={{
                    padding: "0px 10px",
                    display: "flex",
                    flexFlow: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: 10, fontSize: 18 }}>$</div>
                  {pricesVary === false ? (
                    <TextField
                      label="Price"
                      name="price"
                      defaultValue={selectedService.price}
                      placeholder={`${selectedService.price}`}
                      onKeyPress={(e) => numsOnly(e)}
                      onChange={(e) => {
                        setNewPrice(e.target.value);
                      }}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      required
                      fullWidth
                    />
                  ) : (
                    <div
                      style={{
                        width: "inherit",
                        display: "flex",
                        flexFlow: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label="Price"
                        name="price"
                        style={{ width: "70%" }}
                        defaultValue={selectedService.price}
                        placeholder={`${selectedService.price}`}
                        onKeyPress={(e) => numsOnly(e)}
                        onChange={(e) => {
                          setNewPrice(e.target.value);
                        }}
                        margin="normal"
                        variant="outlined"
                        autoComplete="off"
                        required
                      />
                      <Typography style={{ fontSize: 10 }}> and up</Typography>
                    </div>
                  )}
                </Grid>

                <Typography style={{ width: "inherit" }}>Details:</Typography>
                <TextareaAutosize
                  style={{
                    padding: 5,
                    fontSize: 16,
                    font: "inherit",
                    borderColor: "silver",
                    borderRadius: 4,
                    width: "inherit",
                  }}
                  rowsMax={3}
                  rowsMin={3}
                  label="details"
                  name="details"
                  defaultValue={
                    selectedService.details ? selectedService.details : ""
                  }
                  // value={newMins}
                  //placeholder={time[1]}
                  onChange={(e) => {
                    setDetails(e.target.value);
                  }}
                  required
                  fullWidth
                />
              </Grid>

              <Grid
                item
                container
                xs={12}
                alignItems="center"
                className={classes.selectedServiceDetails}
                style={{ marginTop: 20 }}
              >
                <Grid item xs={6} style={{ marginTop: 20 }}>
                  <Typography>
                    Is this an Add-on service?
                    <Tooltip title="This option allows your client to add this service in addtion to their main service (i.e: Nail fix, hair wash, etc.). This service will not appear until checkout.">
                      <HelpOutlineIcon
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          verticalAlign: "middle",
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  xs={6}
                  style={{
                    padding: "0px 10px",
                    display: "flex",
                    flexFlow: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: 20,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        size="normal"
                        color="primary"
                        checked={addOnChecked}
                        onChange={toggleAddOnChecked}
                      />
                    }
                    label={addOnSwitchLabel}
                  />
                </Grid>
                {addOnChecked === false && (
                  <Grid item xs={6} style={{ marginTop: 20 }}>
                    <Typography>
                      Require Deposit?
                      <Tooltip title="Require your customer to pay 25% down when booking.">
                        <HelpOutlineIcon
                          style={{
                            fontSize: "14px",
                            color: "grey",
                            verticalAlign: "middle",
                          }}
                        />
                      </Tooltip>
                    </Typography>
                  </Grid>
                )}
                {addOnChecked === false && (
                  <Grid
                    item
                    container
                    xs={6}
                    style={{
                      padding: "0px 10px",
                      display: "flex",
                      flexFlow: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginTop: 20,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          size="normal"
                          color="primary"
                          checked={depositChecked}
                          onChange={toggleDepositChecked}
                        />
                      }
                      label={depositSwitchLabel}
                    />
                  </Grid>
                )}

                <Grid item xs={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={virtual}
                          color="primary"
                          onChange={() => setVirtual(!virtual)}
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          name="vary"
                        />
                      }
                      label="Virtual Service"
                    />
                  </FormGroup>
                </Grid>

                {virtual === true && (
                  <Grid
                    item
                    container
                    xs={6}
                    style={{
                      padding: "0px 10px",
                      display: "flex",
                      flexFlow: "row",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      label="Zoom Link"
                      name="zoomlLink"
                      // defaultValue={selectedService.price}
                      value={zoomLink}
                      placeholder="https://us05web.zoom.us/j/{PERSONAL_METTING_ID}}"
                      onChange={(e) => {
                        setZoomLink(e.target.value);
                      }}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      required
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                container
                className={classes.selectedServiceDetails}
                style={{ marginBottom: 40 }}
              >
                <Grid item xs={6} style={{ padding: "0px 10px" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleModal2Close}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs={6} style={{ padding: "0px 10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleUpdateService}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      )}
    </Grid>
  );
}

Services.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(Services);
