import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {Grid, Button, Typography, Avatar, IconButton, Snackbar, Modal, TextareaAutosize} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear'
import StarIcon from '@material-ui/icons/Star';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from '../../config/fbConfig';
import firebase from 'firebase';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    button: {
        '&:hover':{
            cursor:'pointer'
        },
    },

  }));

function RatingAndReviewModal({modalOpen, setModalOpen, selectedAppt, userData}) {
    const classes = useStyles()

    const [rating, setRating] = useState(null)
    const [addToFave, setFave] = useState(false)
    const [feedback, setFeedback] = useState("")
    const [toastOpen, setOpen] = React.useState(false);

    
    const handleClose = () => {
        setModalOpen(!modalOpen);
        setRating(null);
        setFave(false);
        setFeedback('');
    }

    const handleOpenToast = () => {
        setOpen(true);
      };
    
      const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
        window.location.reload()
      };
    
      
    const handleSubmit = () => {
        if(userData.userId === selectedAppt.docData.clientUID){
            console.log(true)
            firestore.collection('appointments').doc(selectedAppt.id).update({
                serviceRating: rating,     
            })
            .then(() => {
                firestore.collection('professionals').doc(selectedAppt.docData.proUID).update({
                    ratings: firebase.firestore.FieldValue.arrayUnion({createdAt: new Date(), rating:rating})
                })
            })
            .then(() => {
                    firestore.collection('professionals').doc(selectedAppt.docData.proUID).collection('reviews').add({
                        review: {
                            avatar: selectedAppt.docData.clientAvatar,
                            createdAt: new Date(),
                            feedback: feedback,
                            name: selectedAppt.docData.clientName.split(' ')[0],
                            rating: rating,
                            service: selectedAppt.docData.service,
                        }
                    }) 
            })
            .then(()=> {
                if(addToFave === true){
                    firestore.collection('customers').doc(userData.collection).update({
                        favorites: firebase.firestore.FieldValue.arrayUnion(selectedAppt.docData.proEmail)
                    })
                }
            })
            .then(() => {
                handleOpenToast()

            })
            .catch((error) => {
                console.log(error)
            })
        }else{
            firestore.collection('customers').doc(`${selectedAppt.docData.clientUID}`).update({
                ratings: firebase.firestore.FieldValue.arrayUnion({createdAt: new Date(), rating:rating})
            })
            .then(()=> {
                firestore.collection('appointments').doc(`${selectedAppt.id}`).update({
                    clientRating: firebase.firestore.FieldValue.arrayUnion({createdAt: new Date(), rating:rating, feedback: feedback})
                })
            })
            .then(() => {
                handleOpenToast()
            })
            .catch((error) => {
                console.log(error)
            })
        
            // if(feedback !== ''){
            //     firestore.collection('adminNotfications').add({
            //         ratings: firebase.firestore.FieldValue.arrayUnion({createdAt: new Date(), rating: 5})
            //     })
            // }
        }
    }
    
    return (
        <Modal open={!!modalOpen}>

            <Grid container item xs={11} sm={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
            <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Review
                </Typography>
                <IconButton onClick={handleClose} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid item container xs={12} style={{padding:20, display:'flex', flexFlow:'column', alignItems:'center'}}>
                <Grid item containr xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center', marginBottom:10}}>
                    <Typography>Please Rate Your Experience</Typography>
                </Grid>
                {userData.collection === 'professionals'?
                <Avatar src={selectedAppt.docData.clientAvatar} style={{height:100, width:100}}></Avatar>
                :
                <Avatar src={selectedAppt.docData.proAvatar} style={{height:100, width:100}}></Avatar>
                }
                <Grid item containr xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center'}}>
                    {[...Array(5)].map((star,idx) => {
                        const ratingValue = idx+1
                        return <label>
                                    <input typeof='radio' name='rating' value={ratingValue} onClick={()=> {setRating(ratingValue); if(ratingValue <+ 3){setFave(false)}}} style={{display:'none'}}/>
                                    <StarIcon style={{color: ratingValue <= rating ? '#413e68' : 'lightgray'}} className={classes.button}/>
                                </label>
                        })
                    }
                </Grid>
                <Grid item containr xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center', marginBottom:20}}>
                    {rating === 1 && <Typography>Poor <span role="img" aria-label="poor">😤</span></Typography>}
                    {rating === 2 && <Typography>Bad <span role="img" aria-label="bsd">😒</span></Typography>}
                    {rating === 3 && <Typography>Okay <span role="img" aria-label="okay">😐</span></Typography>}
                    {rating === 4 && <Typography>Good <span role="img" aria-label="good">😃</span></Typography>}
                    {rating === 5 && <Typography>Excellent! <span role="img" aria-label="excellent">🤩</span></Typography>}
                </Grid>

                <Grid item containr xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center', marginBottom:20}}>
                    {((rating === 4 || rating === 5) && userData.collection === 'customers') && 
                    <Typography style={{display:'flex', borderRadius:20, backgroundColor:'lighgray', padding:10}}>Save to favorites? 
                        {addToFave === true ? 
                            <FavoriteIcon className={classes.button} style={{color:'red'}} onClick={()=> setFave(false)}/> : <FavoriteBorderIcon className={classes.button} onClick={()=> setFave(true)}/>}
                    </Typography>}
                </Grid>

                <Grid item containr xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center', marginBottom:20, borderTop:'1px solid lightgray'}}>
                    {userData.collection === 'customers' && 
                    <Grid item container xs={12}>
                        <Typography style={{width:'100%', textAlign:'center', marginTop:10}}>Leave A Review:</Typography>
                        <TextareaAutosize 
                            style={{width:'100%', border:'1px solid lightgray', borderRadius:4, font:'inherit', fontSize:"inherit", marginTop:10, padding:10}}
                            rowsMax={3}
                            rowsMin={3}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                    </Grid>
                    }
                    {(userData.collection === 'professionals' && rating >= 3) &&
                    <Grid item container xs={12}>
                        <Typography style={{width:'100%', textAlign:'center', marginTop:10}}>Leave A Review::</Typography>
                        <TextareaAutosize 
                            style={{width:'100%', border:'1px solid lightgray', borderRadius:4, font:'inherit', fontSize:"inherit", marginTop:10, padding:10}}
                            rowsMax={3}
                            rowsMin={3}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                        />
                    </Grid>
                    }

                </Grid>
                <Grid item containr xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center', marginBottom:10}}>
                    <Button variant='contained' color='primary' disabled={rating === null || (userData.collection === 'customers' && feedback === '')} onClick={handleSubmit}>Submit</Button>
                </Grid>

            </Grid>

            <Snackbar open={toastOpen} autoHideDuration={15000} onClose={handleCloseToast}>
                <Alert onClose={handleCloseToast}>
                    Your review was submitted successfully!
                </Alert>
            </Snackbar>

            </Grid>
            
            
        </Modal>
    )
}

export default RatingAndReviewModal
