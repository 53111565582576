import React, {useEffect, useState} from 'react'
import {Grid, Avatar, Typography, ClickAwayListener} from '@material-ui/core'
import {useSelector, useDispatch} from 'react-redux'
import {firestore} from '../../../config/fbConfig'
import { useHistory } from 'react-router'


function Conversation({ id, currentAppt, setCurrentAppt, conversationId, setConversationId, appointment, messages, recentMessage, userData}) {

  const history = useHistory()
  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const mostRecentMessage = messages && messages.sort((a,b) =>  new Date(a.createdAt.seconds * 1000) < new Date(b.createdAt.seconds * 1000) && 1 || -1)[0]
  const date = mostRecentMessage && new Date(mostRecentMessage.createdAt).toLocaleDateString('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  //const mostRecentMessage = orderedMessages[0]
  
  const stateCID = useSelector(state => state.appt.conversationId)
  const background = (currentAppt === appointment) ? 'lightgrey' : 'white';


  const setSelectedConvo = () => {
    setCurrentAppt(appointment)
    setConversationId(id)
    // history.push(`/home/messages/${id}`)
  }

  useEffect(() => {
    //if(stateCID !== ""){
      // if(conversationId === stateCID){
     //    setSelected(`${conversationId}`)
      // } else{
      //   setSelected('')
      // }
    // }
    //// console.log(id)

    if(appointment) {
        // let unsubscribe = null;
        // unsubscribe = 
        firestore.collection('appointments').doc(`${appointment.id}`)
        .collection('messages').orderBy('createdAt', 'desc')
        .limit(1)
        .onSnapshot(querySnaphot => {
          const data = querySnaphot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
          }))
          // console.log('STEP 5: from Convos')
          setMessage(data.pop())
        })
        // console.log(mostRecentMessage)
        // return ()=> unsubscribe();
    }
    // console.log(appointment)
  },[appointment])


    return (
      <>
      {currentAppt === null ?
        <Grid container item xs={12} style={{cursor:'pointer', height:90, padding:10, display: 'flex', flexFlow:'row', alignItems:'center', borderBottom:'1px solid lightgrey', backgroundColor: 'white'}}
              onClick={setSelectedConvo}>
          <Grid style={{display: 'flex'}}>
            {userData.collection === 'professionals' ? 
              <Avatar src={appointment.clientAvatar} style={{width: 60, height: 60, marginRight:'10px'}}></Avatar>
            :
              <Avatar src={appointment.proAvatar} style={{width: 60, height: 60, marginRight:'10px'}}></Avatar>
            }
          </Grid>

          <Grid style={{display: 'flex', flexFlow:'column', maxWidth: '70%'}}>
          {userData.collection === 'professionals' ? 
            <Typography style={{fontWeight:'bold'}}>{appointment.clientName}</Typography>
          :  
            <Typography style={{fontWeight:'bold'}}>{appointment.proName}</Typography>
          }
          {messages && messages.length > 0 ? 
              <Typography style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{mostRecentMessage.text}</Typography>
              :
              <Typography style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap', color:'#878787'}}>No messages...</Typography>
          }

          </Grid>
        </Grid> 
      :
        <Grid container item xs={12} style={{cursor:'pointer', height:90, padding:10, display: 'flex', flexFlow:'row', alignItems:'center', borderBottom:'1px solid lightgrey', backgroundColor: background}} onClick={setSelectedConvo}>
           <Grid style={{display: 'flex'}}>
           {userData.collection === 'professionals' ? 
            <Avatar src={appointment.clientAvatar} style={{width: 60, height: 60, marginRight:'10px'}}></Avatar>
          :
            <Avatar src={appointment.proAvatar} style={{width: 60, height: 60, marginRight:'10px'}}></Avatar>
          }
           </Grid>

           <Grid style={{display: 'flex', flexFlow:'column', maxWidth: '70%'}}>
          {userData.collection === 'professionals' ? 
            <Typography style={{fontWeight:'bold'}}>{appointment.clientName}</Typography>
          :  
            <Typography style={{fontWeight:'bold'}}>{appointment.proName}</Typography>
          }
          {messages && messages.length > 0 ? 
              <Typography style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{mostRecentMessage.text}</Typography>
              :
              <Typography style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap', color:'#878787'}}>No messages...</Typography>
          }
          </Grid>
        </Grid> 
      }
      </>
          
    )
}

export default Conversation
