import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Grid, IconButton, Typography, Avatar, TextField, Button, Paper, InputBase, Tooltip} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { firestore } from '../../../../config/fbConfig';
import firebase from 'firebase';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ReactPaginate  from 'react-paginate'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon} from "react-share";


function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }


const useStyles = makeStyles((theme) => ({
    input: {
        flex: 1,
        display: 'flex',
        height: 50,
        alignItems: 'center',
        '&:hover':{
            cursor:'pointer'
        },
      },
  }));


function Referrals({userData, view, setView}) {
    const classes = useStyles()
    const steps = [
        {num: "1", header:"Invite your friends", subline:'Just share your link'},
        // {num: "2", header:"Earn Boosts", subline:'When they join'},
        {num: "2", header:"Get $10 when they join! 🎉", subline:'Unlimited. Terms apply.'}
        ]
    const terms = "Service Providers who sign up using your referal code will appear on your Account page. Once they have completed their first 5 appointments, a green check mark will appear next to their name and $10.00 USD will be automatically credited to your account via Stripe. To view your referrals, click the 'My Referrals' button."
    const aboutBoosts = "So, what are boost? Glad you ask! \n\n Boosts help you get noticed by local clients by ranking you higher when looking for services you provide!"

    const value = 'www.cutiapp.com/signup/r/'+userData.url
    const [copied, setCopied] = useState(false)
    const [transition, setTransition] = useState(undefined);
    const [referrals, setReferrals] = useState([]);
    const [loading, setLoading] = useState(true)

    const handleClick = (Transition) => () => {
        setTransition(() => Transition);
        setCopied(true);
        setTimeout(() => {  setCopied(false); }, 2000);
      };

      const handleClose = () => {
        setCopied(false);
      };


    useEffect(() => {

      if(referrals.length === 0  && loading === true){
          setLoading(!loading)
          firestore.collection('professionals').doc(userData.userId).collection('referrals')
          .get().then((querySnapshot) => {
              console.log(querySnapshot.docs.map(doc => doc.id))
            // setReferrals(querySnapshot.docs.map(doc => {doc.id, doc.data()}))
        })
      }
    
    //   return () => {
        
    //   }
    }, [referrals, loading, userData.userId])
    

    return (
        <Grid item container xs={12} md={10}>
            <Grid item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                {view === false ?
                    <div>
                        <Typography style={{fontWeight:'bold'}}>Refer a friend</Typography>
                        <Typography variant='caption'>And you can earn money & other cool perks.</Typography>
                    </div>
                :
                    <div>
                        <Typography style={{fontWeight:'bold'}}>My Referrals</Typography>
                        <Typography variant='caption'>Take a peek at who's making you money!</Typography>
                    </div>
                }
                {view=== false ?
                    <Button variant='contained' color='primary' onClick={()=> setView(!view)}>My Referrals</Button>
                :
                    <Button variant='contained' color='secondary' onClick={()=> setView(!view)}>Share</Button>
                }
            </Grid>

            {view === false ?
            <Grid item container xs={12}>
                <Grid item xs={12} style={{testAlign:'left', margin:'10px'}}>
                    <Typography variant='caption'><HelpOutlineIcon style={{verticalAlign: 'middle', fontSize:20}}/> How it works</Typography>
                </Grid>
                {steps.map(step => {
                    return(
                        <Grid key={step.num} item container xs={12} direction='row' style={{display:'flex', flexFlow:'row', alignItems:'center', marginTop:20}}>
                            <Grid item style={{testAlign:'left'}}>
                                <div style={{fontSize:'20px', fontWeight:'bold', padding:'10px 20px', backgroundColor:'lightgrey', borderRadius:30, color:'white'}}>{step.num}</div>
                            </Grid>
                            <Grid item container style={{ paddingLeft:'10px', display:'flex', flexFlow:'column'}} direction='row'>
                                <Typography style={{fontWeight:'bold', color:'#413e68'}}>{step.header}
                                {step.num === "2" &&
                                <Tooltip title={terms}>
                                    <HelpOutlineIcon style={{fontSize:20}}/>
                                </Tooltip>
                                }</Typography>
                                <Typography variant='caption'>{step.subline} </Typography>
                            </Grid>
                        </Grid>

                    )
                })}

                <Grid item xs={12} container>  
                    <Grid item xs={12} container>
                        <CopyToClipboard text={value} onCopy={handleClick(TransitionUp)}>
                            <Paper component="form" className={classes.input} style={{margin:'30px 0px 10px', paddingLeft:'10px'}}>
                            
                                <InputBase aria-controls="simple-menu" className={classes.input} value={value}/>
                                <Button color='primary'>copy</Button> 
                            </Paper>
                        </CopyToClipboard>

                    </Grid>

                    <CopyToClipboard text={value} onCopy={handleClick(TransitionUp)}>
                        <Button variant='contained' color='primary' fullWidth>
                            Share
                        </Button>
                    </CopyToClipboard>

                    <Snackbar
                        open={copied}
                        onClose={handleClose}
                        TransitionComponent={transition}
                        message="Copied to clipboard!"
                        key={transition ? transition.name : ''}
                    />
                </Grid>

                <Grid item xs={12} container style={{display:'flex', justifyContent:'space-evenly', marginTop:'10px'}}>
                    
                    {/* <EmailShareButton url={value}
                    subject={'Join me on Cuti App 🎉'}
                    body={'Hey, use my code to join Cuti App - the Instant, On-Demand Beauty & Wellness Services Platform. Just use my link to sign up today and earn for free!!'}
                    separator={' --> '}>
                        <EmailIcon size={40} round={true}/>
                    </EmailShareButton> */}

                    <FacebookShareButton url={value}
                    quote={'Hey, if you work in the Beauty & Wellness industry, you need to check out this FREE booking platform!! Join Cuti App today and get $10.00!!'}
                    hashtag={'#CutiApp'}>
                        <FacebookIcon size={40} round={true}/>
                    </FacebookShareButton>

                    <TwitterShareButton url={value}
                    title={'Hey, if you work in the Beauty & Wellness industry, you need to check out this FREE booking platform!! Join Cuti App today and get $10.00!!'}
                    hashtags={['CutiApp', 'BeautyAndWellness', 'BeautyOnDemand', 'WellnessOnDemand']}>
                        <TwitterIcon size={40} round={true}/>
                    </TwitterShareButton>

                    <LinkedinShareButton url={value}
                    summary={'Hey, if you work in the Beauty & Wellness industry, you need to check out this FREE booking platform!! Join Cuti App today and get $10.00!!'}
                    source={'Cuti App'}>
                        <LinkedinIcon size={40} round={true}/>
                    </LinkedinShareButton>

                    <WhatsappShareButton url={value}
                    title={'Hey, if you work in the Beauty & Wellness industry, you need to check out this FREE booking platform!! Join Cuti App today and get $10.00!!'}
                    separator={' --> '}
                    hashtag={'#CutiApp'}>
                        <WhatsappIcon size={40} round={true}/>
                    </WhatsappShareButton>
                </Grid>

            </Grid>
            :
            <Grid item container xs={12} >
                {/* TODO: Create logic to check for pros and change display based on if data exists */}
                {referrals.length !== 0 ? 
                null
                :
                <Grid item container xs={12} style={{minHeight:300, display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
                    <Avatar style={{height:'fit-content', width:'fit-content', padding:10, backgroundColor:'lightgray'}}><img src="https://img.icons8.com/ios-filled/100/F2F2F2/person-in-a-mirror.png" alt=''/></Avatar>
                    <Typography color="initial" >No Referrals to display yet</Typography>
                    <Typography color="initial" style={{color:'gray', fontSize:'12px'}}>Go out there and share the good news about Cuti App!</Typography>
                </Grid>
                }
                
            </Grid>
            }



           



        </Grid>
    )
}

export default Referrals
