import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Grid, IconButton, Typography, FormControl, InputLabel, Select} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { firestore } from '../../../../config/fbConfig';
import firebase from 'firebase';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ReactPaginate  from 'react-paginate'
import AppointmentInfoModal from '../../../modals/AppointmentInfoModal'
import id from 'date-fns/esm/locale/id/index.js';


const useStyles = makeStyles((theme) => ({
    pagination: {
        //align center and margin bottom of 20 or 30
        margin: '15px auto',
        display: 'flex',
        listStyle: 'none',
        outline: 'none',
        fontSize:'14px',
        alignItems:'center',
        color: '#737373',
        alignSelf: 'center',
        paddingInlineStart: '0px',
        justifyContent:'center'
    },
    page: {
        color: '#737373',
        border: '1px solid #737373',
        borderRadius:'25px',
        padding: '6px 12px',
        margin: "0px 5px",
        "&:hover":{
            backgroundColor: 'white' ,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            cursor:'pointer'
        }
    },
    link: {
    },
    active: {
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        fontWeight:'bold',
    },
    navigation:{
        fontWeight: 'bold',
        fontSize: 20,
        "&:hover":{
            color: theme.palette.primary.main,
            cursor:'pointer'
        }
    },
    remove: {
        "&:hover":{
            cursor: 'pointer'
        },
        position:'absolute',
        top: -15,
        right: -15,
        background: '#ffffffad'
    },
    row1: {
        fontWeight:'bold',
        fontSize:'16px'
    },
    row2: {
        fontSize:'12px',
    },
    rformControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    
  }));



function History({userData}) {
    const classes = useStyles()
    const [pageNumber, setPageNumber] =  useState(0)
    const [appointments, setAppointments] = useState([])
    const [filterBy, setFilterBy] = useState("");
    const [filterByy, setFilterByy] = useState("");
    const [loading, setLoading] = useState(true)
    const [selectedAppt, setSelectedAppt] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)

    const perPage = 5
    const apptsViewed = pageNumber * perPage
    
    //const [pageCount, setPageCount] = useState(0)
    const pageCount = Math.ceil(appointments.length / perPage)

    const pageChange = ({selected}) => {
        setPageNumber(selected)
    }

    const handleChange = (event) => {
        const name = event.target.value;
        // console.log(name)
        setFilterBy(name)
        setFilterByy(name)
      };


    useEffect(() => {
        //let userAppointments = firestore.collection("customers").doc(`${userData.userId}`)
        if(appointments.length === 0 && loading === true){
            firestore.collection("appointments").where("serviceComplete", "==", true)
            .onSnapshot((querySnapshot) => {
              var appts = [];
              querySnapshot.forEach((doc) => {
                if(doc.data().proUID === userData.userId){
                  appts.push({id: doc.id, docData: doc.data()});
                }else if(doc.data().clientUID === userData.userId){
                    appts.push({id: doc.id, docData: doc.data()});
                  }
                });
                
                setAppointments(appts)
                setLoading(false)
                // console.log('hello')
          })
        }

      switch (filterBy) {
          case "Price":
            setAppointments(appointments.sort((a,b) =>  a.docData.serviceFee > b.docData.serviceFee && 1 || -1))
            setFilterBy('')
            break;
         case "Date":
              setAppointments(appointments.sort((a,b) => new Date(b.docData.serviceTimeEnd.seconds*1000) > new Date(a.docData.serviceTimeEnd.seconds*1000)))
              setFilterBy('')
              break;
          case "Name":
            setAppointments(appointments.sort((a,b) =>  a.docData.service.toUpperCase() > b.docData.service.toUpperCase() && 1 || -1))
            setFilterBy('')
              break;
          case "Client Name":
            setAppointments(appointments.sort((a,b) =>  a.docData.clientName.toUpperCase() > b.docData.clientName.toUpperCase() && 1 || -1))
            setFilterBy('')
              break;
          case "Pro Name":
            setAppointments(appointments.sort((a,b) =>  a.docData.proName.toUpperCase() > b.docData.proName.toUpperCase() && 1 || -1))
            setFilterBy('')
              break;
      
          default:
            setAppointments(appointments.sort((a,b) => new Date(b.docData.serviceTimeReq.seconds*1000) - new Date(a.docData.serviceTimeReq.seconds*1000)))
              break;
      }

        // cancel subscription to useEffect
      },[userData.userId, filterBy, appointments])

    const displayAppointments = appointments && appointments.slice(apptsViewed, apptsViewed + perPage).map((appt,idx) => {
        const {id, docData} = appt
        const {proName, clientName, serviceTimeReq, serviceComplete, service, serviceFee, serviceFeeBooking, serviceFeeTravel, serviceLocation, serviceRating, serviceTimeEnd} = docData
        const customerTotal = (parseFloat(serviceFee) + parseFloat(serviceFeeTravel) + parseFloat(serviceFeeBooking)).toFixed(2)
        const proTotal = (parseFloat(serviceFee) + parseFloat(serviceFeeTravel)).toFixed(2)
        const date = new Date(serviceTimeReq.seconds*1000).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
        
        if(!!serviceComplete){
            return(
                <Grid onClick={()=> {setSelectedAppt(appt); setModalOpen(!modalOpen)}} item xs={12} md={10} container key={idx} style={{marginBottom:5, border:'1px solid #878787', borderRadius:'16px', padding:10}}>
                    <Grid item container xs={8}>
                        <Grid item xs={12}>
                            <Typography className={classes.row1} style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{service}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {userData.collection === 'professionals' ? 
                                <Typography className={classes.row2} style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{clientName}</Typography>
                            :
                                <Typography className={classes.row2} style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis'}}>{proName}</Typography>
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={3} container>
                        <Grid item xs={12}>
                            {userData.collection === 'professionals' ? 
                                <Typography className={classes.row1} style={{textAlign:'right'}}>${proTotal}</Typography>
                            :
                                <Typography className={classes.row1} style={{textAlign:'right'}}>${customerTotal}</Typography>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.row2} style={{textAlign:'right'}}>{date}</Typography>
                        </Grid>
                    </Grid>

                    <Grid xs={1}>
                            <IconButton><ArrowForwardIosIcon/></IconButton>
                    </Grid>
                </Grid>
                   )
        }
    })

    return (
        <>
        {/* <Grid item xs={12} container style={{display:'flex', flexFlow:'row',justifyContent:'space-between'}}>
            <Typography>Sort</Typography>
            <IconButton><KeyboardArrowUpIcon /></IconButton>
        </Grid> */}
        <Grid item xs={12} container style={{display:'flex', flexFlow:'column', alignItems:'center', position:'relative'}}>
            
            {appointments.length !== 0 ? 
                <Grid item container  xs={12} md={10} style={{display:'flex', justifyContent:'space-between', marginBottom: 10}}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-age-native-simple">Filter</InputLabel>
                        <Select
                        native
                        value={filterByy}
                        onChange={handleChange}
                        label="Filter"
                        inputProps={{
                            name: 'filterBy',
                            id: 'outlined-age-native-simple',
                        }}
                        >
                        <option aria-label="None" value="" />
                        <option value={'Date'}>Date</option>
                        <option value={'Name'}>Service Name</option>
                        {userData.collections === 'professionals' ?
                        <option value={'Pro Name'}>Pro Name</option>
                        :
                        <option value={'Client Name'}>Client Name</option>
                        }
                        <option value={'Price'}>Price</option>
                        </Select>
                    </FormControl>

                    <IconButton><ImportExportIcon/></IconButton>
                </Grid>
                :
                <Typography>You have no appointment history...</Typography>
            }
            
            {displayAppointments}
 
        </Grid>
            {appointments.length > 0 &&
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    onPageChange={pageChange}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    //onPageChange={handlePageClick}
                    containerClassName={classes.pagination}
                    pageClassName={classes.page}
                    pageLinkClassName={classes.link}
                    activeClassName={classes.active}
                    previousClassName={classes.navigation}
                    nextClassName={classes.navigation}
                    subContainerClassName={classes.pagesPagination}
                />
            }

            <AppointmentInfoModal modalOpen={modalOpen} setModalOpen={setModalOpen} selectedAppt={selectedAppt} setSelectedAppt={setSelectedAppt} userData={userData}/>
        
        </>
    )
}

export default History
