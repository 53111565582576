import React, {useEffect} from "react";
import {Typography, Container, Button, FormControlLabel, Snackbar, Checkbox} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {storage} from '../../../../config/fbConfig';
import FileUploader from "../FileUploader";
//import { ErrorOutlineTwoTone } from "@material-ui/icons";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '100%',
      position: 'relative',
      border: '1px solid gray',
      display: 'flex',
      alignItems: "center",
      borderRadius: '8px',
    },
    formLabel:{
      display: 'flex',
      flex:'auto',
      paddingLeft: 10,
      '&:hover':{
        cursor:'pointer'
      },
      overflow:'hidden',
    },
    form: {
      height: 50,
      padding: '15px 0',
      visibility: 'hidden',
      width: 110,
    },
    uploadBtn:{
      height: 50,
      display: 'flex',
      position:'absolute',
      right: 0,
      background: 'gray',
      width: 110,
    },
    question: {
        color: 'gray',
        marginTop:20,
      },
      checkBox: {
          display:'flex',
          alignContent:'start',
          marginLeft: 10,
          marginTop: 20,
      },
      dlButton: {
        background: 'none',
        color: 'blue',
        margin: "20px 0"
      }
  }));

export const W9 = ({ formData, setForm, navigation }) => {
  const classes = useStyles();
  
  const { firstName, lastName, email } = formData;
  const [checked, setChecked] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState({})
  const [toastOpen, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true)

  const handleClick = () => {
    setOpen(true);
  };

  const handleOpenToast = () => {
    setOpen(true);
  };

  const next = () =>{
    navigation.next()
  }

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCheckedChange = (event) => {
    setChecked(!checked);
  };

  const handleFileChange = (event) => {

    const file = event.target.files[0];
    setUploadFile(file)
  };

  useEffect(() => {
    if(checked === true){
      formData.accountStatus = "needs review"
      // console.log(formData)
      setDisabled(false)
    }
    // return () => {
    //   cleanup
    // }
  }, [checked, formData])

  const handleUpload = () => {
    const storageRef = storage.ref();
    const fileRef = storageRef.child("ProInfo").child(email + "-" + firstName + "-" + lastName).child("W-9").child(uploadFile.name)
    fileRef.put(uploadFile).then(() => {setUploadFile({})})
    .then(()=> handleOpenToast()).then(setDisabled(false))
  }

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  // }

  const handleDownload = () => {
    const storageRef = storage.ref();
    const w9Reference = storageRef.child("Downloadables/fw9.pdf")

    w9Reference.getDownloadURL().then(function(url) {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
   
  }


  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>W-9</Typography>

      <Typography variant='body2' className={classes.question}>Upload your commpleted W-9 form so you can get paid!*</Typography>
      <FileUploader formData={formData} id={'file'} file={uploadFile} updateFile={handleFileChange} handleUpload={handleUpload} setUploadFile={setUploadFile} />

      <Snackbar open={toastOpen} autoHideDuration={3000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast}>
          Your file was uploaded successfully!
        </Alert>
      </Snackbar>

      {disabled === false ? null
      :
      <>
        <FormControlLabel className={classes.checkBox}
          control={<Checkbox checked={checked} onChange={handleCheckedChange} />}
          label="I will do this later."
        />
        <Button className={classes.dlButton} onClick={handleDownload}>W-9 Download Form</Button>
      </>
       }
      
      
      <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={() => navigation.next()}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};