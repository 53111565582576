import React, {useState, useEffect} from 'react'
import {Typography, Grid, Button, InputBase,
        Snackbar, CircularProgress} from "@material-ui/core";
//import StripeCheckout from 'react-stripe-checkout';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {firestore} from '../../../../config/fbConfig'
import firebase from'firebase'

import { CardElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

const { ms, m,} = require('time-convert')


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      margin: 5,
      height: 40,
      border: '1px solid grey',
      borderRadius: 6,
      padding: '0px 5px'
    },
    inputContainer: {
        padding: 5
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      border: '3px #413e68 solid',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    formControl: {
        width: '100%'
    },

  }));

export const Checkout = ({formData, setForm, navigation, userData}) => {
    const {reqDeposit, client, pro, includeBooking} = formData
    const stripe = useStripe();
    const elements = useElements();

    var clientNumCleaned = ('' + `${client.phone}`).replace(/\D/g, '');
    var clientNumMatch = clientNumCleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var clientNumE164 = ["+1", clientNumMatch[2], clientNumMatch[3], clientNumMatch[4]].join('')
    var proNumCleaned = ('' + `${pro.phone}`).replace(/\D/g, '');
    var proNumMatch = proNumCleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var proNumE164 = ["+1", proNumMatch[2], proNumMatch[3], proNumMatch[4]].join('')

    const classes = useStyles();
    const [isProcessing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false)
    const [fail, setFail] = useState(false)
    const [checkoutError, setCheckoutError] = useState(null)
    const [paymentRequest, setPaymentRequest] = useState(null)

    const {name, email} = formData.client;
    const {serviceCost, travelFee, bookingFee, service} = formData
    const address = userData.address.split(', ');
    const street = address.shift()
    const city = address.slice(-3,address.length).shift() 
    const state = address.slice(-2,address.length).shift() 
    const zip = '';
    const total = (parseFloat(serviceCost) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)
    // const deposit = (parseFloat(serviceCost / 4) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)
    const deposit = (reqDeposit === true) ? (parseFloat(serviceCost / 4) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2) : (parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)

    const balance = (total - deposit).toFixed(2)
    //TODO: find a way to include the Booking Fee
    //const balance = includeBooking === true ? (total - deposit).toFixed(2) : (total - deposit).toFixed(2)
    const depositAppFee = (bookingFee * 100) - (((balance * 0.029) + .30).toFixed(2) * 100)

    const cardElementOptions = {
        hidePostalCode: true,
    }
    // useEffect(() => {
    //     if(!stripe || !elements){
    //         return;
    //     }
    //     const pr = stripe.paymentRequest({
    //         currency:'usd',
    //         country:'US',
    //         requestPayerEmail: true,
    //         requestPayerName: true,
    //         total: {
    //             label: `Deposit for Cuti App: Service - ${service}`,
    //             amount: 1999,
    //         }
    //     })

    //     pr.canMakePayment().then((result) => {
    //         if(result) {
    //             setPaymentRequest(pr);
    //         }else{
    //             console.log('cannot malke payment!')
    //         }
    //     })


    //     // console.log(paymentRequest, pr)
    //     // console.log(formData.location === 'pro location' ? formData.startingCoordinates2 : formData.startingCoordinates)
        
    // }, [stripe, elements])


    const handlePayDeposit = async (e) => {
        e.preventDefault()
        setProcessing(true)

        const billingDetails = {
            name: name,
            email: email,
            address: {
                city: city,
                line1: street,
                state: state,
                postal_code: zip,
            }
        };

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: billingDetails,
        })

        if(reqDeposit === true){
            if(!error) {
                if(userData.test === false){
                    console.log("Hello, Im not a test account")
                    try{
                        const {id} = paymentMethod
                        const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment', {
                            amount: deposit * 100,
                            id,
                            description: `Cuti App: ${service} w/ ${formData.pro.name} (DEPOSIT)`,
                            customer: userData.customerId,
                            email: userData.email,
                            proStripeId: formData.pro.stripeId,
                            fee: depositAppFee
                        })

                        if(response.data.success){
                            console.log("Successful Payment")
                            const t = (m.to(ms)(formData.serviceTimeEnd))        
                            setSuccess(true)
                            setProcessing(false)
                            //Move to dispatch
                            firestore.collection("appointments").add({
                                clientEmail: userData.email,
                                clientName: userData.firstName + " " + userData.lastName,
                                clientPhone: clientNumE164,
                                clientUID: userData.userId,
                                clientRating: 'n/a',
                                clientAvatar: userData.images.avatar,
                                clientPets: formData.petTypes,
                                clientNotes: formData.notes,
                                clientStatus: userData.accountStatus,
                                clientStripeId: userData.customerId,
                                proEmail: formData.pro.email,
                                proNumber: proNumE164,
                                proName: formData.pro.name,
                                proUID: formData.pro.userId,
                                proAvatar: formData.pro.avatar,
                                // proPaidOut:false,
                                proStripeId: formData.pro.stripeId,
                                service: formData.service,
                                serviceFee: formData.serviceCost,
                                serviceFeeUpdate: formData.priceVaries,
                                serviceFeeTravel: formData.travelFee,
                                serviceFeeBooking: formData.bookingFee,
                                serviceComplete: false,
                                serviceCanceled: false,
                                serviceTimeReq: new Date(formData.dateTime),
                                serviceTimeStart: "n/a",
                                serviceTimeEnd: new Date(parseFloat(t.join()) + new Date(formData.dateTime).getTime()),
                                serviceLocation: formData.address,
                                serviceCoordinates: formData.startingCoordinates,
                                serviceRating: "n/a",
                                amtPaid: deposit,
                                amtRemainingBalance: balance,
                                messages: [],
                                tip:'',
                                //serviceType:??
        
                            })
                            .then(() => {
                                firestore.collection('professionals').doc(formData.pro.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .then(() => {
                                firestore.collection('customers').doc(userData.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });
                            setSuccess(true)
                            setProcessing(false)
                        }
                    }catch (error){
                        console.log("Oops, there's an issue:", error)
                    }
                }
                else if(userData.test === true){
                    console.log("Hello, Im a test account")
                    try{
                        const {id} = paymentMethod
                        const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment-test', {
                            amount: deposit * 100,
                            id,
                            description: `Cuti App: ${service} w/ ${formData.pro.name} (DEPOSIT)`,
                            customer: userData.customerId,
                            email: userData.email,
                            proStripeId: formData.pro.stripeId,
                            fee: depositAppFee
                        })

                        if(response.data.success){
                            console.log("Successful Payment")
                            const t = (m.to(ms)(formData.serviceTimeEnd))        
                            setSuccess(true)
                            setProcessing(false)
                            //Move to dispatch
                            firestore.collection("appointments").add({
                                clientEmail: userData.email,
                                clientName: userData.firstName + " " + userData.lastName,
                                clientPhone: clientNumE164,
                                clientUID: userData.userId,
                                clientRating: 'n/a',
                                clientAvatar: userData.images.avatar,
                                clientPets: formData.petTypes,
                                clientNotes: formData.notes,
                                clientStatus: userData.accountStatus,
                                clientStripeId: userData.customerId,
                                proEmail: formData.pro.email,
                                proNumber: proNumE164,
                                proName: formData.pro.name,
                                proUID: formData.pro.userId,
                                proAvatar: formData.pro.avatar,
                                // proPaidOut:false,
                                proStripeId: formData.pro.stripeId,
                                service: formData.service,
                                serviceFee: formData.serviceCost,
                                serviceFeeUpdate: formData.priceVaries,
                                serviceFeeTravel: formData.travelFee,
                                serviceFeeBooking: formData.bookingFee,
                                serviceComplete: false,
                                serviceCanceled: false,
                                serviceTimeReq: new Date(formData.dateTime),
                                serviceTimeStart: "n/a",
                                serviceTimeEnd: new Date(parseFloat(t.join()) + new Date(formData.dateTime).getTime()),
                                serviceLocation: formData.address,
                                serviceCoordinates: formData.startingCoordinates,
                                serviceRating: "n/a",
                                amtPaid: deposit,
                                amtRemainingBalance: balance,
                                messages: [],
                                tip:'',
                                //serviceType:??
        
                            })
                            .then(() => {
                                firestore.collection('professionals').doc(formData.pro.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .then(() => {
                                firestore.collection('customers').doc(userData.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });
                            setSuccess(true)
                            setProcessing(false)
                        }
                    }catch (error){
                        console.log("Oops, there's an issue:", error)
                    }
                }
            } else {
                console.log("Error:", error)
                setCheckoutError(error.message)
                setFail(true)
            }
        }
        else if(reqDeposit === false){
            if(!error) {
                if(userData.test === false){
                    console.log("Hello, Im not a test account")
                    try{
                        const {id} = paymentMethod
                        const response =  await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment', {
                            amount: deposit * 100,
                            id,
                            description: `Cuti App: ${service} w/ ${formData.pro.name} (DEPOSIT)`,
                            customer: userData.customerId,
                            email: userData.email,
                            proStripeId: formData.pro.stripeId,
                            fee: depositAppFee
                        })

                        if(response.data.success){
                            console.log("Successful Payment")
                            const t = (m.to(ms)(formData.serviceTimeEnd))        
                            setSuccess(true)
                            setProcessing(false)
                            //Move to dispatch
                            firestore.collection("appointments").add({
                                clientEmail: userData.email,
                                clientName: userData.firstName + " " + userData.lastName,
                                clientPhone: clientNumE164,
                                clientUID: userData.userId,
                                clientRating: 'n/a',
                                clientAvatar: userData.images.avatar,
                                clientPets: formData.petTypes,
                                clientNotes: formData.notes,
                                clientStatus: userData.accountStatus,
                                clientStripeId: userData.customerId,
                                proEmail: formData.pro.email,
                                proNumber: proNumE164,
                                proName: formData.pro.name,
                                proUID: formData.pro.userId,
                                proAvatar: formData.pro.avatar,
                                // proPaidOut:false,
                                proStripeId: formData.pro.stripeId,
                                service: formData.service,
                                serviceFee: formData.serviceCost,
                                serviceFeeUpdate: formData.priceVaries,
                                serviceFeeTravel: formData.travelFee,
                                serviceFeeBooking: formData.bookingFee,
                                serviceComplete: false,
                                serviceCanceled: false,
                                serviceTimeReq: new Date(formData.dateTime),
                                serviceTimeStart: "n/a",
                                serviceTimeEnd: new Date(parseFloat(t.join()) + new Date(formData.dateTime).getTime()),
                                serviceLocation: formData.address,
                                serviceCoordinates: formData.startingCoordinates,
                                serviceRating: "n/a",
                                amtPaid: deposit,
                                amtRemainingBalance: balance,
                                messages: [],
                                tip:'',
                                //serviceType:??
        
                            })
                            .then(() => {
                                firestore.collection('professionals').doc(formData.pro.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .then(() => {
                                firestore.collection('customers').doc(userData.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });
                            setSuccess(true)
                            setProcessing(false)
                        }
                    }catch (error){
                        console.log("Oops, there's an issue:", error)
                    }
                }
                else if(userData.test === true){
                    console.log("Hello, Im a test account")
                    try{
                        const {id} = paymentMethod
                        const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment-test', {
                            amount: deposit * 100,
                            id,
                            description: `Cuti App: ${service} w/ ${formData.pro.name} (DEPOSIT)`,
                            customer: userData.customerId,
                            email: userData.email,
                            proStripeId: formData.pro.stripeId,
                            fee: depositAppFee
                        })

                        if(response.data.success){
                            console.log("Successful Payment")
                            const t = (m.to(ms)(formData.serviceTimeEnd))        
                            setSuccess(true)
                            setProcessing(false)
                            //Move to dispatch
                            firestore.collection("appointments").add({
                                clientEmail: userData.email,
                                clientName: userData.firstName + " " + userData.lastName,
                                clientPhone: clientNumE164,
                                clientUID: userData.userId,
                                clientRating: 'n/a',
                                clientAvatar: userData.images.avatar,
                                clientPets: formData.petTypes,
                                clientNotes: formData.notes,
                                clientStatus: userData.accountStatus,
                                clientStripeId: userData.customerId,
                                proEmail: formData.pro.email,
                                proNumber: proNumE164,
                                proName: formData.pro.name,
                                proUID: formData.pro.userId,
                                proAvatar: formData.pro.avatar,
                                // proPaidOut:false,
                                proStripeId: formData.pro.stripeId,
                                service: formData.service,
                                serviceFee: formData.serviceCost,
                                serviceFeeUpdate: formData.priceVaries,
                                serviceFeeTravel: formData.travelFee,
                                serviceFeeBooking: formData.bookingFee,
                                serviceComplete: false,
                                serviceCanceled: false,
                                serviceTimeReq: new Date(formData.dateTime),
                                serviceTimeStart: "n/a",
                                serviceTimeEnd: new Date(parseFloat(t.join()) + new Date(formData.dateTime).getTime()),
                                serviceLocation: formData.address,
                                serviceCoordinates: formData.startingCoordinates,
                                serviceRating: "n/a",
                                amtPaid: deposit,
                                amtRemainingBalance: balance,
                                messages: [],
                                tip:'',
                                //serviceType:??
        
                            })
                            .then(() => {
                                firestore.collection('professionals').doc(formData.pro.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .then(() => {
                                firestore.collection('customers').doc(userData.userId)
                                .update({
                                    apptsScheduled: firebase.firestore.FieldValue.increment(1)
                                })
                            })
                            .catch((error) => {
                                console.error("Error writing document: ", error);
                            });
                            setSuccess(true)
                            setProcessing(false)
                        }
                    }catch (error){
                        console.log("Oops, there's an issue:", error)
                    }
                }
        } else {
            setCheckoutError(error.message)
            setProcessing(false)
            setFail(true)
        }
        }
        
    }

    return (
        <Grid container item xs={12} style={{borderRadius: 12, border:'1px solid #e0e0e0', position: 'relative', marginTop:'20px', height: 510, boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)', textAlign: 'left'}}>
            <Grid item xs={12} style={{borderRadius: '8px 8px 0 0', background: '#413e68', height: "50px"}}>
                <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Schedule A Service</Typography>
            </Grid>

            <Grid container item xs={12} style={{ padding:'0px 20px'}}>
                <Typography variant='h6' style={{color:"#413e68"}}>7. Checkout</Typography>

    
                <form onSubmit={handlePayDeposit} style={{padding:10}}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} md={6} className={classes.inputContainer}>
                            <InputBase placeholder='Full Name' variant="outlined" className={classes.input} fullWidth defaultValue={name} />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.inputContainer}>
                            <InputBase placeholder='Email' variant="outlined" className={classes.input} fullWidth defaultValue={email} /> 
                        </Grid>

                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='Steet Address' variant="outlined" className={classes.input} fullWidth defaultValue={street} />
                        </Grid>
                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='City' variant="outlined" className={classes.input} fullWidth defaultValue={city} />
                        </Grid>

                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='State' variant="outlined" className={classes.input} fullWidth defaultValue={state} />
                        </Grid>
                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='Zip' variant="outlined" className={classes.input} fullWidth defaultValue={zip} />
                        </Grid>
                        <Grid item xs={12} className={classes.inputContainer}>
                            <div className={classes.input} style={{width: 'inherit',  padding: '10px', margin: '0px 0px 0px 6px', border:'1px solid, grey', borderRadius: 6}}>
                                <CardElement options={cardElementOptions}/>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} style={{display:'flex', justifyContent: 'center'}}>
                        
                        {!!isProcessing ?
                            <div>
                                <CircularProgress style={{color: '#413e68'}}/>
                                <Typography>Processing...</Typography>
                            </div>
                            :
                            
                            <Button
                                type='submit'
                                variant="contained"
                                className={classes.btn}
                                fullWidth
                                style={{ backgroundColor: '#413e68', color: 'white'}}
                                >
                                Pay Deposit Now: ${deposit}
                            </Button>
                            
                        }

                        {paymentRequest && <PaymentRequestButtonElement options={paymentRequest}/>}
                    
                    </Grid>
                </form>
                
            </Grid>

            <Snackbar
                open={success}
                autoHideDuration={2000}
                // onClose={()=> {setSuccess(false); window.location.reload();}}
                onClose={()=> {setSuccess(false); navigation.next();}}
                message="Success! Your appointment was booked 🎉"
            />
            <Snackbar
                open={fail}
                autoHideDuration={2000}
                // onClose={()=> {setSuccess(false); window.location.reload();}}
                onClose={()=> {setFail(false); setCheckoutError(null);}}
                message={checkoutError}
            />



    </Grid>
  );
};
