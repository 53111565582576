import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, useRouteMatch, NavLink, Link, useParams} from "react-router-dom";
import { Grid, Typography, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from '../../../config/fbConfig';
import Carousel from "react-simply-carousel";
import Geocode from "react-geocode";


const useStyles = makeStyles((theme) => ({
    service: {
        position: "relative",
        height: '220px', borderRadius: '25px', border: '10px solid white', 
        boxSizing: 'border-box',
        backgroundSize: 'cover', backgroundPosition: 'center',
        "&:hover": {
            background: "#efefef",
            backgroundSize: '400px', backgroundPosition: 'center',
            opacity: '0.5'
          },
    },
    overlay: {
        width: '100%', height: "100%", 
        background: "linear-gradient(rgba(255,255,255,.2), rgba(0,0,0, .72) 110%)", 
        borderRadius: '18px'
    },
    serviceTitle: {
        position: 'absolute',
        bottom: 15,
        width: "100%",
        textAlign: "center",
        color: "white",
        fontWeight: "bold",
    },
    view:{
        marginTop:10,
        fontSize:14,
        color:'#413e68',
        '&:hover':{
            cursor:'pointer'
        },
    },

      }));

function PartnersSection({user}) {

    const classes = useStyles();
    const margin = !!user ? 50 : null
    const [coordinates, setCoordinates] = useState([{lat:null, lng:null}]);
    const [loading, setLoading] = useState(true)
    const [partners, setPartners] = useState([])
    const [activeSlide, setActiveSlide] = useState(0);



    useEffect(() => {
        var docRef = firestore.collection("brandAms")
    
        if(partners.length === 0 && loading===true){
            docRef.get().then((querySnapshot) => {
                setPartners(querySnapshot.docs.map(doc => doc.data()))
                })
                .then(()=> setLoading(false))
                .catch((error) => {
                // console.log("Error getting document:", error);
            });
        }else{
            partners.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1)
            // filteredServices.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1)
        }
    
        
    
        console.log('partners:', partners)
        console.log('loading:', loading)
    
      }, [partners, loading])

  return (
    <Grid container style={{background:"white", marginLeft:margin, padding:15}}>

        <Grid item xs={false} md={2} xl={3} ></Grid>
        <Grid item container direction="column" alignItems="center" xs={12} md={8} xl={6} style={{paddingTop: '30px', paddingBottom: '30px'}}>
            <Grid item>
                <Typography variant='h6' style={{ textAlign: 'center', marginTop: "40px"}}>Introducing BizzyBook!<br/>We're an on-demand appointment booking marketplace that connects industry professionals to people in need, in real-time.</Typography>
            
                <Typography variant="h5" color="initial" style={{marginTop: "40px", fontWeight: "bold"}}>Our Partners</Typography>


                {/* <Grid item container xs={12} style={{padding:20}}>
                    {partners.length !== 0 &&
                    <Carousel
                        // updateOnItemClick
                        centerMode={false}
                        disableNavIfAllVisible={true}
                        hideNavIfAllVisible={true}
                        containerProps={{
                        style: {
                            width: "100%",
                            // justifyContent: "center",
                            justifyContent: "left",
                            position:'relative'
                        }
                        }}
                        activeSlideIndex={activeSlide}
                        activeSlideProps={{style: {}}}
                        onRequestChange={setActiveSlide}
                        forwardBtnProps={{
                        show: false,
                        children: ">", 
                        style: {color:`#413e68`, fontSize:20, fontWeight:'bold', position:'absolute', right:'-20px', width: 40, height: 40, 
                        minWidth: 40, alignSelf: "center", borderRadius:25, zIndex:10, boxShadow:'2px 3px 4px #0000008c', background:'white', border:'1px solid grey'}
                        }}
                        backwardBtnProps={{
                        show: false,
                        children: "<",
                        style: { color:`#413e68`, fontSize:20, fontWeight:'bold', position:'absolute', left:'-20px', width: 40, height: 40, 
                        minWidth: 40, alignSelf: "center", borderRadius:25, zIndex:10, boxShadow:'2px 3px 4px #0000008c', background:'white', border:'1px solid grey'}
                        }}
                        itemsToScroll={1}
                        infinite={false}
                        speed={400}
                    >
                        {partners.map((partner, index) =>{ 
                        const name = partner.name
                        const img = partner.themeHeader
                        const url = partner.url
                        return (
                        <Grid 
                        component={NavLink} to={`/brand-ambassador/${url}`}
                        style={{ 
                            width: 300, height: 200, textAlign: "center", boxSizing: "border-box", position:'relative',
                            border:`10px solid white`, display:'flex', alignItems:'center', flexFlow:'column'}}
                            key={index}
                            >
                            <img src={img} style={{width:'100%',objectFit: 'cover', borderRadius:20}}/>
                            <Typography style={{position:'absolute', bottom:0, color:'white', fontWeight:'bold', zIndex:1}}>{name}</Typography>

                        </Grid>
                        )})
                        }
                    </Carousel>
                    }
                </Grid> */}

                

                <Typography variant="h5" color="initial" className={classes.view} onClick={()=> window.open('https://forms.gle/c5PBYsENKnY5F1uH6')}>Become a Brand Ambassador ></Typography>
            </Grid>
        </Grid>
        <Grid item xs={false} md={2} xl={3} ></Grid>

    </Grid>
  )
}

export default PartnersSection