import React, {useState} from "react";
import {Typography, Container, Button, Grid, IconButton} from "@material-ui/core";
import {parse} from 'papaparse';
import CloseIcon from '@mui/icons-material/Close';
import {firestore} from '../../../config/fbConfig'


export const Services = ({ formData, setForm, navigation }) => {
  const { services} = formData;
  const [booksy, setBooksy] = useState(false)
  const [styleSeat, setStyleSeat] = useState(false)
  const [highlighted, setHighlighted] = useState(false)
  const [importedServices, setImported] = useState([])

  const handleAddServies = () => {
    console.log(importedServices.length)
    if(styleSeat === true && importedServices.length!== 0){
        formData.services = importedServices.filter((service)=> service.name !== "").map(service => ({
            addOn: false,
            details: service.details,
            name: service.name.includes('&amp;') ? service.name.split('&amp;').join('&') : service.name,
            price: service.priceAndTime.includes("and up") ? service.priceAndTime.split(' and up for ')[0] : service.priceAndTime.split(' for ')[0].split(' ')[0],
            reqDeposit:false,
            time: service.priceAndTime.includes("and up") ? service.priceAndTime.split(' and up for ')[1].split(' ')[0] : service.priceAndTime.split(' for ')[1].split(' ')[0],
            serviceType: formData.serviceType,
            priceVaries: service.priceAndTime.includes("and up") ? true : false
          }))
          console.log(formData)
        }else if(booksy=== true  && importedServices.length!== 0){
        formData.services = importedServices.filter((service)=> service.name !== "").map(service => ({
            addOn: false,
            details: service.details,
            name: service.name.includes('&amp;') ? service.name.split('&amp;').join('&') : service.name,
            price: service.price.includes("+") ? service.price.split('+')[0] : service.price,
            reqDeposit:false,
            priceVaries: service.price.includes("+") ? true : false,
            time: service.time.includes('min') ? 
            service.time.includes('h') ?
            ((parseFloat(service.time.split('h ')[0])*60) + parseFloat(service.time.split('h ')[1].split('min')[0])).toString()
            :
            (parseFloat(service.time.split('min')[0])).toString()
            :
            ((parseFloat(service.time.split('h ')[0])*60)).toString(),
            serviceType: formData.serviceType
          }))
          console.log(formData)
    }else{
        console.log("no sservices added")
    }
    navigation.next()
}


  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center", display:'flex', flexFlow:'column', alignItems:'center'}}>
      <Typography variant='h4'>Import Servies (CSV)</Typography>

      {(booksy === false && styleSeat === false) &&
      <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
        <Button variant='contained' onClick={()=> setBooksy(!booksy)}>Import from Boosky</Button>
        <Button variant='contained' onClick={()=> setStyleSeat(!styleSeat)}>Import from StyleSeat</Button>
      </Grid>
      }

      {styleSeat===true &&
        <div style={{padding:'30px', width:'inherit', border:highlighted ? '2px solid green' : '1px solid gray', background: highlighted ? 'lightgreen' : 'lightgray'}}
            onDragOver={(e) =>{
                e.preventDefault()
            }}
            onDragEnter={(e) =>{
                setHighlighted(true)
            }}
            onDragLeave={(e) =>{
                setHighlighted(false)
            }}
            onDrop={(e) =>{
                e.preventDefault()
                setHighlighted(false)
                console.log(e.dataTransfer.files)
                Array.from(e.dataTransfer.files)
                .filter((file)=> file.type === 'text/csv')
                .forEach(async(file)=> {
                    const text = await file.text()
                    const result = parse(text, {header: true})
                    console.log(result)
                    setImported(existing => [...existing,...result.data])

                })
            }}
        >DRAG & DROP HERE</div>
      }
      {booksy===true &&
        <div style={{padding:'30px', width:'inherit', border:highlighted ? '2px solid green' : '1px solid gray', background: highlighted ? 'lightgreen' : 'lightgray'}}
            onDragOver={(e) =>{
                e.preventDefault()
            }}
            onDragEnter={(e) =>{
                setHighlighted(true)
            }}
            onDragLeave={(e) =>{
                setHighlighted(false)
            }}
            onDrop={(e) =>{
                e.preventDefault()
                setHighlighted(false)
                console.log(e.dataTransfer.files)
                Array.from(e.dataTransfer.files)
                .filter((file)=> file.type === 'text/csv')
                .forEach(async(file)=> {
                    const text = await file.text()
                    const result = parse(text, {header: true})
                    console.log(result)
                    setImported(existing => [...existing,...result.data])

                })
            }}
        >DROP HERE</div>
      }

      <Grid item container xs={12} style={{border:'1px solid gray', margin:10, maxHeight:300, overflowY:'scroll'}}>
            {styleSeat === true && importedServices.filter((service)=> service.name !== "").map((service, idx) => {
                return(
                <li key={idx} style={{listStyle:'none', margin:'5px 0px', background:'#ededed', position:'relative', textAlign:'left', width:'inherit', fontSize:'12px'}}>
                    <strong style={{fontSize:'16px'}}>{service.name.includes('&amp;') ? service.name.split('&amp;').join('&') : service.name}</strong> <br/> 
                    <strong>Price:</strong> {service.priceAndTime.includes(' and up for ') ? service.priceAndTime.split(' and up for ')[0] : service.priceAndTime.split(' for ')[0]}<br/> 
                    <strong>Time:</strong> {service.priceAndTime.includes(' and up for ') ? service.priceAndTime.split(' and up for ')[1].split(' ')[0] : service.priceAndTime.split(' for ')[1].split(' ')[0]}<br/> 
                    <strong>Details:</strong> {service.details}
                    <IconButton style={{position:'absolute', right:0, top:0}} onClick={()=>setImported(importedServices.filter((s)=> s.name !== service.name))}><CloseIcon/></IconButton> 
                </li>
                )
            })}
            {booksy === true && importedServices.filter((service)=> service.name !== "").map((service, idx) => {
                // const time = service.time
                // const hrs = parseFloat(time.split('h ')[0]) * 60
                // const mins = parseFloat(time.split('h ')[1].split('min')[0])
                // const totalTime = (hrs+mins).toString()
                return(
                <li key={idx} style={{listStyle:'none', margin:'5px 0px', background:'#ededed', position:'relative', textAlign:'left', width:'inherit', fontSize:'12px'}}>
                    <strong style={{fontSize:'16px'}}>{service.name}</strong> <br/> 
                    <strong>Price:</strong> {service.price}<br/> 
                    <strong>Time:</strong> {service.time.includes('min') ? 
                    service.time.includes('h') ?
                    ((parseFloat(service.time.split('h ')[0])*60) + parseFloat(service.time.split('h ')[1].split('min')[0])).toString()
                    :
                    (parseFloat(service.time.split('min')[0])).toString()
                    :
                    ((parseFloat(service.time.split('h ')[0])*60)).toString()
                }<br/> 
                    <strong>Details:</strong> {service.details}
                    <IconButton style={{position:'absolute', right:0, top:0}}><CloseIcon/></IconButton> 
                </li>
                )
            })}

      </Grid>

      <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={(handleAddServies)}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};