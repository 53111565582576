import React, {useState} from 'react'
import {Grid, Typography, Avatar, Modal, Button, IconButton, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear'
import { firestore } from '../../../config/fbConfig';

function Footer({designData, uid, edit, brandAm, classes, footerPaddingLeft, handleContactModalOpen}) {
  const {footer, theme} = designData
  const [compactView, setCompactView] = useState(footer.compactFooter)
  const [backgroundColor, setBackgroundColor] = useState(footer.backgroundColor)
  const [displayContact, setDisplayContact] = useState(footer.displayContactInfo)
  const [fontColor, setFontColor] = useState(footer.fontColor)
  const [modalOpen, setModalOpen] = useState(false)
  const [availability, setAvailability] = useState(brandAm.availability)
  const [hrsModalOpen, setHrsModalOpen] = useState(false)
  const [socialFB, setSocialFB] = useState(brandAm.socialFB)
  const [socialIG, setSocialIG] = useState(brandAm.socialIG)
  const [socialTW, setSocialTW] = useState(brandAm.socialTW)
  const [socialTT, setSocialTT] = useState(brandAm.socialTT)
  const label = compactView===false ? 'No': 'Yes'


  const handleModalOpen = () => {
    setModalOpen(!modalOpen)
}

  const handleHrsModalOpen = () => {
    setHrsModalOpen(!hrsModalOpen)
}

const handleCancel = () => {
    setCompactView(footer.compactFooter)
    setBackgroundColor(footer.backgroundColor)
    setDisplayContact(footer.displayContactInfo)
    setFontColor(footer.fontColor)
    handleModalOpen()
  }

const handleSave = () => {
  firestore.collection('brandAms').doc(uid).update({
      socialFB: socialFB,
      socialTW: socialTW,
      socialIG: socialIG,
      socialTT: socialTT,
      availability: availability
  }).then(()=> {
    firestore.collection('brandAms').doc(uid).collection('design').doc('designInfo').update({
      'footer.compactFooter': compactView,
      'footer.fontColor': fontColor,
      'footer.backgroundColor': backgroundColor,
      'footer.displayContactInfo': displayContact,
     }) 
  })
}
  
  return (
    <Grid item container xs={12} style={{padding:20, paddingLeft: footerPaddingLeft, backgroundColor:backgroundColor}}>

      {compactView === true ?
      <>
      <Grid item container xs={6} style={{display:'flex', justifyContent:'left', alignItems:'center'}}>
        <Typography style={{fontWeight:'bold', color:fontColor}}>Connect with us:</Typography>
        {brandAm.socialFB !== '' && 
          <Avatar className={classes.circBtn} style={{marginLeft:5, border:'2px solid white', backgroundColor:'#4267B2'}} onClick={()=>{window.open(`${brandAm.socialFB}`)}}>FB</Avatar>
        }
        {brandAm.socialIG !== '' && 
          <Avatar className={classes.circBtn} style={{marginLeft:5, border:'2px solid white', backgroundColor:'#FD1D1D'}} onClick={()=>{window.open(`${brandAm.socialIG}`)}}>IG</Avatar>
        }
        {brandAm.socialTW !== '' && 
          <Avatar className={classes.circBtn} style={{marginLeft:5, border:'2px solid white', backgroundColor:'#1DA1F2'}} onClick={()=>{window.open(`${brandAm.socialTW}`)}}>TW</Avatar>
        }
        {brandAm.socialTT !== '' && 
          <Avatar className={classes.circBtn} style={{marginLeft:5, border:'2px solid white', backgroundColor:'#14171A'}} onClick={()=>{window.open(`${brandAm.socialTT}`)}}>TT</Avatar>
        }
        {edit === true && <Avatar className={classes.circBtn} onClick={handleModalOpen}><EditIcon/></Avatar>
}
      </Grid>
      <Grid item container xs={6} style={{display:'flex', justifyContent:'right', alignItems:'center'}}>
        <Typography className={classes.circBtn} style={{fontWeight:'bold', color:theme.primary}} onClick={handleContactModalOpen}>Contact Us</Typography>
      </Grid>
      </>
      :
      <Grid>

      </Grid>
      }


            {/* -------------------------MODAL----------------------------- */}

            <Modal open={!!modalOpen} onClose={handleModalOpen}>
            <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

                <Grid item container justify='space-between' style={{backgroundColor: theme.primary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Edit Footer
                    </Typography>
                    <IconButton onClick={()=>setModalOpen(!modalOpen)} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>



                <Grid container item xs={12} style={{padding:'20px 20px 0px 20px'}}>
                    <TextField
                        label="FaceBook link"
                        placeholder={socialFB}
                        name="name"
                        value={socialFB}
                        onChange={(e)=> setSocialFB(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </Grid>
                <Grid container item xs={12} style={{padding:'0px 20px 0px 20px'}}>
                    <TextField
                        label="Instagram link"
                        placeholder={socialIG}
                        name="name"
                        value={socialIG}
                        onChange={(e)=> setSocialIG(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </Grid>
                <Grid container item xs={12} style={{padding:'0px 20px 0px 20px'}}>
                    <TextField
                        label="Twitter link"
                        placeholder={socialTW}
                        name="name"
                        value={socialTW}
                        onChange={(e)=> setSocialTW(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </Grid>
                <Grid container item xs={12} style={{padding:'0px 20px 0px 20px'}}>
                    <TextField
                        label="TikTok link"
                        placeholder={socialTT}
                        name="name"
                        value={socialTT}
                        onChange={(e)=> setSocialTT(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </Grid>



                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Text Color</Typography>
                        <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                            <input type='color' defaultValue={fontColor} value={fontColor} style={{border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                            <input type='text' defaultValue={fontColor} value={fontColor} style={{width:'100%', border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                        </span>
                </Grid>
                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Background Color</Typography>
                        <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                            <input type='color' value={backgroundColor} style={{border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                            <input type='text' value={backgroundColor} style={{width:'100%', border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                        </span>
                </Grid>


                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Hours</Typography>
                    <Button variant='contained' onClick={handleHrsModalOpen} name="split">Edit</Button> 
                </Grid>

                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Compact Footer</Typography>
                    <Button variant='contained' onClick={()=> setCompactView(!compactView)} name="split">{label}</Button> 
                </Grid>







                <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                    <Button
                    variant="contained"
                    style={{  
                    letterSpacing: '2px',marginRight: "1rem" }}
                    onClick={handleCancel}
                    >
                    Cancel
                    </Button>

                    <Button
                    style={{letterSpacing: '2px', backgroundColor:theme.primary, color:theme.secondary}}
                    variant="contained"
                    onClick={handleSave}
                    >
                    Save
                    </Button>
                </div>


                <Modal open={hrsModalOpen} onClose={handleHrsModalOpen}>
                  <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

                    <Grid item container justify='space-between' style={{backgroundColor: theme.primary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                        <Typography variant='h6' style={{padding: '10px'}}>
                            Hours Of Operation
                        </Typography>
                        <IconButton onClick={()=>setModalOpen(!modalOpen)} style={{color: 'white'}}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>





                  </Grid>


                </Modal>

            </Grid>

        </Modal>
  </Grid>
  )
}

export default Footer