import React, { Component } from 'react'
import PropTypes from 'prop-types';
import {Grid, withWidth} from '@material-ui/core'

//import Menu from './Menu'
import Home from './Home'
import HomeMobile from './mobile/HomeMobile'
import Appointments from './mobile/Appointments'
import Earnings from './Earnings'
import Favorites from './Favorites'
import Messages from './Messages'
import Shop from './Shop'
import ProProfile from './settings/ProProfile'

import {connect} from 'react-redux'
import {Switch, Route} from 'react-router-dom'
import {firestore} from '../../config/fbConfig'

class Dashboard extends Component {

    state = {
        services: [],
        amountOfServices: 0,
    }

    componentDidMount() {
        let arr = []
        firestore.collection('services').where("listOfServices", "!=", [])
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                arr = arr.concat(doc.data().listOfServices)
                this.setState({amountOfServices: arr.length, })
                this.setState({services: arr})
            });
        })
        .catch((error) => {
            // console.log("Error getting documents: ", error);
        })
        ;
    }
    
    render() {
        const {services, amountOfServices} = this.state
        const {user, userData, appointments, width} = this.props;
        return (
            <div className="dash container">
                {(width === 'md' || width === 'lg' || width === 'xl')&&
                <Grid container direction="column" style={{height: '92vh'}}>

                    <Grid container style={{height:'inherit', display: 'flex', alignItems:'center', justifyContent:'center'}}>
                        <Grid item xs={2}md={1}></Grid>
                        <Grid item xs={9}md={10} style={{display:'flex', justifyContent:'center'}}>
                            <Switch>
                                <Route exact path='/home' render={() => <Home currentUser={user} userData={userData} services={services} amount={amountOfServices} appointments={appointments}/>} />
                                <Route exact path='/home/messages' render={() => <Messages currentUser={user} userData={userData} appointments={appointments}/>} />
                                <Route exact path='/home/earnings' render={() => <Earnings currentUser={user} userData={userData} appointments={appointments}/>} />
                                <Route exact path='/home/shop' component={Shop} />
                                <Route exact path='/home/favorites' render={() => <Favorites currentUser={user} userData={userData} amount={amountOfServices}/>} />
                                <Route exact path="/home/profile" render={() => <ProProfile currentUser={user} userData={userData} />} />
                            </Switch>        
                        </Grid>
                        <Grid item xs={1}md={1}></Grid>
                    </Grid>

                </Grid>
                }
                {(width === 'sm' || width === 'xs') &&
                <Grid container direction="column" style={{height: '92vh', marginBottom:60}}>
                    <Grid container style={{height:'inherit', display: 'flex', alignItems:'start', justifyContent:'center'}}>
                        <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                            <Switch>   
                                <Route exact path='/home' render={() => <HomeMobile currentUser={user} userData={userData} services={services} amount={amountOfServices} appointments={appointments}/>} />
                                <Route exact path='/home/appointments' render={() => <Appointments currentUser={user} userData={userData} services={services} amount={amountOfServices} appointments={appointments}/>} />
                                <Route exact path='/home/messages' render={() => <Messages currentUser={user} userData={userData} appointments={appointments}/>} />
                                <Route exact path='/home/earnings' render={() => <Earnings currentUser={user} userData={userData} appointments={appointments}/>} />
                                <Route exact path='/home/shop' component={Shop} />
                                <Route exact path='/home/favorites' render={() => <Favorites currentUser={user} userData={userData} amount={amountOfServices}/>} />
                                <Route exact path="/home/profile" render={() => <ProProfile currentUser={user} userData={userData} />} />
                            </Switch>        
                        </Grid>
                    </Grid>

                </Grid>
                }
            </div>
        )
    }
}

const mapState = (state) => ({
    appointments: state.appt.appointments
  })

Dashboard.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(Dashboard)
