import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, FormControl, InputLabel, MenuItem, Select, Modal, Avatar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { set } from 'date-fns';
import FavoriteProCard from '../dashboard/favorites/FavoriteProCard';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import { filterDependencies } from 'mathjs';




function NavSearch(props) {
    const {open, handleOpen, service, options, setValue, CssTextField, classes, userData, width} = props
    const [province, setProvince] = useState('');
    const [rating, setRating] = useState('');
    const [activePros, setActivePros] = useState([]);
    const [pros, setPros] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filtered, setFiltered] = useState(true)
    var prosRef = firestore.collection("professionals");
    var query = prosRef.where("accountStatus", "==", 'active').where("state", "==", `${province}`);
 
    const [isBorderPink, setIsBorderPink] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    
    const avatarSize = (width === 'xs' || width === 'sm') ? 40 : 60
    const avatarPadding = (width === 'xs' || width === 'sm') ? 10 : null
    const titleSize = (width === 'xs' || width === 'sm') ? 10 : 14
    const textPadding = (width === 'xs' || width === 'sm') ? 10 : null
    const viewSize = (width === 'xs' || width === 'sm') ? 14 : 20
    const profileSize = (width === 'xs' || width === 'sm') ? 8 : 12


    useEffect(() => {
        
        if(province === "" && userData.address){
            setProvince(userData.address.split(', ').slice(-2, -1).join())
            setLoading(true)
        }

        if(province !== '' && service !== '' && loading === true){
            query.get().then((querySnapshot) => {
                setActivePros(querySnapshot.docs.map(doc => doc.data()))
                })
            .then(
                // console.log('these are the pros:', activePros)
            )
            .then(setLoading(false))
            .catch((error) => {
                // // console.log("Error getting documents: ", error);
            });
        
        }

        if(activePros.length !== 0 && loading === false){         
           const result = activePros.filter(pro =>{
             if(pro.services.some(s => s.name.toLowerCase().includes(service.toLowerCase()))){
               return pro
               }
             });
           setPros(result)
           setLoading(null)       
         }

    
    }, [userData, province, service, activePros, pros,loading, query, filtered])


    return (
        <Modal open={!!open} onClose={handleOpen}>  
            <Grid container item xs={10} sm={8} md={5} lg={4} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:10}}>
                {service !== '' ?
                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <Typography><span style={{fontWeight:'bold'}}>Service: </span> {service}</Typography>
                    <Button variant='contained' color='primary' onClick={()=> setValue('')}>Change</Button>
                </Grid>
                :
                <Grid item xs={12}>
                    <Autocomplete
                            style={{ width: '100%' }}
                            defaultValue={service}
                            options={options}
                            classes={{
                                option: classes.option,
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                                <div style={{width:'100%'}} onClick={()=> setValue(option.name)}>
                                <Typography>{option.name}</Typography>
                                <Typography style={{fontSize:12, color:'lightgray'}}>{option.typeOfService}</Typography>
                                </div>
                            )}
                            renderInput={(params) => (
                                <CssTextField
                                style={{marginTop:6, height: 45, backgroundColor:'white', borderRadius:40}}
                                {...params}
                                autoFocus
                                label="Search..."
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                                />
                            )}
                            />
                </Grid>
                }

                <Grid item container xs={12} style={{margin:'10px 0px'}}>
                    <Grid item xs={12}>
                        <Typography>Filters:</Typography>
                    </Grid>

                    <Grid item xs={4} style={{padding:'0px 5px'}}>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel id="province">Location</InputLabel>
                            <Select
                            labelId="province"
                            id="province"
                            value={province}
                            onChange={(e) => {setProvince(e.target.value); setFiltered(false)}}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {statesUS.map((state, idx) => {
                                return <MenuItem key={state} value={state}>{state}</MenuItem>
                            })}
                            </Select>
                        </FormControl>
                    </Grid> 

                    {/* <Grid item xs={4} style={{padding:'0px 5px'}}>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel id="rating">Rating</InputLabel>
                            <Select
                            labelId="rating"
                            id="rating"
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid> */}

                    <Grid item xs={4} style={{padding:'0px 5px', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                        <Button disabled={filtered} variant='contained' color='primary' style={{height:50}} onClick={() => {setLoading(true); setFiltered(true)}}>Filter</Button>
                    </Grid> 
                </Grid>

                <Grid item container xs={12} style={{display:'flex', alignItems:'center'}}>
                    <Typography style={{fontWeight:'bold'}}>Professionals:</Typography>
                </Grid>

                <Grid container item xs={12} style={{height:300, margin: '0px 20px', padding: 5, justifyContent:'center'}}>
                    {(activePros.length <= 0 || pros.length <= 0 ) ? 
                    <Grid item container xs={12} md={10}>
                        <Typography style={{textAlign:'center', width:'100%'}}><span role="img" aria-label="Idk">🤷‍♀️</span>  Oops, Looks like we don't provide services in that area at this time!</Typography>
                        <Typography style={{textAlign:'center', width:'100%'}}>Try choosing another service or location! <span role="img" aria-label="ThumbsUp"> 👍</span></Typography>
                        {/* <Button fullWidth variant='contained' color='primary' onClick={() => window.location.reload()}>Request Service in my Area</Button> */}
                    </Grid> 
                    :
                    <SimpleBarReact autoHide={false} style={{maxHeight: 300, width: 'inherit', padding:'0px 15px 5px 5px'}}>
                        {pros.map((pro,idx) => {
                            // const haversine_m = haversine(formData.startingCoordinates, pro.coordinates)
                            // const distance = (haversine_m / 1609.344).toFixed(2)
                            // const rating = (pro.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / pro.ratings.length).toFixed(2)
                            const name = pro.firstName + " " + pro.lastName.charAt(0) + "."
                            const handleClick = () => {
                                //history.push(`/pro/profile/${pro.url}`)
                                window.location.assign(`/pro/profile/${pro.url}`)
                              }
                            return(
                                <Grid container item xs={12} className={classes.root} style={{border: "1px solid grey", borderRadius:12}}>
                                  <Grid item xs={2} style={{margin:'auto', display:'flex', justifyContent:'center', paddingLeft: avatarPadding}}>
                                    {pro.images &&
                                    <Avatar src={pro.images.avatar} style={{width:avatarSize, height:avatarSize}}/>
                                    }
                                    </Grid>
                                    <Grid item xs={8} md={7} style={{margin:'auto', paddingLeft:textPadding}}>
                                    <Typography style={{fontWeight: 'bold'}}>{name}</Typography>
                                    <Typography style={{fontSize:titleSize}}>{pro.title}</Typography>
                                    </Grid>

                                    <Grid item xs={2} md={3} onClick={handleClick} className={classes.vpBtn}>
                                        <Typography style={{fontSize: viewSize, fontWeight:'bold', color:"#413e68"}}>VIEW</Typography>
                                        <Typography style={{fontSize: profileSize, fontWeight:'bold', color:"#413e68"}}>PROFILE</Typography>
                                    </Grid>  
                                </Grid>
                            )
                        })}
                    </SimpleBarReact>
                    }
                </Grid>
            </Grid>
            
       
        </Modal>
    )
}

NavSearch.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };
  
  export default withWidth()(NavSearch)


const statesUS = [ 'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN',
            'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA',
            'WI', 'WV', 'WY']
