import React, {useState, useRef, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Avatar, IconButton, Modal, TextField, TextareaAutosize, Snackbar, CircularProgress} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';

import Slider from '@material-ui/core/Slider'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from './canvasUtils'
import Cropper from 'react-easy-crop'


const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    profileContainer:{
      position: 'relative',
      display:"flex",
      flexFlow:'column',
      alignItems:'center',
      padding: 10,
      marginBottom: 12,
    },
    avatar: {
      height: 140,
      width: 140,
    },
    imgSelecter:{
      position:'absolute',
      top: 120,
      left: '52%',
      border: '2px solid white',
      backgroundColor: theme.palette.primary.main,
      '&:hover':{
        cursor:'pointer',
        opacity: .8,
      },
    },
      accordianDetails:{
        width:'100%',
        display:'flex',
        flexFlow:'column',
        textAlign: 'left'
      },
      accordianEditBtn:{
        alignSelf:'flex-end'
      },
      wrapper: {
        height: '100%',
        width: 'fit-content',
        position: 'relative',
      },
      buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
          height: 400,
        },
      },
      cropButton: {
        flexShrink: 0,
        marginLeft: 16,
      },
      controls: {
        width:'100%',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
      },
      sliderLabel: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 65,
        },
      },
      slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
          margin: '0 16px',
        },
      },
  
  }));

function EditAccountModal(props) {
    const {modalOpen, setModalOpen, userData} = props
    const {firstName, lastName, title, bio, images, email, phone, displayName} = userData
    const classes = useStyles()
    const [fName, setFirstName] = useState(firstName)
    const [lName, setLastName] = useState(lastName)
    const [dName, setDisplayName] = useState(displayName)
    const [number, setPhone] = useState(phone)
    const [biography, setBio] = useState(bio)
    const [proTitle, setTitle] = useState(title)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openErr, setOpenErr] = useState(false)
    const fileInput = useRef();
    const [imageLoading, setImageLoading] = useState(false);
    const [avatar, setAvatar] = useState('')
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const disabled = (fName === "" || lName==="" || number==="" || dName==="" || proTitle==="") ? true : false
    const severity = ["error", "success"]

    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const uploadCroppedImage = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(
          imageSrc,
          croppedAreaPixels,
          rotation
        )

        var xhr = new XMLHttpRequest();
        xhr.open('GET', `${croppedImage}`, true);
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
          if (this.status === 200) {
            var myBlob = this.response;
            // myBlob is now the blob that the object URL pointed to.
              
                let fileurl = ''
                var uploadTask = storage.ref(`CustomerInfo/${email}-${firstName}-${lastName}/images/avatar/avatar`).put(myBlob)
                uploadTask.on('state_changed', 
                  (snapshot) => {
                    //Handle progress...
                    setImageLoading(true)
                  }, 
                  (error) => {
                    // Handle unsuccessful uploads...
                  }, 
                  async () => {
                    // Handle successful uploads on complete...
                    await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
                      fileurl = url
                      // console.log('File available at', fileurl)
                      setAvatar(url)
                      setImageLoading(false)
                      closeModal()
                    })
                    .then(() => {
                      var userRef = firestore.collection(userData.collection).doc(userData.userId)
                      return userRef.update({
                          "images.avatar": fileurl, 
                          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                      })
                      .then(() => {
                          setOpenSuccess(!openSuccess)
                      })
                      .catch((error) => {
                          console.error("Error updating document: ", error);
                          setOpenErr(!openErr)
                      });
                    });
                  }
                )
              
          }
        };
        xhr.send();

        // console.log(croppedImage)
        setCroppedImage(croppedImage)


      } catch (e) {
        console.error(e)
      }
    }, [imageSrc, croppedAreaPixels, rotation, email, userData, firstName, lastName, openErr, openSuccess])

    const onFileChange = async (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0]
        let imageDataUrl = await readFile(file)
  
        // apply rotation if needed
        const orientation = await getOrientation(file)
        const rotation = ORIENTATION_TO_ANGLE[orientation]
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
        }
  
        setImageSrc(imageDataUrl)
      }
    }
    
    
    const select = () => {
        fileInput.current.click()
      }   
    
      const closeModal = () => {
        setImageSrc(null)
      }
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSuccess(false);
        setOpenErr(false);
      };

    const handleSave = () => {
        var userRef = firestore.collection(userData.collection).doc(userData.userId);
        
        if(userData.collection === 'professionals'){
            return userRef.update({
                firstName: fName, lastName: lName, phone:number, bio: biography, title: proTitle, displayName: dName,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            })
			.then(()=> {
				if(userData.partner === true){
					const url = dName.replace(/[^\w ]/g, '').toLowerCase().split(" ")
		
						for( var i = 0; i < url.length; i++){ 
					
							if ( url[i] === "") { 
						
								url.splice(i, 1); 
						}
					}

					firestore.collection('brandAms').where("email", "==", `${userData.email}`)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							// doc.data() is never undefined for query doc snapshots
							firestore.collection('brandAms').doc(doc.id).update({
								url: url.join("-")
							})
						});
					})
					.catch((error) => {
						console.log("Error getting documents: ", error);
					});

				}else{
					console.log("moving along...")
				}
			})
            .then(() => {
                setOpenSuccess(!openSuccess)
            }).then(
                    () => {setTimeout(function(){setModalOpen(!modalOpen)}, 3500)}
            )
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
                setOpenErr(!openErr)
            })
        }
        else if(userData.collection === 'customers'){
          return userRef.update({
            firstName: fName, lastName: lName, phone:number,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
            setOpenSuccess(!openSuccess)
        }).then(
                () => {setTimeout(function(){setModalOpen(!modalOpen)}, 3500)}
        )
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
            setOpenErr(!openErr)
        })
        }
    }

    return (
        <Modal open={!!modalOpen}>
              
        <Grid container item xs={10} sm={6} md={4} lg={3} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', maxHeight:'90%'}}>
            <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Edit Account
                </Typography>
                <IconButton onClick={() => setModalOpen(!modalOpen)} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid container item xs={12} className={classes.profileContainer}>
                <input style={{display: 'none'}}
                type="file"
                id="image"
                name="image"
                onChange={onFileChange}
                ref={fileInput}
                accept=".gif,.jpg,.jpeg,.png"
                />
                    {images && 
                    <Avatar className={classes.avatar} src={images.avatar}/>
                    }
                    
                <Avatar className={classes.imgSelecter} onClick={select}>
                <AddAPhotoIcon/>
                </Avatar>

                {/* <Typography variant='h4'>{fName + " " + lName}</Typography>
                <Typography variant='h5'>{proTitle}</Typography> */}

            </Grid>

            <Grid item xs={12} container style={{padding:'0px 20px', maxHeight:'275px', overflowY:'auto'}}>

                <Grid item xs={6} style={{paddingRight: 10}}>
                    <TextField
                        label="First Name"
                        inputMode="text"
                        //onKeyPress={(e)=>{validateCC(e)}}
                        placeholder={fName}
                        name="fName"
                        value={fName}
                        onChange={(e)=> setFirstName(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </Grid>

                <Grid item xs={6} style={{paddingLeft: 10}}>
                    <TextField
                        label="Last Name"
                        inputMode="text"
                        //onKeyPress={(e)=>{validateCC(e)}}
                        placeholder={lName}
                        name="lName"
                        value={lName}
                        onChange={(e)=> setLastName(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </Grid>

                
                {userData.collection === 'professionals' &&
                <TextField
                    label="Display Name"
                    inputMode="text"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    placeholder={dName}
                    name="proTitle"
                    value={dName}
                    onChange={(e)=> setDisplayName(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
                }


                {userData.collection === 'professionals' &&
                <TextField
                    label="Title"
                    inputMode="text"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    placeholder={proTitle}
                    name="proTitle"
                    value={proTitle}
                    onChange={(e)=> setTitle(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
                }

                <TextField
                    label="Phone"
                    inputMode="tel"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    placeholder={number}
                    name="number"
                    value={number}
                    onChange={(e)=> setPhone(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />

                {userData.collection === 'professionals' &&
                <TextareaAutosize
                    label="About"
                    style={{width:'inherit', borderRadius:4, borderColor:'silver', padding:10, fontSize: 16, font:'inherit'}}
                    rowsMax={4}
                    rowsMin={4}
                    placeholder="Add a short Bio/About Us here"
                    value={biography}
                    onChange={(e)=> setBio(e.target.value)}
                
                />
                }
            </Grid>

            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                <Button
                variant="contained"
                style={{  
                letterSpacing: '2px',marginRight: "1rem" }}
                onClick={() => setModalOpen(!modalOpen)}
                >
                Cancel
                </Button>
                <Button
                disabled={disabled}
                style={{  
                letterSpacing: '2px', }}
                variant="contained"
                color='primary'
                onClick={handleSave}
                >
                Save
                </Button>
            </div>
            
            <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[1]}>
                    Account Updated Successfully! (you may need to refresh)
                </Alert>
            </Snackbar>
            <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[0]}>
                Oops, something went wrong...
                </Alert>
            </Snackbar>


            <Modal open={imageSrc !== null} onClose={closeModal}>
              <Grid container item xs={10} sm={6}
                style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:'30px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none',  maxHeight:'90%'}}>
                    <div style={{width:'inherit', height:'inherit', position:'relative'}}>
                      <div className={classes.cropContainer}>
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={1 / 1}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                    </div>
                    <div className={classes.controls}>
                      <div className={classes.sliderContainer}>
                        <Typography
                          variant="overline"
                          classes={{ root: classes.sliderLabel }}
                        >
                          Zoom
                        </Typography>
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          classes={{ root: classes.slider }}
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>
                      <div className={classes.sliderContainer}>
                        <Typography
                          variant="overline"
                          classes={{ root: classes.sliderLabel }}
                        >
                          Rotation
                        </Typography>
                        <Slider
                          value={rotation}
                          min={0}
                          max={360}
                          step={1}
                          aria-labelledby="Rotation"
                          classes={{ root: classes.slider }}
                          onChange={(e, rotation) => setRotation(rotation)}
                        />
                      </div>
                    </div>
                      <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
                        <Button variant='contained' color='secondary' style={{fontWeight:'bold', marginTop:20}} onClick={closeModal}>Cancel</Button>
                        {imageLoading === false ?
                        <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} 
                        // onClick={()=>uploadImg(previewCanvasRef.current, croppedAreaPixels)}
                        onClick={uploadCroppedImage}
                        >Upload</Button>
                        :
                        <div className={classes.wrapper}>
                        <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} disabled>Uploading...</Button>
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      </div> 
                        }

                      </Grid>

                </Grid>

            </Modal>
        </Grid>


    </Modal>
    )
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export default EditAccountModal
