import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Grid, IconButton, Typography} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { firestore } from '../../../../config/fbConfig';
import firebase from 'firebase';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function BankDetails({userData}) {
    const {banking} = userData

    useEffect(() => {

    }, [banking])

    const displayAccounts = banking.map((account, idx) => {
        const num = account.accountNumber.toString()
        const numArr = num.split('')
        const last4 = numArr.length - 4
        numArr.splice(0, last4, "····")
        const hiddenAcctNum = numArr.join('')
        return(
            <Grid item xs={12} md={10} container key={hiddenAcctNum} style={{marginBottom:5}}>
                <Grid item xs ={2} style={{display:'flex', flexFlow:'column', alignItems:'center', justifyContent:'center', border:'1px solid #878787', borderRadius:'4px', background: "#d2e7ff"}}>
                    <AccountBalanceIcon style={{color:'rgba(82,152,227,1)'}}/>
                </Grid>

                <Grid item xs={9} container direction='column' style={{padding:'0px 10px'}}>
                    {account.nickName === "" ?
                        <Typography variant="body1" style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{account.name}</Typography>
                    :
                        <Typography variant="body1">{account.nickName}</Typography>
                    }
                    <Typography variant="subtitle2"><span>Acct #</span> {hiddenAcctNum}</Typography>
                </Grid>

                <Grid item xs ={1} style={{display:'flex', flexFlow:'column', alignItems:'center', justifyContent:'center'}}>
                    <ArrowForwardIosIcon/>
                </Grid>
            </Grid>
               )
           })


    return (
        <Grid item container xs={12} style={{display:'flex', flexFlow:'column', alignItems:'center'}}>
            <Typography variant="subtitle2" style={{color:'#878787', marginBottom:15}}>We keep your financial details secure so no one sees your sensitive info.</Typography>
            
            {displayAccounts}

        </Grid>
    )
}

export default BankDetails
