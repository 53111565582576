import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  withWidth,
  Slide,
  Button,
  Modal,
  IconButton,
  InputBase,
  Snackbar,
  Paper,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ScheduleAppointment } from "../home/scheduleAppointment/ScheduleAppointment";
import ProUpcomingAppointments from "../home/upcomingAppointments/ProUpcomingAppointments";
import UpcomingAppointments from "../home/upcomingAppointments/UpcomingAppointments";
import PreviousAppointments from "../home/previousAppointments/PreviousAppointments";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import EarningsQuickView from "../home/proEarnings/EarningsQuickView";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment, { weekdays } from "moment";
import AppointmentCard from "../../appointments/ProAppointmentCard";
import { firestore } from "../../../config/fbConfig";
import "../../../index.css";
import { Close, MobileScreenShare } from "@material-ui/icons";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SupportModal from "../../modals/SupportModal";
import EmailVerified from "../../modals/EmailVerified";

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  homeRoot: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    overflowY: "scroll",
    height: "92vh",
    borderTop: "solid 1px #d3d3d3",
    textAlign: "center",
    maxWidth: 1000,
  },
  paper: {
    margin: "10% auto",
    width: "80%",
    height: "70%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "8px",
  },
  dashSectionContainer: {
    height: "45%",
    padding: 10,
  },
  dashSection: {
    height: "100%",
    border: "1px solid lightgray",
    borderRadius: 12,
    padding: 10,
    textAlign: "center",
    display: "flex",
    //alignItems:'center',
  },
  section: {
    borderRadius: 12,
    border: "1px solid lightgray",
    padding: 10,
    marginBottom: 10,
    textDecoration: "none",
    color: "black",
    "&:hover": {
      cursor: "pointer",
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: "#e6e6e6",
    },
  },
}));

const HomeMobile = ({ currentUser, userData, services, amount }) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [loadingAppts, setLoadingAppts] = useState(null);
  const [toggled, setToggle] = useState(false);
  const [toggled2, setToggle2] = useState(false);
  const [showProTip, setShowProTip] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [nextAppt, setNextAppt] = useState(null);
  const value =
    userData.url !== null || userData.url !== undefined
      ? "www.bizzybook.app/pro/profile/" + userData.url
      : null;
  const [copied, setCopied] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [supportModalOpen, setSupportMoadlOpen] = useState(false);

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleShareModalOpen = () => {
    setShareModalOpen(!shareModalOpen);
  };

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleSupportModalOpen = () => {
    setSupportMoadlOpen(!supportModalOpen);
  };

  useEffect(() => {
    if (amount > 0) {
      setLoading(false);
    }
    if (userData) {
      if (userData.accountStatus === "onboarding") {
        history.push("continue-onboarding");
      }
    }
    if (currentUser === null) {
      history.push("/");
    }

    if (
      appointments.length === 0 &&
      loadingAppts === null &&
      userData.collection === "professionals"
    ) {
      setLoadingAppts(true);
      firestore
        .collection("appointments")
        .where("proUID", "==", `${userData.userId}`)
        .get()
        .then(async (querySnapshot) => {
          setAppointments(
            querySnapshot.docs.map((doc) => {
              return { docData: doc.data(), id: doc.id };
            })
          );
        })
        .catch((error) => {
          if (error) {
            alert("Error.mgs:", error);
          }
        });
    }

    if (
      appointments.length > 0 &&
      loadingAppts === true &&
      userData.collection === "professionals"
    ) {
      console.log("we are here");
      setUpcoming(
        appointments.filter(
          (x) =>
            x.docData.serviceCanceled === false &&
            x.docData.serviceComplete === false
        )
      );
      setLoadingAppts(false);
    }

    if (upcoming.length > 1 && userData.collection === "professionals") {
      setNextAppt(
        upcoming.sort(function (a, b) {
          return (
            new Date(a.docData.serviceTimeReq.seconds * 1000) -
            new Date(b.docData.serviceTimeReq.seconds * 1000)
          );
        })[0]
      );
    } else if (upcoming.length === 1) {
      setNextAppt(upcoming[0]);
    } else {
    }

    console.log(nextAppt);
    // console.log("This year will end:", moment(new Date()).endOf('year').toDate())
  }, [amount, userData, appointments, upcoming, nextAppt, loadingAppts]);

  return (
    <Container className={classes.homeRoot}>
      {/* PRO VIEW -------------------------------------------------------------------------------------------------------------------------
 ------------------------------------------------------------------------------------------------------------------------------------*/}
      {userData && userData.collection === "professionals" && (
        <Grid
          container
          item
          xs={12}
          direction="column"
          style={{ height: "fit-content" }}
        >
          <Grid container item xs={12}>
            {loading === true ? (
              <div
                style={{
                  display: "flex",
                  width: "inherit",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: "#413e68" }} />
              </div>
            ) : (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginBottom: 20,
                    overflowY: "scroll",
                    // paddingTop: 45,
                  }}
                >
                  <EarningsQuickView userData={userData} />
                  {currentUser.emailVerified === false && (
                    <EmailVerified currentUser={currentUser} />
                  )}

                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "left",
                      paddingLeft: 20,
                      marginTop: 20,
                      marginBottom: 10,
                    }}
                  >
                    Up Next
                  </Typography>

                  <Grid
                    item
                    container
                    xs={12}
                    style={{ padding: "0px 20px", position: "relative" }}
                  >
                    {nextAppt !== null &&
                      nextAppt.docData.messages.length === 0 &&
                      showProTip === true && (
                        <Grid
                          item
                          collection
                          xs={12}
                          id="pro-tip"
                          style={{
                            position: "absolute",
                            width: "90%",
                            top: -75,
                            backgroundColor: "lightgrey",
                            display: "flex",
                            flexDirection: "row",
                            padding: 7,
                            marginBottom: "-4px",
                            borderRadius: 12,
                            boxShadow: "4px 4px 6px -1px rgba(0,0,0,0.20)",
                          }}
                        >
                          <Typography style={{ fontSize: 14 }}>
                            <HelpOutlineIcon
                              style={{
                                verticalAlign: "middle",
                                fontSize: 20,
                                marginRight: 5,
                              }}
                            />
                            Pro tip: Reach out to{" "}
                            {nextAppt.docData.clientName.split(" ")[0]}. Pro's
                            who build meaningful connections see an increased
                            number of repeat customers!
                          </Typography>
                          <HighlightOffIcon
                            style={{ fontSize: 24 }}
                            onClick={() => setShowProTip(!showProTip)}
                          />
                        </Grid>
                      )}

                    {nextAppt !== null && (
                      <AppointmentCard
                        id={nextAppt.id}
                        appt={nextAppt}
                        userData={userData}
                      />
                    )}

                    {nextAppt === null && (
                      <Grid
                        container
                        item
                        xs={12}
                        style={{
                          height: 200,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "lightgrey",
                          borderRadius: 12,
                        }}
                      >
                        <Typography>
                          You don't have any upcoming appointments at this time.
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    style={{ marginTop: 20, padding: "0px 20px" }}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        borderTop: "1px solid lightgrey",
                        height: 40,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={handleShareModalOpen}
                      >
                        <MobileScreenShare
                          style={{ marginRight: 10, color: "#413e68" }}
                        />
                        <Typography>Share Your Page</Typography>
                      </div>
                      <ArrowForwardIosIcon style={{ color: "grey" }} />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        borderTop: "1px solid lightgrey",
                        height: 40,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => history.push("/ip/onboarding")}
                      >
                        <LiveTvIcon
                          style={{ marginRight: 10, color: "#413e68" }}
                        />
                        <Typography>How-To Videos</Typography>
                      </div>
                      <ArrowForwardIosIcon style={{ color: "grey" }} />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        borderTop: "1px solid lightgrey",
                        height: 40,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={handleSupportModalOpen}
                      >
                        <HelpOutlineIcon
                          style={{ marginRight: 10, color: "#413e68" }}
                        />
                        <Typography>Get Help</Typography>
                      </div>
                      <ArrowForwardIosIcon style={{ color: "grey" }} />
                    </Grid>
                  </Grid>
                  {/* <div style={{width:'100%', height:100}}></div> */}
                </Grid>
              </>
            )}
          </Grid>
          <Modal open={shareModalOpen} onClose={handleShareModalOpen}>
            <Grid
              item
              container
              xs={11}
              md={6}
              style={{
                position: "absolute",
                display: "flex",
                flexFlow: "column",
                outline: "none",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "12px",
                backgroundColor: "white",
              }}
            >
              <Grid
                item
                contianer
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 10px",
                }}
              >
                <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                  Share
                </Typography>
                <IconButton onClick={handleShareModalOpen}>
                  <Close />
                </IconButton>
              </Grid>

              <Grid item container xs={12} style={{ padding: 20 }}>
                <Grid item xs={12} container>
                  <CopyToClipboard
                    text={value}
                    onCopy={handleClick(TransitionUp)}
                  >
                    <Button variant="contained" color="primary" fullWidth>
                      Copy Link
                    </Button>
                  </CopyToClipboard>

                  <Snackbar
                    open={copied}
                    TransitionComponent={transition}
                    message="Copied to clipboard!"
                    key={transition ? transition.name : ""}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: "10px",
                  }}
                >
                  <FacebookShareButton
                    url={value}
                    quote={
                      "Hey I'm now on BizzyBook! Use the link to book with me today!"
                    }
                    hashtag={"#CutiApp #BizzyBook"}
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={value}
                    title={
                      "Hey I'm now on BizzyBook! Use the link to book with me today!"
                    }
                    hashtags={[
                      "BizzyBook",
                      "BeautyAndWellness",
                      "AppointmentsOnDemand",
                    ]}
                  >
                    <TwitterIcon size={40} round={true} />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={value}
                    summary={
                      "Hey I'm now on BizzyBook! Use the link to book with me today!"
                    }
                    source={"Bizzy Book"}
                  >
                    <LinkedinIcon size={40} round={true} />
                  </LinkedinShareButton>

                  <WhatsappShareButton
                    url={value}
                    title={
                      "Hey I'm now on BizzyBook! Use the link to book with me today!"
                    }
                    separator={" --> "}
                    hashtag={"#CutiApp #BizzyBook"}
                  >
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                </Grid>
              </Grid>
            </Grid>
          </Modal>

          <SupportModal
            modalOpen={supportModalOpen}
            handleModalOpen={handleSupportModalOpen}
          />
        </Grid>
      )}

      {/* CUSTOMER VIEW --------------------------------------------------------------------------------------------------------------------
 ------------------------------------------------------------------------------------------------------------------------------------*/}

      {userData && userData.collection === "customers" && (
        <Grid container item xs={12} style={{ height: "100%" }}>
          {currentUser.emailVerified === false && (
            <EmailVerified currentUser={currentUser} />
          )}
          {/* <Grid container item xs={12} style={{backgroundColor:'lightgrey', height:'240px'}}>

                </Grid> */}
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              md={7}
              style={{
                marginBottom: 20,
                display: "flex",
                justifyContent: "center",
                padding: "0px 10px",
              }}
            >
              <Grid item>
                <ScheduleAppointment userData={userData} services={services} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* ADMIN VIEW ----------------------------------------------------------------------------------------------------------------------
 ------------------------------------------------------------------------------------------------------------------------------------*/}

      {userData && userData.collection === "admins" && (
        <Grid
          container
          item
          xs={12}
          direction="column"
          style={{ height: "100%" }}
        >
          <Grid container item xs={12}>
            <Grid
              item
              container
              xs={12}
              md={6}
              className={classes.dashSectionContainer}
            >
              <Grid item container xs={12} className={classes.dashSection}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: "bold", width: "100%" }}
                >
                  Users
                </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Grid
                    item
                    container
                    xs={11}
                    md={5}
                    className={classes.section}
                    component={Link}
                    to="/admin/users/pros"
                  >
                    <img
                      style={{ height: 24, marginRight: 5 }}
                      src="https://img.icons8.com/ios-filled/24/000000/caretaker.png"
                      alt=""
                    />
                    <Typography
                      style={{ fontWeight: "bold", lineHeight: "24px" }}
                    >
                      Pros
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={11}
                    md={5}
                    className={classes.section}
                    component={Link}
                    to="/admin/users/clients"
                  >
                    <img
                      style={{ height: 24, marginRight: 5 }}
                      src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png"
                      alt=""
                    />
                    <Typography
                      style={{ fontWeight: "bold", lineHeight: "24px" }}
                    >
                      Clients
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={11}
                    className={classes.section}
                    component={Link}
                    to="/admin/users/admins"
                  >
                    {/* <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/> */}
                    <Typography
                      style={{ fontWeight: "bold", lineHeight: "24px" }}
                    >
                      Admins
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={6}
              className={classes.dashSectionContainer}
            >
              <Grid item container xs={12} className={classes.dashSection}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: "bold", width: "100%" }}
                >
                  Appointments
                </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Grid
                    item
                    container
                    xs={11}
                    className={classes.section}
                    component={Link}
                    to="/admin/appointments"
                  >
                    {/* <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/> */}
                    <Typography
                      style={{ fontWeight: "bold", lineHeight: "24px" }}
                    >
                      Appointments
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={11}
                    className={classes.section}
                    component={Link}
                    to="/admin/services"
                  >
                    {/* <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/> */}
                    <Typography
                      style={{ fontWeight: "bold", lineHeight: "24px" }}
                    >
                      Services
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              className={classes.dashSectionContainer}
            >
              <Grid item container xs={12} className={classes.dashSection}>
                <Typography
                  variant="h4"
                  style={{ fontWeight: "bold", width: "100%" }}
                >
                  Support Chats
                </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Grid
                    item
                    container
                    xs={11}
                    className={classes.section}
                    component={Link}
                    to="/admin/chat/pros"
                  >
                    <img
                      style={{ height: 24, marginRight: 5 }}
                      src="https://img.icons8.com/ios-filled/24/000000/caretaker.png"
                      alt=""
                    />
                    <Typography
                      style={{ fontWeight: "bold", lineHeight: "24px" }}
                    >
                      Pros
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={11}
                    className={classes.section}
                    component={Link}
                    to="/admin/chat/clients"
                  >
                    <img
                      style={{ height: 24, marginRight: 5 }}
                      src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png"
                      alt=""
                    />
                    <Typography
                      style={{ fontWeight: "bold", lineHeight: "24px" }}
                    >
                      Clients
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {userData.role === "super admin" && (
              <Grid
                item
                container
                xs={12}
                md={6}
                className={classes.dashSectionContainer}
              >
                <Grid item container xs={12} className={classes.dashSection}>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: "bold", width: "100%" }}
                  >
                    Revenue
                  </Typography>
                  <Grid container item xs={6}>
                    <Typography style={{ width: "100%", textAlign: "left" }}>
                      At a glance:
                    </Typography>
                  </Grid>
                  <Grid container item xs={6}>
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Appts Booked: 1.2k
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "left",
                        fontSize: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Revenue YTD: $79.6k
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "right",
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Earnings: $6.2k
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        height={40}
                        data={data}
                        margin={{
                          top: 5,
                          right: 5,
                          left: 5,
                          bottom: 5,
                        }}
                        barSize={15}
                      >
                        <XAxis dataKey="name" padding={{ left: 2, right: 2 }} />
                        {/* <YAxis /> */}
                        <Tooltip />
                        <Bar dataKey="sales" stackId="a" fill="#413e68" />
                        <Bar dataKey="earnings" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="appts" fill="#ffc658" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                  <Grid contianer items xs={12}>
                    <Button variant="outlined" fullWidth>
                      View Details
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

HomeMobile.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

const data = [
  {
    name: "J",
    sales: 1200,
    earnings: 240,
    appts: 20,
  },
  {
    name: "F",
    sales: 3000,
    earnings: 600,
    appts: 58,
  },
  {
    name: "M",
    sales: 6500,
    earnings: 720,
    appts: 79,
  },
  {
    name: "A",
    sales: 7200,
    earnings: 1340,
    appts: 92,
  },
  {
    name: "M",
    sales: 6500,
    earnings: 800,
    appts: 88,
  },
  {
    name: "J",
    sales: 8800,
    earnings: 880,
    appts: 100,
  },
  {
    name: "J",
    sales: 7300,
    earnings: 940,
    appts: 98,
  },
  {
    name: "A",
    sales: 14320,
    earnings: 1430,
    appts: 140,
  },
  {
    name: "S",
    sales: 12562,
    earnings: 2300,
    appts: 132,
  },
  {
    name: "O",
    sales: 7823,
    earnings: 600,
    appts: 89,
  },
  {
    name: "N",
    sales: 15022,
    earnings: 2140,
    appts: 144,
  },
  {
    name: "D",
    sales: 8302,
    earnings: 800,
    appts: 98,
  },
];

export default withWidth()(HomeMobile);
