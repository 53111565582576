import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Grid, Button, Snackbar, Tooltip, TextField, FormGroup, FormControlLabel, Checkbox, TextareaAutosize, Switch, Typography, Avatar, FormControl, IconButton, InputLabel, MenuItem, Select, withWidth, Modal,makeStyles, AccordionSummary, Accordion, AccordionDetails} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { DataGrid } from '@material-ui/data-grid';
import firebase, { firestore } from '../../config/fbConfig';
const { ms, s, m, h, d } = require('time-convert')


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    copyText:{
        "&:hover":{
            color: theme.palette.primary.main,
            cursor:'pointer',
        }
    },
	addAnother:{
        width:'100%',
        textAlign:'center',
        fontSize:14,
        '&:hover':{
          cursor:'pointer',
          fontSize:13,
          color: theme.palette.primary.main,
          textDecoration:'underline'
        },
      },
	  avatar:{
		height:'80px',
		width:'100%',
		'&:hover':{
			cursor:'pointer',
		  },
	  }
  }));


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

function AdminUserInfoModal({modalOpen, handleClose, handleDelete, user, userType, loading, setLoading}) {
    const classes = useStyles()
    const [status, setStatus] = useState("");
    const [apptClicked, setClicked] = useState(false);
	const [expand, setExpand] = useState(false)
	const [addServiceModal, setAddServiceModal] = useState(false)

	//service state
	const [allServices, setAllServices] = useState([])
    const [filteredServices, setFilteredServices] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [category, setCategory] = useState('')
    const [serviceName, setServiceName] = useState('')
    const [hours, setHours] = useState('')
    const [mins, setMins] = useState('')
    const [price, setPrice] = useState('')
    const [details, setDetails] = useState('')
	const [pricesVary, setPricesVary] = useState(false)
    const [virtual, setVirtual] = useState(false)
    const [zoomLink, setZoomLink] = useState("")
    const [reasonInvalid, setReasonInvalid] = useState("")
	const [checked, setChecked] = useState(true)
	const [saveCliked, setSaveClicked] = useState(false)
	const [containsProfaninty, setContainsProfanity] = useState(false) 


	//
	const [openSuccess, setOpenSuccess] = useState(false)
    const [openErr, setOpenErr] = useState(false)
	const severity = ["error", "success"]

	
	const switchLabel = (checked === true) ? "Yes" : "No"
	const categories = ["Brow & Lashes", "Facials & Skincare", "Hair", "Health & Wellness","Home & Car Care", "Makeup", "Massage", "Nails","Pet Care", "Tattoo & Piercing", "Waxing Services", "Other"]
	const disabledAddNew = ((price === "" || hours === "" || mins === "" || serviceName === "" || containsProfaninty === true) || saveCliked===true || reasonInvalid.length !== 0 || (virtual === true && zoomLink.length === 0)) ? true : false


	const handleCloseAlert = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpenSuccess(false);
		setOpenErr(false);
		setSaveClicked(true)
	  };

    const numsOnly = (e) => {
        const re = /[0-9]+/g;
        const reg = /[0-9.]+/g;
        if(e.target.name !== 'price'){
          if (!re.test(e.key)) {
            e.preventDefault();
          }
          if(e.target.value.length >= 2){
            e.preventDefault()
          }
        }else{
          if (!reg.test(e.key)) {
            e.preventDefault();
          }
        }
      }

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

	const toggleChecked = () => {
		setChecked(!checked)
	  }

	  const handleAddAnotherCliked = (event, reason) => {
		setCategory('')
		setServiceName('')
		setHours('')
		setMins('')
		setPrice('')
		setDetails('')
		setPricesVary(false)
		setSaveClicked(false)
	  };

	const handleAddNewService = () => {
		const time = parseFloat((h.to(m)(parseFloat(hours)))) + (parseFloat(mins))
        
          firestore.collection('professionals').doc(`${user.userId}`).update({
            services: firebase.firestore.FieldValue.arrayUnion({
              name: serviceName, serviceType: category, 
              time: time, 
              price: price, 
              details: details,
              reqDeposit: checked,
              addOn:false,
              priceVaries: pricesVary,
              virtual: virtual,
              zoomLink: zoomLink,
            })     
          })
        .then(() => {
          setOpenSuccess(true)
          // window.location.reload()
        })
        .catch((error) => {
          // The document probably doesn't exist.
          // console.error("Error updating document: ", error);
          setOpenErr(true)
        });
	}

    const handleSave = (event) => {
        setLoading(!loading)
        firestore.collection(user.collection).doc(user.userId)
           .update({
               accountStatus: status
           })
    };

    useEffect(() => {
       if(user){
           setStatus(user.accountStatus)
       }
    }, [user])
  
    return (
        <>
        {user &&
        <Modal open={modalOpen} onClose={handleClose}>
                <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        {userType} Details
                    </Typography>
                    <IconButton onClick={handleClose} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
                    
                <Grid container item xs={12} style={{padding:20}}>

                    <Grid container item xs={12} style={{display:'flex', alignItems:'center', justifyContent:'center', flexFlow:'row', borderBottom:'1px solid lightgray', marginBottom:20}}>
                        <Grid item container style={{display:'flex', justifyContent:'center', alignItems:'center', flex:'1 0 100px', padding:10}}>

                            {user.images && <Avatar src={user.images.avatar} className={classes.avatar} onClick={()=>{user.collection === 'professionals' && window.open(`/pro/profile/${user.url}`)}}></Avatar>}
                        </Grid>

                        <Grid item container style={{display:'flex', flex:'0 1 auto'}}>
                            <Typography style={{width:'100%', fontWeight:'bold'}}>{user.firstName + " " + user.lastName}</Typography> 
                            <Typography style={{width:'100%'}}>Email: {user.email}</Typography>  
                            {user.collection==='professionals' &&
                            <Typography style={{width:'100%'}}><span>{user.title + " | "}</span>({parseFloat(user.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / user.ratings.length).toFixed(2)})</Typography>
                            }            
                            <Typography style={{width:'100%'}}>Account Status: {status}</Typography>
                        </Grid>
                    </Grid>


                    {(user.derogatoryMarks && user.derogatoryMarks.length > 0) &&
                    <Typography style={{width:'100%', fontWeight:'bold'}}>Derogatory Marks: ({user.derogatoryMarks.length})</Typography>
                    }

                    {(user.derogatoryMarks && user.derogatoryMarks.length > 0)  &&
                    user.derogatoryMarks.map((dm, idx) => {
                        return(
                            <Grid item container xs={12} key={idx+1}>
                                <Grid item container xs={12} style={{padding:'0px 20px 10px'}}>
                                    <Typography style={{width:'100%'}}>Mark: {dm.mark}</Typography>
                                    <Typography style={{width:'100%'}}>Severity: {dm.severity}</Typography>
                                    <Typography style={{width:'100%'}}>Appointment: <span className={classes.copyText} onClick={() => alert('appt ID copied to cliptray')}>{dm.appt}</span></Typography>
                                    <Typography style={{width:'100%', borderBottom:'1px solid lightgray'}}>Date: {new Date(dm.createdAt.seconds*1000).toLocaleDateString()}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })
                    }
                    
                    <Grid container item xs={12} style={{display:'flex', alignItems:'center'}}>
                        <Grid item container xs={6} style={{padding:'0px 5px 0px 0px'}}>
                            <FormControl variant="outlined" className={classes.formControl} style={{width:'inherit'}}>
                                <InputLabel id="demo-simple-select-outlined-label">Account Status</InputLabel>
                                <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={status}
                                onChange={handleChange}
                                label="Account Status"
                                fullWidth
                                >
                                {user.userId.includes('guestUser') && 
                                <MenuItem value={'guest'}>Guest</MenuItem>
                                }
                                {user.collection === 'professionals' &&
                                <>
                                <MenuItem value={'onboarding'}>Onboarding</MenuItem>
                                <MenuItem value={'needs review'}>Needs Review</MenuItem>
                                </>
                                }
                                <MenuItem value={'active'}>Active</MenuItem>
                                <MenuItem value={'not active'}>Not Active</MenuItem>
                                <MenuItem value={'paused'}>Paused</MenuItem>
                                <MenuItem value={'suspended'}>Suspended</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={6} style={{padding:'0px 0px 0px 5px', height:50}}>
                            <Button fullWidth variant='contained' style={{color:'red', backgroundColor:'white'}}>Reset Password</Button>
                        </Grid>
                    </Grid>

					<Grid container item xs={12}>
					{userType.toLowerCase() === 'pro' &&
						<Accordion expanded={expand} onChange={()=>setExpand(!expand)} style={{maxHeight:'50vh', overflowY:'scroll', width:'100%', marginBottom:20}}>
						<AccordionSummary
						  expandIcon={<ExpandMoreIcon />}
						  aria-controls="panel1bh-content"
						  id="panel1bh-header"
						>
						  <Typography style={{fontWeight:'bold'}}>Services ({user.services.length})</Typography>
						</AccordionSummary>
						<AccordionDetails style={{display:'flex', flexDirection:'column'}}>
							<Button variant='contained' color='primary' style={{margin:'20px 0px'}} onClick={()=> setAddServiceModal(!addServiceModal)}>Add A Service</Button>
							{user.services.map((service, idx) => {
								const {name, details, price, reqDeposit, time, addOn, serviceType} = service
								return (
									<Grid item container xs={12} style={{width:'100%', display:'flex', flexFlow:'row', padding:'20px 5px', borderRadius:12, border:'1px solid lightgrey', alignItems:'center', marginBottom:10}}>
										<Grid item container xs={8} style={{alignItems:'center', justifyContent:'space-between'}}>
											<Typography style={{fontWeight:'bold'}}>{(addOn!==undefined && addOn) && "+ "}{name} - {parseFloat(price) === 0 ? "FREE" : `$${price}`} 
											{(service.priceVaries !== undefined && service.priceVaries === true) && 
											<span style={{fontSize:12, fontWeight:'normal'}}>{' (and up)'}</span>}
											</Typography>
											<Typography style={{fontSize:12, color:'grey'}}>{serviceType} - {parseInt(time/60) === 0 ? time%60 + "mins" : parseInt(time/60) === 1 ? time%60 === 0 ? parseInt(time/60)+"hr" : parseInt(time/60)+"hr " + time%60 + "mins" : time%60 === 0 ? parseInt(time/60)+"hrs" : parseInt(time/60)+"hrs " + time%60 + "mins"}</Typography>
										</Grid>
										<Grid item container xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
											<div>
												<IconButton style={{height:35, width:35, backgroundColor:`lightgrey`, color:`white`}} 
												// onClick={()=> {setSelectedService(service); setPricesVary(service.priceVaries !== undefined ? service.priceVaries : false); handleModal2Open()}}
												><EditIcon/></IconButton>
												<IconButton style={{height:35, width:35, marginLeft:5, backgroundColor:`red`, color:`white`}} 
												// onClick={()=> {setSelectedService(service); handleRemoveService()}}
												><DeleteIcon/></IconButton>
											</div>
										</Grid>
									</Grid>
								)
							})}
							<Grid></Grid>
						</AccordionDetails>
					  </Accordion>
					}
					</Grid>

                    <Grid container item xs={12} style={{display:'flex', flexFlow:'row'}}>
                        <Grid item container style={{width:'fit-content'}}>
                            <Button color='error' onClick={() => handleDelete(user.userId)} style={{color:'red'}}>Delete</Button>
                        </Grid>

                        <Grid item container style={{flex:'0 1 auto', justifyContent:'flex-end'}}>
                            <Button variant='contained' onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' color='primary' onClick={handleSave} style={{marginLeft:10}}>Save</Button>
                        </Grid>
                    </Grid>

                </Grid>
                </Grid>


            </Modal>

            }
			<Modal open={!!addServiceModal} onClose={()=> setAddServiceModal(!addServiceModal)}>
				<Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
					<Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
						<Typography variant='h6' style={{padding: '10px'}}>
							{userType} Add A Service
						</Typography>
						<IconButton onClick={()=> setAddServiceModal(!addServiceModal)} style={{color: 'white'}}>
							<ClearIcon />
						</IconButton>
					</Grid>

					<Grid item xs={12} container style={{padding:20, maxHeight:'65vh', overflowY:'scroll'}}>
                  
                  <Grid item xs={12} className={classes.selectedServiceDetails}>
                    <Typography>Category & Name:</Typography>
                    <FormControl variant="outlined" style={{width:'100%'}}>
                      <InputLabel id="select-category">Category</InputLabel>
                      <Select
                        labelId="select-category"
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        label="Category"
                      >
                        <MenuItem value="">
                          <em>--Selected One--</em>
                        </MenuItem>
                        {categories.map((category, idx) => {
                          return (
                            <MenuItem key={idx} value={category}>{category}</MenuItem>
                          )}
                        )
                        }
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} className={classes.selectedServiceDetails}>
                    <TextField
                            label="Name of Service"
                            name="serviceName"
                            // defaultValue={Math.floor(selectedService.time/60)}
                            value={serviceName}
                            placeholder="ex: Haircut"
                            // onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setServiceName(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails}>
                    <Grid item xs={12} md={6}>
                      <Typography>Appointment Time:</Typography>
                    </Grid>

                    <Grid item container xs={12} md={6} style={{paddingRight:10}}>
                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Hrs"
                            name="hours"
                            // defaultValue='0'
                            value={hours}
                            placeholder="00"
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setHours(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>

                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Mins"
                            name="mins"
                            // defaultValue={selectedService.time - (Math.floor(selectedService.time/60)*60)}
                            // value={newMins}
                            placeholder="00"
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setMins(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={6}>
                      <Typography style={{width:'100%'}}>Price
                        <Tooltip title="Selecting the 'Varies' checkbox allows you to create a general service price, and update the price later after confirming exactly what your client needs.">
                          <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                          </Tooltip>:
                        </Typography>
                      <FormGroup>
                       <FormControlLabel
                       control={
                         <Checkbox
                           checked={pricesVary}
                           color='primary'
                           onChange={()=> setPricesVary(!pricesVary)}
                           icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                           checkedIcon={<CheckBoxIcon fontSize="small" />}
                           name="vary"
                         />
                       }
                       label="Varies"
                     />
                   </FormGroup>
                    </Grid>

                    <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center'}}>
                          <div style={{marginRight:10, fontSize:18}}>$</div>
                          {pricesVary === false ?
                          <TextField
                            label="Price"
                            name="price"
                            // defaultValue={selectedService.price}
                            value={price}
                            placeholder="100.00"
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setPrice(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                          :
                          <div style={{width:'inherit', display:'flex', flexFlow:'row', justifyContent:'space-evenly', alignItems:'center'}}>
                            <TextField
                              label="Price"
                              name="price"
                              style={{width:'70%'}}
                              // defaultValue={selectedService.price}
                              value={price}
                              placeholder="100.00"
                              onKeyPress={(e) => numsOnly(e)}
                              onChange={(e) => {setPrice(e.target.value)}}
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              required
                            />
                            <Typography style={{fontSize:10}}> and up</Typography>
                          </div>
                          }
                    </Grid>

                    <Typography style={{width:'inherit'}}>Details:</Typography>
                    <TextareaAutosize
                              style={{padding:5, fontSize: 16, font:'inherit', borderColor:'silver', borderRadius:4, width:'inherit'}}
                              rowsMax={3}
                              rowsMin={3}
                              label="details"
                              name="details"
                              // defaultValue={selectedService.details ? selectedService.details : ""}
                              value={details}
                              //placeholder={time[1]}
                              onChange={(e) => {setDetails(e.target.value)}}
                              
                            
                              required
                              fullWidth
                            />
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={6}>
                        <Typography>Require Deposit?
                          <Tooltip title="Require your customer to pay 25% down when booking.">
                            <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                            </Tooltip>
                        </Typography>
                      </Grid>

                      <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'flex-end', marginTop:20}}>
                        <FormControlLabel 
                            control={<Switch size="normal" color="primary" checked={checked} onChange={toggleChecked} />}
                            label={switchLabel}
                            />
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <FormGroup>
                          <FormControlLabel
                          control={
                            <Checkbox
                              checked={virtual}
                              color='primary'
                              onChange={()=> setVirtual(!virtual)}
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              name="vary"
                            />
                          }
                          label="Virtual Service"
                        />
                      </FormGroup>
                        </Grid>

                      {virtual === true &&
                        <Grid item container xs={12} md={6} style={{padding:'0px 10px', display:'flex', flexFlow:'column', alignItems:'center'}}>
                              <TextField
                                label="Zoom Link"
                                name="zoomlLink"
                                // defaultValue={selectedService.price}
                                value={zoomLink}
                                placeholder="https://us05web.zoom.us/j/{PERSONAL_METTING_ID}}"
                                onChange={(e) => {setZoomLink(e.target.value)}}
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                required
                                fullWidth
                              />

                              {reasonInvalid.length !== 0 &&
                              <Typography style={{width:'100%', color:'red', fontSize:14, lineHeight:'1',marginBottom:30}}>Invalid URL: {reasonInvalid}</Typography>
                              }
                        </Grid>
                      }



                  </Grid>



                  <Grid item xs={12} container style={{marginTop:20}}>
                    <Typography className={classes.addAnother} onClick={handleAddAnotherCliked}>Add another</Typography>
                  </Grid>

                  <Grid item xs={12} container className={classes.selectedServiceDetails} style={{marginBottom: 40}}>
                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" fullWidth onClick={()=>{window.location.reload()}}>Cancel</Button>
                    </Grid>

                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" color="primary" fullWidth onClick={handleAddNewService} disabled={disabledAddNew}>Save</Button>
                    </Grid>
                  </Grid>
      
                </Grid>

				<Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleCloseAlert} severity={severity[1]}>
                        {serviceName} has successfull been added!
                    </Alert>
                </Snackbar>
                
                <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleCloseAlert} severity={severity[0]}>
                    Oops, something went wrong...
                    </Alert>
                </Snackbar>
				</Grid>
			</Modal>
            </>
    )
}

export default AdminUserInfoModal
