import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import {Container, Grid, Typography, CircularProgress, withWidth, Hidden, Button} from '@material-ui/core'
import {ScheduleAppointment} from '../home/scheduleAppointment/ScheduleAppointment'
import ProUpcomingAppointments from '../home/upcomingAppointments/ProUpcomingAppointments';
import UpcomingAppointments from '../home/upcomingAppointments/UpcomingAppointments'
import PreviousAppointments from '../home/previousAppointments/PreviousAppointments'
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    homeRoot:{display:"flex",
      justifyContent:"center",
      flexDirection: "column",
      padding: "0",
      overflowY: "scroll",
      height: "92vh",
      borderTop: 'solid 1px #d3d3d3',
      textAlign: 'center',
      maxWidth: 1000 },
    paper: {
      margin: '10% auto',
      width: '80%',
      height: '70%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: '8px',
    },
    dashSectionContainer:{
      height: '45%',
      padding: 10,
    },
    dashSection:{
      height: '100%',
      border:'1px solid lightgray',
      borderRadius:12,
      padding: 10,
      textAlign:'center',
      display:'flex',
      //alignItems:'center',
    },
    section:{
      borderRadius: 12,
      border:'1px solid lightgray',
      padding:10,
      marginBottom: 10,
      textDecoration:'none',
      color: 'black',
      "&:hover":{
        cursor:'pointer',
        border:`2px solid ${theme.palette.primary.main}`,
        backgroundColor:'#e6e6e6',
      },
    },
  }));

function Appointments({currentUser, userData, services, amount, appointments}) {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [toggled, setToggle] = useState(false)

    useEffect(() => {
        if(amount > 0){
          setLoading(false)
        }
        if(userData){
          if(userData.accountStatus === 'onboarding'){
            history.push('continue-onboarding')
          }
        }
        if(currentUser === null){
            history.push('/')
          
        }
        // console.log("This year will end:", moment(new Date()).endOf('year').toDate())
      },[amount, userData, appointments])

  return (
    <Container className={classes.homeRoot}>
        {loading === true ?
        <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
            <CircularProgress style={{color: '#413e68'}}/>
        </div>
        : 
        <>
            {userData.collection === 'customers' &&
                <>
                    {toggled === false ? 
                    <Grid item xs={12} style={{padding:'0 10px', height: 460, margin:'20px 0px'}}>
                        <UpcomingAppointments userData={userData}/>
                    </Grid>
                    :
                    <Grid item xs={12} style={{padding:'0 10px', height: 460, margin:'20px 0px'}}>
                        <PreviousAppointments userData={userData}/>  
                    </Grid> 
                    }

                    {appointments.length === 0 ?
                        <Grid item xs={12} style={{padding:'0px 10px'}}>
                            <Button variant='contained' fullWidth style={{padding:10 }} component={Link} to='/home' >Schedule an Appointment</Button>
                        </Grid>
                        :
                        <>
                        {toggled === false ? 
                            <Grid item xs={12} style={{padding:'0px 10px'}}>
                                <Button variant='contained' fullWidth style={{padding:10 }}  onClick={()=> setToggle(!toggled)}>Previous Appointments</Button>
                            </Grid>
                        :
                            <Grid item xs={12} style={{padding:'0px 10px'}}>
                                <Button variant='contained' fullWidth style={{padding:10,}} color='primary' onClick={()=> setToggle(!toggled)}>Upcoming Appointments</Button>
                            </Grid>
                        }
                        </>
                    }
                </>
            }

            {userData.collection === 'professionals' &&
            <>
                <Grid container item xs={12} style={{padding:'0 10px', height: 460, margin:'20px 0px'}}>
                    <ProUpcomingAppointments userData={userData} appointments={appointments}/>
                </Grid>

                <Grid item xs={12} style={{padding:'0px 10px'}}>
                    <Button variant='contained' fullWidth style={{padding:10 }} component={Link} to='/settings/availability' >Update Calendar</Button>
                </Grid>
            </>
            }
            

        </>

        
        }
    </Container>
  )
}

export default Appointments