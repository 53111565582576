import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Typography, Grid, Button, Avatar, Modal, withWidth} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

//Imports for the Profressional Profile Preview
import CloseIcon from '@material-ui/icons/Close';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import StarIcon from '@material-ui/icons/Star';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import { NotInterested, Visibility } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
      border:'1px solid gray',
      height: 76,
      marginBottom: 5,
      background: 'white',
      
      },
      vpBtn:{
        margin:'auto',
        textAlign:'center',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      card:{
        height: 150,
        width: '100%',
        backgroundColor: 'lightgrey',
        borderRadius:14,
        marginBottom:10,
        display:'flex',
        flexDirection:'row',
        boxShadow:'2px 2px 6px grey',
        elevation:5,
        position:'relative'
    
      },
      profileImgContainer:{
        width:'40%',
        height:'100%',
        backgroundColor:'grey',
        borderTopLeftRadius:14,
        borderBottomLeftRadius:14
      },
      profileImg:{
        height:'100%',
        width:'100%',
        borderTopLeftRadius:14,
        borderBottomLeftRadius:14,
        objectFit:'cover',
    
      },
      profileInfoContainer:{
        width:'60%',
        height:'100%',
        backgroundColor:'white',
        borderTopRightRadius:14,
        borderBottomRightRadius:14,
        padding:10,
      },
      name:{
        fontSize:16,
        fontWeight:'bold'
      },
      icon:{
      position:'absolute',
      bottom:10,
      right:10
      },
      btn: {
        width:100,
        height: 46,
        marginTop: 20,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor: '#73c8fe',
        borderRadius: 25
      }

  }));
  


function FavoriteProCard({pro, width}) {

  const classes = useStyles();
  const history = useHistory()
 
  const [isBorderPink, setIsBorderPink] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const name = pro.firstName + " " + pro.lastName.charAt(0) + "."
  
  const avatarSize = (width === 'xs' || width === 'sm') ? 40 : 60
  const avatarPadding = (width === 'xs' || width === 'sm') ? 10 : null
  const titleSize = (width === 'xs' || width === 'sm') ? 10 : 14
  const textPadding = (width === 'xs' || width === 'sm') ? 10 : null
  const viewSize = (width === 'xs' || width === 'sm') ? 14 : 20
  const profileSize = (width === 'xs' || width === 'sm') ? 8 : 12


  const handleSelect = () => {
    // console.log("Hello World")
  }

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleClick = () => {
    history.push(`/pro/profile/${pro.url}`)
  }

  return (
      <Grid container item xs={12} className={classes.card} onClick={handleClick}>
        <div className={classes.profileImgContainer}>
          {pro.images &&
          <img src={pro.images.avatar} className={classes.profileImg} alt='' onClick={handleClick}/>
          }
        </div>
        <div className={classes.profileInfoContainer}>
          <Typography className={classes.name}>{name}</Typography>
          <Typography style={{fontSize:titleSize}}>{pro.title.split(' | ').length >= 4 ? pro.title.split(' | ').splice(1,3).join(" | ") + " & more" : pro.title}</Typography>

        </div>
        <Grid item xs={8} md={7} style={{margin:'auto', paddingLeft:textPadding}}>
        </Grid>

        <Visibility onClick={handleClick} className={classes.icon}>

        </Visibility>



        <Modal open={!!isModalOpen}>
          <Grid container style={{width: 845, height: 540, background: 'white', position: 'absolute', display: 'flex', flexFlow:'column',
                        top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius:'12px', outline:'none'}}>
            <Grid container item xs={12} style={{backgroundColor: '#413e68', flex:'0 1 160px', position:'relative', borderRadius:'12px 12px 0 0'}}>
                <CloseIcon style={{position:'absolute', right:12, top: 12, color:'white', cursor:'pointer'}} onClick={handleModalOpen}/>
                <Button variant='contained' style={{height:'46px', position:'absolute', right:12, bottom: 12}}> <FavoriteBorderIcon style={{verticalAlign:'middle'}}/> Add to Favorites</Button>
            </Grid>
            {pro.images  && 
            <Avatar src={pro.images.avatar} style={{height:160, width:160, position:'absolute', left:80, top: 80, border:'5px solid white'}}></Avatar>
            }

            <Grid container item xs={12} style={{flex: '1 0 auto', borderRadius:'0 0  12px 12px'}}>
              <Grid item xs={12} sm={6} md={5} style={{borderRight: '1px solid lightgray', padding: 16, textAlign:'center'}}>
                <Typography style={{fontSize:'20px', fontWeight:'bold', marginTop:'70px'}}>{pro.name}</Typography>
                <Typography style={{fontSize:'18px'}}>{pro.title}</Typography>
                <Typography style={{margin: "16px 0"}}><span style={{fontWeight:'bold'}}>0</span> Completed | Rating: 0.00 <StarIcon style={{verticalAlign: 'middle', fontSize:'16px', color: 'yellow'}}/> </Typography>
                <SimpleBarReact autoHide={false} style={{maxHeight: 100, width: 'inherit', padding:'0px 15px 5px 5px'}}>
                  <Typography>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore eum a inventore asperiores magni perspiciatis id voluptatem suscipit labore, eius eos fuga laborum voluptates vel perferendis ullam pariatur placeat. Totam. </Typography>
                </SimpleBarReact>
              </Grid>
              <Grid container item xs={12} sm={6} md={7} style={{padding:'16px'}}>

              </Grid>


            </Grid>
          </Grid>
        </Modal>
      </Grid>
  )
}

FavoriteProCard.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(FavoriteProCard)
