import React, {useState} from "react";
import {Typography, Grid, Button, TextArea, TextField} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

function AddOnServiceCard({formData, service, name, price, time, serviceType, classes}) {

    const [amt, setAmt] = useState(0)
    // const [amt, setAmt] = useState(0)

    const handleAdd = (service) => {
        setAmt(amt+1)
        formData.addOns.push(service)
        formData.addOnPrice = formData.addOnPrice + parseFloat(price)
        formData.serviceTimeEnd = formData.serviceTimeEnd + parseFloat(time)
        console.log(formData)
        console.log(formData.addOnPrice)
    }
    
    const handleSubtract = (service) => {
        setAmt(amt-1)
        formData.addOns.pop()
        formData.addOnPrice = (formData.addOnPrice - (parseFloat(price)))
        formData.serviceTimeEnd = formData.serviceTimeEnd - parseFloat(time)
        console.log(formData)
        console.log(formData.addOnPrice)
      }

  return (
    <Grid container item xs={12} style={{marginBottom:10, display:'flex', maxHeight:80, alignItems:'center', backgroundColor:'white', borderRadius:5, padding:8, border:'1px solid lightgray'}}>
        <Grid item contianer xs={8}>
        <Typography style={{fontWeight:'bold'}}>{name} - ${price}</Typography>
        <Typography style={{fontSize:12, color:'grey'}}>{serviceType} +{parseInt(time/60) === 0 ? time%60 + "mins" : parseInt(time/60) === 1 ? time%60 === 0 ? parseInt(time/60)+"hr" : parseInt(time/60)+"hr " + time%60 + "mins" : time%60 === 0 ? parseInt(time/60)+"hrs" : parseInt(time/60)+"hrs " + time%60 + "mins"}</Typography>
        </Grid>

        {(amt === 0) ?
        <Grid item container xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
        <Button onClick={(e) => {handleAdd(service)}} color='primary' variant='contained' style={{fontSize:12, fontWeight:'bold'}}>Add</Button>
        </Grid>
        :
        <Grid item container xs={4} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
        <RemoveIcon className={classes.iconCounter} onClick={(e) => {handleSubtract(service)}}/>
        <TextField variant='outlined' inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} style={{width:40}} value={amt}/>
        <AddIcon className={classes.iconCounter} onClick={(e)=> handleAdd(service)}/>
        </Grid>
        }

    </Grid>
  )
}

export default AddOnServiceCard