import React from 'react'
import {Container} from '@material-ui/core'
import {Switch, Route} from 'react-router-dom'
import Shop from './Shop';
import Users from './admin/Users';
import Appointments from './admin/Appointments';


function Settings({userData, currentUser}) {

    return (
        <Container style={{padding: "0", overflowY: "hidden scroll", height: "95vh", borderTop: 'solid 1px #d3d3d3' }}>
              <Switch>
                  {/* <Route exact path='/settings/general' render={() => <GeneralSettings userData={userData}/>} /> */}
                  <Route exact path='/admin/users/pros' render={() => <Users currentUser={currentUser} userData={userData}/>} />
                  <Route exact path='/admin/users/clients' render={() => <Users currentUser={currentUser} userData={userData}/>} />
                  <Route exact path='/admin/users/admins' render={() => <Users currentUser={currentUser} userData={userData}/>} />
                  <Route exact path='/admin/appointments' render={() => <Appointments currentUser={currentUser} userData={userData}/>} />
                  <Route exact path='/admin/services' render={() => <Appointments currentUser={currentUser} userData={userData}/>} />
                  <Route exact path='/admin/chat/pros' render={() => <Shop currentUser={currentUser} userData={userData}/>} />
                  <Route exact path='/admin/chat/clients' render={() => <Shop currentUser={currentUser} userData={userData}/>} />
                  {/* <Route exact path='/settings/payment-info' render={() => <Account userData={userData}/>} /> */}
              </Switch>        

        </Container>
    )
}

export default Settings
