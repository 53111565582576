import React from 'react'
import { BrowserRouter as Router, Switch, Route, useRouteMatch, NavLink, Link, useParams} from "react-router-dom";
import { Grid, Typography, Button} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ServiceSearch from '../ServiceSearch/ServiceSearch';

const useStyles = makeStyles((theme) => ({
    service: {
        position: "relative",
        height: '220px', borderRadius: '25px', border: '10px solid white', 
        boxSizing: 'border-box',
        backgroundSize: 'cover', backgroundPosition: 'center',
        "&:hover": {
            background: "#efefef",
            backgroundSize: '400px', backgroundPosition: 'center',
            opacity: '0.5'
          },
    },
    overlay: {
        width: '100%', height: "100%", 
        background: "linear-gradient(rgba(255,255,255,.2), rgba(0,0,0, .72) 110%)", 
        borderRadius: '18px'
    },
    serviceTitle: {
        position: 'absolute',
        bottom: 15,
        width: "100%",
        textAlign: "center",
        color: "white",
        fontWeight: "bold",
    },
    view:{
        marginTop:10,
        fontSize:14,
        color:'#413e68',
        '&:hover':{
            cursor:'pointer'
        },
    },

      }));


const InformationalSection = ({user}) => {

    const classes = useStyles();
    const margin = !!user ? 50 : null

    return (
            <Grid container style={{background:"white", marginLeft:margin, padding:15}}>

                <Grid item xs={false} md={2} xl={3} ></Grid>
                <Grid item container direction="column" alignItems="center" xs={12} md={8} xl={6} style={{paddingTop: '30px', paddingBottom: '30px'}}>
                    <Grid item style={{width:'100%'}}>
                        {/* <Typography variant='h6' style={{ textAlign: 'center', marginTop: "40px"}}>Introducing  
                            <span style={{fontFamily: 'sarina, cursive', color: '#413e68'}}> Cuti </span>  
                            <i> (kyoo·tee) </i>. We're an on-demand beauty & wellness services platform that connects industry professionals to people in need, in real-time.</Typography>
                 */}
                        <Typography variant="h5" color="initial" style={{marginTop: "40px", fontWeight: "bold"}}>Book a Service</Typography>
                        
                        <Grid container style={{marginTop: "10px"}}>

                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/nails'
                                style={{backgroundImage: 'url(http://st2.depositphotos.com/3261171/6011/i/450/depositphotos_60117565-stock-photo-client-and-manicurist-in-manicure.jpg)'}}>
                                <div className={classes.overlay}></div>
                                <Typography className={classes.serviceTitle}>Nails</Typography>
                            </Grid>

                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/hair'
                            style={{backgroundImage: 'url(https://i2.wp.com/cyclopsbrand.com/wp-content/uploads/2019/09/Screen-Shot-2019-09-13-at-4.22.02-PM.png?resize=598%2C550&ssl=1)',}}>
                                <div className={classes.overlay}></div>
                                <Typography className={classes.serviceTitle}>Hair</Typography>
                            </Grid>

                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/makeup'
                            style={{backgroundImage: 'url(https://tallypress.com/wp-content/uploads/2020/05/top-10-bridal-makeup-artists-johor-bahru.jpg)'}}>
                                <div className={classes.overlay}></div>
                                <Typography className={classes.serviceTitle} >Make Up</Typography>
                            </Grid>

                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/brow-lashes' 
                                style={{backgroundImage: 'url(https://alliedbeautyexperts.net/wp-content/uploads/2020/06/Eyelash-extensions-blog.png)'}}>
                                    <div className={classes.overlay}></div>
                                    <Typography className={classes.serviceTitle}>Brow & Lashes</Typography>

                            </Grid>

                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/facials' 
                                style={{backgroundImage: 'url(//cdn.shopify.com/s/files/1/0018/9556/3317/products/ODS_FT_1050x.png?v=1529954991)'}}>
                                    <div className={classes.overlay}></div>
                                    <Typography className={classes.serviceTitle}>Facials</Typography>
                            </Grid>


                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/massage' 
                                style={{backgroundImage: 'url(https://www.pennmedicine.org/-/media/images/miscellaneous/face%20and%20body/woman_receiving_massage.ashx)'}}>
                                    <div className={classes.overlay}></div>
                                    <Typography className={classes.serviceTitle}>Massages</Typography>
                            </Grid>

                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/waxing' 
                                style={{backgroundImage: 'url(https://www.americannailsco.com/wp-content/uploads/2017/06/waxing-service.jpg)'}}>  
                                    <div className={classes.overlay}></div>
                                    <Typography className={classes.serviceTitle}>Waxing Services</Typography>
                            </Grid>

                            <Grid container item xs={6} md={3} className={classes.service} component={NavLink} to='/services/health-fitness' 
                                style={{backgroundImage: 'url(https://www.nfpt.com/wp-content/uploads/in-home-training-setting-with-personal-trainer-400x267.jpg)'}}>
                                    <div className={classes.overlay}></div>
                                    <Typography className={classes.serviceTitle}>Heath & Fitness</Typography>
                            </Grid>

                        
                        
                        
                        </Grid>

                        <Typography variant="h5" color="initial" className={classes.view}>View all services ></Typography>
                    </Grid>
                </Grid>
                <Grid item xs={false} md={2} xl={3} ></Grid>

                <Grid item xs={false} md={2} xl={3}></Grid>
                    <Grid item container xs={12} md={8} xl={6} style={{padding:15, height:300, borderRadius:'12px', backgroundImage:"url('http://cen.acs.org/content/dam/cen/98/web/3/WEB/20200313lnp1-spray.jpg')", backgroundSize: 'cover', backgroundPosition: '0% 24%', marginBottom:100, display:'flex', alignItems:'center'}}>
                        <Typography variant='h4' style={{fontWeight:'bold', width:'inherit', color:'white', textShadow:'4px 4px 4px #0000009c'}}>Our COVID 19 Health & Safety Guidelines</Typography>
                        <Typography style={{width:'inherit', marginTop:'-30px'}}>We’ve provided guidelines so clients can book with peace of mind.</Typography>
                        <Button variant="contained" color='primary' component={Link} to='/ip/covid-19' style={{width:200, height: 40}}>Learn More</Button>
                    </Grid>
                <Grid item xs={false} md={2} xl={3}></Grid>

            </Grid>
    )
}

export default InformationalSection