import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from '../../../config/fbConfig';
import firebase from 'firebase';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    rootGrid: {
      display: 'flex',
      justifyContent:'center',
      minWidth:280,
    },
    sectionTitle:{
        fontSize: 24,
        fontWeight: 'bold',
        margin: "20px 0 10px",
    },
    sectionTitleSmall:{
        fontSize: 18,
        fontWeight: 'bold',
        margin: "20px 0 10px",
        width: 'inherit'
    },
    containerBox:{
        backgroundColor:'white',
        borderRadius:'8px',
        padding: 20,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexFlow:'column',
        position:'relative'
    },
    profilePic:{
        height:160,
        width:160
    }
    
  }));

function NotificationSettings(props) {
    const {width, userData} = props;
    const {notifSettings} = userData
    const classes = useStyles()
    const padding = width > 'md' ? '0px 10px 0px 60px' : 0
    const [loading, setLoading] = useState(true)
    const [notifications, setNotifications] =useState(null)

    useEffect(() => {
        if(notifSettings !== undefined && loading === true){
            setLoading(false)
            setNotifications({
                newApptAlertsText: notifSettings.newApptAlertsText, newApptAlertsEmail: notifSettings.newApptAlertsEmail, newApptAlertsPush: notifSettings.newApptAlertsPush,
                apptCompletedAlertsText: notifSettings.apptCompletedAlertsText, apptCompletedAlertsEmail: notifSettings.apptCompletedAlertsEmail, apptCompletedAlertsPush: notifSettings.apptCompletedAlertsPush,
                apptCanceledAlertsText: notifSettings.apptCanceledAlertsText, apptCanceledAlertsEmail: notifSettings.apptCanceledAlertsEmail, apptCanceledAlertsPush: notifSettings.apptCanceledAlertsPush,
                apptStartedAlertsText: notifSettings.apptStartedAlertsText, apptStartedAlertsEmail: notifSettings.apptStartedAlertsEmail, apptStartedAlertsPush: notifSettings.apptStartedAlertsPush,
                apptRescheduledAlertsText: notifSettings.apptRescheduledAlertsText, apptRescheduledAlertsEmail: notifSettings.apptRescheduledAlertsEmail, apptRescheduledAlertsPush: notifSettings.apptRescheduledAlertsPush,
                msgAlertsText: notifSettings.msgAlertsText, msgAlertsEmail: notifSettings.msgAlertsEmail, msgAlertsPush: notifSettings.msgAlertsPush,
            })
        }
        // firestore.collection("professionals").where("url", "==", pathName)
        // .onSnapshot((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         // doc.data() is never undefined for query doc snapshots
        //         setPro(doc.data());
        //     }, (error) => {
        //         // console.log("Error getting documents: ", error);
        //     }
            
        //     );
        // })
    },[notifSettings, loading])
    
      const handleChange = (event) => {
          // console.log(event.target.name)
       
        switch (event.target.name) {   
            case "newMessages":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.msgAlertsEmail': !notifSettings.msgAlertsEmail
                })
                // .then(()=> window.location.reload())
                break;
            case "newApptAlertsEmail":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.newApptAlertsEmail': !notifSettings.newApptAlertsEmail
                }).then(()=> window.location.reload())
                break;
            case "newApptAlertsText":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.newApptAlertsText': !notifSettings.newApptAlertsText
                }).then(()=> window.location.reload())
                break;
            case "apptStartedAlertsEmail":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.apptStartedAlertsEmail': !notifSettings.apptStartedAlertsEmail
                }).then(()=> window.location.reload())
                break;
        
            case "apptCompletedAlertsEmail":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.apptCompletedAlertsEmail': !notifSettings.apptCompletedAlertsEmail
                }).then(()=> window.location.reload())
                break;
            case "apptRescheduledAlertsEmail":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.apptRescheduledAlertsEmail': !notifSettings.apptRescheduledAlertsEmail
                }).then(()=> window.location.reload())
                break;
            case "apptRescheduledAlertsText":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.apptRescheduledAlertsText': !notifSettings.apptRescheduledAlertsText
                }).then(()=> window.location.reload())
                break;
            case "apptCanceledAlertsEmail":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.apptCanceledAlertsEmail': !notifSettings.apptCanceledAlertsEmail
                }).then(()=> window.location.reload())
                break;
            case "apptCanceledAlertsText":
                firestore.collection(userData.collection).doc(userData.userId).update({
                 'notifSettings.apptCanceledAlertsText': !notifSettings.apptCanceledAlertsText
                }).then(()=> window.location.reload())
                break;
        
            default:
                break;
        }

      };


    return (
        <Grid container item xs={12} style={{padding:'0px 10px', alignContent:'center'}} direction="column" >
            {notifications !== null &&
            <>

                <Grid item xs={12} md={8} container style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                    <Typography className={classes.sectionTitle}>Notification Settings</Typography>
                </Grid>

                
                <Grid item xs={12} md={8} style={{display:'flex', textAlign:'left', justifyContent:"space-between"}}>
                    <Typography className={classes.sectionTitleSmall}>Notifications</Typography>
                </Grid>
                
                
                <Grid item xs={12} md={8} container className={classes.containerBox}>
                    <Grid item container xs={12} md={10}>
                        {notifSettings && 
                        <Grid item container xs={12}>

                            <Grid item xs={12} style={{textAlign:'left'}}>
                                <Typography style={{fontWeight:'bold'}}>Messages</Typography>
                            </Grid>

                            <Grid item container xs={12} style={{paddingLeft:10, display:'flex', justifyContent:'space-between'}}>
                            <Typography>Get Notifications when you receive New Messages:</Typography>

                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Email Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.msgAlertsEmail}
                                        color="primary"
                                        onChange={handleChange}
                                        name="newMessages"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>

                            </Grid>




                            <Grid item xs={12} style={{textAlign:'left'}}>
                                <Typography style={{fontWeight:'bold'}}>Appointments</Typography>
                            </Grid>

                            <Grid item container xs={12} style={{paddingLeft:10, display:'flex', justifyContent:'space-between'}}>
                                <Typography>Get Notifications when New Appointements are Booked:</Typography>

                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Email Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.newApptAlertsEmail}
                                        color="primary"
                                        onChange={handleChange}
                                        name="newApptAlertsEmail"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Text Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.newApptAlertsText}
                                        color="primary"
                                        onChange={handleChange}
                                        name="newApptAlertsText"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>


                                <Typography style={{marginTop:30}}>Get Notifications your Appointement has Started:</Typography>

                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Email Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptStartedAlertsEmail}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptStartedAlertsEmail"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                {/* <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Text Notifictions</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptStartedAlertsText}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptStartedAlertsText"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid> */}


                                <Typography style={{marginTop:30}}>Get Notifications your Appointement has been Completed:</Typography>

                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Email Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptCompletedAlertsEmail}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptCompletedAlertsEmail"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                {/* <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Text Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptCompletedAlertsText}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptCompletedAlertsText"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid> */}


                                <Typography style={{marginTop:30}}>Get Notifications your Appointement has been Rescheduled:</Typography>

                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Email Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptRescheduledAlertsEmail}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptRescheduledAlertsEmail"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Text Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptRescheduledAlertsText}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptRescheduledAlertsText"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>


                                <Typography style={{marginTop:30}}>Get Notifications your Appointement has been Canceled:</Typography>

                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Email Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptCanceledAlertsEmail}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptCanceledAlertsEmail"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                                <Grid item xs={8} style={{textAlign:'left'}}>
                                    <Typography variant='caption'>Text Notifications</Typography>
                                </Grid>
                                <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Switch
                                        checked={notifications.apptCanceledAlertsText}
                                        color="primary"
                                        onChange={handleChange}
                                        name="apptCanceledAlertsText"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                </Grid>
                            </Grid>  
                        </Grid>
                        }
                        
                    
                    </Grid>
                </Grid>
            </>
            }
            
            
        </Grid>
    )
}

NotificationSettings.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };
  

export default withWidth()(NotificationSettings)
