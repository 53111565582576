import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Grid, Typography, FormControl, Button, FormControlLabel, FormLabel, Snackbar, RadioGroup, Radio, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {firestore} from '../../config/fbConfig'
import firebase from'firebase'


const useStyles = makeStyles((theme) => ({
    formOption: {
     borderBottom:'1px solid lightgray',
      marginTop: theme.spacing(1),
      width:'100%'
    },
  }))


const ReportModal = ({open, handleOpen, pro}) => {
    const classes = useStyles()
    const [value, setValue] = useState(null)

    const handleChange = (event) => {
        setValue(event.target.value);
      };

    const handleCancel = () => {
        setValue(null)
        handleOpen()
    }

    const handleSave = () => {
        firestore.collection('professionals').doc(pro.userId).update({
            // accountStatus:'suspended',
            derogatoryMarks: firebase.firestore.FieldValue.arrayUnion({
                mark: value,
                severity: 'high',
                appt: 'mark made on pro profile - requires investigation',
                createdAt: new Date(),
            })
        }).then(()=>{
            handleOpen()
            setValue(null)
        })
    }
    
  return (
    <Modal open={!!open} onClose={handleOpen}>
        <Grid container item xs={11} md={5} lg={4} display='column' style={{display:'flex', outline:'none', position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
            <Grid container item xs={12} style={{padding:10}}>

                <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <Typography component="h1" variant="h5">
                        Report Content
                    </Typography>
                    <IconButton onClick={handleCancel}><CloseIcon/></IconButton>
                </Grid>


                <FormControl component="fieldset" style={{marginTop:20, width:'inherit'}}>
                <RadioGroup value={value} onChange={handleChange}>
                    <FormControlLabel className={classes.formOption} value="Sexual Content" control={<Radio style={{color:'#413e68'}}/>} label="Sexual Content" />
                    <FormControlLabel className={classes.formOption} value="Violent or repulsive content" control={<Radio style={{color:'#413e68'}}/>} label="Violent or repulsive content" />
                    <FormControlLabel className={classes.formOption} value="Hateful or abusive content" control={<Radio style={{color:'#413e68'}}/>} label="Hateful or abusive content" />
                    <FormControlLabel className={classes.formOption} value="Harmful or dangerous acts" control={<Radio style={{color:'#413e68'}}/>} label="Harmful or dangerous acts" />
                    <FormControlLabel className={classes.formOption} value="Child abuse" control={<Radio style={{color:'#413e68'}}/>} label="Child abuse" />
                    <FormControlLabel className={classes.formOption} value="Infringes my rights" control={<Radio style={{color:'#413e68'}}/>} label="Infringes my rights" />
                    <FormControlLabel className={classes.formOption} value="Promotes terrorism" control={<Radio style={{color:'#413e68'}}/>} label="Promotes terrorism" />
                    <FormControlLabel value="Spam or misleading" control={<Radio style={{color:'#413e68'}}/>} label="Spam or misleading" />
                </RadioGroup>
                </FormControl>
            </Grid>

            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
              <Button variant="contained" style={{   letterSpacing: '2px',marginRight: "1rem" }} onClick={handleCancel}>
              Cancel
              </Button>

              <Button variant='contained' color='primary' style={{letterSpacing: '2px'}}  onClick={handleSave} 
                disabled={value===null}
              >
              Save
              </Button>
          </div>

    </Grid>
</Modal>
  )
}

export default ReportModal