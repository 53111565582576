import React from 'react'
import './Slider.css'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {IconButton} from "@material-ui/core";

function BtnSlider({direction, moveSlide}) {
    return (
        <IconButton className={direction ==='next' ? 'btn-slide next' : 'btn-slide prev'} style={{position:'absolute', zIndex:10, color:''}} onClick={moveSlide}>
            {direction === 'prev' ? <ArrowBackIosIcon style={{color:'white'}}/> : <ArrowBackIosIcon style={{color:'white', transform: 'rotate(180deg)'}}/>}
            
        </IconButton>
    )
}

export default BtnSlider
