import React, {useState, useEffect} from "react";
import {Typography, Container, TextField, Button, Paper, Tooltip, InputBase, MenuItem, CircularProgress} from "@material-ui/core";
import PlacesAutoComplete, {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-places-autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_KEY);
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();



const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    display: 'flex',
    height: 50,
    alignItems: 'center',
  },
  iconButton: {
    color: theme.palette.primary.main,
    fontSize: "40px",
    "&:hover":{
      cursor: 'pointer',
      opacity: '80%',
    },
  },
}));

export const Address = ({ formData, setForm, navigation }) => {
  const classes = useStyles();
  const [address, setAddress] = useState("");
  const [unformattedAddress, setUnformattedAddress] = useState([]);
  const [loading, setLoading] = useState(false)
  const [coordinates, setCoordinates] = useState([{lat:null, lng:null}]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const disabled = (address === "") ? true : false;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setLocation = () => {
    if(navigator.geolocation){
      setLoading(true)
      navigator.geolocation.getCurrentPosition(position => {
        setCoordinates({lat: position.coords.latitude, lng: position.coords.longitude})
      })
    }

  }

  useEffect(() => {
    if(coordinates !== {lat:null, lng:null} && formData.coordinates !== coordinates){
      // console.log('1')
      const {lat, lng} = coordinates
      Geocode.fromLatLng(`${lat}`, `${lng}`).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const addressArr = address.split(', ')
          const state = addressArr.slice(-2, -1).join('').split(' ').slice(0,1).join()
          if(addressArr.length === 4){
            addressArr.splice(2, 1, state)
            setUnformattedAddress(addressArr)
          }
          else if(addressArr.length === 5){
            addressArr.splice(3, 1, state)
            setUnformattedAddress(addressArr)
          }
          else if(addressArr.length === 6){
            addressArr.splice(4, 1, state)
            setUnformattedAddress(addressArr)
          }
        },
        (error) => {
          console.error(error);
        }
        );
      }
      
      if(unformattedAddress.length !== 0 && loading===true){
        // // console.log('2')
        let formatted = unformattedAddress.join(', ')
        setAddress(formatted)
      }
      
      if((address !== '') && (anchorEl !== null)){
        // // console.log('3?')
        formData.address = address
        formData.coordinates = coordinates
      }
      
      if(address !== '' && loading===true){
        // // console.log('3')
        setLoading(false)
        formData.address = address
        formData.coordinates = coordinates
      }
      

    // console.log(formData)
  }, [address, coordinates, anchorEl, formData.address, formData.coordinates, formData, unformattedAddress, loading])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (value) => {
    setAddress(value);
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)
    const latLng = await getLatLng(result[0])

    setAddress(value);
    setCoordinates(latLng)
    formData.address = value;
  };

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Address</Typography>
      
      {loading === true ? 
        <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
          <CircularProgress style={{color: '#413e68'}}/>
        </div> 
        :
        <PlacesAutoComplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            style={{width:'inherit'}}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{width:'inherit'}}>
                <Paper component="form" className={classes.input}>
                  
                    <InputBase aria-controls="simple-menu" onClick={handleClick}
                    {...getInputProps({
                      placeholder: 'Enter Your Address...',
                    })}
                    className={classes.input}
                  />
                  <Tooltip title='Use my location'>
                    <LocationOnIcon className={classes.iconButton} onClick={setLocation}/>
                  </Tooltip>
                </Paper>

                  <div id="simple-menu" anchorEl={anchorEl}>
                    {loading && <MenuItem>Loading...</MenuItem>}
                    {suggestions.map((suggestion,) => {
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#413e68",
                            color: "white",
                            cursor: "pointer",
                          }
                        : { backgroundColor: "white" };

                      return (
                        <MenuItem onClick={handleClose} key={suggestion.description} {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </MenuItem>
                      );
                    })}
                  </div>

              </div>
            )}
          </PlacesAutoComplete>
        }

      <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={() => navigation.next()}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};