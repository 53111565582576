import React from 'react'

export const theme = {
    primary: {dark: '#413e68', light:''},
    secondary: {dark: '#73c8fe', light:'#cdecff'},
    tertiary: {dark: '#f1cd65', light:''},
    grey: {dark: '#808080', light:'#D3D3D3'},
    black: '#000000',
    white: "#FFFFFF",
        error: '#d10000',
    success: '#00bc16',
  }