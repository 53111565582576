import React, {useEffect} from "react";
import {Typography, Container, TextField, Button} from "@material-ui/core";
import {firestore} from '../../../../config/fbConfig'


export const Contact = ({ formData, setForm, navigation }) => {
  const { phone, email, password } = formData;
  const [emailInUse, setEmailInUse]=React.useState()
  const [checked, setChecked] = React.useState()
  const [invalid, setInvalid]=React.useState(false)
  const disabled = ((phone === "" || email === "" || password === "") || invalid===true) ? true : false;
  const border = (invalid === true || emailInUse === true) ? '2px solid red' : 'none'

  const numsOnly = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const validEmail = (e) => {
    const re = /[a-z0-9@-_.+~]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const checkIfEmailInUse = () => {
    // console.log('checking...')
    setChecked(true)
    if(emailInUse === true){
      setEmailInUse(false)
    }
    if(email.includes("@") && email.includes(".")){
      firestore.collection('professionals').where("email", "==", `${email}`)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log('Email in use')
          setEmailInUse(true)
          // formData.password = ''
      });
    })
    }
    if(email.includes("@") && email.includes(".")){
      firestore.collection('customers').where("email", "==", `${email}`)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log('Email in use')
          setEmailInUse(true)
          // formData.password = ''
      });
    })
    }

    // console.log(emailInUse)
  }

  useEffect(() => {

    if(checked !== undefined){
      setChecked(false)
    }

    if(!email.includes("@") || !email.includes(".")) {
      setInvalid(true)
    }

    if(!email.includes("@") && email !== '') {
      setInvalid(true)
      setEmailInUse(false)
    }else{
      setInvalid(false)
    }

    if(checked === true && emailInUse === false && disabled === false && invalid === false){
      navigation.next()
    }

  }, [email, invalid, emailInUse, checked, disabled, navigation, formData])

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Contact</Typography>
      <TextField required
        label="Phone Number"
        name="phone"
        type="tel"
        value={phone}
        onKeyPress={(e) => numsOnly(e)}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="tel"
        fullWidth
      />
      <TextField required
        label="Email Address"
        name="email"
        id="email"
        value={email}
        onKeyPress={(e) => validEmail(e)}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="email"
        fullWidth
        style={{border:border}}
      />

      {emailInUse === true && 
      <Typography style={{color: "red"}}>Email is already in use.</Typography>}

      <TextField required
        label="Password"
        type="password"
        id="password"
        name="password"
        value={password}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
      <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={checkIfEmailInUse}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};