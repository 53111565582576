import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import ProSelect from "./01ProSelect";
import {TimeSelect} from "./02TimeSelect";
import  Notes from "./03Notes";
import Review from "./04Review";
import {Checkout} from "./05Submit";
import AppointmentTips from "./06AppointmentTips";

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripePromiseTest = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)

const defaultData = {
  service: "",
  serviceCost: "",
  dateTime: null,
  address: "",
  havePets: 'no',
  petTypes: "",
  notes: "",
  client: null,
  pro: null,
  bookingFee: "",
  travelFee: "0",
  reqDeposit: false,
  priceVaries:false,
};

const steps = [
  { id: "pro select" },
  { id: "time select" },
  { id: "notes" },
  { id: "review" },
  { id: "submit" },
  { id: "close" },
];

export const BAScheduler = ({userData, availablePros, selectedService, brandAm, handleBookingModalClose, backgroundColor, brandAmUID, fontColor}) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { userData, formData, setForm, navigation, availablePros, brandAm, selectedService, handleBookingModalClose, backgroundColor, brandAmUID,fontColor};

  switch (step.id) {
    case "pro select":
      return <ProSelect {...props} />;
    case "time select":
      return <TimeSelect {...props} />;
    case "notes":
      return <Notes {...props} />;
    case "review":
      return <Review {...props} />;
     case "submit":
       return (
         <>
         {userData && userData.test===false &&
       <Elements stripe={stripePromise}>
         <Checkout {...props} />
       </Elements>}
         {userData && userData.test===true &&
       <Elements stripe={stripePromiseTest}>
         <Checkout {...props} />
       </Elements>}
       </>
       );
    case "close":
      return <AppointmentTips {...props} />;
    default:
      return (
        <Review {...props} />
        );
  }
};