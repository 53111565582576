import React, {useState, useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Typography, Grid, Button, Avatar, IconButton, Modal, Snackbar, Paper, InputBase} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import StarIcon from '@material-ui/icons/Star';
import SearchIcon from '@material-ui/icons/Search';
import { firestore, storage } from '../../../config/fbConfig';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import firebase from 'firebase';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { ProSignUp } from './proSignUp/ProSignUp';





const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
      vpBtn:{
        margin:'auto',
        textAlign:'center',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      gridList: {
        width: 'inherit',
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      section: {
        '&:hover': {
          cursor:'pointer'
        }
      },
      
      contactAv:{
        "&:hover": {
          width:38,
          height:38,
          cursor:'pointer',
        },
      },
      wrapper: {
        height: '100%',
        width: 'fit-content',
        position: 'relative',
      },
      buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
          height: 400,
        },
      },
      cropButton: {
        flexShrink: 0,
        marginLeft: 16,
      },
      controls: {
        width:'100%',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
      },
      sliderLabel: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 65,
        },
      },
      slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
          margin: '0 16px',
        },
      },     
  }));


  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }



function ClaimAccount(props) {
  const { width } = props;
//   const history = useHistory();
  const [pro, setPro] = useState(null)
  const [loading,setLoading]= useState(false)

  const [openSuccess, setOpenSuccess] = useState(false)
  const [claimModal, setClaimModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [serviceSearch, setServiceSearch] = useState('')
  const [reviews, setReviews] = useState([])
  const [openErr, setOpenErr] = useState()

  const classes = useStyles();
  const [copied, setCopied] = useState(false)
  const [transition, setTransition] = useState(undefined);
  const severity = ["error", "success"]
  const services = pro!== null ? pro.services : null

  const imageHeight = (width === 'xs') ? 200 : (width === 'md') ? 400 : 500
  const h1 = (width === 'xs' || width === 'sm') ? 22 : 30
  const h2 = (width === 'xs' || width === 'sm') ? 14 : 22
  const text = (width === 'xs' || width === 'sm') ? 10 : 16

  const avatarSize = (width === 'xs' || width === 'sm') ? 60 : 90
  const infoHeight = (width === 'xs' || width === 'sm') ? 80 : 100

  const onlineTop = (width === 'xs' || width === 'sm') ? '55px' : '75px'
  const onlineLeft = (width === 'xs' || width === 'sm') ? '60px' : '85px'

  const barWidth = (width === 'xs' || width === 'sm') ? 45 : 70
  const paddingRgt = width === 'xs' ? 12 : 0
  const xs =  11
  
  const path = window.location.pathname.split('/')
  const pathName = path[2]

  console.log(pathName)

const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenErr(false);
  };


  useEffect(() => {
    if(pro=== null){
        firestore.collection("prospects").where("url", "==", pathName)
        .onSnapshot((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setPro(doc.data());
            }, (error) => {
              }
            );
        })
    }

  }, [pro])


  const handleDelete = () => {
    firestore.collection('prospects').doc(pro.url).update({
      accountStatus:'opt to delete',
      lastUpdated: new Date(),
    }).then(()=> window.location.reload())
  }


  return (
      <>
      {pro!==null && 
      <Grid container style={{height:'95vh', display:'flex', justifyContent:'center', alignItems:'center', paddingRight:paddingRgt}}>

        {width === 'xs' &&
        <Grid item container xs={12} style={{display:'flex', justifyContent:'center', marginTop:20, marginBottom:-50}}>
          {(pro.accountStatus !== "claimed" && pro.accountStatus !== "opt to delete") ?
          <>
          <Button variant='contained' color='primary' onClick={()=>setClaimModal(!claimModal)} style={{marginBottom:20}}>CLAIM ACCOUNT</Button>
          <Button variant='contained' color='secondary' onClick={()=>setDeleteModal(!deleteModal)} style={{marginBottom:20, marginLeft:5}}>DELETE ACCOUNT</Button>
          </>
          :
          <Typography style={{fontSize:20, fontWeight:'bold'}}>This account is {pro.accountStatus}</Typography>
          }
        </Grid>
        }

      <Grid item xs={xs} sm={10} container style={{minWidth:280, maxWidth: 1200, position:'relative', background: 'white', display: 'flex', borderRadius:'12px', outline:'none', alignSelf:'baseline', marginTop:40}}>
        <Grid id='service-side' item container xs={12} md={7} style={{padding:'5px', height:'fit-content'}}>
        
          <Grid id='image-container' item container xs={12} style={{height: imageHeight, position:'relative'}}>
            {/* REPLACE THIS DIV WITH THE IMAGE GALLERY */}

              <div style={{width: 'inherit', height:'inherit', borderRadius: 16, backgroundColor:'#0000002b', position:'absolute'}}>
                <img src="https://www.arabamerica.com/wp-content/themes/arabamerica/assets/img/thumbnail-default.jpg" alt="" style={{ borderRadius: 16, width:'100%', height:"100%", objectFit:'cover'}}/>
                  
                <Typography style={{fontSize:20, fontWeight:'bold', width:185, position:'absolute', left: '50%', top:' 50%', transform: 'translate(-50%,-50%)'}}>Your Images Here</Typography>
            
              </div>

              <div id='avatar-info-container' style={{position:'absolute', height: infoHeight, width:'inherit', display:'flex', justifyContent:'space-between', alignItems:'center', padding: 16}}>
                  {(width === 'xs') ? 
                  null
                :
                <>
                <Grid item container xs={9} style={{display:'flex', flexFlow:'row'}}>
                  <Avatar src={pro.images.avatar} style={{height: avatarSize, width: avatarSize, border: '2px solid white', marginRight:5}}></Avatar>
                  <div style={{display:'flex', flexFlow:'column', justifyContent:'center', overflow:'hidden'}}>
                      {pro.showDisplay !== undefined && pro.displayName !== undefined && pro.showDisplay === false ?
                        <Typography style={{fontWeight:'bold', fontSize:h2, color: 'white', lineHeight:0, marginBottom:h2}}>{pro.firstName+' '+pro.lastName}</Typography>
                      :
                        <Typography style={{fontWeight:'bold', fontSize:h2, color: 'white', lineHeight:0, marginBottom:h2}}>{pro.displayName}</Typography>
                      }
                      <Typography style={{fontSize:text, color: 'white', lineHeight:0, marginBottom:h2, textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{pro.title}</Typography>
                      <Typography style={{fontWeight:'bold', fontSize:h2, color: 'white',lineHeight:0}}>5.00 <span>(0)</span></Typography>
                  </div>
                </Grid>
                <Grid item container xs={3} style={{display:'flex', justifyContent:'flex-end'}}>
                  
                  <IconButton><img src="https://img.icons8.com/material-sharp/24/ffffff/share-rounded.png" alt='share'/></IconButton>
                  <IconButton><FavoriteBorderIcon style={{color:'white'}}/></IconButton>
                
                </Grid>
        
                <div style={{position:'absolute', top:onlineTop, left:onlineLeft, height:15, width:15, borderRadius:15, backgroundColor:'green', border: '2px solid white'}}></div>
                
                </>
                }
              </div>

          </Grid>

          <Grid item container xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'space-between', margin: '10px 0px'}}>
            <Grid item container xs={6}>
            </Grid>

            <Grid item container xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
                <Typography style={{color:'green', fontSize:text}}>(CLAIM YOUR ACCOUT NOW!)</Typography>
            </Grid>
          </Grid>

          {width === 'xs' ?
          <Grid item container xs={12} style={{position:'relative'}}>
            <Grid item container xs={9} style={{display:'flex', flexFlow:'row'}}>
              <Avatar src={pro.images.avatar} style={{height: avatarSize, width: avatarSize, border: '2px solid white', marginRight:5}}></Avatar>
              <div style={{display:'flex', flexFlow:'column', justifyContent:'center', overflow:'hidden'}}>
                  <Typography style={{fontWeight:'bold', fontSize:h2, lineHeight:0, marginBottom:h2}}>{pro.firstName+' '+pro.lastName.charAt(0)}</Typography>
                  <Typography style={{fontSize:text, lineHeight:0, marginBottom:h2, textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{pro.title}</Typography>
                  <Typography style={{fontWeight:'bold', fontSize:h2,lineHeight:0}}>5.00 <span>(0)</span></Typography>
              </div>
            </Grid>

            <Grid item container xs={3} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
            </Grid>

            <div style={{position:'absolute', top:45, left:45, height:15, width:15, borderRadius:15, backgroundColor:'green', border: '2px solid white'}}></div>

          </Grid>  
          :
          null
          }

          <Grid item container xs={12} style={{margin:'10px 0px', paddingBottom:10, borderBottom:'1px solid lightgray'}}>
              <Grid item container xs={6}>
                <Typography style={{fontSize:h1, fontWeight:'bold'}}>Services</Typography>
              </Grid>
              <Grid item container xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
                <Paper component='form' style={{border:'2px solid lightgray', borderRadius: 20, height: 40 , width: 'inherit', paddingTop: 2, display:'flex', alignItems:'center', boxShadow: 'none'}}
                  className={classes.inputField}>    
                    <SearchIcon className={classes.icon} style={{color:'lightgrey', cursor:'pointer'}}/>
                    <InputBase
                      name='search'
                      value={serviceSearch}
                      onChange={(e) => {setServiceSearch(e.target.value)}}
                      placeholder="Search..."
                      className={classes.input}
                      style={{flex:'1 1 auto', padding:' 0px 10px'}}
                      //autoFocus
                      />  
                </Paper>
              </Grid>
          </Grid>

          <Grid item container xs={12} style={{margin:'10px 0px', paddingBottom:10, borderBottom:'1px solid lightgray'}}>

            {services.length !== 0
            && services.filter((service)=> service.addOn !== null && service.addOn !== true).
            sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1).filter((service) => {
              const name = service.name
              if(serviceSearch === '') {
                  return service
                  } else if (name.toLowerCase().includes(serviceSearch.toLowerCase())) {
                      return service
                  }
                  }).map((service, idx) => {
                    const {name, price, serviceType, time, priceVaries} = service
                      return(
                        <Grid container item xs={12} style={{marginBottom:10, display:'flex', minHeight:80, alignItems:'center', backgroundColor:'white', borderRadius:5, padding:8, border:'1px solid lightgray'}}>
                        <Grid item contianer xs={8}>
                          <Typography style={{fontWeight:'bold'}}>{name} - ${price.includes('.') ? price.split('$')[1] : price.split('$')[1]+".00"}
                            {(priceVaries !== undefined && priceVaries === true) && 
                            <span style={{fontSize:12, fontWeight:'normal'}}>{' (and up)'}</span>}
                          </Typography>
                          <Typography style={{fontSize:12, color:'grey'}}>{serviceType} - {parseInt(time/60) === 0 ? time%60 + "mins" : parseInt(time/60) === 1 ? time%60 === 0 ? parseInt(time/60)+"hr" : parseInt(time/60)+"hr " + time%60 + "mins" : time%60 === 0 ? parseInt(time/60)+"hrs" : parseInt(time/60)+"hrs " + time%60 + "mins"}</Typography>
                          <Typography className={classes.info} style={{fontSize:12, fontWeight:'bold', color:'#413e68'}}>MORE INFO</Typography>
                        </Grid>
          
                        <Grid item container xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                          <Button color='primary' variant='contained' style={{fontSize:12, fontWeight:'bold'}}>Book</Button>
                        </Grid>
                        {/* <Typography style={{width:'inherit', marginTop:20}}>{service.details}</Typography><div style={{width:'inherit', marginTop:20}}></div> */}
                      </Grid>
                      )
                  }).sort()  
          }
          </Grid>

        </Grid>


        <Grid id='info-side' item container xs={12} md={5} style={{padding:'5px', height:'fit-content'}}>

          <Grid item container xs={12}>
            <Typography style={{fontSize:h1, fontWeight:'bold'}}>About</Typography>
          </Grid>

            <Grid item container xs={12} style={{paddingBottom:10, borderBottom:'1px solid lightgray', display:'flex', justifyContent:'center'}}>
            <Typography variant='subtitle1' style={{maxHeight:220, overflowY:'scroll', width:'100%'}}>{pro.bio}</Typography>
            <Typography style={{width:'100%', textAlign:'center', marginTop:20, fontWeight:'bold'}}>Contact Info:</Typography>
            

            <Grid item container xs={12} style={{paddingBottom:10, borderBottom:'1px solid lightgray', display:'flex', justifyContent:'center'}}>
              <Typography style={{fontSize:14, color:'lightgray', marginBottom:20}}><em>Please create accout to add contact info</em></Typography>
            </Grid>

              {(pro.accountStatus !== "claimed" && pro.accountStatus !== "opt to delete") ?
              <>
              <Button variant='contained' color='primary' onClick={()=>setClaimModal(!claimModal)} style={{marginBottom:20}}>CLAIM ACCOUNT</Button>
              <Button variant='contained' color='secondary' onClick={()=> setDeleteModal(!deleteModal)} style={{marginBottom:20, marginLeft:5}}>DELETE ACCOUNT</Button>
              </>
              :
              <Typography style={{fontSize:20, fontWeight:'bold'}}>This account is {pro.accountStatus}</Typography>
              }
          </Grid>


         
          <Grid item container xs={12}>
            <Typography style={{fontSize:h1, fontWeight:'bold'}}>Location</Typography>
          </Grid>
          

          <Grid item container xs={12} style={{paddingBottom:10, borderBottom:'1px solid lightgray', textAlign:'center'}}>
            <Typography variant='subtitle1' style={{maxHeight:220, width:'100%'}}>123 Street, Atlanta, GA 30000</Typography>
          </Grid>
          

          <Grid item container xs={12}>
            <Typography style={{fontSize:h1, fontWeight:'bold'}}>Availability</Typography>
          </Grid>

          <Grid item container xs={12} style={{padding:10, borderBottom:'1px solid lightgray', display:'flex', justifyContent:'center',}}>
           
              <Grid item contianer xs={12} sm={8} style={{height:200, display:'flex', flexFlow:'column'}}>
                          <Grid item container xs={12}>
                            <Grid item xs={4}>
                              <Typography>Mon:</Typography>
                            </Grid>
                            <Grid item xs={8} style={{display:'flex', justifyContent:'flex-end'}}>
                              <Typography>9:00 AM - 5:00 PM</Typography>
                            </Grid>
                          </Grid>
                                            
                     
                          <Grid item container xs={12}>
                            <Grid item xs={4}>
                              <Typography>Tue:</Typography>
                            </Grid>
                            <Grid item xs={8} style={{display:'flex', justifyContent:'flex-end'}}>
                              <Typography>9:00 AM - 5:00 PM</Typography>
                            </Grid>
                          </Grid>
                      
                          <Grid item container xs={12}>
                            <Grid item xs={4}>
                              <Typography>Wed:</Typography>
                            </Grid>
                            <Grid item xs={8} style={{display:'flex', justifyContent:'flex-end'}}>
                              <Typography>9:00 AM - 5:00 PM</Typography>
                            </Grid>
                          </Grid>
                    
                          <Grid item container xs={12}>
                            <Grid item xs={4}>
                              <Typography>Thu:</Typography>
                            </Grid>
                            <Grid item xs={8} style={{display:'flex', justifyContent:'flex-end'}}>
                              <Typography>9:00 AM - 5:00 PM</Typography>
                            </Grid>
                          </Grid>
                     
                          <Grid item container xs={12}>
                            <Grid item xs={4}>
                              <Typography>Fri:</Typography>
                            </Grid>
                            <Grid item xs={8} style={{display:'flex', justifyContent:'flex-end'}}>
                              <Typography>9:00 AM - 5:00 PM</Typography>
                            </Grid>
                          </Grid>
                     
                          <Grid item container xs={12}>
                            <Grid item xs={4}>
                              <Typography>Sat:</Typography>
                            </Grid>
                            <Grid item xs={8} style={{display:'flex', justifyContent:'flex-end'}}>
                              <Typography>9:00 AM - 5:00 PM</Typography>
                            </Grid>
                          </Grid>
               
                          <Grid item container xs={12}>
                            <Grid item xs={4}>
                              <Typography>Sun:</Typography>
                            </Grid>
                            <Grid item xs={8} style={{display:'flex', justifyContent:'flex-end'}}>
                              <Typography>CLOSED</Typography>
                            </Grid>
                          </Grid>
                 
              </Grid>
            

          </Grid>

          <Grid item container xs={12}>
            <Typography style={{fontSize:h1, fontWeight:'bold'}}>Reviews</Typography>

            <Grid item containter xs={12} style={{borderRadius:12, margin:10, height:150, border:'1px solid lightgray', display:'flex'}}>
              <Grid container item xs={6} style={{borderRight:'1px solid lightgray', height:'inherit', display:'flex', alignItems:'center'}}>
                <div style={{width:'inherit', height:'fit-contnet', display:'flex', flexFlow:'inherit', justifyContent:'center'}}>
                  <Typography style={{width:'100%', textAlign:'center'}}><span style={{fontSize:30, fontWeight:'bold'}}>5.00</span>/5</Typography>
                  {[...Array(5)].map((star,idx) => {
                          return <StarIcon style={{color: '#413e68', fontSize:24}} className={classes.button}/>       
                          })
                  }
                  <Typography style={{color:'gray', fontSize:12, width:'100%', textAlign:'center'}}>1 review(s)</Typography>  
                </div>
              </Grid>
              <Grid container item xs={6} style={{height:'inherit', display:'flex', flexFlow:'inherit', justifyContent:'center'}}>
                  <ul style={{paddingLeft:0, listStyleType:'none'}}>
                    {[...Array(5)].map((num, idx) => {
                        return <li style={{display:'flex', alignItems:'center'}}>
                          <Typography style={{textAlign:'right', width:50}}>{5 - idx} <StarIcon style={{color: '#413e68', verticalAlign:'middle', marginRight:10, fontSize:14}}/></Typography>
                          <div style={{width:barWidth, height:4, backgroundColor:'lightgray', display:'flex'}}>
                              <div style={{width:`${(reviews.filter(r => r.review.rating === (5 - idx)).length / 0 * 100).toFixed(2)}%`, backgroundColor:'#413e68'}}></div>
                          </div>
                          <Typography style={{marginLeft:10, color:'gray', fontSize:14}}>0</Typography>
                          </li>
                    })}
                  </ul>
              </Grid>

            </Grid>

      
            <Grid item xs={12} style={{padding:10, display:'flex', borderBottom:'1px solid lightgray'}}>
                <Grid item container style={{flex:'0 1 40px'}}>
                <Avatar src={'https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/CustomerInfo%2Fantony%40cutiapp.com-Antony1-Sanders1%2Fimages%2Favatar%2Favatar?alt=media&token=e11684a0-fe6b-4929-bf00-51bd840deb60'}></Avatar>
                </Grid>
                <Grid item style={{flex:'1 0 80%', paddingLeft:5}}>
                <Typography style={{width:'100%', fontWeight:'bold', display:'flex', alignItems:'center'}}>Antony S - Founder/CEO <span style={{fontWeight:'normal', fontSize:12, color: 'gray', marginLeft:5}}>(What You Do Best)</span></Typography>
                {[...Array(5)].map((star,idx) => {
                    return <StarIcon style={{color: '#413e68', fontSize:16}} className={classes.button}/>       
                    })
                }
                <Typography style={{width:'100%'}}>Hey {pro.firstName !== '' ? pro.firstName : "there"}! I've gotten the chance to see your work on another platform and I was really impressed! I believe you would greatly benefit from using our FREE platfrom so I took the liberty of creating you this temporary account and even added all of your services! Just Click the "CLAIM ACCOUNT" button to get started. If you don't want the account that's ok too, but we hope to see you around!</Typography>
                </Grid>
            </Grid>
            
        



          </Grid>

          <Grid item container xs={12} style={{paddingBottom:10, borderBottom:'1px solid lightgray'}}>

          </Grid>

          <Grid className={classes.section} item container xs={12} style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid lightgray', alignItems:'center'}}>
            <Typography style={{fontSize:text, padding:'10px 0px'}}>Cancelation Policy</Typography>
            <IconButton>
              <ArrowBackIos style={{transform:'rotate(180deg)'}}/>
            </IconButton>
          </Grid>

          <Grid className={classes.section} item container xs={12} style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid lightgray', alignItems:'center'}}>
            <Typography style={{fontSize:text, padding:'10px 0px'}}>Report Abuse</Typography>
            <IconButton>
              <ArrowBackIos style={{transform:'rotate(180deg)'}}/>
            </IconButton>
          </Grid>
          
        </Grid>

      </Grid>

   
      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity[1]}>
              Your Image Was Uploaded Successfully!
          </Alert>
      </Snackbar>
      <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity[0]}>
          Oops, something went wrong...
          </Alert>
      </Snackbar>
      
      </Grid>
      }


      {/* =============================================== MODALS =============================================== */}
      {/* =============================================== vvvvvv =============================================== */}
      {/* =============================================== MODALS =============================================== */}


      <Modal open={!!claimModal} onClose={()=> setClaimModal(!claimModal)}>
        <Grid container item xs={11} md={5} lg={4} display='column' style={{display:'flex', outline:'none', position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
                <Grid container item xs={12} style={{padding:10}}>

                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Typography component="h1" variant="h5">
                           Claim Account
                        </Typography>
                        <IconButton onClick={()=> setClaimModal(!claimModal)}><CloseIcon/></IconButton>
                    </Grid>
                </Grid>


                <Grid item container xs={12} style={{maxHeight:'80vh', overflowY:'scroll'}}>
                  <ProSignUp pro={pro}/>
                </Grid>
        </Grid>
      </Modal>

      <Modal open={!!deleteModal} onClose={()=> setDeleteModal(!deleteModal)}>
        <Grid container item xs={11} md={5} lg={4} display='column' style={{display:'flex', outline:'none', position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
                <Grid container item xs={12} style={{padding:10}}>

                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <Typography component="h1" variant="h5">
                           Delete Account
                        </Typography>
                        <IconButton onClick={()=> setDeleteModal(!deleteModal)}><CloseIcon/></IconButton>
                    </Grid>

                </Grid>

                <Grid item container xs={12} style={{display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
                    <WarningIcon style={{width:100, height:100, color:'#f4bd02'}}/>
                    <Typography style={{marginTop:20}}>This action cannot be undone.</Typography>
                    <Typography>Are you sure you wish to delete it?</Typography>
                </Grid>

                <Grid item container xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'center', margin:'20px 0px'}}>
                    <Button variant='contained' color='primary' onClick={()=>setDeleteModal(!deleteModal)}>No, Cancel</Button>
                    <Button variant='contained' color='secondary' onClick={handleDelete} style={{marginLeft:5}}>Yes, I'm Sure</Button>
                </Grid>
              
        </Grid>

      </Modal>


      <Snackbar
              open={copied}
              onClose={handleClose}
              TransitionComponent={transition}
              message="Copied to cliptrap."
              key={transition ? transition.name : ''}
          />
      </>
  )
}

ClaimAccount.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};


export default withWidth()(ClaimAccount)

