import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Navbar from './components/layout/NavBar'
import LandingPage from './components/layout/LandingPage'
import Dashboard from './components/dashboard/_DashboardContainer';
import ServiceSearch from './components/layout/ServiceSearch/ServiceSearch'
import Notifications from './components/dashboard/Notifications'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import {connect} from 'react-redux'
import {auth, firestore} from './config/fbConfig'
import {setCurrentUser, clearCurrentUser, setUserData} from './auth/authActions'
import { setAppointments } from './auth/appointmentActions';
import ProProfile from './components/dashboard/settings/ProProfile';
import  Settings  from './components/dashboard/Settings';
import  Admin  from './components/dashboard/Admin';
import CompleteOnboarding from './components/CompleteOnboarding';
import Success from './components/Success';
import ResetPassword from './components/auth/ResetPassword';
import InformationPages from './components/layout/InformationPages';
import PageNotFound from './components/PageNotFound';
import BrandAmbassador from './components/layout/brandAmbassadors/BrandAmbassador';
import Payment from './components/Payment';

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import ClaimAccount from './components/layout/claimAccount/ClaimAccount';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripePromiseTest = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)

function App({ currentUser, setCurrentUser, clearCurrentUser, userData,
              appointments, setUserData, setAppointments }) {
  
  const declareUserType = (user) => {
    if (getUserData('customers', user) === undefined) {
      if(getUserData('professionals', user) === undefined){
          getUserData('admins', user)
      } else {
        const typeOfUser = 'professionals'
      }
    } else {
      const typeOfUser = 'customers'
    }
  }
  
  const getUserData = (typeOfUser, user) => {
    if (user) {
       const appts = []
       const {uid} = user
       firestore.collection(typeOfUser).doc(uid).get()
       .then((doc) => {
                if (doc.exists) {
                  setUserData(doc.data())
                  // if (userData) {
                  if(typeOfUser === 'customers'){
                    firestore.collection('appointments').where("clientUID", "==", `${uid}`)
                    .get().then(async (querySnapshot) => {
                      await querySnapshot.forEach((doc) => {
                        appts.push({docData: doc.data(), id: doc.id})
                      })
                    }).then(setAppointments(appts))
                  } 
                  else if (typeOfUser === 'professionals'){
                    firestore.collection('appointments').where("proUID", "==", `${uid}`)
                    .get().then(async (querySnapshot) => {
                      await querySnapshot.forEach((doc) => {
                        appts.push({docData: doc.data(), id: doc.id})
                      })
                    }).then(setAppointments(appts))
                  } 
                  else if (typeOfUser === 'admins'){
                    firestore.collection('appointments')
                    .get().then(async (querySnapshot) => {
                      await querySnapshot.forEach((doc) => {
                        appts.push({docData: doc.data(), id: doc.id})
                      })
                    }).then(setAppointments(appts))
                  } 
                  // }
                }
            })
       }
   }


  useEffect(() => {
    let unsubscribeFromAuth = null;
    unsubscribeFromAuth = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
        declareUserType(user);
      } else {
        clearCurrentUser();
      }
    });
    
   return () => unsubscribeFromAuth();
  }, []);
  

  return (
    <Router>
      <div className="App" style={{display:"flex", flexFlow:"column", height:"100vh",}}>
        <Navbar/>
        <Switch>
          <Route exact path="/"component={() => <LandingPage user={currentUser}/>}/>
          <Route exact path="/ip/:info" component={() => <InformationPages/>}/> 
          <Route path="/home" component={() => <Dashboard user={currentUser} userData={userData} appointments={appointments}/>}/>
          <Route exact path="/continue-onboarding" component={() => <CompleteOnboarding user={currentUser} userData={userData} appointments={appointments}/>}/>
          <Route exact path="/success" component={() => <Success user={currentUser} userData={userData} appointments={appointments}/>}/>
          {/* <Route exact path="/account" component={Account}/> */}
          <Route exact path="/signin" component={SignIn}/>
          <Route path="/signup" component={SignUp}/>
          <Route exact path="/reset-password" component={ResetPassword}/>
          <Route path='/services/:service' component={ServiceSearch} />
          <Route path='/pro/profile/:pro' component={() => <ProProfile currentUser={currentUser} userData={userData}/>} />
          <Route path='/brand-ambassador/:ambassador' component={() => <BrandAmbassador currentUser={currentUser} userData={userData}/>} />
          <Route path='/claim-account/:prospect' component={() => <ClaimAccount/>} />
          <Route path='/payment/:appointment' component={() => 
            <Elements stripe={stripePromiseTest}>
              <Payment appointments={appointments} currentUser={currentUser} userData={userData}/>
            </Elements>
            } />
          <Route path='/settings' component={() => <Settings user={currentUser} userData={userData} appointments={appointments}/>}/>
          <Route path='/admin' component={() => <Admin user={currentUser} userData={userData}/>}/>
          <Route path="*" component={()=> <PageNotFound/>}/>
        </Switch>
      </div>
    </Router>
  );
}

const mapState = (state) => ({
  currentUser: state.auth.currentUser,
  userData: state.auth.userData,
  appointments: state.appt.appointments
})

const mapDispatch = dispatch => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
  setUserData: (data) => dispatch(setUserData(data)),
  setAppointments: (appts) => dispatch(setAppointments(appts))
})

export default connect(mapState, mapDispatch)(App);
