import React, {useState} from 'react'
import PropTypes from 'prop-types';
import {CustomerSignUp} from './Customer/CustomerSignUp'
import {ProSignUp} from './Pro/ProSignUp'
import {Grid, Button, withWidth, Hidden, Link, Box, Typography} from '@material-ui/core';


function SignUp({width}) {
    const [pro, setPro] = useState(false)
    const [customer, setCustomer] = useState(false)
    const sideImg = (width==='xs' || width==='sm') ? false : true
    const gridSize = (width==='xs' || width==='sm') ? 12 : 6

    return(
        <Grid item container xs={12} style={{display:"flex", flex:'1 1 auto', alignItems:'center',  position: 'relative'}}>
            <Hidden smDown>
            {pro===false ? 
            <Grid item container xs={0} md={6} 
                    style={{backgroundImage:"url('https://facesspa.com/wp-content/uploads/2019/07/how-often-should-you-get-a-facial.jpeg')", 
                    backgroundPosition:'center top',
                    backgroundRepeat:'no-repeat', 
                    backgroundSize:'cover',
                    height:'95vh', }}>
                </Grid>
                :
                <Grid item container xs={0} md={6} 
                    style={{backgroundImage:"url('https://img.beautylaunchpad.com/files/base/allured/all/image/2017/08/blp.lady-barbers-instagram.png?auto=format%2Ccompress&fit=max&w=1280')", 
                    backgroundPosition:'center top',
                    backgroundRepeat:'no-repeat', 
                    backgroundSize:'cover',
                    height:'95vh', }} >
                </Grid>
                }
            </Hidden>

            <Grid item container xs={gridSize} md={6}>
                <Grid container style={{display:'flex', flexFlow:'column', alignItems:'center'}}>

                        <Grid container item xs={12} sm={10} md={7} style={{justifyContent:'space-evenly', marginBottom:20, textAlign:'center'}}>
                            <Typography style={{fontWeight: 'bold', fontSize:24}}>Welcome!<br/>How can we help?</Typography>
                        </Grid>

                        <Grid container item xs={12} sm={10} md={7} style={{justifyContent:'space-evenly'}}>
                            {customer === true ? <Button style={{ borderRadius: "0px", backgroundColor: "#413e68", fontWeight: "bold", color:'white'}} onClick={()=>{setCustomer(true); setPro(false)}}>I'm looking for services</Button> :
                            <Button style={{ borderRadius: "0px", border:'1px solid #413e68'}} onClick={()=>{setCustomer(true); setPro(false)}}>I'm looking for services</Button>} 
                            {pro === false ? <Button style={{ borderRadius: "0px", border:'1px solid #413e68'}} onClick={()=>{setCustomer(false); setPro(true)}}>I'm A Service Provider</Button> :
                            <Button style={{borderRadius: "0px", backgroundColor: "#413e68", fontWeight: "bold", color:'white'}} onClick={()=>{setCustomer(false); setPro(true)}}>I'm A Service Provider</Button>}
                        </Grid>
                        

                        {pro === true && <ProSignUp/>}
                        {customer === true && <CustomerSignUp/>}
                        
                        <Grid container style={{justifyContent:'center', flexFlow:'column', marginTop:24}}>
                            <Grid item style={{display:'flex', justifyContent:'center'}}>
                                <Link href="/signin" variant="body2">
                                {"Have an account? Sign In!"}
                                </Link>
                            </Grid>
                            <Box mt={4}>
                                <Typography variant="body2" color="textSecondary" style={{width:'100%', textAlign:'center'}}>
                                <Link style={{marginRight:10}} color="inherit" href="https://cutiapp.com/ip/terms">Terms of Service</Link>
                                <Link color="inherit" href="https://cutiapp.com/ip/privacy">Privacy Policy</Link>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" align="center">
                                    {'Copyright © '}
                                    <Link color="inherit" href="https://cutiapp.com/">
                                        Cuti App
                                    </Link>{' '}
                                    {new Date().getFullYear()}
                                    {'.'}
                                </Typography>
                            </Box>
                        </Grid>

                </Grid>

            </Grid>
            

            
        </Grid>
        
        
    )

    }



        
    


SignUp.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };
  
  export default withWidth()(SignUp)