import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { Scraper } from "../CreateAProspect/Scraper";
import { Names } from "../CreateAProspect/Names";
import { AccountInfo } from "../CreateAProspect/AccountInfo";
import {Services} from "../CreateAProspect/Services"
// import { Contact } from "/Contact";
import { Review } from "../CreateAProspect/Review";

const defaultData = {
  avatar:"",
  firstName: "",
  lastName: "",
  displayName: "",
  title: "",
  bio:"",
  serviceType:[],
  services:[],
  address: "",
  coordinates:{lat:null,lng:null},
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  status: 'unclaimed', //statuses: unclaimed, claimed, delete
  
};

const steps = [
  // { id: "scraper" },
  { id: "names" },
  { id: "account" },
  { id: "services" },
  { id: "review" },
];

export const ProspectCreator = ({handleProspectModal}) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setForm, navigation, handleProspectModal };

  switch (step.id) {
    // case "scraper":
      // return <Scraper {...props} />;
    case "names":
      return <Names {...props} />;
    case "account":
      return <AccountInfo {...props} />;
    case "services":
      return <Services {...props} />;
    case "review":
      return <Review {...props} />;
    default:
      return (
          <div>
            <h1>An Error Occurred...</h1>
          </div>
        );
  }

  
};