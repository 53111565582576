import React from 'react'
import {Grid, Typography, Button, Checkbox, FormControlLabel, FormGroup, Tooltip, IconButton, Modal, TextField, TextareaAutosize, Switch} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    info: {
        '&:hover':{
          cursor:'pointer'
        }
      },
  
  }));

function ServiceSelect({pro, formData, navigation}) {
    const classes = useStyles()
    const handleServiceSelect = (service) => {
        formData.service = service.name
        formData.serviceCost = service.price
        formData.amtOfTime = service.time
       // formData.dateTime = selectedDate
        formData.serviceTimeEnd = service.time
        formData.reqDeposit = (service.reqDeposit !== null && service.reqDeposit !== undefined) ? service.reqDeposit : false
        formData.priceVaries = (service.priceVaries !== undefined) ? service.priceVaries : false
        navigation.next()
    }
  return (
    <Grid item xs={11} sm={10} md={8} lg={6} xl={5} container style={{outline:'none', height:600, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
        
        <Grid item xs={12}>
            <Typography variant='h5' className={classes.title}>Select A Service:</Typography>
        </Grid>
        
        <div style={{width:'100%'}}>
            {pro.services.length > 0 && 
            pro.services.map((service, idx)=> {
                const {name, serviceType, time, price} = service
                return(
                    <Grid container item xs={12} style={{marginBottom:10, display:'flex', minHeight:80, alignItems:'center', backgroundColor:'white', borderRadius:5, padding:8, border:'1px solid lightgray'}}>
                        <Grid item contianer xs={8}>
                            <Typography style={{fontWeight:'bold'}}>{name} - {parseFloat(price) === 0 ? "FREE" : `$${price}`} 
                            {(service.priceVaries !== undefined && service.priceVaries === true) && 
                            <span style={{fontSize:12, fontWeight:'normal'}}>{' (and up)'}</span>}
                            </Typography>
                            <Typography style={{fontSize:12, color:'grey'}}>{serviceType} - {parseInt(time/60) === 0 ? time%60 + "mins" : parseInt(time/60) === 1 ? time%60 === 0 ? parseInt(time/60)+"hr" : parseInt(time/60)+"hr " + time%60 + "mins" : time%60 === 0 ? parseInt(time/60)+"hrs" : parseInt(time/60)+"hrs " + time%60 + "mins"}</Typography>
                        </Grid>

                        <Grid item container xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Button onClick={handleServiceSelect} color='primary' variant='contained' style={{fontSize:12, fontWeight:'bold'}}>Book</Button>
                        </Grid>
                    </Grid>
                )
            })}

        </div>

    </Grid>

  )
}

export default ServiceSelect