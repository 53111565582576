import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, FormControl, IconButton, Modal, TextField,
        MenuItem, Select, InputLabel, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
    profileContainer:{
      position: 'relative',
      display:"flex",
      flexFlow:'column',
      alignItems:'center',
      padding: 10,
      marginBottom: 12,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    imgSelecter:{
      position:'absolute',
      top: 120,
      left: '52%',
      border: '2px solid white',
      backgroundColor: theme.palette.primary.main,
      '&:hover':{
        cursor:'pointer',
        opacity: .8,
      },
      accordianDetails:{
        width:'100%',
        display:'flex',
        flexFlow:'column',
        textAlign: 'left'
      },
      accordianEditBtn:{
        alignSelf:'flex-end'
      },
    }
  
  }));

function UpdatePriceModal({appt, userData, modalOpen, handleModalOpen, handleMenuClose}) {
    const classes = useStyles()
    const apptInfo = appt.docData
    const apptID = appt.id
    const [price, setPrice] = useState(apptInfo.serviceFee)
    const [note, setNote] = useState("")
    const disabled = (price === "" || (parseFloat(price) < parseFloat(apptInfo.serviceFee))) ? true : false

    const numsOnly = (e) => {
        const reg = /[0-9.]+/g;
          if (!reg.test(e.key)) {
            e.preventDefault();
          }
          if (e.keyCode === 46 && e.value.split('.').length === 2) {
            return false;
          }
        
      }

    const handleUpdate = () => {
        firestore.collection('appointments').doc(apptID)
        .update({
            amtRemainingBalance: (parseFloat(price) - parseFloat(apptInfo.amtPaid)).toFixed(2),
            serviceFee: parseFloat(price).toFixed(2),
        })
        .then(() => {
            handleMenuClose()
            handleModalOpen()
        })
    }

  return (
    <Modal open={!!modalOpen} onClose={handleModalOpen}>
    <Grid container item xs={10} sm={6} md={4} lg={3} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
        <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
            <Typography variant='h6' style={{padding: '10px'}}>
                Update Service Price
            </Typography>
            <IconButton onClick={handleModalOpen} style={{color: 'white'}}>
                <ClearIcon />
            </IconButton>
        </Grid>

        <Grid item container xs={12} style={{textAlign:'center'}}>
            <Typography style={{margin:20, fontSize:50, fontWeight:'bold', width:'100%' }}>${apptInfo.serviceFee}</Typography>
            <TextField
                className={classes.formControl} 
                name='price'
                fullWidth
                variant='outlined'
                placeholder={apptInfo.serviceFee}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                onKeyPress={(e) => numsOnly(e)}
            />

            {(parseFloat(price) < parseFloat(apptInfo.serviceFee)) &&
            <Typography style={{color:'red', width:'100%'}}>The adjusted price is too low!</Typography>
        }
            <Typography style={{marginBottom:10, width:'100%'}}>The adjusted price cannot be lowered once increased. Your client will be notified upon updating the price.</Typography>

            <Grid item container xs={12} className={classes.formControl}>
                <Grid item xs={6} style={{paddingRignt:5}}>
                    <Button variant='contained' fullWidth onClick={handleModalOpen}>Never Mind</Button>
                </Grid>
                <Grid item xs={6} style={{paddingLeft:5}}>
                    <Button variant='contained' fullWidth color='primary' disabled={disabled} onClick={handleUpdate}>Confirm</Button>
                </Grid>
            </Grid>


        </Grid>

    </Grid>
    
</Modal>
  )
}

export default UpdatePriceModal