import React from 'react'
import {Card, Container, Grid, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    border: "1px solid grey",
    marginTop:'20px',
    display: 'flex',
    flexFlow:'column',
  
  },
}));

function Notifications() {
  const classes = useStyles()
    return (
        <Grid item container xs={12} className={classes.container}>
           
        </Grid>
    )
}

export default Notifications
