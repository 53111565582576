import authTypes from './authTypes'

const initialState = {
    appointments: [],
    conversationId: '',
}

const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case authTypes.SET_APPOINTMENTS:
            return {
                ...state,
                appointments: action.payload
            }
        case authTypes.SET_CONVERSATION_ID:
            return {
                ...state,
                conversationId: action.payload
            }
            // case 'GET_PREVIOUS_APPOINTMENTS':
            //     return
            // case 'CREATE_APPOINTMENT':
            //     return
        default:
            return state
    }
}

export default appointmentReducer