import React, {useEffect} from "react";
import {Typography, Container, TextField, Button, Avatar, TextareaAutosize, Select, MenuItem, FormControl, InputLabel, Chip} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {firestore} from '../../../config/fbConfig'


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%'
  },
  question: {
      color: 'gray',
      marginTop:20,
      marginBottom: 4,
    },
    chip: {
      margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export const AccountInfo = ({ formData, setForm, navigation }) => {
  const classes = useStyles()
  const { avatar, phone, email, bio } = formData;
  const [emailInUse, setEmailInUse]=React.useState()
  const [checked, setChecked] = React.useState()
  const [invalid, setInvalid]=React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [typesOfServices, setTypesOfServices] = React.useState([]) 
    const [selectedTitles, setTitles] = React.useState([]);
  let [allTitles, setAllTitles] = React.useState([])
 
  
  const disabled = (phone === "" && (email === "" || invalid===true || selectedTitles.length === 0)) ? true : false;
  const border = (invalid === true || emailInUse === true) ? '2px solid red' : 'none'
  

  useEffect(() => {
    let titles = []
    if(loading===true && typesOfServices.length === 0){
      firestore.collection('typesOfServices').where('titles', "!=", "1")
      .onSnapshot((snapshot) => { 
        setTypesOfServices(snapshot.docs.map(doc => {return({serviceType: doc.id, titles: doc.data().titles})}))
      })
     setLoading(false)
    }

    if(typesOfServices.length !== 0 && allTitles.length === 0){
      typesOfServices.map(tos => titles.push(tos.titles))
      setAllTitles(titles.flat())
      setLoading(null)
    }


  console.log("service types and titles", typesOfServices)
  console.log("TITILE", allTitles)

  }, [loading, typesOfServices,])


  const handleTitleChange = (event) => {
    setTitles(event.target.value);
    formData.title = event.target.value
    const proTos = typesOfServices.filter(s => s.titles.find(element => element === formData.title[0]))[0]
    formData.serviceType = proTos.serviceType
    console.log("TITLES:", formData.title)
  };
  

  const numsOnly = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const validEmail = (e) => {
    const re = /[a-z0-9@-_.+~]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const checkIfEmailInUse = () => {
    // console.log('checking...')
    setChecked(true)
    if(emailInUse === true){
      setEmailInUse(false)
    }
    if(email.includes("@") && email.includes(".")){
      firestore.collection('professionals').where("email", "==", `${email}`)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log('Email in use')
          setEmailInUse(true)
          // formData.password = ''
      });
    })
    }
    if(email.includes("@") && email.includes(".")){
      firestore.collection('customers').where("email", "==", `${email}`)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log('Email in use')
          setEmailInUse(true)
          // formData.password = ''
      });
    })
    }

    // console.log(emailInUse)
  }

  useEffect(() => {

    if(checked !== undefined){
      setChecked(false)
    }

    if(!email.includes("@") || !email.includes(".")) {
      setInvalid(true)
    }

    if(!email.includes("@") && email !== '') {
      setInvalid(true)
      setEmailInUse(false)
    }else{
      setInvalid(false)
    }

    if(checked === true && emailInUse === false && disabled === false && invalid === false){
      navigation.next()
    }

  }, [email, invalid, emailInUse, checked, disabled, navigation, formData])

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center", display:'flex', flexFlow:'column', alignItems:'center'}}>
      <Typography variant='h4'>Account Info</Typography>
      
      {avatar === "" ?
      <TextField 
        label="Image URL"
        name="avatar"
        value={avatar}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="tel"
        fullWidth
      />
      :
      <Avatar src={avatar} style={{width:100, height:100, margin:10}}/>
      }

      <TextField 
        label="Email Address"
        name="email"
        id="email"
        value={email}
        onKeyPress={(e) => validEmail(e)}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="email"
        fullWidth
        style={{border:border}}
      />     

      <TextField 
        label="Phone Number"
        name="phone"
        type="tel"
        value={phone}
        onKeyPress={(e) => numsOnly(e)}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="tel"
        fullWidth
      />
    

      {allTitles.length !== 0 && 
      <>   
      <Typography variant='body2' className={classes.question}>Professional Title:</Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="title">Select Your Title(s)</InputLabel>
        <Select
          labelId="title"
          id="select-title"
          name="title"
          multiple
          value={formData.title || selectedTitles}
          onChange={(e) => {handleTitleChange(e); setForm(e);}}
          label="Select Your Title(s)"
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {allTitles.map((title, idx) => {
            return(
              <MenuItem key={idx} value={title}>{title}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
      </>
      }

      <Typography style={{marginTop:10, color:'gray', fontSize:14}}>Bio/About</Typography>
      <TextareaAutosize
        style={{padding:5, fontSize: 16, font:'inherit', borderColor:'silver', borderRadius:4, width:'inherit'}}
        label="Bio/About"
        name="bio"
        value={bio}
        rowsMax={3}
        rowsMin={3}
        onChange={setForm}
        required
        fullWidth
      />

      {emailInUse === true && 
      <Typography style={{color: "red"}}>Email is already in use.</Typography>}

      <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={email !== "" ? checkIfEmailInUse : ()=>navigation.next()}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};