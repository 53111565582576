import 'date-fns';
import React, {useEffect, useState} from "react";
import {Typography, Grid, Modal, Container, TextField, Button, Tooltip} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {MiuPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import {firestore} from '../../../../config/fbConfig'
import AppointmentCard from '../../../appointments/ProAppointmentCard';
import { AddCircle, AddCircleOutline } from '@material-ui/icons';
import { SchedulerPro } from '../../../modals/BookAppointmentModal/SchedulerPro';

const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 12,
      backgroundColor:'white',
      // marginTop:'20px',
      height: 'inherit',
      boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)',
      textAlign: 'left',
      alignSelf:'center',
    },
    titleWrapper:{
      borderRadius: '8px 8px 0 0',
      background: theme.palette.primary.main,
      height: "50px",
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      padding:'0px 20px'
    },
    title: {
      color:"white",
      // padding: "8px 0 0 20px"
    },
    question: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))"
    },
    sAPWrapper: {
      position: 'relative',
      height:30
    },
    sAPBtn: {
      position: 'absolute',
      top: 12,
      right: 12
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    dateTimePicker: {
      width:'100%'
    },
    iconButton: {
     color: theme.palette.primary.main,
     fontSize: '40px',
    },
    addButton: {
     color: 'white',
     "&:hover":{
       cursor:'pointer'
     }
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      border: `3px ${theme.palette.primary.main} solid`,
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160,
    },

  }));

export default function ProUpcomingAppointments({userData}) {

    const classes = useStyles();
    const [appointments, setAppointments] = useState([])
    const [modalOpen, setModalOpen] = useState(false)

    const handleBookingModalOpen = () => {
      setModalOpen(!modalOpen)
    }

  
    useEffect(() => {
      //let userAppointments = firestore.collection("customers").doc(`${userData.userId}`)
      firestore.collection("appointments").where("serviceComplete", "==", false).where("serviceCanceled", "==", false)
      .onSnapshot((querySnapshot) => {
        var appts = [];
        querySnapshot.forEach((doc) => {
          if(doc.data().proUID === userData.userId){
            appts.push({docData: doc.data(), id: doc.id});
            appts.sort((a,b) => a.docData.serviceTimeReq.seconds*1000 > b.docData.serviceTimeReq.seconds*1000 && 1 || -1)
          }
          });
          setAppointments(appts)
    })
      // cancel subscription to useEffect
    },[])

    return (
        <Grid container item xs={12} className={classes.root}>
            <Grid item xs={12} className={classes.titleWrapper}>
                <Typography variant='h6' className={classes.title}>Schedule</Typography>
                {/* <AddCircleOutline className={classes.addButton} onClick={handleBookingModalOpen}/> */}
            </Grid>
            <Grid item xs={12} style={{width: 'inherit', padding:'5px 5px 0 0', height: '100%'}}>
              <SimpleBarReact autoHide={false} style={{height: '89%', width: 'inherit', padding:'0px 15px 5px 5px'}}>
                { appointments.length !== 0 ? appointments.map((appt, idx) => {
                  const appInfo = appt.docData
                  const key = appt.id
                  return(
                    <AppointmentCard id={appt.id} appt={appt} userData={userData}/>
                  )
                })
                :
                <Grid item xs={12} style={{height: 'fit-content', textAlign: 'center'}}>
                    <Typography variant='caption' style={{color: 'grey'}}>You have no Upcoming Appointments <br/>--</Typography>
                </Grid> 
                }
                
              </SimpleBarReact>
            </Grid>


            <Modal open={!!modalOpen} onClose={handleBookingModalOpen}>
              <SchedulerPro pro={userData} handleBookingModalOpen={handleBookingModalOpen}/>
            </Modal>

      
        </Grid>
  );
}

