const authTypes = {
    //User
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
    SET_USER_DATA: 'SET_USER_DATA',
    //Appointments
    SET_APPOINTMENTS: 'SET_APPOINTMENTS',
    SET_CONVERSATION_ID: 'SET_CONVERSATION_ID',
    //Services & Titles
    SET_TYPES_OF_SERVICES: 'SET_TYPES_OF_SERVICES',
    SET_SERVICES: 'SET_SERVICES',
    SET_TITLES: 'SET_TITLES',
}


export default authTypes;