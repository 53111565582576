import React from "react";
import {Container, Typography, Button} from '@material-ui/core';
import {useHistory} from 'react-router';

export const Submit = () => {
  const history = useHistory();
  return (
    <Container maxWidth="sm" style={{ marginTop: '4rem', textAlign: 'center' }}>
      <Typography>
        Thank you for registering to be a <span style={{fontFamily: 'sarina, cursive', color: '#413e68'}}>Cuti</span> <strong style={{color: '#413e68'}}>PRO</strong>!
      </Typography>

      <Typography>
        You may now log in, but your account is currently inactive. Please allow us 3-5 business days to verify your account!
      </Typography>
      <br/><br/>
      
      <Button variant="contained" style={{ backgroundColor: '#413e68',  backgroundSize: "400px",borderRadius: '0',
        color: 'white', fontWeight: 'bold', border: '3px #413e68 solid', letterSpacing: '2px', marginTop: '1.5rem' }}
        onClick={() => history.push('/home')}>
        Let's Go!
      </Button>
    </Container>
  );
};