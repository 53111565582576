import React from 'react'
import {Typography, Container, Button} from "@material-ui/core";
import {storage} from '../../../config/fbConfig';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '100%',
      position: 'relative',
      border: '1px solid gray',
      display: 'flex',
      alignItems: "center",
      borderRadius: '8px',
    },
    formLabel:{
      display: 'flex',
      flex:'auto',
      paddingLeft: 10,
      '&:hover':{
        cursor:'pointer'
      },
      overflow:'hidden',
    },
    form: {
      height: 50,
      padding: '15px 0',
      visibility: 'hidden',
      width: 110,
    },
    uploadBtn:{
      height: 50,
      display: 'flex',
      position:'absolute',
      right: 0,
      background: '#c6c6c6',
      width: 110,
      color: 'white',
    },
    instruction: {
        color: 'gray',
        display:'flex',
        margin:'20px 0px 4px 4px',
      },
  }));



const FileUploader = ({formData, id, handleUpload, updateFile, setUploadFile, file}) => {
    const classes = useStyles();
  
    //const { firstName, lastName, email } = formData;
  
    // const handleUpload = () => {
    //   const storageRef = storage.ref();
    //   //const firstInitial = firstName.match(/\b(\w)/g)
    //   if(uploadFile !== {}){
    //     const fileRef = storageRef.child("ProInfo").child(email + "-" + firstName + "-" + lastName).child("Licences").child(uploadFile.name)
    //     fileRef.put(uploadFile).then(()=> {
    //     }).then(() => {setUploadFile({})})
    //   }
    // }
  
    const handleSubmit = (event) => {
        event.preventDefault();
    }
    
    return (
        <form variant="outlined" className={classes.formControl} onSubmit={handleSubmit}>
          <label htmlFor={id} className={classes.formLabel}>{!file.name ? "Click to Select File" : file.name}</label>
          <input id={id} type='file' className={classes.form} onChange={updateFile} accept=".jpg,.jpeg,.png,.pdf"/>
          {!file.name ?
            <Button id={`${id}`+'BtnDisabled'}disabled className={classes.uploadBtn}>Upload</Button>
            :
            <Button id={`${id}`+'Btn'} className={classes.uploadBtn} onClick={handleUpload}><span id={`${id}`+'Btn'}>Upload</span></Button>
          }
        </form>

    )
}

export default FileUploader
