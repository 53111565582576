import React from 'react'
import {Container} from '@material-ui/core'
import Availability from './proSettings/Availability'
import Services from './proSettings/Services'

import {Switch, Route} from 'react-router-dom'
import Account from './settings/Account';
import NotificationSettings from './settings/NotificationSettings'
import PaymentInfo from './settings/PaymentInfo'


function Settings({userData, appointments}) {

    return (
        <Container style={{padding: "0", overflowY: "hidden scroll", height: "95vh", borderTop: 'solid 1px #d3d3d3' }}>
              <Switch>
                  {/* <Route exact path='/settings/general' render={() => <GeneralSettings userData={userData}/>} /> */}
                  <Route exact path='/settings/account' render={() => <Account userData={userData} appointments={appointments}/>} />
                  <Route exact path='/settings/availability' render={() => <Availability userData={userData}/>} />
                  <Route exact path='/settings/services' render={() => <Services userData={userData}/>} />
                  <Route exact path='/settings/notifications' render={() => <NotificationSettings userData={userData}/>} />
                  <Route exact path='/settings/payment-info' render={() => <PaymentInfo userData={userData}/>} />
              </Switch>        

        </Container>
    )
}

export default Settings
