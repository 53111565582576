import authTypes from './authTypes'

const initialState = {
    typesOfServices: [],
    services: [],
    titles: [],
}

const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case authTypes.SET_TYPES_OF_SERVICES:
            return {
                ...state,
                typesOfServices: action.payload
            }
        case authTypes.SET_SERVICES:
            return {
                ...state,
                services: action.payload
            }
        case authTypes.SET_TITLES:
            return {
                ...state,
                titles: action.payload
            }
        default:
            return state
    }
}

export default servicesReducer