import React, {useState, useRef, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Switch, IconButton, Modal, TextField, TextareaAutosize, Snackbar, CircularProgress, ModalManager} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    profileContainer:{
      position: 'relative',
      display:"flex",
      flexFlow:'column',
      alignItems:'center',
      padding: 10,
      marginBottom: 12,
    },
    avatar: {
      height: 140,
      width: 140,
    },
    imgSelecter:{
      position:'absolute',
      top: 120,
      left: '52%',
      border: '2px solid white',
      backgroundColor: theme.palette.primary.main,
      '&:hover':{
        cursor:'pointer',
        opacity: .8,
      },
    },
      accordianDetails:{
        width:'100%',
        display:'flex',
        flexFlow:'column',
        textAlign: 'left'
      },
      accordianEditBtn:{
        alignSelf:'flex-end'
      },
      wrapper: {
        height: '100%',
        width: 'fit-content',
        position: 'relative',
      },
      buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
          height: 400,
        },
      },
      cropButton: {
        flexShrink: 0,
        marginLeft: 16,
      },
      controls: {
        width:'100%',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
      },
      sliderLabel: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 65,
        },
      },
      slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
          margin: '0 16px',
        },
      },
  
  }));


function BrandAmThemeEditor(props) {
    const {modalOpen, setModalOpen, designData, userData, themePrimary, themeSecondary, themeLight, uid } = props
    const {theme} = designData
    const [primary, setPrimary] = useState(theme.primary)
    const [secondary, setSecondary] = useState(theme.secondary)
    const [light, setLight] = useState(theme.lightTheme)
    const [template, setTemplate] = useState(theme.template)
    const label = light === false ? 'Light Theme' : 'Dark Theme'
    const classes = useStyles()


    const handleSave = () => {
              firestore.collection('brandAms').doc(uid).collection('design').doc('designInfo').update({
               'theme.lightTheme': light,
               'theme.primary': primary,
               'theme.secondary': secondary,
               'theme.template': template,
               'hero.backgroundColor': theme.lightTheme === true ? '#ffffff' :'#000000',
               'hero.titleColor': theme.lightTheme === false ? '#ffffff' :'#000000',
               'hero.subtitleColor': theme.lightTheme === false ? '#ffffff' :'#000000',
               'about.backgroundColor': theme.lightTheme === true ? '#ffffff' :'#000000',
               'about.titleColor': primary,
               'about.fontColor': theme.lightTheme === false ? '#ffffff' :'#000000',
               'services.backgroundColor': theme.lightTheme === true ? '#ffffff' :'#000000',
               'services.cardColor': theme.lightTheme === true ? '#ffffff' :'#000000',
               'services.titleColor': primary,
               'services.fontColor': theme.lightTheme === false ? '#ffffff' :'#000000',
               'staff.backgroundColor': theme.lightTheme === true ? '#ffffff' :'#000000',
               'staff.cardColor': theme.lightTheme === true ? '#ffffff' :'#000000',
               'staff.titleColor': primary,
               'staff.fontColor': theme.lightTheme === false ? '#ffffff' :'#000000',
               'footer.backgroundColor': theme.lightTheme === true ? '#ffffff' :'#000000',
               'footer.fontColor': theme.lightTheme === false ? '#ffffff' :'#000000',
              })
             
    };

  return (
    <Modal open={!!modalOpen}l>
        <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', maxHeight:'90%'}}>
        <Grid item container justify='space-between' style={{backgroundColor: primary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Edit Theme
                </Typography>
                <IconButton onClick={() => setModalOpen(!modalOpen)} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid container item xs={12} style={{padding:20}}>

               <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10}}>
                   <Typography style={{fontWeight:'bold'}}>Primary</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' defaultValue='#413e68' value={primary} style={{border:'none'}} onChange={(e)=>setPrimary(e.target.value)}/>
                        <input type='text' defaultValue={primary} value={primary} style={{width:'100%', border:'none'}} onChange={(e)=>setPrimary(e.target.value)}/>
                    </span>
               </Grid>
               <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10}}>
                   <Typography style={{fontWeight:'bold'}}>Secondary</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' value={secondary} style={{border:'none'}} onChange={(e)=>setSecondary(e.target.value)}/>
                        <input type='text' value={secondary} style={{width:'100%', border:'none'}} onChange={(e)=>setSecondary(e.target.value)}/>
                    </span>
               </Grid>
               <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10}}>
                   <Typography style={{fontWeight:'bold'}}>Theme</Typography>

                   <Button style={{backgroundColor:light===false?'#ffffff':'#000000', color:light===true?'#ffffff':'#000000', border:'1px solid black'}} onClick={()=> setLight(!light)}>{label}</Button>

               </Grid>

               <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                      <Button
                      variant="contained"
                      style={{  
                      letterSpacing: '2px',marginRight: "1rem" }}
                      onClick={()=> setModalOpen(!modalOpen)}
                      >
                      Cancel
                      </Button>

                      <Button
                      style={{letterSpacing: '2px', backgroundColor:primary, color:secondary}}
                      variant="contained"
                      onClick={handleSave}
                      >
                      Save
                      </Button>
                  </div>



            </Grid>


        </Grid>

    </Modal>
  )
}

export default BrandAmThemeEditor