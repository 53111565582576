import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Grid, Typography, InputBase, Button, Tooltip, CircularProgress, Snackbar } from '@material-ui/core';
import axios from 'axios';
import {firestore} from '../../config/fbConfig'
import firebase from'firebase'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PaymentIcon from '@material-ui/icons/Payment';


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      margin: 5,
      height: 40,
      border: '1px solid grey',
      borderRadius: 6,
      padding: '0px 5px'
    },
    inputContainer: {
        padding: 5
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      border: '3px #413e68 solid',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    formControl: {
        width: '100%'
    },

  }));


function PayBalanceModal(props) {
    const {userData, appt, modalOpen, handleModalOpen, setRRModalOpen} = props
    const apptId = appt.id
    const apptInfo = appt.docData
    const stripe = useStripe();
    const elements = useElements();
    const address = userData.address.split(', ');
    const street = address.shift()
    const city = address.slice(-3,address.length).shift() 
    const state = address.slice(-2,address.length).shift() 
    const zip = '';

    const classes = useStyles();
    const [isProcessing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)
    const [checkoutError, setCheckoutError] = useState(null)

    const cardElementOptions = {
        hidePostalCode: true,
    }

    // const handleFormSubmit = async (e) => {
    //     e.preventDefault()
    //     setProcessing(true)
    //     const addressArr = userData.address

    //     const billingDetails = {
    //         name: apptInfo.clientName,
    //         email: apptInfo.clientEmail,
    //         address: {
    //             city: city,
    //             line1: street,
    //             state: state,
    //             postal_code: zip,
    //         }
    //     };

    //     const {error, paymentMethod} = await stripe.createPaymentMethod({
    //         type: 'card',
    //         card: elements.getElement(CardElement),
    //         billing_details: billingDetails,
    //     })
        
    //     if(!error) {
    //         try {
    //             const {id} = paymentMethod
    //             const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment', {
    //                 amount: parseFloat(apptInfo.amtRemainingBalance) * 100,
    //                 id,
    //                 description: `Cuti Service: ${apptInfo.service} (BALANCE)`,
    //                 customer: userData.stripeId,
    //                 email: userData.email,
    //             })

    //             if(response.data.success){
    //                 // console.log("Successful Payment")     

    //                 //Move to dispatch
    //                 firestore.collection("appointments").doc(apptId).update({
    //                     amtPaid: (parseFloat(apptInfo.amtPaid) + parseFloat(apptInfo.amtRemainingBalance)).toFixed(2),
    //                     amtRemainingBalance: '0',
    //                     clientStatus:'paid'
    //                 })
    //                 .then(() => {
    //                     // console.log("Document successfully written!");
    //                 })
    //                 .then(() => {
    //                     if(apptInfo.serviceComplete === true){
    //                         firestore.collection('customers').doc(userData.userId)
    //                         .update({
    //                             accountStatus:'active',
    //                             apptsScheduled: firebase.firestore.FieldValue.increment(-1),
    //                             apptsCompleted: firebase.firestore.FieldValue.increment(1)
    //                         })
    //                     }
    //                 })
    //                 .catch((error) => {
    //                     console.error("Error writing document: ", error);
    //                 });
    //                 setSuccess(true)
    //                 setProcessing(false)
    //             }
    //         } catch (error) {
    //             // console.log("Error:", error)
    //         }
    //     } else {
    //         // console.log(error.message)
    //     }
    // }

    const handlePayBalance = async (e) => {
        e.preventDefault()
        setProcessing(true)

        const billingDetails = {
            name: apptInfo.clientName,
            email: apptInfo.clientEmail,
            address: {
                city: city,
                line1: street,
                state: state,
                postal_code: zip,
            }
        };

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: billingDetails,
        })
        
        if(!error) {
            console.log(paymentMethod)
            try {
                const {id} = paymentMethod
                const response = (userData && userData.test===false) ? await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment', {
                    amount: apptInfo.amtRemainingBalance * 100,
                    id,
                    description: `Cuti App: ${apptInfo.service} w/ ${apptInfo.proName} (BALANCE)`,
                    customer: userData.customerId,
                    email: userData.email,
                    proStripeId: apptInfo.proStripeId,
                    fee: ((parseFloat(apptInfo.amtRemainingBalance) * 0.029) + .30).toFixed(2) * 100
                })
                :
                await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment-test', {
                    amount: apptInfo.amtRemainingBalance * 100,
                    id,
                    description: `Cuti App: ${apptInfo.service} w/ ${apptInfo.proName} (BALANCE)`,
                    customer: userData.customerId,
                    email: userData.email,
                    proStripeId: apptInfo.proStripeId,
                    fee: ((parseFloat(apptInfo.amtRemainingBalance) * 0.029) + .30).toFixed(2) * 100
                })

                if(response.data.success){
                    // console.log("Successful Payment")     

                    //Move to dispatch
                    firestore.collection("appointments").doc(apptId).update({
                        amtPaid: (parseFloat(apptInfo.amtPaid) + parseFloat(apptInfo.amtRemainingBalance)).toFixed(2),
                        amtRemainingBalance: '0',
                        clientStatus:'paid'
                    })
                    .then(() => {
                        if(apptInfo.serviceComplete === true){
                            firestore.collection('customers').doc(userData.userId)
                            .update({
                                accountStatus:'active',
                            })
                        }
                    })
                    .then(()=> {
                        let promises = []
                        firestore.collection('appointments').where('clientUID','==', `${apptInfo.clientUID}`)
                        .where('serviceComplete', '==', false).where('serviceCanceled','==', false).where('serviceTimeStart', '==', 'n/a')
                        .get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                const id = doc.id
                                console.log(id)
                                const p = firestore.collection('appointments').doc(id).update({clientStatus: 'active'})
                                promises.push(p)
                            });
                            return Promise.all(promises)
                            })
                            .catch((error) => {
                            console.log("Error trying to update all appointments:", error);
                        });
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
                    setSuccess(true)
                    setProcessing(false)
                    setTimeout(()=> setRRModalOpen(true), 1500)
                }
            } catch (error) {
                // console.log("Error:", error)
            }
        } else {
            setFailure(true)
            setProcessing(false)
            setCheckoutError(error.message)
        }
    }

    return (
        <Modal open={!!modalOpen}>
            <Grid xs={11} sm={10} md={5} lg={4} xl={4} container style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>

            <Grid container item xs={12} style={{ padding:'0px 20px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <PaymentIcon style={{color:'#413e68', marginRight:10, fontSize:30}}/>
                <Typography variant='h6' style={{color:"#413e68", fontWeight:'bold'}}>Pay Balance: ${apptInfo.amtRemainingBalance}</Typography>

                <form onSubmit={handlePayBalance} style={{padding:10}}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} md={6} className={classes.inputContainer}>
                            <InputBase placeholder='Full Name' variant="outlined" className={classes.input} fullWidth defaultValue={apptInfo.clientName} />
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.inputContainer}>
                            <InputBase placeholder='Email' variant="outlined" className={classes.input} fullWidth defaultValue={userData.email} /> 
                        </Grid>

                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='Steet Address' variant="outlined" className={classes.input} fullWidth defaultValue={street} />
                        </Grid>
                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='City' variant="outlined" className={classes.input} fullWidth defaultValue={city} />
                        </Grid>

                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='State' variant="outlined" className={classes.input} fullWidth defaultValue={state} />
                        </Grid>
                        <Grid item xs={6} className={classes.inputContainer}>
                            <InputBase placeholder='Zip' variant="outlined" className={classes.input} fullWidth defaultValue={zip} />
                        </Grid>
                        <Grid item xs={12} className={classes.inputContainer}>
                            <div className={classes.input} style={{width: 'inherit',  padding: '10px', margin: '0px 0px 0px 6px', border:'1px solid, grey', borderRadius: 6}}>
                                <CardElement options={cardElementOptions}/>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} style={{display:'flex', justifyContent: 'center'}}>
                        
                        {!!isProcessing ?
                            <div>
                                <CircularProgress style={{color: '#413e68'}}/>
                                <Typography>Processing...</Typography>
                            </div>
                            :
                            <Button
                                type='submit'
                                variant="contained"
                                className={classes.btn}
                                fullWidth
                                style={{ backgroundColor: '#413e68', color: 'white'}}
                                >
                                Pay
                            </Button>
                        }
                    
                    </Grid>
                </form>
                
            </Grid>

            <Snackbar
                open={success}
                autoHideDuration={2000}
                // onClose={()=> {setSuccess(false); window.location.reload();}}
                onClose={()=> {setSuccess(false); handleModalOpen(!modalOpen)}}
                message="Success! You're all wrapped up! 🎉"
            />
            <Snackbar
                open={failure}
                autoHideDuration={2000}
                // onClose={()=> {setSuccess(false); window.location.reload();}}
                onClose={()=> {setFailure(false); setCheckoutError(null)}}
                message={checkoutError}
            />
            </Grid>
            
        </Modal>
    )
}

export default PayBalanceModal
