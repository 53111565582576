import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Avatar, IconButton, Modal, TextField, TextareaAutosize, Snackbar} from '@material-ui/core'
import ScheduleIcon from '@material-ui/icons/Schedule';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import { id } from 'date-fns/locale';
import ServiceCard from '../appointments/ServiceCard';
const { ms, s, m, h, d } = require('time-convert')


function ScheduleProModal(props) {
    const {handleOpenSchedule, scheduleOpen, pro, width} = props
    const [selected, setSelected] = useState() 

    let borderColor = null

    const handleSelect = (service) => {
        setSelected(service)
    }

    const setWidth = (width === 'xs' || width === 'sm') ? 306 : 408
    const setHeight = (width === 'xs' || width === 'sm') ? 534 : 712
    const size = (width === 'xs' || width === 'sm') ? 2 : 10
    
    

    const displayServices = pro.services && pro.services.map((service, idx) => {
            const {name, price, serviceType, time} = service
            const convertedTime = m.to(h,m)(parseFloat(time))
            let icon = "https://img.icons8.com/windows/32/000000/service-bell.png"
            switch (serviceType) {
                case 'Hair':
                    icon = "https://img.icons8.com/ios/50/413e68/hair-brush.png"
                    break;
                case 'Nails':
                    icon = "https://img.icons8.com/ios/50/413e68/nails.png"
                    break;
                case 'Makeup':
                    icon = "https://img.icons8.com/ios/50/413e68/eye-shadows--v1.png"
                    break;
                case 'Facials & Skincare':
                    icon = "https://img.icons8.com/ios/50/413e68/cleansing.png"
                    break;
                case 'Brow & Lashes':
                    icon = "https://img.icons8.com/ios/50/413e68/eyebrow.png"
                    break;
                case 'Massage':
                    icon = "https://img.icons8.com/dotty/80/413e68/hand.png"
                    break;
                case 'Waxing Services':
                    icon = "https://img.icons8.com/ios/50/413e68/waxing.png"
                    break;
                case 'Health & Wellness':
                    icon = "https://img.icons8.com/windows/32/413e68/apple.png"
                    break;
                default:
                    break;
            }
            return(
                <ServiceCard  key={idx} service={service} size={size} name={name} price={price} icon={icon} serviceType={serviceType} time={time} convertedTime={convertedTime} pro={pro} handleSelect={handleSelect}/>
            )
        })
    

    return (
        <Modal open={!!scheduleOpen}>
            <Grid item xs={11} sm={10} md={8} lg={6} xl={5} container style={{outline:'none', height:setHeight, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: '#e6e6e6', borderRadius: '12px', padding:10}}>
                
                <SimpleBarReact autoHide={false} style={{maxHeight: '88%', width: 'inherit', padding:'0px 15px 5px 5px'}}>
                    {displayServices}
                </SimpleBarReact>

                <Grid item xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
                    <Button onClick={handleOpenSchedule} variant='contained' style={{width:150}}>Cancel</Button>
                    <Button onClick={() => console.log(selected, pro)} variant='contained' color='primary' style={{width:150}}>Continue</Button>
                </Grid>
                
            </Grid>
        </Modal>
    )
}

ScheduleProModal.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };
  
export default withWidth()(ScheduleProModal)
