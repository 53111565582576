import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import {Typography, Grid, Avatar, Button, MenuItem, Menu, makeStyles, Tooltip} from "@material-ui/core";
import {NavLink} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
// import StarIcon from '@material-ui/icons/Star';
import SmsIcon from '@material-ui/icons/Sms';
import SettingsIcon from '@material-ui/icons/Settings';
import firebase, {firestore} from '../../config/fbConfig';
import {mean} from 'mathjs';
import {useDispatch} from 'react-redux';
import {setConversationId} from '../../auth/appointmentActions';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import haversine from "haversine-distance"
import moment from 'moment';
// import ListIcon from '@material-ui/icons/List';
import CancelApptModal from '../modals/CancelApptModal';
import UpdatePriceModal from '../modals/UpdatePriceModal';
import ConfirmCompletionModal from '../modals/ConfirmCompletionModal';
import RescheduleModal from '../modals/RescheduleModal';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import  QRCode  from 'qrcode';
import { useHistory } from 'react-router';
import { Event } from '@material-ui/icons';

const { ms, s, m, h, d } = require('time-convert')


function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: theme.palette.primary.secondary,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  card:{
    backgroundColor:'white',
    width:'100%',
    height:'fit-content',
    marginLeft: 0,
    marginBottom:10,
    borderRadius:14,
    boxShadow: '4px 4px 6px -1px rgba(0,0,0,0.20)',
    flexDirection:'row'
},
infoSide: {
    width:'75%',
    height:'100%',
    borderRight:'1px solid lightgrey',
    display:'flex',
    padding:7,
    overflow:'hidden',
    // justifyContent:''
},
with:{
    color:'grey',
    fontSize:12,
    marginVertical:3,
    width:'100%',
    textAlign:'left',
},
service:{
    // fontSize:16,
    fontWeight:'bold',
    width:'100%',
    textAlign:'left',
},
badge:{
    padding:1,
    borderRadius:10,
    width:'32%',
    marginBottom:3
},
badgeText:{
    color:'white',
    fontSize:12,
    fontWeight:'bold',
    textAlign:'center',
    paddingHorizontal:3
},
proInfo:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    marginBottom:8
},
img:{
    width:24,
    height: 24,
    borderRadius:24,
    marginRight:5,
},
btnsContainer:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-evenly',

},
btn:{
    borderRadius:8,
    // backgroundColor:'#413e68',
    width:90,
    height:35,
    justifyContent:'center',
    alignItems:'center'
},
chatBtn:{
    borderRadius:8,
    // backgroundColor: '#413e68',
    flexDirection:'row',
    width:90,
    height:35,
    justifyContent:'center',
    alignItems:'center'
},
btnTxt:{
    color:'white',
},
date:{
    padding:7,
    width:'25%',
    justifyContent:'center',
    alignItems:'center'

},
day:{
    fontSize:24,
    fontWeight:'bold'
},
}));

const AppointmentCard = ({userData, appt, appointments, id}) => {

  const classes = useStyles();
  const history = useHistory()
  const [proRating, setProRating] = useState('')
  const apptInfo = appt.docData
  const fullName = apptInfo.clientName.split(' ');
  const lastName = fullName.pop()
  const firstWInitial = fullName.pop() + ' ' + lastName.charAt(0) + '.'
  const apptId = appt.id
  const [detailsViewed, setDetailsViewed] = useState(false) 
  const [currentTime, setCurrentTime] = useState(new Date())
  const [copied, setCopied] = useState(false)
  const [transition, setTransition] = useState(undefined);
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [distance, setDistance] = useState(null);
  const [startSnackBar, setStartSnackBar] = useState(false);
  const [completeSnackBar, setCompleteSnackBar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [confirmCashModalOpen, setConfirmCashModalOpen] = useState(false)
  const[src, setSrc] = useState()

  var gapi = window.gapi
  var CLIENT_ID = "5074722352-4q8icoa3g669h4v8f3fqltril5spaa56.apps.googleusercontent.com"
  var API_KEY = "AIzaSyDa_I8zG8L-tfutMU97YEkiBSZ1IRopaGw"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = 'https://www.googleapis.com/auth/calendar.events'

  const date = new Date(apptInfo.serviceTimeReq.seconds*1000).toLocaleString('en-US', {day: '2-digit',
  month: '2-digit',year: '2-digit',hour: 'numeric',minute: 'numeric',hour12: true,})

  const addressURL = apptInfo.serviceLocation.split(' ').join('+')

  const border = ((new Date() > new Date(apptInfo.serviceTimeReq.seconds*1000)) && apptInfo.serviceComplete === false) ? '2px solid red': '1px solid #e0e0e0'

  useEffect(() => {
    const timeCheck = () => { 
      setCurrentTime(new Date())
    }

    let unsubscribeFromRating = null;
    unsubscribeFromRating = firestore.collection('professionals').doc(`${apptInfo.proUID}`).get()
    .then((doc) => {
      if (doc.exists) {
          setProRating(((doc.data().ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0)) / doc.data().ratings.length).toFixed(2));
      } else {
          // console.log("No such document!");
      }
    })


    if(loading === true && coordinates !== null){
      if(new Date().getTime() >= new Date((apptInfo.serviceTimeReq.seconds*1000 ) - 1800000).getTime()){
        const distance = haversine(coordinates, apptInfo.serviceCoordinates).toFixed(2)
        // console.log(distance)
        setDistance(distance)
        setLoading(false)
      }else{
        alert(`Cannot start appointment until ${new Date((apptInfo.serviceTimeReq.seconds*1000 ) - 1800000)}!`)
        setLoading(false)
        setCoordinates(null)
      }
      
    }

    if(distance !== null && distance <= 50 && loading===false){
      // console.log('we are here now')
      setLoading(null)
      firestore.collection('appointments').doc(apptId)
      .update({
        serviceTimeStart: new Date(),
        clientStatus:'busy',
      })
      .then(() => {
        firestore.collection('professionals').doc(userData.userId)
        .update({
          busy: true
        })
      })
      .then(() => {
        if(apptInfo.clientStatus !== 'guest'){
          firestore.collection('customers').doc(apptInfo.clientUID)
          .update({
            accountStatus: 'busy'
          })
          .then(()=> {
            let promises = []
          firestore.collection('appointments').where('clientUID','==', `${apptInfo.clientUID}`)
          .where('serviceComplete', '==', false).where('serviceCanceled','==', false).where('serviceTimeStart', '==', 'n/a')
          .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const id = doc.id
                    console.log(id)
                    const p = firestore.collection('appointments').doc(id).update({clientStatus: 'busy'})
                    promises.push(p)
                });
                return Promise.all(promises)
              })
              .catch((error) => {
                console.log("Error trying to update all appointments:", error);
            })
            .then(() => {
              setStartSnackBar(true)
            })
            .then(() =>{
              handleCloseApptStarted()
            }) 
          })

        }else if(apptInfo.clientStatus === 'guest'){
          setStartSnackBar(true)
          handleCloseApptStarted()
        }
      })
      // console.log(distance)
    }

    if(distance !== null && distance >= 51 && loading===null){
      setDistance(null)
      setCoordinates(null)
      setLoading(false)
    }

    if(apptInfo.serviceTimeStart !== 'n/a' && apptInfo.clientStatus === 'busy' && 
      ((new Date(currentTime).getTime() - new Date(apptInfo.serviceTimeStart.seconds*1000).getTime()) < 120000)){
      setInterval(timeCheck, 30000)
      // console.log(currentTime, new Date(currentTime).getTime() - new Date(apptInfo.serviceTimeStart.seconds*1000).getTime())
    }

    // if(userData.address === apptInfo.serviceLocation && checkingTime === null){
    //   setCheckingTime(true)
    // }

    // if(userData.address === apptInfo.serviceLocation &&
    //   (new Date(currentTime).getTime() < new Date(apptInfo.serviceTimeReq.seconds*1000).getTime())){
    //   setInterval(timeCheck, 30000)
    //   setInterval(console.log('Checking Time'), 30000)
    // }


    QRCode.toDataURL(`cutiapp.com/payment/${apptId}`)
    .then((data)=> {
      setSrc(data)
    })


  },[detailsViewed, distance, coordinates, loading, apptInfo, apptId, appointments, currentTime, startSnackBar, completeSnackBar])


  const dispatch = useDispatch()

  const handleSetMessage = () => {
    dispatch(setConversationId(apptId));
    history.push(`/home/messages`)
  }

  const handleView = () => {
    setDetailsViewed(!detailsViewed)
  }

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setCopied(true);
    setTimeout(() => {  setCopied(false); window.open(`https://www.google.com/maps`)}, 2000);
  };

  const handleCloseAddress = () => {
    setCopied(false);
    setStartSnackBar(false);
    setCompleteSnackBar(false);
  };
  const handleCloseApptStarted = () => {
    setTimeout(() => {setStartSnackBar(false);}, 3000)
    
  };
  const handleCloseApptCompleted = () => {
    setTimeout(() => { setCompleteSnackBar(false);}, 3000);
    // setTimeout(() => { window.location.reload();}, 5000);
    // setCompleteSnackBar(false);
  };

  const handleStart = () => {
    if(userData.address !== apptInfo.serviceLocation){
      if(navigator.geolocation){
        setLoading(true)
        navigator.geolocation.getCurrentPosition(position => {
          setCoordinates({lat: position.coords.latitude, lng: position.coords.longitude})
        })
      }
      // TODO: Handle geoloaction error -- Allow popup override to start appt
    }else{
      // console.log('equal')
      setLoading(true)
      setCoordinates(userData.coordinates)
    }
  }

  

  const handleComplete = () => {
    if(parseFloat(apptInfo.amtRemainingBalance) === 0){
      firestore.collection('appointments').doc(apptId)
      .update({
        serviceTimeEnd: new Date(),
        clientStatus:'paid',
        // serviceTimeStart: apptInfo.serviceTimeStart !== 'n/a' ? new Date(apptInfo.serviceTimeStart.seconds*1000) : new Date(apptInfo.serviceTimeReq.seconds*1000),
        serviceComplete: true,
      }).then(() => {
        if(!apptInfo.clientUID.includes('guestUser')){
          firestore.collection('customers').doc(apptInfo.clientUID)
          .update({
            accountStatus: 'active',
          })
        }
        else{
          firestore.collection('customers').doc(apptInfo.clientUID)
          .update({
            accountStatus: 'guest',
          })
        }
      })
      .then(() => 
        firestore.collection('professionals').doc(apptInfo.proUID)
        .update({
          apptsCompleted: firebase.firestore.FieldValue.increment(1),
          apptsScheduled: firebase.firestore.FieldValue.increment(-1),
          busy: false
        })
        .then(() =>
        setCompleteSnackBar(true))
        )
    }
    else {
      firestore.collection('appointments').doc(apptId)
      .update({
        clientStatus: 'paused',
        serviceTimeEnd: new Date(),
        // serviceTimeStart: apptInfo.serviceTimeStart !== 'n/a' ? new Date(apptInfo.serviceTimeStart.seconds*1000) : new Date(apptInfo.serviceTimeReq.seconds*1000),
        serviceComplete: true,
      }).then(()=>{
        if(!apptInfo.clientUID.includes('guestUser')){
          firestore.collection('customers').doc(apptInfo.clientUID)
          .update({
            accountStatus: 'paused',
            apptsCompleted: firebase.firestore.FieldValue.increment(1),
            apptsScheduled: firebase.firestore.FieldValue.increment(-1),
          })
          .then(()=> {
            let promises = []
            firestore.collection('appointments').where('clientUID','==', `${apptInfo.clientUID}`)
            .where('serviceComplete', '==', false).where('serviceCanceled','==', false).where('serviceTimeStart', '==', 'n/a')
            .get()
              .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const id = doc.id
                      console.log(id)
                      const p = firestore.collection('appointments').doc(id).update({clientStatus: 'paused'})
                      promises.push(p)
                  });
                  return Promise.all(promises)
                })
                .catch((error) => {
                  console.log("Error trying to update all appointments:", error);
              });
          })
        }
      })
      .then(() => 
        firestore.collection('professionals').doc(apptInfo.proUID)
        .update({
          apptsCompleted: firebase.firestore.FieldValue.increment(1),
          apptsScheduled: firebase.firestore.FieldValue.increment(-1),
          busy: false
        }).then(() =>
        setCompleteSnackBar(true))
        )
    }
  }

  const handlePaidWithCash = () => {
    firestore.collection('appointments').doc(apptId)
      .update({
        amtPaid: (parseFloat(apptInfo.amtPaid) + parseFloat(apptInfo.amtRemainingBalance)).toFixed(2),
        amtRemainingBalance: '0',
        clientStatus:'paid balance with cash',
        // serviceTimeStart: apptInfo.serviceTimeStart !== 'n/a' ? apptInfo.serviceTimeStart : apptInfo.serviceTimeReq,
        serviceTimeEnd: new Date(),
        serviceComplete: true,
        tip: '0.00',
      }).then(() => {
        if (!apptInfo.clientUID.includes('guestUser')){
          firestore.collection('customers').doc(apptInfo.clientUID)
          .update({
            accountStatus: 'active',
          })
          .then(()=> {
            let promises = []
            firestore.collection('appointments').where('clientUID','==', `${apptInfo.clientUID}`)
            .where('serviceComplete', '==', false).where('serviceCanceled','==', false).where('serviceTimeStart', '==', 'n/a')
            .get()
              .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const id = doc.id
                      console.log(id)
                      const p = firestore.collection('appointments').doc(id).update({clientStatus: 'active'})
                      promises.push(p)
                  });
                  return Promise.all(promises)
                })
                .catch((error) => {
                  console.log("Error trying to update all appointments:", error);
              });
          })  
        }else{
          firestore.collection('customers').doc(apptInfo.clientUID)
          .update({
            accountStatus: 'guest',
          })
        }
      })
      .then(() => 
        firestore.collection('professionals').doc(apptInfo.proUID)
        .update({
          apptsCompleted: firebase.firestore.FieldValue.increment(1),
          apptsScheduled: firebase.firestore.FieldValue.increment(-1),
          busy: false
        })
        .then(() =>
        setCompleteSnackBar(true))
        )
  }

  const handleOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(!cancelModalOpen)
  }
  const handleReschedule = () => {
    setRescheduleModalOpen(!rescheduleModalOpen)
  }

  const handleUpdateModalOpen = () => {
    setUpdateModalOpen(!updateModalOpen)
  }

  const handleConfirmModalOpen = () => {
    setConfirmModalOpen(!confirmModalOpen)
    handleCloseApptCompleted()
  }

  const handleConfirmCashModalOpen = () => {
    setConfirmCashModalOpen(!confirmCashModalOpen)
    handleCloseApptCompleted()
  }

  const handleNavigate = () => {
    const queryAddress = apptInfo.serviceLocation.split(" ").join("+")
    window.open(`https://www.google.com/maps/place/${queryAddress}`)
  }

  const handleJoinMeeting = () => {
    window.open(`${apptInfo.serviceLocation}`)
  }

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleAddToCalendar= () => {
    const t = (m.to(ms)(new Date(apptInfo.serviceTimeEnd.seconds*1000)))
    
      gapi.load('client:auth2', () => {
          console.log('loaded client')
          // console.log(CLIENT_ID)
          gapi.client.init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              discoveryDocs: DISCOVERY_DOCS,
              scope: SCOPES,
          })
  
          gapi.client.load('calendar', 'v3', ()=> console.log('calender load...'))
  
          gapi.auth2.getAuthInstance().signIn()
          .then(() => {
            console.log('adding event!!')
            var event = {
              'summary': `${apptInfo.service + ": " + apptInfo.proName + " <> " + apptInfo.clientName}`,
              'location': `${apptInfo.serviceLocation}`,
              'description': `You have a Scheduled Appointment Via BizzyBook: ${apptInfo.service}`,
              'start': {
                'dateTime': new Date(apptInfo.serviceTimeReq.seconds*1000).toISOString(),
                'timeZone': 'America/Los_Angeles'
              },
              'end': {
                'dateTime': new Date(apptInfo.serviceTimeEnd.seconds*1000).toISOString(), //this is an iso
                'timeZone': 'America/Los_Angeles'
              },
              'recurrence': [
                // 'RRULE:FREQ=DAILY;COUNT=2'
              ],
              'attendees': [
                {'email': `${apptInfo.clientEmail}`},
              ],
              'reminders': {
                'useDefault': false,
                'overrides': [
                  {'method': 'email', 'minutes': 24*60},
                  {'method': 'email', 'minutes': 60},
                  {'method': 'popup', 'minutes': 10}
                ]
              }
            }
  
            var request = gapi.client.calendar.events.insert({
              'calendarId': 'primary',
              'resource': event,
            })
  
            request.execute( event => 
              window.open(event.htmlLink))
          }).then(() => setTimeout(()=> {window.location.reload()}, 20000)) 
      })

      firestore.collection('appointments').doc(apptId)
      .update({
        addedToCalendar: true
      }) 
  
  }
  
  
    return (
        <Grid key={id} container item xs={12} className={classes.card}>

            <Grid item container className={classes.infoSide}>
              
              <Grid item container xs={12} style={{height:'fit-content', display:'flex', flexFlow:'row', position:'relative'}}>
                
                <div style={{width:'100%', height:20}}>
                  {apptInfo.serviceCanceled === true &&
                    <div className={classes.badge} style={{backgroundColor:'grey'}}>
                        <Typography className={classes.badgeText}>Canceled</Typography>
                    </div>
                  }
                    

                  {((new Date().getTime() > (new Date(apptInfo.serviceTimeReq.seconds*1000).getTime() + 900000)) && apptInfo.clientStatus !== 'suspended') &&
                    <Tooltip title={`Looks like we're a little behind schedule. Try reaching out to your client.`}>
                      <div className={classes.badge} style={{backgroundColor:'red', marginLeft:3}}>
                        <Typography className={classes.badgeText}>LATE</Typography>
                        </div>
                    </Tooltip>
                    }
                </div>

                  {(apptInfo.serviceComplete === false && apptInfo.serviceCanceled === false && (apptInfo.addedToCalendar === undefined || apptInfo.addedToCalendar === false)) &&
                  <Tooltip title={`Add this event to your calendar`}>
                      <div className={classes.badge} style={{position:'absolute', right:0, backgroundColor:'#413e68', width:'fit-content', padding:'1px 10px'}} onClick={handleAddToCalendar}>
                        <Typography className={classes.badgeText}>ADD TO <Event style={{verticalAlign:'middle', fontSize:17}}/></Typography>
                      </div>
                  </Tooltip>
                  }

              </Grid>

                <Typography className={classes.service}>{apptInfo.service.length > 29 ? apptInfo.service.slice(0,26)+"..." : apptInfo.service}</Typography>
                <Typography className={classes.with}>{userData.collection === 'customers' ? 'with': 'for'} {userData.collection === 'customers' ? apptInfo.proName.split(" ").shift() : apptInfo.clientName}</Typography>
                
                <Grid className={classes.proInfo}>
                    <img className={classes.img} alt='avatar' src={userData.collection === 'customers' ? apptInfo.proAvatar : apptInfo.clientAvatar}/>
                    <Typography className={classes.proName}>{userData.collection === 'customers' ? apptInfo.proName : apptInfo.clientName}</Typography>
                </Grid>

                <Grid item container className={classes.btnsContainer}>
                    {(apptInfo.serviceComplete === true || apptInfo.serviceCanceled === true) ?
                    <Button variant='contained' color='primary' className={classes.btn}>
                        <Typography className={classes.btnTxt}>Book Again</Typography>
                    </Button>
                    :
                    <Button variant='contained' color='primary' className={classes.btn} onClick={handleOptions}>
                        <Typography className={classes.btnTxt} aria-controls="options-menu" aria-haspopup="true">Options</Typography>
                    </Button>
                    }


                    {(apptInfo.serviceComplete === false && apptInfo.serviceCanceled === false) &&
                    <Button variant='contained' color='primary' className={classes.chatBtn} onClick={handleSetMessage}>
                        <SmsIcon style={{fontSize:24, color:'white'}}/>
                        <Typography className={classes.btnTxt}>Chat</Typography>
                    </Button>
                    }

                  <div onClick={handleView} className={classes.badge} style={{display:'flex', justifyContent:'center', alignItems:'center', width:36, height:36, borderRadius:8, border:'1px solid #413e68'}}>
                     {!detailsViewed ? 
                     <KeyboardArrowDownIcon style={{color:'#413e68'}}/>
                     :
                     <KeyboardArrowUpIcon style={{color:'#413e68'}}/>
                     }
                  </div>

                </Grid>


            </Grid>

            <Grid item container className={classes.date}>
              <Grid item container style={{width:'100%', borderBottom:'1px solid lightgrey', marginBottom:10, alignItems:'center'}}>
                  <Typography style={{width:'100%', textAlign:'center', fontSize:10}}>BALANCE:</Typography>
                  <Typography style={{width:'100%', textAlign:'center', fontWeight:'bold'}}>${apptInfo.amtRemainingBalance}</Typography>
              </Grid>
              <Typography style={{width:'100%', textAlign:'center',}}>{(apptInfo.serviceComplete === false && apptInfo.serviceCanceled === false) ? moment(new Date(apptInfo.serviceTimeReq.seconds*1000)).format('MMM') : moment(new Date(apptInfo.serviceTimeEnd.seconds*1000)).format('MMM')}</Typography>
              <Typography className={classes.day} style={{width:'100%', textAlign:'center',}}>{(apptInfo.serviceComplete === false && apptInfo.serviceCanceled === false) ?  moment(new Date(apptInfo.serviceTimeReq.seconds*1000)).format('Do') : moment(new Date(apptInfo.serviceTimeEnd.seconds*1000)).format('Do')}</Typography>
              <Typography style={{width:'100%', textAlign:'center',}}>{(apptInfo.serviceComplete === false && apptInfo.serviceCanceled === false) ? moment(new Date(apptInfo.serviceTimeReq.seconds*1000)).format('h:mm a') : new Date(apptInfo.serviceTimeEnd.seconds*1000)}</Typography>
            </Grid>

            {!!detailsViewed &&
                  <Grid item xs={12} container style={{borderTop:'1px solid #878787', display:'inline-block', minHeight:200, marginTop: 5, padding:7}}>
                    <Grid item xs={12} container style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:10}}>
                   
                      {/* <div style={{width:'100%'}}>
                        <Typography style={{fontSize: 12, color:'#878787', width:'100%', textAlign:'left', borderTop:'1px solid lightgrey', fontWeight:'bold'}}>Service Cost:</Typography>
                        <Typography>${apptInfo.serviceFee}</Typography>
                      </div> */}
                      <Grid item container xs={8} sm={7} style={{lineBreak:'anywhere'}}>
                        <Typography style={{fontSize: 12, color:'#878787', width:'100%', textAlign:'left',}}><span style={{fontWeight:'bold'}}>Location:</span> {apptInfo.serviceLocation}</Typography>
                      </Grid>

                      <Grid item container xs={3} md={5} style={{padding:'0px 10px'}}>
                      {apptInfo.serviceLocation.includes('https') ? 
                        <Button fullWidth variant='outlined' color='primary' onClick={handleJoinMeeting}>Join</Button>
                        :
                        <Button fullWidth variant='outlined' color='primary' onClick={handleNavigate}>Maps</Button>
                      }
                      </Grid>

                    </Grid>

                    <Grid item container xs={12} style={{}}>
                      <Typography style={{fontSize: 12, color:'#878787', width:'100%', textAlign:'left', borderTop:'1px solid lightgrey', marginTop:5}}><span style={{fontWeight:'bold'}}>Pets:</span> {apptInfo.clientPets === "" ? 'n/a' : apptInfo.clientPets}</Typography>
                      
                      {apptInfo.serviceAddOns !== undefined &&
                      <Typography style={{fontSize: 12, color:'#878787', width:'100%', textAlign:'left', borderTop:'1px solid lightgrey', marginTop:5}}>
                        <span style={{fontWeight:'bold'}}>AddOn Service(s): </span>
                        {apptInfo.serviceAddOns.map(addOn => {
                          return(
                            <span> {addOn.name}, </span>
                          )
                        })}
                        </Typography>
                      }

                      <Typography style={{fontSize: 12, color:'#878787', width:'100%', textAlign:'left', borderTop:'1px solid lightgrey', marginTop:5}}><span style={{fontWeight:'bold'}}>Notes:</span> {apptInfo.clientNotes !== null ? apptInfo.clientNotes : 'none'}</Typography>

                    </Grid>

                    <Grid item xs={12} style={{marginTop:5}}>
                    </Grid>

                    <Grid item container xs={12} style={{justifyContent:'space-evenly', marginTop:20}}>

                      <Grid item xs={5}>
                      {(distance === null && apptInfo.serviceTimeStart === 'n/a' && userData.address !== apptInfo.serviceLocation && 
                        apptInfo.clientStatus !== 'paused'&& apptInfo.clientStatus === 'suspended' && (apptInfo.clientStatus === 'active' || apptInfo.clientStatus === 'guest')) &&
                        <div className={classes.wrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            fullWidth
                            disabled={loading}
                            onClick={handleStart}
                          >
                            START
                          </Button>
                          {loading === true && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      }
                      
                      {userData.address === apptInfo.serviceLocation ?
                      <>
                        {apptInfo.amtRemainingBalance !== "0" ?
                          <Button fullWidth variant='contained' color='primary' onClick={handleConfirmModalOpen}>Checkout</Button>
                          :
                          <Button fullWidth variant='contained' color='primary' onClick={handleConfirmModalOpen}>Complete</Button>
                        }
                      </>
                      :
                      apptInfo.serviceLocation.includes('zoom') ?
                        <Button fullWidth variant='contained' color='primary' onClick={handleConfirmModalOpen}>Complete</Button>
                      :
                      null
                      }

                      { apptInfo.clientStatus === 'paused' &&
                      <div className={classes.wrapper} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                      <Tooltip title={`${apptInfo.clientName} is currently unavailable because he/she is concluding another appointment. If you are not able to start the appointment, you may cancel. You will recieve: $${apptInfo.amtPaid} as an inconvenience fee.`}>
                          <Typography style={{fontSize:10}}>Customer is currently unavailable.</Typography>
                        </Tooltip>
                      </div>
                      }
                      { apptInfo.clientStatus === 'suspended' &&
                        <div className={classes.wrapper} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                          <Tooltip title={`${apptInfo.clientName} is currently unable to request any additional services. You may cancel the appointment. You will recieve: $${apptInfo.amtPaid} as an inconvenience fee.`}>
                            <Typography style={{fontSize:10}}>Cannot perform service.</Typography>
                          </Tooltip>
                        </div>
                      }


                      
                        {((new Date(currentTime).getTime() - new Date(apptInfo.serviceTimeStart.seconds*1000).getTime()) / 60000)  > 1 ?
                          apptInfo.amtRemainingBalance !== "0" ?
                            <Button fullWidth variant='contained' color='primary' onClick={handleConfirmModalOpen}>Checkout</Button>
                            :
                            <Button fullWidth variant='contained' color='primary' onClick={handleConfirmModalOpen}>Complete</Button>
                          :
                          apptInfo.serviceTimeStart !== 'n/a' ?
                            <Button fullWidth variant='contained' color='primary' disabled>Appt Started</Button>
                          :
                            null
                        } 
                        

                      </Grid>
                    </Grid>

                  </Grid>
                }

            <ConfirmCompletionModal
              modalOpen={confirmModalOpen}
              handleModalOpen={handleConfirmModalOpen}
              appt={appt}
              userData={userData}
              handleComplete={handleComplete}
              handlePaidWithCash={handlePaidWithCash}
              setConfirmCashModalOpen={setConfirmCashModalOpen}
              confirmCashModalOpen={confirmCashModalOpen}
              src={src}
              />

            <UpdatePriceModal modalOpen={updateModalOpen} handleModalOpen={handleUpdateModalOpen} handleMenuClose={handleMenuClose} appt={appt} userData={userData}/>
            <RescheduleModal modalOpen={rescheduleModalOpen} handleModalOpen={handleReschedule} handleMenuClose={handleMenuClose} appt={appt} userData={userData} scheduledAppointments={appointments}/>
            <CancelApptModal modalOpen={cancelModalOpen} handleModalOpen={handleCancelModalOpen} appt={appt} userData={userData} handleMenuClose={handleMenuClose}/>
            {(apptInfo.clientStatus === 'busy'  && apptInfo.serviceTimeStart === 'n/a' && (new Date().getTime() < (new Date(apptInfo.serviceTimeReq.seconds*1000).getTime() + 900000))) &&
            <Tooltip title={`${apptInfo.clientName.split(' ')[0]} is currently in another appointment. You cannot start this appointment now. If your appointment is coming up within the next 30 mins, feel free to reach out to your client.`}>
              <div style={{padding:"2px 6px", color:'white', fontSize:10, marginTop:50, letterSpacing:1, fontWeight:'bold', borderRadius:20, border:'2px solid white', backgroundColor:'#0072ff', position:'absolute'}}>BUSY</div>
            </Tooltip>
            }
            {(apptInfo.clientStatus === 'paused' && apptInfo.serviceTimeStart === 'n/a' && (new Date().getTime() < (new Date(apptInfo.serviceTimeReq.seconds*1000).getTime() + 900000))) &&
            <Tooltip title={`${apptInfo.clientName.split(' ')[0]} has an open balance with another pro. For your safety you cannot start this appointment until their balance is paid. You may choose to cancel if you are unable to start the appointment 30 mins prior to the scheduled time.`}>
              <div style={{padding:"2px 6px", fontSize:10, marginTop:50, letterSpacing:1, fontWeight:'bold', borderRadius:20, border:'2px solid white', backgroundColor:'orange', position:'absolute'}}>BUSY</div>
            </Tooltip>
            }
            {apptInfo.clientStatus === 'suspended' &&
            <Tooltip title={`${apptInfo.clientName.split(' ')[0]} has an unpaid balance with another pro. For your safety you cannot start this appointment. Feel free to cancel.`}>
              <div style={{padding:"2px 6px", color:'white', fontSize:10, marginTop:50, letterSpacing:1, fontWeight:'bold', borderRadius:20, border:'2px solid white', backgroundColor:'red', position:'absolute'}}>SUSP</div>
            </Tooltip>
            }



                <Snackbar
                    open={copied}
                    onClose={handleCloseAddress}
                    TransitionComponent={transition}
                    message="Address Copied... Redirecting you to Google Maps!"
                    key={transition ? transition.name : ''}
                />

                <Snackbar
                    open={startSnackBar}
                    onClose={handleCloseApptStarted}
                    TransitionComponent={transition}
                    style={{zIndex:100}}
                    //Set message based on distance: "Appointment Started! 🎉" / "Oops, looks like you're not close enough to start this appointment... try again!"
                    message="Appointment Started! 🎉"
                    key={transition ? transition.name : ''}
                    />

                <Snackbar
                    open={completeSnackBar}
                    onClose={handleCloseApptCompleted}
                    TransitionComponent={transition}
                    style={{zIndex:100}}
                    //Set message based on distance: "Appointment Started! 🎉" / "Oops, looks like you're not close enough to start this appointment... try again!"
                    message="Appointment Completed! 🎉"
                    key={transition ? transition.name : ''}
                />


            <Menu
              id="options-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {(apptInfo.serviceTimeStart === "n/a" && apptInfo.serviceFeeUpdate !== undefined && apptInfo.serviceFeeUpdate === true) && <MenuItem onClick={handleUpdateModalOpen}>UPDATE PRICE</MenuItem>}
              {apptInfo.serviceTimeStart === "n/a" &&
              <MenuItem onClick={handleReschedule}>RESCHEDULE</MenuItem>
              }
              <MenuItem onClick={handleCancelModalOpen}>CANCEL</MenuItem>
            </Menu>
        </Grid>
    )
}

export default AppointmentCard
