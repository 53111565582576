import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  InputBase,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
//import StripeCheckout from 'react-stripe-checkout';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { firestore } from "../../../config/fbConfig";
import firebase from "firebase";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const { ms, m } = require("time-convert");

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "inherit",
    height: "60px",
    boxShadow: "none",
    border: "1px solid lightgray",
  },
  input: {
    margin: 5,
    height: 40,
    border: "1px solid grey",
    borderRadius: 6,
    padding: "0px 5px",
  },
  inputContainer: {
    padding: 5,
  },
  iconButton: {
    color: "#413e68",
    fontSize: "40px",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  btn: {
    fontWeight: "bold",
    border: "3px #413e68 solid",
    letterSpacing: "2px",
    marginTop: "1rem",
    height: "48px",
    width: 160,
  },
  formControl: {
    width: "100%",
  },
}));

export const Checkout = ({
  formData,
  setForm,
  navigation,
  userData,
  selectedService,
}) => {
  const { client, pro } = formData;
  const stripe = useStripe();
  const elements = useElements();
  // console.log(client)

  const classes = useStyles();
  const [isProcessing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);

  const [name, setName] = useState(client.name === "" ? "" : client.name);
  const [email, setEmail] = useState(client.email === "" ? "" : client.email);
  const [phone, setPhone] = useState(client.phone === "" ? "" : client.phone);
  const {
    serviceCost,
    travelFee,
    bookingFee,
    service,
    reqDeposit,
    addOnPrice,
    addOns,
  } = formData;
  const [address, setAddress] = useState(
    userData.address !== undefined ? userData.address.split(", ") : ""
  );
  const [street, setStreet] = useState(
    userData.address !== undefined ? address.shift() : ""
  );
  const [city, setCity] = useState(
    userData.address !== undefined
      ? address.slice(-3, address.length).shift()
      : ""
  );
  const [state, setState] = useState(
    userData.address !== undefined
      ? address.slice(-2, address.length).shift()
      : ""
  );
  const [zip, setZip] = useState("");
  const total = (
    parseFloat(serviceCost) +
    parseFloat(travelFee) +
    addOnPrice +
    parseFloat(bookingFee)
  ).toFixed(2);
  //const deposit = (parseFloat(serviceCost / 4) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)
  const deposit =
    reqDeposit === true
      ? (
          parseFloat(serviceCost / 4) +
          parseFloat(travelFee) +
          parseFloat(bookingFee)
        ).toFixed(2)
      : (parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2);
  const balance = (total - deposit).toFixed(2);
  // const depositAppFee = Math.ceil(parseFloat(parseFloat(bookingFee) - (((deposit * 0.029) + .30) + ((balance * 0.029) + .30))).toFixed(2) * 100)

  const depositAppFee =
    bookingFee * 100 - (balance * 0.029 + 0.3).toFixed(2) * 100;
  const [emails, setEmails] = useState([]);

  // console.log(formData)

  const cardElementOptions = {
    hidePostalCode: true,
  };

  const handlePayDeposit = async (e) => {
    e.preventDefault();
    client.name = name;
    client.email = email;
    client.phone = phone;

    var clientNumCleaned = ("" + `${client.phone}`).replace(/\D/g, "");
    var clientNumMatch = clientNumCleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var clientNumE164 = [
      "+1",
      clientNumMatch[2],
      clientNumMatch[3],
      clientNumMatch[4],
    ].join("");
    var proNumCleaned = ("" + `${pro.phone}`).replace(/\D/g, "");
    var proNumMatch = proNumCleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var proNumE164 = [
      "+1",
      proNumMatch[2],
      proNumMatch[3],
      proNumMatch[4],
    ].join("");

    setProcessing(true);

    const billingDetails = {
      name: name,
      email: email,
      address: {
        city: city,
        line1: street,
        state: state,
        postal_code: zip,
      },
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: billingDetails,
    });

    if (!error) {
      if (userData.test === false) {
        console.log("Hello, Im not a test account");
        try {
          const { id } = paymentMethod;
          const response = await axios.post(
            "https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment",
            {
              amount: deposit * 100,
              id,
              description: `Cuti App: ${service} w/ ${pro.name} (DEPOSIT)`,
              customer: userData.customerId,
              email: userData.email,
              proStripeId: pro.stripeId,
              fee: depositAppFee,
            }
          );

          if (response.data.success) {
            console.log("Successful Payment");

            const t = m.to(ms)(formData.serviceTimeEnd);
            setSuccess(true);
            setProcessing(false);
            //Move to dispatch
            firestore
              .collection("appointments")
              .add({
                clientEmail: client.email,
                clientName: client.name,
                clientUID: client.userId,
                clientRating: "n/a",
                clientAvatar: client.avatar,
                clientPets: formData.petTypes,
                clientNotes: formData.notes,
                clientStatus: client.accountStatus,
                clientStripeId: client.customerId,
                clientPhone: clientNumE164,
                proEmail: pro.email,
                proName: pro.name,
                proPhone: proNumE164,
                proUID: pro.userId,
                proAvatar: pro.avatar,
                // proPaidOut:false,
                proStripeId: pro.stripeId,
                service: formData.service,
                serviceAddOns: addOns,
                serviceFee: formData.serviceCost,
                serviceFeeUpdate: formData.priceVaries,
                serviceFeeTravel: formData.travelFee,
                serviceFeeBooking: formData.bookingFee,
                serviceComplete: false,
                serviceCanceled: false,
                serviceTimeReq: new Date(formData.dateTime),
                serviceTimeStart: "n/a",
                serviceTimeEnd: new Date(
                  parseFloat(t.join()) + new Date(formData.dateTime).getTime()
                ),
                serviceLocation: formData.address,
                serviceCoordinates: formData.startingCoordinates,
                serviceRating: "n/a",
                amtPaid: deposit,
                amtRemainingBalance: balance,
                messages: [],
                tip: "",
                virtual:
                  selectedService.virtual !== undefined
                    ? selectedService.virtual
                    : false,
                //serviceType:??
              })
              .then(() => {
                firestore
                  .collection("professionals")
                  .doc(formData.pro.userId)
                  .update({
                    apptsScheduled: firebase.firestore.FieldValue.increment(1),
                  });
              })
              .then(() => {
                firestore
                  .collection("customers")
                  .doc(userData.userId)
                  .update({
                    apptsScheduled: firebase.firestore.FieldValue.increment(1),
                  });
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
            setSuccess(true);
            setProcessing(false);
          } else {
            console.log("response data: ", response.data);
            setProcessing(false);
            setCheckoutError("Payment Failed. Please Try again.");
            setFail(true);
          }
        } catch (error) {
          console.log("Oops, there's an issue:", error);
        }
      } else if (userData.test === true) {
        console.log("Hello, Im a test account");
        try {
          const { id } = paymentMethod;
          const response = await axios.post(
            "https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment-test",
            {
              amount: deposit * 100,
              id,
              description: `Cuti App: ${service} w/ ${pro.name} (DEPOSIT)`,
              customer: userData.customerId,
              email: userData.email,
              proStripeId: pro.stripeId,
              fee: depositAppFee,
            }
          );

          if (response.data.success) {
            console.log("Successful Payment");
            const t = m.to(ms)(formData.serviceTimeEnd);
            setSuccess(true);
            setProcessing(false);
            //Move to dispatch
            firestore
              .collection("appointments")
              .add({
                clientEmail: client.email,
                clientName: client.name,
                clientUID: client.userId,
                clientRating: "n/a",
                clientAvatar: client.avatar,
                clientPets: formData.petTypes,
                clientNotes: formData.notes,
                clientStatus: client.accountStatus,
                clientStripeId: client.customerId,
                clientPhone: clientNumE164,
                proEmail: pro.email,
                proName: pro.name,
                proPhone: proNumE164,
                proUID: pro.userId,
                proAvatar: pro.avatar,
                // proPaidOut:false,
                proStripeId: pro.stripeId,
                service: formData.service,
                serviceAddOns: addOns,
                serviceFee: formData.serviceCost,
                serviceFeeUpdate: formData.priceVaries,
                serviceFeeTravel: formData.travelFee,
                serviceFeeBooking: formData.bookingFee,
                serviceComplete: false,
                serviceCanceled: false,
                serviceTimeReq: new Date(formData.dateTime),
                serviceTimeStart: "n/a",
                serviceTimeEnd: new Date(
                  parseFloat(t.join()) + new Date(formData.dateTime).getTime()
                ),
                serviceLocation: formData.address,
                serviceCoordinates: formData.startingCoordinates,
                serviceRating: "n/a",
                amtPaid: deposit,
                amtRemainingBalance: balance,
                messages: [],
                tip: "",
                virtual:
                  selectedService.virtual !== undefined
                    ? selectedService.virtual
                    : false,
              })
              .then(() => {
                firestore
                  .collection("professionals")
                  .doc(formData.pro.userId)
                  .update({
                    apptsScheduled: firebase.firestore.FieldValue.increment(1),
                  });
              })
              .then(() => {
                firestore
                  .collection("customers")
                  .doc(userData.userId)
                  .update({
                    apptsScheduled: firebase.firestore.FieldValue.increment(1),
                  });
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
            setSuccess(true);
            setProcessing(false);
          } else {
            console.log("response data: ", response);
            setProcessing(false);
            setCheckoutError("Payment Failed. Please Try again.");
            setFail(true);
          }
        } catch (error) {
          console.log("Oops, there's an issue:", error);
        }
      } else if (client.accountStatus === "guest") {
        console.log("Hello, Im a guest account");
        try {
          const { id } = paymentMethod;
          const response = await axios.post(
            "https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment-test",
            {
              amount: deposit * 100,
              id,
              description: `Cuti App: ${service} w/ ${formData.pro.name} (DEPOSIT)`,
              customer: userData.customerId,
              email: formData.client.email,
              proStripeId: formData.pro.stripeId,
              fee: depositAppFee,
            }
          );

          if (response.data.success) {
            let guestUsers = [];
            console.log("Successful Payment");
            const t = m.to(ms)(formData.serviceTimeEnd);
            setSuccess(true);
            setProcessing(false);
            //Move to dispatch
            firestore
              .collection("customers")
              .where("accountStatus", "==", "guest")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  const guestData = doc.data();
                  guestUsers.push(guestData);
                  return Promise.all(guestUsers);
                });
              })
              .then(() => {
                if (
                  guestUsers.filter((user) => user.email === client.email)
                    .length === 1
                ) {
                  const guestData = guestUsers.filter(
                    (user) => user.email === client.email
                  )[0];
                  console.log(
                    "Guest user exists. Appt being created user is being updated!"
                  );
                  firestore
                    .collection("appointments")
                    .add({
                      clientEmail: client.email,
                      clientName: client.name,
                      clientUID: guestData.userId,
                      clientRating: "n/a",
                      clientAvatar: client.avatar,
                      clientPets: formData.petTypes,
                      clientNotes: formData.notes,
                      clientStatus: client.accountStatus,
                      // clientStripeId: client.customerId,
                      clientPhone: clientNumE164,
                      proEmail: pro.email,
                      proName: pro.name,
                      proPhone: proNumE164,
                      proUID: pro.userId,
                      proAvatar: pro.avatar,
                      // proPaidOut:false,
                      proStripeId: pro.stripeId,
                      service: formData.service,
                      serviceFee: formData.serviceCost,
                      serviceFeeUpdate: formData.priceVaries,
                      serviceFeeTravel: formData.travelFee,
                      serviceFeeBooking: formData.bookingFee,
                      serviceComplete: false,
                      serviceCanceled: false,
                      serviceTimeReq: new Date(formData.dateTime),
                      serviceTimeStart: "n/a",
                      serviceTimeEnd: new Date(
                        parseFloat(t.join()) +
                          new Date(formData.dateTime).getTime()
                      ),
                      serviceLocation: formData.address,
                      serviceCoordinates: formData.startingCoordinates,
                      serviceRating: "n/a",
                      amtPaid: deposit,
                      amtRemainingBalance: balance,
                      messages: [],
                      tip: "",
                      virtual:
                        selectedService.virtual !== undefined
                          ? selectedService.virtual
                          : false,
                    })
                    .then(() => {
                      firestore
                        .collection("customers")
                        .doc(guestData.userId)
                        .update({
                          lastUpdated: new Date(),
                        });
                    });
                } else {
                  console.log(
                    "Guest is a new user. Appt being created and guest data is being saved for thier return"
                  );
                  firestore
                    .collection("customers")
                    .doc(client.userId)
                    .set({
                      accountStatus: client.accountStatus,
                      address: `${street.toUpperCase()}, ${city.toUpperCase()}, ${state.toUpperCase()}, USA`,
                      firstName: client.name.split(" ")[0],
                      lastName: client.name.split(" ")[1],
                      email: client.email,
                      phone: client.phone,
                      ratings: { createdAt: new Date(), rating: 5 },
                      collection: "customers",
                      lastUpdated: new Date(),
                      userId: client.userId,
                      notifSettings: {
                        msgAlertsText: true,
                        msgAlertsEmail: true,
                        msgAlertsPush: true,
                        newApptAlertsText: true,
                        newApptAlertsEmail: true,
                        newApptAlertsPush: true,
                        apptCompletedAlertsText: true,
                        apptCompletedAlertsEmail: true,
                        apptCompletedAlertsPush: true,
                        apptCanceledAlertsText: true,
                        apptCanceledAlertsEmail: true,
                        apptCanceledAlertsPush: true,
                        apptStartedAlertsText: true,
                        apptStartedAlertsEmail: true,
                        apptStartedAlertsPush: true,
                        apptRescheduledAlertsText: true,
                        apptRescheduledAlertsEmail: true,
                        apptRescheduledAlertsPush: true,
                      },
                    })
                    .then(() => {
                      firestore.collection("appointments").add({
                        clientEmail: client.email,
                        clientName: client.name,
                        clientUID: client.userId,
                        clientRating: "n/a",
                        clientAvatar: client.avatar,
                        clientPets: formData.petTypes,
                        clientNotes: formData.notes,
                        clientStatus: client.accountStatus,
                        // clientStripeId: client.customerId,
                        clientPhone: clientNumE164,
                        proEmail: pro.email,
                        proName: pro.name,
                        proPhone: proNumE164,
                        proUID: pro.userId,
                        proAvatar: pro.avatar,
                        // proPaidOut:false,
                        proStripeId: pro.stripeId,
                        service: formData.service,
                        serviceFee: formData.serviceCost,
                        serviceFeeUpdate: formData.priceVaries,
                        serviceFeeTravel: formData.travelFee,
                        serviceFeeBooking: formData.bookingFee,
                        serviceComplete: false,
                        serviceCanceled: false,
                        serviceTimeReq: new Date(formData.dateTime),
                        serviceTimeStart: "n/a",
                        serviceTimeEnd: new Date(
                          parseFloat(t.join()) +
                            new Date(formData.dateTime).getTime()
                        ),
                        serviceLocation: formData.address,
                        serviceCoordinates: formData.startingCoordinates,
                        serviceRating: "n/a",
                        amtPaid: deposit,
                        amtRemainingBalance: balance,
                        messages: [],
                        tip: "",
                        virtual:
                          selectedService.virtual !== undefined
                            ? selectedService.virtual
                            : false,
                      });
                    });
                }
              })
              .then(() => {
                firestore
                  .collection("professionals")
                  .doc(formData.pro.userId)
                  .update({
                    apptsScheduled: firebase.firestore.FieldValue.increment(1),
                  });
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
            setSuccess(true);
            setProcessing(false);
          } else {
            console.log("response data: ", response.data);
            setProcessing(false);
            setCheckoutError("Payment Failed. Please Try again.");
            setFail(true);
          }
        } catch (error) {
          console.log("Oops, there's an issue:", error);
        }
      }
    } else {
      console.log("Error:", error);
      setProcessing(false);
      setCheckoutError(error.message);
      setFail(true);
    }
  };

  const handleSubmitNoPayment = (e) => {
    e.preventDefault();

    var clientNumCleaned = ("" + `${phone}`).replace(/\D/g, "");
    var clientNumMatch = clientNumCleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var clientNumE164 = [
      "+1",
      clientNumMatch[2],
      clientNumMatch[3],
      clientNumMatch[4],
    ].join("");
    var proNumCleaned = ("" + `${pro.phone}`).replace(/\D/g, "");
    var proNumMatch = proNumCleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var proNumE164 = [
      "+1",
      proNumMatch[2],
      proNumMatch[3],
      proNumMatch[4],
    ].join("");

    if (userData.test === false) {
      console.log("Hello, Im not a test account");
      const t = m.to(ms)(formData.serviceTimeEnd);
      //Move to dispatch
      firestore
        .collection("appointments")
        .add({
          clientEmail: client.email,
          clientName: client.name,
          clientUID: client.userId,
          clientRating: "n/a",
          clientAvatar: client.avatar,
          clientPets: formData.petTypes,
          clientNotes: formData.notes,
          clientStatus: client.accountStatus,
          clientStripeId: client.customerId,
          clientPhone: clientNumE164,
          proEmail: pro.email,
          proName: pro.name,
          proPhone: proNumE164,
          proUID: pro.userId,
          proAvatar: pro.avatar,
          // proPaidOut:false,
          proStripeId: pro.stripeId,
          service: formData.service,
          serviceAddOns: addOns,
          serviceFee: "0.00",
          serviceFeeUpdate: formData.priceVaries,
          serviceFeeTravel: formData.travelFee,
          serviceFeeBooking: formData.bookingFee,
          serviceComplete: false,
          serviceCanceled: false,
          serviceTimeReq: new Date(formData.dateTime),
          serviceTimeStart: "n/a",
          serviceTimeEnd: new Date(
            parseFloat(t.join()) + new Date(formData.dateTime).getTime()
          ),
          serviceLocation: selectedService.zoomLink,
          serviceCoordinates: pro.coordinates,
          serviceRating: "n/a",
          amtPaid: "0.00",
          amtRemainingBalance: "0.00",
          messages: [],
          tip: "",
          virtual:
            selectedService.virtual !== undefined
              ? selectedService.virtual
              : false,
          //serviceType:??
        })
        .then(() => {
          firestore
            .collection("professionals")
            .doc(formData.pro.userId)
            .update({
              apptsScheduled: firebase.firestore.FieldValue.increment(1),
            });
        })
        .then(() => {
          firestore
            .collection("customers")
            .doc(userData.userId)
            .update({
              apptsScheduled: firebase.firestore.FieldValue.increment(1),
            });
          setSuccess(true);
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else if (userData.test === true) {
      console.log("Hello, Im a test account");
      console.log("Successful Payment");
      const t = m.to(ms)(formData.serviceTimeEnd);

      firestore
        .collection("appointments")
        .add({
          clientEmail: client.email,
          clientName: client.name,
          clientUID: client.userId,
          clientRating: "n/a",
          clientAvatar: client.avatar,
          clientPets: formData.petTypes,
          clientNotes: formData.notes,
          clientStatus: client.accountStatus,
          clientStripeId: client.customerId,
          clientPhone: clientNumE164,
          proEmail: pro.email,
          proName: pro.name,
          proPhone: proNumE164,
          proUID: pro.userId,
          proAvatar: pro.avatar,
          // proPaidOut:false,
          proStripeId: pro.stripeId,
          service: formData.service,
          serviceAddOns: addOns,
          serviceFee: "0.00",
          serviceFeeUpdate: formData.priceVaries,
          serviceFeeTravel: formData.travelFee,
          serviceFeeBooking: formData.bookingFee,
          serviceComplete: false,
          serviceCanceled: false,
          serviceTimeReq: new Date(formData.dateTime),
          serviceTimeStart: "n/a",
          serviceTimeEnd: new Date(
            parseFloat(t.join()) + new Date(formData.dateTime).getTime()
          ),
          serviceLocation: selectedService.zoomLink,
          serviceCoordinates: pro.coordinates,
          serviceRating: "n/a",
          amtPaid: "0.00",
          amtRemainingBalance: "0.00",
          messages: [],
          tip: "",
          virtual:
            selectedService.virtual !== undefined
              ? selectedService.virtual
              : false,
        })
        .then(() => {
          firestore
            .collection("professionals")
            .doc(formData.pro.userId)
            .update({
              apptsScheduled: firebase.firestore.FieldValue.increment(1),
            });
        })
        .then(() => {
          firestore
            .collection("customers")
            .doc(userData.userId)
            .update({
              apptsScheduled: firebase.firestore.FieldValue.increment(1),
            });
          setSuccess(true);
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else if (client.accountStatus === "guest") {
      console.log("Hello, Im a guest account");
      let guestUsers = [];
      console.log("Successful Payment");
      const t = m.to(ms)(formData.serviceTimeEnd);

      //Move to dispatch
      firestore
        .collection("customers")
        .where("accountStatus", "==", "guest")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const guestData = doc.data();
            guestUsers.push(guestData);
            return Promise.all(guestUsers);
          });
        })
        .then(() => {
          if (guestUsers.filter((user) => user.email === email).length === 1) {
            const guestData = guestUsers.filter(
              (user) => user.email === email
            )[0];
            console.log(
              "Guest user exists. Appt being created user is being updated!"
            );
            firestore
              .collection("appointments")
              .add({
                clientEmail: email,
                clientName: name,
                clientUID: guestData.userId,
                clientRating: "n/a",
                clientAvatar: client.avatar,
                clientPets: formData.petTypes,
                clientNotes: formData.notes,
                clientStatus: client.accountStatus,
                // clientStripeId: client.customerId,
                clientPhone: clientNumE164,
                proEmail: pro.email,
                proName: pro.name,
                proPhone: proNumE164,
                proUID: pro.userId,
                proAvatar: pro.avatar,
                // proPaidOut:false,
                proStripeId: pro.stripeId,
                service: formData.service,
                serviceFee: "0.00",
                serviceFeeUpdate: formData.priceVaries,
                serviceFeeTravel: formData.travelFee,
                serviceFeeBooking: formData.bookingFee,
                serviceComplete: false,
                serviceCanceled: false,
                serviceTimeReq: new Date(formData.dateTime),
                serviceTimeStart: "n/a",
                serviceTimeEnd: new Date(
                  parseFloat(t.join()) + new Date(formData.dateTime).getTime()
                ),
                serviceLocation: selectedService.zoomLink,
                serviceCoordinates: pro.coordinates,
                serviceRating: "n/a",
                amtPaid: "0.00",
                amtRemainingBalance: "0.00",
                messages: [],
                tip: "",
                virtual:
                  selectedService.virtual !== undefined
                    ? selectedService.virtual
                    : false,
              })
              .then(() => {
                firestore.collection("customers").doc(guestData.userId).update({
                  lastUpdated: new Date(),
                });
                setSuccess(true);
              });
          } else {
            console.log(
              "Guest is a new user. Appt being created and guest data is being saved for thier return"
            );
            firestore
              .collection("customers")
              .doc(client.userId)
              .set({
                accountStatus: client.accountStatus,
                address: `${street.toUpperCase()}, ${city.toUpperCase()}, ${state.toUpperCase()}, USA`,
                firstName: name.split(" ")[0],
                lastName: name.split(" ")[1],
                email: email,
                phone: phone,
                ratings: { createdAt: new Date(), rating: 5 },
                collection: "customers",
                lastUpdated: new Date(),
                userId: client.userId,
                notifSettings: {
                  msgAlertsText: true,
                  msgAlertsEmail: true,
                  msgAlertsPush: true,
                  newApptAlertsText: true,
                  newApptAlertsEmail: true,
                  newApptAlertsPush: true,
                  apptCompletedAlertsText: true,
                  apptCompletedAlertsEmail: true,
                  apptCompletedAlertsPush: true,
                  apptCanceledAlertsText: true,
                  apptCanceledAlertsEmail: true,
                  apptCanceledAlertsPush: true,
                  apptStartedAlertsText: true,
                  apptStartedAlertsEmail: true,
                  apptStartedAlertsPush: true,
                  apptRescheduledAlertsText: true,
                  apptRescheduledAlertsEmail: true,
                  apptRescheduledAlertsPush: true,
                },
              })
              .then(() => {
                firestore.collection("appointments").add({
                  clientEmail: email,
                  clientName: name,
                  clientUID: client.userId,
                  clientRating: "n/a",
                  clientAvatar: client.avatar,
                  clientPets: formData.petTypes,
                  clientNotes: formData.notes,
                  clientStatus: client.accountStatus,
                  // clientStripeId: client.customerId,
                  clientPhone: clientNumE164,
                  proEmail: pro.email,
                  proName: pro.name,
                  proPhone: proNumE164,
                  proUID: pro.userId,
                  proAvatar: pro.avatar,
                  // proPaidOut:false,
                  proStripeId: pro.stripeId,
                  service: formData.service,
                  serviceFee: "0.00",
                  serviceFeeUpdate: formData.priceVaries,
                  serviceFeeTravel: formData.travelFee,
                  serviceFeeBooking: formData.bookingFee,
                  serviceComplete: false,
                  serviceCanceled: false,
                  serviceTimeReq: new Date(formData.dateTime),
                  serviceTimeStart: "n/a",
                  serviceTimeEnd: new Date(
                    parseFloat(t.join()) + new Date(formData.dateTime).getTime()
                  ),
                  serviceLocation: selectedService.zoomLink,
                  serviceCoordinates: pro.coordinates,
                  serviceRating: "n/a",
                  amtPaid: "0.00",
                  amtRemainingBalance: "0.00",
                  messages: [],
                  tip: "",
                  virtual:
                    selectedService.virtual !== undefined
                      ? selectedService.virtual
                      : false,
                });
              });
          }
        })
        .then(() => {
          firestore
            .collection("professionals")
            .doc(formData.pro.userId)
            .update({
              apptsScheduled: firebase.firestore.FieldValue.increment(1),
            });
          setSuccess(true);
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  };

  return (
    <Grid
      item
      xs={11}
      sm={10}
      md={8}
      lg={6}
      xl={5}
      container
      style={{
        outline: "none",
        position: "absolute",
        height: 600,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "white",
        borderRadius: "12px",
        padding: 10,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          Booking with: {formData.pro.name}
        </Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding: "0px 20px" }}>
        <Typography variant="h6" style={{ color: "#413e68" }}>
          7. Checkout
        </Typography>

        <form
          onSubmit={
            selectedService.zoomLink &&
            selectedService.zoomLink !== "" &&
            parseFloat(selectedService.price) === 0
              ? handleSubmitNoPayment
              : handlePayDeposit
          }
          style={{ padding: 10 }}
        >
          <Grid container item xs={12}>
            {client.accountStatus === "guest" ? (
              <>
                <Grid item xs={12} className={classes.inputContainer}>
                  <InputBase
                    placeholder="Full Name"
                    variant="outlined"
                    className={classes.input}
                    fullWidth
                    defaultValue={name}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputContainer}>
                  <InputBase
                    placeholder="Phone"
                    variant="outlined"
                    type="tel"
                    className={classes.input}
                    fullWidth
                    defaultValue={phone}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputContainer}>
                  <InputBase
                    placeholder="Email"
                    variant="outlined"
                    className={classes.input}
                    fullWidth
                    defaultValue={email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6} className={classes.inputContainer}>
                  <InputBase
                    placeholder="Full Name"
                    variant="outlined"
                    className={classes.input}
                    fullWidth
                    defaultValue={name}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.inputContainer}>
                  <InputBase
                    placeholder="Email"
                    variant="outlined"
                    className={classes.input}
                    fullWidth
                    defaultValue={email}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
              </>
            )}

            {selectedService.virtual !== undefined &&
              selectedService.virtual === false && (
                <>
                  <Grid item xs={6} className={classes.inputContainer}>
                    <InputBase
                      placeholder="Steet Address"
                      variant="outlined"
                      className={classes.input}
                      fullWidth
                      defaultValue={street}
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.inputContainer}>
                    <InputBase
                      placeholder="City"
                      variant="outlined"
                      className={classes.input}
                      fullWidth
                      defaultValue={city}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6} className={classes.inputContainer}>
                    <InputBase
                      placeholder="State"
                      variant="outlined"
                      className={classes.input}
                      fullWidth
                      defaultValue={state}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.inputContainer}>
                    <InputBase
                      placeholder="Zip"
                      variant="outlined"
                      className={classes.input}
                      fullWidth
                      defaultValue={zip}
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </Grid>
                </>
              )}

            {parseFloat(selectedService.price) !== 0 && (
              <Grid item xs={12} className={classes.inputContainer}>
                <div
                  className={classes.input}
                  style={{
                    width: "inherit",
                    padding: "10px",
                    margin: "0px 0px 0px 6px",
                    border: "1px solid, grey",
                    borderRadius: 6,
                  }}
                >
                  <CardElement options={cardElementOptions} />
                </div>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {parseFloat(selectedService.price) === 0 ? (
              <Button
                type="submit"
                variant="contained"
                className={classes.btn}
                style={{
                  backgroundColor: "#413e68",
                  color: "white",
                  width: "100%",
                }}
                disabled={
                  name === "" ||
                  email === "" ||
                  (selectedService.virtual !== undefined &&
                    selectedService.virtual !== true &&
                    street === "") ||
                  (selectedService.virtual !== undefined &&
                    selectedService.virtual !== true &&
                    city === "") ||
                  (selectedService.virtual !== undefined &&
                    selectedService.virtual !== true &&
                    state === "") ||
                  (selectedService.virtual !== undefined &&
                    selectedService.virtual !== true &&
                    zip === "")
                }
              >
                Schedule Now
              </Button>
            ) : !!isProcessing ? (
              <div>
                <CircularProgress style={{ color: "#413e68" }} />
                <Typography>Processing...</Typography>
              </div>
            ) : (
              <Button
                type="submit"
                variant="contained"
                className={classes.btn}
                fullWidth
                style={{
                  backgroundColor: "#413e68",
                  color: "white",
                  width: "100%",
                }}
                disabled={
                  selectedService.virtual !== undefined &&
                  !selectedService.virtual
                    ? name === "" ||
                      email === "" ||
                      street === "" ||
                      city === "" ||
                      state === "" ||
                      zip === ""
                    : name === "" || email === ""
                }
              >
                Pay Deposit Now: ${deposit}
              </Button>
            )}
          </Grid>
        </form>
      </Grid>

      <Snackbar
        open={success}
        autoHideDuration={2000}
        // onClose={()=> {setSuccess(false); window.location.reload();}}
        onClose={() => {
          setSuccess(false);
          navigation.next();
        }}
        message="Success! Your appointment was booked 🎉"
      />
      <Snackbar
        open={fail}
        autoHideDuration={2000}
        // onClose={()=> {setSuccess(false); window.location.reload();}}
        onClose={() => {
          setFail(false);
          setCheckoutError(null);
        }}
        message={checkoutError}
      />
    </Grid>
  );
};
