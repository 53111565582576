import React, {useState, useRef, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Switch, IconButton, Modal, TextField, Avatar, Snackbar, CircularProgress, ModalManager} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';

function BrandAmAddStaff(props) {
    const {modalOpen, setModalOpen, brandStaff, themePrimary, themeSecondary, uid} = props
    const [email, setEmail] = useState('')
    const [pro, setPro] = useState(null)

    useEffect(() => {
        
    }, [pro])
    

    const handleSearch = () => {
        firestore.collection('professionals').where('email', '==', email)
        .get()
    .then((querySnapshot) => {
        setPro(querySnapshot.docs.map(doc => doc.data()).pop())    
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
       
    };
    const handleCloseSearch = () => {
        setPro(null)
        setEmail('')
       
    };

    const handleSave = () => {
        firestore.collection('brandAms').doc(uid).update({
         staff: firebase.firestore.FieldValue.arrayUnion(pro.email),
        }).then(()=> window.location.reload())
    };


  return (
    <Modal open={!!modalOpen} onClose={(()=>setModalOpen(!modalOpen))}>
        <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

            <Grid item container justify='space-between' style={{backgroundColor: themePrimary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                   Add Staff
                </Typography>
                <IconButton onClick={()=>setModalOpen(!modalOpen)} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid container item xs={12} style={{padding:20}}>
                <Typography style={{width:'100%', textAlign:'center'}}>Enter the email of the staff member you wish to add to your roster.</Typography>
                <TextField
                    label="Email"
                    placeholder={email}
                    name="email"
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
            </Grid>

            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                <Button
                variant="contained"
                style={{  
                letterSpacing: '2px',marginRight: "1rem" }}
                onClick={()=>setModalOpen(!modalOpen)}
                >
                Cancel
                </Button>

                <Button
                style={{letterSpacing: '2px', backgroundColor:themePrimary, color:themeSecondary}}
                variant="contained"
                onClick={handleSearch}
                >
                Add
                </Button>
            </div>
            
            {pro !== null &&
                <Modal open={pro !== null} onClose={handleCloseSearch}>
                    <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                        <Grid item container justify='space-between' style={{backgroundColor: themePrimary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                            <Typography variant='h6' style={{padding: '10px'}}>
                            Search Results
                            </Typography>
                            <IconButton onClick={handleCloseSearch} style={{color: 'white'}}>
                                <ClearIcon/>
                            </IconButton>
                        </Grid>
                        {pro!==undefined ?
                        <Typography style={{width:'100%', padding:20, textAlign:'center'}}>Is this the professional you're looking for?</Typography>
                        :
                        <Typography style={{width:'100%', padding:20, textAlign:'center'}}>Oops! Looks like we don't have results under that email.</Typography>
                        }

                        {pro!== undefined &&
                        <>
                            <div 
                            style={{ 
                                 width: '100%', textAlign: "center", lineHeight: "240px", boxSizing: "border-box", position:'relative',
                                 display:'flex', alignItems:'center', flexFlow:'column', padding:20}}
                                >
                                <Avatar  src={pro.images.avatar} style={{width:100, height:100, marginBottom:10}}></Avatar>
                                <Typography style={{fontWeight:'bold', color:'black'}}>{pro.firstName+" "+pro.lastName}</Typography>
                                <Typography style={{fontSize:12, color:'black', marginBottom:20}}>{pro.title}</Typography>
                            </div>
                        </>
                        }

                        {pro!==undefined ?
                            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                                <Button
                                variant="contained"
                                style={{  
                                letterSpacing: '2px',marginRight: "1rem" }}
                                onClick={handleCloseSearch}
                                >
                                No, Try Again
                                </Button>

                                <Button
                                style={{letterSpacing: '2px', backgroundColor:themePrimary, color:themeSecondary}}
                                variant="contained"
                                onClick={handleSave}
                                >
                                Yes, Confirm
                                </Button>
                            </div>
                        :
                            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                                <Button
                                variant="contained"
                                style={{ letterSpacing: '2px',marginRight: "1rem" }}
                                onClick={()=>{handleCloseSearch(); setModalOpen(!modalOpen)}}
                                >
                                Cancel
                                </Button>

                                <Button
                                style={{letterSpacing: '2px', backgroundColor:themePrimary, color:themeSecondary}}
                                variant="contained"
                                onClick={handleCloseSearch}
                                >
                                Try Again
                                </Button>
                            </div>
                        }
                    </Grid>

                </Modal>
            }

        </Grid>
    </Modal>
  )
}

export default BrandAmAddStaff