import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Grid, Typography, withWidth, Button} from '@material-ui/core'
//import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import {firestore} from '../config/fbConfig';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
formControl: {
  width:'inherit',
  minWidth: 120,
},
selectEmpty: {
  marginTop: theme.spacing(2),
},
question: {
    color: 'gray',
    marginTop:20,
    marginBottom: 4,
  },
chip: {
    margin: 2,
},
link:{
    '&:hover':{
        cursor:'pointer',
        color:theme.palette.primary.main,
    },
},
buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Success(props) {
    const {userData, currentUser, width} = props
    const classes = useStyles()
    const history = useHistory()
    const [chargesEnabled, setChargesEnabled] = useState(null)
    const [detailsSubmitted, setDetailsSubmitted] = useState(null)
    const [showButton, setShowButton] = useState(false)
    const [loading, setLoading] = useState(true)
    const [clicked, setClicked] = useState(false)

    const smMargin = (width === 'sm' || width === 'xs') ? 50 : 0


    const handleContinue = async (e) => {
        e.preventDefault()
        //window.open(userData.stripeInfo.accountLinks.url)
        //// console.log(userData.stripeId)
        setClicked(true)
            try {
                const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/onboarding', {
                    id: userData.stripeId
                })
                if(response.data.success){
                    // console.log("Successful Link retrieval", response.data.accountLinks.url)
                    window.location.assign(response.data.accountLinks.url)
                }else{
                  // console.log("Failure Link retrieval")
                }      
            } catch (error) {
                // console.log("This is the Error:", error)
            }
        
    }


    useEffect(() => {

        const retrieveUser = async () => {
            try {
                const response = await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/retrieve', {
                    params:  {acctId: userData.stripeId}
                })
                if(response.data.success){
                    // console.log("Successful Link retrieval", response.data.account)
                    setChargesEnabled(response.data.account.charges_enabled)
                    setDetailsSubmitted(response.data.account.details_submitted)
                }else{
                  // console.log("Failure Link retrieval")
                }      
            } catch (error) {
                // console.log("This is the Error:", error)
            }
        }

        if(userData.stripeId!==null && userData.stripeId!==undefined && loading){
            // console.log('StripeId not invalid: ', userData.stripeId)
            retrieveUser()
            setLoading(false)
        }

        if(chargesEnabled===true && detailsSubmitted===true){
        
            firestore.collection(userData.collection).doc(userData.userId).update({
                accountStatus:'active',
                lastUpdated:new Date()
            })
            //setLoading(false)
            
        }

        if(userData.accountStatus === 'active'){
            setShowButton(true)
            //history.push('/home')
        }

        // console.log(userData.stripeId, chargesEnabled, detailsSubmitted)

    }, [userData, chargesEnabled, detailsSubmitted, history, showButton, loading])


    return (
        <Grid container item xs={12} style={{height: "fit-content", display:'flex', alignItems:'center', justifyContent:'center'}}>
            
            {loading ?
            null
            :
            (chargesEnabled===false || detailsSubmitted===false) ?
            <Grid item container xs={10} sm={8} md={4} style={{marginLeft:smMargin, height:'inherit', display:'flex', flexFlow:'column', alignItems:'center',}}>
                <span role="img" aria-label="thinging" style={{fontSize:100, marginBottom:20}}>🤔</span>
                <Typography style={{textAlign:'center', marginBottom:10}}>Hmm... Looks like there's a bit more information we need from you.</Typography>
                <Button variant='contained' color='primary' onClick={handleContinue} disabled={clicked}>Lets Finish up</Button>
                <Typography style={{fontWeight:'bold', color:'gray', marginBottom:'10px', marginTop:40}}>Payment Information</Typography>        
                <Typography style={{color:'gray', padding:10, border:'1px solid #c0c0c0', borderRadius:'4px'}}>
                    We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click <span style={{fontWeight:'bold'}}>LETS FINISH UP</span> to set up your payments on Stripe.
                    By registering and completing your account, you certify that the business information you are providing is accurate and agree to the <span className={classes.link} style={{fontWeight:'bold'}} onClick={() => window.open("https://stripe.com/issuing/celtic/spend-card-terms-and-disclosures/legal")}> Stripe Issuing terms and disclosures with Celtic Bank, Member FDIC .</span>
                </Typography>
            </Grid>
            :
            <Grid item container xs={10} sm={8} md={4} style={{marginLeft:smMargin, height:'inherit',display:'flex', flexFlow:'column', alignItems:'center',}}>
                <span role="img" aria-label="party-hat" style={{fontSize:100, marginBottom:20}}>🥳</span>
                <Typography style={{textAlign:'center', marginBottom:10}}>Awesome! You're all set up!</Typography>
                <Button variant='contained' color='primary' onClick={() => window.location.assign('/home')}>Lets go!</Button>
            </Grid>
            }

        </Grid>
    )
}

Success.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(Success)
