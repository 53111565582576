import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import {Grid, Typography, Avatar, withWidth} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
//import {firestore} from '../../config/fbConfig'
import { firestore } from '../../config/fbConfig';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
    notifContainer: {
        '&:hover':{
            cursor:'pointer',
        },
      },
      icon: {
        flex:'0 1 40px',
        // color: '#413e68',
        cursor:'pointer',
      },
      inputField: {
        border:'2px solid lightgray',
        borderRadius: 20,
        height: 40 ,
        flex:'1 1 auto',
        paddingTop: 2,
        margin: "10px 5px 5px",
        display:'flex',
        alignItems:'center',
        boxShadow: 'none',
      },
      selectedServiceDetails:{
        padding: '0px 30px',
        marginTop: 20,
      },
  }));

function Notification({notif, id, userData}) {
    const classes = useStyles()
    const readStatus = notif.read === false ? '#b6b6f7' : null
    const isClickable = notif.read === false ? classes.notifContainer : null
    const timeColor = notif.read === false ? null : 'darkgrey'

    const date = new Date(notif.time).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
      const time = new Date(notif.time).toLocaleString('en-US', { 
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        })

    const handleRead = () => {
        firestore.collection(userData.collection).doc(userData.userId)
        .collection('notifications').doc(id).update({
            read: true
        })
    }



    return (
        <Grid item container xs={12} key={notif.createdAt} className={isClickable} style={{outline:'none', padding:5, alignItems:'center', backgroundColor: readStatus, borderBottom:'1px solid lightgray'}}
            onClick={handleRead}>
            <Grid item xs={2} style={{justifyContent:'center'}}>
                {(notif.clientavatar && notif.proavatar) ? 
                notif.clientUID === userData.userId ?
                    <Avatar src={notif.proavatar} style={{width:30, height:30, margin:'auto'}}></Avatar>
                    :
                    <Avatar src={notif.clientavatar} style={{width:30, height:30, margin:'auto'}}></Avatar>
                :
                <Avatar src={notif.avatar} style={{width:30, height:30, margin:'auto'}}></Avatar>

                }
            </Grid>
            <Grid item xs={7}>
                {(notif.clientUID === userData.userId && notif.pro) &&
                <Typography style={{fontSize:12, fontWeight: 'bold'}}>{notif.pro}</Typography>
                }
                {(notif.clientUID !== userData.userId && notif.client) &&
                <Typography style={{fontSize:12, fontWeight: 'bold'}}>{notif.client}</Typography>
                }
                {notif.user &&
                <Typography style={{fontSize:12, fontWeight: 'bold'}}>{notif.user}</Typography>
                }
                <Typography style={{fontSize:12}}>{notif.content}</Typography>
                {notif.reason ? 
                <Typography style={{fontSize:10}}>{notif.reason}</Typography>
                :
                notif.time ?
                <Typography style={{fontSize:10}}>{date} @ {time}</Typography>
                :
                null
                }
            </Grid>
            <Grid item xs={3} style={{display:'flex', flexFlow:'column', alignItems:'flex-end', paddingRight:10}}>
                {notif.read === false &&
                <div style={{height:10, width:10, borderRadius:10, backgroundColor:'blue'}}></div>
                }
                <Typography style={{fontSize:10, color:timeColor}}>{moment(notif.createdAt.toDate()).fromNow()}</Typography>
            </Grid>
        </Grid>
    )
}

export default Notification
