import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import moment from 'moment'
import {Grid, Typography, Avatar, Drawer, Menu, Modal, MenuItem, Snackbar, Button, Slide,withWidth, FormControlLabel, Switch,
  List, Divider, ListItem, ListItemIcon, IconButton, Paper, InputBase, BottomNavigation, BottomNavigationAction} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {Home, Notifications, Favorite, DateRange, Close, Sms, Settings, AttachMoney, AccountCircle, LiveHelp, Brush, ToggleOff, ToggleOn, MobileScreenShare, Work, LocalAtm} from '@material-ui/icons'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import FavoriteIcon from '@material-ui/icons/Favorite';
import RestoreIcon from '@material-ui/icons/Restore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FolderIcon from '@material-ui/icons/Folder';
import StarIcon from '@material-ui/icons/Star';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';


//import {firestore} from '../../config/fbConfig'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types';
import {Link, NavLink} from 'react-router-dom'
import { firestore } from '../../config/fbConfig';
import firebase from 'firebase';
import Notification from '../modals/Notification';
import SupportModal from '../modals/SupportModal';
import RatingAndReviewModal from '../modals/RatingAndReviewModal'
import PayBalanceModal from '../modals/PayBalanceModal';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import AppointmentTips from '../modals/BookAppointmentModal/07AppointmentTips';
import BusinessCardModal from '../modals/BusinessCardModal';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripePromiseTest = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)

const drawerWidth = 240;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    background: '#413e68',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: '#413e68',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 50,
    [theme.breakpoints.up('sm')]: {
      width: 50,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    //padding: theme.spacing(0, 1),
    minHeight: 50,
    // necessary for content to be below app bar
    //...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItem: {
    paddingTop: "14px",
    paddingBottom: "14px",
  },
  listItemIconAndText: {
    color: "white",
    fontWeight: 'bold',
    fontSize: '20px',
  },
  avatar:{
    '&:hover':{
      cursor: 'pointer'
    }
  },
  input: {
    flex: 1,
    display: 'flex',
    height: 50,
    alignItems: 'center',
    '&:hover':{
        cursor:'pointer'
    },
  },
  bottomNav:{
    borderTop:'1px solid lightgrey',
    width:'100%',
    position:'fixed',
    bottom:0,
    // backgroundColor:'#cf599f',
    // color:'white',
    zIndex:10,
    display:'flex',
    justifyContent:'space-evenly'
  },
  bottomNavBtn:{
    //color:'white',
    minWidth:50,
    padding: '10px 0px',
  },
}));

    
const LoggedInMenu = ({currentUser, userData, width}) => {

  const classes = useStyles();
  // const theme = useTheme();
  const [open, setOpen] = useState(false)
  const [expand, setExpand] = useState(false)
  const [loading, setLoading] = useState(false)
  const [appt, setAppt] = useState(null)
  //const anchorRef = React.useRef(null);
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openErr, setOpenErr] = useState(false)
  const [settingsAnchorEl, setSettingsAnchorEl] = React.useState(null);
  const [notifAnchorEl, setNotifAnchorEl] = React.useState(null);
  const [usersAnchorEl, setUsersAnchorEl] = React.useState(null);
  const [apptsAnchorEl, setApptsAnchorEl] = React.useState(null);
  const [chatsAnchorEl, setChatsAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [accountStatus, setAccountStatus] = React.useState('');
  const [isOnline, setIsOnline] = React.useState(null);
  const [checked, setChecked] = useState(isOnline);
  const [modalOpen, setModalOpen] = useState(false)
  const [balanceModalOpen, setBalanceModalOpen] = useState(false)
  const [rrModalOpen, setRRModalOpen] = useState(false)
  const [verifyEmailModalOpen, setVerifyEmailModalOpen] = useState(false)
  const hasUnread = notifications && notifications.some(notif => notif.docData.read === false)
  const switchLabel = (isOnline === true) ? 'Online' : 'Offline'
  const alertMessage = (isOnline === true) ? 'WooHoo!! You are now Online! 🎉' : 'You are now Offline, 👋🏾'
  const severity = ["error", "success"]
  const [openedImage, setOpenedImage] = useState('')
  const [bcModalOpen, setBcModalOpen] =useState(false)
  const [shareModalOpen, setShareModalOpen] =useState(false)
  const [onlineModal, setOnlineModal] =useState(false)
  const value = (userData.url!==null || userData.url!== undefined) ? 'www.bizzybook.app/pro/profile/'+userData.url : null
  const [copied, setCopied] = useState(false)
  const [transition, setTransition] = useState(undefined);
  const [navValue, setValue] = React.useState('home');
  const [brandAmUrl, setBrandAmUrl] = React.useState('');




  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  

  const handleOpen = (e) => {
    setModalOpen(!modalOpen)
  }

  const handleBalanceModalOpen = () => {
    setBalanceModalOpen(!balanceModalOpen)
  }

  const handleVerityEmailModalOpen = () => {
    setVerifyEmailModalOpen(!verifyEmailModalOpen)
  }


  const handleClickSettings = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };
  const handleClickNotif = (event) => {
    setNotifAnchorEl(event.currentTarget);
  };
  const handleClickUsers = (event) => {
    setUsersAnchorEl(event.currentTarget);
  };
  const handleClickAppts = (event) => {
    setApptsAnchorEl(event.currentTarget);
  };
  const handleClickChats = (event) => {
    setChatsAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSettingsAnchorEl(null);
    setNotifAnchorEl(null);
    setChatsAnchorEl(null);
    setApptsAnchorEl(null);
    setUsersAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenErr(false);
  };
  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const handleDrawer = () => {
    if(open === false){
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setExpand(false);
  //   }
  // }

  const handleMarkAllRead = () => {
    if(userData.collection === 'admins'){
      notifications.filter(notif => {
        const id = notif.id
        const data = notif.docData
        if(data.read === false){
          firestore.collection('adminNotifications').doc(id).update({read: true})
        }
      })
    }else{
      notifications.filter(notif => {
        const id = notif.id
        const data = notif.docData
        if(data.read === false){
          firestore.collection(userData.collection).doc(userData.userId).collection('notifications')
          .doc(id).update({read: true})
        }else{
        }
      })
    }
  }

  const toggleChecked = () => {
    var userRef = firestore.collection(userData.collection).doc(userData.userId)
    userRef.update({
      online: !isOnline,
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
  })
  .then(() => {
      console.log("User is online:", isOnline);
      // setChecked(isOnline)
      setOnlineModal(false)
      setOpenSuccess(!openSuccess)
  })
  // .then(() => window.location.reload())
  .catch((error) => {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
      setOpenErr(!openErr)
  });
  };  

  const handleOpenBizCard = (e) => {
    setOpenedImage(e.target.src)
    setBcModalOpen(!bcModalOpen)
  }

  const handleShareModalOpen = () => {
    setShareModalOpen(!shareModalOpen)
  }

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setCopied(true);
    setTimeout(() => {  setCopied(false); }, 2000);
  };

  useEffect(() => {

    // if(userData === {}){
    //   setLoading(true)
    // }

    if(userData.email !== undefined){
      const {collection, userId} = userData

      firestore.collection(`${collection}`).doc(`${userId}`)
        .onSnapshot((doc) => {
          const data = doc.data()
          setAccountStatus(data.accountStatus);
          setIsOnline(data.online)
        })


      // firestore.collection(`${collection}`).doc(`${userId}`).collection('notifications').limit(75)
      // .onSnapshot((snapshot) => {
          
      //     // var snd = new Audio("https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/notificationSounds%2Fsound-01.mp3?alt=media&token=bdaaf0c4-41d5-4f5b-97b7-b855abedc1bb");      
      //     // snd.play(); 
      //     setNotifications(snapshot.docs.map(doc => 
      //       {return({id:doc.id, docData:doc.data()})}
      //       )
      //     )
      //       // if(notifications.length > 0){
      //        // }
          
      //     // snapshot.docChanges().forEach((change) => {
      //     //   if (change.type === "removed") {
      //     //       var snd = new Audio("https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/notificationSounds%2Fsound-01.mp3?alt=media&token=bdaaf0c4-41d5-4f5b-97b7-b855abedc1bb");      
      //     //       snd.play(); 
      //     //   }
      //     // })

      //   })

        if(userData.collection === 'admins'){
          firestore.collection(`adminNotifications`).orderBy('createdAt', "desc").limit(100)
          .onSnapshot((snapshot) => {
              setNotifications(snapshot.docs.map(doc => 
                {return({id:doc.id, docData:doc.data()})}
                )
              )
            })
        }else{
          firestore.collection(`${collection}`).doc(`${userId}`).collection('notifications').orderBy('createdAt', "desc").limit(50)
          .onSnapshot((snapshot) => {
              
              setNotifications(snapshot.docs.map(doc => 
                {return({id:doc.id, docData:doc.data()})}
                )
              )

    
            })
        }

        firestore.collection('appointments').where('clientUID', '==', `${userData.userId}`)
        .where('serviceComplete', '==',true).where('amtRemainingBalance', '!=', '0')
        .onSnapshot((snapshot) => {
          
          setAppt(snapshot.docs.map(doc => 
            {return({id:doc.id, docData:doc.data()})}
            ).pop()
          )
        })
        
      //  setLoading(false)
      //  // console.log("---------------------")
    }


    // if(notifications.length > 0 && loading===true){
    //   setLoading(false)
    //   // console.log('did this step')
    // }

    

      // console.log('notifications: ', notifications)
  },[userData, loading, checked, accountStatus, isOnline])

  useEffect(() => {
	if(userData.collection === 'professionals' && userData.partner === true && userData.displayName !== ""){
		const url = userData.displayName.replace(/[^\w ]/g, '').toLowerCase().split(" ")
		
		for( var i = 0; i < url.length; i++){ 
    
			if ( url[i] === "") { 
		
				url.splice(i, 1); 
		   }
	   }
	   
	   setBrandAmUrl(url.join("-"))
	}

	console.log("I AM HERE!")
  }, [userData])
  
  
    return(
      <div style={{position:'relative'}}>
      {(width === "lg" ||width === "xl") ?
      <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            style={{position:'relative'}}
          >
            <div className={classes.toolbar}>
              {(currentUser && userData.images) ?
                <Grid container>

                  <Grid item xs={3} style={{display: 'flex', justifyContent:'center', padding: 5}}>
                    {userData.collection === 'professionals' ?
                      <Avatar src={userData.images.avatar} className={classes.avatar} component={NavLink} to={`/pro/profile/${userData.url}`} onClick={!!open && handleDrawer}/>
                    :
                    userData.collection === 'customers' ?
                      <Avatar src={userData.images.avatar} className={classes.avatar} component={NavLink} to='/settings/account' onClick={!!open && handleDrawer}/>
                      :
                      <Avatar src={userData.avatar} className={classes.avatar} component={NavLink} to='/settings/account' onClick={!!open && handleDrawer}/>
                    }
                  </Grid>

                  {open === true ? 
                    <Grid container item xs={9} style={{padding:5}}>
                      <Grid item xs={12}>
                        {userData.collection !== 'admins' ?
                          <Typography variant="body1" style={{color: 'white'}}>{userData.firstName}</Typography>
                          :
                          <Typography variant="body1" style={{color: 'white'}}>{userData.name}</Typography>
                        }
                      </Grid>

                      {userData.collection !== 'admins' &&
                      <Grid item xs={12}>
                        {(userData.ratings && userData.ratings.length) &&
                        <Typography variant="caption" style={{color: 'white'}}>Rating: {((userData.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0)) / userData.ratings.length).toFixed(2)}</Typography>
                        }
                        <StarIcon style={{marginLeft:"5px", color: 'white', fontSize: '14px', display: "inline-block", verticalAlign: 'middle'}}/>
                      </Grid>
                      }

                      
                      
                    </Grid> 
                  :
                  null
                  }

                </Grid>
              :
                null
              }
              <IconButton className={classes.listItemIconAndText} onClick={handleDrawer}>
              {open === false ? 
                <MenuIcon />
              :
                <ChevronLeftIcon/>
              }
              </IconButton>
            </div>
            <Divider />

            {(userData.collection === 'professionals' && open === true && userData.accountStatus!=='onboarding' && userData.accountStatus!=='onboarding'
              && userData.accountStatus!=='needs review' && userData.accountStatus!=='inactive') ?
                    <Grid container item xs={12} style={{display:'contents'}}>
                      <FormControlLabel style={{margin: '15px 0px 0px 15px', color:'white', fontWeight:'bold'}}
                        control={<Switch size="small" checked={isOnline} onChange={toggleChecked} />}
                        label={switchLabel}
                      />
                    </Grid> 
                  :
                  null
                  }

            <List>
              {userData.collection !== 'admins'?
               <ListItem button className={classes.listItem} component={Link} to="/home" onClick={!!open && handleDrawer}>
                  <ListItemIcon className={classes.listItemIconAndText}><Home/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Home</Typography>
                </ListItem>
              :
               <ListItem button className={classes.listItem} component={Link} to="/home" onClick={!!open && handleDrawer}>
                  <ListItemIcon className={classes.listItemIconAndText}><img src="https://img.icons8.com/material-outlined/24/ffffff/dashboard.png"  alt=""/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Dashboard</Typography>
                </ListItem>
              }

                {hasUnread &&
                      <div style={{borderRadius:5, height:10, width:10, backgroundColor:'red', position:'absolute', right:8, marginTop:15, zIndex:100}}></div> 
                  }
                {userData.accountStatus!=='onboarding' &&
                <ListItem button className={classes.listItem} aria-controls="simple-menu-notifications" aria-haspopup="true" onClick={handleClickNotif}>
                  <ListItemIcon className={classes.listItemIconAndText}><Notifications/></ListItemIcon>
                    <Typography className={classes.listItemIconAndText}>Notifications</Typography>
                </ListItem>
                }

                <Menu PaperProps={{
                  style: {
                    maxHeight: 500,
                    width: '40ch',
                  },}} id="simple-menu-notifications" anchorEl={notifAnchorEl}
                    keepMounted open={Boolean(notifAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {left:25}}>
                      <Grid item xs={12} style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                      {!!notifications && notifications.length !== 0 ?
                        <Button onClick={handleMarkAllRead}>Mark All as Read</Button>
                        :
                        <Typography style={{color:'darkgrey'}}>You have no notifications!</Typography>
                      }
                      </Grid>
                      
                      {notifications && notifications.sort((a,b) => b.docData.createdAt > a.docData.createdAt && 1 || -1).map((notif) => {
                        return(
                          <Notification key={notif.id} notif={notif.docData} id={notif.id} userData={userData}/>
                          
                          )
                        })
                      }
                </Menu>

                {/* <div style={{borderRadius:5, height:10, width:10, backgroundColor:'red', position:'absolute', right:8, marginTop:15}}></div> */}
                {userData.collection === 'admins' ? 
                <ListItem button className={classes.listItem} aria-controls="simple-menu-chats" aria-haspopup="true" onClick={handleClickChats}>
                  <ListItemIcon className={classes.listItemIconAndText}><Sms/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Messages</Typography>
                </ListItem>
                :
                userData.accountStatus!=='onboarding' ?
                <ListItem button className={classes.listItem} component={Link} to="/home/messages" onClick={!!open && handleDrawer}>
                  <ListItemIcon className={classes.listItemIconAndText}><Sms/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Messages</Typography>
                </ListItem>
                :
                null
                }

                <Menu id="simple-menu-chats" anchorEl={chatsAnchorEl} keepMounted open={Boolean(chatsAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {}}>
                  <MenuItem onClick={handleClose} component={Link} to='/admin/chat/pros'>Pros</MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to='/admin/chat/clients'>Clients</MenuItem>
                </Menu>

                {(userData.collection === 'professionals' && userData.accountStatus!=='onboarding') &&
                  <ListItem button className={classes.listItem} component={Link} to="/home/earnings" onClick={!!open && handleDrawer}>
                    <ListItemIcon className={classes.listItemIconAndText}><AttachMoney/></ListItemIcon>
                    <Typography className={classes.listItemIconAndText}>Earnings</Typography>
                  </ListItem>
                }
                {userData.collection === 'customers' &&
                  <div>
                    <ListItem button className={classes.listItem} component={Link} to="/home/favorites" onClick={!!open && handleDrawer}>
                      <ListItemIcon className={classes.listItemIconAndText}><Favorite/></ListItemIcon>
                      <Typography className={classes.listItemIconAndText}>Favorites</Typography>
                    </ListItem>

                    {/* <ListItem button className={classes.listItem} component={Link} to="/home/shop">
                      <ListItemIcon className={classes.listItemIconAndText}><ShoppingCart/></ListItemIcon>
                      <Typography className={classes.listItemIconAndText}>Shop</Typography>
                    </ListItem> */}
                  </div>
                }

                {userData.collection === 'admins' &&
                <div>
                <ListItem button className={classes.listItem} aria-controls="simple-menu-users" aria-haspopup="true" onClick={handleClickUsers}>
                  <ListItemIcon className={classes.listItemIconAndText}><img src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/ffffff/external-user-interface-kiranshastry-solid-kiranshastry-1.png"  alt=""/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Users</Typography>
                </ListItem>

                <Menu id="simple-menu-users" anchorEl={usersAnchorEl} keepMounted open={Boolean(usersAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {}}>
                  <MenuItem onClick={handleClose} component={Link} to='/admin/users/pros'>Pros</MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to='/admin/users/clients'>Clients</MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to='/admin/users/admins'>Admins</MenuItem>
                </Menu>

                <ListItem button className={classes.listItem} aria-controls="simple-menu-appts" aria-haspopup="true" onClick={handleClickAppts}>
                  <ListItemIcon className={classes.listItemIconAndText}><img src="https://img.icons8.com/material-rounded/24/ffffff/calendar--v1.png" alt=""/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Appts</Typography>
                </ListItem>

                <Menu id="simple-menu-appts" anchorEl={apptsAnchorEl} keepMounted open={Boolean(apptsAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {}}>
                  <MenuItem onClick={handleClose} component={Link} to='/admin/appointments'>Appointments</MenuItem>
                  <MenuItem onClick={handleClose} component={Link} to='/admin/services'>Services</MenuItem>
                </Menu>
              </div>
                }

            <Divider />

                 {(userData.collection === 'professionals' && userData.partner===true && brandAmUrl !== "") &&
                  <ListItem button className={classes.listItem} component={NavLink} to={`/brand-ambassador/${brandAmUrl}`}
                  //aria-controls="simple-menu-settings" aria-haspopup="true"  onClick={handleClickSettings}
                  >
                    <ListItemIcon className={classes.listItemIconAndText}><StorefrontIcon/></ListItemIcon>
                      <Typography className={classes.listItemIconAndText}>Business</Typography>
                  </ListItem>
                 }

                 {userData.accountStatus!=='onboarding' &&
                  <ListItem button className={classes.listItem} aria-controls="simple-menu-settings" aria-haspopup="true" onClick={handleClickSettings}>
                    <ListItemIcon className={classes.listItemIconAndText}><Settings/></ListItemIcon>
                      <Typography className={classes.listItemIconAndText}>Settings</Typography>
                  </ListItem>
                 }

                <Menu id="simple-menu-settings" anchorEl={settingsAnchorEl} keepMounted open={Boolean(settingsAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {}}
                >
                  {/* <MenuItem component={Link} to={`/settings`}>General Settings</MenuItem> */}
                  {/* <MenuItem onClick={handleClose} component={Link} to='/settings/general'>Gengeral Settings</MenuItem> */}
                  <MenuItem onClick={handleClose} component={Link} to='/settings/account'>My Account</MenuItem>
                  
                  {(userData.collection === 'professionals' && userData.accountStatus!=='onboarding') &&
                    <div>
                      <MenuItem onClick={handleClose} component={Link} to={`/pro/profile/${userData.url}`}>Profile</MenuItem>
                      <MenuItem onClick={handleClose} component={Link} to='/settings/availability'>Availability</MenuItem>
                      <MenuItem onClick={handleClose} component={Link} to='/settings/services'>Services</MenuItem>
                    </div>
                  }

                  {userData.accountStatus!=='onboarding' &&
                  <MenuItem onClick={handleClose} component={Link} to='/settings/notifications'>Notifications</MenuItem>
                  } 

                  {userData.collection === 'professionals' &&
                  <MenuItem onClick={handleClose} component={Link} to='/settings/payment-info'>Payment Info</MenuItem>
                  }
                </Menu>

                {/* <ListItem button className={classes.listItem} component={Link} to="/help-center">
                  <ListItemIcon className={classes.listItemIconAndText}><LiveHelpIcon/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Help Center</Typography>
                </ListItem>           */}
                <ListItem button className={classes.listItem} onClick={()=>setModalOpen(true)}>
                  <ListItemIcon className={classes.listItemIconAndText}><LiveHelpIcon/></ListItemIcon>
                  <Typography className={classes.listItemIconAndText}>Help Center</Typography>
                </ListItem>          
            </List>

          {open === true && userData.collection==='professionals' ? 
          <div style={{position:'absolute', right:5, bottom:5, display:'flex', alignItems:'center'}}>
            <Typography style={{color:'white'}}>Share:</Typography>
            <IconButton onClick={handleShareModalOpen}><IosShareIcon style={{color:'white'}}/></IconButton>
            <IconButton onClick={handleOpenBizCard}><img src="https://img.icons8.com/fluency-systems-regular/24/ffffff/qr-code--v1.png" alt=''/></IconButton>
          </div>
          :
          null
          }          
        </Drawer>
      :
      <BottomNavigation value={navValue} onChange={handleChange} className={classes.bottomNav}>
        
        {userData.collection !== 'admins'?
        <BottomNavigationAction label="Home" value="home" icon={<Home/>} component={Link} to="/home" className={classes.bottomNavBtn}/>
        :
        <BottomNavigationAction label="Dashboard" value="dashboard" icon={<Home/>} />
        }

        {userData.collection === 'customers' &&
        <BottomNavigationAction label="Favorites" value="favorites" icon={<FavoriteIcon />} component={Link} to="/home/favorites" className={classes.bottomNavBtn}/>
        }

        {userData.collection === 'professionals' &&
        <BottomNavigationAction label="Earnings" value="earnings" icon={<AttachMoney />} component={Link} to="/home/earnings" className={classes.bottomNavBtn}/>
        }
        
        {userData.collection === 'professionals' &&
        <BottomNavigationAction label={isOnline ? 'Go Offline' : 'Go Online'} value="online" icon={!isOnline ? <ToggleOff /> : <ToggleOn/>} onClick={()=>setOnlineModal(!onlineModal)} className={classes.bottomNavBtn}/>
        }

        {(userData.collection === 'customers' && userData.accountStatus!=='onboarding') &&
        <BottomNavigationAction label="Appointments" value="appointments" icon={<DateRange />} component={Link} to="/home/appointments" className={classes.bottomNavBtn}/>
        }

        {(userData.collection === 'professionals' && userData.accountStatus!=='onboarding') &&
        <BottomNavigationAction label="Schedule" value="schedule" icon={<DateRange />} component={Link} to="/home/appointments" className={classes.bottomNavBtn}/>
        }
       

        {/* {userData.accountStatus!=='onboarding' &&
        <div style={{marginTop:'-7px', position:'relative'}}>
          {hasUnread &&
            <div style={{borderRadius:10, height:13, width:13, backgroundColor:'red', position:'absolute', top:16, right:21, zIndex:100}}></div> 
          }
          <BottomNavigationAction 
          label="Notifications" value="notifications" icon={<Notifications />} className={classes.bottomNavBtn} aria-controls="simple-menu-notifications" aria-haspopup="true" onClick={handleClickNotif}/>
        </div>
        } */}

        {userData.collection !== 'admins' &&
        <BottomNavigationAction label="Settings" value="settings" icon={<Settings />} className={classes.bottomNavBtn} aria-controls="simple-menu-settings" aria-haspopup="true" onClick={handleClickSettings}/>
        }

       

        {userData.collection === 'admins' && 
        <BottomNavigationAction label="Messages" value="messages" icon={<Sms />} onClick={handleClickChats} className={classes.bottomNavBtn}/>
        }



        <Menu PaperProps={{
          style: {
            maxHeight: '80vh',
            width: '40ch',
            position:'relative'
          },}} id="simple-menu-notifications" anchorEl={notifAnchorEl}
            keepMounted open={Boolean(notifAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {left:25}}>
                <IconButton style={{position:'absolute', top:3, left:3}} onClick={handleClose}>
                  <Close/>
                </IconButton>
              <Grid item xs={12} style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
              {!!notifications && notifications.length !== 0 ?
                <Button onClick={handleMarkAllRead}>Mark All as Read</Button>
                :
                <Typography style={{color:'darkgrey'}}>You have no notifications!</Typography>
              }
              </Grid>
              
              {notifications && notifications.sort((a,b) => b.docData.createdAt > a.docData.createdAt && 1 || -1).map((notif) => {
                return(
                  <Notification key={notif.id} notif={notif.docData} id={notif.id} userData={userData}/>
                  
                  )
                })
              }
        </Menu>


        <Menu id="simple-menu-settings" anchorEl={settingsAnchorEl} keepMounted open={Boolean(settingsAnchorEl)} onClose={handleClose} style={!!open ? {left:180} : {}}>
          {/* <MenuItem component={Link} to={`/settings`}>General Settings</MenuItem> */}
          {/* <MenuItem onClick={handleClose} component={Link} to='/settings/general'>Gengeral Settings</MenuItem> */}
          {/* <MenuItem onClick={handleClose} component={Link} to='/settings/account'>My Account</MenuItem> */}

          
          {(userData.collection === 'professionals' && userData.accountStatus!=='onboarding') &&
            <div>
              <MenuItem onClick={handleClose} component={Link} to='/settings/availability'><DateRange style={{color:'grey', marginRight:5}}/>Availability</MenuItem>
              <MenuItem onClick={handleClose} component={Link} to='/settings/services'><Work style={{color:'grey', marginRight:5}}/>Services</MenuItem>
            </div>
          }

          {userData.accountStatus!=='onboarding' &&
          <MenuItem onClick={handleClose} component={Link} to='/settings/notifications'><ToggleOff style={{color:'grey', marginRight:5}}/>Notifications</MenuItem>
          } 

          {userData.collection === 'professionals' &&
          <MenuItem onClick={handleClose} component={Link} to='/settings/payment-info'><LocalAtm style={{color:'grey', marginRight:5}}/>Payment Info</MenuItem>
          }

          <Divider/>

          <MenuItem onClick={()=>setModalOpen(true)}><LiveHelp style={{color:'grey', marginRight:5}}/>Help Center</MenuItem>
        </Menu>

      </BottomNavigation>
      }


          {userData.collection==='professionals' &&
          <BusinessCardModal handleOpenBizCard={handleOpenBizCard} bcModalOpen={bcModalOpen} pro={userData}/>
          }

          {userData.collection==='professionals' &&
          <Modal open={shareModalOpen} onClose={handleShareModalOpen}>
            <Grid item container xs={11} md={6} style={{position: 'absolute', display: 'flex', flexFlow:'column', outline: 'none',
                          top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius:'12px', backgroundColor:'white'}}>
              <Grid item contianer xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'0px 10px'}}>
                <Typography style={{fontSize:20, fontWeight:'bold'}}>Share</Typography>
                <IconButton onClick={handleShareModalOpen}><CloseIcon/></IconButton>
              </Grid>

              <Grid item container xs={12} style={{padding:20}}>
              <Grid item xs={12} container>  

                    <CopyToClipboard text={value} onCopy={handleClick(TransitionUp)}>
                        <Button variant='contained' color='primary' fullWidth>
                            Copy Link
                        </Button>
                    </CopyToClipboard>

                    <Snackbar
                        open={copied}
                        onClose={handleClose}
                        TransitionComponent={transition}
                        message="Copied to clipboard!"
                        key={transition ? transition.name : ''}
                    />
                </Grid>

                <Grid item xs={12} container style={{display:'flex', justifyContent:'space-evenly', marginTop:'10px'}}>

                    <FacebookShareButton url={value}
                    quote={"Hey I'm now on CutiApp! Use the link to book with me today!"}
                    hashtag={'#CutiApp'}>
                        <FacebookIcon size={40} round={true}/>
                    </FacebookShareButton>

                    <TwitterShareButton url={value}
                    title={"Hey I'm now on CutiApp! Use the link to book with me today!"}
                    hashtags={['CutiApp', 'BeautyAndWellness', 'BeautyOnDemand', 'WellnessOnDemand']}>
                        <TwitterIcon size={40} round={true}/>
                    </TwitterShareButton>

                    <LinkedinShareButton url={value}
                    summary={"Hey I'm now on CutiApp! Use the link to book with me today!"}
                    source={'Cuti App'}>
                        <LinkedinIcon size={40} round={true}/>
                    </LinkedinShareButton>

                    <WhatsappShareButton url={value}
                    title={"Hey I'm now on CutiApp! Use the link to book with me today!"}
                    separator={' --> '}
                    hashtag={'#CutiApp'}>
                        <WhatsappIcon size={40} round={true}/>
                    </WhatsappShareButton>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
          }

<Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleCloseSnackbar} severity={severity[1]}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleCloseSnackbar} severity={severity[0]}>
                Oops, something went wrong...
                </Alert>
            </Snackbar>

            <SupportModal modalOpen={modalOpen} handleModalOpen={handleOpen}/>

            {accountStatus === 'suspended' ?
              <Modal open={accountStatus==='suspended'}>
                <Grid>
                  <Typography>Your account has been suspended for the following reason:</Typography>
                {appt && 
                  <Button color='primary' variant='contained' onClick={handleBalanceModalOpen}>Pay Palance</Button>
                }
                </Grid>
              </Modal>
              :
              null
            }

            {(appt && userData.test ===false) &&
            <Elements stripe={stripePromise}>
              <PayBalanceModal userData={userData} appt={appt} modalOpen={accountStatus==='paused' || balanceModalOpen} handleModalOpen={handleBalanceModalOpen} openReviewModal={setRRModalOpen}/>
            </Elements>
            }
            {(appt && userData.test ===true) &&
            <Elements stripe={stripePromiseTest}>
              <PayBalanceModal userData={userData} appt={appt} modalOpen={accountStatus==='paused' || balanceModalOpen} handleModalOpen={handleBalanceModalOpen} openReviewModal={setRRModalOpen}/>
            </Elements>
            }
            {appt &&
            <RatingAndReviewModal modalOpen={rrModalOpen} setModalOpen={setRRModalOpen} selectedAppt={appt} userData={userData}/>
            }

            <Modal open={!!onlineModal} onClose={()=>setOnlineModal(!onlineModal)}>
              <Grid item container xs={11} style={{outline:'none', position:'absolute', bottom:'7%', left:15, backgroundColor: 'white', borderRadius: '15px', padding:20}}>
                <Typography fullWidth style={{width:'100%', marginBottom:10}}>{isOnline ? "Are you sure you want to go offline" : "Go online?"}</Typography>
                <Grid item container xs={6} style={{padding:10}}>
                  <Button fullWidth variant='contained' color='secondary' onClick={()=> setOnlineModal(!onlineModal)}>Not Yet</Button>
                </Grid>
                <Grid item container xs={6} style={{padding:10}}>
                  <Button fullWidth variant='contained' color='primary' onClick={toggleChecked}>Yes</Button>
                </Grid>
              </Grid>

            </Modal>
      </div>

    )
}

  const mapState = (state) => ({
    currentUser: state.auth.currentUser,
    userData: state.auth.userData
  })

  LoggedInMenu.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };


export default compose(
  withWidth(),connect(mapState))(LoggedInMenu)