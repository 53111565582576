import React from "react";
import {Typography, Grid, Button, TextArea, TextField} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    formControl: {
        width: '100%'
    },

  }));

function Notes({formData, setForm, userData, navigation}) {
  //const { notes } = formData;
  const [notes, setNotes] = React.useState(formData.notes)
  const classes = useStyles();

  const handleAddNotes = (e) => {
    setNotes(e.target.value)
    formData.notes = e.target.value
    // console.log(formData.notes)
  }

  //// console.log(formData.havePets , petTypes)
  
  return (
    <Grid container item xs={12} style={{borderRadius: 12, border:'1px solid #e0e0e0', position: 'relative', marginTop:'20px', height: 510, boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)', textAlign: 'left'}}>
      <Grid item xs={12} style={{borderRadius: '8px 8px 0 0', background: '#413e68', height: "50px"}}>
        <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Schedule A Service</Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding:'0px 20px'}}>

        <Typography variant='h6' style={{color:"#413e68"}}>3. Any Additional Notes?.</Typography>
        <TextField
            fullWidth
            label="Add a Note"
            name="notes"
            value={notes}
            variant='outlined'
            multiline
            rows={4}
            onChange={(e) => handleAddNotes(e)}
        />
      </Grid>


      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'flex-end'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ backgroundColor: 'white',  
              color: '#413e68'}}
            onClick={() => navigation.previous()}>
            Back
        </Button>

      </Grid>

      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'left'}}> 
  
        <Button
            variant="contained"
            className={classes.btn}
            color='primary'
            onClick={() => navigation.next()}>
            Next
        </Button>

      </Grid>
    </Grid>
  );
}

export default Notes
