import React, {useRef, useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import {Grid, Paper, InputBase, Typography, Avatar, Tooltip, IconButton, Modal, Slider, Button, CircularProgress, Hidden, withWidth} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import firebase, {firestore, storage} from '../../../config/fbConfig'
import ReactScrollableFeed from 'react-scrollable-feed'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {useDispatch} from 'react-redux';
import {setConversationId as dscid} from '../../../auth/appointmentActions';
import { PhotoLibrary } from '@material-ui/icons';
import Resizer from "react-image-file-resizer";
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../modals/canvasUtils'
import Cropper from 'react-easy-crop'

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1000,
    borderRadius: '12px 12px 0 0',
    border: "1px solid grey",
    position: 'relative',
    marginTop:'20px',
    height:510,
    minHeight: 360,
    boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)',
    textAlign: 'left',
    display: 'flex',
    flexFlow:'column',
  },
  rootTitle:{
    borderRadius: '8px 8px 0 0',
    background: '#413e68',
    flex:'0 1 50px',
  },
  conversationContainer: {
    height:'inherit',
    borderRight:'1px solid lightgrey',
    display:'flex',
    flexFlow:'column',
  },
  conversationHeader: {
    height:'50px',
    borderBottom:'1px solid lightgrey',
    padding:'0px 5px',
    flex:'0 1 50px',
    display:'flex',
    justifyContent: 'space-between',
    // height:'inherit',
    alignItems:'center'
  },

  navBtn: {
    padding: "0",
    height: "100%",
    color: 'white',
  },
  container: {
    display:'flex',
    justifyContent:'flex-end',
    margin:'auto',
    paddingRight:12,
  },
  grid: {
    display:'flex',
    justifyContent:'center',
  },
  input: {
    flex:'1 1 auto',
    paddingLeft:'10px'
  },
  icon: {
    flex:'0 1 40px',
    // color: '#413e68',
    cursor:'pointer',
  },
  inputField: {
    border:'2px solid lightgray',
    borderRadius: 20,
    height: 40 ,
    flex:1,
    paddingTop: 2,
    display:'flex',
    alignItems:'center',
    boxShadow: 'none',
  },
  chatArea:{
    height:'inherit',
    display:'flex',
    flexFlow:'column',
  },
  chatAreaHeader: {
    flex:'0 1 50px',
    backgroundColor:'lightgrey',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messagesContainerUser:{
    display: 'flex',
    flexDirection:'row-reverse',
    flex: '0 1 auto',
    padding: '5px 5px 5px 5%'
  },
  messagesContainerOther:{
    display: 'flex',
    flex: '0 1 auto',
    padding: '5px 5% 5px 5px'
  },
  messageWrapperUser: {
    padding: 7,
    width:'fit-content',
    borderRadius:'12px 0px 12px 12px',
    // backgroundColor:'lightgrey',
  },
  messageWrapperOther: {
    padding: 7,
    width:'fit-content',
    borderRadius:'0px 12px 12px 12px',
    // backgroundColor:'lightgrey',
  },
  chatControls: {
    display: 'flex',
    alignItems:'center',
    borderTop: '1px solid lightgrey',
    padding: 5,
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    width:'100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ChatWindow(props) {
  const {currentAppt, setCurrentAppt, userData, conversationId, setConversationId, width, cId} = props
  const dispatch = useDispatch()
  // console.log("Current Appt", currentAppt)
  const classes = useStyles();
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [closed, setClosed] = useState(false)  
  const [appointmentId, setAppointmentId] = useState(null)
  const disabled = (message === "") ? true : false

  //IMAGES
  const fileInput = useRef();
  const [imageSrc, setImageSrc] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [myBlob, setMyBlob] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [cropperOpen, setCropperOpen] = useState(false)
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)


const proNames = currentAppt!== null && currentAppt.proName.split(' ');
const prolastInitail = currentAppt!== null && proNames[1].charAt(0) + "."
const proFirstNameLastInit = currentAppt!== null && proNames[0] + " " + prolastInitail 

const clientNames = currentAppt!== null && currentAppt.clientName.split(' ');
const lastInitail = currentAppt!== null && clientNames[1].charAt(0) + "."
const clientFirstNameLastInit = currentAppt!== null && clientNames[0] + " " + lastInitail 


const maxHeight = (width ==='xs' ||width === 'sm') ? "61vh" : 355

   useEffect(()=>{
    if(firestore && (conversationId !== null)) {
      setClosed(currentAppt.serviceComplete)
      
      firestore.collection('appointments').doc(`${conversationId}`)
      .onSnapshot((doc) => {
        if(doc.data().messages){
          var orderedMessages = doc.data().messages.sort((a,b) =>  a.createdAt > b.createdAt && 1 || -1)
          setMessages(orderedMessages);
        }
        setAppointmentId(conversationId)
        setConversationId(null)
      })  
    }
    // else if(cId !== null){
    //   //setClosed(currentAppt.serviceComplete)
    //   // console.log(cId)
    //   firestore.collection('appointments').doc(`${cId}`)
    //   .onSnapshot((doc) => {
    //     // console.log('hello from Chat Window')
    //     if(doc.data().messages){
    //       var orderedMessages = doc.data().messages.sort((a,b) =>  a.createdAt > b.createdAt && 1 || -1)
    //       setMessages(orderedMessages);
    //     }
    //     setAppointmentId(cId)
    //     setConversationId(null)
    //   }) 
    // }


    // console.log(messages, conversationId)
    console.log(imageSrc)

   },[conversationId, setConversationId, currentAppt, messages, appointmentId, cId, imageSrc])

  const handleChange = (e) => {
    e.preventDefault()
    setMessage(e.target.value)
  }
  
  const handleCloseChat = () => {
    setConversationId(null)
    dispatch(dscid(''));
    setMessage([])
    setCurrentAppt(null)
  }

   const submitMessage = async (e) => {
     e.preventDefault()
    const {firstName, lastName, userId} = userData
    var apptRef = firestore.collection("appointments").doc(`${appointmentId}`)
    if(imageSrc !== null){
        let fileurl = ''
        var uploadTask = storage.ref(`AppointmentImages/${appointmentId}/${fileName}`).put(myBlob)
        uploadTask.on('state_changed', 
          (snapshot) => {
            //Handle progress...
            setImageLoading(true)
          }, 
          (error) => {
            // Handle unsuccessful uploads...
          }, 
          async () => {
            // Handle successful uploads on complete...
            await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
              fileurl = url
              // console.log('File available at', fileurl)
              setImageLoading(false)
              closeModal()
            })
            .then(() => {
              return apptRef.update({
                messages: firebase.firestore.FieldValue.arrayUnion({
                  createdAt: new Date(),
                  text: message,
                  uid: userData.userId,
                  name: userData.firstName+" "+userData.lastName,
                  images: imageSrc !== null ? [fileurl] : [],
                  deleted: false,
                })
              })
              .then(()=>{
                setMessage("");
                setImageSrc(null);
                setFileName(null);
                setMyBlob(null);
              })
              .catch((error) => {
                  console.error("Error updating document: ", error);
              });
            });
          }
        )
    }else if(imageSrc === null && message !== ""){
      await apptRef.update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          createdAt: new Date(),
          text: message,
          uid: userId,
          name: firstName+" "+lastName,
          images:[],
          deleted: false,
        })
      })
      .then(displayMesages)
      setMessage("")
    }
   }

   const displayMesages = () => {
    setConversationId(appointmentId)
  }



  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  
  const handleCropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )
  
      var xhr = new XMLHttpRequest();
      xhr.open('GET', `${croppedImage}`, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status === 200) {
          setMyBlob(this.response);
          // myBlob is now the blob that the object URL pointed to.   
        }
      };
      xhr.send();
  
      console.log(croppedImage)
      setCroppedImage(croppedImage)
      setCropperOpen(false)
  
  
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation, userData,])
  
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)
  
      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }
      setFileName(file.name.split(' ').join('-'))
      setImageSrc(imageDataUrl)
      setCropperOpen(!cropperOpen)
    }
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
  
  const select = () => {
    fileInput.current.click()
  } 

  const closeModal = () => {
    setCropperOpen(false)
    // setImageSrc(null)
  }

    return (
        <Grid item xs={12} md={8} className={classes.chatArea} container >
          {currentAppt !== null  &&
          <>
            <Grid item xs={12} className={classes.chatAreaHeader}>
              <Grid item xs={2} style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
                <IconButton onClick={handleCloseChat}>
                  <NavigateBeforeIcon/>
                </IconButton>
              </Grid>
              <Grid item xs={10} containter>
                <Grid item xs={12}>
                  {userData.userId === currentAppt.clientUID ? 
                  <Typography style={{fontWeight:'bold'}}>{proFirstNameLastInit}</Typography>              
                  :
                  <Typography style={{fontWeight:'bold'}}>{clientFirstNameLastInit}</Typography>  
                }
                </Grid>
                <Grid item xs={12} style={{display:'flex', justifyContent:'space-between', paddingRight:5}}>
                  <Typography style={{fontSize:12, textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{currentAppt.service}</Typography>  
                  <Typography style={{fontSize:12}}>{new Date(currentAppt.serviceTimeReq.seconds * 1000).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit'})}</Typography>  
                </Grid>
              </Grid>
            </Grid>

            <div style={{maxHeight:maxHeight, flex:'1 0 auto', width: 'inherit', padding:'0px 15px 5px 5px'}}>
                <ReactScrollableFeed>
                  {messages && messages.map((message, idx)=> message.uid === userData.userId ? 
                    <div key={idx} style={{display:'flex', flexFlow:'column', width:'inherit'}}>
                      <Grid key={message.createdAt} className={classes.messagesContainerUser}>
                        <Avatar src={userData.images.avatar} style={{marginLeft:5}}/>
                        {!!message.deleted ?
                        <div className={classes.messageWrapperUser} style={{backgroundColor:'lightgrey', padding:'10px 7px'}}>
                          <Typography style={{color:'grey', fontSize:12, fontStyle:'italic',whiteSpace: "nowrap"}}>This message has been deleted.</Typography>
                        </div>
                        :
                        <div className={classes.messageWrapperUser} style={{backgroundColor:'#413e68'}}>
                          {message.images.length > 0 &&
                            message.images.map((x,i) => {
                              return <img key={i} onClick={()=>{setSelectedImage(x)}}
                                          src={x} alt='img' style={{width:250, height:200, marginBottom:10, borderRadius:8}}/>
                            })
                          }
                          <Typography style={{color:'white'}}>{message.text}</Typography>
                        </div>
                        }
                      </Grid>
                      <Typography style={{display: 'flex', flexDirection:'row-reverse', fontSize:10, color:'gray'}}>
                        {moment(message.createdAt.toDate()).fromNow()}
                      </Typography>
                    </div>
                
                    :
                    <div key={idx} style={{display:'flex', flexFlow:'column', width:'inherit'}}>
                    <Grid item container xs={12} key={message.createdAt} className={classes.messagesContainerOther}>
                      {userData.collection === 'customers' ?
                      <Avatar src={currentAppt.proAvatar} style={{marginRight:5}}/> :
                      <Avatar src={currentAppt.clientAvatar} style={{marginRight:5}}/>
                      }
                      {!!message.deleted ?
                      <div className={classes.messageWrapperOther} style={{backgroundColor:'lightgrey', padding:'10px 7px'}}>
                        <Typography style={{color:'grey', fontSize:12, fontStyle:'italic', whiteSpace: "nowrap"}}>This message has been deleted.</Typography>
                      </div>
                      :
                      <div className={classes.messageWrapperOther} style={{backgroundColor:'grey'}}>
                        {message.images.length > 0 &&
                            message.images.map((x,i) => {
                              return <img key={i} onClick={()=>{setSelectedImage(x)}}
                                          src={x} alt='img' style={{width:250, height:200, marginBottom:10, borderRadius:8}}/>
                            })
                          }
                        <Typography style={{color:'white'}}>{message.text}</Typography>
                      </div>
                      }
                      </Grid>
                      <Typography style={{display: 'flex', flexDirection:'row', fontSize:10, color:'gray'}}>
                        {moment(message.createdAt.toDate()).fromNow()}
                      </Typography>
                    </div>
                    )}
                </ReactScrollableFeed>


            </div>

            <div style={{flex: '1 1 auto'}}></div>


            {imageLoading === false &&
            <Grid className={classes.chatControls}>
              {imageSrc ? <img src={imageSrc} alt='library' style={{width:30, height:30, margin:'0px 6px'}}/> : <PhotoLibrary style={{color:'grey', margin:'0px 6px', fontSize:'30px'}} onClick={select}/>}
              <input style={{display: 'none'}}
                type="file"
                id="image"
                name="image"
                // onChange={onChange}
                onChange={onFileChange}
                ref={fileInput}
                accept=".gif,.jpg,.jpeg,.png"
                />
              <Paper component='form' onSubmit={submitMessage}
                className={classes.inputField}>    
                  <Tooltip
                    disableHoverListener={!closed} 
                    disableFocusListener={!closed}
                    title="This appointment was closed. Messaging is disabled.">
                    <InputBase
                      disabled={closed}
                      type='text'
                      name='message'
                      value={message}
                      onChange={handleChange}
                      placeholder="Type Message..."
                      className={classes.input}
                      //autoFocus
                      />
                  </Tooltip>
                    
                  {!!closed ? 
                    null
                  :
                    <SendIcon className={classes.icon} style={{color:'#413e68'}} disabled={disabled} onClick={submitMessage}/>
                  }
              </Paper>
            </Grid>      
            }
          </>
        }

        <Modal open={!!cropperOpen} onClose={closeModal}>
          <Grid container item xs={10} sm={6}
            style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:'30px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none',  maxHeight:'90%'}}>
                <div style={{width:'inherit', height:'inherit', position:'relative'}}>
                  <div className={classes.cropContainer}>
                    <Cropper
                      image={imageSrc}
                      crop={crop}
                      rotation={rotation}
                      zoom={zoom}
                      aspect={4 / 3}
                      onCropChange={setCrop}
                      onRotationChange={setRotation}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                </div>
                <div className={classes.controls}>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Zoom
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      classes={{ root: classes.slider }}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Rotation
                    </Typography>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      aria-labelledby="Rotation"
                      classes={{ root: classes.slider }}
                      onChange={(e, rotation) => setRotation(rotation)}
                    />
                  </div>
                </div>
                  <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
                    <Button variant='contained' color='secondary' style={{fontWeight:'bold', marginTop:20}} onClick={closeModal}>Cancel</Button>
                    {imageLoading === false ?
                    <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} 
                    // onClick={()=>uploadImg(previewCanvasRef.current, croppedAreaPixels)}
                    onClick={handleCropImage}
                    >Upload</Button>
                    :
                    <div className={classes.wrapper}>
                    <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} disabled>Uploading...</Button>
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  </div> 
                    }

                  </Grid>

            </Grid>

        </Modal>

        <Modal open={selectedImage !== null} onClose={()=> {setSelectedImage(null)}}>

            {selectedImage !== null &&
              <img src={selectedImage} alt='selectedimg'
              style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', borderRadius: '15px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none',  maxWidth:'98vw'}}/>
            }

        </Modal>


          </Grid>
    )
}

ChatWindow.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};


export default withWidth()(ChatWindow);
