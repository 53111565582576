import React from 'react'
import { Modal} from '@material-ui/core';
import { Scheduler } from './BookAppointmentModal/Scheduler';


function BookAppointmentModal(props) {
    const {pro, userData, modalOpen, handleBookingModalOpen, selectedService} = props

    return (
        <Modal open={!!modalOpen} onClose={handleBookingModalOpen}>
            <Scheduler userData={userData} pro={pro} selectedService={selectedService} handleBookingModalOpen={handleBookingModalOpen}/>
        </Modal>
    )
}

export default BookAppointmentModal
