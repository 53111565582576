import React,{useState} from 'react'
import {Grid, Button, Typography, Avatar, TextField, FormControl, InputLabel, MenuItem, Select, InputBase, Paper, IconButton, Modal } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import { firestore } from '../../../config/fbConfig';



function Services({userData, brandAm, classes,  edit, uid, designData, paddingLeft, handleAddServiceModalOpen, handleModal3Open, setPricesVary, setSelectedService, setBrandSelectedService, handleModal2Open, handleRemoveService, handleLogineModalOpen}) {
    const {services, theme} = designData
    const [serviceSearch, setServiceSearch] = useState('')
    const [backgroundColor, setBackgroundColor] = useState(services.backgroundColor)
    const [cardColor, setCardColor] = useState(services.cardColor)
    const [titleColor, setTitleColor] = useState(services.titleColor)
    const [fontColor, setFontColor] = useState(services.fontColor)
    const [modalOpen, setModalOpen] = useState(false)


    const handleModalOpen = () => {
      setModalOpen(!modalOpen)
    }

    const handleSave = () => {
      firestore.collection('brandAms').doc(uid).collection('design').doc('designInfo').update({
        'services.backgroundColor': backgroundColor,
        'services.cardColor': cardColor,
        'services.titleColor': titleColor,
        'services.fontColor': fontColor
       })
    }

    const handleCancel = () => {
      setTitleColor(services.titleColor)
      setFontColor(services.fontColor)
      setCardColor(services.cardColor)
      setBackgroundColor(services.backgroundColor)
      handleModalOpen()
    }

  return (
    <Grid item container xs={12} style={{display:'flex', justifyContent:'center', padding:20, paddingLeft: paddingLeft, backgroundColor: backgroundColor, maxHeight:500}}>
    <Grid item container xs={11} md={9}>
      <Grid item contianer xs={12} md={6} style={{display:'flex', justifyContent:'left'}}>
        <Typography style={{fontWeight:'bold', fontSize:24, color:titleColor}}>Services</Typography>
      </Grid>
      <Grid item contianer xs={12} md={6} style={{display:'flex', justifyContent:'right'}}>
        <Paper component='form' style={{border:'2px solid lightgray', borderRadius: 20, height: 40 , width: 'inherit', paddingTop: 2, display:'flex', alignItems:'center', boxShadow: 'none'}}>    
                <SearchIcon style={{color:'lightgrey', cursor:'pointer',}}/>
                <InputBase
                  name='search'
                  value={serviceSearch}
                  onChange={(e) => {setServiceSearch(e.target.value)}}
                  placeholder="Search..."
                  style={{flex:'1 1 auto', padding:' 0px 10px'}}
                  />
            </Paper>
            {(userData.email === brandAm.email && edit === true)?
              <>
                <Avatar className={classes.circBtn} style={{marginLeft:5}}  onClick={handleAddServiceModalOpen}><AddCircleOutlineIcon/></Avatar>
                <Avatar className={classes.circBtn} style={{marginLeft:5}}  onClick={handleModalOpen}><EditIcon/></Avatar>
              </>
              :
              <>
                <Avatar className={classes.circBtn} style={{marginLeft:5}}><SortByAlphaIcon/></Avatar>  
                <Avatar className={classes.circBtn} style={{marginLeft:5}}><FilterListIcon/></Avatar>  
              </>
            }
      </Grid>
    </Grid>

    <Grid item container xs={12} style={{marginTop:20, minHeight:200, maxHeight:400, paddingBottom:20, overflowY:'scroll', justifyContent:'center'}}>

        {brandAm.services.length > 0 && 
          brandAm.services.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1).filter((service) => {
            const name = service.name
            if(serviceSearch === '') {
                return service
                } else if (name.toLowerCase().includes(serviceSearch.toLowerCase())) {
                    return service
                }
                }).map((service, idx) => {
                  const {name, price, serviceType, time} = service
                    return(
                      <Grid container item xs={12} md={5} style={{margin:5, display:'flex', minHeight:80, maxHeight:120, alignItems:'center', backgroundColor:cardColor, borderRadius:5, padding:8, border:'1px solid lightgray'}}>
                        <Grid item contianer xs={8} >
                          <Typography style={{fontWeight:'bold', color:fontColor}}>{name} - ${price}  
                            {(service.priceVaries !== undefined && service.priceVaries === true) && 
                            <span style={{fontSize:12, fontWeight:'normal'}}>{' (and up)'}</span>}
                          </Typography>
                          <Typography style={{fontSize:12, color:'grey'}}>{serviceType} - {parseInt(time/60) === 0 ? time%60 + "mins" : parseInt(time/60) === 1 ? time%60 === 0 ? parseInt(time/60)+"hr" : parseInt(time/60)+"hr " + time%60 + "mins" : time%60 === 0 ? parseInt(time/60)+"hrs" : parseInt(time/60)+"hrs " + time%60 + "mins"}</Typography>
                          <Typography  style={{fontSize:12, fontWeight:'bold', color:theme.primary}}>MORE INFO</Typography>
                        </Grid>

                        <Grid item container xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                          {(brandAm.email === userData.email && edit=== true)?
                          <div style={{display:'flex', justifyContent:'center'}}>
                            <IconButton style={{marginLeft:5, backgroundColor:theme.primary, color:theme.secondary}} onClick={()=> {setBrandSelectedService(service); setPricesVary(service.priceVaries !== undefined ? service.priceVaries : false); handleModal2Open()}}><EditIcon/></IconButton>
                            <IconButton style={{marginLeft:5, backgroundColor:theme.primary, color:theme.secondary}} onClick={()=> {setBrandSelectedService(service); handleRemoveService()}}><DeleteIcon/></IconButton>
                          </div>
                          :
                          userData.userId !== undefined ?
                          <Button onClick={() => {setSelectedService(service); handleModal3Open()}} variant='contained' style={{fontSize:12, fontWeight:'bold', backgroundColor:theme.primary, color:theme.secondary}}>Book</Button>
                          :
                          <Button onClick={handleLogineModalOpen} variant='contained' style={{fontSize:12, fontWeight:'bold', backgroundColor:theme.primary, color:theme.secondary}}>Book</Button>
                          }
                        </Grid>
                      </Grid>
                    )}).sort()
          }

    </Grid>


    <Modal open={!!modalOpen} onClose={handleModalOpen}>
      <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

            <Grid item container justify='space-between' style={{backgroundColor: theme.primary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Edit Design
                </Typography>
                <IconButton onClick={handleModalOpen} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid item contianer xs={12} style={{padding:20}}>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Background Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' defaultValue={backgroundColor} value={backgroundColor} style={{border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                        <input type='text' defaultValue={backgroundColor} value={backgroundColor} style={{width:'100%', border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                    </span>
              </Grid>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Service Card Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' defaultValue={cardColor} value={cardColor} style={{border:'none'}} onChange={(e)=>setCardColor(e.target.value)}/>
                        <input type='text' defaultValue={cardColor} value={cardColor} style={{width:'100%', border:'none'}} onChange={(e)=>setCardColor(e.target.value)}/>
                    </span>
              </Grid>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Title Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' value={titleColor} style={{border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                        <input type='text' value={titleColor} style={{width:'100%', border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                    </span>
              </Grid>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Text Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' value={fontColor} style={{border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                        <input type='text' value={fontColor} style={{width:'100%', border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                    </span>
              </Grid>

            </Grid>

            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                <Button
                variant="contained"
                style={{  
                letterSpacing: '2px',marginRight: "1rem" }}
                onClick={handleCancel}
                >
                Cancel
                </Button>

                <Button
                style={{letterSpacing: '2px', backgroundColor:theme.primary, color:theme.secondary}}
                variant="contained"
                onClick={handleSave}
                >
                Save
                </Button>
            </div>

      </Grid>

    </Modal>
  
  </Grid>
  )
}

export default Services