import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Grid, Button, InputBase, Typography, Avatar, Tooltip, IconButton, Hidden, withWidth, Modal, TextField} from '@material-ui/core';
import { firestore } from '../../config/fbConfig';
import ClearIcon from '@material-ui/icons/Clear';

var generator = require('generate-password');


function CreateBrandAmModal({modalOpen, setModalOpen}) {
    const [brandName, setBrandName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [themeHeader, setThemeHeader] = useState('')
    const [aboutImg, setAboutImg] = useState('')
    const [logo, setLogo] = useState('')
    const [about, setAbout] = useState('')
    const [url, setUrl] = useState('')
    const [address, setAddress] = useState('')

    const createBrandAm = () => {
        var uid = generator.generate({
            length: 20,
            numbers: true
          })
        firestore.collection('brandAms').doc(uid).set({
            about:about,
            address:address,
            availability: '',
            class:'business',
            email: email,
            emailSupport:email,
            lastUpdated: new Date(),
            name: brandName,
            phone: phone,
            services:[{name: 'Consultation', details:'', price:'0.00', reqDeposit:false, serviceType:'Other', time:30}, {name: 'Template Service', details:'', price:'0.00', reqDeposit:false, serviceType:'Other', time:60}],
            socialFB: '',
            socialIF: '',
            socialTW: '',
            socialTT: '',
            socialWed: '',
            staff:['antony@cutiapp.com', 'book.razzadah@gmail.com'],
            subtitle:subtitle,
            themeLight: false,
            themePrimary: '#ff0000',
            themeSecondary: '#ffffff',
            themeTemplate: 1,
            url: brandName.includes(" ") ? brandName.split(" ").join("-").toLocaleLowerCase() : brandName.toLocaleLowerCase()
        }).then(()=> {
            firestore.collection('brandAms').doc(uid).collection('design').doc('designInfo').set({
                about: {
                    backgroundColor: '#ffffff',
                    backgroundImage: aboutImg,
                    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                    fontColor:'#000000',
                    split:true,
                    title:'About',
                    titleColor: '#000000',
                },
                footer: {
                    backgroundColor: '#ffffff',
                    compactFooter:true,
                    displayContatInfo:true,
                    fontColor: '#000000',
                },
                gallery: [],
                library: [],
                hero: {
                    backgroundColor: '#ffffff',
                    backgroundImage: themeHeader,
                    buttonText:'Book With Us!',
                    fadeOverlay:false,
                    fontColor:'black',
                    imageOpacity:'',
                    logo:logo,
                    subtitle:subtitle,
                    subtitleColor: '#000000',
                    title: brandName,
                    titleColor: '#000000',
                    textAlignment:'center'
                },
                services: {
                    backgroundColor: '#ffffff',
                    cardColor:'#ffffff',
                    fontColor: '#000000',
                    titleColor: '#000000',
                },
                staff: {
                    backgroundColor: '#ffffff',
                    cardColor:'#ffffff',
                    fontColor: '#000000',
                    titleColor: '#000000',
                },
                theme:{
                    lightTheme: false,
                    primary: '#ff0000',
                    secondary: '#ffffff',
                    template:1
                },
                themeLight:false,
                themePrimary:'#ff0000',
                themeSecondary: "#ffffff",
                themeTemplate:1
            })
        })
    }

  return (
    <Modal open={!!modalOpen} onClose={setModalOpen}>
        
        <Grid container xs={10} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
        <Grid item container justify='space-between' style={{backgroundColor: `#413e68`, color: `white`, height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
            <Typography variant='h6' style={{padding: '10px'}}> 
                Create A New Brand
            </Typography>
            <IconButton onClick={()=>{setModalOpen(!modalOpen)}} style={{color: 'white'}}>
                <ClearIcon />
            </IconButton>
        </Grid>

        <Grid item xs={12} container style={{padding:20, maxHeight:'80vh', overflowY:'scroll'}}>

            <Grid item container xs={12} style={{marginTop:10}}>
                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='Brand Name'
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                    />
                </Grid>
                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='Phone'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='Subtitle'
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                    />
                </Grid>

                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='Address'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />

                </Grid>

                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='URL'
                        value={brandName.includes(" ") ? brandName.split(" ").join("-").toLocaleLowerCase() : brandName.toLocaleLowerCase()}
                    />
                </Grid>
                
                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='Header Image Url'
                        value={themeHeader}
                        onChange={(e) => setThemeHeader(e.target.value)}
                    />
                </Grid>

                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='About Image Url'
                        value={aboutImg}
                        onChange={(e) => setAboutImg(e.target.value)}
                    />
                </Grid>

                <Grid item container xs={12} md={6} lg={3}>
                    <TextField
                        variant='outlined'
                        label='Logo Image Url'
                        value={logo}
                        onChange={(e) => setLogo(e.target.value)}
                    />
                </Grid>





                <Button disabled={brandName === ''}
                onClick={createBrandAm}>Create</Button>
            
            </Grid>

            </Grid>

        </Grid>
    </Modal>
  )
}

export default CreateBrandAmModal