import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'

var config = {
    apiKey: "AIzaSyDa_I8zG8L-tfutMU97YEkiBSZ1IRopaGw",
    authDomain: "cuti-app-7c963.firebaseapp.com",
    databaseURL: "https://cuti-app-7c963.firebaseio.com",
    projectId: "cuti-app-7c963",
    storageBucket: "cuti-app-7c963.appspot.com",
    messagingSenderId: "5074722352",
    appId: "1:5074722352:web:b240c1474765f16c75d7f5",
    measurementId: "G-D8PLLCJS38"
  };
  // Initialize Firebase
firebase.initializeApp(config);
//firebase.analytics();

export const firestore = firebase.firestore()
//export const messaging = firebase.messaging()
export const auth = firebase.auth()
export const storage = firebase.storage()

export default firebase;