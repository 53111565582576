import React, {useEffect, useState} from 'react'
import {Typography, Grid} from "@material-ui/core";
import {firestore} from '../../../../config/fbConfig';
import AppointmentCard from '../../../appointments/AppointmentCard';

import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";

import { makeStyles } from '@material-ui/core/styles';

import {useSelector} from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      border: '3px #413e68 solid',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },

  }));

export default function PreviousAppointments() {

  const classes = useStyles();
  const [appointments, setAppointments] = useState([])

  const userData= useSelector(state => state.auth.userData)
  
  useEffect(() => {
    //const userAppointments = firestore.collection("customers").doc(`${userData.userId}`)
    let unsubscribeFromSetAppt = null;
    unsubscribeFromSetAppt = firestore.collection("appointments").where("serviceComplete", "==", true)
    .onSnapshot((querySnapshot) => {
      var appts = [];
      querySnapshot.forEach((doc) => {
        if(doc.data().clientUID === userData.userId){
          appts.push({docData: doc.data(), id: doc.id});
          appts.sort((a,b) => b.docData.serviceTimeEnd.seconds*1000 > a.docData.serviceTimeEnd.seconds*1000 && 1 || -1)
        }
        });
        setAppointments(appts)
    })
    return () => unsubscribeFromSetAppt()
  },[])

    return (
        <Grid container item xs={12} style={{borderRadius: 12, border:'1px solid #e0e0e0', position: 'relative', height: 'inherit', boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)', textAlign: 'left'}}>
            <Grid item xs={12} style={{borderRadius: '8px 8px 0 0', background: 'grey', height: "50px"}}>
                <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Previous Appointments</Typography>
            </Grid>

            {/* <Grid item xs={12} style={{height: 'fit-content', textAlign: 'center'}}>
                <Typography variant='caption' style={{color: 'grey'}}>You have no Upcoming Appointments <br/>--</Typography>
            </Grid> */}

            <Grid container style={{width: 'inherit', height:'80%', padding:'5px 5px 0 0'}}>
              <SimpleBarReact autoHide={false} style={{maxHeight: '96%', width: 'inherit', padding:'0px 15px 5px 5px'}}>
                { appointments.length !== 0 ? appointments.map((appt, idx) => {
                  return(
                    <AppointmentCard appt={appt} key={idx} userData={userData}/>
                  )
                })
                :
                <Grid item xs={12} style={{height: 'fit-content', textAlign: 'center'}}>
                  <Typography variant='caption' style={{color: 'grey'}}>You have no Previous Appointments <br/>--</Typography>
                </Grid> 
                }
                
              </SimpleBarReact>
            </Grid>
            
        </Grid>
    )
}
