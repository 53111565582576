import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button} from '@material-ui/core';
import {NavLink} from 'react-router-dom'
import ComingSoonModal from '../modals/ComingSoonModal';

const useStyles = makeStyles(theme => ({
    navBtn: {
      padding: "0",
      height: "100%",
      borderRadius: '0',
    },
  }));


const SignedOutLinks = () => {
  const classes = useStyles();

    return(
        
            <Grid container item xs={4} justify="center" >
                <Grid item xs={3}></Grid>
                <Grid item xs={3} > <Button component={NavLink} to='/ip/about' fullWidth className={classes.navBtn}>About</Button> </Grid>
                <Grid item xs={3} > <Button component={NavLink} to='/signup' fullWidth className={classes.navBtn}>Sign Up</Button> </Grid>
                <Grid item xs={3} > <Button component={NavLink} to='/signin' fullWidth style={{background: "#413e68", color: 'white'}} variant="contained" className={classes.navBtn}>Sign In</Button> </Grid>
            </Grid>    
       
        
    )
}

export default SignedOutLinks;