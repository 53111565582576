import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Avatar, IconButton, Modal, TextField, TextareaAutosize, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    profileContainer:{
      position: 'relative',
      display:"flex",
      flexFlow:'column',
      alignItems:'center',
      padding: 10,
      marginBottom: 12,
    },
    avatar: {
      height: 140,
      width: 140,
    },
    imgSelecter:{
      position:'absolute',
      top: 120,
      left: '52%',
      border: '2px solid white',
      backgroundColor: theme.palette.primary.main,
      '&:hover':{
        cursor:'pointer',
        opacity: .8,
      },
      accordianDetails:{
        width:'100%',
        display:'flex',
        flexFlow:'column',
        textAlign: 'left'
      },
      accordianEditBtn:{
        alignSelf:'flex-end'
      },
    }
  
  }));

function EditSocialMediaModal(props) {
    const {modalOpen, setModalOpen, userData} = props
    const {socialMedia, showPhone, showEmail} = userData
    const classes = useStyles()
    const [facebook, setFacebook] = useState(socialMedia.facebook)
    const [instagram, setInsta] = useState(socialMedia.instagram)
    const [twitter, setTwitter] = useState(socialMedia.twitter)
    const [tiktok, setTiktok] = useState(socialMedia.tiktok)
    const [validFb, setValidFb] = useState(false)
    const [validIg, setValidIg] = useState(false)
    const [validTw, setValidTw] = useState(false)
    const [validTt, setValidTt] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openErr, setOpenErr] = useState(false)
    const fileInput = useRef();
    const [image, setImage] = useState('');
    const [avatar, setAvatar] = useState('')
    const disabled = (validFb === false || validIg=== false || validTt=== false || validTt=== false) ? true : false
    const severity = ["error", "success"]
       
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSuccess(false);
        setOpenErr(false);
      };

    const handleSave = () => {
        var userRef = firestore.collection(userData.collection).doc(userData.userId);

            return userRef.update({
                "socialMedia.facebook": facebook, "socialMedia.instagram": instagram, "socialMedia.twitter":twitter, "socialMedia.tiktok": tiktok,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                setOpenSuccess(!openSuccess)
            }).then(
                    () => {setTimeout(function(){setModalOpen(!modalOpen)}, 3000)}
            )
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
                setOpenErr(!openErr)
            })
        
      }
       

    useEffect(() => {
        // effect
        if(facebook.includes('.com') && !facebook.includes('facebook')){
          setValidFb(false)
        }else{
          setValidFb(true)
        }
        if(instagram.includes('.com') && !instagram.includes('instagram')){
          setValidIg(false)
        }else{
          setValidIg(true)
        }
        if(twitter.includes('.com') && !twitter.includes('twitter')){
          setValidTw(false)
        }else{
          setValidTw(true)
        }
        if(tiktok.includes('.com') && !tiktok.includes('tiktok')){
          setValidTt(false)
        }else{
          setValidTt(true)
        }
        
        // return () => {
        //     cleanup
        // }
    }, [facebook, instagram, twitter, tiktok, validFb, validTt, validIg, validTw])

    return (
        <Modal open={!!modalOpen}>
              
        <Grid container item xs={10} sm={6} md={4} lg={3} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
            <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Edit Social Accounts
                </Typography>
                <IconButton onClick={() => setModalOpen(!modalOpen)} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid container item xs={12} className={classes.profileContainer}>

                {/* <Typography variant='h4'>{facebook + " " + instagram}</Typography>
                <Typography variant='h5'>{twitter}</Typography> */}

            </Grid>

            <Grid item xs={12} container style={{padding:20}}>

                <TextField
                    label="Facebook url"
                    inputMode="text"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    placeholder={socialMedia.facebook}
                    name="facebook"
                    value={facebook}
                    onChange={(e)=> setFacebook(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
                {validFb === false && <Typography style={{color:'red'}}>Please enter a valid facebook url</Typography>}

                <TextField
                    label="Insta url"
                    inputMode="text"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    placeholder={socialMedia.instagram}
                    name="instagram"
                    value={instagram}
                    onChange={(e)=> setInsta(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
                {validIg === false && <Typography style={{color:'red'}}>Please enter a valid Instagram url</Typography>}
        
 
                <TextField
                    label="Twitter url"
                    inputMode="text"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    placeholder={socialMedia.twitter}
                    name="twitter"
                    value={twitter}
                    onChange={(e)=> setTwitter(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
                {validTw === false && <Typography style={{color:'red'}}>Please enter a valid Twitter url</Typography>}
                
                <TextField
                    label="Tiktok url"
                    inputMode="text"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    placeholder={socialMedia.tiktok}
                    name="tiktok"
                    value={tiktok}
                    onChange={(e)=> setTiktok(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
                {validTt === false && <Typography style={{color:'red'}}>Please enter a valid Tiktok url</Typography>}
                
            </Grid>

            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                <Button
                variant="contained"
                style={{  
                letterSpacing: '2px',marginRight: "1rem" }}
                onClick={() => setModalOpen(!modalOpen)}
                >
                Cancel
                </Button>
                <Button
                disabled={disabled}
                style={{  
                letterSpacing: '2px', }}
                variant="contained"
                color='primary'
                onClick={handleSave}
                >
                Save
                </Button>
            </div>
            
            <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[1]}>
                    Account Updated Successfully!
                </Alert>
            </Snackbar>
            <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[0]}>
                Oops, something went wrong...
                </Alert>
            </Snackbar>
        </Grid>


    </Modal>
    )
}

export default EditSocialMediaModal
