import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import {Typography, Grid, TextField, Button, Avatar, Tooltip, withWidth} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import StarIcon from '@material-ui/icons/Star';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    title: {
      color: theme.palette.primary.main,
      padding: "8px 0 0 20px",
      fontWeight:'bold',
    },
    btn: {  
      fontWeight: 'bold',
      border: '3px #413e68 solid',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    info: {
        fontSize: 12,
        color: 'grey',
    },
    infoIcon: {
        fontSize: 16,
        color: 'grey',
        verticalAlign: 'middle'
    },
    infoContainer: {
        display:'flex',
        alignItems: 'center',
    }

  }));
  

const Review = ({ formData, navigation, width }) => {
  const { service, serviceCost, bookingFee, travelFee, dateTime, address, havePets, petTypes, pro, reqDeposit } = formData;
  const firstName = pro.name.split(' ')[0]
  const lastInitial = pro.name.split(' ')[1].charAt(0)
  const firstNameLastInitial = firstName + ' ' + lastInitial + '.'
  
  const dateTimeArry = dateTime.toString().split(' ');

  const weekday = dateTimeArry.slice(0, 1)
  const monthDay = dateTimeArry.slice(1, 3).join(' ')
  const date = weekday + " - " + monthDay + ", " + dateTimeArry.slice(3,4)

  const timeZoneArr = dateTimeArry.slice(6,9).join(' ').split('(').join(' ').split(')').join(' ').trim().split(' ')
  const timeZone = timeZoneArr.map((word) => word.charAt(0)).join("")
  const time = dateTimeArry.slice(4,5).join('') + " (" + timeZone + ")"

  const total = (parseFloat(serviceCost) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)
  //const deposit = (parseFloat(serviceCost / 4) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)
  const deposit = (reqDeposit === true) ? (parseFloat(serviceCost / 4) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2) : (parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)


  const avatarSize = (width === 'xs' || width === 'sm') ? 30 : 40
  const priceSize = (width === 'xs' || width === 'sm') ? 22 : 26

  console.log("form data:", formData)



  const classes = useStyles();

//   const handleToken = async (token) => {
//     const response = await axios.post('https://oyfkz.sse.codesandbox.io/checkout', {
//       token,
//       formData
//     })
//     const {status} = response.data
//     if (status === 'success') {
//       // console.log(status)
//       navigation.next()
//     }
//   }
  
  return (
    <Grid item xs={12} container style={{outline:'none', position:'absolute', height:600, top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
      <Grid item xs={12}>
        <Typography variant='h5' className={classes.title}>Booking with: {pro.name}</Typography>
      </Grid>

      <Grid container item xs={12} style={{padding:'0px 20px'}}>
        <Typography variant='h6' style={{color:"#413e68"}}>6. Review</Typography>
      </Grid>

      <Grid container item xs={12} style={{maxHeight:360, padding:' 0 20px',}}>
          
          <Grid item container xs={12} style={{border: "1px solid grey", maxHeight: 240 }}>
            <Grid item container xs={12} md={6} style={{borderRight: "1px solid lightgrey", height: 120, padding: 5}}>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>{service}</Typography>
                </Grid>

                <Grid item xs={12} className={classes.infoContainer}>
                    <Typography className={classes.info}>
                        <LocationOnIcon className={classes.infoIcon}/> {address}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.infoContainer}>
                    <Typography className={classes.info}>
                        <CalendarTodayIcon className={classes.infoIcon}/> {date}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.infoContainer}>
                    <Typography className={classes.info}>
                        <AccessTimeIcon className={classes.infoIcon}/> {time}
                    </Typography>
                </Grid>
            </Grid>
            
            <Grid item container xs={12} md={6} style={{borderRight: "1px solid lightgrey", height: 120, padding: 5, marginBottom: 6}}>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>Pro's Info:</Typography>
                </Grid>

                <Grid item container xs={12} style={{borderBottom: "1px solid lightgrey"}}>
                    <Grid xs={2}>
                        <Avatar src={pro.avatar} style={{height: avatarSize, width: avatarSize}}></Avatar>
                    </Grid>
                    
                    <Grid item container xs={7}>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: 'bold'}}>{firstNameLastInitial}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.info}>{pro.title}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography style={{fontSize:12}}>({(pro.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / pro.ratings.length).toFixed(2)} <StarIcon style={{fontSize: 12, color: 'yellow', verticalAlign: 'middle'}}/>)</Typography>
                    </Grid>
                </Grid>
            
                <Grid item xs={6} style={{display:'flex', alignItems:'center'}}>
                   <Typography style={{fontWeight: 'bold'}}>Service Cost</Typography> 
                </Grid>

                <Grid item xs={6} style={{alignItems:'center', display:'flex', justifyContent:'flex-end'}}>
                   <Typography style={{fontWeight: 'bold', fontSize: priceSize}}>${serviceCost}
                   {formData.priceVaries === true &&
                   <Tooltip title="This price is subjet to change! Be sure to communicate price with your Service Provider."><HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/></Tooltip>
                   }
                   </Typography> 
                </Grid>
            
            </Grid>
          </Grid>

          <Grid item container xs={12} style={{border: "1px solid grey", marginTop: 10 }}>
            <Grid item container xs={12} style={{borderBottom: "1px solid lightgrey", height: '50%', padding:'0 5px'}}>
                <Grid item container xs={6}>
                    <Typography>Travel Fee <Tooltip title="This fee helps cover the cost of travel. The amount will likely vary for each service."><HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/></Tooltip>:</Typography>
                </Grid>

                <Grid item container xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
                    <Typography style={{fontWeight: 'bold'}}>${travelFee}</Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} style={{height: '50%', padding:'0 5px'}}>
                <Grid item container xs={6}>
                    <Typography>Booking Fee <Tooltip title="The service fee is fixed to $5.99 or 6% of the cost of service, whichever greater. Price subject to change."><HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/></Tooltip>:</Typography>
                </Grid>

                <Grid item container xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
                    <Typography style={{fontWeight: 'bold'}}>${bookingFee}</Typography>
                </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} style={{border: "1px solid grey", marginTop: 10, padding:'0 5px' }}>
            <Grid item container xs={6}>
                <Typography style={{fontWeight: 'bold', display:'flex', alignItems:'center'}}>Total <Tooltip title={`The total amount due for your service. ${formData.priceVaries === true ? " PRICE OF THIS SERVICE SUBJECT TO CHANGE." : ""}`}><HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/></Tooltip>:</Typography>
            </Grid>

            <Grid item container xs={6} style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                <Typography style={{fontWeight: 'bold', fontSize: priceSize}}>${`${total}`}</Typography>
            </Grid>

            <Grid item container xs={12} style={{borderTop: "1px solid lightgrey", padding:'0 5px'}}>
                    <Grid item container xs={7}>
                        <Typography style={{fontWeight: 'bold'}}>Deposit <Tooltip title="Booking Fee + Any addition Deposit required by your service provider."><HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/></Tooltip>:</Typography>
                    </Grid>

                    <Grid item container xs={5} style={{display:'flex', justifyContent:'flex-end'}}>
                        <Typography style={{fontWeight: 'bold'}}>${deposit}</Typography>
                    </Grid>
              </Grid>
          </Grid>


      </Grid>

      <Grid container item xs={6} style={{ padding: '0px 10px 50px', justifyContent: 'flex-end'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ backgroundColor: 'white',  
              color: '#413e68'}}
            onClick={() => navigation.previous()}>
            Back
        </Button>

      </Grid>

      <Grid container item xs={6} style={{ padding: '0px 10px 50px', justifyContent: 'left'}}> 
          <Button
          fullWidth
            variant="contained"
            className={classes.btn}
            style={{ backgroundColor: '#413e68',  
              color: 'white'}}
            onClick={() => navigation.next()}
            >
            Confirm
        </Button>

      </Grid>
    </Grid>
  );
};


Review.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Review)
