import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, withWidth, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CurrentBalance from "./WithdrawBalance";
import WeeklyOverview from "./WeeklyOverview";
import { firestore } from "../../../../config/fbConfig";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useHistory } from "react-router";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromiseTest = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
);

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: "white",
    marginTop: "20px",
    height: 510,
    boxShadow: "5px 5px 7px -1px rgba(0,0,0,0.26)",
    textAlign: "left",
    alignSelf: "center",
  },
  titleWrapper: {
    borderRadius: "8px 8px 0 0",
    background: theme.palette.secondary.dark,
    height: "50px",
  },
  title: {
    color: "white",
    padding: "8px 0 0 20px",
  },
  cbWrapper: {
    flex: "0 1 auto",
    marginBottom: 20,
  },
  woWrapper: {
    flex: "1 1 auto",
  },
}));

function EarningsQuickView({ userData, width }) {
  const history = useHistory();
  const classes = useStyles();
  const [appointments, setAppointments] = useState([]);

  const padding = width === "xs" || width === "sm" ? "4px" : "20px";
  const alignSelf = width === "xs" || width === "sm" ? "start" : "center";

  useEffect(() => {
    //let userAppointments = firestore.collection("customers").doc(`${userData.userId}`)
    firestore
      .collection("appointments")
      .where("serviceCanceled", "==", false)
      .onSnapshot((querySnapshot) => {
        var appts = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().proUID === userData.userId) {
            appts.push(doc.data());
          }
        });
        setAppointments(appts);
      });
    // cancel subscription to useEffect
  }, []);

  return (
    <>
      {width === "sm" || width === "xs" ? (
        <Grid
          item
          container
          xs={12}
          style={{
            height: 360,
            backgroundColor: "#cdecff",
            padding: "20px 10px",
          }}
        >
          <Grid
            item
            container
            xs={12}
            style={{
              dispplay: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontWeight: "bold", fontSize: 20 }}>
              Earnings
            </Typography>
            <HelpOutlineIcon />
          </Grid>
          <Grid item xs={12} className={classes.woWrapper}>
            <WeeklyOverview appointments={appointments} />
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 10 }}
            onClick={() => history.push("/home/earnings")}
          >
            View Earnings Activity
          </Button>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          className={classes.root}
          style={{ alignSelf: alignSelf }}
        >
          <Grid item xs={12} className={classes.titleWrapper}>
            <Typography variant="h6" className={classes.title}>
              Earnings
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              width: "inherit",
              height: "90%",
              padding: padding,
              display: "flex",
              flexFlow: "column",
            }}
          >
            <Grid item xs={12} className={classes.cbWrapper}>
              {/* { appointments.length !== 0 ?  */}
              {userData && userData.test === false && (
                <Elements stripe={stripePromise}>
                  <CurrentBalance
                    appointments={appointments}
                    userData={userData}
                  />
                </Elements>
              )}
              {userData && userData.test === true && (
                <Elements stripe={stripePromiseTest}>
                  <CurrentBalance
                    appointments={appointments}
                    userData={userData}
                  />
                </Elements>
              )}

              {/* : */}
              {/* <Grid item xs={12} style={{height: 'fit-content', textAlign: 'center'}}>
                    <Typography variant='caption' style={{color: 'grey'}}>You have no Avaialable balance.<br/>--</Typography>
                </Grid>  */}
              {/* } */}
            </Grid>
            <Grid item xs={12} className={classes.woWrapper}>
              <WeeklyOverview appointments={appointments} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

EarningsQuickView.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(EarningsQuickView);
