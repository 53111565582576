import React, {useState, useEffect} from 'react'
import {Grid, Typography, Button, Modal, Tooltip, TextareaAutosize, IconButton, TextField, ClickAwayListener, Checkbox, FormControlLabel, FormGroup, Switch, Snackbar} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import { firestore } from '../../../../config/fbConfig';
import firebase from 'firebase';
const { m, h, } = require('time-convert')

const useStyles = makeStyles((theme) => ({
    service:{
        '&:hover': {
            cursor: 'pointer',
          },
    },
    selectedServiceDetails:{
      padding: '0px 30px',
      marginTop: 20,
    },
  }));


function ServiceCard({service, userData, setSelectedService, brandAmUID}) {
    const classes = useStyles()
    const [isSelected, setIsSelected] = useState(false)
    const [addOnChecked, setAddOnChecked] = useState(false)
    const [depositChecked, setDepositChecked] = useState(true)
    const [isBackgrondPink, setIsBackgrondPink] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [hours, setHours] = useState('')
    const [mins, setMins] = useState('')
    const [price, setPrice] = useState('')
    const [deets, setDeets] = useState('')
    const depositSwitchLabel = (depositChecked === true) ? "Yes" : "No"
    const addOnSwitchLabel = (addOnChecked === true) ? "Yes" : "No"
    const [success, setSuccess] = useState(false)
    const [pricesVary, setPricesVary] = useState(false)
    const [virtual, setVirtual] = useState(false)
    const [zoomLink, setZoomLink] = useState("")
    const [reasonInvalid, setReasonInvalid] = useState("")



    // const [selectedService, setSelectedService] = useState(null)
    const backgrdColor = !!isBackgrondPink ? "#413e68" : ""
    const fontColor = !!isBackgrondPink ? "white" : ''
    const disabled = (hours === "" || mins === "" || price === "" || reasonInvalid.length !== 0 || (virtual === true && zoomLink.length ===0)) ? true : false;

    const handleSelect = () => {
       setIsSelected(!isSelected)
       setIsBackgrondPink(!isBackgrondPink)
       if(service.price){
         setSelectedService(service)
       }
      //  setSelectedService(service)
      }
    
      const handleClickAway = () => {
        setIsBackgrondPink(false)
        setIsSelected(false)
      }
    
      const handleModalOpen = () => {
        setIsModalOpen(!isModalOpen)
      }

      const numsOnly = (e) => {
        const re = /[0-9]+/g;
        const reg = /[0-9.]+/g;
        if(e.target.name !== 'price'){
          if (!re.test(e.key)) {
            e.preventDefault();
          }
          if(e.target.value.length >= 2){
            e.preventDefault()
          }
        }else{
          if (!reg.test(e.key)) {
            e.preventDefault();
          }
        }
      }

      const toggleAddOnChecked = () => {
        setAddOnChecked(!addOnChecked)
        setDepositChecked(false)
        setPricesVary(false)
      }

      const toggleDepositChecked = () => {
        setDepositChecked(!depositChecked)
      }

      const handleSave = () => {
        const time = parseFloat((h.to(m)(parseFloat(hours)))) + (parseFloat(mins))
        // var UserRef = firestore.collection("professionals").doc("DC")
        // console.log(hours, mins, time)
        if(brandAmUID !== undefined){
          console.log("brandAm", brandAmUID)
          firestore.collection("brandAms").doc(`${brandAmUID}`).update({
            services: firebase.firestore.FieldValue.arrayUnion({name: service.name, price: price, serviceType: service.typeOfService,
                                                                time: time, details:deets, addOn: addOnChecked, reqDeposit: depositChecked,
                                                                priceVaries: pricesVary, virtual: virtual, zoomLink: zoomLink})
          })
          .then(() => setSuccess(!success))
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
        }else{
          firestore.collection("professionals").doc(`${userData.userId}`).update({
            services: firebase.firestore.FieldValue.arrayUnion({name: service.name, price: price, serviceType: service.typeOfService,
                                                                time: time, details:deets, addOn: addOnChecked, reqDeposit: depositChecked,
                                                                priceVaries: pricesVary, virtual: virtual, zoomLink: zoomLink})
          })
          .then(() => setSuccess(!success))
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });

        }

      }


      useEffect(() => {
        if(zoomLink.length !== 0 && reasonInvalid === ""){
          if(zoomLink.includes("https") === false){
            setReasonInvalid("Please include: https://")
            
          }else if(zoomLink.includes("zoom.us") === false){
            setReasonInvalid("Please use a Zoom link")
          }
        }else if(reasonInvalid.length!==0 && zoomLink.includes("https") && zoomLink.includes("zoom.us")){
          setReasonInvalid("")
        }
  
  
      }, [reasonInvalid, zoomLink])

  


    return (
      <>
        <ClickAwayListener onClickAway={handleClickAway}>
            <Grid item container xs={12} onClick={handleSelect} className={classes.service} style={{backgroundColor: backgrdColor, marginBottom:5}} >
                <Grid item xs={8} style={{textAlign:'left', color: fontColor}}>
                  <Typography>{service.name}</Typography>
                  {(isSelected && service.price) &&
                    <>
                     <br/>
                     <Typography style={{fontSize:12, color: fontColor}}>Details: {service.details}</Typography>
                    </>
                  }
                </Grid>

                {service.price && 
                <Grid item xs={4} style={{color: fontColor}}>
                  <Typography>${service.price} {(service.priceVaries!== undefined && service.priceVaries === true) ? " +" : null}</Typography>
                  {(isSelected && service.reqDeposit) ?
                    <>
                     <br/>
                     <Typography style={{fontSize:12, color: fontColor}}>Reqs Deposit</Typography>
                    </>
                    :
                    null
                  }
                </Grid>
                }

                {service.typeOfService && 
                <Grid item xs={4} style={{textAlign:'right', color: fontColor}}><Button variant="contained" color="primary" onClick={handleModalOpen}>Add</Button></Grid>
                }
            </Grid>

        </ClickAwayListener>

        <Modal open={!!isModalOpen} onClose={handleModalOpen}>
              
              <Grid container ontainer xs={10} sm={6} md={5} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Service Details
                    </Typography>
                    <IconButton onClick={handleModalOpen} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={12} container style={{maxHeight:'65vh', overflowY:'scroll'}}>
                  
                  <Grid item xs={12} className={classes.selectedServiceDetails}>
                    <Typography>Category: <span style={{fontWeight:'bold'}}>{service.typeOfService}</span></Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.selectedServiceDetails}>
                    <Typography>Name: <span style={{fontWeight:'bold'}}>{service.name}</span></Typography>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails}>
                    <Grid item xs={12} md={6}>
                      <Typography>Appointment Time:</Typography>
                    </Grid>

                    <Grid item container xs={12} md={6} style={{paddingRight:10}}>
                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Hrs"
                            name="hours"
                            value={hours}
                            placeholder='00'
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setHours(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>

                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Mins"
                            name="mins"
                            value={mins}
                            placeholder='00'
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setMins(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={6}>
                      <Typography style={{width:'100%'}}>Price
                        <Tooltip title="Selecting the 'Varies' checkbox allows you to create a general service price, and update the price later after confirming exactly what your client needs.">
                          <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                          </Tooltip>:
                      </Typography>

                      {(addOnChecked===false && parseFloat(price) !== 0 && price !== '') &&
                        <FormGroup>
                            <FormControlLabel
                            control={
                              <Checkbox
                                checked={pricesVary}
                                color='primary'
                                onChange={()=> setPricesVary(!pricesVary)}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name="vary"
                              />
                            }
                            label="Varies"
                          />
                        </FormGroup>
                      }

                    </Grid>

                    <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center'}}>
                          <div style={{marginRight:10, fontSize:18}}>$</div>
                          {pricesVary === false ?
                          <TextField
                            label="Price"
                            name="price"
                            value={price}
                            placeholder='00.00'
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setPrice(e.target.value); console.log(parseFloat(price))}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                            
                          />
                           :
                          <div style={{width:'inherit', display:'flex', flexFlow:'row', justifyContent:'space-evenly', alignItems:'center'}}>
                            <TextField
                              label="Price"
                              name="price"
                              style={{width:'70%'}}
                              value={price}
                              placeholder={`${price}`}
                              onKeyPress={(e) => numsOnly(e)}
                              onChange={(e) => {setPrice(e.target.value); console.log(parseFloat(price))}}
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              required
                            />
                            <Typography style={{fontSize:10}}> and up</Typography>
                          </div>
                          }
                    </Grid>

                    <Typography style={{width:'inherit'}}>Details <em>(optional)</em>:</Typography>
                    <TextareaAutosize
                      style={{padding:5, fontSize: 16, font:'inherit', borderColor:'silver', borderRadius:4, width:'inherit'}}
                      rowsMax={3}
                      rowsMin={3}
                      label="deets"
                      name="deets"
                      value={deets}
                      onChange={(e) => {setDeets(e.target.value)}}
                    />

                    {(depositChecked === false && parseFloat(price) !== 0) &&
                    <>
                      <Grid item xs={6} style={{marginTop:20}}>
                        <Typography>Is this an Add-on service?
                            <Tooltip title="This option allows your client to add this service in addtion to their main service (i.e: Nail fix, hair wash, etc.). This service will not appear until checkout.">
                              <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                              </Tooltip>
                        </Typography>
                      </Grid>

                      <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'flex-end', marginTop:20}}>
                        <FormControlLabel 
                            control={<Switch size="normal" color="primary" checked={addOnChecked} onChange={toggleAddOnChecked} />}
                            label={addOnSwitchLabel}
                            />
                      </Grid>
                    </>
                    }

                    {(addOnChecked===false && parseFloat(price) !== 0 && price!=='') &&
                      <Grid item xs={6} style={{marginTop:20}}>
                        <Typography>Require Deposit?
                          <Tooltip title="Require your customer to pay 25% down when booking.">
                            <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                            </Tooltip>
                      </Typography>
                      </Grid>
                      }
                      {(addOnChecked===false && parseFloat(price) !== 0 && price!=='') &&
                      <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'flex-end', marginTop:20}}>
                        <FormControlLabel 
                            control={<Switch size="normal" color="primary" checked={depositChecked} onChange={toggleDepositChecked} />}
                            label={depositSwitchLabel}
                            />
                      </Grid>
                      }

                      <Grid item xs={12} md={6}>
                          <FormGroup>
                          <FormControlLabel
                          control={
                            <Checkbox
                              checked={virtual}
                              color='primary'
                              onChange={()=> setVirtual(!virtual)}
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              name="vary"
                            />
                          }
                          label="Virtual Service"
                        />
                      </FormGroup>
                        </Grid>

                      {virtual === true &&
                        <Grid item container xs={12} md={6} style={{padding:'0px 10px', display:'flex', flexFlow:'column', alignItems:'center'}}>
                              <TextField
                                label="Zoom Link"
                                name="zoomlLink"
                                // defaultValue={selectedService.price}
                                value={zoomLink}
                                placeholder="https://us05web.zoom.us/j/{PERSONAL_METTING_ID}}"
                                onChange={(e) => {setZoomLink(e.target.value)}}
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                required
                                fullWidth
                              />
                              {reasonInvalid.length !== 0 &&
                              <Typography style={{width:'100%', color:'red', fontSize:14, lineHeight:'1',marginBottom:30}}>Invalid URL: {reasonInvalid}</Typography>
                              }
                        </Grid>
                      }



                  </Grid>

                  <Grid item xs={12} container className={classes.selectedServiceDetails} style={{marginBottom: 40}}>
                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" fullWidth onClick={handleModalOpen}>Cancel</Button>
                    </Grid>

                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" color="primary" disabled={disabled} fullWidth onClick={handleSave}>Save</Button>
                    </Grid>
                  </Grid>
      
      
                  {/* <Button variant="contained" color="primary" size="small" className={classes.button}>
                      ADD
                  </Button> */}
                </Grid>

                <Snackbar
                  open={success}
                  autoHideDuration={2000}
                  // onClose={()=> {window.location.reload()}}
                  message="Service added successfully!"
                />


              </Grid>
            </Modal>

      </>
    )
}

export default ServiceCard
