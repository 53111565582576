import React,{useEffect, useRef, useState} from 'react'
import {Button, CssBaseline, Grid, IconButton, Typography, Link} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Header from './LandingPage/Header'
import InformationalSection from './LandingPage/InformationalSection'
import WhyUs from './LandingPage/WhyUs'
import Footer from './Footer'
import MailingListModal from '../modals/MailingListModal';
import { useHistory } from 'react-router-dom';
import PartnersSection from './LandingPage/PartnersSection';
import CloseIcon from '@mui/icons-material/Close';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();



const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
      backgroundColor:'white',
    },
    headerImage: {
        height: '600px',
        backgroundImage: 'url(https://static.wixstatic.com/media/ecef23b48595462198d6d7287936f358.jpg/v1/fill/w_1397,h_688,al_c,q_85/ecef23b48595462198d6d7287936f358.webp)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    image: {
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    text: {
        textDecoration: 'none',
        fontFamily: "spinnaker,sans-serif",
        display: "block"
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

const LandingPage = ({user, width}) => {
  const proSignUp = useRef(null);
  const [closed, setClosed] = useState(false)
  const [mailingListModalOpen, setMailingListModalOpen] = useState(false)

  const scrollToProSignUp = () => 
    window.scrollTo({
      top: proSignUp.current.offsetTop,
      behavior:'smooth'
    })

  useEffect(() => {
    
    if(!!user){
      history.push("/home")
    }

	setTimeout(()=> setMailingListModalOpen(!mailingListModalOpen), 2500)

  }, [])

    const history = useHistory();
    const classes = useStyles();

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {/* {closed === false ?
        <Grid item contianer xs={12} style={{positon:'relative', height:50, backgroundColor:'#413e68', padding:'0px 10px', display:'flex', alignItems: 'center'}}>
          <Typography style={{width:'100%', textAlign:'center', color:'white'}} component={Link} href="/signup">Sign Up as a PRO, get $20.00!</Typography>
          <IconButton style={{psotion:'absolute', right:0,}} onClick={()=> setClosed(!closed)}><CloseIcon style={{color:'white'}}/></IconButton>
        </Grid> 
        :
        null
        } */}
        <Header user={user} scrollToProSignUp={scrollToProSignUp}/>

        <Grid item container xs={12} style={{height:'80vh', backgroundColor:'white'}}>
          <Grid item container xs={12} style={{margin:'auto', position:'relative', overflow:'hidden', maxWidth:1200, height:'inherit'}}>
            <img data-aos="fade-up" src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Fschedule.png?alt=media&token=456e130b-93a6-4c14-a76a-1787ea89542c' alt='schedule'
            style={{filter:'blur(2px)' ,height: '70%', position:'absolute', margin:'auto', bottom: 0, left:0, right:width <= 'md' ? 0 : -200}} />
            <div style={{width:'100%', position:'absolute'}}>
                <Typography data-aos="fade-down" style={{fontSize: width <= 'md' ? 100 : 70, fontWeight:'bold', width:'100%', textAlign:'center', lineHeight:'70%', marginTop: width <= 'md' ? 50 : 30, color:'#ceebff', textShadow:'1px 1px #413e68, -1px 1px #413e68, 1px -1px #413e68, -1px -1px #413e68'}}>SIMPLE</Typography>
                <Typography data-aos="fade-down" style={{fontSize: width <= 'md' ? 64 : 45, fontWeight:'bold', width:'100%', textAlign:'center', lineHeight:'70%', color:'#413e68', textShadow:'1px 1px #413e68, -1px 1px #413e68, 1px -1px #413e68, -1px -1px #413e68'}}>POWERFUL</Typography>
                <Typography data-aos="fade-down" style={{fontSize: width <= 'md' ? 160 : 110, fontWeight:'bold', width:'100%', textAlign:'center', lineHeight:'70%', color:'#ffffff', textShadow:'1px 1px #413e68, -1px 1px #413e68, 1px -1px #413e68, -1px -1px #413e68'}}>FREE</Typography>
            </div>

          <div data-aos="fade-right" data-aos-delay="700" style={{width: width <= 'md' ? 300 : 250, padding:2, backgroundColor:'white', position:'absolute', bottom: 0, left:0, right:0, margin: width <= 'md' ? 'auto auto 350px 200px' : 'auto auto 320px 10px', borderRadius:14, boxShadow:'3px 3px 6px grey'}}>
            <Typography style={{fontWeight:'bold'}}>Text Notifications</Typography>
            <Typography style={{fontSize:'12px'}}>Get notified the moment you recieve an update on your appointment!</Typography>
            <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2FScreen%20Shot%202022-10-14%20at%205.15.03%20PM.png?alt=media&token=838e2a97-1517-4c0e-a1ff-fe0f5fb9e868' alt='text' 
            style={{width:'100%', borderRadius:14}}
            />
          </div>

          <div data-aos={width <= 'md' ? "fade-left": "fade-right"} data-aos-delay="700" style={{width: width <= 'md' ? 300 : 250, padding:2, backgroundColor:'white', position:'absolute', bottom: 0, left:0,right:0, margin: width <= 'md' ? 'auto 200px 10px auto' : 'auto auto 10px 10px', borderRadius:14, boxShadow:'3px 3px 6px grey'}}>
            <Typography style={{fontWeight:'bold'}}>Simple Dashboards</Typography>
            <Typography style={{fontSize:'12px'}}>Manage your earnings and appointments easier with our easy to use interface.</Typography>
            <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2FScreen%20Shot%202022-10-14%20at%205.33.19%20PM.png?alt=media&token=4d6a9318-1afb-4f4a-8719-d1306813e214' alt='text' 
            style={{width:'100%', borderRadius:14}}
            />
          </div>
          </Grid>
          

        </Grid>

        <Grid item container xs={12} style={{backgroundColor:'white'}}>

        <Grid item container xs={12} style={{margin:'auto', position:'relative', maxWidth: 1200}}>
            <Typography style={{fontSize: width <= 'md' ? 80:55, fontWeight:'bold', textAlign:'center', lineHeight:1,  width:'100%', color:'#413e68', margin: width <= 'md' ? '160px 0px 60px' : '80px 0px 20px'}}>Creating a better client experience</Typography>
          </Grid>
         
         
          <Grid item container xs={12} style={{margin:'auto', position:'relative', overflow:'hidden', maxWidth: 1200, height: width <= 'md' ? '60vh' : 'fit-content'}}>
            
            <Grid data-aos="fade-right" item container xs={12} md={7} style={{height: width <= 'md' ? '100%' : '300px', padding:20, position:'relative'}}>
                <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Fdog-walking-service-scaled.jpeg?alt=media&token=b50ac34c-ba4b-41a7-9fa9-0e69ffca7035' alt=''
                  style={{borderRadius:14, height:'100%', width:'100%', objectFit:'cover'}}
                />

              <div data-aos="fade-right" style={{width: width <= 'md' ? 300 : 160, position:'absolute', margin:'auto', bottom: 40, left:0, right:0}}>
                <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2FScreen%20Shot%202022-10-14%20at%205.15.03%20PM.png?alt=media&token=838e2a97-1517-4c0e-a1ff-fe0f5fb9e868' alt='text' 
                style={{width:'100%', borderRadius:6, boxShadow:'3px 3px 6px grey'}}
                />
              </div>

            </Grid>
            <Grid data-aos="fade-left" item container xs={12} md={5} style={{height:'inherit', padding: width <= 'md' ? '70px 20px' : 20}}>
              <Typography variant='h2' style={{fontSize: width <= 'md' ? 60:40, fontWeight:'bold', lineHeight:1,  width:'100%', color:'#413e68'}}>Better appointmets</Typography>
              <Typography style={{width:'100%'}}>
                Looking for your next appointment with a local service provider? Need a tattoo, lawn care services, or someone to walk your dog?
                <br/>
                <br/>
                BizzyBook is a free scheduling platform and makes it easy to book appointments within seconds. No phone tag. Book anytime, from anywhere, 24/7.
                <br/>
                <br/>
                <strong>Discover top professionals & businesses in your area and book instantly.</strong>
              </Typography>
              <Button variant='outlined' color='primary' style={{borderRadius:0,marginTop:10, height:40}} onClick={()=> history.push('/signup')}>CREATE AN ACCOUNT</Button>


            </Grid>
          </Grid>



          <Grid item container xs={12} style={{margin:'auto', position:'relative', overflow:'hidden', maxWidth: 1200, height: width <= 'md' ? '60vh' : 'fit-content', display:'flex', flexFlow: width <= 'md' ? 'row-reverse' : 'column'}}>
            
            <Grid data-aos="fade-right" item container xs={12} md={7} style={{height: width <= 'md' ? '100%' : '300px', padding:20, position:'relative'}}>
                <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Fholdinghpone1.png?alt=media&token=55ad4e74-73d2-4828-a5a4-8f1f0b6802fe' alt=''
                  style={{borderRadius:14, height:'100%', width:'100%', objectFit:'cover'}}
                />

              <div data-aos="fade-right" style={{width: width <= 'md' ? 300 : 160, position:'absolute', margin:'auto', bottom: 40, left:0, right:0}}>
                <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Fchat.png?alt=media&token=df8fca03-d72c-4a2c-86d4-f134ac0e9270' alt='text' 
                style={{width:'100%', borderRadius:6, boxShadow:'3px 3px 6px grey'}}
                />
              </div>

            </Grid>
            <Grid data-aos="fade-left" item container xs={12} md={5} style={{height:'inherit', padding: width <= 'md' ? '70px 20px' : 20}}>
              <Typography variant='h2' style={{fontSize: width <= 'md' ? 60:40, fontWeight:'bold', lineHeight:1,  width:'100%', color:'#413e68'}}>Flexibility at its best</Typography>
              <Typography style={{width:'100%'}}>
                Create a free account with us and manage your appointments from anywhere. Reschedule or cancel without picking up the phone.
                <br/>
                <br/>
                But communication is key - that why we provide in-app messaging that allows you to connect with the other party.
              </Typography>




            </Grid>
          </Grid>


          <Grid item container xs={12} style={{margin:'auto', position:'relative', overflow:'hidden', maxWidth: 1200, height: width <= 'md' ? '60vh' : 'fit-content'}}>
            
            <Grid data-aos="fade-right" item container xs={12} md={7} style={{height: width <= 'md' ? '100%' : '300px', padding:20, position:'relative'}}>
                <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Fpower-vs-pressure-washing-whats-the-difference.jpg?alt=media&token=0d325335-8521-431b-b779-c8704d66373c' alt=''
                  style={{borderRadius:14, height:'100%', width:'100%', objectFit:'cover'}}
                />

              <div data-aos="fade-right" style={{width: width <= 'md' ? 300 : 160, position:'absolute', margin:'auto', bottom: 40, left:0,right:0}}>
                <img src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Frating.png?alt=media&token=4234f02c-79f7-47b0-9883-a845fa916207' alt='text' 
                style={{width:'100%', borderRadius:6, boxShadow:'3px 3px 6px grey'}}
                />
              </div>

            </Grid>
            <Grid data-aos="fade-left" item container xs={12} md={5} style={{height:'inherit', padding: width <= 'md' ? '70px 20px' : 20}}>
              <Typography variant='h2' style={{fontSize: width <= 'md' ? 60:40, fontWeight:'bold', lineHeight:1,  width:'100%', color:'#413e68'}}>Book the best</Typography>
              <Typography style={{width:'100%'}}>
                See the top professionals on BizzyBook's marketplace. 
                <br/>
                <br/>
                Check out their profile, view thier portfolio and see what other people are saying in their reviews.
                <br/>
                <br/>
                <strong>Save time and leave the stress to someone else. With BizzyBook, setting up your next appointment is a breeze.</strong>
              </Typography>
              <Button variant='outlined' color='primary' style={{borderRadius:0,marginTop:10, height:40}} onClick={()=> history.push('/signup')}>GET STARTED</Button>



            </Grid>
          </Grid>


        </Grid>

        {/* <PartnersSection user={user}/> */}
        {/* <InformationalSection user={user} /> */}
        {/* <WhyUs user={user} proSignUp={proSignUp}/> */}
        <Footer user={user}/>
		<MailingListModal mailingListModalOpen={mailingListModalOpen} setMailingListModalOpen={setMailingListModalOpen}/>
      </Grid>
    );
}

LandingPage.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};


export default withWidth()(LandingPage);