import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, CircularProgress, withWidth} from '@material-ui/core'
//import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    formControl: {
      width:'inherit',
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    question: {
        color: 'gray',
        marginTop:20,
        marginBottom: 4,
      },
    chip: {
        margin: 2,
    },
    link:{
        '&:hover':{
            cursor:'pointer',
            color:theme.palette.primary.main,
        },
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
  }));


function CompleteOnboarding({userData, currentUser, width}) {
    const classes = useStyles()
    const [loading, setLoading] = useState(null)
   

    const smMargin = (width==='xs' || width==='sm') ? 50 : 0


      const handleContinue = async () => {
        //e.preventDefault()
        // console.log(userData.stripeId)
            try {
                const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/onboarding', {
                    id: userData.stripeId
                })
                if(response.data.success){
                    window.location.assign(response.data.accountLinks.url)
                }else{
                  console.log("Failure Link retrieval")
                }      
            } catch (error) {
                console.log("Stripe Onboarding Error:", error)
            }
        
    }

      useEffect(() => {

        if(userData !== undefined && userData !== {} && userData!== null){
          setLoading(true)
          if((userData.stripeId!==null && userData.stripeId !== undefined) && loading){
            handleContinue() 
            console.log('attempting onboarding')
          }
          else if(userData.stripeId===null || userData.stripeId === undefined || userData.stripeId === ""){
            setTimeout(() => {window.location.reload()}, 7000)
          }
          
        }


      }, [userData, loading])

    return (
        <Grid container item xs={12} style={{height: "fit-content", display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Grid item xs={10} sm={8} style={{marginLeft:smMargin, }}>
                <Typography variant='h4' style={{display:'flex', justifyContent:'center', textAlign:'center'}}>Complete your profile</Typography>
            </Grid>


            <Grid item xs={10} sm={8} style={{marginLeft:smMargin, display:'flex', flexFlow:'column', alignItems:'center'}}>
                {/* {loading === false && <Button variant="contained" color='primary' onClick={handleContinue} disabled={clicked}>Continue Onboarding</Button>} */}
                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                {loading && <Typography>You will be redirected in just a sec...</Typography>}
                {loading && <Typography>If you are not redirected after 15 seconds, please refresh the page.</Typography>}
                {/* {stripeURL && <Button variant="contained" color='primary' onClick={() => window.location.assign(`${stripeURL}`)}>Continue Onboarding</Button>} */}
            </Grid>

            <Grid item container xs={10} sm={8} style={{marginLeft:smMargin, display:'flex', flexFlow:'column', alignItems:'center'}}>
                <Typography style={{fontWeight:'bold', color:'gray', marginBottom:'10px'}}>Payment Information</Typography>        
                <Typography style={{color:'gray', padding:10, border:'1px solid #c0c0c0', borderRadius:'4px'}}>
                    We use Stripe to make sure you get paid on time and to keep your personal bank and details secure. Click <span style={{fontWeight:'bold'}}>CONTINUE ONBOARDING</span> to set up your payments on Stripe.
                    By registering and completing your account, you certify that the business information you are providing is accurate and agree to the <span className={classes.link} style={{fontWeight:'bold'}} onClick={() => window.open("https://stripe.com/issuing/celtic/spend-card-terms-and-disclosures/legal")}> Stripe Issuing terms and disclosures with Celtic Bank, Member FDIC .</span>
                </Typography>
            </Grid>


        </Grid>
    )
}

CompleteOnboarding.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(CompleteOnboarding)
