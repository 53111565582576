import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Avatar, IconButton, Modal, TextField, Switch, Tooltip, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core'
import PaymentMethods from './Account/PaymentMethods'
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { firestore } from '../../../config/fbConfig';
import firebase from 'firebase';
import BankDetails from './Account/BankDetails';
import { useSelector } from 'react-redux';
import History from './Account/History';
import Referrals from './Account/Referrals';
import EditAccountModal from '../../modals/EditAccountModal';
import ConfirmResetPwModal from '../../modals/ConfirmResetPwModal';



const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        width: 250,
      },
    },
  };


const useStyles = makeStyles((theme) => ({
    rootGrid: {
      display: 'flex',
      justifyContent:'center',
      minWidth:280,
    },
    sectionTitle:{
        fontSize: 24,
        fontWeight: 'bold',
        margin: "20px 0 10px",
    },
    sectionTitleSmall:{
        fontSize: 18,
        fontWeight: 'bold',
        margin: "20px 0 10px",
        width: 'inherit'
    },
    containerBox:{
        backgroundColor:'white',
        borderRadius:'8px',
        padding: 20,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexFlow:'column',
        position:'relative'
    },
    profilePic:{
        height:160,
        width:160
    }
    
  }));

function Account(props) {
    const {width, userData, appointments, user} = props;
    const classes = useStyles()
    const [modalOpen, setModalOpen] = useState(false)
    const [editAcctModalOpen, setEditAcctModalOpen] = useState(false)
    const [resetModalOpen, setResetModalOpen] = useState(false)
    const [editBankAcctsModalOpen, setEditBankAcctsModalOpen] = useState(false)
    const [debitCardNumber, setDebitCardNumber] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [debitCardExp, setDebitCardExp] = useState('')
    const [debitCardCvc, setDebitCardCvc] = useState('')
    const [update, setUpdate] = useState(null)
    const [name, setName] = useState('')
    const [focus, setFocus] = useState("")
    const [fixedSelected, setFixedSelected] = useState(false)
    const [percentageSelected, setPercentageSelected] = useState(false)
    // const [percentage, setPercentage] = useState('')
    // const [dollarAmount, setDollarAmount] = useState('')
    const [cfReq, setCFReq] = useState(userData.convenienceFeeReq)
    const [cf, setCF] = useState(userData.convenienceFee !== undefined ? userData.convenienceFee : '')
    const [includeBooking, setIncludeBooking] = useState(userData.includeBooking !== undefined ? userData.includeBooking : false)
    const [referralBtnClicked, setReferralBtnClicked] = useState(false)
    const [showAddress, setShowAddress] = useState(userData.showAddress)
    const [showDisplay, setShowDisplay] = useState(userData.showDisplay)
    const [showPhone, setShowPhone] = useState(userData.showPhone)
    const [showEmail, setShowEmail] = useState(userData.showEmail)

    const {firstName, lastName, title, images, displayName} = userData
    const fullname = firstName+" "+lastName
    // const colsAmt = width >= 'sm' ? 2 : 3
    const justifyBtn = (width === 'xs' || width === 'sm') ? 'flex-start' : 'flex-end'
    const padding = width > 'md' ? '0px 10px 0px 60px' : 0
    // const borderRgt = width <= 'lg' ? '1px solid grey' : 'none'
    // const borderBtm = width <= 'lg' ? 'none' : '1px solid grey'
    // const buttonTop = width <= 'sm' ? 150 : 15
    // const paddingRgt = width === 'xs' ? 12 : 0
    console.log("CF:", cf)

    const numsOnly = (e) => {
        const reg = /[0-9.]+/g;
          if (!reg.test(e.key)) {
            e.preventDefault();
          }

      }

    const handleSave = () => {
        var userRef = firestore.collection(`${userData.collection}`).doc(`${userData.userId}`);
        userRef.update({
            billingInfo: firebase.firestore.FieldValue.arrayUnion({cardHolder: name, cardNumber: debitCardNumber, expiration: debitCardExp, cvc: debitCardCvc, primary: false})
        }).then(() => window.location.reload())
 
    }

    const handleEditAcctModal = () => {
        setEditAcctModalOpen(!editAcctModalOpen)
    }

    const handleEditBankAcctsModal = () => {
        setEditBankAcctsModalOpen(!editBankAcctsModalOpen)
    }

    const handleChangeTravel = (event) => {  
        if(userData.travel === true){
            firestore.collection(userData.collection).doc(userData.userId).update({
             travel: !userData.travel,
             convenienceFeeReq: false,
             convenienceFee: ""
            }).then(()=> window.location.reload())         
        }
        else{
            firestore.collection(userData.collection).doc(userData.userId).update({
             travel: !userData.travel,
            }).then(()=> window.location.reload())         
        }   
    };

    // const handleSaveCF = () => {     
    //           firestore.collection(userData.collection).doc(userData.userId).update({
    //             convenienceFeeReq: cfReq,
    //             convenienceFee: cf.includes('.') ? parseFloat(cf).toFixed(2) : cf,
    //           }).then(()=> window.location.reload())         
    // };

    const handleCancel = () => {
        setShowAddress(userData.showAddress)
        setShowPhone(userData.showPhone)
        setShowEmail(userData.showEmail)
        setShowDisplay(userData.showDisplay)
        setCF(userData.convenienceFee)
        setIncludeBooking(userData.includeBooking !== undefined ? userData.includeBooking : false)
    }

    const updateSettings = () => {
        firestore.collection(userData.collection).doc(userData.userId).update({
            showAddress: showAddress,
            showDisplay: showDisplay,
            showPhone: showPhone,
            showEmail: showEmail,
            includeBooking: includeBooking,
            convenienceFeeReq: cf === '' ? false : true,
            convenienceFee: cf.includes('.') ? "$"+parseFloat(cf).toFixed(2) : cf,
          }).then(()=> window.location.reload()) 
    }

    useEffect(() => {

        var cleaned = ('' + `${userData.phone}`).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            setPhoneNumber([intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(''));
        }
        // return (null
        // // console.log(appointments.length)
        // )



    }, [appointments])


    return (
        <Grid container item xs={12} style={{minWidth:280, padding:'0px 10px', alignContent:'center'}} direction="column" >

            <Grid item xs={12} md={8} container style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                <Typography className={classes.sectionTitle}>Account</Typography>
            </Grid>
            <Grid item xs={12} md={8} container className={classes.containerBox}>
                {images &&
                <Avatar className={classes.profilePic} src={images.avatar}></Avatar>
                }
                <IconButton onClick={handleEditAcctModal} style={{backgroundColor:'white', position:'absolute', top:140, left:'57%'}}><EditIcon/></IconButton>
                <Typography style={{marginTop: 10, fontSize:18, fontWeight:'bold'}}>{fullname}</Typography>
                {userData.displayName &&
                <Typography style={{fontWeight:'bold'}}>{displayName}</Typography>
                }
                {userData.title &&
                <Typography style={{fontSize:12}}>{title}</Typography>
                }
            </Grid>

            <Grid item xs={12} md={8} container className={classes.containerBox}>
                <Grid item container xs={12} md={10}>
                    <Grid item container xs={12} sm={6}>
                        <Grid item xs={12} style={{testAlign:'left'}}>
                            <Typography style={{fontWeight:'bold'}}>Email:</Typography>
                        </Grid>
                        <Grid item xs={12} style={{testAlign:'left'}}>
                            <Typography variant='caption'>{userData.email}</Typography>
                        </Grid>
                        
                    </Grid>
                    
                    <Grid item container xs={12} sm={6}>
                        <Grid item xs={12} style={{display:'flex', justifyContent:justifyBtn}}>
                            <Button variant='outlined' color='primary' style={{fontWeight:'bold'}} onClick={() => setResetModalOpen(!resetModalOpen)}> Reset Password</Button>
                        </Grid>

                    </Grid>
                    <Grid item container xs={12} sm={6}>
                        <Grid item xs={12} style={{testAlign:'left'}}>
                            <Typography style={{fontWeight:'bold'}}>Mobile Phone:</Typography>
                        </Grid>
                        <Grid item xs={12} style={{testAlign:'left'}}>
                            <Typography variant='caption'>{phoneNumber}</Typography>
                        </Grid>
                    </Grid>
                    
                    <Grid item container xs={12} sm={6}>
                        <Grid item xs={12} style={{display:'flex', justifyContent:justifyBtn}}>
                            <Typography style={{fontWeight:'bold'}}>Account Status:</Typography>
                        </Grid>
                        <Grid item xs={12} style={{display:'flex', justifyContent:justifyBtn}}>
                            <Typography variant='caption'>{userData.accountStatus}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


            {userData.collection === 'professionals' &&
            <Grid item xs={12} md={8} style={{display:'flex', textAlign:'left', justifyContent:"space-between"}}>
                <Typography className={classes.sectionTitleSmall}>Preferences</Typography>
            </Grid>
            }
            
            {userData.collection === 'professionals' &&
            <Grid item xs={12} md={8} container className={classes.containerBox}>
                <Grid item container xs={12} md={10}>
                    <Grid item container xs={12}>

                    {/* <Typography style={{width:'100%', fontWeight:'bold'}}>Booking Fee:</Typography>
                        <Grid item xs={8} style={{testAlign:'left'}}>
                            <Typography variant='caption'>Include booking fee in the price?
                            <Tooltip title="Builds the platform Booking Fee into the service cost. Booking Fee is $5.99 or 6%, whicher greater.">
                                <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                            </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Switch
                                checked={includeBooking}
                                color="primary"
                                onChange={()=> setIncludeBooking(!includeBooking)}
                                name="checkedG"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid> */}
                       <Typography style={{width:'100%', marginTop:20, borderTop:'1px solid lightgrey',fontWeight:'bold'}}>Travel/Convenience Fee:</Typography>
                        <Grid item xs={8} style={{testAlign:'left', borderBottome:'1px solid lightgrey'}}>
                            <Typography variant='caption'>Willing to Travel?</Typography>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end', borderBottome:'1px solid lightgrey'}}>
                            <Switch
                                checked={userData.travel}
                                color="primary"
                                onChange={handleChangeTravel}
                                name="checkedG"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>

                        {userData.travel === true &&
                        <>
                        <Grid item xs={8} style={{testAlign:'left', marginTop:20, borderTop:'1px solid lightgrey'}}>
                            <Typography variant='caption'>I want to charge convenience fee for travel.
                            <Tooltip title="Set a fixed price for traveling to your clent, or get paid for mileage.">
                                <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                            </Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end', marginTop:20, borderTop:'1px solid lightgrey'}}>
                            <Switch
                                checked={cfReq}
                                color="primary"
                                onChange={()=> {setCFReq(!cfReq); setCF('')}}
                                name="checkedG"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>


                        {cfReq === true &&
                        <>
                            <Grid item xs={12} style={{margin:'10px 0px'}}>
                                {userData.convenienceFee !== '' ?
                                <div style={{display:'flex', flexFlow:'row', justifyContent:'space-between'}}>
                                    <Typography variant='caption'>Your Convenience Fee for travel is <strong>{cf}</strong>. Select <strong>Fixed Price</strong> or <strong>Percentage</strong> to change it.</Typography>
                                    {(update === null || update === false) ?
                                        <Button variant='contained' color='primary' onClick={()=>setUpdate(true)}>Update</Button>
                                        :
                                        <Button variant='contained' color='secondary' onClick={()=>{setUpdate(false); setCF(''); setFixedSelected(false); setPercentageSelected(false)}}>Cancel</Button>
                                    }
                                </div>
                                :
                                <div style={{display:'flex', flexFlow:'row', justifyContent:'space-between'}}>
                                    <Typography variant='caption'>You currently earn <strong>$0.75/mile</strong>  as a Travel Fee. Click Update to set <strong>Fixed Price</strong>  or <strong>Percentage</strong>.</Typography>
                                    {(update === null || update === false) ?
                                        <Button variant='contained' color='primary' onClick={()=>setUpdate(true)}>Update</Button>
                                        :
                                        <Button variant='contained' color='secondary' onClick={()=>{setUpdate(false); setCF(''); setFixedSelected(false); setPercentageSelected(false)}}>Cancel</Button>
                                    }
                                    </div>
                                    }
                            </Grid>
                            {update === true &&
                            <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                                <Button variant='outlined' style={{marginRight:5}} onClick={() => {setPercentageSelected(false); setCF(''); setFixedSelected(true)}}>Fixed Amount</Button>
                                <Button variant= 'outlined' onClick={() => {setPercentageSelected(true); setCF(''); setFixedSelected(false)}}>Percentage</Button>
                            
                            
                            </Grid>
                            }
                            <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                                {fixedSelected === true &&
                                <div style={{display:'flex', alignItems:'baseline'}}>
                                    <Typography>$</Typography>
                                    <TextField
                                    style={{width:150}}
                                    label="Dollar Amount"
                                    placeholder='0.00'
                                    value={cf}
                                    onKeyPress={(e) => numsOnly(e)}
                                    onChange={(e)=> {setCF(e.target.value)}}
                                    />
                                </div>
                                }
        
                                {percentageSelected === true &&
                                    <FormControl style={{width:150}}>
                                        <InputLabel id="demo-simple-select-label">Percentage</InputLabel>
                                        <Select
                                        MenuProps={MenuProps}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={cf}
                                        label="Percentage"
                                        onChange={(e)=> {setCF(e.target.value)}}
                                        >
                                        {percentages.map((p, idx)=> {
                                            return(
                                                <MenuItem value={p} key={p}>{p}</MenuItem>
                                            )
                                        })}
                                        </Select>
                                    </FormControl>
                                }
                            </Grid>


                                                   
                        </>
                        }                    
                        
                        {/* {(update === true && cf !=="") &&
                        <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end', marginTop:20}}>
                            <Button variant='contained' color='primary' onClick={handleSaveCF}
                            disabled={(cf ==="" && cfReq===userData.convenienceFeeReq)}
                            >Save
                            </Button>
                        </Grid>
                        } */}

                        </>
                        }

                        <Typography style={{width:'100%', marginTop:20, borderTop:'1px solid lightgrey',fontWeight:'bold'}}>Work Location:</Typography>
                        <Typography style={{width:'100%'}}>{userData.address}</Typography>
                        <Grid item xs={8} style={{testAlign:'left'}}>
                            <Typography variant='caption'>Display Work Location?</Typography>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Switch
                                checked={showAddress}
                                color="primary"
                                onChange={()=> setShowAddress(!showAddress)}
                                name="checkedG"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                        <Typography style={{width:'100%', borderTop:'1px solid lightgrey'}}><span style={{fontWeight:'bold'}}>Display Contact Info:</span></Typography>
                        <Grid item xs={8} style={{testAlign:'left'}}>
                            <Typography variant='caption'>Use Display Name?</Typography>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Switch
                                checked={showDisplay}
                                color="primary"
                                onChange={()=> setShowDisplay(!showDisplay)}
                                name="checkedG"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                        <Grid item xs={8} style={{testAlign:'left'}}>
                            <Typography variant='caption'>Allow Phone Contact?</Typography>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Switch
                                checked={showPhone}
                                color="primary"
                                onChange={()=> setShowPhone(!showPhone)}
                                name="checkedG"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </Grid>
                        <Grid item xs={8} style={{testAlign:'left'}}>

                            <Typography variant='caption'>Allow Email contact?</Typography>
                        </Grid>
                        <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                            <Switch
                                checked={showEmail}
                                color="primary"
                                onChange={()=> setShowEmail(!showEmail)}
                                name="checkedG"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        
                        </Grid>

                        {(showDisplay !== userData.showDisplay || showEmail !== userData.showEmail || showPhone !== userData.showPhone || showAddress !== userData.showAddress || includeBooking !== userData.includeBooking || cf !== userData.convenienceFee) &&
                        <Grid xs={12} style={{display:'flex', justifyContent:'center'}}>
                            <Button variant='contained' color='secondary' style={{marginRight:5}} onClick={handleCancel}>Cancel</Button>
                            <Button variant='contained' color='primary' onClick={updateSettings}>Save</Button>
                        </Grid>
                        }

                    </Grid>          
                </Grid>
            </Grid>
            }

            {/* <Grid item xs={12} md={8} style={{display:'flex', textAlign:'left', justifyContent:"space-between"}}>
                <Typography className={classes.sectionTitleSmall} stlye={{alignSelf:'left'}}>Payment Methods</Typography>
                <IconButton onClick={() => setModalOpen(!modalOpen)}><AddCircleOutlineIcon/></IconButton>
            </Grid>
            <Grid item xs={12} md={8} container className={classes.containerBox}>
                <PaymentMethods userData={userData} modalOpen={modalOpen} setModalOpen={setModalOpen}/>
            </Grid> */}
            
            {userData.collection === 'professionals' &&
            <Grid item xs={12} md={8} style={{display:'flex', textAlign:'left', justifyContent:"space-between"}}>
                <Typography className={classes.sectionTitleSmall}>Referreals</Typography>
            </Grid>
            }
            {/* {userData.collection === 'professionals' &&
            <Grid item xs={12} md={8} container className={classes.containerBox}>
                <Referrals userData={userData} view={referralBtnClicked} setView={setReferralBtnClicked}/>
            </Grid>
            } */}


            <Grid item xs={12} md={8} style={{textAlign:'left'}}>
                {userData.collection === 'professionals' ?
                <Typography className={classes.sectionTitleSmall}>Earnings History</Typography>
                :
                <Typography className={classes.sectionTitleSmall}>Billing History</Typography>     
                 }
            </Grid>
            <Grid item xs={12} md={8} container className={classes.containerBox} style={{minHeight:400,  marginBottom:40}}>
                <History userData={userData} appointments={appointments} />
            </Grid>

            <Modal open={!!modalOpen}>
              
                <Grid container item xs={10} sm={6} md={4} lg={3} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                    <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                        <Typography variant='h6' style={{padding: '10px'}}>
                            Add New Card
                        </Typography>
                        <IconButton onClick={() => setModalOpen(!modalOpen)} style={{color: 'white'}}>
                            <ClearIcon />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12} container style={{padding:20}}>
                        <Cards  number={debitCardNumber} name={name} expiry={debitCardExp} cvc={debitCardCvc} focused={focus}></Cards>


                        <TextField
                            label="Full Name"
                            inputMode="text"
                            //autoComplete="cc-number"
                            //onKeyPress={(e)=>{validateCC(e)}}
                            placeholder={userData.firstName+" "+userData.lastName}
                            name="name"
                            value={name}
                            onChange={(e)=> setName(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            //onFocus={(e) => {setFocus(e.target.name)}}
                            required
                            fullWidth
                        />

                        <TextField
                            label="Debit Card"
                            inputMode="numeric"
                            //autoComplete="cc-number"
                            //onKeyPress={(e)=>{validateCC(e)}}
                            placeholder="Credit/Debit Card Number"
                            name="debitCardNumber"
                            value={debitCardNumber}
                            onChange={(e)=> setDebitCardNumber(e.target.value)}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            //onFocus={(e) => {setFocus(e.target.name)}}
                            required
                            fullWidth
                        />

                        <Grid container item xs={12}>
                            <Grid item xs={6} style={{paddingRight:10}}>
                                <TextField
                                    label="Expiration Date"
                                    type='text'
                                    name="debitCardExp"
                                    //onKeyPress={(e)=>{validateExD(e)}}
                                    placeholder="00/00"
                                    value={debitCardExp}
                                    onChange={(e)=> setDebitCardExp(e.target.value)}
                                // onFocus={(e) => {setFocus(e.target.name)}}
                                    margin="normal"
                                    variant="outlined"
                                    autoComplete="off"
                                    required
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6} style={{paddingLeft:10}}>
                                <TextField
                                    label="Cvc"
                                    type='tel'
                                    name="debitCardCvc"
                                    placeholder="000"
                                    value={debitCardCvc}
                                    //onKeyPress={(e)=>{validateCVC(e)}}
                                    onChange={(e)=> setDebitCardCvc(e.target.value)}
                                    //onFocus={(e) => {setFocus(e.target.name)}}
                                    margin="normal"
                                    variant="outlined"
                                    autoComplete="off"
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                        <Button
                        variant="contained"
                        style={{  
                        letterSpacing: '2px',marginRight: "1rem" }}
                        //onClick={() => navigation.previous()}
                        >
                        Cancel
                        </Button>
                        <Button
                        //disabled={disabled}
                        style={{ 
                        backgroundColor: '#413e68',  
                        letterSpacing: '2px', }}
                        variant="contained"
                        onClick={handleSave}
                        >
                        Save
                        </Button>
                    </div>
                </Grid>

            </Modal>
            
            <EditAccountModal modalOpen={editAcctModalOpen} userData={userData} setModalOpen={setEditAcctModalOpen}/>
            <ConfirmResetPwModal modalOpen={resetModalOpen} userData={userData} setModalOpen={setResetModalOpen} user={user}/>
            {/* <EditBankAccountModal modalOpen={editBankAcctsModalOpen} userData={userData} setModalOpen={setEditBankAcctsModalOpen}/> */}

        </Grid>
    )
}

Account.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

  const percentages = ['5%', '10%', '15%', '20%', '25%', '30%', '35%', '40%', '50%', '60%', '75%', '100%', '125%', '150%', '200%']
  

export default withWidth()(Account)
