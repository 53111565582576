import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Grid, Button, FormControl, InputLabel, Select, Typography, TextField, Tooltip, IconButton, Hidden, withWidth, Modal} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { DataGrid } from '@material-ui/data-grid';
import { firestore } from '../../../config/fbConfig';
import firebase from 'firebase';
var badWords = require('badwords-list')


const useStyles = makeStyles((theme) => ({
    input: {
        flex:'1 0 auto',
        paddingLeft:'10px'
      },
      icon: {
        flex:'0 1 40px',
        // color: '#413e68',
        cursor:'pointer',
      },
      inputField: {
        border:'2px solid lightgray',
        borderRadius: 20,
        height: 40 ,
        flex:'1 1 auto',
        paddingTop: 2,
        margin: "10px 5px 5px",
        display:'flex',
        alignItems:'center',
        boxShadow: 'none',
      },
      selectedServiceDetails:{
        padding: '0px 30px',
        marginTop: 20,
      },
  }));

const AppointmentsAndServices = ({currentUser, userData, width}) => {
    const classes = useStyles()
    const [rows, setRows] = useState([])
    const [userType, setUserType] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingUser, setLoadingUser] = useState(false)
    const [category, setCategory] = useState('')
    const [serviceName, setServiceName] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [addModalOpen, setAddModalOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const path = window.location.pathname.split('/')
    const pathName = path[2]
    const columns = (pathName === 'appointments') ? [
        { field: 'service', headerName: 'Service', width: 300 },
        { field: 'proName', headerName: ' Pro', width: 200 },
        { field: 'clientName', headerName: 'Client', width: 200 },
        { field: 'serviceComplete', headerName: 'Complete', width: 160 },
        { field: 'serviceCanceled', headerName: 'Canceled', width: 160 },
        { field: 'serviceTimeReq', headerName: 'Date Requested', width: 220 },
        { field: 'id', headerName: 'Appt Id', width: 300 },
    ]
    :
    [
        { field: 'id', headerName: 'Name', width: 120 },
        { field: 'type', headerName: ' Type', width: 145 },
        // { field: 'rating', headerName: 'Rating', width: 130 },
        // { field: 'marks', headerName: 'Marks', width: 130 },
        // { field: 'status', headerName: 'Status', width: 130 },
        // { field: 'id', headerName: 'User Id', width: 300 },
    ];
    
    const categories = ["Brow & Lashes", "Facials & Skincare", "Hair", "Health & Wellness", "Home & Car Care", "Makeup", "Massage", "Nails", "Pet Care", "Tattoo & Piercing", "Waxing Services"]
    const disabled = (serviceName==='' || category==='') ? true : false
    const array = badWords.array
    const padding = (width === 'xs' || width === 'sm') ? '0px 14px 20px 0px' : '0'
    const justify1 = (width === 'xs' || width === 'sm') ? 'flex-end' : 'space-between'
    const justify2 = (width === 'xs' || width === 'sm') ? 'flex-end' : null
    const headerSize = (width === 'xs' || width === 'sm') ? 'h5' : 'h3'


    useEffect(() => {

       if(userData.collection === 'admins' && pathName === 'appointments'){
           setUserType('Appt')
           firestore.collection('appointments').limit(300).get().then((querySnapshot) => {
            setRows(querySnapshot.docs.map(doc => {
                const appt = {id:doc.id, data:doc.data()}
                const {id, data} = appt
                return({proName: data.proName, clientName: data.clientName, service: data.service, serviceComplete: data.serviceComplete, serviceCanceled: data.serviceCanceled,serviceTimeReq: new Date(data.serviceTimeReq.seconds*1000).toLocaleDateString() + " @ " + new Date(data.serviceTimeReq.seconds*1000).toLocaleTimeString() , id: id})
                }))
            })      
        }else if(userData.collection === 'admins' && pathName === 'services'){
            setUserType('Service')
            firestore.collection('services').doc('allServices').get().then((doc) => {
                const data = doc.data()

                setRows(data.listOfServices.map(service => {
                    return({id: service.name, type: service.typeOfService})
                }))
            })  
            // console.log(pathName)              
       }else{
        window.location.assign('/')
       }

       if(selected && loadingUser){
           setLoadingUser(false)
           setModalOpen(true)
       }

       if(serviceName !== ''){
           array.map(badWord => {
               if(serviceName === badWord){
                //    alert('No profanity is allowed!')
                   // console.log('contains bad profanity')
                   setServiceName('')
                   
               }
           })
       }

    }, [userData, pathName, selected, loadingUser, serviceName])

    const handleOpenModal = (id) => {
        setLoadingUser(true)
        switch (pathName) {
            case "appointments":
                firestore.collection('appointments').doc(id)
                    .get().then((doc) => {
                        if (doc.exists) {
                            setSelected(doc.data())
                        } else {
                            // console.log("No such document!");
                        }
                    }).catch((error) => {
                        // console.log("Error getting document:", error);
                    });
                break;
            case "services":
                firestore.collection('services').doc('allServices')
                    .get().then((doc) => {
                        const data = doc.data()
                        if (doc.exists) {
                            setSelected(data.listOfServices.filter(serv => serv.name===id))
                        } else {
                            // console.log("No such document!");
                        }
                    }).catch((error) => {
                        // console.log("Error getting document:", error);
                    });
                break;
            
            default:
                break;
        }
        
    }

    const handleClose = () => {
        setModalOpen(!modalOpen)
        setSelected(null)
        setCategory('');
        setServiceName('')
    }
    const handleAdd = () => {
        setAddModalOpen(!addModalOpen)
    }

    const handleSave = () => {
        rows.filter(service => {
            if(serviceName.toLowerCase() === service.id.toLowerCase()){
                // console.log('This service already exsists')
                setServiceName('')
                
            }else if((serviceName.toLowerCase() !== service.id.toLowerCase())){
                firestore.collection("services").doc("allServices").update({
                    listOfServices: firebase.firestore.FieldValue.arrayUnion({name: serviceName, typeOfService: category, })
                  }) 
                .then(() => {
                  // console.log("Documents successfully updated!");
                })
                .then(() => {
                    // setCategory('');
                    setServiceName('')
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                });
            }
        })

      }


    return (
        <Grid item container xs={12} style={{height:'95vh', display:'flex', flexFlow:'column'}}>
            <Grid item container xs={12} style={{flex:'0 1 100px', display:'flex', justifyContent: justify1, alignItems:'center', padding:'0px 20px'}}>
                <Typography variant={headerSize} style={{fontWeight:'bold', marginRight: 10}}>Users > <span>{userType}s</span></Typography>
                <Button onClick={handleAdd} variant="contained" color="primary" style={{height:'40px'}}>Add {userType}</Button>
            </Grid>
            <Grid container item xs={12} style={{flex:'1 0 auto', display:'flex', alignItems:'center', padding:padding, justifyContent:justify2}}>
                <Grid container item xs={10} md={12} style={{padding:20, backgroundColor:'#e6e6e6', borderRadius:12, height:'100%'}}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        // checkboxSelection
                        onRowDoubleClick={(row) => handleOpenModal(row.id)}
                    />
                </Grid>
            </Grid>

            <Modal open={modalOpen} onClose={handleClose}>
                <Grid style={{height:300, width:300, backgroundColor:'white'}}>
                    {selected &&
                    <Typography>{selected.email}</Typography>
                    }
                </Grid>
            </Modal>



            <Modal open={!!addModalOpen} onClose={handleAdd}>
              
              <Grid container xs={10} sm={6} md={4} lg={3} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Add New Service
                    </Typography>
                    <IconButton onClick={handleAdd} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={12} container>
                  
                <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={5}>
                      <Typography>Category:</Typography>
                    </Grid>

                    <Grid item container xs={7} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center'}}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="select">Select Category</InputLabel>
                            <Select
                            native
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            label="Select Category"
                            inputProps={{
                                name: 'category',
                                id: 'select',
                            }}
                            >
                            <option aria-label="None" value="Select Category" />
                            {categories.map(category => {
                                return(
                                    <option value={category}>{category}</option>

                                )
                            })}
                            </Select>
                        </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={12} md={5}>
                      <Typography>Name of Service:</Typography>
                    </Grid>

                    <Grid item container xs={12} md={7} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center'}}>
                          <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={serviceName}
                            placeholder='(e.g. Manicure)'
                            // onKeyPress={restrictProfanity}
                            onChange={(e) => {setServiceName(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            
                          />
                    </Grid>
                  </Grid>


                  <Grid item xs={12} container className={classes.selectedServiceDetails} style={{marginBottom: 40}}>
                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" fullWidth onClick={handleAdd}>Cancel</Button>
                    </Grid>

                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" color="primary" fullWidth disabled={disabled} onClick={handleSave}>Save</Button>
                    </Grid>
                  </Grid>
      
      
                  {/* <Button variant="contained" color="primary" size="small" className={classes.button}>
                      ADD
                  </Button> */}
                </Grid>

              </Grid>
            </Modal>
            
        </Grid>
    )
}

AppointmentsAndServices.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(AppointmentsAndServices)
