import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Typography, Grid, FormControl, InputLabel, Select, MenuItem,
        Hidden, withWidth, Tooltip, Paper, InputBase, CircularProgress, Avatar,
  ClickAwayListener, AppBar, Tabs, Tab, Box} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
//import SendIcon from '@material-ui/icons/Send';
import firebase, {firestore} from '../../config/fbConfig';

import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";

import {useSelector} from 'react-redux';
import FavoriteProCard from './favorites/FavoriteProCard';
import { DockSharp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1000,
    border: "1px solid #e0e0e0",
    borderRadius: 12,
    position: 'relative',
    marginTop:'20px',
    //height:510,
    minHeight: 360,
    boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)',
    textAlign: 'left',
    display: 'flex',
    flexFlow:'column',
  },
  rootTitle:{
    borderRadius: '8px 8px 0 0',
    background: '#413e68',
    flex:'0 1 50px',
  },
  conversationContainer: {
    height:'inherit',
    borderRight:'1px solid lightgrey',
    display:'flex',
    flexFlow:'column',
  },
  conversationHeader: {
    height:'50px',
    borderBottom:'1px solid lightgrey',
    padding:'0px 5px',
    flex:'0 1 50px',
    display:'flex',
    justifyContent: 'space-between',
    // height:'inherit',
    alignItems:'center'
  },
  navBtn: {
    padding: "0",
    height: "100%",
    color: 'white',
  },
  container: {
    display:'flex',
    justifyContent:'flex-end',
    margin:'auto',
    paddingRight:12,
  },
  grid: {
    display:'flex',
    justifyContent:'center',
  },
  input: {
    flex:'1 0 auto',
    paddingLeft:'10px'
  },
  icon: {
    flex:'0 1 40px',
    // color: '#413e68',
    cursor:'pointer',
  },
  inputField: {
    border:'2px solid lightgray',
    borderRadius: 20,
    height: 40 ,
    width: 'inherit',
    paddingTop: 2,
    display:'flex',
    alignItems:'center',
    boxShadow: 'none',
  },

}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function Favorites({userData, width, amount}) {
  const {favorites} = userData
  const [faves, setFaves] = useState([])
  const [loading2, setLoading2] = useState(true)
  const [loading1, setLoading1] = useState(true)
  const classes = useStyles();
  const categories = ["All", "Brow & Lashes", "Facials & Skincare", "Hair", "Health & Wellness","Home & Car Care", "Makeup", "Massage", "Nails","Pet Care", "Tattoo & Piercing", "Waxing Services", 'Other']
  const [value, setValue] = React.useState(categories[0]);
  //const [servicePros, setServicePros] = React.useState([])

  const containerHeight = (width === 'xs' || width === 'sm') ? '80vh' : 510
  const marginTop = (width === 'xs' || width === 'sm') ? -40 : 20
  const scrollHeight = (width === 'xs' || width === 'sm') ? '500px' : '400px'


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    
    if(amount > 0){
      setLoading1(false)
    }

    if(loading1 === false && userData.email !== undefined){
      if(favorites.length > 0 && loading2 === true){
        setLoading2(false)
        for (let i = 0; i < favorites.length; i++){
            firestore.collection('professionals').where('email', '==', favorites[i])
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  setFaves(prev => [...prev, doc.data()]);
              });
          });
  
        }
       
  
      }
    }

    console.log(faves)

  }, [amount, value, userData, loading1, faves])

 
  

  return (
    <div style={{padding:'0px 10px', width:'100%', display:'flex', justifyContent:'center'}}>
    {loading1===true ?
    <div>Loading</div>
    :
    <Grid container item xs={12} className={classes.root} style={{height: containerHeight}}>
    <Grid item xs={12} className={classes.rootTitle}>
      <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Favorites</Typography>
    </Grid>

    {/* <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end', padding:'15px 15px 2px 15px', flex:'0 1 50px',}}>
      <Grid item xs={8} sm={6} md={4} lg={3}>
        <Paper component='form'
          className={classes.inputField}>    
            <InputBase
              name='search'
              //value={}
              //onChange={}
              placeholder="Search..."
              className={classes.input}
              //autoFocus
              />
              <SearchIcon className={classes.icon} style={{color:'lightgrey'}}/>
        </Paper>
      </Grid>
    </Grid> */}


    <Grid container item xs={12} style={{display:'flex', flex:'0 1 auto'}}>
      {(width === 'xs' || width === 'sm') ? 
      <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end', padding:'15px 15px 2px 15px'}}>
        <FormControl variant="outlined" style={{width:'50%'}}>
          <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={value}
            onChange={handleChange2}
            label="Filter"
          >
            {categories.map((category) => {
              return(
                <MenuItem key={category} value={category}>{category}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
        :
      <AppBar position="static" color="default" style={{boxShadow: 'none', background:'none'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {categories.map((category,idx) => {
            return(
              <Tab key={category} label={category} value={category} {...a11yProps(idx)}/>
            )
          })}
        </Tabs>
      </AppBar>
      }
    </Grid>

    <SimpleBarReact autoHide={false} style={{maxHeight: scrollHeight, width: 'inherit', padding:'0px 15px 5px 5px'}}>
      <Grid container item xs={12} style={{display:'flex', flex:'1 0 auto', padding:'20px 0px', justifyContent:'space-evenly'}}>
            <Grid container item xs={12} style={{height:'fit-content', padding:'0px 5px'}}>
                  {faves.length > 0 &&  faves.map(pro => {
                    if(value==='All'){
                      return(
                        <Grid item xs={12} md={6} key={pro.email} style={{padding: 5}}>
                          <FavoriteProCard pro={pro}/>
                        </Grid>)
                    }else if(pro.services.filter(service => service.serviceType.toLowerCase() === value.toLowerCase()).length >= 1){
                      // console.log(pro.firstName)
                      return(
                        <Grid item xs={12} md={6} key={pro.email} style={{padding: 5}}>
                          <FavoriteProCard pro={pro}/>
                        </Grid>)
                    }else{
                      return null
                    }
                  })
                }
              
            </Grid>
      </Grid>
    </SimpleBarReact>  

    
  </Grid>
    
    }
    </div>
  )
}

Favorites.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Favorites)