import React, {useEffect} from 'react';
import {Container, Grid, Typography, CircularProgress, withWidth, Hidden, Button} from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';
import AboutUs from './InformationPages/AboutUs';
import Privacy from './InformationPages/Privacy';
import Terms from './InformationPages/Terms';
import FAQs from './InformationPages/FAQs';
import  Contact from './InformationPages/Contact';
import Footer from './Footer'
import ProOnboarding from './InformationPages/ProOnboarding';

const useStyles = makeStyles((theme) => ({
    homeRoot:{display:"flex",
      justifyContent:"center",
      padding: "0",
      overflowY: "hidden scroll",
      borderTop: 'solid 1px #d3d3d3',
    },
    paper: {
      margin: '10% auto',
      width: '80%',
      height: '70%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: '8px',
    },
    dashSectionContainer:{
      height: '45%',
      padding: 10,
    },
    dashSection:{
      height: '100%',
      border:'1px solid lightgray',
      borderRadius:12,
      padding: 10,
      textAlign:'center',
      display:'flex',
      //alignItems:'center',
    },
    section:{
      borderRadius: 12,
      border:'1px solid lightgray',
      padding:10,
      marginBottom: 10,
      textDecoration:'none',
      color: 'black',
      "&:hover":{
        cursor:'pointer',
        border:`2px solid ${theme.palette.primary.main}`,
        backgroundColor:'#e6e6e6',
      },
    },
    link: {
      textDecoration:'none',
      color:'black',
      fontSize:24,
      '&:hover':{
          cursor:'pointer',
          color: theme.palette.primary.main
      }
    },
  }));

function InformationPages() {
    const classes = useStyles()
    const path = window.location.pathname.split('/')
    const pathName = path[2]


    useEffect(() => {
        // console.log(pathName)
        window.scrollTo(0, 0)
    }, [pathName])

    return (
        <Grid item container xs={12} style={{display:'flex', justifyContent:'center', backgroundColor:'white'}}>
            <Grid item container xs={10} md={2} style={{height:'fit-content', margin:20, background:'white', position:'sticky', top:'0px', zIndex:1}}>
                <Grid item containter xs={4} md={12} style={{height: 60, display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid lightgray'}}>
                    <Typography component={Link} to='/ip/about' className={classes.link}>About</Typography>
                </Grid>
                <Grid item containter xs={4} md={12} style={{height: 60, display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid lightgray'}}>
                    <Typography component={Link} to='/ip/terms' className={classes.link}>Terms</Typography>
                </Grid>
                <Grid item containter xs={4} md={12} style={{height: 60, display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid lightgray'}}>
                    <Typography component={Link} to='/ip/privacy' className={classes.link}>Privacy</Typography>
                </Grid>
                <Grid item containter xs={4} md={12} style={{height: 60, display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid lightgray'}}>
                    <Typography component={Link} to='/ip/faqs' className={classes.link}>FAQs</Typography>
                </Grid>
                <Grid item containter xs={4} md={12} style={{height: 60, display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid lightgray'}}>
                    <Typography component={Link} to='/ip/careers' className={classes.link}>Careers</Typography>
                </Grid>
                <Grid item containter xs={4} md={12} style={{height: 60, display:'flex', alignItems:'center', justifyContent:'center', borderBottom:'1px solid lightgray'}}>
                    <Typography component={Link} to='/ip/contact' className={classes.link}>Contact</Typography>
                </Grid>

            </Grid>

            <Grid item container xs={10} md={8} style={{borderLeft:'1px solid lightgray', borderRight:'1px solid lightgray'}}>
                {pathName === 'about' && <AboutUs/>}
                {pathName === 'terms' &&  <Terms/>}
                {pathName === 'privacy' &&  <Privacy/>}
                {pathName === 'faqs' &&  <FAQs/>}
                {pathName === 'careers' && <Typography>Careers</Typography>}
                {pathName === 'contact' &&  <Contact/>}
                {pathName === 'onboarding' && <ProOnboarding/>}
            </Grid>

            <Footer/>
            
        </Grid>
    )
}

export default InformationPages
