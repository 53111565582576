import React, {useEffect, useState} from 'react'
import {Container, Grid, Typography, Button, Avatar, Tooltip, CircularProgress, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios'
import {firebase, firestore} from '../../config/fbConfig'
import { setAppointments } from '../../auth/appointmentActions';
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import AppointmentInfoModal from '../modals/AppointmentInfoModal';
import moment, { weekdays } from 'moment';

const useStyles = makeStyles((theme) => ({
    link: {
      fontSize: 12,
      color:theme.palette.primary.main,
      '&:hover':{
        color: theme.palette.primary.light,
        cursor:'pointer',
        },
      },
    appt: {
      '&:hover':{
        color: theme.palette.primary.main,
        cursor:'pointer',
        },
      },
      btn:{
        color: 'white',
        fontWeight:'bold',
        backgroundColor: theme.palette.primary.main,
        height: 'fit-content',
        padding: 10
    },
    wrapper: {
      height: '100%',
      width: 'fit-content',
      position: 'relative',
    },
  buttonDisabled: {
      backgroundColor: theme.palette.primary.secondary,
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    }
      ));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
      


function Earnings(props) {
  const classes = useStyles()
  const {userData, currentUser} = props
  const [loading, setLoading] = useState(null)
  const [appointments, setAppointments] = useState(null)
  const [selectedAppt, setSelectedAppt] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [availableBalance, setBalance] = useState(null)
  const [expectedBalance, setExpectedBalance] = useState(null)
  const [pendingBalance, setPendingBalance] = useState(null)
  const [stripePayoutType, setPayoutType] = useState(null)
  const [completed, setCompleted] = useState(null)
  const [upcoming, setUpcoming] = useState(null)
  const [viewUpcoming, setViewUpcoming] = useState(false)
  const [apptsLoading, setApptsLoading] = useState(false)
  const thisSunday = moment().startOf('week').toDate()
  const [weekDates, setWeekDates] = useState([])
  const [currWeekAppointments, setCurrWeekAppointments] = useState(null)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openErr, setOpenErr] = useState(false)
  const severity = ["error", "success"]


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenErr(false);
    setBalance(null)
  };

  const handleDash = async (e) => {
    e.preventDefault()
        try {
            const response = (userData && userData.test===false) ? await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/dashboard', {
                id: userData.stripeId
            })
            :
            await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/dashboard-test', {
                id: userData.stripeId
            })

            if(response.data.success){
                window.open(response.data.response.url)
            }else{
              console.log("Failure Link retrieval")
            }      
        } catch (error) {
            console.log("Error:", error)
        }
    
}

const handleWithdraw = async () => {
  const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/withdraw', {
      amount: availableBalance * 100,
      stripeId: userData.stripeId
  })

  if(response.data.success){
      // console.log(response.data)
      setOpenSuccess(true)
  }else{
      setOpenSuccess(true)
      // console.log('something went wrong:', response.data)
  }

  
}
  
  useEffect(() => {

    var week= []; 
    // Starting Sunday
    if(weekDates.length <= 0 && currWeekAppointments === null){
      // setLoading(true)
        setApptsLoading(true)
        thisSunday.setDate((thisSunday.getDate() - thisSunday.getDay()));
        for (var i = 0; i < 8; i++) {
            week.push(
                new Date(thisSunday)
            ); 
            thisSunday.setDate(thisSunday.getDate() +1);
            setWeekDates(week);
            setCurrWeekAppointments([])
        }
    }

    if((weekDates.length === 8) && appointments!==null && currWeekAppointments!==null && apptsLoading===true){
      //console.log('2')
      const completedAppts = appointments.filter(x => x.docData.serviceComplete === true)
    //  console.log('completed appts', completedAppts)
      setCurrWeekAppointments(completedAppts.filter(x => (new Date(x.docData.serviceTimeEnd.seconds*1000) >= new Date(weekDates[0])) && (new Date(x.docData.serviceTimeEnd.seconds*1000) < new Date(weekDates[7]))))
      setApptsLoading(false)
  }



    const getBalance = async () => {
      try {
          const response = (userData && userData.test ===false) ? await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/get-balance', {
              params:  {stripeId: userData.stripeId}
          })
          :
          await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/get-balance-test', {
              params:  {stripeId: userData.stripeId}
          })
          if(response.data.success){
              // console.log(response.data)
              setBalance(response.data.balance.instant_available[0].amount / 100)
              setExpectedBalance((response.data.balance.available[0].amount + response.data.balance.instant_available[0].amount) / 100)
              setPendingBalance(response.data.balance.pending[0].amount / 100)
          }else{
            console.log("Failure Balance retrieval")
            setBalance(0)
          }      
      } catch (error) {
          console.log("This is the Error:", error)
      }
  }


  if(availableBalance === null && pendingBalance === null){
      getBalance()
  }
    
    let results = null
    if(userData.firstName && appointments===null){
      if(results === null){
        firestore.collection('appointments').where('proUID', '==', `${userData.userId}`)
        .onSnapshot((snapshot) => { 
          setAppointments(snapshot.docs.map(doc => {return({id:doc.id, docData:doc.data()})})
          )
        })
        setLoading(true)
      }

    }

    if(appointments!== null && appointments.length > 0 && loading===true){
      
      setCompleted(appointments.filter((appt) => appt.docData.serviceComplete === true && appt.docData.amtRemainingBalance === '0'))
      setUpcoming(appointments.filter((appt) => appt.docData.serviceComplete === false && appt.docData.serviceCanceled === false && appt.docData.amtRemainingBalance !== '0'))
      setLoading(false)
    }

    const getPayoutType = async () => {
      try {
          const response = (userData && userData.test === false) ? await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/retrieve', {
              params:  {acctId: userData.stripeId}
          })
          :
          await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/retrieve-test', {
              params:  {acctId: userData.stripeId}
          })
          if(response.data.success){
              // console.log(response.data)
              setPayoutType(response.data.account.external_accounts.data[0].object)
          }else{
          //   console.log("Failure Balance retrieval")
              setBalance(0)
          }   
      } catch (error){
          // console.log('error getting account:', error)
      }
  }

    if(stripePayoutType === null){
      getPayoutType()
  }

    console.log("Payout Type: ", stripePayoutType)
    // console.log("his Sunday: ", thisSunday)
    // console.log("This Week: ", weekDates)
    // console.log("currWeekAppts: ", currWeekAppointments)
    
  }, [userData, appointments, loading, upcoming, completed, availableBalance, pendingBalance, currWeekAppointments, thisSunday, weekDates, apptsLoading, stripePayoutType])

    return (
        <Container style={{padding: "0", overflowY: "hidden scroll", height: "95vh" }}>
          <Grid container item xs={12} direction="column" style={{padding:'0px 10px'}}>
            <Grid items container xs={12} style={{padding:'20px 0px'}}>
              <Typography variant="h4" color="initial" style={{fontWeight:"bold"}}>Earnings</Typography>
            </Grid>

            <Grid items container xs={12} style={{padding:15, backgroundColor:'#F2F2F2', height:30, borderRadius:12}}>
              <Grid item container xs={12} md={6}>
                <Grid style={{display:'flex', justifyContent:'center', alignItems:'center', flex:'0 1 50px'}}>
                  {userData.images && <Avatar src={userData.images.avatar}/>}
                </Grid>
                <Grid style={{display:'flex', flexFlow:'column', justifyContent:'center', flex:'1 0 auto'}}>
                  <Typography color="initial" style={{fontSize:'10px'}}>{userData.title} since {userData.createdAt ? <span>{new Date (userData.createdAt.seconds*1000).toLocaleDateString()}</span> : <span>{new Date().toLocaleDateString()}</span>}</Typography>
                  <Typography color="initial" style={{fontSize:20}}>{userData.firstName + " " + userData.lastName}</Typography>
                  <Typography color="initial" className={classes.link} style={{width:'fit-content', fontWeight:'bold'}} onClick={handleDash}>View Stripe Dashboard</Typography>
                  
                </Grid>
              </Grid>
              
              <Grid item container xs={12} md={6} style={{display:'flex', justifyContent:'flex-end'}}>
                <Grid item container xs={6} style={{display:'flex', flexFlow:'column', justifyContent:'center'}}>
                  <Typography color="initial" style={{fontSize:'10px'}}>Pending</Typography>
                  {pendingBalance !== null ?
                  <Typography color="initial" style={{fontSize:20, fontWeight:'bold'}}>${pendingBalance.toFixed(2)}</Typography>
                  :
                  <Typography color="initial" style={{fontSize:14, fontWeight:'bold'}}>loading...</Typography>
                  }
                  {currWeekAppointments !== null &&
                    <Typography color="initial" style={{fontSize:12, color:'gray'}}>{currWeekAppointments.length} {currWeekAppointments.length === 1 ? 'Appointment' : 'Appointments'}</Typography>
                  }
                </Grid>
                  
                <Grid item container xs={6} style={{display:'flex', flexFlow:'column', justifyContent:'center',}}>
                  <Typography color="initial" style={{fontSize:'10px'}}>Payout Balance</Typography>
                  {availableBalance !== null ?
                  <Typography color="initial" style={{fontSize:20, fontWeight:'bold'}}>${availableBalance.toFixed(2)}</Typography>
                  :
                  <Typography color="initial" style={{fontSize:14, fontWeight:'bold'}}>loading...</Typography>
                  }
                  {expectedBalance !==null ?
                    <Typography color="initial" style={{fontSize:12, color:'gray'}}>${expectedBalance.toFixed(2)} Expected Payout 
                      <Tooltip title="Paid out daily. You can expect funds within 2-3 business days."><InfoIcon style={{fontSize:16}}/></Tooltip>
                    </Typography>
                    :
                    <Typography color="initial" style={{fontSize:10, color:'gray'}}>Loading Expected Payout... 
                      <Tooltip title="Paid out daily. You can expect funds within 2-3 business days."><InfoIcon style={{fontSize:16}}/></Tooltip>
                    </Typography>
                  }
                </Grid>

                <Grid item container xs={12} style={{borderTop:'1px solid grey', borderBottom:'1px solid grey', padding:10}}>
                  
                  <Typography style={{fontSize:'10px', textAlign:'center', width:'100%', fontWeight:'bold'}}>Automatic payouts initiated every Monday</Typography>
                  <Typography style={{fontSize:'10px', textAlign:'center', width:'100%'}}>Weekly payout may take 2-3 business days to appear in your bank account</Typography>
                </Grid>

              </Grid>

              <Grid item container xs={12} style={{marginTop:10, display:'flex', justifyContent:'flex-end'}}>
                {stripePayoutType !== null ?
                  stripePayoutType === 'bank_account' ?
                  <Grid item container xs={12} md={6}>
                    <Grid item container xs={7}>
                      <Typography style={{fontSize:'10px', width:'100%', fontWeight:'bold'}}>Want to get paid out faster?</Typography>
                      <Typography style={{fontSize:'10px', width:'100%'}}>Tap to learn how to become eligble for Instant Pay</Typography>
                    </Grid>
                    <Grid item container xs={5}>
                      <Button fullWidth className={classes.btn}>More Info</Button>
                    </Grid>
                  </Grid>    
                  :
                  <Grid item container xs={12} md={5}>
                    <Button fullWidth className={classes.btn} onClick={handleWithdraw} disabled={availableBalance === 0 || availableBalance === null}>Cash Out ${availableBalance}</Button> 
                  </Grid>
                  :
                  <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonDisabled}
                        fullWidth
                        disabled
                      >
                        Loading..
                      </Button>
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    </div> 
                  }

              </Grid>
    
            </Grid>

            <Grid items container xs={12} style={{padding:'20px 0px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
              <Typography color="initial" style={{fontWeight:"bold"}}>Recent Appointments</Typography>
              {viewUpcoming === false ?
                <Button variant='contained' color='primary' onClick={()=>setViewUpcoming(!viewUpcoming)}>View Upcoming</Button>
                :
                <Button variant='contained' color='primary' onClick={()=>setViewUpcoming(!viewUpcoming)}>View Recent</Button>
              }
            </Grid>

            {appointments && appointments.length === 0 ?
              <Grid items container xs={12} style={{padding:'20px 0px', marginBottom:60, backgroundColor:'#F2F2F2', minHeight:300, borderRadius:12, display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
                <Avatar style={{height:'fit-content', width:'fit-content', padding:10, backgroundColor:'lightgray'}}><img src="https://img.icons8.com/ios-filled/100/F2F2F2/person-in-a-mirror.png" alt=''/></Avatar>
                <Typography color="initial" >No Appointments to display yet</Typography>
                <Typography color="initial" style={{color:'gray', fontSize:'12px'}}>Go out there and grab some clients!</Typography>

              </Grid>
            :
              <Grid items container xs={12} style={{padding:'20px', marginBottom:60, backgroundColor:'#F2F2F2', minHeight:300, borderRadius:12, display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
                  
                  {(completed && viewUpcoming === false && completed.length > 0) && completed.sort((a,b) => new Date(b.docData.serviceTimeEnd.seconds*1000) > new Date(a.docData.serviceTimeEnd.seconds*1000) && 1 || -1).map((appt, idx) => {
                    const {id, docData} = appt
                    return (
                      <Grid item container xs={12} onClick={()=> {setSelectedAppt(appt); setModalOpen(!modalOpen)}} className={classes.appt} style={{display:'flex', flexFlow:'row', borderBottom:'1px solid lightgray', padding:'10px 0px'}}>
                        <Grid item container xs={4} md={2}>
                          <Typography>{new Date (docData.serviceTimeEnd.seconds*1000).toLocaleDateString({month: 'numeric', day: 'numeric'})}</Typography>
                        </Grid>

                        <Grid item container xs={5} md={7}>
                          <Typography>{docData.service}</Typography>
                        </Grid>
                        
                        <Grid item container xs={3} md={3} style={{display:'flex', justifyContent:'flex-end'}}>
                          <Typography>${(parseFloat(docData.serviceFee) + parseFloat(docData.serviceFeeTravel)).toFixed(2)}</Typography>
                        </Grid>
                          
                      </Grid>
                    )
                  })}

                  {(upcoming && viewUpcoming === true && upcoming.length > 0) && upcoming.sort((a,b) => new Date(b.docData.serviceTimeEnd.seconds*1000) > new Date(a.docData.serviceTimeEnd.seconds*1000) && 1 || -1).map((appt, idx) => {
                    const {id, docData} = appt
                    return(
                      <Grid item container xs={12} style={{display:'flex', flexFlow:'row', borderBottom:'1px solid lightgray', padding:'10px 0px'}}>
                        <Grid item container xs={4} md={2}>
                          <Typography>{new Date (docData.serviceTimeReq.seconds*1000).toLocaleDateString()}</Typography>
                        </Grid>

                        <Grid item container xs={4} md={7}>
                          <Typography>{docData.service}</Typography>
                        </Grid>
                        
                        <Grid item container xs={4} md={3} style={{display:'flex', justifyContent:'flex-end'}}>
                          <Typography>${(parseFloat(docData.serviceFee) + parseFloat(docData.serviceFeeTravel)).toFixed(2)}</Typography>
                        </Grid>   
                      </Grid>
                    )
                  })}

              </Grid>
           
            }

            <AppointmentInfoModal modalOpen={modalOpen} setModalOpen={setModalOpen} selectedAppt={selectedAppt} setSelectedAppt={setSelectedAppt} userData={userData}/>


            {availableBalance !== null &&
            <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[1]}>
                    ${availableBalance} has successfull been transfered! 💸
                </Alert>
            </Snackbar>
            }
            <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[0]}>
                Oops, something went wrong...
                </Alert>
            </Snackbar>
          </Grid>
        </Container>
    )
}

export default Earnings
