import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Grid, Button, IconButton, Hidden, withWidth, Tooltip, Paper, InputBase, TextField,
  ClickAwayListener, Menu, Typography} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {auth, firestore} from '../../config/fbConfig'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import firebase from 'firebase'
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NavSearch from '../modals/NavSearch';
import { intlFormat } from 'date-fns';

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
      height:50,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        //borderColor: 'white',
        //height: 40,
        height:50,
        borderRadius: 40,
        border:'2px solid gray',
      },
      '&:hover fieldset': {
        borderColor: 'gray',
        height:50,
      },
      '&.Mui-focused fieldset': {
        //borderColor: 'yellow',
        height:50,
      },
    },
  },
})(TextField)

const useStyles = makeStyles(theme => ({
    navBtn: {
      padding: "0",
      height: "100%",
      color: 'white',
    },
    container: {
      display:'flex',
      justifyContent:'flex-end',
      margin:'auto',
      paddingRight:12,
    },
    grid: {
      display:'flex',
      justifyContent:'center',
    },
    input: {
      width: '85%',
      paddingLeft:'10px'
    },
    searchIcon: {
      verticalAlign: 'middle',
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      border:'1px solid gray',
      height: 76,
      marginBottom: 5,
      background: 'white',
      
      },
      vpBtn:{
        margin:'auto',
        textAlign:'center',
        '&:hover': {
          cursor: 'pointer',
        },
      },


  }));


const SignedOutLinks = (props) => {
  const classes = useStyles();
  const history = useHistory()
  const {width, userData} = props;
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [services, setServices] = useState([])
  const [isExpanded, setIsExpanded] = useState(false)
  const [value, setValue] = useState('')
  const searchWidth = !!isExpanded ? "80%" : 40

  const options = services && services.sort((a,b) => a.name > b.name && 1 || -1 );


  useEffect(() => {
   
    if(userData !== null && userData !== {} && userData !== undefined && loading === true){
      firestore.collection('services').doc('allServices').get()
      .then((doc) => {
        setServices(doc.data().listOfServices)
      }).then(
        setLoading(false)
      )
    }

    if(value !== ''){
      setOpen(true)
    }

  }, [loading, userData, value, open, isExpanded])


  const handleExpand = () => {
    if(isExpanded === false){
      setIsExpanded(true)

    } else {
      setIsExpanded(false)
    }
  }

  const handleSignOut = () => {
    if(userData.collection === 'professionals'){
      if(userData.online){
        // console.log('Pro sign out')
        firestore.collection(userData.collection).doc(userData.userId).update({
          online: false
        }).then(() => {
          auth.signOut()
        }).then(() => {
          history.push('/signin')
        })
      }else{
        // console.log('Pro sign out')
        auth.signOut()
        .then(() => {
          history.push('/signin')
        })
      }
    }else if(userData.collection === 'customers'){
      // console.log('customer signed out')
      auth.signOut()
      .then(() => {
        history.push('/signin')
      })
    }else{
      // console.log('other user type signed out')
      auth.signOut()
      .then(() => {
        history.push('/signin')
      })
    }
   
  }

  const handleOpen = () => {
    setOpen(!open)
    if(value !== ''){
      setValue('')
    }
    handleExpand()
  }

    return(        
            <Grid container item xs={4} sm={4} justify="center" style={{height:'inherit'}} >
                
                {!!isExpanded ?
                  <Grid item xs={12} className={classes.container}>

                    <Autocomplete
                          style={{ width: 300 }}
                          options={options}
                          classes={{
                            option: classes.option,
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.name}
                          renderOption={(option) => (
                            <div style={{width:'100%'}} onClick={()=> setValue(option.name)}>
                              <Typography>{option.name}</Typography>
                              <Typography style={{fontSize:12, color:'lightgray'}}>{option.typeOfService}</Typography>
                            </div>
                          )}
                          renderInput={(params) => (
                            <CssTextField
                              autoFocus
                              style={{marginTop:6, height: 45, backgroundColor:'white', borderRadius:40}}
                              {...params}
                              label="Search..."
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />


                  </Grid>
                :
                <Grid container item xs={12}>
                  {userData.collection === 'customers' ?
                  <Grid item xs={6} sm={7} lg={8} className={classes.container}>
                    {/* <TextField style={{border:'2px solid gray', height:40 , width: searchWidth}}>
                      <SearchIcon onClick={handleExpand}/>
                    </TextField> */}
                    <Paper component='form'
                      onClick={handleExpand}
                      style={{border:'2px solid gray', borderRadius: 20, height:40 , width: searchWidth, boxShadow: 'none'}}>
                      <SearchIcon onClick={handleExpand} className={classes.searchIcon} style={{margin:'6px 0 0 6px', color: 'gray'}}/>
                    </Paper>
                  </Grid>
                  :
                  <Grid item xs={6} sm={7} lg={8} className={classes.container}></Grid>
                  }

                  
                  <Grid item xs={6} sm={5} lg={4} className={classes.grid} style={{backgroundColor:'#cf559f'}}>
                    <Hidden only='xs'>
                      <Button onClick={handleSignOut} fullWidth className={classes.navBtn}>Sign Out</Button>
                    </Hidden>

                    <Hidden smUp>
                      <Tooltip title='Sign Out'>
                        <IconButton onClick={handleSignOut}  style={{color:'white'}}>
                          <ExitToAppIcon/>
                        </IconButton>
                      </Tooltip>
                    </Hidden>
                  </Grid>
                </Grid>

                }
                

              <NavSearch
                userData={userData}
                open={open}
                handleOpen={handleOpen}
                service={value}
                options={options}
                setValue={setValue}
                CssTextField={CssTextField}
                classes={classes}/>
                

            </Grid>    
       
        
    )
}


SignedOutLinks.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};


export default withWidth()(SignedOutLinks);