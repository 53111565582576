import React, {useState, useRef, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Switch, IconButton, Modal, TextField, TextareaAutosize, Snackbar, CircularProgress, ModalManager} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';

function DeleteProPicModal(props) {
    const {userData, modalOpen, setModalOpen, image, setImage} = props
    
    const confirmRemove = () => {
        var serviceRef = firestore.collection("professionals").doc(`${userData.userId}`);
        console.log("deleting: ", image)
        serviceRef.update({
            'images.works': firebase.firestore.FieldValue.arrayRemove(image)
        })
        .then(() => {setImage(null); setModalOpen(!modalOpen)})
        // .then(() => window.location.reload())
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }

  return (
    <Modal open={!!modalOpen} onClose={()=>setModalOpen(!modalOpen)}>
    <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

          <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
              <Typography variant='h6' style={{padding: '10px'}}>
                  Confirm Delete
              </Typography>
              <IconButton onClick={()=>setModalOpen(!modalOpen)} style={{color: 'white'}}>
                  <ClearIcon />
              </IconButton>
          </Grid>

          <Typography style={{width:'inherit', textAlign:'center'}}>Are you sure you wish to delete this image?</Typography>
          <div style={{width:'inherit', height:200, display:'flex', justifyContent:'center'}}>
            <img src={image} alt={image} style={{height:'100%'}}/>
          </div>

          <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
              <Button
              variant="contained"
              style={{  
              letterSpacing: '2px',marginRight: "1rem" }}
              onClick={()=>setModalOpen(!modalOpen)}
              >
              Cancel
              </Button>

              <Button
              style={{letterSpacing: '2px', backgroundColor:'#413e68', color:'white'}}
              variant="contained"
              onClick={confirmRemove}
              >
              Confirm
              </Button>
          </div>
                             

    </Grid>

  </Modal>
  )
}

export default DeleteProPicModal