import React, {useState, useEffect} from 'react'
import {Button, Grid, Typography, Hidden, Modal, Paper, Tooltip, InputBase, TextField, MenuItem} from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import {Info, ExpandMore} from '@material-ui/icons'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import LoginModal from '../../modals/LoginModal';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PlacesAutoComplete, {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-places-autocomplete";
import Geocode from "react-geocode";
import {firestore} from '../../../config/fbConfig'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex', alignItems: 'center',
        height: 'calc(100vh - 45px)', 
        backgroundColor: '#ceebff',
        backgroundSize: 'cover', backgroundPosition: 'center',
    },
    text: {
        textDecoration: 'none', fontFamily: "spinnaker,sans-serif", fontWeight: "bold",
        display: "block", letterSpacing: '2px', textAlign:'center'
    },
    btn: {
        borderRadius: '8px', display: 'inline-block',
        fontWeight: 'bold', letterSpacing: '2px', width: "160px"
    },
    inputContainer: {
        display:"flex",  maxWidth: "fit-content", alignItems:'center', backgroundColor: "white",
        border: "2px black solid", borderRadius: '8px', padding: '7px', margin: '10px'
    },
    input: {
        border: 'none', width: '250px', padding: '10px', outline: 'none', fontSize: '16px'
    },
    listbox: {
        width: 300,
        margin: 0,
        padding: 0,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
          backgroundColor: '#4a8df6',
          color: 'white',
          cursor: 'pointer',
        },
        '& li:active': {
          backgroundColor: '#2977f5',
          color: 'white',
        },
      },
      iconButton: {
            height: "36px",
            marginRight: 'auto 10px',
            "&:hover":{
                cursor: 'pointer',
                opacity: '80%',
                height: "34px",
          },
        },
        textSelect:{
            color:'white',
            width:'100%',
            textAlign:'right',
            position:'absolute',
            bottom:0,
            paddingRight:20,
            display:'flex',
            justifyContent:'flex-end',
            fontWeight:'bold',
            '&:hover':{
                cursor:'pointer',
                fontWeight:'normal'
            }
        }
    
  }));
  

  
  const Header = (props) => {
    const history = useHistory()
    const { width, user, scrollToProSignUp } = props;
    const classes = useStyles();
    
    const [index, setIndex] = useState(0);
    const [services, setServices] = useState([]);
    const [service, setService] = useState('');
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [coordinates, setCoordinates] = useState([{lat:null, lng:null}]);
    const [address, setAddress] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [unformattedAddress, setUnformattedAddress] = useState([]);
    const [anchorel, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false)

    const {getRootProps, getInputLabelProps, getInputProps, getListboxProps, getOptionProps, groupedOptions} = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: services,
        getOptionLabel: (option) => option.name,
      });  


    const borderBottom = width >= 'sm' ? '1px solid grey' : 'none'
    const borderRight = width <= 'md' ? '1px solid grey' : 'none'
    const words = ['BEAUTY', 'WELLNESS', 'JOY', 'RELAX', 'HAPPINESS', 'SLAY']
    const margin = !!user ? 50 : null
    const size = !!user ? 50 : null
    const disabled = (service === '' || address === '') ? true : false
    const covidPadding = (width === 'sm' || width === 'xs') ? null : 10

    useEffect(() => {

        const idx = () => setIndex(i => i + 1);
        const id = setInterval(idx, 3000);
        
        if(services.length <= 0){
            // // console.log('Hello')
            firestore.collection('services').doc('allServices').get().then((doc) => {
                if (doc.exists) {
                    setServices(doc.data().listOfServices)
                } else {
                    // doc.data() will be undefined in this case
                    // // console.log("No such document!");
                }
            }).catch((error) => {
                // // console.log("Error getting document:", error);
            });
        }

        if(coordinates !== {lat:null, lng:null} && loading===true){
            // // // console.log('1')
            const {lat, lng} = coordinates
            Geocode.fromLatLng(`${lat}`, `${lng}`).then(
              (response) => {
                const address = response.results[0].formatted_address;
                const addressArr = address.split(', ')
                const state = addressArr.slice(-2, -1).join('').split(' ').slice(0,1).join()
                if(addressArr.length === 4){
                  addressArr.splice(2, 1, state)
                  setUnformattedAddress(addressArr)
                }
                else if(addressArr.length === 5){
                  addressArr.splice(3, 1, state)
                  setUnformattedAddress(addressArr)
                }
                else if(addressArr.length === 6){
                  addressArr.splice(4, 1, state)
                  setUnformattedAddress(addressArr)
                }
              },
              (error) => {
                console.error(error);
              }
              );
            }

            if(unformattedAddress.length !== 0 && loading===true){
                // // console.log('2')
                let formatted = unformattedAddress.join(', ')
                setAddress(formatted)
              }
              
              if(address !== '' && loading===true){
                // // console.log('3')
                setLoading(null)
              }

              return () => clearInterval(id);

        
      }, [coordinates, address, anchorel, loading, unformattedAddress, services])

    //service functions
    const handleChangeService = (event) => {
        setService(event.target.value);
    };


    //address functions
    const setLocation = () => {
        if(navigator.geolocation){
          setLoading(true)
          navigator.geolocation.getCurrentPosition(position => {
            setCoordinates({lat: position.coords.latitude, lng: position.coords.longitude})
          })
        }
    
      }

    const handleChangeAddress = async (value) => {
        setAddress(value);
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
    }; 

    const handleSelectAddress = async (value) => {
        const result = await geocodeByAddress(value)
        const latLng = await getLatLng(result[0])
    
        setAddress(value);
        setCoordinates(latLng)
    };

    const handleSetAddressAnchor = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //Date functions
    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
  
    };

    const handleCloseModal = () => {
        setShowLoginModal(false);
    };

    return (
        <Grid container item xs={12} className={classes.header} style={{marginLeft:margin, position:'relative', overflow:'hidden'}}>  
         
            <div style={{width:'100%', position:'absolute'}}>
                <Typography style={{fontSize:100, fontWeight:'bold', width:'100%', textAlign:'center', lineHeight:'40px', marginTop:50, color:'#ceebff', textShadow:'1px 1px #413e68, -1px 1px #413e68, 1px -1px #413e68, -1px -1px #413e68'}}>Appointments</Typography>
                <Typography style={{fontSize:100, fontWeight:'bold', width:'100%', textAlign:'center', lineHeight:'40px', marginTop:50, color:'#ceebff', textShadow:'1px 1px #413e68, -1px 1px #413e68, 1px -1px #413e68, -1px -1px #413e68'}}>On Demand</Typography>
            </div>

            <div style={{height:150, background:'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%)', position:'absolute', bottom:0, width:'100%', zIndex:10}}></div>


            <Grid container direction="column" item xs={12} style={{display:'flex', alignItems:'center', position:'relative', height:'100%', overflow:'hidden', maxWidth:1200, margin:'0px auto'}}>

                <img 
                id='hero-hand-1'
                 src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Fmobile%20experience%20bizzy%20book.png?alt=media&token=20980300-3017-4c1a-b129-4b65eecc97f2' alt=''
                 style={{position:'absolute', left:width <= 'md' ? 0 : -65, height: width <= 'md' ?'65%' : '60%'}}/>
                <img 
                id='hero-hand-2'
                 src='https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2Fmobile%20experience_pro%20bizzy%20book.png?alt=media&token=a458b5ed-df12-4044-a285-c5b01d481e43' alt=''
                 style={{position:'absolute',  right:width <= 'md' ? 0 : -90, height: width <= 'md' ?'70%' : '65%'}}/>

                 <div style={{margin: width <= 'md' ? '70px auto 0px' : '30px auto 0px', padding:10, maxWidth:600}}>
                    <Typography style={{fontSize: width <= 'md' ? 60:40, fontWeight:'bold', lineHeight:1,  width:'100%', color:'#413e68'}}>Appointments<br/>On Demand.</Typography>
                    <Typography style={{width:'100%'}}>
                        The best way to manage your schedule with none of the hassle.
                        {width <= 'md' && <br/>}
                        List your services. Get discovered. Get Booked. 100% FREE.<br/>
                        Set up you account today!
                    </Typography>
                    <div style={{padding:20, marginTop:20, display:'flex', alignItems:'center', justifyContent:'space-evenly', border:'1px solid #413e68', width:'70%'}}>
                        <img src='https://alchemyimmersive.com/wp-content/uploads/sites/4/2020/04/apple-logo-transparent.png' alt='ios' className={classes.iconButton} />
                        <img src='https://www.freepnglogos.com/uploads/google-play-png-logo/google-severs-music-studio-png-logo-21.png' alt='android' className={classes.iconButton}/>
                        <Button variant='contained' color='primary' style={{borderRadius:0}} onClick={()=> history.push('/signup')}>GET STARTED</Button>
                    </div>
                 </div>
               


            </Grid>
            


            {/* <Typography className={classes.textSelect} onClick={scrollToProSignUp} style={{color:'#413e68'}}>
                <img src="https://img.icons8.com/ios-glyphs/20/413e68/circled-left-2.png" alt='' style={{transform: 'rotate(180deg)'}}/>
                Are you a Beauty or Wellness Pro?
            </Typography> */}

            <Modal
                open={showLoginModal}
                onClose={handleCloseModal}>
                <LoginModal/>
            </Modal>
    </Grid>
    )
}

Header.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };


  export default withWidth()(Header);
