export const urlPaths = [
    {
        name: 'nails',
        imgUrl: 'https://www.tricociuniversity.edu/wp-content/uploads/2020/06/Nail-technology.png',
        titles: ['Nail Technician', 'Nail Professional', 'Nail Specialist', 'Manicurist']
    },
    {
        name: 'hair',
        imgUrl: 'https://www.gannett-cdn.com/presto/2020/05/15/USAT/ad7fea50-dd88-41e3-91df-15474a7eaa03-001_XXX_Haircuts_053.JPG?width=1320&height=974&fit=crop&format=pjpg&auto=webp',
        titles: ['Barber', 'Beautician', 'Hair Dresser', 'Wig Dresser']
    },
    {
        name: 'makeup',
        imgUrl: 'https://www.ogleschool.edu/wp-content/uploads/2019/02/being-a-makeup-artist.jpg',
        titles: ['Makeup Artist', 'Makeup Professional', 'Cosmetologist']
    },
    {
        name: 'brow-lashes',
        imgUrl: 'https://img.grouponcdn.com/metro_draft_service/3XtLFTpm3tLVWwduEnoTCGyb3ZZx/3X-2560x1212/v1/t440x300.jpg',
        titles: ['Lash Technician', 'Cosmetologist', 'Eyelash Extension Specialist']
    },
    {
        name: 'facials',
        imgUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/therapist-applying-face-mask-to-man-royalty-free-image-609094134-1547225313.jpg?crop=1.00xw:0.755xh;0,0.151xh&resize=1200:*',
        titles: ['Esthetician', 'Skincare Specialist', 'Spa Technician', 'Facial Specialist']
    },
    {
        name: 'massage',
        imgUrl: 'https://post.healthline.com/wp-content/uploads/2020/09/stone_massage_woman-732x549-thumbnail-732x549.jpg',
        titles: ['Massage Therapist', 'Masseur', 'Masseuse']
    },
    {
        name: 'waxing',
        imgUrl: 'http://static1.squarespace.com/static/58013b8b03596eead7401e0b/5801667c893fc06913bbd491/58fa667429687f4a7c27684a/1492805968408/body-waxing-in-downtown-san-diego.jpg?format=1500w',
        titles: ['Waxer', 'Hair Removal Specialist', 'Wax Specialist']
    },
    {
        name: 'health-fitness',
        imgUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-1206266266.jpg',
        titles: ['Dietitian', 'Personal Trainer', 'Yoga Instructor', 'Therapist']
    },
    {
        name: 'home-car-care',
        imgUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-1206266266.jpg',
        titles: ['General Contractor', 'Electrician', 'Carpenter', 'Plumber']
    },
    {
        name: 'tattoo-piercing',
        imgUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-1206266266.jpg',
        titles: ['Tattooist', 'Tattoo Artist', 'Body Piercer']
    },
    {
        name: 'pet-care',
        imgUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-1206266266.jpg',
        titles: ['Pet Sitter', 'Dog Walker', 'Pet Groomer']
    },
    {
        name: 'other',
        imgUrl: 'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-1206266266.jpg',
        titles: ['Audio Engineer']
    },
    

]