import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { TimeSelect } from "./01TimeSelect";
import Review from "./02Review";

const defaultData = {
  service: "",
  serviceCost: "",
  dateTime: null,
  address: "",
  havePets: '',
  petTypes: "",
  notes: "",
  client: null,
  pro: null,
  bookingFee: "",
  travelFee: "",
};

const steps = [
  { id: "time select" },
  { id: "review" },
  // { id: "submit" },
  // { id: "close" },
];

export const Scheduler = ({userData, appt, selectedService, handleModalOpen, modalOpen, scheduledAppointments, handleMenuClose}) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { userData, formData, setForm, navigation, appt, selectedService, handleModalOpen, modalOpen, scheduledAppointments, handleMenuClose};

  switch (step.id) {
    case "time select":
      return <TimeSelect {...props} />;
    case "review":
      return <Review {...props} />;
    //  case "submit":
    //    return (
    //    <Elements stripe={stripePromise}>
    //      <Checkout {...props} />
    //    </Elements>);
    // case "close":
    //   return <AppointmentTips {...props} />;
    default:
      return (
        null
        // <Review {...props} />
        );
  }
};