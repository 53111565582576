import React,{useState, useEffect} from "react";
import {Typography, Container, TextField, Button, MenuItem, CircularProgress, makeStyles, Paper, InputBase, Tooltip} from "@material-ui/core";
import PlacesAutoComplete, {geocodeByAddress, geocodeByPlaceId, getLatLng} from "react-places-autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {firestore} from '../../../../config/fbConfig'
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_PLACES_KEY);
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const useStyles = makeStyles((theme) => ({
  input: {
    paddingLeft: theme.spacing(1),
    flex: 1,
    display: 'flex',
    height: 50,
    alignItems: 'center',
    boxShadow: 'none',
  },
  iconButton: {
    color: theme.palette.primary.main,
    fontSize: "40px",
    "&:hover":{
      cursor: 'pointer',
      opacity: '80%',
    },
  },
}));

export const Account = ({ formData, setForm, navigation }) => {
  const classes = useStyles()
  const { phone, email, password, firstName, lastName, displayName } = formData;
  const [emailInUse, setEmailInUse]= useState()
  const [invalid, setInvalid]= useState(false)
  const [address, setAddress] = useState("");
  const [unformattedAddress, setUnformattedAddress] = useState([]);
  const [loading, setLoading] = useState(false)
  const [coordinates, setCoordinates] = useState([{lat:null, lng:null}]);
  const [anchorEl, setAnchorEl] = useState(null);
  const disabled = ((firstName === "" ||lastName === "" || displayName === "" || phone === "" || email === "" || password === "", address==="") || invalid===true) ? true : false;
  const border = (invalid === true || emailInUse === true) ? '2px solid red' : 'none'
  const [checked, setChecked] = useState()
  
  const lettersOnly = (e) => {
    const re = /[A-Za-z- ]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const numsOnly = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  const validEmail = (e) => {
    const re = /[a-z0-9@-_.+~]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
      setInvalid(true)
    }
  }

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value)
    const latLng = await getLatLng(result[0])

    setAddress(value);
    setCoordinates(latLng)
    formData.address = value;
  };

  const handleChange = async (value) => {
    setAddress(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setLocation = () => {
    if(navigator.geolocation){
      setLoading(true)
      navigator.geolocation.getCurrentPosition(position => {
        setCoordinates({lat: position.coords.latitude, lng: position.coords.longitude})
      })
    }

  }

  const checkIfEmailInUse = () => {
    // console.log('checking...')
    setChecked(true)
    if(emailInUse === true){
      setEmailInUse(false)
    }
    if(email.includes("@") && email.includes(".")){
      firestore.collection('professionals').where("email", "==", `${email}`)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log('Email in use')
          setEmailInUse(true)
          formData.password = ''
      });
    })
    }
    if(email.includes("@") && email.includes(".")){
      firestore.collection('customers').where("email", "==", `${email}`)
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log('Email in use')
          setEmailInUse(true)
          formData.password = ''
      });
    })
    }

    // console.log(emailInUse)
  }

  useEffect(() => {

    if(checked !== undefined){
      setChecked(false)
    }

    if(!email.includes("@") || !email.includes(".")) {
      setInvalid(true)
    }

    if(!email.includes("@") && email !== '') {
      setInvalid(true)
      setEmailInUse(false)
    }else{
      setInvalid(false)
    }

    if(checked === true && emailInUse === false && disabled === false && invalid === false){
      navigation.next()
    }

    if(coordinates !== {lat:null, lng:null} && formData.coordinates !== coordinates){
      // console.log('1')
      const {lat, lng} = coordinates
      Geocode.fromLatLng(`${lat}`, `${lng}`).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const addressArr = address.split(', ')
          const state = addressArr.slice(-2, -1).join('').split(' ').slice(0,1).join()
          if(addressArr.length === 4){
            addressArr.splice(2, 1, state)
            setUnformattedAddress(addressArr)
          }
          else if(addressArr.length === 5){
            addressArr.splice(3, 1, state)
            setUnformattedAddress(addressArr)
          }
          else if(addressArr.length === 6){
            addressArr.splice(4, 1, state)
            setUnformattedAddress(addressArr)
          }
        },
        (error) => {
          console.error(error);
        }
        );
      }
      
      if(unformattedAddress.length !== 0 && loading===true){
        // // console.log('2')
        let formatted = unformattedAddress.join(', ')
        setAddress(formatted)
      }
      
      if((address !== '') && (anchorEl !== null)){
        // // console.log('3?')
        formData.address = address
        formData.coordinates = coordinates
      }
      
      if(address !== '' && loading===true){
        // // console.log('3')
        setLoading(false)
        formData.address = address
        formData.coordinates = coordinates
      }

  }, [address, coordinates, anchorEl, unformattedAddress, loading, email, invalid, emailInUse, checked, disabled, navigation, formData])


  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Account</Typography>
      
      <TextField
        label="First Name"
        name="firstName"
        onKeyPress={(e) => lettersOnly(e)}
        value={firstName}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        fullWidth
      />

      <TextField
        label="Last Name"
        name="lastName"
        onKeyPress={(e) => lettersOnly(e)}
        value={lastName}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        fullWidth
      />
      <TextField required
        label="Business/Display Name"
        name="displayName"
        placeholder='i.e. Cuti Cutz'
        value={displayName}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        fullWidth
      />

      <TextField required
        label="Phone Number"
        name="phone"
        type="tel"
        onKeyPress={(e) => numsOnly(e)}
        value={phone}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="tel"
        fullWidth
      />

{loading === true ? 
        <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
          <CircularProgress style={{color: '#413e68'}}/>
        </div> 
        :
        <PlacesAutoComplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            style={{width:'inherit',}}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{width:'inherit', border:'1px solid silver', borderRadius:4, margin:'10px 0px 5px 0px'}}>
                <Paper component="form" className={classes.input}>
                  
                    <InputBase aria-controls="simple-menu" onClick={handleClick}
                    {...getInputProps({
                      placeholder: 'Enter Your Work Address...',
                    })}
                    className={classes.input}
                  />
                  <Tooltip title='Use my location'>
                    <LocationOnIcon className={classes.iconButton} onClick={setLocation}/>
                  </Tooltip>
                </Paper>

                  <div id="simple-menu" anchorEl={anchorEl}>
                    {loading && <MenuItem>Loading...</MenuItem>}
                    {suggestions.map((suggestion,) => {
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#413e68",
                            color: "white",
                            cursor: "pointer",
                          }
                        : { backgroundColor: "white" };

                      return (
                        <MenuItem onClick={handleClose} key={suggestion.description} {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </MenuItem>
                      );
                    })}
                  </div>

              </div>
            )}
          </PlacesAutoComplete>
        }

      <TextField required
        label="Email Address"
        name="email"
        id="email"
        onKeyPress={(e) => validEmail(e)}
        value={email}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="email"
        fullWidth
        style={{border:border}}
      />
      {emailInUse === true && 
      <Typography style={{color: "red"}}>Email is already in use.</Typography>}
      
      <TextField required
        type="password"
        id="password"
        label="Password"
        name="password"
        value={password}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        fullWidth
      />
      {/* <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button> */}
        <Button
          fullWidth
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={() => navigation.next()}
        >
          Let's Go!
        </Button>
      {/* </div> */}
    </Container>
  );
};