import React from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button} from '@material-ui/core'
import {Link} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
          },
    }
      ));

function WhyUs({user, width, proSignUp}) {
    const margin = !!user ? 50 : null
    const classes = useStyles();
    const padding = (width==='xs' || width==="sm") ? 10 : '0px 30px'
    const padding1 = (width==='xs' || width==="sm") ? 0 : (width==="md") ? 15 : '15px 130px 15px 15px'
    const textPadding = (width==='xs' || width==="sm") ? '10px' : '0px 130px 40px'
    const bc = (width==='xs' || width==="sm") ? 'white' : '#f5f5f5'
    return (
        <Grid container className={classes.root} style={{marginLeft:margin, display:'flex', justifyContent:'center', backgroundColor:'white', padding:15}}>
            {/* <Grid item xs={false} md={2} xl={3}></Grid>
            <Grid container item xs={12} md={8} xl={6} direction="column" style={{textAlign: "center", backgroundColor: 'white', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', padding:15}}>
                <Typography style={{fontWeight:'bold', fontSize:24, color:'#413e68'}}>Do More With Cuti</Typography>
            </Grid>
            <Grid item xs={false} md={2} xl={3}></Grid> */}


            <Grid item xs={false} md={6}>
                <img style={{width: '100%', height:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2FCuti-landingpage-do-more-w-cuti.png?alt=media&token=b42dd276-650a-4d5c-a1fa-bb2e62584cbe" alt="Why Us pic"/>
            </Grid>

            <Grid container item xs={12} md={6} direction="column" style={{backgroundColor: 'white', display:'flex', flexFlow:'column', justifyContent:'center', padding:padding1}}>
                {/* <hr style={{border:"1px solid lightgrey", width:"60%"}}/> */}
                
                <Typography style={{textAlign:'left', fontWeight:'bold', fontSize:24}}>Do more with BizzyBook</Typography>
                <Typography style={{textAlign:'left', fontSize:20, marginTop:10}}>
                    Life can get crazy, we know! Thats why it's our mission to make appointment booking easy and seamless.
                    <br/><br/> 
                    Our platform is great for discovering new and well established local professionals! 
                    Once you've found a business/pro that meets your unique needs, you can conveniently book an appointment with them, 24/7!
                    <br/><br/> 
                    But We don't stop there! Our platform also helps you find pros exactly when you need them, say "now" - is that a good time?

                </Typography>
            
            </Grid>


            <Grid item xs={false} md={2} xl={3}></Grid>
            <Grid item container xs={12} md={8} xl={6} style={{paddingTop:40}}>
                <Typography style={{fontWeight:'bold', fontSize:24, width:'inherit'}}>Our Web App saves space!</Typography>
            </Grid>
            <Grid item xs={false} md={2} xl={3}></Grid>

            <Grid item xs={false} md={2} xl={3}></Grid>
            <Grid item container xs={12} md={8} xl={6} style={{backgroundColor:'white', padding:padding}}>
                <img style={{width: '100%', height:'100%'}} src="https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2FCuti-landingpage-do-more-w-cuti-2.png?alt=media&token=91483577-61ef-43bc-b655-271d3e1c689f" alt="Why Us pic"/>
            </Grid>
            <Grid item xs={false} md={2} xl={3}></Grid>

            <Grid item xs={false} md={2} xl={3}></Grid>
            <Grid item container xs={12} md={8} xl={6} style={{padding:15, marginBottom:60}}>
                <Typography style={{fontSize:20, marginTop:10, padding:20, backgroundColor:'#cdecff', textAlign:'center'}}>
                    Rest assured, our mobile app is on the way! In the meantime, our website is optimized for mobile devices so you can 
                    use it on the go! Save our site to your homescreen for quick reference and save space on your phone.
                </Typography>
            </Grid>
            <Grid item xs={false} md={2} xl={3}></Grid>


            <Grid item xs={false} md={2} xl={3}></Grid>
            <Grid item container xs={12} md={8} xl={6} style={{display:'flex', justifyContent:'center'}}>
                <Typography style={{fontWeight:'bold', fontSize:24, textAlign:'left', width:'inherit'}} ref={proSignUp}>Earn as a Pro</Typography>
                <Typography style={{fontSize:20, marginTop:10, padding:20, textAlign:'center'}}>
                Are you a Service Provider?  Pre-Register with us today! 
                On-demand services have become a dominant force in just about every industry and for good reason: we live busy lives and find it appealing when services can cater to our schedules. 
                Get booked & Control your schedule free today!
                </Typography>
            </Grid>
            <Grid item xs={false} md={2} xl={3}></Grid>


            <Grid item xs={false} md={2} xl={3}></Grid>
            <Grid item container xs={12} md={8} xl={6} style={{height:300, borderRadius:'12px', backgroundImage:"url('https://blog.payroc.com/hubfs/guilherme-petri-602659-unsplash%20%281%29.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', marginBottom:100, display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Typography variant='h3' style={{fontWeight:'bold', width:'inherit', textAlign:'center', color:'white', textShadow:'4px 4px 4px #0000009c'}}>List your services & start earning</Typography>
                <Button variant="contained" color='primary' component={Link} to='/signup' style={{width:200, height: 40}}>Become a pro</Button>
            </Grid>
            <Grid item xs={false} md={2} xl={3}></Grid>


        </Grid>
    )
}

WhyUs.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(WhyUs)
