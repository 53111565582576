import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, FormControl, IconButton, Modal, TextField,
        MenuItem, Select, InputLabel, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { firestore, storage } from '../../config/fbConfig';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
    profileContainer:{
      position: 'relative',
      display:"flex",
      flexFlow:'column',
      alignItems:'center',
      padding: 10,
      marginBottom: 12,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    imgSelecter:{
      position:'absolute',
      top: 120,
      left: '52%',
      border: '2px solid white',
      backgroundColor: theme.palette.primary.main,
      '&:hover':{
        cursor:'pointer',
        opacity: .8,
      },
      accordianDetails:{
        width:'100%',
        display:'flex',
        flexFlow:'column',
        textAlign: 'left'
      },
      accordianEditBtn:{
        alignSelf:'flex-end'
      },
    }
  
  }));

function CancelApptModal({appt, userData, modalOpen, handleModalOpen,handleMenuClose}) {

    const classes = useStyles();
    const [reason, setReason] = React.useState('');
    const [otherReason, setOtherReason] = React.useState('');
    const reasons = (userData && userData.collection === 'customers') ? ['Personal Illness', 'Professionl is Running Late', "I'm Running Late", 'Schedule Conflict', 'I Prefer Not To Answer','Other'] :
                    ['Personal Illness', 'Customer is Running Late', "I'm Running Late", 'Schedule Conflict', 'Customer is unavailable', 'Cannot perform service', 'Other']
    
    const disabled = (reason === '' || (reason === 'Other' && otherReason === '')) ? true : false

    const handleChangeReason = (event) => {
        // console.log(userData)
        setReason(event.target.value);
    };

    const handleChange = (e) => {
        setOtherReason(e.target.value);
    };

    const handleCancel = () => {
        firestore.collection('appointments').doc(appt.id)
        .update({
            serviceCanceled: true,
            serviceCanceledBy: userData.firstName + " " + userData.lastName,
            serviceCanceledReason: `${otherReason} - ${reason}`,
            serviceCanceledTime: firebase.firestore.FieldValue.serverTimestamp(),
            serviceTimeEnd: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
            firestore.collection(userData.collection).doc(userData.userId)
            .update({
                apptsCanceled: firebase.firestore.FieldValue.increment(1)
            })
        })
        // .then(() => {
        // create toast --> 'The appointment has been Canceled. Hope to see you back soon! 👍'
        // })
        .then(() => {
            handleMenuClose()
            handleModalOpen()
            // setTimeout(() => {window.location.reload()},2000)
        })
    }

    return (
        <Modal open={!!modalOpen} onClose={handleModalOpen}>
            <Grid container item xs={10} sm={6} md={4} lg={3} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Cancel Appointment
                    </Typography>
                    <IconButton onClick={handleModalOpen} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid item container xs={12} style={{textAlign:'center'}}>
                    <Typography style={{marginBottom:10, width:'100%'}}>Please select a reason for the cancelation.</Typography>

                    <FormControl variant="outlined" className={classes.formControl} >
                        <InputLabel id="select-outlined-label">Reason</InputLabel>
                        <Select
                            fullWidth
                            labelId="select-outlined-label"
                            id="select-outlined"
                            value={reason}
                            onChange={handleChangeReason}
                            label="Reason"
                            >
                            
                            <MenuItem value=""><em>--Select--</em></MenuItem>
                            {reasons.map((r, idx) => {
                                return(
                                    <MenuItem key={r} value={r}>{r}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    
                    {reason === 'Other' &&
                    <TextField
                        className={classes.formControl} 
                        name='otherReason'
                        fullWidth
                        variant='outlined'
                        placeholder='Reason...'
                        onChange={(e) => handleChange(e)}
                        value={otherReason}
                    />
                    }

                    <Grid item container xs={12} className={classes.formControl}>
                        <Grid item xs={6} style={{paddingRignt:5}}>
                            <Button variant='contained' fullWidth onClick={handleModalOpen}>Never Mind</Button>
                        </Grid>
                        <Grid item xs={6} style={{paddingLeft:5}}>
                            <Button variant='contained' fullWidth color='primary' disabled={disabled} onClick={handleCancel}>Confirm</Button>
                        </Grid>
                    </Grid>


                </Grid>

            </Grid>
            
        </Modal>
    )
}

export default CancelApptModal
