import React, {useState,useEffect} from 'react'
import {Container, CssBaseline, Grid, Typography, Avatar,
    TextField, FormControlLabel, Button, Box, Checkbox, Link} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {withStyles} from '@material-ui/core/styles';
import {auth} from '../../config/fbConfig'

const useStyles = makeStyles((theme) => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        
      },
      avatar: {
        margin: theme.spacing(0),
        backgroundColor: theme.palette.primary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
  }));

 

function ResetPassword() {
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [err, setErr] = useState(null)
    
    const handleSubmit = () => {
        auth.sendPasswordResetEmail(email)
        .then(() => {
            // console.log("Password reset email sent!")
            window.location.assign('/signin')
          // ..
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          setErr(errorMessage)
          // ..
        });
    }

    useEffect(() => {
        if(email=== '' && err!==null){
            setErr(null)
        }
    }, [email, err])

    return (
        <div style={{display:"flex", flex:'1 1 auto', alignItems:'center', position: 'relative'}} >
            <div style={{margin: 0, position: 'absolute', top: '100%', width: '100%', transform: 'translate(0, 10%)'}}></div>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Reset Password
                </Typography>
                {/* <form className={classes.form} onSubmit={handleSubmit}> */}
                  <TextField onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  {(err && email!=='') && <Typography color='error' style={{textAlign:'center'}}>{err}</Typography>}

                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                {/* </form> */}
              </div>
            </Container>
            </div>
    )
}

export default ResetPassword
