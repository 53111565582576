import React, {useState, useEffect} from 'react';
import {Container, Grid, Typography, CircularProgress, withWidth, Hidden, Button} from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {ScheduleAppointment} from '../dashboard/home/scheduleAppointment/ScheduleAppointment'
import ProUpcomingAppointments from './home/upcomingAppointments/ProUpcomingAppointments';
import UpcomingAppointments from '../dashboard/home/upcomingAppointments/UpcomingAppointments'
import PreviousAppointments from '../dashboard/home/previousAppointments/PreviousAppointments'
import EarningsQuickView from './home/proEarnings/EarningsQuickView';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment, { weekdays } from 'moment';
import EmailVerified from '../modals/EmailVerified';
import CreateBrandAmModal from '../modals/CreateBrandAmModal';




const useStyles = makeStyles((theme) => ({
  homeRoot:{display:"flex",
    justifyContent:"center",
    flexDirection: "column",
    padding: "0",
    overflowY: "hidden scroll",
    height: "92vh",
    borderTop: 'solid 1px #d3d3d3',
    textAlign: 'center',
    maxWidth: 1000 },
  paper: {
    margin: '10% auto',
    width: '80%',
    height: '70%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: '8px',
  },
  dashSectionContainer:{
    height: '45%',
    padding: 10,
  },
  dashSection:{
    height: '100%',
    border:'1px solid lightgray',
    borderRadius:12,
    padding: 10,
    textAlign:'center',
    display:'flex',
    //alignItems:'center',
  },
  section:{
    borderRadius: 12,
    border:'1px solid lightgray',
    padding:10,
    marginBottom: 10,
    textDecoration:'none',
    color: 'black',
    "&:hover":{
      cursor:'pointer',
      border:`2px solid ${theme.palette.primary.main}`,
      backgroundColor:'#e6e6e6',
    },
  },
}));

const Home = ({currentUser, userData, services, amount, appointments}) => {
  const history = useHistory()
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true)
  const [toggled, setToggle] = React.useState(false)
  const [toggled2, setToggle2] = React.useState(false)
  const [brandAmModal, setBrandAmModal] = useState(false)


  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  useEffect(() => {
    if(amount > 0){
      setLoading(false)
    }
    if(userData){
      if(userData.accountStatus === 'onboarding'){
        history.push('continue-onboarding')
      }
    }
    if(currentUser === null){
        history.push('/')
      
    }
    // console.log("This year will end:", moment(new Date()).endOf('year').toDate())
  },[amount, userData, appointments])

    return(
          <Container className={classes.homeRoot}>

{/* PRO VIEW -------------------------------------------------------------------------------------------------------------------------
 ------------------------------------------------------------------------------------------------------------------------------------*/}
            {(userData && userData.collection === 'professionals') && 
              <Grid container item xs={12} direction="column" style={{height: "100%"}}>
                <Hidden only={['xs','sm']}>
                  <Grid container item xs={12} >
                    {loading === true ?
                    <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
                      <CircularProgress style={{color: '#413e68'}}/>
                    </div>
                    : 
                    <>
                    <Grid container item md={7} style={{padding: 5, marginBottom: 20, height:510, alignSelf:'center'}}>
                      <ProUpcomingAppointments userData={userData} appointments={appointments}/>
                    </Grid>

                    <Grid container item md={5} style={{padding: 5, marginBottom: 20}}>
                      <EarningsQuickView userData={userData}/>
                    </Grid>
                    </>
                    }
                  </Grid>
               
                </Hidden>

                <Hidden only={['md','lg', 'xl']}>
                  <Grid container item xs={12} >

                    {loading === true ?
                    <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
                      <CircularProgress style={{color: '#413e68'}}/>
                    </div>
                    : 
                    <>
                    {!toggled ? 
                      <Grid container item xs={12} style={{padding: 5, marginBottom: 20}}>
                        <ProUpcomingAppointments userData={userData} appointments={appointments}/>
                      </Grid>
                    :
                      <Grid container item xs={12} style={{padding: 5, marginBottom: 20}}>
                        <EarningsQuickView userData={userData}/>
                      </Grid>
                    }
                    
                    
                    {!toggled ? 
                      <Grid item xs={12}><Button fullWidth variant='contained' style={{padding:10}}  onClick={()=> setToggle(!toggled)}>View Earnings</Button></Grid>
                    :
                      <Grid item xs={12}><Button fullWidth variant='contained' style={{padding:10}} color='primary' onClick={()=> setToggle(!toggled)}>View Schedule</Button></Grid>
                    }
                    
                    </>
                    }
                  </Grid>
                </Hidden>
              </Grid> 
            }

{/* CUSTOMER VIEW --------------------------------------------------------------------------------------------------------------------
 ------------------------------------------------------------------------------------------------------------------------------------*/}
            
            {(userData && userData.collection === 'customers') &&
              <Grid container item xs={12} direction="column" style={{height: "100%"}}>
                <Hidden only={['xs','sm']}>
                  <Grid container item xs={12}>

                    {loading === true ?
                    <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
                      <CircularProgress style={{color: '#413e68'}}/>
                    </div>
                    : 
                    <>
                    <Grid container item xs={12} md={7} style={{padding: 5, marginBottom: 20, display: 'flex', justifyContent: 'center', margin:'auto'}}>
                      <Grid item>
                        <ScheduleAppointment userData={userData} services={services}/>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} md={5} style={{margin:'auto'}}>
                      <Grid item xs={12} style={{padding:'0 10px', height: 245, margin:'20px auto'}}>
                        <UpcomingAppointments userData={userData} appointments={appointments}/>
                      </Grid>
                      <Grid item xs={12} style={{padding:'0 10px', height: 245}}>
                        <PreviousAppointments userData={userData}/>  
                      </Grid> 
                    </Grid>
                    </>
                    }
                  </Grid>

                </Hidden>

                <Hidden only={['md','lg', 'xl']}>
                  <Grid container item xs={12}>

                    {loading === true ?
                    <div style={{display:'flex', width:'inherit', justifyContent:'center', alignItems:'center'}}>
                      <CircularProgress style={{color: '#413e68'}}/>
                    </div>
                    : 
                    <>
                    {!toggled2 ? 
                    <Grid container item xs={12} md={7} style={{padding: 5, marginBottom: 20, display: 'flex', justifyContent: 'center', margin:'auto'}}>
                      <Grid item>
                        <ScheduleAppointment userData={userData} services={services}/>
                      </Grid>
                    </Grid>
                    :
                    <div>
                      <Grid container item xs={12} md={5} style={{margin:'auto'}}>
                        <Grid item xs={12} style={{padding:'0 10px', height: 245, margin:'20px auto'}}>
                          <UpcomingAppointments userData={userData}/>
                        </Grid>
                        <Grid item xs={12} style={{padding:'0 10px', height: 245}}>
                          <PreviousAppointments userData={userData}/>  
                        </Grid> 
                      </Grid>
                    </div>
                    }

                    {!toggled2 ? 
                      <Grid item xs={12}><Button fullWidth variant='contained' style={{padding:10, marginTop:-20}}  onClick={()=> setToggle2(!toggled2)}>View Appointments</Button></Grid>
                    :
                      <Grid item xs={12}><Button fullWidth variant='contained' style={{padding:10, marginTop:-20}} color='primary' onClick={()=> setToggle2(!toggled2)}>Schedule Appointment</Button></Grid>
                    }
                    </>

                    
                    }
                  </Grid>

                </Hidden>
              </Grid>           
            } 

{/* ADMIN VIEW ----------------------------------------------------------------------------------------------------------------------
 ------------------------------------------------------------------------------------------------------------------------------------*/}

            {(userData && userData.collection === 'admins') &&
              <Grid container item xs={12} direction="column" style={{height: "100%"}}>
                
                  <Grid container item xs={12}>

                    <Grid item container xs={12} md={6} className={classes.dashSectionContainer}>
                      <Grid item container xs={12} className={classes.dashSection}>
                        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                        <Typography variant='h4' style={{fontWeight:'bold'}}>Users</Typography>
                          <Button color='primary' variant='contained' style={{height:40}} onClick={()=>setBrandAmModal(!brandAmModal)}>Create A Brand</Button>
                        </div>
                        <Grid item container xs={12} style={{padding:10, display:'flex', justifyContent:'space-around'}}>
                          <Grid item container xs={11} md={5} className={classes.section} component={Link} to="/admin/users/pros">
                            <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/ios-filled/24/000000/caretaker.png" alt=""/>
                            <Typography style={{fontWeight:'bold', lineHeight:'24px', }}>Pros</Typography>
                          </Grid>
                          <Grid item container xs={11} md={5} className={classes.section} component={Link} to="/admin/users/clients">
                            <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/>
                            <Typography style={{fontWeight:'bold', lineHeight:'24px'}}>Clients</Typography>
                          </Grid>
                          <Grid item container xs={11} className={classes.section} component={Link} to="/admin/users/admins">
                            {/* <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/> */}
                            <Typography style={{fontWeight:'bold', lineHeight:'24px'}}>Admins</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container xs={12} md={6} className={classes.dashSectionContainer}>
                      <Grid item container xs={12} className={classes.dashSection}>
                        <Typography variant='h4' style={{fontWeight:'bold', width:'100%'}}>Appointments</Typography>
                        <Grid item container xs={12} style={{padding:10, display:'flex', justifyContent:'space-around'}}>
                          <Grid item container xs={11} className={classes.section} component={Link} to="/admin/appointments">
                            {/* <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/> */}
                            <Typography style={{fontWeight:'bold', lineHeight:'24px'}}>Appointments</Typography>
                          </Grid>
                          <Grid item container xs={11} className={classes.section} component={Link} to="/admin/services">
                            {/* <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/> */}
                            <Typography style={{fontWeight:'bold', lineHeight:'24px'}}>Services</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} md={6} className={classes.dashSectionContainer}>
                      <Grid item container xs={12} className={classes.dashSection}>
                        <Typography variant='h4' style={{fontWeight:'bold', width:'100%'}}>Support Chats</Typography>
                        <Grid item container xs={12} style={{padding:10, display:'flex', justifyContent:'space-around'}}>
                          <Grid item container xs={11} className={classes.section} component={Link} to="/admin/chat/pros">
                          <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/ios-filled/24/000000/caretaker.png" alt=""/>
                            <Typography style={{fontWeight:'bold', lineHeight:'24px'}}>Pros</Typography>
                          </Grid>
                          <Grid item container xs={11} className={classes.section} component={Link} to="/admin/chat/clients">
                            <img style={{height:24, marginRight: 5}} src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/24/000000/external-user-interface-kiranshastry-solid-kiranshastry-1.png" alt=""/>
                            <Typography style={{fontWeight:'bold', lineHeight:'24px'}}>Clients</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {userData.role === 'super admin' &&
                    <Grid item container xs={12} md={6} className={classes.dashSectionContainer}>
                      <Grid item container xs={12} className={classes.dashSection}>
                        <Typography variant='h4' style={{fontWeight:'bold', width:'100%'}}>Revenue</Typography>
                        <Grid container item xs={6}>
                          <Typography style={{width:'100%', textAlign:'left'}}>At a glance:</Typography>
                          </Grid>
                        <Grid container item xs={6}>
                          <Typography style={{width:'100%', textAlign:'right', fontWeight:'bold'}}>Appts Booked: 1.2k</Typography>
                        </Grid>
                        <Grid container item xs={12} md={6}>
                          <Typography style={{width:'100%', textAlign:'left', fontSize:20, fontWeight:'bold'}}>Revenue YTD: $79.6k</Typography>
                          
                        </Grid>
                        <Grid container item xs={12} md={6}>
                          <Typography style={{width:'100%', textAlign:'right', fontWeight:'bold', fontSize:20}}>Earnings: $6.2k</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <ResponsiveContainer width="100%" height='100%'>
                            <BarChart height={40} data={data} 
                            margin={{
                                top: 5,
                                right: 5,
                                left: 5,
                                bottom: 5,
                            }}
                            barSize={15}>
                                <XAxis dataKey="name" padding={{ left: 2, right: 2 }} />
                                {/* <YAxis /> */}
                                <Tooltip />
                                <Bar dataKey="sales" stackId="a" fill="#413e68" />
                                <Bar dataKey="earnings" stackId="a" fill="#82ca9d" />
                                <Bar dataKey="appts" fill="#ffc658" />
                            </BarChart>
                          </ResponsiveContainer>
                        </Grid>
                        <Grid contianer items xs={12}>
                          <Button variant='outlined' fullWidth>View Details</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    }
                  </Grid>


                  <CreateBrandAmModal modalOpen={brandAmModal} setModalOpen={setBrandAmModal}/>

              </Grid>     
            }
         
            

            {(currentUser && userData.collection !== 'admins' && currentUser.emailVerified === false) &&
              <EmailVerified currentUser={currentUser}/>
            }
          </Container>
            
    )
}


Home.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

const data = [
  {
    name: 'J',
    sales: 1200,
    earnings: 240,
    appts: 20,
  },
  {
    name: 'F',
    sales: 3000,
    earnings: 600,
    appts: 58,
  },
  {
    name: 'M',
    sales: 6500,
    earnings: 720,
    appts: 79,
  },
  {
    name: 'A',
    sales: 7200,
    earnings: 1340,
    appts: 92,
  },
  {
    name: 'M',
    sales: 6500,
    earnings: 800,
    appts: 88,
  },
  {
    name: 'J',
    sales: 8800,
    earnings: 880,
    appts: 100,
  },
  {
    name: 'J',
    sales: 7300,
    earnings: 940,
    appts: 98,
  },
  {
    name: 'A',
    sales: 14320,
    earnings: 1430,
    appts: 140,
  },
  {
    name: 'S',
    sales: 12562,
    earnings: 2300,
    appts: 132,
  },
  {
    name: 'O',
    sales: 7823,
    earnings: 600,
    appts: 89,
  },
  {
    name: 'N',
    sales: 15022,
    earnings: 2140,
    appts: 144,
  },
  {
    name: 'D',
    sales: 8302,
    earnings: 800,
    appts: 98,
  },
]

export default withWidth()(Home);