import React, {useState} from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DeleteIcon from '@material-ui/icons/Delete';
import {Typography, Grid, Button, Avatar, Slide, CircularProgress, IconButton, Modal, Snackbar, Paper, InputBase, Slider} from "@material-ui/core";
import { firestore, storage } from '../../../../../config/fbConfig'
import firebase from 'firebase';
import DeleteProPicModal from '../../../../modals/DeleteProPicModal';

function Sliderr(props) {
    const {images, userData, pro, select, addTop, addBottom} = props
    const [slideIndex, setSlideIndex] = useState(1)
    const [selectedImage, setSelectedImage] = useState(null)
    const [deleteImgModalOpen, setDeleteImgModalOpen] = useState(false)

    const nextSlide = () => {
        if(slideIndex !== images.length){
            setSlideIndex(slideIndex + 1)
        }
        else if(slideIndex === images.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if(slideIndex === 1){
            setSlideIndex(images.length)
        }
    }


    return (
        <div className='container-slider'>
            {images.length === 0 && 
                <div className='slide active-anim' style={{borderRadius: 16, position:'absolute'}}>
                    <img src='https://i1.wp.com/lanecdr.org/wp-content/uploads/2019/08/placeholder.png?fit=1200%2C800&ssl=1' alt="" style={{height:'100%', width:'100%', objectFit:'cover', borderRadius: 16}}/>
                    {(pro !==undefined && (userData.userId === pro.userId)) &&
                    <IconButton style={{backgroundColor:'#413e68', color:'white',  position:'absolute', top:addTop, bottom:addBottom, right:10, zIndex:10}} onClick={select}><AddAPhotoIcon/></IconButton>
                    }
                </div>
              }
            {images.map((image, idx) => {
                return (
                  <div key={image+idx} className={slideIndex === idx + 1 ? 'slide active-anim' : 'slide'} style={{borderRadius: 16, position:'absolute'}}>
                    <img src={image} alt="" style={{height:'100%', width:'100%', objectFit:'cover', borderRadius: 16}}/>


                    {(pro !==undefined && (userData.userId === pro.userId)) &&
                    <div style={{display:'flex', justifyContent:'center', position:'absolute', top:addTop, bottom:addBottom, right:10, zIndex:10}}>
                        <IconButton style={{backgroundColor:'#413e68', color:'white'}} onClick={select}><AddAPhotoIcon/></IconButton>
                        <IconButton style={{marginLeft:10, backgroundColor:'#ffffff', color:'grey'}} onClick={()=>{setSelectedImage(image); setDeleteImgModalOpen(!deleteImgModalOpen)}}><DeleteIcon/></IconButton>
                    </div>
                    }
                  </div>
                )
              })}

              {images.length !== 0 && <BtnSlider moveSlide={nextSlide} direction={'next'}/>}
              {images.length !== 0 && <BtnSlider moveSlide={prevSlide} direction={'prev'}/>}
              
              <div className="container-dots" style={{zIndex:2}}>
                  {Array.from({length: images.length}).map((obj, idx) =>(
                      <div key={idx} className={slideIndex === idx+1 ? 'dot active' : 'dot'} onClick={() => setSlideIndex(idx + 1)}></div>
                  )
                  )}
              </div>

              <DeleteProPicModal userData={userData} modalOpen={!!deleteImgModalOpen} setModalOpen={setDeleteImgModalOpen} image={selectedImage} setImage={setSelectedImage}/>

              
            
        </div>
    )
}

export default Sliderr
