import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Grid, Button, InputBase, Typography, Avatar, Tooltip, IconButton, Hidden, withWidth, Modal} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { firestore } from '../../../config/fbConfig';
import AdminUserInfoModal from '../../modals/AdminUserInfoModal';
import ClearIcon from '@material-ui/icons/Clear'
import { ProspectCreator } from '../../modals/CreateAProspect/ProspectCreate';
import CreateBrandAmModal from '../../modals/CreateBrandAmModal';


const Users = ({currentUser, userData, width}) => {
    const [rows, setRows] = useState([])
    const [userType, setUserType] = useState(null)
    const [selectedUsers, setBulkUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingUser, setLoadingUser] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [prospectModalOpen, setProspecttModalOpen] = useState(false)
    const [brandAmModal, setBrandAmModal] = useState(false)
    const [selectedUser, setUser] = useState(null)
    const path = window.location.pathname.split('/')
    const pathName = path[3]
    const columns = (pathName === 'admins') ? [
        { field: 'fullName', headerName: 'Name', description: 'This column has a value getter and is not sortable.', width: 290},
        { field: 'email', headerName: 'Email', width: 220 },
        // { field: 'phone', headerName: 'Phone', width: 120 },
        { field: 'role', headerName: 'Role', width: 130 },
        // { field: 'marks', headerName: 'Marks', width: 130 },
        // { field: 'status', headerName: 'Status', width: 130 },
        { field: 'id', headerName: 'User Id', width: 300 },
      ]
      :
      [
        { field: 'firstName', headerName: 'First name', width: 160 },
        { field: 'lastName', headerName: 'Last name', width: 160 },
        { field: 'email', headerName: 'Email', width: 220 },
        { field: 'phone', headerName: 'Phone', width: 140 },
        { field: 'rating', headerName: 'Rating', width: 130 },
        { field: 'marks', headerName: 'Marks', width: 130 },
        { field: 'status', headerName: 'Status', width: 130 },
        { field: 'id', headerName: 'User Id', width: 300 },
      ];

      const padding = (width === 'xs' || width === 'sm') ? '0px 14px 20px 0px' : '0'
      const justify1 = (width === 'xs' || width === 'sm') ? 'flex-end' : 'space-between'
      const justify2 = (width === 'xs' || width === 'sm') ? 'flex-end' : null
      const headerSize = (width === 'xs' || width === 'sm') ? 'h5' : 'h3'


    useEffect(() => {

       if(userData.collection === 'admins' && pathName === 'pros'){
           setUserType('Pro')
           if(loading){
               firestore.collection('professionals').limit(300).get().then((querySnapshot) => {
                setRows(querySnapshot.docs.map(doc => {
                    const user = doc.data()
                    // console.log(user.ratings)
                    return({firstName: user.firstName, lastName: user.lastName, email: user.email, phone: user.phone, 
                        rating: ((user.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0)) / user.ratings.length).toFixed(2), 
                        // rating: user.ratings,
                        marks: user.derogatoryMarks ? user.derogatoryMarks.length : 0, status: user.accountStatus, id: user.userId})
                    }))
                })
                setLoading(false)
           }
            console.log(pathName)            
        }else if(userData.collection === 'admins' && pathName === 'clients'){
            setUserType('Client')
            if(loading){
                firestore.collection('customers').get().then((querySnapshot) => {
                    setRows(querySnapshot.docs.map(doc => {
                        const user = doc.data()
                        return({firstName: user.firstName, lastName: user.lastName, email: user.email, phone: user.phone, 
                            // rating: ((user.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0)) / user.ratings.length).toFixed(2), 
                            rating: 'n/a',
                            marks: user.derogatoryMarks ? user.derogatoryMarks.length : 0, status: user.accountStatus, id: user.userId})
                    }))
                }) 
                setLoading(false) 
            }
            console.log(pathName)            
        }else if(userData.collection === 'admins' && pathName === 'admins'){
            setUserType('Admin')
            if(loading){
                firestore.collection('admins').get().then((querySnapshot) => {
                    setRows(querySnapshot.docs.map(doc => {
                        const user = doc.data()
                        return({fullName: user.name, email: user.email, role: user.role, id: user.userId})
                    }))
                })  
                setLoading(false)
            }
            console.log(pathName)            
       }else{
        window.location.assign('/home')
       }

       if(selectedUser && loadingUser){
           setLoadingUser(false)
           setModalOpen(true)
       }


    }, [userData, pathName, selectedUser, loadingUser,rows, loading])

    const handleOpenModal = (id) => {
        setLoadingUser(true)
        switch (pathName) {
            case "clients":
                firestore.collection('customers').doc(id)
                    .get().then((doc) => {
                        if (doc.exists) {
                            setUser(doc.data())
                        } else {
                            console.log("No such document!");
                        }
                    }).catch((error) => {
                        console.log("Error getting document:", error);
                    });
                break;
            case "pros":
                firestore.collection('professionals').doc(id)
                    .get().then((doc) => {
                        if (doc.exists) {
                            setUser(doc.data())
                        } else {
                            console.log("No such document!");
                        }
                    }).catch((error) => {
                        console.log("Error getting document:", error);
                    });
                break;
            case "admins":
                firestore.collection('admins').doc(id)
                    .get().then((doc) => {
                        if (doc.exists) {
                            setUser(doc.data())
                        } else {
                            console.log("No such document!");
                        }
                    }).catch((error) => {
                        console.log("Error getting document:", error);
                    });
                break;
            
            default:
                break;
        }
        
    }

    const handleClose = () => {
        setModalOpen(!modalOpen)
        setUser(null)
    }

    const handleDelete = (id) => {
        switch (pathName) {
            case 'pros':
                firestore.collection("professionals").doc(id).delete().then(() => {
                    alert("Account successfully deleted!");
                })
                .then(() => {
                    handleClose()
                    setLoading(true) 
                })
                .catch((error) => {
                    console.error("Error removing document: ", error);
                });
                break;
            case 'clients':
                firestore.collection("customers").doc(id).delete().then(() => {
                    alert("Account successfully deleted!");
                })
                .then(() => {
                    handleClose()
                    setLoading(true) 
                })
                .catch((error) => {
                    console.error("Error removing document: ", error);
                });
                break;
        
            default:
                break;
        }
        
    }

    const handleDeleteMulti = () => {
        switch (pathName) {
            case 'pros':
                selectedUsers.map((id, idx) => {
                    firestore.collection("professionals").doc(id).delete().then(() => {
                    })
                    .then(() => {
                        alert("Account(s) successfully deleted!");
                        handleClose()
                        setLoading(true) 
                    })
                    .catch((error) => {
                        console.error("Error removing document(s): ", error);
                    });
                })
                break;
            case 'clients':
                selectedUsers.map((id, idx) => {
                firestore.collection("customers").doc(id).delete().then(() => {
                })
                .then(() => {
                    alert("Account(s) successfully deleted!");
                    handleClose()
                    setLoading(true) 
                })
                .catch((error) => {
                    console.error("Error removing document: ", error);
                });
                })
                break;
        
            default:
                break;
        }
    }

    const handleProspectModal = () => {
        setProspecttModalOpen(!prospectModalOpen)
    }


    return (
        <Grid item container xs={12} style={{height:'95vh', display:'flex', flexFlow:'column'}}>
            <Grid item container xs={12} style={{flex:'0 1 100px', display:'flex', justifyContent: justify1, alignItems:'center', padding:'0px 20px'}}>
                <Typography variant={headerSize} style={{fontWeight:'bold', marginRight: 10}}>Users > <span>{userType}s</span></Typography>
                {userType === 'Pro' &&
                <div>
                <Button variant="contained" color="primary" style={{height:'40px'}} onClick={handleProspectModal}>Create New {userType}</Button>
                <Button variant="outlined" color="primary" style={{height:'40px'}} onClick={()=>{setBrandAmModal(!brandAmModal)}}>Create New Brand</Button>
                </div>
                }
                {userType === 'Admin' &&
                <Button variant="contained" color="primary" style={{height:'40px'}}>Add {userType}</Button>
                }
            </Grid>
            <Grid container item xs={12} style={{flex:'1 0 auto', display:'flex', alignItems:'center', padding:padding, justifyContent:justify2}}>
                <Grid container item xs={10} md={12} style={{padding:20, backgroundColor:'#e6e6e6', borderRadius:12, height:'100%'}}>
                    {selectedUsers.length >= 1 ?
                        <Grid container style={{display:'flex', justifyContent:'flex-end'}}>
                            {selectedUsers.length === 1 &&
                            <Button variant='contained' style={{marginRight:5}} onClick={()=>handleOpenModal(selectedUsers[0])}>Update</Button>
                            }
                            {(userData.role === 'super admin' && selectedUsers.length > 1) && 
                            <Button variant='contained' style={{marginRight:5}} onClick={()=>setEditModalOpen(!editModalOpen)}>Mass Update</Button>
                            }
                            <Button variant='contained' style={{backgroundColor:'red', color:'white'}} onClick={()=>handleDeleteMulti(selectedUsers)}>Delete</Button>
                        </Grid>
                        :
                        <div style={{height:40, width:'inherit'}}></div>
                    }
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        checkboxSelection
                        onSelectionModelChange={(selectionModel)=> setBulkUsers(selectionModel)}
                        onRowDoubleClick={(row) => handleOpenModal(row.id)}
                    />
                </Grid>
            </Grid>

            <AdminUserInfoModal modalOpen={modalOpen} handleClose={handleClose} user={selectedUser} userType={userType} handleDelete={handleDelete} setLoading={setLoading} loading={loading}/>
            {/* <MassUserUpdateModal modalOpen={editModalOpen} handleClose={handleClose} users={selectedUsers} userType={userType} handleDelete={handleDelete} setLoading={setLoading} loading={loading}/> */}
            
            <Modal open={!!prospectModalOpen} onClose={handleProspectModal}>
              
              <Grid container xs={10} sm={6} md={5} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: `#413e68`, color: `white`, height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}> 
                        Create A New Prospect
                    </Typography>
                    <IconButton onClick={handleProspectModal} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
  
                <Grid item xs={12} container style={{padding:20, maxHeight:'80vh', overflowY:'scroll'}}>
  
                  <Grid item xs={12} style={{marginTop:10}}>
                    <ProspectCreator 
                      handleProspectModal={handleProspectModal}/>
                  </Grid>
  
                  </Grid>
  
              </Grid>
            </Modal>
            
            <Modal open={!!prospectModalOpen} onClose={handleProspectModal}>
              
              <Grid container xs={10} sm={6} md={5} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: `#413e68`, color: `white`, height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}> 
                        Create A New Prospect
                    </Typography>
                    <IconButton onClick={handleProspectModal} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
  
                <Grid item xs={12} container style={{padding:20, maxHeight:'80vh', overflowY:'scroll'}}>
  
                  <Grid item xs={12} style={{marginTop:10}}>
                    <ProspectCreator 
                      handleProspectModal={handleProspectModal}/>
                  </Grid>
  
                  </Grid>
  
              </Grid>
            </Modal>

            <CreateBrandAmModal modalOpen={brandAmModal} setModalOpen={setBrandAmModal}/>
        </Grid>
    )
}

Users.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(Users)
