import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {Grid, Button, InputBase, Typography, Avatar, FormControl, IconButton, InputLabel, MenuItem, Select, withWidth, Modal,makeStyles} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear'
import RatingAndReviewModal from './RatingAndReviewModal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

function AppointmentInfoModal({modalOpen, setModalOpen, selectedAppt, setSelectedAppt, userData}) {
    // const {id, docData} = selectedAppt

    useEffect(() => {
     console.log(selectedAppt)
    }, [selectedAppt])
    
    const  [rrModalOpen, setRRModalOpen]= useState(false)
    return (
        <Modal open={!!modalOpen}>
        {selectedAppt !== null &&

        <Grid container item xs={11} sm={6} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
            <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Details
                </Typography>
                <IconButton onClick={() => {setModalOpen(!modalOpen); setSelectedAppt(null)}} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid container item xs={12} style={{backgroundColor: '#cdecff', padding: 20, display:'flex', alignItems:'center'}}>
                <Grid item container xs={6}>
                    <Typography style={{fontFamily:'sarina, cursive', fontSize:24, color:'#413e68'}}>Cuti</Typography>
                </Grid>
                <Grid item container xs={6} style={{textAlign:"right"}}>
                    <Typography style={{width:'inherit', fontSize:10}}>Total: <span style={{fontWeight:'bold'}}>${selectedAppt.docData.amtPaid}</span></Typography>
                    <Typography style={{width:'inherit', fontSize:10}}>{new Date(selectedAppt.docData.serviceTimeEnd.seconds*1000).toDateString()}</Typography>
                </Grid>
                <Typography variant='h5' style={{width:'100%', marginTop:30, fontWeight:'bold'}}>Thanks for booking,</Typography>
                <Typography variant='h5' style={{width:'100%', marginBottom:10, fontWeight:'bold'}}>{userData.firstName}</Typography>
                <Typography style={{width:'100%'}}>We glad to have you as a valued member of our community.</Typography>
            </Grid>



            <Grid item container xs={12} style={{padding:20, maxHeight:'50vh', overflowY:'scroll'}}>

                <Grid container item xs={12} style={{padding:30, display:'flex', justifyContent:'space-between', borderBottom:'1px solid #413e68'}}>
                    {userData.collection === 'professionals' ? 
                    <Typography style={{width:'100%', fontSize:12}}>Service provided to:</Typography>
                    :
                    <Typography style={{width:'100%', fontSize:12}}>Service provided by:</Typography>
                    }

                    {userData.collection === 'professionals' ? 
                    <Grid container item xs={12} style={{display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'space-between'}}>
                        <div style={{display:'flex', flexFlow:'row', alignItems:'center'}}>
                            <Avatar src={selectedAppt.docData.clientAvatar}/>
                            <Typography style={{fontWeight:'bold', marginLeft:10}}>{selectedAppt.docData.clientName}</Typography>
                        </div>
                        {(selectedAppt.docData.clientRating === undefined || selectedAppt.docData.clientRating === 'n/a') ?
                        <Button variant='contained' color='primary' disabled={selectedAppt.docData.serviceRating !== 'n/a'} onClick={()=> setRRModalOpen(true)}>Review</Button>
                        :
                        <Typography style={{display:'flex'}}>Rated <CheckCircleOutlineIcon/></Typography>
                        }
                    </Grid>
                    :
                    <Grid container item xs={12} style={{display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'space-between'}}>
                        <div style={{display:'flex', flexFlow:'row', alignItems:'center'}}>
                            <Avatar src={selectedAppt.docData.proAvatar}/>
                            <Typography style={{fontWeight:'bold', marginLeft:10}}>{selectedAppt.docData.proName}</Typography>
                        </div>
                        {(selectedAppt.docData.serviceRating === 'n/a')?
                        <Button variant='contained' color='primary' disabled={selectedAppt.docData.serviceRating !== 'n/a'} onClick={()=> setRRModalOpen(true)}>Review</Button>
                        :
                        <Typography style={{fontWeight:'bold', marginLeft:10}}>Rated: {selectedAppt.docData.serviceRating}.00</Typography>
                        }
                    </Grid>
                    }

                    <Grid container item xs={12} style={{marginTop:10, display:'flex', justifyContent:'space-between'}}>
                        <Typography style={{}}>Service:</Typography>
                        <Typography style={{}}>{selectedAppt.docData.service}</Typography>
                    </Grid>
                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between', marginTop:10}}>
                        <Typography style={{}}>Service Cost:</Typography>
                        <Typography style={{}}>${selectedAppt.docData.serviceFee}</Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} style={{padding:30, display:'flex', justifyContent:'space-between', borderBottom:'1px solid lightgray'}}>
                    <Typography variant='h5' style={{fontWeight:'bold'}}>Total:</Typography>
                    <Typography variant='h5' style={{fontWeight:'bold'}}>${selectedAppt.docData.amtPaid}</Typography>
                </Grid>



                <Grid container item xs={12} style={{padding:30, borderBottom:'1px solid lightgray'}}>
                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography style={{fontWeight:'bold'}}>Sub Total:</Typography>
                        <Typography style={{fontWeight:'bold'}}>${selectedAppt.docData.serviceFee}</Typography>
                    </Grid>

                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between', marginTop:10}}>
                        <Typography style={{}}>Tip:</Typography>
                        <Typography style={{}}>${selectedAppt.docData.tip !== undefined ? selectedAppt.docData.tip : "0.00"}</Typography>
                    </Grid>
                    
                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography style={{}}>Travel/Convenience Fee:</Typography>
                        <Typography style={{}}>${selectedAppt.docData.serviceFeeTravel}</Typography>
                    </Grid>

                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography style={{}}>Booking Fee:</Typography>
                        <Typography style={{}}>$-{selectedAppt.docData.serviceFeeBooking}</Typography>
                    </Grid>

                    <Grid container item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                        <Typography style={{}}>Stripe Fee:</Typography>
                        <Typography style={{}}>${selectedAppt.docData.tip !== undefined ?
                         (parseFloat(selectedAppt.docData.amtPaid) - (parseFloat(selectedAppt.docData.tip) + parseFloat(selectedAppt.docData.serviceFeeBooking) + parseFloat(selectedAppt.docData.serviceFeeTravel) + parseFloat(selectedAppt.docData.serviceFee))).toFixed(2)
                         : 
                         "0.00"
                         }</Typography>
                    </Grid>
                    
                </Grid>
                
                {userData.collection === 'professionals' ? 
                <Grid container item xs={12} style={{padding:30, display:'flex', justifyContent:'space-between'}}>
                    <Typography style={{fontWeight:'bold'}}>Your Earnings:</Typography>
                    <Typography style={{fontWeight:'bold'}}>${(parseFloat(selectedAppt.docData.serviceFee) + parseFloat(selectedAppt.docData.serviceFeeTravel) + parseFloat(selectedAppt.docData.tip !== undefined ? selectedAppt.docData.tip : "0")).toFixed(2)}</Typography>
                </Grid>
                :
                <Grid container item xs={12} style={{padding:30, display:'flex', justifyContent:'space-between'}}>
                    <Typography style={{fontWeight:'bold'}}>Amount Charged:</Typography>
                    <Typography style={{fontWeight:'bold'}}>${selectedAppt.docData.amtPaid}</Typography>
                </Grid>
                }
                
                <Grid container item xs={12} style={{padding:30, display:'flex', justifyContent:'flex-end'}}>
                    <Button variant='contained' color='primary' style={{width:100}}>Help</Button>
                </Grid>
            </Grid>

            <RatingAndReviewModal modalOpen={rrModalOpen} setModalOpen={setRRModalOpen} selectedAppt={selectedAppt} userData={userData}/>
            

          {/* <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={severity[1]}>
                  Password Updated Successfully!
              </Alert>
          </Snackbar> */}
            
        </Grid>

        }      


    </Modal>
    )
}

export default AppointmentInfoModal
