import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Checkbox, FormControlLabel, FormGroup, Tooltip, IconButton, Modal, TextField, TextareaAutosize, Switch} from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import {firestore} from '../../config/fbConfig'
import firebase from 'firebase';

import { id } from 'date-fns/locale';
const { ms, s, m, h, d } = require('time-convert')

const useStyles = makeStyles((theme) => ({
  info: {
      '&:hover':{
        cursor:'pointer'
      }
    },

}));

const numsOnly = (e) => {
  const re = /[0-9]+/g;
  const reg = /[0-9.]+/g;
  if(e.target.name !== 'price'){
    if (!re.test(e.key)) {
      e.preventDefault();
    }
    if(e.target.value.length >= 2){
      e.preventDefault()
    }
  }else{
    if (!reg.test(e.key)) {
      e.preventDefault();
    }
  }
}

function ServiceCard(props) {
  const classes = useStyles()
  const {size, width, service, pro, convertedTime, time, price, name, serviceType, selectedService,
      setSelectedService, handleBookingModalOpen,authModal, handleCloseModal, userData, setShowLogin} = props
  const [isModal2Open, setModal2Open] = useState(false)

  const [isSelected, setIsSelected] = useState(false)
  const selectedBorder = !!isSelected ? "#cacaca" : 'white'
  const [showInfo, setShowInfo] = useState(false)
  const [newPrice, setNewPrice] = useState('')
  const [newHrs, setNewHours] = useState([])
  const [newMins, setNewMins] = useState([])
  const [details, setDetails] = useState('')
  const [depositChecked, setDepositChecked] = useState(true)
  const [addOnChecked, setAddOnChecked] = useState(false)
  const depositSwitchLabel = (depositChecked === true) ? "Yes" : "No"
  const addOnSwitchLabel = (addOnChecked === true) ? "Yes" : "No"
  const [pricesVary, setPricesVary] = useState(false)
  const [virtual, setVirtual] = useState(false)
  const [zoomLink, setZoomLink] = useState("")
  const [reasonInvalid, setReasonInvalid] = useState("")


    const handleSelected = (service) => {
        setSelectedService(service)
    }

    const handleModal2Open = () => {
      setModal2Open(!isModal2Open)
    }

    const handleModal2Close = () => {
      setModal2Open(!isModal2Open)
      setSelectedService(null)
      setPricesVary(false)
    }

    const toggleAddOnChecked = () => {
      setAddOnChecked(!addOnChecked)
      setDepositChecked(false)
    }

    const toggleDepositChecked = () => {
      setDepositChecked(!depositChecked)
    }

    const handleUpdateService = () => {
      const newTime = parseFloat((h.to(m)(parseFloat(newHrs)))) + (parseFloat(newMins))
      
      var serviceRef = firestore.collection("professionals").doc(`${pro.userId}`);
    
      serviceRef.update({
          services: firebase.firestore.FieldValue.arrayRemove(selectedService)
      })
      serviceRef.update({
          services: firebase.firestore.FieldValue.arrayUnion({
            name: selectedService.name, serviceType:selectedService.serviceType, 
            time: isNaN(newTime) ? selectedService.time : newTime, 
            price: newPrice === '' ? selectedService.price : newPrice, 
            details: (details === '' && selectedService.details) ? selectedService.details : details,
            reqDeposit: (addOnChecked === true) ? false : depositChecked,
            addOn: depositChecked === true ? false : addOnChecked,
            priceVaries: ((newPrice === '' && parseFloat(selectedService.price) === 0) || parseFloat(newPrice)=== 0) ? false : pricesVary,
            virtual: selectedService.virtual === undefined ? virtual : virtual !== selectedService.virtual ? virtual : selectedService.virtual,
            zoomLink: selectedService.zoomLink === undefined ? zoomLink !== "" ? zoomLink : "" : zoomLink !== "" ? zoomLink : selectedService.zoomLink
          })
      })
     //.then(() => console.log('Successfully updated document!'))
      // .then(() => window.location.reload())
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        // setOpenErr(true)
      });
    }

    const handleRemoveService = () => {
      var serviceRef = firestore.collection("professionals").doc(`${pro.userId}`);
      serviceRef.update({
          services: firebase.firestore.FieldValue.arrayRemove(selectedService)
      })
      // .then(() => {}// console.log('Successfully deleted document!')
      // )
      // .then(() => window.location.reload())
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
    }

    useEffect(() => {
      if(zoomLink.length !== 0 && reasonInvalid === ""){
        if(zoomLink.includes("https") === false){
          setReasonInvalid("Please include: https://")
          
        }else if(zoomLink.includes("zoom.us") === false){
          setReasonInvalid("Please use a Zoom link")
        }
      }else if(reasonInvalid.length!==0 && zoomLink.includes("https") && zoomLink.includes("zoom.us")){
        setReasonInvalid("")
      }


    }, [reasonInvalid, zoomLink])
    

    return (
            <Grid container item xs={12} style={{marginBottom:10, display:'flex', minHeight:80, alignItems:'center', backgroundColor:'white', borderRadius:5, padding:8, border:'1px solid lightgray'}}>
              <Grid item contianer xs={8}>
                <Typography style={{fontWeight:'bold'}}>{name} - {parseFloat(price) === 0 ? "FREE" : `$${price}`} 
                {(service.priceVaries !== undefined && service.priceVaries === true) && 
                <span style={{fontSize:12, fontWeight:'normal'}}>{' (and up)'}</span>}
                </Typography>
                <Typography style={{fontSize:12, color:'grey'}}>{serviceType} - {parseInt(time/60) === 0 ? time%60 + "mins" : parseInt(time/60) === 1 ? time%60 === 0 ? parseInt(time/60)+"hr" : parseInt(time/60)+"hr " + time%60 + "mins" : time%60 === 0 ? parseInt(time/60)+"hrs" : parseInt(time/60)+"hrs " + time%60 + "mins"}</Typography>
                {service.details ? 
                <Typography className={classes.info} style={{fontSize:12, fontWeight:'bold', color:'#413e68'}} onClick={() => setShowInfo(!showInfo)}>MORE INFO</Typography>
                :
                <Typography style={{fontSize:12, fontWeight:'bold', color:'#413e68', opacity:.5}}>MORE INFO</Typography>
                }
              </Grid>

              <Grid item container xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                {userData && userData.userId ? 
                userData.userId === pro.userId ?
                <div>
                   <IconButton style={{height:35, width:35, backgroundColor:`lightgrey`, color:`white`}} onClick={()=> {setSelectedService(service); setPricesVary(service.priceVaries !== undefined ? service.priceVaries : false); handleModal2Open()}}><EditIcon/></IconButton>
                   <IconButton style={{height:35, width:35, marginLeft:5, backgroundColor:`red`, color:`white`}} onClick={()=> {setSelectedService(service); handleRemoveService()}}><DeleteIcon/></IconButton>
                </div>
                :
                <Button onClick={() => {handleSelected(service); handleBookingModalOpen()}} color='primary' variant='contained' style={{fontSize:12, fontWeight:'bold'}}>Book</Button>
                :
                <Button onClick={() => {handleSelected(service); handleBookingModalOpen()}} color='primary' variant='contained' style={{fontSize:12, fontWeight:'bold'}}>Book</Button>
                // <Button onClick={() => {setShowLogin(true)}} color='primary' variant='contained' style={{fontSize:12, fontWeight:'bold'}}>Book</Button>
                }
              </Grid>

              {showInfo === true ? 
              <Typography style={{width:'inherit', marginTop:20, whiteSpace:'pre-line'}}>{service.details}</Typography> : <div style={{width:'inherit', marginTop:20}}></div>
              }

          {((userData.userId === pro.userId) && selectedService) && 
            <Modal open={!!isModal2Open} onClose={handleModal2Close}>
              
              <Grid container xs={10} sm={6} md={5} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Edit Service Details
                    </Typography>
                    <IconButton onClick={handleModal2Close} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={12} container style={{padding:20, maxHeight:'65vh', overflowY:'scroll'}}>
                  
                  <Grid item xs={12} className={classes.selectedServiceDetails}>
                    <Typography>Category: <span style={{fontWeight:'bold'}}>{selectedService.serviceType}</span></Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.selectedServiceDetails}>
                    <Typography>Name: <span style={{fontWeight:'bold'}}>{selectedService.name}</span></Typography>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails}>
                    <Grid item xs={12} md={6}>
                      <Typography>Appointment Time:</Typography>
                    </Grid>

                    <Grid item container xs={12} md={6} style={{paddingRight:10}}>
                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Hrs"
                            name="hours"
                            defaultValue={Math.floor(selectedService.time/60)}
                            // value={newHrs}
                            //placeholder={time[0]}
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setNewHours(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>

                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Mins"
                            name="mins"
                            defaultValue={selectedService.time - (Math.floor(selectedService.time/60)*60)}
                            // value={newMins}
                            //placeholder={time[1]}
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setNewMins(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={6}>
                      <Typography style={{width:'100%'}}>Price
                      <Tooltip title="Selecting the 'Varies' checkbox allows you to create a general service price, and update the price later after confirming exactly what your client needs.">
                        <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                        </Tooltip>:
                      </Typography>
                         
                         {((newPrice !== "" || parseFloat(selectedService.price)!== 0) && (parseFloat(newPrice)!== 0) )&&
                          <FormGroup>
                          <FormControlLabel
                          control={
                            <Checkbox
                              checked={pricesVary}
                              color='primary'
                              onChange={()=> setPricesVary(!pricesVary)}
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              name="vary"
                            />
                          }
                          label="Varies"
                        />
                      </FormGroup>
                         }
                    </Grid>

                    <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center'}}>
                          <div style={{marginRight:10, fontSize:18}}>$</div>
                         {pricesVary === false ?
                          <TextField
                            label="Price"
                            name="price"
                            defaultValue={selectedService.price}
                            // value={newPrice}
                            placeholder={`${selectedService.price}`}
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setNewPrice(e.target.value); console.log(newPrice)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                          :
                          <div style={{width:'inherit', display:'flex', flexFlow:'row', justifyContent:'space-evenly', alignItems:'center'}}>
                            <TextField
                              label="Price"
                              name="price"
                              style={{width:'70%'}}
                              defaultValue={selectedService.price}
                              placeholder={`${selectedService.price}`}
                              onKeyPress={(e) => numsOnly(e)}
                              onChange={(e) => {setNewPrice(e.target.value)}}
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              required
                            />
                            <Typography style={{fontSize:10}}> and up</Typography>
                          </div>
                          }
                    </Grid>

                    <Typography style={{width:'inherit'}}>Details:</Typography>
                    <TextareaAutosize
                              style={{padding:5, fontSize: 16, font:'inherit', borderColor:'silver', borderRadius:4, width:'inherit'}}
                              rowsMax={3}
                              rowsMin={3}
                              label="details"
                              name="details"
                              defaultValue={selectedService.details ? selectedService.details : ""}
                              // value={newMins}
                              //placeholder={time[1]}
                              onChange={(e) => {setDetails(e.target.value)}}
                              
                            
                              required
                              fullWidth
                            />
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.selectedServiceDetails} style={{marginTop:20}}>
                    {/* <Grid item xs={6} style={{marginTop:20}}>
                        <Typography>Is this an Add-on service?</Typography>
                      </Grid>

                      <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'flex-end', marginTop:20}}>
                        <FormControlLabel 
                            control={<Switch size="normal" color="primary" checked={addOnChecked} onChange={toggleAddOnChecked} />}
                            label={addOnSwitchLabel}
                            />
                      </Grid> */}
                      {addOnChecked===false &&
                      <Grid item xs={6} style={{marginTop:20}}>
                        <Typography>Require Deposit?
                      <Tooltip title="Require your customer to pay 25% down when booking.">
                        <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                        </Tooltip>
                      </Typography>
                      </Grid>
                      }
                      {addOnChecked===false &&
                      <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'flex-end', marginTop:20}}>
                        <FormControlLabel 
                            control={<Switch size="normal" color="primary" checked={depositChecked} onChange={toggleDepositChecked} />}
                            label={depositSwitchLabel}
                            />
                      </Grid>
                      }

                      <Grid item xs={12} md={6}>
                          <FormGroup>
                          <FormControlLabel
                          control={
                            <Checkbox
                              checked={virtual}
                              color='primary'
                              onChange={()=> setVirtual(!virtual)}
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              name="vary"
                            />
                          }
                          label="Virtual Service"
                        />
                      </FormGroup>
                        </Grid>

                      {virtual === true &&
                        <Grid item container xs={12} md={6} style={{padding:'0px 10px', display:'flex', flexFlow:'column', alignItems:'center'}}>
                              <TextField
                                label="Zoom Link"
                                name="zoomlLink"
                                defaultValue={selectedService.zoomLink ? selectedService.zoomLink : zoomLink}
                                // value={zoomLink}
                                placeholder="https://us05web.zoom.us/j/{PERSONAL_METTING_ID}}"
                                onChange={(e) => {setZoomLink(e.target.value)}}
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                required
                                fullWidth
                              />
                              {reasonInvalid.length !== 0 &&
                              <Typography style={{width:'100%', color:'red', fontSize:14, lineHeight:'1',marginBottom:30}}>Invalid URL: {reasonInvalid}</Typography>
                              }
                        </Grid>
                      }

                  </Grid>


                  <Grid item xs={12} container className={classes.selectedServiceDetails} style={{marginBottom: 40}}>
                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" fullWidth onClick={handleModal2Close}>Cancel</Button>
                    </Grid>

                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" color="primary" fullWidth onClick={handleUpdateService}>Save</Button>
                    </Grid>
                  </Grid>
      
                </Grid>
                

              </Grid>
            </Modal>
            }

            </Grid>

    )
}

export default ServiceCard
