import React, {useRef, useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Avatar, IconButton, Modal, Snackbar, Slider, CircularProgress} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-credit-cards/es/styles-compiled.css'
import { firestore, storage } from '../../../config/fbConfig';
import firebase from 'firebase';
import { useSelector } from 'react-redux';
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../modals/canvasUtils'
import Cropper from 'react-easy-crop'


const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    rootGrid: {
      display: 'flex',
      justifyContent:'center',
      minWidth:280,
    },
    sectionTitle:{
        fontSize: 24,
        fontWeight: 'bold',
        margin: "20px 0 10px",
    },
    sectionTitleSmall:{
        fontSize: 18,
        fontWeight: 'bold',
        margin: "20px 0 10px",
        width: 'inherit'
    },
    containerBox:{
        backgroundColor:'white',
        borderRadius:'8px',
        padding: 20,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'relative'
    }, 
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
          height: 400,
        },
      },
      cropButton: {
        flexShrink: 0,
        marginLeft: 16,
      },
      controls: {
        width:'100%',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
      },
      sliderLabel: {
        [theme.breakpoints.down('xs')]: {
          minWidth: 65,
        },
      },
      slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
          margin: '0 16px',
        },
      },  
  }));

function PaymentInfo(props) {
    const {userData, width} = props
    const classes = useStyles()
    const fileInputCA = useRef();
    const fileInputPP = useRef();
    const fileInputZ = useRef();
    const fileInputV = useRef();
    const [typeOfQR, setTypeOfQR] = useState('')
    const [imageSrc, setImageSrc] = React.useState(null)
    const padding = width > 'md' ? '0px 10px 0px 60px' : 0
    const [imageLoading, setImageLoading] = useState(false);

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const [openSuccess, setOpenSuccess] = useState(false)
    const [openErr, setOpenErr] = useState(false)
    const severity = ["error", "success"]



    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          let imageDataUrl = await readFile(file)
      
          // apply rotation if needed
          const orientation = await getOrientation(file)
          const rotation = ORIENTATION_TO_ANGLE[orientation]
          if (rotation) {
            imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
          }
      
          setImageSrc(imageDataUrl)
        }
      }

    const handleAddCashApp = () => {
        fileInputCA.current.click()
    }

    const handleAddPayPal = () => {
        fileInputPP.current.click()
    }

    const handleAddZelle = () => {
        fileInputZ.current.click()
    }

    const handleAddVenmo = () => {
        fileInputV.current.click()
    }

    const handleRemove = () => {
      var userRef = firestore.collection(userData.collection).doc(userData.userId)

      if(typeOfQR === 'CashApp'){
        userRef.update({
          qrCashApp:''
        })
        setTypeOfQR('')
      }
      if(typeOfQR === 'PayPal'){
        userRef.update({
          qrPayPal:''
        })
        setTypeOfQR('')
      }
      if(typeOfQR === 'Zelle'){
        userRef.update({
          qrZelle:''
        })
        setTypeOfQR('')
      }
      if(typeOfQR === 'Venmo'){
        userRef.update({
          qrVenmo:''
        })
        setTypeOfQR('')
      }
    }

    const closeModal = () => {
        setImageSrc(null)
      }

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenSuccess(false);
    setOpenErr(false);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const uploadCroppedImage = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          )
      
          var xhr = new XMLHttpRequest();
          xhr.open('GET', `${croppedImage}`, true);
          xhr.responseType = 'blob';
          xhr.onload = function(e) {
            if (this.status === 200) {
              var myBlob = this.response;
              // myBlob is now the blob that the object URL pointed to.
                
                  let fileurl = ''
                  var uploadTask = storage.ref(`ProInfo/${userData.email}-${userData.firstName}-${userData.lastName}/images/STP/${typeOfQR}`).put(myBlob) //-------------------------------Add name of file!!
                  uploadTask.on('state_changed', 
                    (snapshot) => {
                      //Handle progress...
                      setImageLoading(true)
                    }, 
                    (error) => {
                      // Handle unsuccessful uploads...
                    }, 
                    async () => {
                      // Handle successful uploads on complete...
                      await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
                        fileurl = url
                        // console.log('File available at', fileurl)
                        setImageLoading(false)
                        closeModal()
                      })
                      .then(() => {
                        var userRef = firestore.collection(userData.collection).doc(userData.userId)
                        if(typeOfQR==='CashApp'){
                            return userRef.update({
                             qrCashApp: fileurl
                            })
                            .then(() => {
                                setOpenSuccess(!openSuccess)
                            })
                            .then(() => window.location.reload())
                            .catch((error) => {
                                console.error("Error updating document: ", error);
                                setOpenErr(!openErr)
                            });
                        }
                        if(typeOfQR==='PayPal'){
                            return userRef.update({
                             qrPayPal: fileurl
                            })
                            .then(() => {
                                setOpenSuccess(!openSuccess)
                            })
                            .then(() => window.location.reload())
                            .catch((error) => {
                                console.error("Error updating document: ", error);
                                setOpenErr(!openErr)
                            });               
                        }
                        if(typeOfQR==='Zelle'){
                            return userRef.update({
                             qrZelle: fileurl
                            })
                            .then(() => {
                                setOpenSuccess(!openSuccess)
                            })
                            .then(() => window.location.reload())
                            .catch((error) => {
                                console.error("Error updating document: ", error);
                                setOpenErr(!openErr)
                            });

                        }
                        if(typeOfQR==='Venmo'){
                            return userRef.update({
                             qrVenmo: fileurl
                            })
                            .then(() => {
                                setOpenSuccess(!openSuccess)
                            })
                            .then(() => window.location.reload())
                            .catch((error) => {
                                console.error("Error updating document: ", error);
                                setOpenErr(!openErr)
                            });

                        }
                      });
                    }
                  )
                
            }
          };
          xhr.send();
      
          // console.log(croppedImage)
          setCroppedImage(croppedImage)
      
      
        } catch (e) {
          console.error(e)
        }
      }, [imageSrc, croppedAreaPixels, rotation, userData, typeOfQR, openErr, openSuccess])


    

  return (
    <Grid container item xs={12} style={{padding:'0px 10px', alignContent:'center'}} direction="column" >

        <Grid item xs={12} md={8} container style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
            <Typography className={classes.sectionTitle}>Payment Info</Typography>
        </Grid>

        <Grid item xs={12} md={8} container className={classes.containerBox}>
            <Grid item container xs={6} md={9} style={{display:'flex', alignItems:'center'}}>
                <Typography style={{fontWeight:'bold', fontSize:20}}>Stripe</Typography>
                <Typography style={{fontSize:12, color:'#413e68', marginLeft:10}}>View Dashboard</Typography>
            </Grid>

            <Grid item container xs={6} md={3} style={{display:'flex', justifyContent:'flex-end'}}>
                <Typography style={{fontWeight:'bold', color:'green'}}>enabled</Typography>
            </Grid>

            <Grid item contianer xs={12}>
                <Typography style={{fontSize:12, marginLeft:10}}>
                Easily accept debit/credit within the app  and cash out your earnings when they become available.
                </Typography>
            </Grid>

            <Grid item container xs={6} md={9} style={{display:'flex', alignItems:'center'}}>
                <Typography style={{fontWeight:'bold', fontSize:20}}>Cash</Typography>
            </Grid>

            <Grid item container xs={6} md={3} style={{display:'flex', justifyContent:'flex-end'}}>
                <Typography style={{fontWeight:'bold', color:'green'}}>enabled</Typography>
            </Grid>

            <Grid item contianer xs={12}>
                <Typography style={{fontSize:12, marginLeft:10}}>
                For your safety, cash transactions are only enabled when providing services from your place of business. After the service is complete, simply click the “COMPLETE” button, then select “Client paid with cash” to finalize the transaction.
                </Typography>
            </Grid>
        </Grid>

        <Grid item xs={12} md={8} container>
            <Typography className={classes.sectionTitle}>Scan To Pay (STP)</Typography>
            <Typography style={{fontSize:12, marginLeft:10}}>
                Add QR codes from alternate payment platforms for immediate access to funds. <br/>
                Acceptable QR codes: <span style={{color:'#00ca45', fontWeight:'bold'}}>CashApp</span>, <span style={{color:'#25397c', fontWeight:'bold'}}>PayPal</span>, <span style={{color:'#6d1ed4', fontWeight:'bold'}}>Zelle</span>, or <span style={{color:'#3391c6', fontWeight:'bold'}}>Venmo</span>.
                <br/><br/>
                HOW TO USE: After the service is complete, click the “COMPLETE” button then select “Checkout Options” and choose the QR code that you wish to use. Once you have verified the funds have transferred, click “Done”.
            </Typography>
        </Grid>

        <Grid item xs={12} md={8} container className={classes.containerBox} style={{marginBottom:60}}>
            <Grid item container xs={8} md={5} style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:20}}>
                <Typography style={{fontWeight:'bold', fontSize:20}}>CashApp</Typography>
                {userData.qrCashApp === "" ?
                <Button variant='contained' color='primary' onClick={()=>{setTypeOfQR('CashApp'); handleAddCashApp()}}>Add</Button>
                :
                <IconButton variant='contained' color='primary' onClick={()=>{setTypeOfQR('CashApp'); handleRemove()}}><DeleteIcon/></IconButton>
                }            
            </Grid>

            <Grid item container xs={4} md={7} style={{display:'flex', justifyContent:'flex-end'}}>
                {userData.qrCashApp === "" ?
                <Typography style={{fontWeight:'bold', color:'lightgrey', textAlign:'end'}}>not enabled</Typography>
                :
                <Typography style={{fontWeight:'bold', color:'green', textAlign:'end'}}>enabled</Typography>
                }
            </Grid>


            <Grid item container xs={8} md={5} style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:20}}>
                <Typography style={{fontWeight:'bold', fontSize:20}}>PayPal</Typography>
                {userData.qrPayPal === "" ?
                <Button variant='contained' color='primary' onClick={()=>{setTypeOfQR('PayPal'); handleAddPayPal()}}>Add</Button>
                :
                <IconButton variant='contained' color='primary' onClick={()=>{setTypeOfQR('PayPal'); handleRemove()}}><DeleteIcon/></IconButton>
                }
            </Grid>

            <Grid item container xs={4} md={7} style={{display:'flex', justifyContent:'flex-end'}}>
                {userData.qrPayPal === "" ?
                <Typography style={{fontWeight:'bold', color:'lightgrey', textAlign:'end'}}>not enabled</Typography>
                :
                <Typography style={{fontWeight:'bold', color:'green', textAlign:'end'}}>enabled</Typography>
                }
            </Grid>


            
            <Grid item container xs={8} md={5} style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:20}}>
                <Typography style={{fontWeight:'bold', fontSize:20}}>Zelle</Typography>
                {userData.qrZelle === "" ?
                <Button variant='contained' color='primary' onClick={()=>{setTypeOfQR('Zelle'); handleAddZelle()}}>Add</Button>
                :
                <IconButton variant='contained' color='primary' onClick={()=>{setTypeOfQR('Zelle'); handleRemove()}}><DeleteIcon/></IconButton>
                }
            </Grid>

            <Grid item container xs={4} md={7} style={{display:'flex', justifyContent:'flex-end'}}>
                {userData.qrVenmo === "" ?
                <Typography style={{fontWeight:'bold', color:'lightgrey', textAlign:'end'}}>not enabled</Typography>
                :
                <Typography style={{fontWeight:'bold', color:'green', textAlign:'end'}}>enabled</Typography>
                }
            </Grid>


            <Grid item container xs={8} md={5} style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography style={{fontWeight:'bold', fontSize:20}}>Venmo</Typography>
                {userData.qrVenmo === "" ?
                <Button variant='contained' color='primary' onClick={()=>{setTypeOfQR('Venmo'); handleAddVenmo()}}>Add</Button>
                :
                <IconButton variant='contained' color='primary' onClick={()=>{setTypeOfQR('CashApp'); handleRemove()}}><DeleteIcon/></IconButton>
                }
            </Grid>

            <Grid item container xs={4} md={7} style={{display:'flex', justifyContent:'flex-end'}}>
                {userData.qrVenmo === "" ?
                <Typography style={{fontWeight:'bold', color:'lightgrey', textAlign:'end'}}>not enabled</Typography>
                :
                <Typography style={{fontWeight:'bold', color:'green', textAlign:'end'}}>enabled</Typography>
                }
            </Grid>
            
            <input style={{display: 'none'}} type="file" id="image" name="image" 
            onChange={onFileChange} ref={fileInputCA} accept=".gif,.jpg,.jpeg,.png"
                />

            <input style={{display: 'none'}} type="file" id="image" name="image" 
            onChange={onFileChange} ref={fileInputPP} accept=".gif,.jpg,.jpeg,.png"
                />

            <input style={{display: 'none'}} type="file" id="image" name="image" 
            onChange={onFileChange} ref={fileInputZ} accept=".gif,.jpg,.jpeg,.png"
            />

            <input style={{display: 'none'}} type="file" id="image" name="image" 
            onChange={onFileChange} ref={fileInputV} accept=".gif,.jpg,.jpeg,.png"
            />


        </Grid>




        <Modal open={imageSrc !== null} onClose={closeModal}>
              <Grid container item xs={10} sm={6}
                style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:'30px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none',  maxHeight:'90%'}}>
                    <div style={{width:'inherit', height:'inherit', position:'relative'}}>
                      <div className={classes.cropContainer}>
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={4 / 6}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                        />
                      </div>
                    </div>
                    <div className={classes.controls}>
                      <div className={classes.sliderContainer}>
                        <Typography
                          variant="overline"
                          classes={{ root: classes.sliderLabel }}
                        >
                          Zoom
                        </Typography>
                        <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          classes={{ root: classes.slider }}
                          onChange={(e, zoom) => setZoom(zoom)}
                        />
                      </div>
                      <div className={classes.sliderContainer}>
                        <Typography
                          variant="overline"
                          classes={{ root: classes.sliderLabel }}
                        >
                          Rotation
                        </Typography>
                        <Slider
                          value={rotation}
                          min={0}
                          max={360}
                          step={1}
                          aria-labelledby="Rotation"
                          classes={{ root: classes.slider }}
                          onChange={(e, rotation) => setRotation(rotation)}
                        />
                      </div>
                    </div>
                      <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
                        <Button variant='contained' color='secondary' style={{fontWeight:'bold', marginTop:20}} onClick={closeModal}>Cancel</Button>
                        {imageLoading === false ?
                        <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} 
                        // onClick={()=>uploadImg(previewCanvasRef.current, croppedAreaPixels)}
                        onClick={uploadCroppedImage}
                        >Upload</Button>
                        :
                        <div className={classes.wrapper}>
                        <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} disabled>Uploading...</Button>
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      </div> 
                        }

                      </Grid>

                </Grid>

      </Modal>

      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity[1]}>
              Your QR Code Was Uploaded Successfully!
          </Alert>
      </Snackbar>
      <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity[0]}>
          Oops, something went wrong...
          </Alert>
      </Snackbar>




    </Grid>
  )
}

PaymentInfo.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
  

export default withWidth()(PaymentInfo)