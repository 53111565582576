import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types';
import {Grid, Typography, Button, FormControl, IconButton, Modal, TextField,
    MenuItem, Select, InputLabel, withWidth} from '@material-ui/core'
import {auth} from '../../config/fbConfig'
import { Close, ReportProblem } from '@material-ui/icons'


function EmailVerified({currentUser, width}) {
    const [showMessage, setShowMessage]=useState(false)
    const [modalOpen, setModalOpen] = useState(true)

    const handleModalOpen = () => {
        setModalOpen(!modalOpen)
    }

    const handleShowMessage = () => {
        setShowMessage(true)
    }

    useEffect(() => {
      
    }, [showMessage])
  return (
    <Modal open={!!modalOpen} onClose={handleModalOpen}>

        <Grid item container style={{display:'flex', flexFlow:'column', backgroundColor:'#73c8fe', width:'100%', height:'fit-content', padding:10, justifyContent:'center', outline:'none'}}>
            <div style={{display:'flex', width:'100%', justifyContent:'flex-end'}}>
            <IconButton onClick={handleModalOpen}>
                <Close/>
            </IconButton>

            </div>
            {width <= 'lg' ?
            <div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <Typography><ReportProblem/> Your email is not verified. Please go to your email inbox, and verify your email.</Typography>
                <Button variant='contained' color='primary' style={{fontWeight:'bold', margin:'0px 10px'}} onClick={() => {auth.currentUser.sendEmailVerification(); handleShowMessage()}}>Resend Email</Button>
                <Button variant='contained' color='primary' style={{fontWeight:'bold'}} onClick={() => {window.location.reload()}}>Refresh</Button>
            </div>
            :
            <div style={{display:'flex', flexFlow:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>
                <Typography><ReportProblem/> Your email is not verified. Please go to your email inbox, and verify your email.</Typography>
                <div style={{width:'100%', display:'flex', flexFlow:'row', justifyContent:'center'}}>
                    <Button variant='contained' color='primary' style={{fontWeight:'bold', margin:'0px 10px'}} onClick={() => {auth.currentUser.sendEmailVerification(); handleShowMessage()}}>Resend Email</Button>
                    <Button variant='contained' color='primary' style={{fontWeight:'bold'}} onClick={() => {window.location.reload()}}>Refresh</Button>
                </div>
            </div>
            }

            {showMessage === true &&
            <Typography variant='subtitle2' style={{color:"#413e68", textAlign:'center', marginTop:5}}>Your Email has been sent. Check your inbox!</Typography>
            }
        </Grid>
    </Modal>
  )
}

EmailVerified.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(EmailVerified)