import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Typography, Grid, Container, TextField, Button, Avatar,
        Hidden, withWidth, Tooltip, Paper, InputBase, CircularProgress,
  ClickAwayListener,} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import firebase, {firestore} from '../../config/fbConfig';
import ChatWindow from './messages/ChatWindow';
import { NavLink } from 'react-router-dom';

import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import Conversation from './messages/Conversation';

import {useSelector} from 'react-redux';
import { setAppointments } from '../../auth/appointmentActions';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1000,
    borderRadius: 12,
    border: "1px solid #e0e0e0",
    position: 'relative',
    //marginTop:'20px',
    minHeight: 360,
    boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)',
    textAlign: 'left',
    display: 'flex',
    flexFlow:'column',
  },
  rootTitle:{
    borderRadius: '8px 8px 0 0',
    background: '#413e68',
    flex:'0 1 50px',
  },
  conversationContainer: {
    height:'inherit',
    borderRight:'1px solid lightgrey',
    display:'flex',
    flexFlow:'column',
  },
  conversationHeader: {
    height:'50px',
    borderBottom:'1px solid lightgrey',
    padding:'0px 5px',
    flex:'0 1 50px',
    display:'flex',
    justifyContent: 'space-between',
    // height:'inherit',
    alignItems:'center'
  },

  navBtn: {
    padding: "0",
    height: "100%",
    color: 'white',
  },
  container: {
    display:'flex',
    justifyContent:'flex-end',
    margin:'auto',
    paddingRight:12,
  },
  grid: {
    display:'flex',
    justifyContent:'center',
  },
  input: {
    flex:'0 1 auto',
    paddingLeft:'10px'
  },
  icon: {
    flex:'1 0 40px',
    // color: '#413e68',
    cursor:'pointer',
  },
  inputField: {
    border:'2px solid lightgray',
    borderRadius: 20,
    height: 40 ,
    width: 'inherit',
    paddingTop: 2,
    display:'flex',
    alignItems:'center',
    boxShadow: 'none',
  },
  chatArea:{
    height:'inherit',
    display:'flex',
    flexFlow:'column',
  },
  chatAreaHeader: {
    flex:'0 1 50px',
    backgroundColor:'lightgrey',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messagesContainer:{
    flex: '1 1 auto',
    padding: 10
  },
  message: {
    padding: 7,
    width:'fit-content',
    maxWidth:'50%',
    borderRadius:'25px',
    // backgroundColor:'lightgrey',
  },
  chatControls: {
    flex: '0 1 50px',
    borderTop: '1px solid lightgrey',
    padding: 5,
  },
}));


function Messages(props) {

  const {width, currentUser, userData,} = props
  const classes = useStyles();
  const [loading, setLoading] = useState(true)
  const [loadingCurrAppt, setLoadingCurrAppt] = useState(true)
  const [currentAppt, setCurrentAppt] = useState(null)
  const [conversationId, setConversationId] = useState(null)
  const [nameSearch, setNameSearch] = useState('')
  const [appointments, setAppointments] = useState([])

  const height = (width ==='xs' ||width === 'sm') ? "80vh" : 510
  const maxHeight = (width === "xs" || width === 'sm') ? '66vh' : 400
  // const [orderedAppts, setOrderedAppts] = useState([]) 

  // const uid = useSelector(state => state.auth.userData.userId)
  const cId = useSelector(state => state.appt.conversationId)

  
  useEffect(()=>{
    //var convoIds = []
    //const uid = userData.userId
    if(appointments.length > 0){
      setLoading(false)
      console.log("Appintments from Messages:", appointments)
    }
    if(userData.collection === 'customers'){
        firestore.collection('appointments').where('clientUID', '==', userData.userId)
        .where('serviceComplete', '==', false).where('serviceCanceled', '==', false)
        .onSnapshot((snapshot) => {
          setAppointments(snapshot.docs.map(doc => {return ({id:doc.id, docData:doc.data()})}))
      });

    }else if(userData.collection === 'professionals'){
      firestore.collection('appointments').where('proUID', '==', userData.userId)
      .where('serviceComplete', '==', false).where('serviceCanceled', '==', false)
      .onSnapshot((snapshot) => {
        setAppointments(snapshot.docs.map(doc => {return ({id:doc.id, docData:doc.data()})}))
    });

  }

  if(cId !== null && cId !== "" && !!loadingCurrAppt){
    firestore.collection('appointments').doc(cId).get().then((doc) => {
      console.log("Doc Data from messages", doc.data())
      setCurrentAppt(doc.data())
    }).catch((error) => {
      console.log("Error getting cached document:", error);
    });
    setConversationId(cId)
    setLoadingCurrAppt(false)
  }


  console.log("Convo Id", cId)
    

  },[currentAppt, loadingCurrAppt])

    return (
      <>
      {appointments.length === 0 ?
        <div style={{display:'flex', flexFlow:'column', width:'inherit', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
          <CircularProgress style={{color: '#413e68'}}/>
          <Typography style={{margin:'20px 0px'}}>Loading...</Typography>
        </div>
        : 
        <Grid container item xs={12} className={classes.root} style={{height: height, alignSelf:'baseline'}}>
          <Grid item xs={12} className={classes.rootTitle}>
            <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Messages</Typography>
          </Grid>
          
          <Grid container item xs={12} style={{flex:'1 0 auto'}}>

          <Hidden only={['xs', 'sm']}>

              <Grid container item md={4} className={classes.conversationContainer}>
                
                <Grid item xs={12} className={classes.conversationHeader} >
                  
                    <Typography variant='h6' style={{fontWeight:'bold'}}>Conversations</Typography>
                    
                          <Paper component='form'
                            className={classes.inputField}>    
                              <InputBase
                                name='search'
                                value={nameSearch}
                                onChange={(e) => {setNameSearch(e.target.value)}}
                                placeholder="Search..."
                                className={classes.input}
                                //autoFocus
                                />
    
                                <SearchIcon className={classes.icon} style={{color:'lightgrey'}}/>
                          </Paper>
                  
                </Grid>

                <SimpleBarReact autoHide={false} style={{maxHeight: maxHeight, flex:'1 0 auto', width: 'inherit', padding:'0px 15px 5px 5px'}}>
                  <div style={{width:'inherit'}}>
                  {appointments.length > 0 &&  appointments.filter((x) => {
                    const appt = x.docData
                    const name = appt.proName
                    const name2 = appt.clientName
                    //const messages = appt.messages
                    const id = x.id
                    if(nameSearch === '') {
                        return x
                        } else if (name.toLowerCase().includes(nameSearch.toLowerCase())) {
                            return x
                        } else if (name2.toLowerCase().includes(nameSearch.toLowerCase())) {
                            return x
                        }
                        })
                        //.sort((a,b) => a.docData.messages.pop().createdAt > b.docData.messages.pop().createdAt)
                        .map(x => {
                            const appt = x.docData
                            const messages = appt.messages
                            const id = x.id
                              return(<Conversation key={id} appointment={appt} id={id} messages={messages} 
                                userData={userData} conversationId={conversationId} cId={cId} setConversationId={setConversationId} 
                                currentAppt={currentAppt} setCurrentAppt={setCurrentAppt}/>)
                            
                        })
                }
                  </div>
                </SimpleBarReact> 
              
              </Grid>
              {currentAppt !== null &&
              <ChatWindow userData={userData} appointments={appointments} conversationId={conversationId} cId={cId}
                setConversationId={setConversationId} currentAppt={currentAppt} setCurrentAppt={setCurrentAppt}/>
              }
          </Hidden>

{/* ---------------------------------------------------Mobile Version----------------------------------------------------- */}
{/* ----------------------------------------------------------------------------------------------------------------------- */}

          <Hidden only={['md', 'lg', 'xl']}>
            {appointments.length !== 0 && currentAppt === null ?
                <Grid container item md={4} className={classes.conversationContainer}>
                  
                  <Grid item xs={12} className={classes.conversationHeader} >
                    
                      <Typography variant='h6' style={{fontWeight:'bold'}}>Conversations</Typography>
                      
                            <Paper component='form'
                              className={classes.inputField}>    
                                <InputBase
                                  name='search'
                                  value={nameSearch}
                                  onChange={(e) => {setNameSearch(e.target.value)}}
                                  placeholder="Search..."
                                  className={classes.input}
                                  //autoFocus
                                  />
      
                                  <SearchIcon className={classes.icon} style={{color:'lightgrey'}}/>
                            </Paper>
                    
                  </Grid>

                  <SimpleBarReact autoHide={false} style={{maxHeight:maxHeight, flex:'1 0 auto', width: 'inherit', padding:'0px 15px 5px 5px'}}>
                    <div style={{width:'inherit'}}>
                    {appointments.length > 0 &&  appointments.filter((x) => {
                      const appt = x.docData
                      const name = appt.proName
                      const name2 = appt.clientName
                      //const messages = appt.messages
                      const id = x.id
                      if(nameSearch === '') {
                          return x
                          } else if (name.toLowerCase().includes(nameSearch.toLowerCase())) {
                              return x
                          } else if (name2.toLowerCase().includes(nameSearch.toLowerCase())) {
                              return x
                          }
                          }).map(x => {
                              const appt = x.docData
                              const messages = appt.messages
                              const id = x.id
                              return(<Conversation key={id} appointment={appt} id={id} messages={messages} 
                                userData={userData} conversationId={conversationId} setConversationId={setConversationId} 
                                currentAppt={currentAppt} setCurrentAppt={setCurrentAppt}/>)
                          }).sort() 
                  }
                    </div>
                  </SimpleBarReact> 
                
                </Grid>
              :
              <>
               {currentAppt !== null &&
              <ChatWindow userData={userData} appointments={appointments} conversationId={conversationId}
                setConversationId={setConversationId} currentAppt={currentAppt} setCurrentAppt={setCurrentAppt}/>
               }
              </>

              }
          </Hidden>
          </Grid>
  
        </Grid>
      }
      </>
    )
}

Messages.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Messages)

