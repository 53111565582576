import React from 'react'

function PageNotFound() {
    return (
        <div style={{width:'100%', height:'92vh', background:'white', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <h2>404</h2>
            <p>OOPS, SORRY WE CANT FIND THAT PAGE</p>
            <p>Either something went wrong or this page doesn't exist anymore.</p>
            
        </div>
    )
}

export default PageNotFound
