import React, {useState, useReducer} from 'react'
import PropTypes from 'prop-types';
import { Grid, Typography, Tooltip} from '@material-ui/core';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useSelector } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import SchedulerCalendar from 'scheduler-calendar'
import 'scheduler-calendar/dist/index.css'
import { firestore } from '../../../config/fbConfig';

function reducer(state, action) {
    // console.log("State from reducer function", state)
    return {userData: state}
}

const Availability = ({width}) => {
    //    const localizer = momentLocalizer(moment)
    const userData = useSelector(state => state.auth.userData)
    const [state, dispatch] = useReducer(reducer,  userData)
    const [availability, setAvailability] = useState(userData.availability || [])
    
    const padding = width >= 'lg' ? '0px 15px 0px 60px' : 0

    function handleIntervalChange(event) {
        // console.log('User Data:', userData, 'Availability', event)
        setAvailability(event)

        var currentProRef = firestore.collection("professionals").doc(userData.userId);

        // Set the "avaialability" field of the pro
        return currentProRef.update({
            availability: event
        })
        .then(() => {
            // console.log("Document successfully updated!");
            //update the global state of userData
            dispatch()
        })
        .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        });

    }

    
    return (
        <Grid container item xs={12} style={{background:"white", padding:'0px 10px'}} direction="column" alignItems="center" justify="center">

            <Grid item xs={12} container style={{borderRadius:'10px 10px 0 0', marginBottom:60}}>
                <Grid item xs={12} style={{height:50, backgroundColor: '#413e68', padding: '8px 0 0 20px', margin: '20px 0', borderRadius:'10px 10px 0 0', color:'white' }}>
                    <Typography variant='h5'>
                        Availability <Tooltip title={`Set your availability hours to allow clients to schedule appointments with you!`}>
                            <HelpOutlineIcon style={{fontSize: '20px', color:'white', verticalAlign:'middle'}}/>
                            </Tooltip>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SchedulerCalendar
                        availabilities={availability}
                        availabilityType={'infinity'}
                        duration={10}
                        isDisabledDateLocked={true}
                        onIntervalChange={handleIntervalChange}
                        />
                </Grid>
            </Grid>
        </Grid>
    )
}

Availability.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };
  
  export default withWidth()(Availability)