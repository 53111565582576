import React, {useEffect, useState} from 'react'
import {Container, Grid, Typography} from '@material-ui/core'

function Shop() {
  

    return (
        <Container style={{padding: "0", overflowY: "hidden scroll", height: "95vh", borderTop: 'solid 1px #d3d3d3' }}>
          <Grid container item direction="column">
              <Typography variant="h4" color="initial" style={{fontWeight:"bold", paddingLeft: "20px", borderBottom: 'solid 1px #d3d3d3'}}>Shop</Typography>
            <div style={{padding: "20px"}}>Hello World from the Shop page! <br/> 
            </div>
          </Grid>
        </Container>
    )
}

export default Shop
