import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Staff from './Staff';
import Hero from './Hero';
import {Grid, Button, Typography, Avatar, InputBase, Paper, Modal, Checkbox, FormGroup,Tooltip,
  TextField, TextareaAutosize, IconButton, Menu, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import withWidth from '@material-ui/core/withWidth';
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear'
import Carousel from "react-simply-carousel";
import ServiceCard from '../../dashboard/settings/Account/ServiceCard';
import { firestore, storage } from '../../../config/fbConfig';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import DeleteIcon from '@material-ui/icons/Delete';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import firebase from 'firebase';
import BrandAmThemeEditor from '../../modals/BrandAmThemeEditor';
import BrandAmAddStaff from '../../modals/BrandAmAddStaff';
import { BAScheduler } from './scheduleAnAppt/BAScheduler';
import LoginModal from '../../modals/LoginModal';
import ContactUsModal from './ContactUsModal';
import Footer from './Footer';
import Services from './Services';
import About from './About';
import { CachedOutlined, Check, Close, CloseRounded, Collections, ColorLens, Delete } from '@material-ui/icons';
import { Upload } from '@mui/icons-material';
import imageCompression from 'browser-image-compression';


const { ms, s, m, h, d } = require('time-convert')
var badWords = require('badwords-list')

const useStyles = makeStyles((theme) => ({
  sectionHeader:{
    fontSize: 24,
    fontWeight: 'bold',
  },
  searchBar: {
    border:'2px solid lightgray',
    borderRadius: 20,
    height: 40 ,
    width: 'inherit',
    paddingTop: 2,
    display:'flex',
    alignItems:'center',
    boxShadow: 'none'
  },
  circBtn:{
    '&:hover':{
      cursor: 'pointer'
    }
  },
  question: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  imgSelecter:{
    position:'absolute',
    top: 120,
    left: '52%',
    border: '2px solid white',
    backgroundColor: theme.palette.primary.main,
    '&:hover':{
      cursor:'pointer',
      opacity: .8,
    },
  },
  accordianDetails:{
    width:'100%',
    display:'flex',
    flexFlow:'column',
    textAlign: 'left'
  },
  accordianEditBtn:{
    alignSelf:'flex-end'
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    width:'100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
  }));



const BrandAmbassador = (props) => {
  const classes = useStyles()
  const { currentUser, userData, width} = props;
  const history = useHistory();
  const [brandAm, setBrandAm] = useState({})
  const [brandAmUID, setBrandAmUID] = useState(null)
  const [designData, setDesignData] = useState(null)
  const [lightTheme, setLightTheme] = useState(false)
  const [loading, setLoading] = useState(true)
  const [staffEmails, setStaffEmails] = useState([])
  const [staff, setStaff] = useState([])
  const [availablePros, setAvailablePros] = useState(null)
  const array = badWords.array
  const path = window.location.pathname.split('/')
  const pathName = path[2]
  const paddingLeft = ((currentUser && (width==='xs' || width==='sm'))) ? 70 : 20
  const footerPaddingLeft = (currentUser) ? 70 : 20
  const [edit, setEdit] = useState(false)


  //Theme Modal
  const [themeEditorOpen, setThemeEditorOpen] = useState(false)
  //Name Modal
  const [nameModalOpen, setNameModalOpen] = useState(false)
  const [name, setName] = useState('')
  const [subtitle, setSubtitle] = useState('')

  //About Modal
  const [aboutModalOpen, setAboutModalOpen] = useState(false)
  const [about, setAbout] = useState('')
  
  //Staff Modal
  const [staffModalOpen, setStaffModalOpen] = useState(false)

  //Services Modal
  const [allServices, setAllServices] = useState([])
  const [serviceName, setServiceName] = useState('')
  const [selectedService, setSelectedService] = useState(null)
  const [brandSelectedService, setBrandSelectedService] = useState(null)
  const [filteredServices, setFilteredServices] = useState([])
  const [isAddServiceModalOpen, setAddServiceModalOpen] = useState(false)
  const [nameSearch, setNameSearch] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [filterBy, setFilterBy] =React.useState('')
  const [filterByy, setFilterByy] =React.useState('')
  const [time, setTime] = useState([])
  const [isModal2Open, setIsModal2Open] = useState(false)
  const [isModal3Open, setIsModal3Open] = useState(false)
  const [libraryModalOpen, setLibraryModalOpen] = useState(false)
  const [loginModal, setLoginModal] = useState(false)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [pricesVary, setPricesVary] = useState(false)
 
  //Services
  const [newPrice, setNewPrice] = useState('')
  const [newHrs, setNewHours] = useState([])
  const [newMins, setNewMins] = useState([])
  const [details, setDetails] = useState('')
  const [editChecked, setEditChecked] = useState(true)
  const switchLabelEdit = (editChecked === true) ? "Yes" : "No"

  //theme
  const backgroundColor = lightTheme === true ? 'white' : 'black'
  const fontColor = lightTheme === true ? 'black' : 'white'

  //Images Library
  const fileInput = useRef();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [library, setLibrary] = useState([]);
  const [loadingUploads, setLoadingUploads] = useState(null);


  const selectImg = () => {
    fileInput.current.click()
  }
  
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    
    // console.log(selectedFiles)
    
    var options = {
      maxSizeMB: .5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    const imagesArray = selectedFilesArray.map((file) => {
      // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

    //   imageCompression(file, options)
    //   .then(function (compressedFile) {
    //     // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    //     // console.log(`compressedFile Name ${compressedFile.name.split(' ').join('-')}`); // smaller than maxSizeMB
    //     let fileurl = ''
    //     var uploadTask = storage.ref(`BrandAmbassadorInfo/${brandAm.email}-${brandAm.name}/images/${compressedFile.name.split(' ').join('-')}`).put(compressedFile)
    //       uploadTask.on('state_changed', 
    //         (snapshot) => {
    //           //Handle progress...
    //           // setImageLoading(true)
    //         }, 
    //         (error) => {
    //           // Handle unsuccessful uploads...
    //         }, 
    //         async () => {
    //           // Handle successful uploads on complete...
    //           await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
    //             fileurl = url
    //             firestore.collection('brandAms').doc(brandAmUID).collection('design').doc('designInfo').update({
    //               library: firebase.firestore.FieldValue.arrayUnion(fileurl),
    //              }).then(()=> {
    //                 setLibrary(library.push(fileurl));
    //                 setSelectedImages(selectedImages.filter((e) => e !== file));
    //                 URL.revokeObjectURL(file);
    //              })
    //             console.log(`${compressedFile.name} available at => `, fileurl)
    //           })
    //         }
    //       )
    //     })
    //     .catch(function (error) {
    //     console.log(error.message);
    //   });
      return URL.createObjectURL(file);
    });

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return URL.createObjectURL(file);
    // });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    setSelectedFiles((previousImages) => previousImages.concat(selectedFilesArray))

    // FOR BUG IN CHROME
    event.target.value = "";
  };



  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }


  function handleImageUpload(event) {
    console.log(selectedFiles[0])
    setLoadingUploads(true)

    var options = {
      maxSizeMB: .5,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    selectedFiles.map((file, idx) => {
      imageCompression(file, options)
      .then(function (compressedFile) {
        let fileurl = ''
        var uploadTask = storage.ref(`BrandAmbassadorInfo/${brandAm.email}-${brandAm.name}/images/${compressedFile.name.split(' ').join('-')}`).put(compressedFile)
          uploadTask.on('state_changed', 
            (snapshot) => {
              //Handle progress...
            }, 
            (error) => {
              // Handle unsuccessful uploads...
            }, 
            async () => {
              // Handle successful uploads on complete...
              await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
                fileurl = url
                firestore.collection('brandAms').doc(brandAmUID).collection('design').doc('designInfo').update({
                  library: firebase.firestore.FieldValue.arrayUnion(fileurl),
                  }).then(()=> {
                    setLibrary(prevState => [...prevState, fileurl]);
                    setLoadingUploads(false)
                  })
              })
            }
          )
        })
        .catch(function (error) {
        console.log(error.message);
      });
    })


    
  }

  const numsOnly = (e) => {
    const re = /[0-9]+/g;
    const reg = /[0-9.]+/g;
    if(e.target.name !== 'price'){
      if (!re.test(e.key)) {
        e.preventDefault();
      }
      if(e.target.value.length >= 2){
        e.preventDefault()
      }
    }else{
      if (!reg.test(e.key)) {
        e.preventDefault();
      }
    }
  }





  useEffect(() => {
    var docRef = firestore.collection("services").doc("allServices");

    if(allServices.length === 0){
        docRef.get().then((doc) => {
            if (doc.exists) {
                setAllServices(doc.data().listOfServices);
                setFilteredServices(doc.data().listOfServices);
                // console.log(doc.data())
            } else {
                // console.log("No such document!");
            }
        }).catch((error) => {
            // console.log("Error getting document:", error);
        });
    }else{
        allServices.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1)
        // filteredServices.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1)
    }

    if(selectedService){
        const t = (m.to(h,m)(selectedService.time))
        setTime(t)
    }

    if(serviceName !== ''){
      array.map(badWord => {
          if(serviceName === badWord){
              alert('To help keep our community safe, profanity is absolutely prohibited. Services are checked daily by our staff. Thanks!')
              setServiceName('')
              
          }
      })
  }

    if(!brandAmUID){
      firestore.collection("brandAms").where("url", "==", pathName)
      .onSnapshot((querySnapshot) => {
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              setBrandAm(doc.data());
              setLightTheme(doc.data().themeLight)
              setStaffEmails(doc.data().staff)
              setBrandAmUID(doc.id)
              console.log('this is happening')
          }, (error) => {
                // console.log("Error getting documents: ", error);
            }
          );
      })
    }

    if(!designData & brandAmUID !== null){
      firestore.collection("brandAms").doc(brandAmUID).collection('design').doc('designInfo')
        .get().then((doc) => {
          if (doc.exists) {
            setDesignData(doc.data())
            setLibrary(doc.data().library)
          } else {
              console.log("No such document!");
          }
      }).catch((error) => {
          console.log("Error getting Design Data:", error);
      })
    }

    if(staffEmails.length !== 0 && loading===true){
      setLoading(false)
        for (let i = 0; i < staffEmails.length; i++){
            firestore.collection('professionals').where('email', '==', staffEmails[i])
            .onSnapshot((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                  setStaff(prev => [...prev, doc.data()]);
              });
          });
        }    
      
    }

    if(staff.length!==0 && selectedService!== null && availablePros===null){
      if(staff.filter((pro) => pro.services.some(s => s.name.toLowerCase().includes(selectedService.name.toLowerCase()))).length <= 0){
        setAvailablePros([])
      }
      else if(staff.filter((pro) => pro.services.some(s => s.name.toLowerCase().includes(selectedService.name.toLowerCase()))).length >= 1) {
        setAvailablePros(staff.filter((pro) => pro.services.some(s => s.name.toLowerCase().includes(selectedService.name.toLowerCase()))))
      }
    }
    
    console.log('staff emals:', staffEmails)
    console.log('staff:', staff)
    console.log('loading:', loading)
    console.log("UID:", brandAmUID)
    console.log("Available Pros:", availablePros)
    console.log('Design Data:', designData)

  }, [brandAmUID, loading, staffEmails, allServices, selectedService, serviceName, filteredServices, availablePros, designData])


  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }

  const editName = () => {
    setNameModalOpen(!nameModalOpen)
  } 

  const saveName = () => {
    var userRef = firestore.collection('brandAms').doc(brandAmUID);

    return userRef.update({
        name: name, subtitle: subtitle,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
    })
    // .then(() => {
    //     setOpenSuccess(!openSuccess)
    // })
    // .then(
    //         () => {setTimeout(function(){setModalOpen(!modalOpen)}, 3000)}
    // )
    .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        // setOpenErr(!openErr)
    })  }

    const handleEdit = () => {
      setEdit(!edit)
    }




  const editAbout = () => {
    setAboutModalOpen(!aboutModalOpen)
  } 
  const handleAddServiceModalOpen = () => {
    setAddServiceModalOpen(!isAddServiceModalOpen)
  }

  const handleSelectFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilter = (typeOfService) => {
    console.log(typeOfService)
    setFilteredServices(allServices.filter(service => service.typeOfService === typeOfService))
    setAnchorEl(null);
  }

  const handleSelectSort = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const sortAtoZ = () => {
    filteredServices.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() && 1 || -1)
    setAnchorEl2(null);
  }

  const sortZtoA = () => {
    filteredServices.sort((a,b) => b.name.toLowerCase() > a.name.toLowerCase() && 1 || -1)
    setAnchorEl2(null);
  }

  const handleClose = () => {
    setAnchorEl(null)
}

const handleModal2Open = () => {
  setIsModal2Open(!isModal2Open)
}
const handleModal2Close = () => {
  setIsModal2Open(!isModal2Open)
  setBrandSelectedService(null)
}

const handleModal3Open = () => {
  setIsModal3Open(!isModal3Open)
}
const handleModal3Close = () => {
  setIsModal3Open(!isModal3Open)
  setSelectedService(null)
  setAvailablePros(null)
}

const handleLogineModalOpen = () => {
  setLoginModal(!loginModal)
}
const handleContactModalOpen = () => {
  setContactModalOpen(!contactModalOpen)
}

const toggleEditChecked = () => {
  setEditChecked(!editChecked)
}

const handleUpdateService = () => {
  const newTime = parseFloat((h.to(m)(parseFloat(newHrs)))) + (parseFloat(newMins))
  
  var serviceRef = firestore.collection("brandAms").doc(`${brandAmUID}`);

  serviceRef.update({
      services: firebase.firestore.FieldValue.arrayRemove(brandSelectedService)
  })
  serviceRef.update({
      services: firebase.firestore.FieldValue.arrayUnion({
        name: brandSelectedService.name, serviceType:brandSelectedService.serviceType, 
        time: isNaN(newTime) ? brandSelectedService.time : newTime, 
        price: newPrice === '' ? brandSelectedService.price : newPrice, 
        details: (details === '' && brandSelectedService.details) ? brandSelectedService.details : details,
        reqDeposit: editChecked,
        priceVaries: pricesVary
      })
  })
 //.then(() => console.log('Successfully updated document!'))
  // .then(() => window.location.reload())
  .catch((error) => {
    // The document probably doesn't exist.
    console.error("Error updating document: ", error);
    // setOpenErr(true)
  });
}

const handleRemoveService = () => {
  var serviceRef = firestore.collection("brandAms").doc(`${brandAmUID}`);
  serviceRef.update({
      services: firebase.firestore.FieldValue.arrayRemove(brandSelectedService)
  })
  // .then(() => {}// console.log('Successfully deleted document!')
  // )
  // .then(() => window.location.reload())
  .catch((error) => {
    // The document probably doesn't exist.
    console.error("Error updating document: ", error);
  });
}

  const handleRemovePro = (email) => {
    firestore.collection('brandAms').doc(brandAmUID).update({
      staff: firebase.firestore.FieldValue.arrayRemove(email),
     }).then(()=> window.location.reload())
  } 


  return (
    <>
    {brandAmUID &&
      <Grid item container xs={12} style={{position:'relative',}}>

          {designData &&
          <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'flex-end', position:'sticky', top:70, zIndex:1, height:300}}>
            {(userData.email === brandAm.email && edit===false) && <Button variant='contained' style={{marginRight:20, height:40}} onClick={handleEdit}>Edit</Button>}

            {(userData.email === brandAm.email && edit===true) && 
            <div style={{marginRight:20, display:'flex', flexDirection:'column'}}>
              <Button variant='contained' style={{marginBottom:5, borderRadius:24, height:40}} onClick={()=> setThemeEditorOpen(!themeEditorOpen)}><ColorLens style={{marginRight:5}}/>Theme</Button>
              <Button variant='contained' style={{marginBottom:5, borderRadius:24, height:40}} onClick={()=> setLibraryModalOpen(!libraryModalOpen)}><Collections style={{marginRight:5}}/>Images Library</Button>
              <Button variant='contained' style={{backgroundColor:'red', color:'white'}} onClick={handleEdit}><Close style={{marginRight:5}}/> Close</Button>
            </div>}
          </div>
          }
         
          {designData &&
            <Hero 
              userData={userData}
              brandAm={brandAm} 
              uid={brandAmUID}
              designData={designData}
              classes={classes}
              subtitle={brandAm.subtitle} image={brandAm.themeHeader}
              light={brandAm.themeLight}
              primary={brandAm.themePrimary} secondary={brandAm.themeSecondary}
              handleScroll={handleScroll}
              paddingLeft={paddingLeft}
              themeEditorOpen={themeEditorOpen}
              setThemeEditorOpen={setThemeEditorOpen}
              edit={edit}
              handleEdit={handleEdit}
            />
          }

          <Grid item container style={{maxWidth:1500, margin:'0px auto'}}>
            {/* The ABOUT section */}
            {designData &&
            <About 
              userData={userData}
              brandAm={brandAm}
              uid={brandAmUID}
              classes={classes}
              light={brandAm.themeLight}
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              paddingLeft={paddingLeft}
              designData={designData}
              editAbout={editAbout}
              edit={edit}
              handleEdit={handleEdit}
            />
            }

            {/* The SERVICES section */}
            {designData &&
            <Services 
                userData={userData}
                brandAm={brandAm}
                uid={brandAmUID}
                classes={classes} 
                designData={designData}
                paddingLeft={paddingLeft}
                handleAddServiceModalOpen={handleAddServiceModalOpen}
                setSelectedService={setSelectedService}
                setBrandSelectedService={setBrandSelectedService}
                handleModal2Open={handleModal2Open}
                handleModal3Open={handleModal3Open}
                handleRemoveService={handleRemoveService}
                handleLogineModalOpen={handleLogineModalOpen}
                setPricesVary={setPricesVary}
                edit={edit}
            />
            }

            {(designData 
           && designData.gallery.length !== undefined && designData.gallery.length !== 0
            ) &&
              <Grid item container xs={12} style={{padding:20, height:500, backgroundColor: designData.theme.secondary}}>
                <Typography style={{width:'90%', margin:'0px auto', textAlign:'center', fontWeight:'bold', fontSize:24}}>Gallery</Typography>
                <div style={{width:'100%', display:'flex', flexDirection:'row', overflowX:'scroll', padding:20}}>
                 {designData.gallery.map((img, idx) => {
                  return  (
                    <img src ={img} alt='img'  style={{height:350, width:350, objectFit:'contain', padding:10 }}/>
                  )

                 })}
                </div>

              </Grid>
            }

            {/* The STAFF section */}
            {designData &&
            <Staff
              userData={userData}
              brandAm={brandAm}
              uid={brandAmUID}
              classes={classes} 
              designData={designData}
              staffModalOpen={staffModalOpen}
              setStaffModalOpen={setStaffModalOpen}
              personel={staff}
              handleRemovePro={handleRemovePro}
              edit={edit}
              paddingLeft={paddingLeft}
            />
            }
            
            {/* The FOOTER section */}
            {designData &&
            <Footer 
              brandAm={brandAm}
              uid={brandAmUID}
              classes={classes}
              designData={designData}
              footerPaddingLeft={footerPaddingLeft}
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              handleContactModalOpen={handleContactModalOpen}
              edit={edit}
              // handleEdit={handleEdit}
            />
            }

          </Grid>
         

          
          {designData &&
          <BrandAmThemeEditor 
            designData={designData}
            modalOpen={themeEditorOpen}
            setModalOpen={setThemeEditorOpen}
            userData={userData}
            themePrimary={brandAm.themePrimary}
            themeSecondary={brandAm.themeSecondary}
            themeLight={brandAm.themeLight}
            uid={brandAmUID}
            />
          }

          <BrandAmAddStaff
            modalOpen={staffModalOpen}
            setModalOpen={setStaffModalOpen}
            brandStaff={brandAm.staff}
            themePrimary={brandAm.themePrimary}
            themeSecondary={brandAm.themeSecondary}
            uid={brandAmUID}
          />



          <Modal open={!!isAddServiceModalOpen} onClose={handleAddServiceModalOpen}>
                <Grid item xs={10} sm={6} md={5} container style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', padding:20, backgroundColor:'white', display:'flex', justifyContent:'space-between', borderRadius:'14px'}}>
                    <Typography>Add A Services</Typography>
                    <HighlightOffIcon className={classes.modalBtn} onClick={handleAddServiceModalOpen}/>
                    <Grid item container xs={12} className={classes.service} style={{border:'1px solid #878787',height: '70vh', borderRadius: '8px', marginTop:10, display:'flex', justifyContent:'flex-end'}}>
                    
                        <Paper component='form'
                            className={classes.inputField}>    
                            <InputBase
                            name='search'
                            onChange={(e) => {setNameSearch(e.target.value)}}
                            placeholder="Search..."
                            className={classes.input}
                            />

                            <SearchIcon className={classes.icon} style={{color:'lightgrey'}}/>
                        </Paper>

                        <IconButton aria-label="filter" component="span" aria-haspopup="true" onClick={handleSelectFilter} style={{margin:"10px 5px 0px 0px", height:40, width:40}}>
                            <FilterListIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu-filter"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            >
                            <MenuItem onClick={()=> setFilteredServices(allServices)}>All</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Brow & Lashes')}>Brow & Lashes</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Facials & Skincare')}>Facials & Skincare</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Hair')}>Hair</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Health & Wellness')}>Health & Wellness</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Home & Care Care')}>Home & Car Care</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Makeup')}>Makeup</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Massage')}>Massage</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Nails')}>Nails</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Pet Care')}>Pet Care</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Waxing Services')}>Waxing Services</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Tattoo & Piercing')}>Tattoo & Piercing</MenuItem>
                            <MenuItem onClick={()=> handleFilter('Other')}>Other Services</MenuItem>
                            {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                        </Menu>
                        <IconButton aria-label="sort" component="span" aria-haspopup="true" onClick={handleSelectSort} style={{margin:"10px 5px 0px 0px", height:40, width:40}}>
                            <SortByAlphaIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu-sort"
                            anchorEl={anchorEl2}
                            keepMounted
                            open={Boolean(anchorEl2)}
                            onClose={handleClose}
                            >
                            <MenuItem onClick={sortAtoZ}>Sort A - Z</MenuItem>
                            <MenuItem onClick={sortZtoA}>Sort Z - A</MenuItem>
                            {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                        </Menu>

                        <SimpleBarReact autoHide={false} style={{height:'80%', width: 'inherit', padding:'0px 15px 5px 5px'}}>
                                {filteredServices.filter((service) => {
                                    const name = service.name
                                    if(nameSearch === '') {
                                        return service
                                        } else if (name.toLowerCase().includes(nameSearch.toLowerCase())) {
                                            return service
                                        }
                                        }).map(service => {
                                            return(<ServiceCard service={service} userData={userData} brandAmUID={brandAmUID}/>)
                                        }).sort()  
                                }

                                {filteredServices.filter((service) => {
                                    const name = service.name
                                    if(nameSearch !== '' && !name.toLowerCase().includes(nameSearch.toLowerCase())) {
                                        return service
                                        }
                                        }).map((service, idx) => {
                                            if(idx === 0){
                                                return(
                                                    <Grid item container xs={12} alignItems='center' style={{marginTop:5}}>
                                                        <Grid item xs={12}>
                                                            <Typography>Don't see what you're looking for?</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Button variant="contained" color='secondary' onClick={()=>console.log('do this later')}>Add A New Service!</Button>
                                                        </Grid>

                                                    </Grid>
                                                )
                                            }
                                        })  
                                }
                        </SimpleBarReact>
                    </Grid>
                  </Grid>

          </Modal>

          {brandSelectedService && 
            <Modal open={!!isModal2Open} onClose={handleModal2Close}>
              
              <Grid container xs={10} sm={6} md={5} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Edit Service Details
                    </Typography>
                    <IconButton onClick={handleModal2Close} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={12} container style={{padding:20, overflowY:'scroll', maxHeight:'65vh'}}>
                  
                  <Grid item xs={12} className={classes.brandSelectedServiceDetails}>
                    <Typography>Category: <span style={{fontWeight:'bold'}}>{brandSelectedService.serviceType}</span></Typography>
                  </Grid>

                  <Grid item xs={12} className={classes.brandSelectedServiceDetails}>
                    <Typography>Name: <span style={{fontWeight:'bold'}}>{brandSelectedService.name}</span></Typography>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.brandSelectedServiceDetails}>
                    <Grid item xs={12} md={6}>
                      <Typography>Appointment Time:</Typography>
                    </Grid>

                    <Grid item container xs={12} md={6} style={{paddingRight:10}}>
                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Hrs"
                            name="hours"
                            defaultValue={Math.floor(brandSelectedService.time/60)}
                            // value={newHrs}
                            //placeholder={time[0]}
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setNewHours(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>

                      <Grid item xs={6} style={{paddingLeft:10}}>
                          <TextField
                            label="Mins"
                            name="mins"
                            defaultValue={brandSelectedService.time - (Math.floor(brandSelectedService.time/60)*60)}
                            // value={newMins}
                            //placeholder={time[1]}
                            onKeyPress={(e) => numsOnly(e)}
                            onChange={(e) => {setNewMins(e.target.value)}}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            required
                            fullWidth
                          />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.brandSelectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={6}>
                    <Typography style={{width:'100%'}}>Price
                      <Tooltip title="Selecting the 'Varies' checkbox allows you to create a general service price, and update the price later after confirming exactly what your client needs.">
                        <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                        </Tooltip>:
                      </Typography>
                        <FormGroup>
                        <FormControlLabel
                        control={
                          <Checkbox
                            checked={pricesVary}
                            color='primary'
                            onChange={()=> setPricesVary(!pricesVary)}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            name="vary"
                          />
                        }
                        label="Varies"
                      />
                    </FormGroup>
                    </Grid>

                    <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center'}}>
                          <div style={{marginRight:10, fontSize:18}}>$</div>
                          {pricesVary === false ?
                            <TextField
                              label="Price"
                              name="price"
                              defaultValue={brandSelectedService.price}
                              // value={newPrice}
                              placeholder={`${brandSelectedService.price}`}
                              onKeyPress={(e) => numsOnly(e)}
                              onChange={(e) => {setNewPrice(e.target.value)}}
                              margin="normal"
                              variant="outlined"
                              autoComplete="off"
                              required
                              fullWidth
                            />
                            :
                            <div style={{width:'inherit', display:'flex', flexFlow:'row', justifyContent:'space-evenly', alignItems:'center'}}>
                              <TextField
                                label="Price"
                                name="price"
                                defaultValue={brandSelectedService.price}
                                style={{width:'70%'}}
                                placeholder={`${brandSelectedService.price}`}
                                onKeyPress={(e) => numsOnly(e)}
                                onChange={(e) => {setNewPrice(e.target.value)}}
                                margin="normal"
                                variant="outlined"
                                autoComplete="off"
                                required
                              />
                              <Typography style={{fontSize:10}}> and up</Typography>
                            </div>
                          }
                    </Grid>

                    <Typography style={{width:'inherit'}}>Details:</Typography>
                    <TextareaAutosize
                              style={{padding:5, fontSize: 16, font:'inherit', borderColor:'silver', borderRadius:4, width:'inherit'}}
                              rowsMax={3}
                              rowsMin={3}
                              label="details"
                              name="details"
                              defaultValue={brandSelectedService.details ? brandSelectedService.details : ""}
                              // value={newMins}
                              //placeholder={time[1]}
                              onChange={(e) => {setDetails(e.target.value)}}
                              
                            
                              required
                              fullWidth
                            />
                  </Grid>

                  <Grid item container xs={12} alignItems="center" className={classes.brandSelectedServiceDetails} style={{marginTop:20}}>
                    <Grid item xs={6}>
                        <Typography>Require Deposit?
                          <Tooltip title="Require your customer to pay 25% down when booking.">
                            <HelpOutlineIcon style={{fontSize: '14px', color:'grey', verticalAlign:'middle'}}/>
                            </Tooltip>
                        </Typography>
                      </Grid>

                      <Grid item container xs={6} style={{padding:'0px 10px', display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'flex-end', marginTop:20}}>
                        <FormControlLabel 
                            control={<Switch size="normal" color="primary" checked={editChecked} onChange={toggleEditChecked} />}
                            label={switchLabelEdit}
                            />
                      </Grid>
                  </Grid>


                  <Grid item xs={12} container className={classes.brandSelectedServiceDetails} style={{marginBottom: 40}}>
                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" fullWidth onClick={handleModal2Close}>Cancel</Button>
                    </Grid>

                    <Grid item xs={6} style={{padding:'0px 10px'}}>
                      <Button variant="contained" color="primary" fullWidth onClick={handleUpdateService}>Save</Button>
                    </Grid>
                  </Grid>
      
                </Grid>
                

              </Grid>
            </Modal>
            }

          <Modal open={!!isModal3Open} onClose={handleModal3Close}>
              
            <Grid container xs={10} sm={6} md={5} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
              <Grid item container justify='space-between' style={{backgroundColor: `${brandAm.themePrimary}`, color: `${fontColor}`, height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                  <Typography variant='h6' style={{padding: '10px'}}> 
                      {selectedService !== null && <span>{selectedService.name}</span>}
                  </Typography>
                  <IconButton onClick={handleModal3Close} style={{color: 'white'}}>
                      <ClearIcon />
                  </IconButton>
              </Grid>

              <Grid item xs={12} container style={{padding:20}}>

                <Grid item xs={12} style={{marginTop:10}}>
                  <BAScheduler 
                    selectedService={selectedService}
                    availablePros={availablePros}
                    userData={userData}
                    brandAm={brandAm}
                    brandAmUID={brandAmUID}
                    backgroundColor={backgroundColor}
                    fontColor={fontColor}
                    handleBookingModalClose={handleModal3Close}/>
                </Grid>

                </Grid>

            </Grid>
          </Modal>

          <Modal open={loginModal} onClose={handleLogineModalOpen}>
              <LoginModal/>
          </Modal>
          
          {designData &&
          <ContactUsModal modalOpen={contactModalOpen} handleModalOpen={handleContactModalOpen} brandAm={brandAm} designData={designData}/>
          }

          {designData &&
            <Modal open={libraryModalOpen} onClose={()=> setLibraryModalOpen(!libraryModalOpen)} >
              <Grid item container xs={12} md={11} style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                      <Typography variant='h6' style={{padding: '10px'}}>
                          Media Library
                      </Typography>
                      <IconButton onClick={()=> setLibraryModalOpen(!libraryModalOpen)} style={{color: 'white'}}>
                          <ClearIcon />
                      </IconButton>
                  </Grid>

                  <Grid item container xs={12} style={{height:'84vh'}}>
                    <Grid item container xs={12} style={{height:'calc(100% - 80px)', padding:20, display:'flex',}}>

                      {library.length > 0 &&
                        library.map((image, idx) => {
                          return (
                            <img src={image} style={{width:"200px", height:'200px', objectFit:'contain', padding:5, backgroundColor:'aliceblue', margin:10}} alt="img"/>
                          )
                        })
                      }
                      {library.length === 0 &&
                        <Typography style={{width:'100%', height:'100%', textAlign:'center'}}>You dont have any images in your library.</Typography>
                      }


                    </Grid>
                    <Grid item container xs={12} style={{backgroundColor:'lightgrey', height:80, display:'flex', justifyContent:'flex-end', alignItems:'center', padding:'0px 20px', position:'relative'}}>
                             
                    {(selectedImages.length > 0) &&
                        <div style={{width:250, position:'absolute', backgroundColor:'white', bottom:70, borderRadius:4, boxShadow:'2px 2px 6px #00000075', display:'flex', flexFlow:'column', padding:5}}>
                          
                          {loadingUploads===null && selectedImages.map((image, index) => {
                            return (
                              <div key={image} style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:5}}>
                                <div style={{display:'flex', flexFlow:'row', alignItems:'center'}}>
                                  <p style={{marginRight:5}}>{index + 1}</p>
                                  <img src={image} height="40" alt="upload" />
                                </div>
                                <Button variant='contained' style={{backgroundColor:'#d40100', color:'white', width:30, height:30}} onClick={() => deleteHandler(image)}><CloseRounded/></Button>
                              </div>
                            );
                          })}

                       
                          {library.length + selectedImages.length <= 12 ?
                          loadingUploads === null ?
                          <Button variant='contained' style={{backgroundColor:'#009832', height:'30', color:'white'}} onClick={handleImageUpload}><Upload/> Confirm</Button>
                          :
                          loadingUploads === true ?
                          <Button variant='contained' disabled style={{backgroundColor:'#009832', height:'30', color:'white'}} onClick={handleImageUpload}><CachedOutlined/> Loading</Button>
                          :
                          <Button variant='contained' style={{backgroundColor:'#009832', height:'30', color:'white'}} onClick={()=>setSelectedImages([])}><Check/> Completed!</Button>
                          :
                          <Typography style={{width:'100%', textAlign:'center', marginTop:10, color:'red'}}>Max number of images is limied to<strong>12</strong>. Please remove <strong style={{color:'black'}}>{(library.length + selectedImages.length)-12}</strong> images to move foward.</Typography>
                        }
                        </div>
                        }
                      
                      
                      <input
                        style={{display: 'none'}} 
                        type='file'
                        name='images'
                        onChange={onSelectFile}
                        multiple
                        accept='.gif,.jpg,.jpeg,.png'
                        ref={fileInput}
                      />

                      <Button variant='contained' color='primary' style={{borderRadius:24, height:40}} onClick={selectImg}><Upload style={{marginRight:5}}/> Upload Images</Button>

                    </Grid>
                  </Grid>

              </Grid>

            </Modal>
          }




      </Grid>
    }
    </>


    )
}

BrandAmbassador.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(BrandAmbassador);
