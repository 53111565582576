import React from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, FormControl, IconButton, Modal, TextField,
        MenuItem, Select, InputLabel, Snackbar} from '@material-ui/core'

function ComingSoonModal({modalOpen, handleModalOpen}) {
    return (
        <Modal open={!!modalOpen} onClose={handleModalOpen}>
            <Grid container item xs={10} sm={6} md={4}
            style={{
                position:'absolute',
                top:'50%', left:'50%',
                transform:'translate(-50%, -50%)',
                backgroundColor: 'white',
                borderRadius: '15px',
                padding:'30px',
                display:'flex',
                flexFlow:'column',
                justifyContent:'center',
                alignItems:'center',
                outline:'none'
                }}>
                <img style={{height:70, width:70}} src="https://img.icons8.com/carbon-copy/100/413e68/new-year-calendar.png" alt=''/> 
                <Typography variant='h3' style={{color:'#413e68', fontWeight:'bold', textAlign:'center'}}>COMING SOON!</Typography>
                <Typography style={{fontWeight:'bold', textAlign:'center', marginTop:30}}>We're working hard everyday to bring you new and exciting features! Please check back later.</Typography>
                <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={handleModalOpen}>Close</Button>

            </Grid>
            
        </Modal>
    )
}

export default ComingSoonModal
