import React from "react";
import {Typography, Container, TextField, Button} from "@material-ui/core";


export const Names = ({ formData, setForm, navigation }) => {
  const { firstName, lastName} = formData;
  const disabled = (firstName === "" || lastName === "") ? true : false;

  const lettersOnly = (e) => {
    const re = /[A-Za-z- ]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Name</Typography>
      
      {/* <input style={{display: 'none'}}
        type="file"
        id="image"
        name="image"
        onChange={handleImgSelected}
        ref={fileInput => this.fileInput = fileInput}
        />
      <Button variant="contained" fullWidth onClick={() => this.fileInput.click()}>
          Select your image
      </Button> */}
      
      
      <TextField
        label="First Name"
        name="firstName"
        value={firstName}
        onKeyPress={(e) => lettersOnly(e)}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        fullWidth
      />
      <TextField
        label="Last Name"
        name="lastName"
        value={lastName}
        onKeyPress={(e) => lettersOnly(e)}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        fullWidth
      />
    
      <Button
        disabled={disabled}
        variant="contained"
        fullWidth
        color="primary"
        style={{ backgroundColor: '#413e68',  
        backgroundSize: "400px",
        backgroundPosition: "center",
        borderRadius: '0',
        color: 'white',
        fontWeight: 'bold',
        border: '3px #413e68 solid',
        letterSpacing: '2px',
        marginTop: "1rem" }}
        onClick={() => navigation.next()}
      >
        Next
      </Button>
    </Container>
  );
};