import { Grid, IconButton, Typography } from "@mui/material";
import React, {useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { theme } from "../../Theme";
import {firestore} from '../../config/fbConfig'
import { Close } from "@material-ui/icons";
const MailingListModal = ({mailingListModalOpen, setMailingListModalOpen}) => {
	const [interests, setInterests] = useState([])
	const [homeChecked, setHomeChecked] = useState(false)
	const [autoChecked, setAutoChecked] = useState(false)
	const [petChecked, setPetChecked] = useState(false)
	const [lawnChecked, setLawnChecked] = useState(false)
	const [selfChecked, setSelfChecked] = useState(false)
	const [checked, setChecked] = useState(false)
	const [success, setSuccess] = useState(false)
	const [email, setEmail] = useState('')
	const [other, setOther] = useState('')

	const handleChange = (e, value) => {
		console.log(e)
		switch (value) {
			case 'Home Care Services':
				setHomeChecked(!homeChecked)
				
				break;
				case 'Lawn Care Services':
				setLawnChecked(!lawnChecked)
				
				break;
				case 'Pet Care Services':
				setPetChecked(!petChecked)
				
				break;
				case 'Auto Care Services':
				setAutoChecked(!autoChecked)
				
				break;
				case 'Self Care Services':
				setSelfChecked(!selfChecked)
				
				break;
				case 'Other':
				setChecked(!checked)
				
				break;
		
			default:
				break;
		}
		if(!!e.target.checked){
			setInterests((prev) => [...prev, value])
		}else{
			setInterests(interests.filter(x => x!== value))
		}
	}

const handleSubmit = () => {
	if(other !== ''){
		let services = interests.filter(service => service !== 'Other')
		services.push(other)
		// services.push(other)
		console.log('services =>', services)
		setInterests(services)
		//handle MailChimp Mailing Lists (Audience) Integration...
		firestore.collection('subscribers').add({
			email,
			interests,
			createdAt: new Date(),
		}).then(()=> {
			setSuccess(true)
			console.log(new Date())
			setTimeout(()=> setMailingListModalOpen(!mailingListModalOpen), 2000)
		})
		
	}else{
		//handle MailChimp Mailing Lists (Audience) Integration...
		firestore.collection('subscribers').add({
			email,
			interests,
			createdAt: new Date(),
		}).then(()=> {
			setSuccess(true)
			console.log(new Date())
			setTimeout(()=> setMailingListModalOpen(!mailingListModalOpen), 2000)
		})
	}
 }
	useEffect(() => {
	  console.log(interests)
	}, [interests])
	

  return (
          <Dialog open={!!mailingListModalOpen} onClose={()=>setMailingListModalOpen(!mailingListModalOpen)} >
            <Grid
              container
              item
              xs={12}
              style={{
                backgroundColor: "lightblue",
                height: "108vh",
                display: "flex",
                flexFlow: "column",
				position:'relative'
              }}
            >
				<IconButton style={{position:'absolute', right:5, top:5, zIndex:10}} onClick={()=>setMailingListModalOpen(!mailingListModalOpen)}>
					<Close/>
				</IconButton>
              <DialogTitle style={{fontWeight:'bold', fontSize:30, padding:'20px 20px 0px', textAlign:'center'}}>Looking for services?</DialogTitle>
              <DialogContent  style={{textAlign:'center', width:'100%'}}>
                <DialogContentText>
                  Let us know what you're looking for and we'll connect you with
                  service providers in your area!
                </DialogContentText>
              </DialogContent>
            </Grid>

            <FormControl component="fieldset" >
              <FormGroup
                aria-label="position"
                row
                style={{ display: "flex", justifyContent: "space-between", padding:20 }}
              >
                <FormControlLabel
                  value="Home Care Services"
                  control={<Checkbox  checked={homeChecked}
				  onChange={(e) => handleChange(e, e.target.value)}
				  inputProps={{ 'aria-label': 'controlled' }}/>}
                  label="Home Care"
                />
                <FormControlLabel
                  value="Lawn Care Services"
                  control={<Checkbox checked={lawnChecked} />}
				  onChange={(e) => handleChange(e, e.target.value)}
                  label="Lawn Care"
                />
                <FormControlLabel
                  value="Pet Care Services"
                  control={<Checkbox checked={petChecked} />}
				  onChange={(e) => handleChange(e, e.target.value)}
                  label="Pet Care"
                />
                <FormControlLabel
                  value="Auto Care Services"
                  control={<Checkbox checked={autoChecked} />}
				  onChange={(e) => handleChange(e, e.target.value)}
                  label="Auto Care"
                />
                <FormControlLabel
                  value="Self Care Services"
                  control={<Checkbox checked={selfChecked} />}
				  onChange={(e) => handleChange(e, e.target.value)}
                  label="Beauty & Wellness"
                />
				<FormControlLabel
					value="Other"
					control={<Checkbox checked={checked} />}
					onChange={(e) => handleChange(e, e.target.value)}

					label="Other"
				/>
				{!!checked &&
				<TextField
					id="outlined-basic"
					variant="outlined"
					label='Other'
					style={{ width: "100%" }}
					value={other}
					onChange={e => {setOther(e.target.value)}}
					required
				/>
				}
              </FormGroup>

            </FormControl>
            <Grid item container xs={12} style={{ display: "flex", justifyContent: "center", padding:'0px 20px' }}>
              <TextField
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                variant="outlined"
                style={{ width: "100%" }}
				value={email}
				onChange={e => setEmail(e.target.value)}
				required
              />
              <Button fullWidth variant="contained"  style={{backgroundColor: theme.primary.dark, color: theme.white, marginTop:10}}
			  disabled={email === '' || interests.length === 0 || (!!checked && other === '')}
			  onClick={handleSubmit}
			  >
                Submit
              </Button>
			  {!!success &&
			  <Typography variant="caption" style ={{color:theme.success, width:"100%", textAlign:'center', padding:10}}>
				Success, You have been added to the mailing list!
			  </Typography>
			  }
            </Grid>

            <DialogActions>
              <Button onClick={()=>window.location.assign('/signup')} style={{color: theme.secondary.dark}}>Provide Services?</Button>
            </DialogActions>
          </Dialog>
  );
};

export default MailingListModal;