import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import {Container, CssBaseline, Grid, Typography, Avatar,
    TextField, FormControlLabel, Button, Box, Checkbox, Link} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles';
import {auth} from '../../config/fbConfig'


   const useStyles = makeStyles((theme) => ({
    paper: {
      //marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      
    },
    avatar: {
      margin: theme.spacing(0),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }))


const LoginModal = () => {
    const history = useHistory()
    const classes = useStyles();
    const path = history.location.pathname
    console.log("included:", !path.includes('brand-ambassador'))

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setErr] = useState('')

    const handleSubmit = (e) => {
      e.preventDefault()

      if(!path.includes('brand-ambassador')){
        auth.signInWithEmailAndPassword(email, password)
        .then((user) => {
          if(user) {
            if(path.split('/')[1] !== 'pro'){
              history.push('/home')
            }
          }
        })
        .catch(err => console.log(err))
      }
      else{
        auth.signInWithEmailAndPassword(email, password)
        .then((user) => {
          if(user) {
          }
        })
        .catch(err =>  {
          if(err){
            console.log('Error:', err)
            setErr('Password or Email is incorrect.')
          }
        })
      }
    }


    return (
        <Grid container xs={11} md={4} lg={3} display='column' style={{display:'flex', justifyContent:'center', outline:'none', position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
             <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Sign in to Schedule
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    value={email}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    // autoFocus
                  />
                  <TextField
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    value={password}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />

                  <Typography style={{width:'inherit', textAlign:'center', color:'red'}}>{error}</Typography>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        {"No account? Sign Up!"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
        </Grid>
    )
}

export default LoginModal