import React, {useState} from "react";
import {Typography, Grid, Button, TextArea, TextField} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AddOnServiceCard from "./AddOnServiceCard";

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    title: {
      color: theme.palette.primary.main,
      padding: "8px 0 0 20px",
      fontWeight:'bold',
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    iconCounter: {
     '&:hover': {
       cursor: 'pointer'
     }
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    formControl: {
        width: '100%'
    },

  }));

function Notes({formData, setForm, userData, navigation, pro}) {
  //const { notes } = formData;
  const [notes, setNotes] = useState(formData.notes)
  const classes = useStyles();
  const addOnServices = pro.services.filter(service => service.addOn === true)
  
  //// console.log(formData.havePets , petTypes)
  // CREATe

  const handleAddNotes = (e) => {
    setNotes(e.target.value)
    formData.notes = e.target.value
  }

  const handleBack = () => {
    formData.addOns = []
    formData.addOnPrice = 0
    formData.serviceTimeEnd = formData.amtOfTime
    navigation.previous()
  }

   
  return (
    <Grid item xs={11} sm={10} md={8} lg={6} xl={5} container style={{outline:'none', height:600, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
      <Grid item xs={12}>
        <Typography variant='h5' className={classes.title}>Booking with: {formData.pro.name}</Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding:'0px 20px'}}>

        <Typography variant='h6' style={{color:"#413e68"}}>3. Would you like to add any Additional Services or Notes?</Typography>

        <Grid container item xs={12} style={{maxHeight:235, overflowY:'scroll', borderBottom:'1px solid black', marginBottom:15}}>

          {addOnServices.length === 0 ?
            <Typography style={{color:'gray', fontSize:12}}>There are no add-on services avialable at this time.</Typography>
          :
            addOnServices.map(service => {
              const name = service.name
              const serviceType = service.serviceType
              const time = service.time
              const price = service.price
              return(
                <AddOnServiceCard service={service} name={name} serviceType={serviceType} time={time} price={price} formData={formData} classes={classes}/>
              )
            })
          }
        </Grid>

        <TextField
            fullWidth
            label="Add a Note"
            name="notes"
            value={notes}
            variant='outlined'
            multiline
            rows={4}
            onChange={(e) => handleAddNotes(e)}
        />
      </Grid>


      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'flex-end'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ backgroundColor: 'white',  
              color: '#413e68'}}
            onClick={handleBack}>
            Back
        </Button>

      </Grid>

      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'left'}}> 
  
        <Button
            variant="contained"
            className={classes.btn}
            color='primary'
            onClick={() => navigation.next()}>
            Next
        </Button>

      </Grid>
    </Grid>
  );
}

export default Notes
