import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Container, CssBaseline, Grid, Typography, Avatar, InputBase,
    TextField, withWidth, Button, Box, Hidden, Link, Snackbar, CircularProgress} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {makeStyles} from '@material-ui/core/styles';
import {auth, firestore} from '../config/fbConfig'
import { useHistory } from 'react-router';
import axios from 'axios';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { set } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    paper: {
      //marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      
    },
    infoBox:{
        padding:20,
        display:'flex',
        flexFlow:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    infoBoxTxt: {
        width:'inherit',
        textAlign:'center'
    },
    proAvatar:{
        height:80,
        width:80,
        marginBottom:5
    },
    balance: {
        fontSize:30,
        fontWeight:'bold',
        width:'inherit',
        textAlign:'center',
        color: theme.palette.primary.main,
    },
    avatar: {
      margin: theme.spacing(0),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    input: {
        margin: 5,
        height: 40,
        border: '1px solid grey',
        borderRadius: 6,
        padding: '0px 5px'
      },
      inputContainer: {
          padding: 5
      },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

function Payment({navigation, userData, currentUser, width}) {

    const classes = useStyles()
    const history = useHistory()
    const pathName = history.location.pathname.split('/')[2]
    const [loading, setLoading] = useState(null)
    const [appointment, setAppointmet] = useState(null)
    const [error, setErr] = useState('')
    const gridSize = (width==='xs' || width==='sm') ? 12 : 6
    
    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [tip, setTip] = useState('0')
    const [address, setAddress] = useState((userData.address !== undefined) ? userData.address.split(', ') : '')
    const [street, setStreet] = useState((userData.address !== undefined) ? address.shift() : '')
    const [city, setCity] = useState((userData.address !== undefined) ? address.slice(-3,address.length).shift() : '')
    const [st, setST] = useState((userData.address !== undefined) ? address.slice(-2,address.length).shift()  : '')
    const [zip, setZip] = useState('');

    const [isProcessing, setProcessing] = useState(false)
    const [success, setSuccess] = useState(false)
    const [failure, setFailure] = useState(false)
    const [checkoutError, setCheckoutError] = useState(null)


    useEffect(() => {
      
        if(loading === null && appointment === null){
           console.log(pathName)
           setLoading(true)
           firestore.collection('appointments').doc(`${pathName}`).get().then((doc) => {
            if (doc.exists) {
                setAppointmet(doc.data());
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        }

        if(appointment!== null && loading === true){
            setLoading(false)
        }

        console.log(appointment, loading)
    

    }, [loading, appointment])



    const cardElementOptions = {
        hidePostalCode: true,
    }

    
    const handlePayBalance = async (e) => {
        e.preventDefault()
        setProcessing(true)


        const billingDetails = {
            name: appointment.clientName,
            email: appointment.clientEmail,
            address: {
                city: city,
                line1: street,
                state: st,
                postal_code: zip,
            }
        };

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: billingDetails,
        })
        
        if(!error) {
            console.log(paymentMethod)
            try {
                const {id} = paymentMethod
                const response = (userData && userData.test===false) ? await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment', {
                    amount: appointment.amtRemainingBalance * 100,
                    id,
                    description: `Cuti App: ${appointment.service} w/ ${appointment.proName} (BALANCE)`,
                    customer: userData.customerId,
                    email: userData.email,
                    proStripeId: appointment.proStripeId,
                    fee: tip !=="0" ? 
                    parseFloat(parseFloat(((parseFloat(tip) + parseFloat(appointment.amtRemainingBalance)) * 0.031 + .30) * 100).toFixed())
                    :
                    ((parseFloat(appointment.amtRemainingBalance) * 0.029) + .30).toFixed(2) * 100
                })
                :
                await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/payment-test', {
                    amount:( parseFloat(appointment.amtRemainingBalance) + parseFloat(tip)) * 100,
                    id,
                    description: `Cuti App: ${appointment.service} w/ ${appointment.proName} (BALANCE)`,
                    customer: userData.customerId,
                    email: userData.email,
                    proStripeId: appointment.proStripeId,
                    fee: tip !=="0" ? 
                    parseFloat(parseFloat(((parseFloat(tip) + parseFloat(appointment.amtRemainingBalance)) * 0.031 + .30) * 100).toFixed())
                    :
                    ((parseFloat(appointment.amtRemainingBalance) * 0.029) + .30).toFixed(2) * 100
                })

                if(response.data.success){
                    // console.log("Successful Payment")     

                    //Move to dispatch
                    firestore.collection("appointments").doc(pathName).update({
                        amtPaid: (parseFloat(appointment.amtPaid) + parseFloat(appointment.amtRemainingBalance) + parseFloat(tip)).toFixed(2),
                        amtRemainingBalance: '0',
                        clientStatus:'paid',
                        tip: tip !== '0' ? (parseFloat(tip) - ((parseFloat(appointment.amtRemainingBalance) + parseFloat(tip))*.031 + .30)).toFixed(2) : '0'
                    })
                    .then(() => {
                        if(appointment.serviceComplete === true){
                            firestore.collection('customers').doc(userData.userId)
                            .update({
                                accountStatus:'active',
                            })
                        }
                    })
                    .then(()=> {
                        let promises = []
                        firestore.collection('appointments').where('clientUID','==', `${appointment.clientUID}`)
                        .where('serviceComplete', '==', false).where('serviceCanceled','==', false).where('serviceTimeStart', '==', 'n/a')
                        .get()
                        .then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                const id = doc.id
                                console.log(id)
                                const p = firestore.collection('appointments').doc(id).update({clientStatus: 'active'})
                                promises.push(p)
                            });
                            return Promise.all(promises)
                            })
                            .catch((error) => {
                            console.log("Error trying to update all appointments:", error);
                        });
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
                    setSuccess(true)
                    setProcessing(false)
                    setTimeout(()=> window.location.reload(), 2000)
                }
            } catch (error) {
                console.log("Error:", error)
            }
        } else {
            setFailure(true)
            setProcessing(false)
            setCheckoutError(error.message)
        }
    }

    


  return (
    <Grid item container xs={12} style={{display:"flex", flex:'1 1 auto', alignItems:'center', position: 'relative'}}>
    <Hidden smDown>
      <Grid item container xs={0} md={6} 
          style={{backgroundImage:"url('https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-934877872-1571657144.jpg')", 
          backgroundPosition:'center top',
          backgroundRepeat:'no-repeat', 
          backgroundSize:'cover',
          height:'95vh', }}>
      </Grid>
    </Hidden>

    <Grid container item xs={12} md={6} style={{ padding:'0px 20px', display:'flex', justifyContent:'center'}}>

        {(appointment === null && loading===true) && 
        <CircularProgress style={{color: '#413e68'}}/>
    }

        {appointment !== null ? 
        parseFloat(appointment.amtRemainingBalance) !== 0 ?
        <Grid container item xs={10} className={classes.infoBox}>
            <Typography variant='h6' style={{color:"#413e68", fontSize:30, marginBottom:10}}>Checkout</Typography>
            <Avatar src={appointment.proAvatar}/>
            <Typography className={classes.infoBoxTxt} style={{fontWeight:'bold'}}>Thank you for booking with us!</Typography>
            {/* <Typography className={classes.infoBoxTxt}>Your Appointment with {appointment.proName} is complete.</Typography> */}
            <Typography variant='caption' className={classes.infoBoxTxt}>Please fill out the form below to play your balance upon completion.</Typography>
            <Typography className={classes.balance}>Balance: {(parseFloat(appointment.amtRemainingBalance) + parseFloat(tip)).toFixed(2)}</Typography>
            {tip !== '0' &&
            <Typography>Tip: ${parseFloat(tip).toFixed(2)}</Typography>
            }

            <form onSubmit={handlePayBalance} style={{padding:10, marginTop:10}}>

            <Grid xs={12} className={classes.inputContainer} style={{paddingLeft:15}}>
                <Typography>Tip:</Typography>
                <Grid style={{display:'flex', justifyContent:'space-between'}}>
                    <Grid item xs={3}>
                        <Button fullWidth variant={tip !== (parseFloat(appointment.serviceFee) * .15).toFixed(2) ? 'outlined' : 'contained'} color='primary' onClick={()=>setTip((parseFloat(appointment.serviceFee) * .15).toFixed(2))}>15%</Button>

                    </Grid>
                    <Grid item xs={3}>
                        <Button fullWidth variant={tip !== (parseFloat(appointment.serviceFee) * .18).toFixed(2) ? 'outlined' : 'contained'} color='primary' onClick={()=>setTip((parseFloat(appointment.serviceFee) * .18).toFixed(2))}>18%</Button>

                    </Grid>
                    <Grid item xs={3}>
                        <Button fullWidth variant={tip !== (parseFloat(appointment.serviceFee) * .22).toFixed(2) ? 'outlined' : 'contained'} color='primary' onClick={()=>setTip((parseFloat(appointment.serviceFee) * .22).toFixed(2))}>22%</Button>

                    </Grid>
                    
                    <Grid item xs={3}>
                        <Button fullWidth variant={tip !== '0' ? 'outlined' : 'contained'} color='primary' onClick={()=>setTip('0')}>none</Button>
                    </Grid>
                    
                </Grid>
            </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={12} md={6} className={classes.inputContainer}>
                        <InputBase placeholder='Full Name' variant="outlined" className={classes.input} fullWidth value={name} onChange={(e)=> setName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.inputContainer}>
                        <InputBase placeholder='Email' variant="outlined" className={classes.input} fullWidth value={email} onChange={(e)=> setEmail(e.target.value)} /> 
                    </Grid>

                    {/* <Grid item xs={6} className={classes.inputContainer}>
                        <InputBase placeholder='Steet Address' variant="outlined" className={classes.input} fullWidth value={street} onChange={(e)=> setStreet(e.target.value)} />
                    </Grid>
                    <Grid item xs={6} className={classes.inputContainer}>
                        <InputBase placeholder='City' variant="outlined" className={classes.input} fullWidth value={city} onChange={(e)=> setCity(e.target.value)} />
                    </Grid> */}


                    <Grid item xs={6} className={classes.inputContainer}>
                        <InputBase placeholder='State' variant="outlined" className={classes.input} fullWidth value={st} onChange={(e)=> setST(e.target.value)} />
                    </Grid>
                    <Grid item xs={6} className={classes.inputContainer}>
                        <InputBase placeholder='Zip' variant="outlined" className={classes.input} fullWidth value={zip} onChange={(e)=> setZip(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <div className={classes.input} style={{width: 'inherit',  padding: '10px', margin: '0px 0px 0px 6px', border:'1px solid, grey', borderRadius: 6}}>
                            <CardElement options={cardElementOptions}/>
                        </div>
                    </Grid>


                </Grid>
                <Grid item xs={12} style={{display:'flex', justifyContent: 'center'}}>
                    
                    {!!isProcessing ?
                        <div>
                            <CircularProgress style={{color: '#413e68'}}/>
                            <Typography>Processing...</Typography>
                        </div>
                        :
                        <Button
                            type='submit'
                            variant="contained"
                            className={classes.btn}
                            fullWidth
                            style={{ backgroundColor: '#413e68', color: 'white'}}
                            >
                            Pay
                        </Button>
                    }
                
                </Grid>
            </form>
        </Grid>

        :

        <Grid container item xs={10} className={classes.infoBox}>
            <Typography variant='h6' style={{color:"#413e68", fontSize:30, marginBottom:10}}>Balance Paid!</Typography>
            <Avatar src={appointment.proAvatar}/>
            <Typography className={classes.infoBoxTxt} style={{fontWeight:'bold'}}>Thank you for booking with us!</Typography>
            <Typography className={classes.infoBoxTxt}>Your Appointment with {appointment.proName} is all paid up.</Typography>
            <Typography className={classes.balance}>Balance: {appointment.amtRemainingBalance}</Typography>
            
            
            {(appointment.virtual && appointment.serviceComplete === false) &&
                <Grid item container xs={12} style={{display:'flex', justifyContent:'center', flexDirection:'row'}}>
                    <Typography style={{marginTop:50}}>{appointment.serviceLocation}</Typography>
                    <Button fullWidth variant='contained' color='primary' onClick={() => {window.open(`${appointment.serviceLocation}`)}}>Join Meeting</Button>

                </Grid>
            }

            {(appointment.virtual && appointment.serviceComplete === true) &&
                <Typography className={classes.infoBoxTxt} style={{marginTop:50}}> <em>Your zoom link is no longer available.</em> </Typography>
            }
        </Grid>

        :

        null

        }

        
    </Grid>

    <Snackbar
        open={success}
        autoHideDuration={2000}
        // onClose={()=> {setSuccess(false); window.location.reload();}}
        onClose={()=> {setSuccess(false); navigation.next();}}
        message="Success! You're all wrapped up! 🎉"
    />
    <Snackbar
        open={failure}
        autoHideDuration={2000}
        // onClose={()=> {setSuccess(false); window.location.reload();}}
        onClose={()=> {setFailure(false); setCheckoutError(null)}}
        message={checkoutError}
    />



    </Grid>





  )
}

Payment.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(Payment)