import React, {useState, useEffect} from 'react'
import Cards from 'react-credit-cards'
import ReactPaginate  from 'react-paginate'
import 'react-credit-cards/es/styles-compiled.css'
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Grid, IconButton, Tooltip, Typography} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { firestore } from '../../../../config/fbConfig';
import firebase from 'firebase';


const useStyles = makeStyles((theme) => ({
    pagination: {
        margin: '15px auto',
        display: 'flex',
        listStyle: 'none',
        outline: 'none',
        fontSize:'14px',
        alignItems:'center',
        color: '#737373',
        alignSelf: 'center',
        paddingInlineStart: '0px',
        justifyContent:'center'
    },
    page: {
        color: '#737373',
        border: '1px solid #737373',
        borderRadius:'25px',
        padding: '6px 12px',
        margin: "0px 5px",
        "&:hover":{
            backgroundColor: 'white' ,
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            cursor:'pointer'
        }
    },
    link: {
    },
    active: {
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        fontWeight:'bold',
    },
    navigation:{
        fontWeight: 'bold',
        fontSize: 20,
        "&:hover":{
            color: theme.palette.primary.main,
            cursor:'pointer'
        }
    },
    remove: {
        "&:hover":{
            cursor: 'pointer'
        },
        position:'absolute',
        top: -15,
        right: -15,
        background: '#ffffffad'
    },
    primary:{
        position:'absolute',
        top: -15,
        left: -15,
        color: 'white',
        fontSize: 12,
        backgroundColor: theme.palette.primary.main,
        padding: '5px 10px',
        borderRadius: '8px'
    },
    setPrimary:{
        "&:hover":{
            cursor: 'pointer'
        },
        position:'absolute',
        top: -15,
        left: -15,
        backgroundColor: 'white',
        fontSize: 12,
        color: theme.palette.primary.main,
        padding: '5px 10px',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.primary.main}`
    },
    blur:{
        position:'absolute',
        height: 24,
        backdropFilter: "blur(5px)",
        zIndex: 1,
        top:84,
    },
    
  }));

function PaymentMethods({userData, modalOpen, setModalOpen}) {
    const classes = useStyles()
    const data = userData.billingInfo
    
    const [cards, setCards] = useState([])
    const [pageNumber, setPageNumber] =  useState(0)
    
    const perPage = 1
    const cardsViewed = pageNumber * perPage
    
    //const [pageCount, setPageCount] = useState(0)
    const pageCount = Math.ceil(cards.length / perPage)

    useEffect(() => {
        
        if(data){
            setCards(data)
        }
        //// console.log(cardsViewed)
    }, [data, cards, cardsViewed])

    const handleRemoveCard = () => {
        cards.map((card, x) => {
            if(x === cardsViewed){
                var userRef = firestore.collection(`${userData.collection}`).doc(`${userData.userId}`);
                userRef.update({
                    billingInfo: firebase.firestore.FieldValue.arrayRemove(card)
                }).then(() => window.location.reload())
               
            }
        })
    }
    const handleNewPrimaryCard = () => {
        var userRef = firestore.collection(`${userData.collection}`).doc(`${userData.userId}`);

        cards.sort((a,b) =>  a.primary < b.primary && 1 || -1).map((card, x) => {
            if(!!card.primary){
                userRef.update({
                    billingInfo: firebase.firestore.FieldValue.arrayUnion({cardHolder: card.cardHolder, cardNumber: card.cardNumber, expiration: card.expiration, cvc: card.cvc, primary:false})
                })
                .then(() => 
                userRef.update({
                    billingInfo: firebase.firestore.FieldValue.arrayRemove(card)
                }))
            }
            else if(x === cardsViewed){
                // console.log(cards[cardsViewed])
                userRef.update({
                    billingInfo: firebase.firestore.FieldValue.arrayUnion({cardHolder: cards[cardsViewed].cardHolder, cardNumber: cards[cardsViewed].cardNumber, expiration: cards[cardsViewed].expiration, cvc: cards[cardsViewed].cvc, primary:true})
                })
                .then(() => 
                userRef.update({
                    billingInfo: firebase.firestore.FieldValue.arrayRemove(cards[cardsViewed])
                }))
                .then(() => window.location.reload())
            }
        })
    }

    
    const displayCard = cards.sort((a,b) =>  a.primary < b.primary && 1 || -1).slice(cardsViewed, cardsViewed + perPage).map((card, idx) => {
        
         return(
                <div key={idx} style={{position: 'relative'}}>
                    {card.cardNumber.toString().split('').length === 16 ? 
                        <div className={classes.blur} style={{width:200}}></div>
                    :
                        <div className={classes.blur} style={{width:188}}></div>
                    }
                    <Cards number={card.cardNumber} name={card.cardHolder} expiry={card.expiration} cvc={card.cvc}></Cards>
                    {!!card.primary ?
                    <Typography className={classes.primary}>Primay</Typography>
                    :
                    <Typography className={classes.setPrimary} onClick={handleNewPrimaryCard}>Set As Primay</Typography>
                    }

                    {!!card.primary ? 
                    <Tooltip 
                    title="This is the primary card and cannot be removed until you set another card as the primary payment method. Please contact customer support for any assistance. Thank you.">
                        <IconButton className={classes.remove}>
                            <HighlightOffIcon/>
                        </IconButton>
                    </Tooltip>
                    :
                    <IconButton className={classes.remove} onClick={handleRemoveCard}>
                        <HighlightOffIcon/>
                    </IconButton>
                    }

                </div>
                )
            })
        
    

    const pageChange = ({selected}) => {
        setPageNumber(selected)
    }
    // const [currentPage, setCurrentPage] = useState(0)
    // // console.log(data)
    //const pageCount = Math.ceiling(userData.billingInfo.length / perPage)
    // const offset = 0
    // var [currentCard, setCurrentCard] = useState(null)
    //const [orgTabelData, setOrgTableData] = useState([])


    return (
        <div style={{alignItems:'center'}}>
            {displayCard}
            {cards.length > 0 &&
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    onPageChange={pageChange}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    //onPageChange={handlePageClick}
                    containerClassName={classes.pagination}
                    pageClassName={classes.page}
                    pageLinkClassName={classes.link}
                    activeClassName={classes.active}
                    previousClassName={classes.navigation}
                    nextClassName={classes.navigation}
                    subContainerClassName={classes.pagesPagination}
                />
            }

           {cards.length === 0 &&
                <Grid item xs={12} container style={{dispplay:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
                    <Typography variant="h5">You don't have any Cards on file.</Typography>
                    <Typography variant="subtitle2">Add a new debit/credit card to get started!</Typography>
                    <IconButton onClick={() => setModalOpen(!modalOpen)}><AddCircleOutlineIcon/></IconButton>
                </Grid>
           }
            
            

            
        </div>
    )
}

export default PaymentMethods
