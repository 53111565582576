import React from "react";
import {Typography, Grid, Button, TextArea, TextField} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
const { ms, s, m, h, d } = require('time-convert')
const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    formControl: {
        width: '100%'
    },

  }));

function AppointmentTips({formData, userData, navigation}) {
  const classes = useStyles();

  var gapi = window.gapi
  var CLIENT_ID = "5074722352-4q8icoa3g669h4v8f3fqltril5spaa56.apps.googleusercontent.com"
  var API_KEY = "AIzaSyDa_I8zG8L-tfutMU97YEkiBSZ1IRopaGw"
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = 'https://www.googleapis.com/auth/calendar.events'

  const handleReset= () => {
    const t = (m.to(ms)(formData.serviceTimeEnd))
    
      gapi.load('client:auth2', () => {
          // console.log('loaded client')
          // console.log(CLIENT_ID)
          gapi.client.init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              discoveryDocs: DISCOVERY_DOCS,
              scope: SCOPES,
          })
  
          gapi.client.load('calendar', 'v3', ()=> console.log('calender load...'))
  
          gapi.auth2.getAuthInstance().signIn()
          .then(() => {
            // console.log('adding event!!')
            var event = {
              'summary': `${formData.service + ": " + formData.pro.name + " <> " + formData.client.name}`,
              'location': `${formData.address}`,
              'description': `You have a Scheduled Appointment Via Cuti App: ${formData.service}`,
              'start': {
                'dateTime': new Date(formData.dateTime).toISOString(),
                'timeZone': 'America/Los_Angeles'
              },
              'end': {
                'dateTime': new Date(parseFloat(t.join()) + new Date(formData.dateTime).getTime()).toISOString(), //this is an iso
                'timeZone': 'America/Los_Angeles'
              },
              'recurrence': [
                // 'RRULE:FREQ=DAILY;COUNT=2'
              ],
              'attendees': [
                {'email': `${formData.pro.email}`},
              ],
              'reminders': {
                'useDefault': false,
                'overrides': [
                  {'method': 'email', 'minutes': 24*60},
                  {'method': 'email', 'minutes': 60},
                  {'method': 'popup', 'minutes': 10}
                ]
              }
            }
  
            var request = gapi.client.calendar.events.insert({
              'calendarId': 'primary',
              'resource': event,
            })
  
            request.execute( event => 
              window.open(event.htmlLink))
          }).then(() => setTimeout(()=> {window.location.reload()}, 20000)) 
      })
  
  }


  
  return (
    <Grid item xs={12} container style={{outline:'none', position:'absolute', height:600, top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
      <Grid item xs={12} style={{borderRadius: '8px 8px 0 0', background: '#413e68', height: "50px"}}>
        <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Confirmation</Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding:'0px 20px', textAlign:'center'}}>

        <Typography variant='h4' style={{color:"#413e68", width:'inherit'}}><span role="img" aria-label="celebrate">🎉 </span> You're all set!</Typography>

          <ul style={{textAlign:'left'}}>
              <li style={{color:'#413e68'}}>
                  <Typography style={{color:'black'}}>Be sure to arrive on time. If you will be late, contact your Pro and give notice.</Typography>
              </li>
              
              <li style={{color:'#413e68'}}>
                  <Typography style={{color:'black'}}>Safety is key so ensure you have a mask available.</Typography>
              </li>
              
              <li style={{color:'#413e68'}}>
                  <Typography style={{color:'black'}}>You can now message your Pro directly via this app. Feel free to reach out with any questions!</Typography>
              </li>
          </ul>
          
      </Grid>


      <Grid container item xs={12} style={{ padding:'0px 10px 36px 10px', justifyContent: 'center'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleReset}>
            Add to Calendar
        </Button>

      </Grid>
    </Grid>
  );
}

export default AppointmentTips
