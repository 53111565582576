import React, {useRef, useState, useEffect, useCallback} from "react";
import {Typography, Container, Button, Accordion, Grid, AccordionSummary,
  ListItemText, Avatar, Modal, CircularProgress} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AccordionDetail from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {auth, firestore, storage} from '../../../../../config/fbConfig';
import { useHistory } from "react-router";
import Slider from '@material-ui/core/Slider'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../../../modals/canvasUtils'
import Cropper from 'react-easy-crop'

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}


var generator = require('generate-password');
var randomKey = generator.generate({
	length: 12,
	numbers: true
});

const useStyles = makeStyles((theme) => ({
  profileContainer:{
    position: 'relative',
    display:"flex",
    flexFlow:'column',
    alignItems:'center',
    padding: 10,
    marginBottom: 12,
  },
  avatar: {
    height: 140,
    width: 140,
  },
  imgSelecter:{
    position:'absolute',
    top: 120,
    left: '52%',
    border: '2px solid white',
    backgroundColor: theme.palette.primary.main,
    '&:hover':{
      cursor:'pointer',
      opacity: .8,
    },
  },
  accordianDetails:{
    width:'100%',
    display:'flex',
    flexFlow:'column',
    textAlign: 'left'
  },
  accordianEditBtn:{
    alignSelf:'flex-end'
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    width:'100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },

}));

export const Review = ({ formData, navigation, pro }) => {
  const history = useHistory();
  const classes = useStyles();
  const fileInput = useRef();
  const imgRef = useRef(null);
  const [imageLoading, setImageLoading] = useState(false);

  const [imageSrc, setImageSrc] = React.useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const { go } = navigation;
  //const storageRef = storage.ref();
  const [av,setAvatar] = useState("");
  const {
    firstName,
    lastName,
    title,
    address,
    coordinates,
    phone,
    email,
    password,
    ratings,
    services,
    works,
    displayName,

  } = formData;

  console.log(services)


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const uploadCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )

      var xhr = new XMLHttpRequest();
      xhr.open('GET', `${croppedImage}`, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status === 200) {
          var myBlob = this.response;
          // myBlob is now the blob that the object URL pointed to.
            
              let fileurl = ''
              var uploadTask = storage.ref(`ProInfo/${email}-${firstName}-${lastName}/images/avatar/avatar`).put(myBlob)
              uploadTask.on('state_changed', 
                (snapshot) => {
                  //Handle progress...
                  setImageLoading(true)
                }, 
                (error) => {
                  // Handle unsuccessful uploads...
                }, 
                async () => {
                  // Handle successful uploads on complete...
                  await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
                    fileurl = url
                    // console.log('File available at', fileurl)
                    setAvatar(url)
                    formData.avatar = url
                    setImageLoading(false)
                    closeModal()
                  })
                }
              )
            
        }
      };
      xhr.send();

      // console.log(croppedImage)
      setCroppedImage(croppedImage)


    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation, email, firstName, lastName, formData])

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      const orientation = await getOrientation(file)
      const rotation = ORIENTATION_TO_ANGLE[orientation]
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      }

      setImageSrc(imageDataUrl)
    }
  }
  
  
  const select = () => {
      fileInput.current.click()
    }
  
  
    const closeModal = () => {
      setImageSrc(null)
    }


  const handleSubmit = () => {
    
        firestore.collection('prospects').doc(pro.url).update({
            accountStatus: 'claimed',
            lastUpdated: new Date(),
        }).then(()=> {
            auth.createUserWithEmailAndPassword(email, password)
            .then((resp) => {
              
                return firestore.collection('professionals').doc(resp.user.uid).set({
                  accountStatus: 'onboarding',
                  address: address,
                  apptsCanceled: 0,
                  apptsCompleted: 0,
                  apptsScheduled: 0,
                  availability:[{day:'sun', slots:[{from:"08:00",to:"17:00"}]},
                                {day:'mon', slots:[{from:"08:00",to:"17:00"}]},
                                {day:'tue', slots:[{from:"08:00",to:"17:00"}]},
                                {day:'wed', slots:[{from:"08:00",to:"17:00"}]},
                                {day:'thu', slots:[{from:"08:00",to:"17:00"}]},
                                {day:'fri', slots:[{from:"08:00",to:"17:00"}]},
                                {day:'sat', slots:[{from:"08:00",to:"17:00"}]}
                              ],
                  bio: "",
                  busy:false,
                  buffer: 30,
                  clients:[],
                  collection: 'professionals',
                  convenienceFee:'',
                  convenienceFeeReq:false,
                  coordinates: coordinates,
                  createdAt: new Date(),
                  displayName: displayName,
                  email: email,
                  firstName: pro.firstName !== "" ? pro.firstName : firstName,
                  images:{ avatar: av !=="" ? av : pro.images.avatar, header: '', works: works},
                  lastName: lastName,
                  lastUpdated: new Date(),
                  notifSettings: {
                    msgAlertsText: true, msgAlertsEmail: true, msgAlertsPush: true,
                    newApptAlertsText: true, newApptAlertsEmail: true, newApptAlertsPush: true,
                    apptCompletedAlertsText: true, apptCompletedAlertsEmail: true, apptCompletedAlertsPush: true,
                    apptCanceledAlertsText: true, apptCanceledAlertsEmail: true, apptCanceledAlertsPush: true,
                    apptStartedAlertsText: true, apptStartedAlertsEmail: true, apptStartedAlertsPush: true,
                    apptRescheduledAlertsText: true, apptRescheduledAlertsEmail: true, apptRescheduledAlertsPush: true,
                                },
                  online: false,
                  partner: false,
                  phone: phone,
                  qrCashApp:"",
                  qrPayPal:"",
                  qrVenmo:"",
                  ratings: ratings,
                  reviews:[],
                  services: services,
                  state: address.split(', ').slice(2,3).join(),
                  url: firstName+'-'+lastName+'-'+randomKey,
                  userId: resp.user.uid,
                  travel: false,
                  test: false,
                  title: title.join(" | "),
                  showAdress:false,
                  showPhone: false,
                  showDisplay: false,
                  showEmail:false,
                  socialMedia:{
                    facebook:'',
                    instagram:'',
                    tiktok:'',
                    twitter:'',
                  }
                })
                
            })
            .then(() =>{
              const user = auth.currentUser
              user.sendEmailVerification()
            })
            .then(() => {
                history.push('/home')
            })
            .catch(err => console.error(err))
        })
  }

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Review</Typography>

      <Grid container item xs={12} className={classes.profileContainer}>
        <input style={{display: 'none'}}
          type="file"
          id="image"
          name="image"
          onChange={onFileChange}
          ref={fileInput}
          accept=".gif,.jpg,.jpeg,.png"
          /> 
            <Avatar className={classes.avatar} src={av !== "" ? av : pro.images.avatar}>
            </Avatar>
        <Avatar className={classes.imgSelecter} onClick={select}>
          <AddAPhotoIcon/>
        </Avatar>

        {/* <Typography variant='h4'>{firstName + " " + lastName}</Typography>
        <Typography variant='h5'>{title}</Typography> */}

      </Grid>

      <RenderAccordion summary="Account" go={ go } details={[
        { 'First Name': firstName !== "" ? firstName : pro.firstName },
        { 'Last Name': lastName !== "" ? lastName : pro.lastName },
        { 'Display Name': displayName !== "" ? displayName : pro.displayName },
        { 'Title': title.length !== 0 ? title.join(" | ") : pro.title },
        { 'Phone': phone !== "" ? phone : pro.phone },
        { 'Address': address},
        { 'Email': email !== "" ? email : pro.email },
        { 'Password': password },
      ]} />


      <Button
        color="primary"
        variant="contained"
        style={{ backgroundColor: '#413e68',  
        backgroundSize: "400px",
        borderRadius: '0',
        color: 'white',
        fontWeight: 'bold',
        border: '3px #413e68 solid',
        letterSpacing: '2px',
        marginTop: '1.5rem' }}
        onClick={handleSubmit}
      >
        Submit
      </Button>

      <Modal open={imageSrc !== null} onClose={closeModal}>
        <Grid container item xs={10} sm={6} md={4}
          style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:'30px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none', maxHeight:'90%'}}>
            <div style={{width:'inherit', height:'inherit', position:'relative'}}>
              <div className={classes.cropContainer}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
            </div>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}
                >
                  Rotation
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  classes={{ root: classes.slider }}
                  onChange={(e, rotation) => setRotation(rotation)}
                />
              </div>
            </div>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
                <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={closeModal}>Cancel</Button>
                {imageLoading === false ?
                <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={uploadCroppedImage}>Upload</Button>
                :
                <div className={classes.wrapper}>
                <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} disabled>Uploading...</Button>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </div> 
                }

              </Grid>
          </Grid>

      </Modal>

    </Container>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const RenderAccordion = ({ summary, details, go }) => {

  return(
  <Accordion>
    <AccordionSummary style={{fontSize:22}}
      expandIcon={<ExpandMoreIcon/>}
    >{summary}</AccordionSummary>
    <AccordionDetail>
      <div style={{width:'100%', display:'flex', flexFlow:'column', textAlign:'left'}}>
        { details.map((data, index) => {
          const objKey = Object.keys(data)[0];
          const objValue = data[Object.keys(data)[0]];

          return <ListItemText key={index}><span style={{fontWeight:'bold'}}>{objKey}: </span> {`${objValue}`}</ListItemText>

        }) }
        <IconButton style={{alignSelf:'flex-end'}}
          color="primary"
          component="span"
          onClick={() => go(`${summary.replace(/ /g,'').toLowerCase()}`)}
        ><EditIcon /></IconButton>
      </div>
    </AccordionDetail>
  </Accordion>
)}