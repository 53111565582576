import React, {useEffect} from "react";
import {Typography, Grid, TextField, Button, Paper, InputBase, FormControl,
    Select, InputLabel, MenuItem, Tooltip} from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import haversine from "haversine-distance"


const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    title: {
      color: theme.palette.primary.main,
      padding: "8px 0 0 20px",
      fontWeight:'bold',
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    formControl: {
        width: '100%'
    },

  }));
  

function Pets({ formData, setForm, navigation, selectedService }) {
    const { address, petTypes, pro } = formData;
  const [havePets, setHavePets] = React.useState(formData.havePets)
  const classes = useStyles();
  const disabled = (formData.havePets === '' || (formData.havePets === 'Yes' && petTypes === '')) ? true : false
  console.log(formData.serviceCost)
  
  var haversine_m = haversine(formData.startingCoordinates, pro.coordinates)
  var distance = (haversine_m / 1609.344).toFixed(2)


  const handlePetSelect = (e) => {
    formData.havePets = e.target.value
    setHavePets(formData.havePets);
  }

  useEffect(() => {

    if(formData.startingCoordinates === pro.coordinates || (selectedService.zoomLink && selectedService.zoomLink !== "")){
      // // console.log('they equal')
      formData.travelFee = '0.00'
    }
    else if(formData.startingCoordinates !== pro.coordinates && pro.convenienceFeeReq === true){
      if(pro.convenienceFee.includes('$')){
        formData.travelFee = pro.convenienceFee.split('$')[1]
      }else if(pro.convenienceFee.includes('%')){
        formData.travelFee = (parseFloat("." + pro.convenienceFee.split('%')[0]) * parseFloat(formData.serviceCost)).toFixed(2)
      }
    }
    else{
      formData.travelFee = (parseFloat(distance)*0.75).toFixed(2)
    }

    if((selectedService.zoomLink && selectedService.zoomLink !=="")){
      formData.havePets = "No"
      navigation.next()
    }

  }, [distance, formData, pro])

  // // console.log(formData.startingCoordinates , petTypes)
  console.log(formData)
  
  return (
    <Grid item xs={11} sm={10} md={8} lg={6} xl={5} container style={{outline:'none', height:600, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
      <Grid item xs={12}>
        <Typography variant='h5' className={classes.title}>Booking with: {pro.name}</Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding:'0px 20px'}}>

        <Typography variant='h6' style={{color:"#413e68"}}>3. Where we'll meet.</Typography>
        <Paper component='form' className={classes.root}>
            <InputBase value={address} disabled className={classes.input}/>
        </Paper>


      </Grid>

      <Grid container item xs={12} md={6} style={{padding: '0 10px 0 20px' }}>
          <Grid item xs={12}>
            {pro.travel === false ? 
            <Typography variant='h6' style={{color:"#413e68"}}>4. Do you have a service animal?</Typography>
            :
            <Typography variant='h6' style={{color:"#413e68"}}>4. Do you have any pets?</Typography>
            }
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id='pet-select-label'>Yes/No</InputLabel>
                <Select
                    labelId='pet-select-label'
                    id='pet-select'
                    value={havePets}
                    onChange={handlePetSelect}
                    label='Yes/No'
                    >

                    <MenuItem value={'Yes'}>Yes</MenuItem>
                    <MenuItem value='No'>No</MenuItem>
            
                </Select>
            </FormControl>
          </Grid>


      </Grid>

      <Grid container item xs={12} md={6} style={{padding: '0 20px 0 10px'}}>
        
        {havePets === 'Yes' &&
            <Grid item xs={12}>
              <Typography variant='h6' style={{color:"#413e68"}}>Type of pet(s)?*</Typography>
            </Grid>
        }

        {havePets === 'Yes' &&
            <Grid item xs={12}>
              <TextField 
                placeholder="e.g. 1 Dog, 2 Cats"
                name="petTypes"
                value={petTypes}
                onChange={setForm}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                required
                fullWidth/>
            </Grid>
        }


      </Grid>

      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'flex-end'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ backgroundColor: 'white',  
              color: '#413e68'}}
            onClick={() => navigation.previous()}>
            Back
        </Button>

      </Grid>

      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'left'}}> 
      <Tooltip title="Let us know if you have pets?" disableHoverListener={!disabled}>
        <Button
            disabled={disabled}
            variant="contained"
            className={classes.btn}
            color='primary'
            onClick={() => navigation.next()}>
            Next
        </Button>
      </Tooltip>

      </Grid>
    </Grid>
  );
}

export default Pets
