import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Container, CssBaseline, Grid, Typography, Avatar,
    TextField, withWidth, Button, Box, Hidden, Link} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {makeStyles} from '@material-ui/core/styles';
import {auth} from '../../config/fbConfig'
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    paper: {
      //marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      
    },
    avatar: {
      margin: theme.spacing(0),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  
  function SignIn({width}) {
    const classes = useStyles()
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setErr] = useState('')
    const gridSize = (width==='xs' || width==='sm') ? 12 : 6

    const handleSubmit = (e) => {
      e.preventDefault()
      auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        if(user) {
          history.push('/home')
        }
      })
      .catch(err =>  {
        if(err){
          setErr('Password or Email is incorrect.')
        }
      })
    }
    return (
      <Grid item container xs={12} style={{display:"flex", flex:'1 1 auto', alignItems:'center', position: 'relative'}}>
        <Hidden smDown>
          <Grid item container xs={0} md={6} 
              style={{backgroundImage:"url('https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-934877872-1571657144.jpg')", 
              backgroundPosition:'center top',
              backgroundRepeat:'no-repeat', 
              backgroundSize:'cover',
              height:'95vh', }}>
          </Grid>
        </Hidden>

        <Grid item container xs={gridSize} md={6} >
          <div style={{margin: 0, position: 'absolute', top: '100%', width: '100%', transform: 'translate(0, 10%)'}}></div>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography style={{fontWeight:'bold', fontSize:24, textAlign:'center'}}>
                Welcome back! <br/> Sign in
              </Typography>
              <form className={classes.form} onSubmit={(e) => handleSubmit(e)}>
                <TextField onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField onChange={(e)=> setPassword(e.target.value)}
                  value={password}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}

                <Typography style={{width:'inherit', textAlign:'center', color:'red'}}>{error}</Typography>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign In
                </Button>

                <Grid container>
                  <Grid item xs>
                    <Link href="/reset-password" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/signup" variant="body2">
                      {"No account? Sign Up!"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
            <Box mt={4}>
              <Typography variant="body2" color="textSecondary" style={{width:'100%', textAlign:'center'}}>
              <Link style={{marginRight:10}} color="inherit" href="https://cutiapp.com/ip/terms">Terms of Service</Link>
              <Link color="inherit" href="https://cutiapp.com/ip/privacy">Privacy Policy</Link>
              </Typography>
              <Typography variant="body2" color="textSecondary" align="center">
                  {'Copyright © '}
                  <Link color="inherit" href="https://cutiapp.com/">
                      Cuti App
                  </Link>{' '}
                  {new Date().getFullYear()}
                  {'.'}
              </Typography>
            </Box>
          </Container>
        </Grid>
        </Grid>      
    
      );
  }
  
  SignIn.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(SignIn)