import React, {useState, useEffect} from 'react'
import {Grid, Typography, Button, IconButton, Modal, TextField, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear'
import { firestore, storage,cauth } from '../../config/fbConfig';
import {auth} from '../../config/fbConfig'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    profileContainer:{
      position: 'relative',
      display:"flex",
      flexFlow:'column',
      alignItems:'center',
      padding: 10,
      marginBottom: 12,
    },
    avatar: {
      height: 140,
      width: 140,
    },
    imgSelecter:{
      position:'absolute',
      top: 120,
      left: '52%',
      border: '2px solid white',
      backgroundColor: theme.palette.primary.main,
      '&:hover':{
        cursor:'pointer',
        opacity: .8,
      },
      accordianDetails:{
        width:'100%',
        display:'flex',
        flexFlow:'column',
        textAlign: 'left'
      },
      accordianEditBtn:{
        alignSelf:'flex-end'
      },
    }
  
  }));

function ConfirmResetPwModal(props) {
    const {modalOpen, setModalOpen, userData} = props
    const {firstName, lastName, title, bio, images, email, phone} = userData
    const classes = useStyles()
    const [openSuccess, setOpenSuccess] = useState(false)
    const [pw, setPw] = useState('')
    const [confirmPw, setConfirmPw] = useState('')
    const [err, setErr] = useState(null)
    const disabled = (err!== null && pw !== confirmPw) ? true : false
    const severity = ["error", "success"]

    const handleConfirm = () => {
      const user = auth.currentUser;
      user.updatePassword(pw).then(() => {
        setOpenSuccess(!openSuccess)
      }).catch((error) => {
        setErr("Can't reset at this time. You must log out and click 'Forgot Password'.")
      });
    }

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenSuccess(false);
    };

    useEffect(() => {
      if(pw !== '' && confirmPw !=='' && pw!==confirmPw){
        setErr('Passwords do not match!')
      }
      if((pw === "" || confirmPw === '') && err!==''){
        setErr(null)
      }
      if(pw === confirmPw && err!==null && pw.length >= 8){
        setErr(null)
      }
      if(pw!=='' && confirmPw!=='' && pw.length < 8){
        setErr('Password must be 8 char long')
      }

    }, [pw, confirmPw, err])



    return (
        <Modal open={!!modalOpen}>
              
        <Grid container item xs={10} sm={6} md={4} lg={3} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
            <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Reset Password
                </Typography>
                <IconButton onClick={() => setModalOpen(!modalOpen)} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid container item xs={12} className={classes.profileContainer}>

                <Typography style={{textAlign:'center'}} variant='h5'>{firstName + " " + lastName}</Typography>
                <Typography style={{textAlign:'center'}}>You will be redirected to reset your password. Are you sure?</Typography>

            </Grid>

            <Grid item xs={12} style={{padding: 20}}>
                <TextField
                    label="Email"
                    inputMode="email"
                    //onKeyPress={(e)=>{validateCC(e)}}
                    value={email}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} style={{padding: '0px 20px'}}>
                <TextField
                    label="Password"
                    inputMode="password"
                    type="password"
                    onChange={(e) => setPw(e.target.value)}
                    //onKeyPress={(e)=>{validateCC(e)}}
                    value={pw}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} style={{padding: '0px 20px'}}>
                <TextField
                    label="Confirm Password"
                    inputMode="password"
                    type="password"
                    onChange={(e) => setConfirmPw(e.target.value)}
                    //onKeyPress={(e)=>{validateCC(e)}}
                    value={confirmPw}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                />
            </Grid>

            <Grid item xs={12} style={{padding: '0px 20px'}}>
              {err && <Typography color='error' style={{textAlign:'center'}}>{err}</Typography>} 
            </Grid>

            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                <Button
                variant="contained"
                style={{  
                letterSpacing: '2px',marginRight: "1rem" }}
                onClick={() => setModalOpen(!modalOpen)}
                >
                Cancel
                </Button>
                <Button
                disabled={disabled}
                style={{  
                letterSpacing: '2px', }}
                variant="contained"
                color='primary'
                onClick={handleConfirm}
                >
                Confirm
                </Button>
            </div>

          <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={severity[1]}>
                  Password Updated Successfully!
              </Alert>
          </Snackbar>
            
        </Grid>


    </Modal>
    )
}

export default ConfirmResetPwModal
