import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Grid,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { NavLink } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import PaymentIcon from "@material-ui/icons/Payment";
import SmsIcon from "@material-ui/icons/Sms";
import { firestore } from "../../config/fbConfig";
import moment from "moment";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import { setConversationId } from "../../auth/appointmentActions";
import withWidth from "@material-ui/core/withWidth";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Slide from "@material-ui/core/Slide";
import CancelApptModal from "../modals/CancelApptModal";
import PayBalanceModal from "../modals/PayBalanceModal";
import RescheduleModal from "../modals/RescheduleModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import RatingAndReviewModal from "../modals/RatingAndReviewModal";
import { useHistory } from "react-router";
import { Event } from "@material-ui/icons";

const { ms, s, m, h, d } = require("time-convert");

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const stripePromiseTest = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
);

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: theme.palette.primary.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  card: {
    backgroundColor: "white",
    width: "100%",
    height: "fit-content",
    marginLeft: 0,
    marginBottom: 10,
    borderRadius: 14,
    boxShadow: "4px 4px 6px -1px rgba(0,0,0,0.20)",
    flexDirection: "row",
  },
  infoSide: {
    width: "75%",
    height: "100%",
    borderRight: "1px solid lightgrey",
    display: "flex",
    padding: 7,
    overflow: "hidden",
    // justifyContent:''
  },
  with: {
    color: "grey",
    fontSize: 12,
    marginVertical: 3,
    width: "100%",
    textAlign: "left",
  },
  service: {
    // fontSize:16,
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
  },
  badge: {
    padding: 1,
    borderRadius: 10,
    width: "32%",
    marginBottom: 3,
  },
  badgeText: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    paddingHorizontal: 3,
  },
  proInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  img: {
    width: 24,
    height: 24,
    borderRadius: 24,
    marginRight: 5,
  },
  btnsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  btn: {
    borderRadius: 8,
    // backgroundColor:'#413e68',
    width: 90,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
  },
  chatBtn: {
    borderRadius: 8,
    // backgroundColor: '#413e68',
    flexDirection: "row",
    width: 90,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
  },
  btnTxt: {
    color: "white",
  },
  date: {
    padding: 7,
    width: "25%",
    justifyContent: "center",
    alignItems: "center",
  },
  day: {
    fontSize: 24,
    fontWeight: "bold",
  },
}));

const AppointmentCard = ({ userData, appt, width, appointments, id }) => {
  const classes = useStyles();
  const history = useHistory();
  const [proRating, setProRating] = useState("");
  const [open, setOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [balanceModalOpen, setBalanceModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const apptInfo = appt.docData;
  const fullName = apptInfo.proName.split(" ");
  const lastName = fullName.pop();
  const firstWInitial = fullName.pop() + " " + lastName.charAt(0) + ".";
  const apptId = appt.id;
  const [rrModalOpen, setRRModalOpen] = useState(false);

  const date = new Date(apptInfo.serviceTimeReq.seconds * 1000).toLocaleString(
    "en-US",
    {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }
  );

  const border =
    new Date() > new Date(apptInfo.serviceTimeReq.seconds * 1000) &&
    apptInfo.serviceComplete === false
      ? "2px solid red"
      : "1px solid #e0e0e0";

  const alignItems = width === "sm" ? "flex-end" : "center";

  var gapi = window.gapi;
  var CLIENT_ID =
    "5074722352-4q8icoa3g669h4v8f3fqltril5spaa56.apps.googleusercontent.com";
  var API_KEY = "AIzaSyDa_I8zG8L-tfutMU97YEkiBSZ1IRopaGw";
  var DISCOVERY_DOCS = [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ];
  var SCOPES = "https://www.googleapis.com/auth/calendar.events";

  useEffect(() => {
    let unsubscribeFromRating = null;
    unsubscribeFromRating = firestore
      .collection("professionals")
      .doc(`${apptInfo.proUID}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setProRating(
            (
              doc.data().ratings.reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.rating;
              }, 0) / doc.data().ratings.length
            ).toFixed(2)
          );
        } else {
          // console.log("No such document!");
        }

        //// console.log(userData)
        return () => unsubscribeFromRating();
      });
  }, [proRating]);

  const dispatch = useDispatch();

  const handleSetMessage = () => {
    dispatch(setConversationId(apptId));
    history.push(`/home/messages`);
  };

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      window.open(`https://www.google.com/maps`);
    }, 2000);
  };

  const handleClose = () => {
    setCopied(false);
  };

  const handleBtnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(!cancelModalOpen);
  };

  const handleBalanceModalOpen = () => {
    //setBalanceModalOpen(!balanceModalOpen)
    window.location.assign(`/payment/${apptId}`);
  };

  const handleReschedule = () => {
    setRescheduleModalOpen(!rescheduleModalOpen);
  };

  const handleNavigate = () => {
    const queryAddress = apptInfo.serviceLocation.split(" ").join("+");
    window.open(`https://www.google.com/maps/place/${queryAddress}`);
  };

  const handleJoinMeeting = () => {
    window.open(`${apptInfo.serviceLocation}`);
  };

  const handleAddToCalendar = () => {
    const t = m.to(ms)(new Date(apptInfo.serviceTimeEnd.seconds * 1000));

    gapi.load("client:auth2", () => {
      console.log("loaded client");
      // console.log(CLIENT_ID)
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      });

      gapi.client.load("calendar", "v3", () => console.log("calender load..."));

      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          console.log("adding event!!");
          var event = {
            summary: `${
              apptInfo.service +
              ": " +
              apptInfo.proName +
              " <> " +
              apptInfo.clientName
            }`,
            location: `${apptInfo.serviceLocation}`,
            description: `You have a Scheduled Appointment Via BizzyBook: ${apptInfo.service}`,
            start: {
              dateTime: new Date(
                apptInfo.serviceTimeReq.seconds * 1000
              ).toISOString(),
              timeZone: "America/Los_Angeles",
            },
            end: {
              dateTime: new Date(
                apptInfo.serviceTimeEnd.seconds * 1000
              ).toISOString(), //this is an iso
              timeZone: "America/Los_Angeles",
            },
            recurrence: [
              // 'RRULE:FREQ=DAILY;COUNT=2'
            ],
            attendees: [{ email: `${apptInfo.proEmail}` }],
            reminders: {
              useDefault: false,
              overrides: [
                { method: "email", minutes: 24 * 60 },
                { method: "email", minutes: 60 },
                { method: "popup", minutes: 10 },
              ],
            },
          };

          var request = gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
          });

          request.execute((event) => window.open(event.htmlLink));
        })
        .then(() =>
          setTimeout(() => {
            window.location.reload();
          }, 20000)
        );
    });

    firestore.collection("appointments").doc(apptId).update({
      addedToCalendar: true,
    });
  };

  return (
    <Grid key={id} container item xs={12} className={classes.card}>
      <Grid item container className={classes.infoSide}>
        <Grid
          item
          container
          xs={12}
          style={{
            height: "fit-content",
            display: "flex",
            flexFlow: "row",
            position: "relative",
          }}
        >
          <div style={{ width: "100%", height: 20 }}>
            {apptInfo.serviceCanceled === true && (
              <div
                className={classes.badge}
                style={{ backgroundColor: "grey" }}
              >
                <Typography className={classes.badgeText}>Canceled</Typography>
              </div>
            )}

            {new Date().getTime() >
              new Date(apptInfo.serviceTimeReq.seconds * 1000).getTime() +
                900000 &&
              apptInfo.clientStatus !== "suspended" && (
                <Tooltip
                  title={`Looks like we're a little behind schedule. Try reaching out to your client.`}
                >
                  <div
                    className={classes.badge}
                    style={{ backgroundColor: "red", marginLeft: 3 }}
                  >
                    <Typography className={classes.badgeText}>LATE</Typography>
                  </div>
                </Tooltip>
              )}
          </div>

          {apptInfo.serviceComplete === false &&
            apptInfo.serviceCanceled === false &&
            (apptInfo.addedToCalendar === undefined ||
              apptInfo.addedToCalendar === false) && (
              <Tooltip title={`Add this event to your calendar`}>
                <div
                  className={classes.badge}
                  style={{
                    position: "absolute",
                    right: 0,
                    backgroundColor: "#413e68",
                    width: "fit-content",
                    padding: "1px 10px",
                  }}
                  onClick={handleAddToCalendar}
                >
                  <Typography className={classes.badgeText}>
                    ADD TO{" "}
                    <Event style={{ verticalAlign: "middle", fontSize: 17 }} />
                  </Typography>
                </div>
              </Tooltip>
            )}
        </Grid>

        <Typography className={classes.service}>
          {apptInfo.service.length > 29
            ? apptInfo.service.slice(0, 26) + "..."
            : apptInfo.service}
        </Typography>
        <Typography className={classes.with}>
          {userData.collection === "customers" ? "with" : "for"}{" "}
          {userData.collection === "customers"
            ? apptInfo.proName.split(" ").shift()
            : apptInfo.clientName}
        </Typography>

        <Grid className={classes.proInfo}>
          <img
            className={classes.img}
            alt="avatar"
            src={
              userData.collection === "customers"
                ? apptInfo.proAvatar
                : apptInfo.clientAvatar
            }
          />
          <Typography className={classes.proName}>
            {userData.collection === "customers"
              ? apptInfo.proName
              : apptInfo.clientName}
          </Typography>
        </Grid>

        <Grid item container className={classes.btnsContainer}>
          {apptInfo.serviceComplete === true ||
          apptInfo.serviceCanceled === true ? (
            <Button
              onClick={() => {
                console.log(apptInfo);
              }}
              variant="contained"
              color="primary"
              className={classes.btn}
              style={{ width: 134 }}
            >
              <Typography className={classes.btnTxt}>Book Again</Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={handleBtnClick}
            >
              <Typography
                className={classes.btnTxt}
                aria-controls="options-menu"
                aria-haspopup="true"
              >
                Options
              </Typography>
            </Button>
          )}

          {apptInfo.serviceComplete === false &&
            apptInfo.serviceCanceled === false && (
              <Button
                variant="contained"
                color="primary"
                className={classes.chatBtn}
                onClick={handleSetMessage}
              >
                <SmsIcon style={{ fontSize: 24, color: "white" }} />
                <Typography className={classes.btnTxt}>Chat</Typography>
              </Button>
            )}

          <div
            onClick={() => setOpen(!open)}
            className={classes.badge}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 36,
              height: 36,
              borderRadius: 8,
              border: "1px solid #413e68",
            }}
          >
            {!open ? (
              <KeyboardArrowDownIcon style={{ color: "#413e68" }} />
            ) : (
              <KeyboardArrowUpIcon style={{ color: "#413e68" }} />
            )}
          </div>
        </Grid>
      </Grid>

      <Grid item container className={classes.date}>
        <Grid
          item
          container
          style={{
            width: "100%",
            borderBottom: "1px solid lightgrey",
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          <Typography
            style={{ width: "100%", textAlign: "center", fontSize: 10 }}
          >
            BALANCE:
          </Typography>
          <Typography
            style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
          >
            ${apptInfo.amtRemainingBalance}
          </Typography>
        </Grid>
        <Typography style={{ width: "100%", textAlign: "center" }}>
          {apptInfo.serviceComplete === false &&
          apptInfo.serviceCanceled === false
            ? moment(new Date(apptInfo.serviceTimeReq.seconds * 1000)).format(
                "MMM"
              )
            : moment(new Date(apptInfo.serviceTimeEnd.seconds * 1000)).format(
                "MMM"
              )}
        </Typography>
        <Typography
          className={classes.day}
          style={{ width: "100%", textAlign: "center" }}
        >
          {apptInfo.serviceComplete === false &&
          apptInfo.serviceCanceled === false
            ? moment(new Date(apptInfo.serviceTimeReq.seconds * 1000)).format(
                "Do"
              )
            : moment(new Date(apptInfo.serviceTimeEnd.seconds * 1000)).format(
                "Do"
              )}
        </Typography>
        <Typography style={{ width: "100%", textAlign: "center" }}>
          {apptInfo.serviceComplete === false &&
          apptInfo.serviceCanceled === false
            ? moment(new Date(apptInfo.serviceTimeReq.seconds * 1000)).format(
                "h:mm a"
              )
            : moment(new Date(apptInfo.serviceTimeEnd.seconds * 1000)).format(
                "h:mm a"
              )}
        </Typography>
      </Grid>

      {!!open && (
        <Grid
          item
          xs={12}
          container
          style={{
            borderTop: "1px solid #878787",
            display: "inline-block",
            minHeight: 200,
            marginTop: 5,
            padding: 7,
          }}
        >
          <Grid
            item
            xs={12}
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            {/* <div style={{width:'100%'}}>
                  <Typography style={{fontSize: 12, color:'#878787', width:'100%', textAlign:'left', borderTop:'1px solid lightgrey', fontWeight:'bold'}}>Service Cost:</Typography>
                  <Typography>${apptInfo.serviceFee}</Typography>
                </div> */}
            <Grid
              item
              container
              xs={8}
              sm={7}
              style={{ lineBreak: "anywhere" }}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: "#878787",
                  width: "100%",
                  textAlign: "left",
                  wordWap: "break-word",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Location:</span>{" "}
                {apptInfo.serviceLocation}
              </Typography>
            </Grid>

            <Grid item container xs={3} md={5} style={{ padding: "0px 10px" }}>
              {apptInfo.serviceLocation.includes("https") ? (
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleJoinMeeting}
                >
                  Join
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleNavigate}
                >
                  Maps
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} style={{}}>
            <Typography
              style={{
                fontSize: 12,
                color: "#878787",
                width: "100%",
                textAlign: "left",
                borderTop: "1px solid lightgrey",
                marginTop: 5,
              }}
            >
              <span style={{ fontWeight: "bold" }}>Pets:</span>{" "}
              {apptInfo.clientPets === "" ? "n/a" : apptInfo.clientPets}
            </Typography>

            {apptInfo.serviceAddOns !== undefined && (
              <Typography
                style={{
                  fontSize: 12,
                  color: "#878787",
                  width: "100%",
                  textAlign: "left",
                  borderTop: "1px solid lightgrey",
                  marginTop: 5,
                }}
              >
                <span style={{ fontWeight: "bold" }}>AddOn Service(s): </span>
                {apptInfo.serviceAddOns.map((addOn) => {
                  return <span> {addOn.name}, </span>;
                })}
              </Typography>
            )}

            <Typography
              style={{
                fontSize: 12,
                color: "#878787",
                width: "100%",
                textAlign: "left",
                borderTop: "1px solid lightgrey",
                marginTop: 5,
              }}
            >
              <span style={{ fontWeight: "bold" }}>Notes:</span>{" "}
              {apptInfo.clientNotes !== null ? apptInfo.clientNotes : "none"}
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: 5 }}></Grid>
        </Grid>
      )}

      {apptInfo.clientStatus === "busy" &&
        apptInfo.serviceTimeStart === "n/a" &&
        new Date().getTime() <
          new Date(apptInfo.serviceTimeReq.seconds * 1000).getTime() +
            900000 && (
          <Tooltip
            title={`${
              apptInfo.clientName.split(" ")[0]
            } is currently in another appointment. You cannot start this appointment now. If your appointment is coming up within the next 30 mins, feel free to reach out to your client.`}
          >
            <div
              style={{
                padding: "2px 6px",
                color: "white",
                fontSize: 10,
                marginTop: 50,
                letterSpacing: 1,
                fontWeight: "bold",
                borderRadius: 20,
                border: "2px solid white",
                backgroundColor: "#0072ff",
                position: "absolute",
              }}
            >
              BUSY
            </div>
          </Tooltip>
        )}

      <Snackbar
        open={copied}
        onClose={handleClose}
        TransitionComponent={transition}
        message="Address Copied... Redirecting you to Google Maps!"
        key={transition ? transition.name : ""}
      />

      {userData && userData.test === false && (
        <Elements stripe={stripePromise}>
          <PayBalanceModal
            modalOpen={balanceModalOpen}
            handleModalOpen={handleBalanceModalOpen}
            appt={appt}
            userData={userData}
            setRRModalOpen={setRRModalOpen}
          />
        </Elements>
      )}
      {userData && userData.test === true && (
        <Elements stripe={stripePromiseTest}>
          <PayBalanceModal
            modalOpen={balanceModalOpen}
            handleModalOpen={handleBalanceModalOpen}
            appt={appt}
            userData={userData}
            setRRModalOpen={setRRModalOpen}
          />
        </Elements>
      )}

      <RescheduleModal
        modalOpen={rescheduleModalOpen}
        handleModalOpen={handleReschedule}
        handleMenuClose={handleMenuClose}
        appt={appt}
        userData={userData}
        scheduledAppointments={appointments}
      />
      <CancelApptModal
        modalOpen={cancelModalOpen}
        handleModalOpen={handleCancelModalOpen}
        handleMenuClose={handleMenuClose}
        appt={appt}
        userData={userData}
      />

      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {apptInfo.serviceTimeStart === "n/a" && (
          <MenuItem onClick={handleReschedule}>RESCHEDULE</MenuItem>
        )}
        <MenuItem onClick={handleCancelModalOpen}>CANCEL</MenuItem>
      </Menu>

      <RatingAndReviewModal
        modalOpen={rrModalOpen}
        setModalOpen={setRRModalOpen}
        selectedAppt={appt}
        userData={userData}
      />
    </Grid>
  );
};

AppointmentCard.propTypes = {
  width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
};

export default withWidth()(AppointmentCard);
