import React, {useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Button, Typography, IconButton, Modal, TextField, TextareaAutosize, Avatar, CircularProgress } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../../../src/components/modals/canvasUtils'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import { firestore, storage } from '../../../config/fbConfig';

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
  }

function About({uid, userData, brandAm, classes, light, designData, paddingLeft, editAbout, edit, handleEdit, width}) {
    const {about, theme} = designData
    const [title, setTitle] = useState(about.title)
    const [titleColor, setTitleColor] = useState(about.titleColor)
    const [body, setAbout] = useState(about.body)
    const [fontColor, setFontColor] = useState(about.fontColor)
    const [backgroundColor, setBackgroundColor] = useState(about.backgroundColor)
    const [backgroundImage, setBackgroundImage] = useState(about.backgroundImage)
    const [modalOpen, setModalOpen] =useState(false)
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [imageLoading, setImageLoading] = useState(false)
    const [split, setSplit] = useState(about.split)
    const fileInput = useRef();
    const label = split===false ? 'No': 'Yes'
    const themeLight = light === true ? 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)' : 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)'

    const select = () => {
        fileInput.current.click()
      }

    const closeModal = () => {
        setImageSrc(null)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          let imageDataUrl = await readFile(file)
    
          // apply rotation if needed
          const orientation = await getOrientation(file)
          const rotation = ORIENTATION_TO_ANGLE[orientation]
          if (rotation) {
            imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
          }
    
          setImageSrc(imageDataUrl)
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => resolve(reader.result), false)
          reader.readAsDataURL(file)
        })
      }

      const uploadCroppedImage = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          )
    
          var xhr = new XMLHttpRequest();
          xhr.open('GET', `${croppedImage}`, true);
          xhr.responseType = 'blob';
          xhr.onload = function(e) {
            if (this.status === 200) {
              var myBlob = this.response;
              // myBlob is now the blob that the object URL pointed to.
                
                  let fileurl = ''
                  var uploadTask = storage.ref(`BrandAmbassadorInfo/${brandAm.email}-${brandAm.name}/images/about/about`).put(myBlob)
                  uploadTask.on('state_changed', 
                    (snapshot) => {
                      //Handle progress...
                      setImageLoading(true)
                    }, 
                    (error) => {
                      // Handle unsuccessful uploads...
                    }, 
                    async () => {
                      // Handle successful uploads on complete...
                      await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
                        fileurl = url
                        // console.log('File available at', fileurl)
                        setBackgroundImage(url)
                        setImageLoading(false)
                        closeModal()
                      })
                    }
                  )
                
            }
          };
          xhr.send();
    
          // console.log(croppedImage)
          setCroppedImage(croppedImage)
    
    
        } catch (e) {
          console.error(e)
        }
      }, [imageSrc, croppedAreaPixels, rotation, brandAm.email, brandAm.name])
    

    const handleSave = () => {
        firestore.collection('brandAms').doc(uid).collection('design').doc('designInfo').update({
         'about.title': title,
         'about.body': body,
         'about.fontColor': fontColor,
         'about.titleColor': titleColor,
         'about.backgroundColor': backgroundColor,
         'about.backgroundImage': backgroundImage,
         'about.split': split,
        }) 
    };

    const handleModalOpen = () => {
        setModalOpen(!modalOpen)
    }

    const handleCancel = () => {
        setTitle(about.title)
        setTitleColor(about.titleColor)
        setAbout(about.body)
        setFontColor(about.fontColor)
        setBackgroundColor(about.backgroundColor)
        setSplit(about.split)
        handleModalOpen()
      }

  return (
      <>
      {split=== false ?
        <Grid item container xs={12} style={{position:'relative', display:'flex', minHeight:500, paddingLeft: ((width==='xs' || width==='sm') && userData.firstName !==undefined) ? 50 : 0, justifyContent:'center', backgroundColor:backgroundColor}}>
            <Grid item container xs={11} md={9} lg={7} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <div style={{display:'flex', flexFlow:'inherit', alignItems:'baseline', justifyContent:'center'}}>
                    <Typography style={{fontWeight:'bold', fontSize:24, margin:'10px 0px 20px 0px', marginRight:10, textAlign:'center', color: titleColor}}>{title}</Typography>
                    {(userData.email === brandAm.email && edit===true) &&
                    <Avatar className={classes.circBtn} onClick={handleModalOpen}><EditIcon/></Avatar>
                }
                    <div style={{display:'flex', justifyContent:'center', width:'100%', marginTop:20}}>
                        <img src={backgroundImage} alt="" style={{width:"300px"}} />

                    </div>
                    <br/>
                    <Typography style={{width:'inherit', textAlign:'center', color:fontColor}}>
                        {body}
                    </Typography>
                    </div>
            </Grid>

            {/* <div style={{width:'100%', height:30, background:themeLight, position:'absolute', top:0}}></div> */}

        </Grid>
        :
        <Grid item container xs={12} style={{position:'relative', display:'flex', minHeight:500, maxHeight:800, justifyContent:'center', paddingLeft:  ((width==='xs' || width==='sm')&& userData.firstName !== undefined) ? 50 : 0, backgroundColor:backgroundColor}}>
            <Grid item contaier xs={12} md={6}>
             <div style={{display:'flex', justifyContent:'center', width:'100%', maxHeight:500, padding:20}}>
                    <img src={backgroundImage} alt="" style={{width:"100%", maxHeight:500, objectFit:'contain'}} />
                </div>
            </Grid>
            <Grid item contaier xs={12} md={6} style={{display:'flex',}}>
            <div style={{display:'flex', flexFlow:'column', alignItems:'center', justifyContent:'center', paddingBottom:20}}>
                <div style={{display:'flex', alignItems:'baseline', justifyContent:'center', width:'100%'}}>
                    <Typography style={{fontWeight:'bold', fontSize:24, margin:'10px 0px 20px 0px', marginRight:10, textAlign:'center', color: titleColor, paddingLeft:20}}>{title}</Typography>
                    {(userData.email === brandAm.email && edit===true) &&<Avatar className={classes.circBtn} onClick={handleModalOpen}><EditIcon/></Avatar>}
                </div>
                <div style={{width:'100%', maxHeight:300, overflowY:'scroll', padding:15,}}>
                    <Typography style={{width:'inherit', textAlign:'center', color:fontColor, paddingLeft:20, }}>
                        {body+" "+body+" "+body+" "+body}
                    </Typography>
                </div>
            </div>
            </Grid>

            {/* <div style={{width:'100%', height:30, background:themeLight, position:'absolute', top:0}}></div> */}
        </Grid>
      }


      {/* -------------------------MODAL----------------------------- */}

        <Modal open={!!modalOpen} onClose={handleModalOpen}>
            <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

                <Grid item container justify='space-between' style={{backgroundColor: theme.primary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Edit About
                    </Typography>
                    <IconButton onClick={()=>setModalOpen(!modalOpen)} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid container item xs={12} style={{padding:20}}>
                    <TextField
                        label="Title"
                        placeholder={title}
                        name="name"
                        value={title}
                        onChange={(e)=> setTitle(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                </Grid>

                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Title Color</Typography>
                        <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                            <input type='color' defaultValue={titleColor} value={titleColor} style={{border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                            <input type='text' defaultValue={titleColor} value={titleColor} style={{width:'100%', border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                        </span>
                </Grid>

                <Grid container item xs={12} style={{padding:20}}>
                    <TextareaAutosize
                        label="About"
                        style={{width:'inherit', borderRadius:4, borderColor:'silver', padding:10, fontSize: 16, font:'inherit'}}
                        rowsMax={4}
                        rowsMin={4}
                        placeholder="Add a short Bio/About Us here"
                        value={body}
                        onChange={(e)=> setAbout(e.target.value)}
                    
                    />
                </Grid>

                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Text Color</Typography>
                        <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                            <input type='color' defaultValue={fontColor} value={fontColor} style={{border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                            <input type='text' defaultValue={fontColor} value={fontColor} style={{width:'100%', border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                        </span>
                </Grid>
                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Background Color</Typography>
                        <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                            <input type='color' value={backgroundColor} style={{border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                            <input type='text' value={backgroundColor} style={{width:'100%', border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                        </span>
                </Grid>

                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Image</Typography>
                    <input style={{display: 'none'}}
                        type="file"
                        id="image"
                        name="image"
                        onChange={onFileChange}
                        ref={fileInput}
                        accept=".gif,.jpg,.jpeg,.png"
                    /> 
                    <Avatar onClick={select}><AddAPhotoIcon/></Avatar> 
                </Grid>

                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Split Screen</Typography>
                    <Button variant='contained' onClick={()=> setSplit(!split)} name="split">{label}</Button> 
                </Grid>







                <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                    <Button
                    variant="contained"
                    style={{  
                    letterSpacing: '2px',marginRight: "1rem" }}
                    onClick={handleCancel}
                    >
                    Cancel
                    </Button>

                    <Button
                    style={{letterSpacing: '2px', backgroundColor:theme.primary, color:theme.secondary}}
                    variant="contained"
                    onClick={handleSave}
                    >
                    Save
                    </Button>
                </div>

                <Modal open={imageSrc !== null} onClose={closeModal}>
                    <Grid container item xs={10} sm={6} md={4}
                    style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:'30px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none', maxHeight:'90%'}}>
                        <div style={{width:'inherit', height:'inherit', position:'relative'}}>
                        <div className={classes.cropContainer}>
                            <Cropper
                            image={imageSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            />
                        </div>
                        </div>
                        <div className={classes.controls}>
                        <div className={classes.sliderContainer}>
                            <Typography
                            variant="overline"
                            classes={{ root: classes.sliderLabel }}
                            >
                            Zoom
                            </Typography>
                            <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            classes={{ root: classes.slider }}
                            onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </div>
                        <div className={classes.sliderContainer}>
                            <Typography
                            variant="overline"
                            classes={{ root: classes.sliderLabel }}
                            >
                            Rotation
                            </Typography>
                            <Slider
                            value={rotation}
                            min={0}
                            max={360}
                            step={1}
                            aria-labelledby="Rotation"
                            classes={{ root: classes.slider }}
                            onChange={(e, rotation) => setRotation(rotation)}
                            />
                        </div>
                        </div>
                        <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
                            <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={closeModal}>Cancel</Button>
                            {imageLoading === false ?
                            <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={uploadCroppedImage}>Upload</Button>
                            :
                            <div className={classes.wrapper}>
                            <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} disabled>Uploading...</Button>
                            <CircularProgress size={24} className={classes.buttonProgress} />
                        </div> 
                            }

                        </Grid>
                    </Grid>

                </Modal>
                                    

            </Grid>

        </Modal>
      </>
    )
}

About.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };
  
  export default withWidth()(About);