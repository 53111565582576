import React from "react";
import {Typography, Grid, TextField, Button, Paper, InputBase, FormControl,
    Select, InputLabel, MenuItem, Tooltip} from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    formControl: {
        width: '100%'
    },

  }));
  

export const Pets = ({ formData, setForm, navigation }) => {
  const { address, petTypes } = formData;
  const [havePets, setHavePets] = React.useState(formData.havePets)
  const classes = useStyles();
  const disabled = (formData.havePets === '' || (formData.havePets === 'Yes' && petTypes === '')) ? true : false

  const handlePetSelect = (e) => {
    formData.havePets = e.target.value
    // console.log(formData.havePets)
    setHavePets(formData.havePets);
  }

  //// console.log(formData.havePets , petTypes)
  
  return (
    <Grid container item xs={12} style={{borderRadius: 12, border:'1px solid #e0e0e0', position: 'relative', marginTop:'20px', height: 510, boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)', textAlign: 'left'}}>
      <Grid item xs={12} style={{borderRadius: '8px 8px 0 0', background: '#413e68', height: "50px"}}>
        <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Schedule A Service</Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding:'0px 20px'}}>

        <Typography variant='h6' style={{color:"#413e68"}}>3. Where we'll meet.</Typography>
        <Paper component='form' className={classes.root}>
            <InputBase value={address} disabled className={classes.input}/>
        </Paper>
      </Grid>

      <Grid container item xs={12} md={6} style={{padding: '0 10px 0 20px' }}>
          <Grid item xs={12}>
            <Typography variant='h6' style={{color:"#413e68"}}>4. Do you have any pets?</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id='pet-select-label'>Yes/No</InputLabel>
                <Select
                    labelId='pet-select-label'
                    id='pet-select'
                    value={havePets}
                    onChange={handlePetSelect}
                    label='Yes/No'
                    >

                    <MenuItem value={'Yes'}>Yes</MenuItem>
                    <MenuItem value='No'>No</MenuItem>
            
                </Select>
            </FormControl>
          </Grid>


      </Grid>

      <Grid container item xs={12} md={6} style={{padding: '0 20px 0 10px'}}>
        
        {havePets === 'Yes' &&
            <Grid item xs={12}>
              <Typography variant='h6' style={{color:"#413e68"}}>Type of pet(s)?*</Typography>
            </Grid>
        }

        {havePets === 'Yes' &&
            <Grid item xs={12}>
              <TextField 
                placeholder="e.g. 1 Dog, 2 Cats"
                name="petTypes"
                value={petTypes}
                onChange={setForm}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                required
                fullWidth/>
            </Grid>
        }


      </Grid>

      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'flex-end'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ backgroundColor: 'white',  
              color: '#413e68'}}
            onClick={() => navigation.previous()}>
            Back
        </Button>

      </Grid>

      <Grid container item xs={6} style={{ padding:'0px 10px 36px 10px', justifyContent: 'left'}}> 
      <Tooltip title="Let us know if you have pets?" disableHoverListener={!disabled}>
        <Button
            disabled={disabled}
            variant="contained"
            className={classes.btn}
            color='primary'
            onClick={() => navigation.next()}>
            Next
        </Button>
      </Tooltip>

      </Grid>
    </Grid>
  );
};
