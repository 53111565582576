import React, {useState, useEffect} from 'react'
import {Grid, Button, Typography, Avatar, Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from "react-simply-carousel";


const useStyles = makeStyles((theme) => ({
  sectionHeader:{
    fontSize: 24,
    fontWeight: 'bold',
  },
  searchBar: {
    border:'2px solid lightgray',
    borderRadius: 20,
    height: 40 ,
    width: 'inherit',
    paddingTop: 2,
    display:'flex',
    alignItems:'center',
    boxShadow: 'none'
  },
  circBtn:{
    '&:hover':{
      cursor: 'pointer'
    }
  },
  question: {
    fontWeight: 'bold',
    fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))"
  },

  }));

function ProSelect(props) {
  const {userData, availablePros,formData, setForm, navigation, brandAm,  backgroundColor, fontColor, handleBookingModalClose} = props
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedPro, setSelectedPro] = useState(null)
  const classes = useStyles()
  const selected = selectedPro !== null ? 'lightgrey' : 'white'
  const selectedBorder = selectedPro !== null ? `5px solid ${brandAm.themePrimary}` : '10px solid white'
  const disabled = selectedPro !== null ? false : true


  const handleSelectPro = (pro) => {
    setSelectedPro(pro)
    formData.pro = {name: pro.firstName +" " +pro.lastName, coordinates: pro.coordinates, address:pro.address, availability: pro.availability,
    email: pro.email, userId: pro.userId, ratings: pro.ratings, avatar: pro.images.avatar, stripeId: pro.stripeId, travel:pro.travel, busy: pro.busy, 
    online:pro.online, phone: pro.phone}
  }

  useEffect(() => {
    // first

    formData.address = brandAm.address
    // formData.coordinates = brandAm.coordinates

    if(userData.userId !== undefined){
      console.log(userData)
      formData.client = {name: userData.firstName +" " +userData.lastName, avatar: userData.images.avatar,
      email: userData.email, userId: userData.userId, accountStatus: userData.accountStatus, phone: userData.phone}
    }

    console.log('selected pro', selectedPro)
    console.log('form data:', formData)
  
    // return () => {
    //   second
    // }
  }, selectedPro, userData)
  


  return (
    <Grid item container xs={12} style={{paddingTop:20, paddingBottom:20}}>

      <Grid item xs={12}>
          <Typography className={classes.question}>2. Select your provider.</Typography>
      </Grid>

      {selectedPro !== null && <Typography style={{width:'100%', textAlign:'center', margin:'20px 0px 20px 0px'}}>{formData.pro.name}</Typography>}

      {availablePros.length !== 0 &&
      <Carousel
        updateOnItemClick
        centerMode={true}
        containerProps={{style: { width: "100%", justifyContent: "center", position:'relative', marginBottom:30}}}
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        
        forwardBtnProps={{
          children: ">", 
          style: {color:`${brandAm.themePrimary}`, fontSize:20, fontWeight:'bold', position:'absolute', right:'-20px', width: 40, height: 40, 
          minWidth: 40, alignSelf: "center", borderRadius:25, zIndex:10, boxShadow:'2px 3px 4px #0000008c', background:'white', border:'1px solid grey'}
        }}
        backwardBtnProps={{
          children: "<",
          style: { color:`${brandAm.themePrimary}`, fontSize:20, fontWeight:'bold', position:'absolute', left:'-20px', width: 40, height: 40, 
          minWidth: 40, alignSelf: "center", borderRadius:25, zIndex:10, boxShadow:'2px 3px 4px #0000008c', background:'white', border:'1px solid grey'}
        }}
        
        itemsToScroll={1}
        infinite={false}
        speed={400}
        disableNavIfAllVisible={true}
        hideNavIfAllVisible={true}

        activeSlideProps={{style: {background: selected, width:210, height:210, border: selectedBorder}}}
      >
        {availablePros.map((pro, index) =>{ 
          const name = pro.firstName+" "+pro.lastName.charAt(0)+"."
          const title = pro.title
          const img = pro.images.avatar
          // const rating = pro.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / pro.ratings.length
          return (
          <div 
          style={{ 
            background: "white", width: 200, height: 200, textAlign: "center", lineHeight: "240px", boxSizing: "border-box", position:'relative',
            border:`10px solid white`, borderRadius:20, display:'flex', alignItems:'center', flexFlow:'column', padding:10}}
            key={index}
            onClick={()=> handleSelectPro(pro)}
            >
              <Avatar src={img} style={{width:100, height:100, marginBottom:10}}></Avatar>
              {pro.email === brandAm.email &&
              <Avatar style={{position:'absolute', top:80, right:45, width:34, height:34, backgroundColor:'lightgray'}}><img src="https://img.icons8.com/ios-glyphs/24/000000/crown.png"/></Avatar>
              }
              <Typography style={{fontWeight:'bold'}}>{name}</Typography>
              <Typography style={{fontSize:10, marginBottom:20}}>{title}</Typography>
                                    
          </div>
        )})
        }
      </Carousel>
      }

      <Grid container item xs={6} style={{ padding: "0px 10px 36px 10px", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          style={{ backgroundColor: "white", color: "#413e68" }}
          onClick={handleBookingModalClose}
        >
          Cancel
        </Button>
      </Grid>

      <Grid container item xs={6} style={{ padding: "0px 10px 36px 10px", justifyContent: "left" }}>

        <Tooltip title="Please select a Time." disableHoverListener={!disabled}>
          {/* <div style={{width:'fit-content'}}> */}
            <Button
                disabled={disabled}
                variant="contained"
                className={classes.btn}
                color='primary'
                onClick={() => navigation.next()}>
                Next
            </Button>
          {/* </div> */}
        </Tooltip> 
      </Grid>


    </Grid>

  )
}

export default ProSelect