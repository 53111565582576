import React, {useState} from "react";
import {Typography, Container, TextField, Button, Grid} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { validate } from "@material-ui/pickers";
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '100%',
      position: 'relative',
      border: '1px solid gray',
      display: 'flex',
      alignItems: "center",
      borderRadius: '8px',
    },
    formLabel:{
      display: 'flex',
      flex:'auto',
      paddingLeft: 10,
      '&:hover':{
        cursor:'pointer'
      },
      overflow:'hidden',
    },
    form: {
      height: 50,
      padding: '15px 0',
      visibility: 'hidden',
      width: 110,
    },
    uploadBtn:{
      height: 50,
      display: 'flex',
      position:'absolute',
      right: 0,
      background: 'gray',
      width: 110,
    },
    description: {
        color: 'gray',
        marginTop:20,
        textAlign: 'left'
      },
      checkBox: {
          display:'flex',
          alignContent:'start',
          marginLeft: 10,
          marginTop: 20,
      },
      dlButton: {
        background: 'none',
        color: 'blue',
        margin: "20px 0"
      }
  }));

export const FastPay = ({ formData, setForm, navigation }) => {
  const { debitCard, expiration, cvc} = formData;
  const classes = useStyles();
  const disabled = (debitCard === "" || expiration === "" || cvc === "") ? true : false;
  const [focus, setFocus] = useState("")
  
  const validateCC = (e) => {
    const re = /[0-9-]+/g;
    if(e.target.value.length >= 19){
      e.preventDefault()
    }
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  const validateExD = (e) => {
    const re = /[0-9/]+/g;
    if(e.target.value.length >= 5){
      e.preventDefault()
    }
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  const validateCVC = (e) => {
    const re = /[0-9]+/g;
    if(e.target.value.length >= 3){
      e.preventDefault()
    }
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Fast Pay Set Up</Typography>

      <Typography variant='body2' className={classes.description}>Add a debit card to get your earnings right away. $1.49 fee per transaction. (optional)</Typography>
      
      <Cards number={debitCard} name={formData.firstName + " " + formData.lastName} expiry={expiration} cvc={cvc} focused={focus}></Cards>
      
      <TextField
        label="Debit Card"
        type="number"
        inputMode="numeric"
        onKeyPress={(e)=>{validateCC(e)}}
        onFocus={(e) => {setFocus(e.target.name)}}
        placeholder="Credit/Debit Card Number"
        name="debitCard"
        value={debitCard}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        fullWidth
      />

      <Grid>
          <Grid></Grid>
          <Grid></Grid>
      </Grid>
      <TextField
        label="Expiration Date"
        name="expiration"
        type="text"
        onKeyPress={(e)=>{validateExD(e)}}
        onFocus={(e) => {setFocus(e.target.name)}}
        placeholder="00/00"
        value={expiration}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        fullWidth
      />

    <TextField
        label="Cvc"
        name="cvc"
        type="text"
        placeholder="000"
        value={cvc}
        onKeyPress={(e)=>{validateCVC(e)}}
        onFocus={(e) => {setFocus(e.target.name)}}
        onChange={setForm}
        margin="normal"
        variant="outlined"
        autoComplete="off"
        required
        fullWidth
      />
    
    <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={() => navigation.next()}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};