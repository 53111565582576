import React, {useEffect} from "react";
import {Typography, Container, Button, Snackbar, FormControlLabel, Checkbox} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import {storage} from '../../../../config/fbConfig';
import FileUploader from "../FileUploader";
//import { ErrorOutlineTwoTone } from "@material-ui/icons";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '100%',
      position: 'relative',
      border: '1px solid gray',
      display: 'flex',
      alignItems: "center",
      borderRadius: '8px',
    },
    formLabel:{
      display: 'flex',
      flex:'auto',
      paddingLeft: 10,
      '&:hover':{
        cursor:'pointer'
      },
      overflow:'hidden',
    },
    form: {
      height: 50,
      padding: '15px 0',
      visibility: 'hidden',
      width: 110,
    },
    uploadBtn:{
      height: 50,
      display: 'flex',
      position:'absolute',
      right: 0,
      background: '#c6c6c6',
      width: 110,
      color: 'white',
    },
    instruction: {
        color: 'gray',
        display:'flex',
        margin:'20px 0px 4px 4px',
      },
      checkBox: {
          display:'flex',
          alignContent:'start',
          marginLeft: 10,
          marginTop: 20,
      },
      dlButton: {
        background: 'none',
        color: 'blue',
        margin: "20px 0"
      }
  }));


export const LicenseInfo = ({ formData, setForm, navigation }) => {
  const classes = useStyles();
  
  const { firstName, lastName, email } = formData;

  const [checked, setChecked] = React.useState(false);
  const [uploadFile1, setUploadFile1] = React.useState({})
  const [uploadFile2, setUploadFile2] = React.useState({})
  const [uploadFile3, setUploadFile3] = React.useState({})
  const [toastOpen, setOpen] = React.useState(false);
  const [uploadProgress, setProgress] = React.useState(0)
  const [disabled, setDisabled] = React.useState(true)
  


  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleOpenToast = () => {
    setOpen(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  const handleCheckedChange = (event) => {
    setChecked(!checked);
  };

  const handleFileChange = (event) => {
    // console.log(event.target.id);
    const file = event.target.files[0];
    if(event.target.id === 'prolicense'){
      setUploadFile1(file)
    }
    if(event.target.id === 'idfront'){
      setUploadFile2(file)
    }
    if(event.target.id === 'idback'){
      setUploadFile3(file)
    }
  };

  useEffect(() => {
    if(uploadProgress >= 3 || checked===true){
      setDisabled(false)
    }
    if(checked === true){
      formData.accountStatus = "needs review"
      // console.log(formData)
    }
    // return () => {
    //   cleanup
    // }
  }, [checked, uploadProgress, formData])


  const handleUpload = (e) => {
    //Note: muiButton's text is automatically wrapped in a span which causes id conflict if clicked.
    //So in order to create an id match, I literally wrapped the muiButton text in a span and assigned it the same id.  
    const storageRef = storage.ref();
    if((uploadFile1 !== {}) && (e.target.id === "prolicenseBtn")){
     
      const fileRef = storageRef.child("ProInfo").child(email + "-" + firstName + "-" + lastName).child("ProLicences").child(uploadFile1.name)
      fileRef.put(uploadFile1).then(() => {setUploadFile1({})}).then(()=> handleOpenToast()).then(setProgress(uploadProgress+1))
    }
    if((uploadFile2 !== {}) && (e.target.id === "idfrontBtn")){
      
      const fileRef = storageRef.child("ProInfo").child(email + "-" + firstName + "-" + lastName).child("DriversLicence").child("front_" + uploadFile2.name)
      fileRef.put(uploadFile2).then(() => {setUploadFile2({})}).then(()=> handleOpenToast()).then(setProgress(uploadProgress+1))
    }
    if((uploadFile3 !== {}) && (e.target.id === "idbackBtn")){
      
      const fileRef = storageRef.child("ProInfo").child(email + "-" + firstName + "-" + lastName).child("DriversLicence").child("back_" + uploadFile3.name)
      fileRef.put(uploadFile3).then(() => {setUploadFile3({})}).then(()=> handleOpenToast()).then(setProgress(uploadProgress+1))
    }
  }

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  // }

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>License Info</Typography>

      <Typography variant='body2' className={classes.instruction}>Upload a photo of your professional license.*</Typography>
      {/* {uploadProgress >= 1 ? 
        <Typography>Filed Uploaded Successfully.</Typography>
        :  */}
        <FileUploader formData={formData} id={'prolicense'} file={uploadFile1} updateFile={handleFileChange} handleUpload={handleUpload} setUploadFile={setUploadFile1}/>
      {/* } */}

      <Typography variant='body2' className={classes.instruction}>Upload a photo of your driver's license / state ID (front).*</Typography>
      {/* {uploadProgress >= 2 ? 
        <Typography>Filed Uploaded Successfully.</Typography>
        :  */}
        <FileUploader formData={formData} id={'idfront'} file={uploadFile2} updateFile={handleFileChange} handleUpload={handleUpload} setUploadFile={setUploadFile2}/>
      {/* } */}

      <Typography variant='body2' className={classes.instruction}>Upload a photo of your driver's license / state ID (back).*</Typography>
      {/* {uploadProgress >= 3 ? 
        <Typography>Filed Uploaded Successfully.</Typography>
        :  */}
        <FileUploader formData={formData} id={'idback'} file={uploadFile3} updateFile={handleFileChange} handleUpload={handleUpload} setUploadFile={setUploadFile3}/>
      {/* } */}

      {disabled === false ? null
      :
      <FormControlLabel className={classes.checkBox}
        control={<Checkbox checked={checked} onChange={handleCheckedChange} />}
        label="I will do this later."
      />
       }

      <Snackbar open={toastOpen} autoHideDuration={3000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast}>
          Your file was uploaded successfully!
        </Alert>
      </Snackbar>

      <div style={{marginTop: "1rem" }}>
        <Button
          variant="contained"
          style={{  
          backgroundColor: "white",
          backgroundSize: "400px",
          borderRadius: '0',
          color: '#413e68',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px',marginRight: "1rem" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
        <Button
          disabled={disabled}
          style={{ 
          backgroundColor: '#413e68',  
          backgroundSize: "400px",
          backgroundPosition: "center",
          borderRadius: '0',
          color: 'white',
          fontWeight: 'bold',
          border: '3px #413e68 solid',
          letterSpacing: '2px', }}
          variant="contained"
          onClick={() => navigation.next()}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};