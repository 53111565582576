import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'
import {Provider} from 'react-redux';
import store from './auth/store'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#413e68",
    },
    secondary: {
      main:"#fafafa",
      dark:"#737373"
    },
    error: {
      main: "#c70000"
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
        color: "#fff",
        backgroundColor: "#413e68"
      }
    }
  },
  typography:{
    fontFamily:[
      'Poppins'
    ].join(',')
  }
  
})


ReactDOM.render( 
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
  ,document.getElementById('root')
);