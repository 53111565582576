import React, {useState} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Menu, IconButton, Modal, TextField,
        MenuItem, Select, InputLabel, Snackbar} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'



function ConfirmCompletionModal({modalOpen, handleModalOpen, appt, userData, handleComplete, handlePaidWithCash, setConfirmCashModalOpen, confirmCashModalOpen, src}) {
    const {docData} = appt
    const [anchorEl, setAnchorEl] = useState(null);
    const [qrModalOpen, setQrModalOpen]= useState(false)
    const [typeOfQR, setTypeOfQR] = useState('')

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <Modal open={!!modalOpen} onClose={handleModalOpen}>
            <Grid container item xs={10} sm={6} md={4}
            style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:'30px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none'}}>
                {parseFloat(docData.amtRemainingBalance) !== 0 &&
                <img style={{height:70, width:70}} src="https://img.icons8.com/external-others-phat-plus/64/46b551/external-checkout-payment-outline-others-phat-plus-3.png" alt=''/> 
                }

                {parseFloat(docData.amtRemainingBalance) !== 0 ? 
                docData.clientUID.includes('guestUser') ?
                <>
                    <Typography variant='h3' style={{color:'#413e68', fontWeight:'bold', textAlign:'center'}}>CHECKOUT</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:20, textAlign:'center', marginTop:30}}>
                       THIS USER booked as a GUEST. Be sure to collect payment.
                        <br/>
                        <span style={{fontSize:14, fontWeight:'normal'}}><strong>IMPORTANT:</strong> Confirm the method of payment before moving forward.
                        <br/>
                        </span>
                    </Typography>
                </>
                :
                <>
                    <Typography variant='h3' style={{color:'#413e68', fontWeight:'bold', textAlign:'center'}}>CHECKOUT</Typography>
                    <Typography style={{fontWeight:'bold', fontSize:20, textAlign:'center', marginTop:30}}>
                        Your client has not yet paid. 
                        <br/>
                        <span style={{fontSize:14, fontWeight:'normal'}}><strong>IMPORTANT:</strong> Confirm the method of payment before moving forward.
                        <br/>
                        </span>
                    </Typography>
                </>
                :
                <>
                <Typography variant='h3' style={{color:'#413e68', fontWeight:'bold', textAlign:'center'}}>PAID!</Typography>
                <Typography style={{fontWeight:'bold', textAlign:'center', marginTop:30}}>All wrapped up?</Typography>
                </>

                }
                <Grid item container xs={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Grid container item xs={12} style={{display:'flex', paddingRight:5}}>
                        <Button variant='contained' fullWidth style={{fontWeight:'bold', marginTop:20}} onClick={handleModalOpen}>Not Yet</Button>
                    </Grid>

                    <Grid container item xs={12} style={{display:'flex', paddingLeft:5}}>
                        {parseFloat(docData.amtRemainingBalance) === 0 ?
                            <Button fullWidth variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={() => {handleComplete(); handleModalOpen()}}>Confirm</Button>
                        :
                            <Button fullWidth variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                <img style={{verticalAlign:'middle'}} src="https://img.icons8.com/fluency-systems-regular/20/ffffff/qr-code--v1.png" alt=''/> Checkout Options
                            </Button> 
                        
                        }
                    </Grid>
                </Grid>



                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} >
                    
                    {parseFloat(docData.amtRemainingBalance) !== 0 &&
                    <MenuItem onClick={()=>{setTypeOfQR('Debit'); setQrModalOpen(!qrModalOpen)}}><img style={{marginRight:5}} src="https://img.icons8.com/material-rounded/24/000000/bank-cards.png" alt=''/> Debit/Credit</MenuItem>
                    }
                    
                    {userData.qrCashApp !== '' &&
                    <MenuItem onClick={()=>{setTypeOfQR('CashApp'); setQrModalOpen(!qrModalOpen)}}><img style={{marginRight:5}} src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/24/000000/external-cashapp-instantly-send-money-between-friends-or-accept-card-payments-for-your-business-logo-color-tal-revivo.png" alt=""/> CashApp</MenuItem>
                    }
                    {userData.qrPayPal !== '' &&
                    <MenuItem onClick={()=>{setTypeOfQR('PayPal'); setQrModalOpen(!qrModalOpen)}}><img style={{marginRight:5}} src="https://img.icons8.com/color/24/000000/paypal.png" alt=""/> PayPal</MenuItem>
                    }
                    {(userData.qrZelle !== undefined && userData.qrZelle !== '') &&
                    <MenuItem onClick={()=>{setTypeOfQR('Zelle'); setQrModalOpen(!qrModalOpen)}}><img style={{marginRight:5}} src="https://img.icons8.com/color/24/000000/zelle.png" alt=""/> Zelle</MenuItem>
                    }
                    {userData.qrVenmo !== '' &&
                    <MenuItem onClick={()=>{setTypeOfQR('Venmo'); setQrModalOpen(!qrModalOpen)}}><img style={{marginRight:5}} src="https://img.icons8.com/ios-filled/24/70baec/venmo.png" alt=""/> Venmo</MenuItem>
                    }
                    {parseFloat(docData.amtRemainingBalance) !== 0 &&
                    <MenuItem onClick={()=>{setConfirmCashModalOpen(!confirmCashModalOpen)}}><img style={{marginRight:5}} src="https://img.icons8.com/windows/32/000000/cash.png" alt=""/> Paid w/Cash</MenuItem>
                    }
                </Menu>


                <Modal open={!!qrModalOpen} onClose={()=>setQrModalOpen(!qrModalOpen)}>
                    <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                        <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                            <Typography variant='h6' style={{padding: '10px'}}>
                                Scan To Pay: ${docData.amtRemainingBalance}
                            </Typography>
                            <IconButton onClick={()=>setQrModalOpen(!qrModalOpen)} style={{color: 'white'}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>

                        <div style={{width:'inherit', height:360, display:'flex', justifyContent:'center'}}>
                            {typeOfQR === "Debit" &&
                            <div style={{padding:10}}>
                                <Typography style={{width:'inherit', textAlign:'center', fontWeight:'bold'}}>Link To Payment</Typography>
                                <img src={src} alt='' style={{height:'65%', marginTop:20}}/>
                                <Typography style={{width:'inherit', textAlign:'center', fontWeight:'bold'}}>{docData.proName}</Typography>
                                <Typography style={{width:'inherit', textAlign:'center'}}>Debit/Credit</Typography>

                            </div>
                            }
                            {typeOfQR === "CashApp" &&
                            <img src={userData.qrCashApp} alt='' style={{height:'100%'}}/>
                            }
                            {typeOfQR === "PayPal" &&
                            <img src={userData.qrPayPal} alt='' style={{height:'100%'}}/>
                            }
                            {typeOfQR === "Zelle" &&
                            <img src={userData.qrZelle} alt='' style={{height:'100%'}}/>
                            }
                            {typeOfQR === "Venmo" &&
                            <img src={userData.qrVenmo} alt='' style={{height:'100%'}}/>
                            }
                        </div>

                        <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                            <Button
                            variant="contained"
                            style={{  
                            letterSpacing: '2px',marginRight: "1rem" }}
                            onClick={()=>{setQrModalOpen(!qrModalOpen); setTypeOfQR('')}}
                            >
                            Cancel
                            </Button>

                            {typeOfQR === 'Debit' ?
                            <Button
                            variant="contained"
                            style={{letterSpacing: '2px', backgroundColor:'#413e68', color:'white'}}
                            onClick={()=>{setQrModalOpen(!qrModalOpen); setTypeOfQR('')}}
                            >
                            Close
                            </Button>
                            :
                            <Button
                            style={{letterSpacing: '2px', backgroundColor:'#413e68', color:'white'}}
                            variant="contained"
                            onClick={() => {handlePaidWithCash(); handleModalOpen(); setTypeOfQR(''); setTimeout(()=>{window.location.reload()}, 3000)}}
                            >
                            Paid!
                            </Button>
                            }
                        </div>
                    </Grid>
                </Modal>

                <Modal open={!!confirmCashModalOpen} onClose={()=>setConfirmCashModalOpen(!confirmCashModalOpen)}>
                    <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>
                        <Grid item container justify='space-between' style={{backgroundColor: '#413e68', color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                            <Typography variant='h6' style={{padding: '10px'}}>
                                Pay w/ Cash
                            </Typography>
                            <IconButton onClick={()=>setConfirmCashModalOpen(!confirmCashModalOpen)} style={{color: 'white'}}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>

                        <div style={{width:'inherit', height:360, display:'flex', justifyContent:'center'}}>
                            
                            <div style={{padding:10}}>
                                <Typography style={{width:'inherit', textAlign:'center', fontWeight:'bold'}}>Balance Due:</Typography>
                                <Typography style={{width:'inherit', textAlign:'center', fontWeight:'bold', color:'#413e68', fontSize:60}}>${docData.amtRemainingBalance}</Typography>
                                <Typography style={{width:'inherit', textAlign:'center', fontWeight:'bold'}}>Has the client already paid?</Typography>
                            </div>
                            
                        </div>

                        <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                            <Button
                            variant="contained"
                            style={{  
                            letterSpacing: '2px',marginRight: "1rem" }}
                            onClick={()=>{setConfirmCashModalOpen(!confirmCashModalOpen)}}
                            >
                            Cancel
                            </Button>

                            
                            <Button
                            style={{letterSpacing: '2px', backgroundColor:'#413e68', color:'white'}}
                            variant="contained"
                            onClick={() => {handlePaidWithCash(); handleModalOpen(); setConfirmCashModalOpen(!confirmCashModalOpen); setTimeout(()=>{window.location.reload()}, 3000)}}
                            >
                            Paid!
                            </Button>
                            
                        </div>
                    </Grid>
                </Modal>

            </Grid>
            
        </Modal>
    )
}

export default ConfirmCompletionModal
