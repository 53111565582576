import React from 'react'
import {Grid, 
    Avatar,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({ 
    card: {
        display:'flex',
        borderRadius: '18px',
        textAlign: 'center',
        height: '250px', 
        width: '200px',
        justifyContent:'center',
        alignItems:'center'
    },
    text: {
        marginBottom: '10px'
    },
    avatar: {
        height:'80px',
        width:'80px'
    },
    stars:{
        color: theme.palette.primary.main,
        fontSize:'14px'
    }
}))

const ProviderCard = () => {
    
    const classes = useStyles();

    return (

        <Grid direction="column" variant="outlined" className={classes.card}>
            <Avatar alt="service provider image" src="" className={classes.avatar}/>
                <Typography variant="h6" color='gray' style={{fontWeight: 'bold'}} className={classes.text}> Pro Name Here </Typography>
                <Typography variant="subtitle1" color='primary' className={classes.text}> Title </Typography>
                <Grid container direction='row' alignItems="center" justify="center">
                    <StarIcon className={classes.stars}/>
                    <StarIcon className={classes.stars}/>
                    <StarIcon className={classes.stars}/>
                    <StarIcon className={classes.stars}/>
                    <StarIcon className={classes.stars}/>
                </Grid>
                <Typography variant="body2" color='gray' style={{fontWeight: 'bold'}} className={classes.text}> Rating: 5 stars</Typography>
                <Typography variant="caption" color='primary' className={classes.text}> City, ST </Typography>
        </Grid >
        
    )
}

export default ProviderCard