import React,{useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, Button, Typography, Avatar, Modal, TextField, TextareaAutosize, IconButton, withWidth } from '@material-ui/core';
import Carousel from "react-simply-carousel";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { firestore } from '../../../config/fbConfig';

function Staff({personel, userData, uid, brandAm, edit, designData, classes, staffModalOpen, setStaffModalOpen, handleRemovePro, paddingLeft, width}) {
    const {staff, theme} = designData
    const [backgroundColor, setBackgroundColor] = useState(staff.backgroundColor)
    const [cardColor, setCardColor] = useState(staff.cardColor)
    const [titleColor, setTitleColor] = useState(staff.titleColor)
    const [fontColor, setFontColor] = useState(staff.fontColor)
    const [modalOpen, setModalOpen] = useState(false)
    const [activeSlide, setActiveSlide] = useState(0);

    const handleModalOpen = () => {
      setModalOpen(!modalOpen)
    }

    const handleSave = () => {
      firestore.collection('brandAms').doc(uid).collection('design').doc('designInfo').update({
        'staff.backgroundColor': backgroundColor,
        'staff.cardColor': cardColor,
        'staff.titleColor': titleColor,
        'staff.fontColor': fontColor
       })
    }

    const handleCancel = () => {
      setTitleColor(staff.titleColor)
      setFontColor(staff.fontColor)
      setCardColor(staff.cardColor)
      setBackgroundColor(staff.backgroundColor)
      handleModalOpen()
    }


  return (         
  <Grid item container xs={12} style={{display:'flex', justifyContent:'center', padding:20, paddingLeft: paddingLeft, backgroundColor:backgroundColor}}>
    <Grid item container xs={11} md={9} lg={7} style={{display:'flex', justifyContent:'flex-end'}}>
        <Typography style={{fontWeight:'bold', fontSize:24, marginBottom:20, width:'inherit', textAlign:'center', color:titleColor}}>Staff</Typography>
        {(userData.email === brandAm.email && edit === true) &&
          <>
            <Avatar className={classes.circBtn} style={{marginLeft:5}} onClick={()=>setStaffModalOpen(!staffModalOpen)}><AddCircleOutlineIcon/></Avatar>
            <Avatar className={classes.circBtn} style={{marginLeft:5}} onClick={handleModalOpen}><EditIcon/></Avatar>
          </>
        }
    </Grid>


    
    <Grid item container xs={11} style={{paddingTop:20, paddingBottom:20}}>
      {/* {personel.length !== 0 &&
      <Carousel
        // updateOnItemClick
        centerMode={true}
        disableNavIfAllVisible={true}
        hideNavIfAllVisible={true}
        containerProps={{
          style: {
            width: "100%",
            justifyContent: "center",
            position:'relative'
          }
        }}
        activeSlideIndex={activeSlide}
        activeSlideProps={{style: {background: cardColor}}}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{children: ">", 
          style: {color:theme.primary, fontSize:20, fontWeight:'bold', position:'absolute', right:'-20px', width: 40, height: 40, 
          minWidth: 40, alignSelf: "center", borderRadius:25, zIndex:10, boxShadow:'2px 3px 4px #0000008c', background:backgroundColor, border:'1px solid grey'}
        }}
        backwardBtnProps={{
          children: "<",
          style: { color:theme.primary, fontSize:20, fontWeight:'bold', position:'absolute', left:'-20px', width: 40, height: 40, 
          minWidth: 40, alignSelf: "center", borderRadius:25, zIndex:10, boxShadow:'2px 3px 4px #0000008c', background:backgroundColor, border:'1px solid grey'}
        }}
        itemsToScroll={1}
        infinite={false}
        speed={400}
      >
        {personel.map((pro, index) =>{ 
          const name = pro.firstName+" "+pro.lastName.charAt(0)+"."
          const title = pro.title
          const img = pro.images.avatar
          const rating = pro.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / pro.ratings.length
          return (
          <div 
          style={{ 
            background: cardColor, width: 220, height: 310, textAlign: "center", lineHeight: "240px", boxSizing: "border-box", position:'relative',
            border:`10px solid ${backgroundColor}`, borderRadius:20, display:'flex', alignItems:'center', flexFlow:'column', padding:10}}
            key={index}
            >
              <Avatar src={img} style={{width:100, height:100, marginBottom:10}}></Avatar>
              {pro.email === brandAm.email &&
              <Avatar style={{position:'absolute', top:80, right:45, width:34, height:34, backgroundColor:"#ffffff", border:`2px solid ${theme.primary}`}}><img src={`https://img.icons8.com/ios-glyphs/24/${theme.primary.split('#').pop()}/crown.png`} alt=''/></Avatar>
              }
              <Typography style={{fontWeight:'bold', color:fontColor}}>{name}</Typography>
              <Typography style={{fontSize:10, color:fontColor, marginBottom:20}}>{title}</Typography>
              
              <Typography style={{fontSize:12, marginBottom:20, fontWeight:'bold', color:fontColor}}>Rating: {rating.toFixed(2)}</Typography>
              {(userData.email === brandAm.email && edit===true )?
              <Button style={{width:150, backgroundColor:theme.primary, color:theme.secondary}} variant='contained' onClick={()=> handleRemovePro(pro.email)}>Remove</Button>
              :
              <Button style={{width:150, backgroundColor:theme.primary, color:theme.secondary}} variant='contained' onClick={()=>window.location.assign(`/pro/profile/${pro.url}`)}>Book Me!</Button>
              }
          </div>
        )})
        }
      </Carousel>
      } */}

      {personel.length !== 0 && 
        personel.map((pro, idx)=> {
          if((idx + 1) % 2){
            return (
              <Grid item container xs={12} style={{}}>
                <Grid item container xs={12} md={6} style={{minHeight:(width==='sm' || width==='xs') ? 280 : 460}}>
                  <img src={pro.images.avatar} alt='profile' style={{objectFit:'cover', height:'100%', width:'100%'}}/>
                </Grid>
                <Grid item container xs={12} md={6} style={{minHeight:(width==='sm' || width==='xs') ? 280 : 460, backgroundColor:'white', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', textAlign:'center', padding:10}}>
                  <Typography style={{width:'100%',marginTop:20, fontSize:20, fontWeight:'bold'}}>{pro.firstName+" "+pro.lastName}</Typography>
                  <Typography style={{width:'100%'}}>{pro.title}</Typography>
                  <Typography style={{width:'100%', marginTop:20}}>{pro.bio === '' ? `${pro.firstName} is a ${pro.title} currently staffed under ${brandAm.name}, providing quality services!` : pro.bio}</Typography>
                  <Button onClick={()=>window.location.assign(`/pro/profile/${pro.url}`)} variant='contained' style={{marginTop:40, backgroundColor:theme.primary, color:theme.secondary}}>Book Now</Button>
                  <div style={{display:'flex', flexFlow:'row', marginTop:10}}>
                    {pro.socialMedia.facebook !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.facebook.includes('https') ? window.open(`${pro.socialMedia.facebook}`) : window.open(`https://${pro.socialMedia.facebook}`) }}><img src="https://img.icons8.com/fluency/48/000000/facebook-new.png" alt='fb'/></Avatar>    
                    }

                    {pro.socialMedia.instagram !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.instagram.includes('https') ? window.open(`${pro.socialMedia.instagram}`) : window.open(`https://${pro.socialMedia.instagram}`)}}><img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" alt="insta"/></Avatar>
                    }

                    {pro.socialMedia.twitter !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.twitter.includes('https') ? window.open(`${pro.socialMedia.twitter}`) : window.open(`https://${pro.socialMedia.twitter}`)}}><img src="https://img.icons8.com/color/48/000000/twitter-circled--v1.png" alt="twitter"/></Avatar>
                    }

                    {pro.socialMedia.tiktok !== '' && 
                    <Avatar onClick={()=> {pro.socialMedia.tiktok.includes('https') ? window.open(`${pro.socialMedia.tiktok}`) : window.open(`https://${pro.socialMedia.tiktok}`)}}><img src="https://img.icons8.com/color/48/000000/tiktok--v1.png" alt="tiktok"/></Avatar>
                    }
                  </div>
                  
                </Grid>
              </Grid>
            )

          }else{
            return (
              <>
              {(width === 'sm' || width==="xs") ?
              <Grid item container xs={12} style={{minHeight:500}}>
                <Grid item container xs={12} md={6} style={{minHeight:(width==='sm' || width==='xs') ? 280 : 460}}>
                <img src={pro.images.avatar} alt='profile' style={{objectFit:'cover', height:'100%', width:'100%'}}/>
                </Grid>
                <Grid item container xs={12} md={6} style={{minHeight:(width==='sm' || width==='xs') ? 280 : 460, backgroundColor:'white', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', textAlign:'center', padding:10}}>
                  <Typography style={{width:'100%',marginTop:20, fontSize:20, fontWeight:'bold'}}>{pro.firstName+" "+pro.lastName}</Typography>
                  <Typography style={{width:'100%'}}>{pro.title}</Typography>
                  <Typography style={{width:'100%', marginTop:20}}>{pro.bio === '' ? `${pro.firstName} is a ${pro.title} currently staffed under ${brandAm.name}, providing quality services!` : pro.bio}</Typography>
                  <Button onClick={()=>window.location.assign(`/pro/profile/${pro.url}`)} variant='contained' style={{marginTop:40, backgroundColor:theme.primary, color:theme.secondary}}>Book Now</Button>
                  <div style={{display:'flex', flexFlow:'row', marginTop:10}}>
                    {pro.socialMedia.facebook !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.facebook.includes('https') ? window.open(`${pro.socialMedia.facebook}`) : window.open(`https://${pro.socialMedia.facebook}`) }}><img src="https://img.icons8.com/fluency/48/000000/facebook-new.png" alt='fb'/></Avatar>    
                    }

                    {pro.socialMedia.instagram !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.instagram.includes('https') ? window.open(`${pro.socialMedia.instagram}`) : window.open(`https://${pro.socialMedia.instagram}`)}}><img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" alt="insta"/></Avatar>
                    }

                    {pro.socialMedia.twitter !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.twitter.includes('https') ? window.open(`${pro.socialMedia.twitter}`) : window.open(`https://${pro.socialMedia.twitter}`)}}><img src="https://img.icons8.com/color/48/000000/twitter-circled--v1.png" alt="twitter"/></Avatar>
                    }

                    {pro.socialMedia.tiktok !== '' && 
                    <Avatar onClick={()=> {pro.socialMedia.tiktok.includes('https') ? window.open(`${pro.socialMedia.tiktok}`) : window.open(`https://${pro.socialMedia.tiktok}`)}}><img src="https://img.icons8.com/color/48/000000/tiktok--v1.png" alt="tiktok"/></Avatar>
                    }
                  </div>
                  
                </Grid>
              </Grid>
              :
              <Grid item container xs={12} style={{minHeight:500}}>
                <Grid item container xs={12} md={6} style={{minHeight:(width==='sm' || width==='xs') ? 280 : 460, backgroundColor:'white', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', textAlign:'center', padding:10}}>
                  <Typography style={{width:'100%',marginTop:20, fontSize:20, fontWeight:'bold'}}>{pro.firstName+" "+pro.lastName}</Typography>
                  <Typography style={{width:'100%'}}>{pro.title}</Typography>
                  <Typography style={{width:'100%', marginTop:20}}>{pro.bio === '' ? `${pro.firstName} is a ${pro.title} currently staffed under ${brandAm.name}, providing quality services!` : pro.bio}</Typography>
                  <Button onClick={()=>window.location.assign(`/pro/profile/${pro.url}`)} variant='contained' style={{marginTop:40, backgroundColor:theme.primary, color:theme.secondary}}>Book Now</Button>
                  <div style={{display:'flex', flexFlow:'row', marginTop:10}}>
                    {pro.socialMedia.facebook !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.facebook.includes('https') ? window.open(`${pro.socialMedia.facebook}`) : window.open(`https://${pro.socialMedia.facebook}`) }}><img src="https://img.icons8.com/fluency/48/000000/facebook-new.png" alt='fb'/></Avatar>    
                    }

                    {pro.socialMedia.instagram !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.instagram.includes('https') ? window.open(`${pro.socialMedia.instagram}`) : window.open(`https://${pro.socialMedia.instagram}`)}}><img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" alt="insta"/></Avatar>
                    }

                    {pro.socialMedia.twitter !== '' && 
                    <Avatar style={{marginRight:5}} onClick={()=> {pro.socialMedia.twitter.includes('https') ? window.open(`${pro.socialMedia.twitter}`) : window.open(`https://${pro.socialMedia.twitter}`)}}><img src="https://img.icons8.com/color/48/000000/twitter-circled--v1.png" alt="twitter"/></Avatar>
                    }

                    {pro.socialMedia.tiktok !== '' && 
                    <Avatar onClick={()=> {pro.socialMedia.tiktok.includes('https') ? window.open(`${pro.socialMedia.tiktok}`) : window.open(`https://${pro.socialMedia.tiktok}`)}}><img src="https://img.icons8.com/color/48/000000/tiktok--v1.png" alt="tiktok"/></Avatar>
                    }
                  </div>
                  
                </Grid>
                <Grid item container xs={12} md={6} style={{minHeight:(width==='sm' || width==='xs') ? 280 : 460}}>
                <img src={pro.images.avatar} alt='profile' style={{objectFit:'cover', height:'100%', width:'100%'}}/>
                </Grid>
              </Grid>

              }
              </>
            )
          }
        })
      
      }
    </Grid>


    <Modal open={!!modalOpen} onClose={handleModalOpen}>
      <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

            <Grid item container justify='space-between' style={{backgroundColor: theme.primary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                <Typography variant='h6' style={{padding: '10px'}}>
                    Edit Design
                </Typography>
                <IconButton onClick={handleModalOpen} style={{color: 'white'}}>
                    <ClearIcon />
                </IconButton>
            </Grid>

            <Grid item contianer xs={12} style={{padding:20}}>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, paddingLeft:paddingLeft}}>
                  <Typography style={{fontWeight:'bold'}}>Background Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' defaultValue={backgroundColor} value={backgroundColor} style={{border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                        <input type='text' defaultValue={backgroundColor} value={backgroundColor} style={{width:'100%', border:'none'}} onChange={(e)=>setBackgroundColor(e.target.value)}/>
                    </span>
              </Grid>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Service Card Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' defaultValue={cardColor} value={cardColor} style={{border:'none'}} onChange={(e)=>setCardColor(e.target.value)}/>
                        <input type='text' defaultValue={cardColor} value={cardColor} style={{width:'100%', border:'none'}} onChange={(e)=>setCardColor(e.target.value)}/>
                    </span>
              </Grid>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Title Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' value={titleColor} style={{border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                        <input type='text' value={titleColor} style={{width:'100%', border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                    </span>
              </Grid>
              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Text Color</Typography>
                    <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                        <input type='color' value={fontColor} style={{border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                        <input type='text' value={fontColor} style={{width:'100%', border:'none'}} onChange={(e)=>setFontColor(e.target.value)}/>
                    </span>
              </Grid>

            </Grid>

            <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                <Button
                variant="contained"
                style={{  
                letterSpacing: '2px',marginRight: "1rem" }}
                onClick={handleCancel}
                >
                Cancel
                </Button>

                <Button
                style={{letterSpacing: '2px', backgroundColor:theme.primary, color:theme.secondary}}
                variant="contained"
                onClick={handleSave}
                >
                Save
                </Button>
            </div>

      </Grid>

    </Modal>

  </Grid>
  )
}

Staff.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Staff);
