import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Paper,
  Menu,
  InputBase,
  Tooltip,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";
import PlacesAutoComplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    border: "1px solid #e0e0e0",
    position: "relative",
    marginTop: "20px",
    minHeight: 510,
    boxShadow: "5px 5px 7px -1px rgba(0,0,0,0.26)",
    textAlign: "left",
  },
  titleWrapper: {
    borderRadius: "8px 8px 0 0",
    background: theme.palette.primary.main,
    height: "50px",
  },
  title: {
    color: "white",
    padding: "8px 0 0 20px",
  },
  question: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    fontSize: "calc(12px + 3 * ((100vw - 320px) / 680))",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    display: "flex",
    height: 50,
    alignItems: "center",
    width: "100%",
  },
  iconButton: {
    color: theme.palette.primary.main,
    fontSize: "40px",
    "&:hover": {
      cursor: "pointer",
      opacity: "80%",
    },
  },
  divider: {
    height: 28,
    margin: 4,
  },
  btn: {
    fontWeight: "bold",
    letterSpacing: "2px",
    marginTop: "1rem",
    height: "48px",
    width: 160,
  },
}));

export const Address = ({ formData, setForm, navigation, userData }) => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState([{ lat: null, lng: null }]);
  const [unformattedAddress, setUnformattedAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const disabled = address === "" ? true : false;

  useEffect(() => {
    formData.client = {
      name: userData.firstName + " " + userData.lastName,
      email: userData.email,
      userId: userData.userId,
      avatar: userData.images.avatar,
      phone: userData.phone,
    };

    if (
      (coordinates?.lat !== null || coordinates?.lng !== null) &&
      formData.startingCoordinates !== coordinates
    ) {
      //// console.log('1')
      const { lat, lng } = coordinates;
      Geocode.fromLatLng(`${lat}`, `${lng}`).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const addressArr = address.split(", ");
          const state = addressArr
            .slice(-2, -1)
            .join("")
            .split(" ")
            .slice(0, 1)
            .join();
          if (addressArr.length === 4) {
            addressArr.splice(2, 1, state);
            setUnformattedAddress(addressArr);
          } else if (addressArr.length === 5) {
            addressArr.splice(3, 1, state);
            setUnformattedAddress(addressArr);
          } else if (addressArr.length === 6) {
            addressArr.splice(4, 1, state);
            setUnformattedAddress(addressArr);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }

    if (unformattedAddress.length !== 0 && loading === true) {
      //// console.log('2')
      let formatted = unformattedAddress.join(", ");
      setAddress(formatted);
    }

    if (address !== "" && anchorEl !== null) {
      //// console.log('3?')
      formData.address = address;
      formData.startingCoordinates = coordinates;
    }

    if (address !== "" && loading === true) {
      //// console.log('3')
      setLoading(false);
      formData.address = address;
      formData.startingCoordinates = coordinates;
    }

    formData.startingCoordinates = coordinates;
    // console.log(formData)
  }, [
    formData,
    userData.images.avatar,
    userData.email,
    userData.firstName,
    userData.lastName,
    userData.userId,
    coordinates,
    address,
    anchorEl,
    loading,
    unformattedAddress,
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (value) => {
    setAddress(value);
  };

  const handleTravelToPro = () => {
    setAddress("I'll travel to the Pro.");
    setCoordinates(userData.coordinates);
    formData.location = "pro location";
    formData.address = userData.address;
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);

    setAddress(value);
    setCoordinates(latLng);
    formData.address = value;
  };

  return (
    <Grid container item xs={12} className={classes.root}>
      <Grid item xs={12} className={classes.titleWrapper}>
        <Typography variant="h6" className={classes.title}>
          Schedule A Service
        </Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding: "0px 20px" }}>
        <Typography className={classes.question}>
          3. Where would you like us to meet you?
        </Typography>

        {loading === true ? (
          <div
            style={{
              display: "flex",
              width: "inherit",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "#413e68" }} />
          </div>
        ) : (
          // <Autocomplete
          //   apiKey={"AIzaSyCfCg-US72LkkuiG9YKa0cxouHhhZtMgQg"}
          //   onPlaceSelected={(place) => {
          //     console.log(place);
          //   }}
          // />
          <PlacesAutoComplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            style={{ width: "inherit" }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ width: "inherit" }}>
                <Paper component="form" className={classes.input}>
                  <InputBase
                    aria-controls="simple-menu"
                    onClick={handleClick}
                    {...getInputProps({
                      placeholder: "Enter Your Address...",
                    })}
                    className={classes.input}
                  />
                  <Tooltip title="Use my location">
                    <LocationOnIcon
                      className={classes.iconButton}
                      onClick={setLocation}
                    />
                  </Tooltip>
                </Paper>

                <div
                  id="simple-menu"
                  anchorEl={anchorEl}
                  style={{
                    width: "98%",
                    zIndex: 1,
                    marginLeft: 10,
                    marginTop: 2,
                    maxHeight: 150,
                    overflowY: "scroll",
                    boxShadow: "4px 4px 6px -1px rgba(0,0,0,0.20)",
                    borderRadius: 4,
                  }}
                >
                  {loading && <Typography>Loading...</Typography>}
                  {suggestions.map((suggestion) => {
                    const style = suggestion.active
                      ? {
                          backgroundColor: "#413e68",
                          color: "white",
                          cursor: "pointer",
                        }
                      : {
                          backgroundColor: "white",
                          borderBottom: "1px solid #e6e6e6",
                        };

                    return (
                      <Typography
                        onClick={handleClose}
                        key={suggestion.description}
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutoComplete>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>-- or --</Typography>
      </Grid>

      <Grid container item xs={12} style={{ padding: "0px 15%" }}>
        <Button
          className={classes.btn}
          style={{ backgroundColor: "#413e68", color: "white", width: "100%" }}
          onClick={handleTravelToPro}
        >
          I'll travel to the Pro.
        </Button>
      </Grid>

      <Grid
        container
        item
        xs={6}
        style={{ padding: "0px 10px 36px 10px", justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          style={{ backgroundColor: "white", color: "#413e68" }}
          onClick={() => navigation.previous()}
        >
          Back
        </Button>
      </Grid>

      <Grid
        container
        item
        xs={6}
        style={{ padding: "0px 10px 36px 10px", justifyContent: "left" }}
      >
        <Tooltip
          title="Please select an Address!"
          disableHoverListener={!disabled}
        >
          <Button
            disabled={disabled}
            variant="contained"
            className={classes.btn}
            color="primary"
            onClick={() => navigation.next()}
          >
            Next
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
