import React, {useState, useEffect} from 'react'
import {Container, Grid, Typography, TextField, TextareaAutosize, Hidden, Button} from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';
import { firestore } from '../../../config/fbConfig';
import emailjs from 'emailjs-com';

const useStyles = makeStyles((theme) => ({
    infoContainer:{display:"flex",
      justifyContent:"center",
      padding: "10px",
      overflowY: "hidden scroll",
      borderTop: 'solid 1px #d3d3d3',
    },
    title:{
        width:'100%',
        textAlign:'center',
        fontWeight:'bold',
    },
    header:{

    },
    section:{
      borderRadius: 12,
      border:'1px solid lightgray',
      padding:10,
      marginBottom: 10,
      textDecoration:'none',
      color: 'black',
      "&:hover":{
        cursor:'pointer',
        border:`2px solid ${theme.palette.primary.main}`,
        backgroundColor:'#e6e6e6',
      },
    },
    link: {
      textDecoration:'none',
      color:'black',
      fontSize:24,
      '&:hover':{
          cursor:'pointer',
          color: theme.palette.primary.main
      }
    },
  }));

function Contact() {
    const classes = useStyles()
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const disabled = (name === '' || email === '' || message === '' || success===true) ? true : false
    const margin = (window.innerWidth > 959) ? '200px 0px' : '50px 0px'

    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccess(true)

        emailjs.sendForm('service_40l9v6e', 'template_s38516t', e.target, 'user_xmz0sMY70mPgmzqPLAjBK')
          .then((result) => {
              // console.log(result.text);
          }, (error) => {
              // console.log(error.text);
          })
        e.target.reset()
        setTimeout(()=> {setSuccess(false)},2500)
    }

    useEffect(() => {
        // console.log(margin)
    }, [margin])

    return (
        <Grid item container xs={12} className={classes.infoContainer}>
            <Typography variant='h2' className={classes.title}>Contact Us</Typography>

            <Grid item container xs={12} md={6} style={{margin: margin, display:'flex', justifyContent:'flex-end'}}>
                <form style={{width:'inherit'}} onSubmit={handleSubmit}>
                    <TextField
                        label="Your Name"
                        inputMode="text"
                        //onKeyPress={(e)=>{validateCC(e)}}
                        placeholder='Full Name'
                        name="name"
                        // value={name}
                        // onChange={(e)=> setName(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                    <TextField
                        label="Email"
                        inputMode="email"
                        //onKeyPress={(e)=>{validateCC(e)}}
                        placeholder='Email Address'
                        name="email"
                        // value={email}
                        // onChange={(e)=> setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                    <TextField
                        label="Subject"
                        inputMode="text"
                        //onKeyPress={(e)=>{validateCC(e)}}
                        placeholder='Subject'
                        name="subject"
                        // value={email}
                        // onChange={(e)=> setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                    <Typography style={{textAlign:'left', width:'100%'}}>Message:</Typography>
                    <TextareaAutosize
                        label="message"
                        name='message'
                        style={{width:'inherit', borderRadius:4, borderColor:'silver', padding:10, fontSize: 16, font:'inherit'}}
                        rowsMax={10}
                        rowsMin={10}
                        placeholder='Type your message here...'
                        // value={message}
                        // onChange={(e)=> setMessage(e.target.value)}
                    
                    />
                    <Button type='submit' 
                    // disabled={disabled} 
                    variant='contained' color='primary' style={{marginTop: 10}}>Submit</Button>
                    {success === true &&
                    <Typography style={{width:'100%', color:'green', marginTop:10, textAlign:'center'}}>Success, Your message has been submitted!</Typography>
                    }

                </form>
                </Grid>

        </Grid>
    )
}

export default Contact
