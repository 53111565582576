import 'date-fns';
import React, {useEffect} from "react";
import {Typography, Grid, Container, TextField, Button, Tooltip} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 12, 
      border:'1px solid #e0e0e0',
      position: 'relative',
      marginTop:'20px', // -10% for mobile
      minHeight: 530,
      boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)',
      textAlign: 'left'
    },
    titleWrapper:{
      borderRadius: '8px 8px 0 0',
      background: theme.palette.primary.main,
      height: "50px"
    },
    title: {
      color:"white",
      padding: "8px 0 0 20px"
    },
    question: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))"
    },
    sAPWrapper: {
      position: 'relative',
      height:30
    },
    sAPBtn: {
      position: 'absolute',
      top: 12,
      right: 12
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    dateTimePicker: {
      width:'100%'
    },
    iconButton: {
     color: theme.palette.primary.main,
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      //border: `3px ${theme.palette.primary.main} solid`,
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160,
    },

  }));

export const ServiceSelect = ({ formData, setForm, navigation, userData, services}) => {
  const {service, dateTime} = formData
  const [value, setValue] = React.useState(service);
  const [now, setNow] = React.useState(service);
  const [later, setLater] = React.useState(service);
  const [selectedService, setSelectedService] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(formData.dateTime);
  const classes = useStyles();
  const disabled = (service === '' || dateTime === null) ? true : false
  const disabled2 = (now === true || later === true) ? true : false
  const backgroundColor = (now === true || later === true) ? '#413e68' : null
  const color = (now === true || later === true) ? 'white' : null
  const variant = (now === true || later === true) ? 'contained' : 'outlined'

  useEffect(() => {
    formData.dateTime = new Date(`${selectedDate}`)

    if(now === true){
      formData.dateTime = new Date(new Date().getTime() + 900000)
    }

    if(later===true && formData.dateTime !== null && formData.dateTime < new Date(new Date().getTime() + 7140000)){
      //formData.dateTime = new Date(`${selectedDate}`)
      alert('Please select a time at lease 2 hours from now!')
      setSelectedDate('')
      formData.dateTime = null
    }




    console.log(formData)

  }, [selectedDate, formData, now, later, userData])

  const handleServiceChange = (event, newValue) => {
    setValue(newValue);
    // console.log(newValue, event.target.value)
    if(newValue === null){
      formData.service = event.target.value
    }else if(newValue !== null && newValue.includes(' --')){
      formData.service = newValue.split(' --').shift()
    }
    else{
      formData.service = event.target.value

    }
  }
  
  const handleDateChange = (e) => {
      setSelectedDate(e.target.value);

  };

  const handleNow = () => {
    setNow(true)
    setLater(false)
    formData.when = 'now'
  }
  
  const handleLater = () => {
    setNow(false)
    setLater(true)
    formData.when = 'later'
    // formData.dateTime = null
    // setSelectedDate('')
  }

  return (
    <Grid container item xs={12} className={classes.root}>
      <Grid item xs={12} className={classes.titleWrapper}>
        <Typography variant='h6' className={classes.title}>Schedule A Service</Typography>
      </Grid>

      {/* <Grid item xs={12} className={classes.sAPWrapper}>
        <Button variant="outlined" className={classes.sAPBtn}>
          <SearchIcon/> Search A Pro
        </Button>
      </Grid> */}
      
      <Grid container item xs={12} style={{ padding:20}}>

        <Typography className={classes.question}>1. What type of service would you like?</Typography>
        <Autocomplete
          value={value}
          onChange={handleServiceChange}
          options={services.map(option => option.name+" -- "+option.typeOfService)}
            renderInput={(params) => 
            <TextField
              {...params}
              label="Search s Service..."
              name="service"
              variant="outlined"
              InputProps={{...params.InputProps, type:'search'}}
              />}
            //getOptionLabel = {(option) => option.name}
            required
            fullWidth
        />
      </Grid>

      <Grid container item xs={12} style={{ padding:20, justifyContent: 'space-between'}}> 
        
            <Grid container justify="space-around">
                <Grid item xs={12}>
                    <Typography className={classes.question}>2. When are you available?</Typography>
                </Grid>

                {later !== true &&
                <Grid item xs={12} sm={5}>
                  <Button fullWidth variant={variant} color='primary' disabled={disabled2} style={{backgroundColor:backgroundColor, color:color}} onClick={handleNow}>NOW</Button>
                </Grid>
                }
                {now !== true &&
                <Grid item xs={12} sm={5}>
                  <Button fullWidth variant={variant} color='primary' disabled={disabled2} style={{backgroundColor:backgroundColor, color:color}} onClick={handleLater}>LATER</Button>
                </Grid>
                }


              {later === true &&
                <Grid item xs={12} style={{marginTop:10}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    name="date"
                    id="datetime-local"
                    label="Enter a Date/Time"
                    type="datetime-local"
                    value={selectedDate}
                    onChange={(e)=> handleDateChange(e)}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    minDate={new Date()}
                    minutesStep={15}
                  />
                </Grid>
              }

            </Grid>
       


        

      </Grid>

      <Grid item xs={12} style={{display:'flex', justifyContent: 'center', padding:'0px 0px 60px'}}>
        <Tooltip title="Please select a Service, Time, & Date!" disableHoverListener={!disabled}>
          <div>
            <Button
                disabled={disabled}
                variant="contained"
                className={classes.btn}
                color='primary'
                onClick={() => navigation.next()}>
                {/* onClick={handleClick}> */}
                Let's Go
            </Button>
          </div>
        </Tooltip> 
      </Grid>
    </Grid>
  );
};