import React from 'react'
import PropTypes from 'prop-types';
import {Grid, Typography, IconButton, withWidth} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration:'none',
        color:'black',
        '&:hover':{
            cursor:'pointer',
            fontSize:14,
            color: theme.palette.primary.main
        }
    },
    }
      ));

function Footer({user, width}) {
    const margin = !!user ? 50 : null
    const classes = useStyles()
    const paddingLeft = (width==="xs"||width==='sm') ? null : 40
    const textAlign = (width==="xs"||width==='sm') ? 'center' : null
    const paddingBottom = (width==="xs"||width==='sm') ? 40 : null
    const justify = (width==="xs"||width==='sm') ? 'center' : 'flex-end'
    return (
        <Grid container style={{background: "darkgrey", marginLeft:margin}}>
            <Grid container item xs={12} style={{padding: '20px', display:'flex', alignItems:'center'}}>

                <Grid container item md={6} direction="column" style={{paddingLeft:paddingLeft, paddingBottom:paddingBottom}}>
                    <span style={{fontFamily: 'sarina, cursive', fontSize:30, textAlign:textAlign, color:'white'}}> Cuti </span>  
                    <Typography variant="caption" color="initial" style={{textAlign:textAlign, color:'white'}}>BEAUTY & WELLNESS ON-DEMAND</Typography>
                </Grid>

                <Grid container item md={6} style={{display:'flex', justifyContent:'space-evenly'}}>
                    <Typography component={Link} to='/ip/about' className={classes.link}>About Us</Typography>
                    <Typography component={Link} to='/ip/terms' className={classes.link}>Terms of Service</Typography>
                    <Typography component={Link} to='/ip/privacy' className={classes.link}>Privacy Policy</Typography>
                    <Typography component={Link} to='/ip/careers' className={classes.link}>Careers</Typography>
                    <Typography component={Link} to='/ip/faqs' className={classes.link}>FAQs</Typography>
                    <Typography component={Link} to='/ip/contact' className={classes.link}>Contact</Typography>
                </Grid>  
                
            </Grid>            

            <Grid container item xs={12} style={{borderTop:"1px black solid", padding: "20px"}}>
                <Grid item xs={12} sm={6} style={{paddingLeft:paddingLeft}}> 
                    <Typography style={{textAlign:textAlign, color:'white'}}>
                        © 2022 Cuti App. All Rights Reserved.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{display:'flex', justifyContent:justify,}}>
                     <IconButton onClick={()=> window.open('https://www.instagram.com/cutiapp')}><img src="https://img.icons8.com/ios/24/000000/instagram-new--v1.png" alt='social-1'/></IconButton>
                     <IconButton onClick={()=> window.open('https://www.facebook.com/cutiapp')}><img src="https://img.icons8.com/ios/24/000000/facebook--v1.png" alt='social-2'/></IconButton>
                     <IconButton onClick={()=> window.open('https://www.twitter.com/Cuti_app')}><img src="https://img.icons8.com/ios/24/000000/twitter--v1.png" alt='social-3'/></IconButton>
                </Grid>
    
            </Grid>
        </Grid>
    )
}

Footer.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };

export default withWidth()(Footer)
