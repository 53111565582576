import React, {useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Button, IconButton, Modal, TextField, Select, FormControl, InputLabel, MenuItem, Avatar, CircularProgress, withWidth} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import ClearIcon from '@material-ui/icons/Clear'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../../../src/components/modals/canvasUtils'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import EditIcon from '@material-ui/icons/Edit';
import { firestore, storage } from '../../../config/fbConfig';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

const Hero = ({uid, designData, classes, light, handleScroll, paddingLeft, userData, brandAm, themeEditorOpen, setThemeEditorOpen, edit, handleEdit, width}) => {
    // const [light, setLight] = useState(brandTheme.light)
    const {hero, theme} = designData
    const themeLight = light === true ? 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)' : 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)'
    
    const [title, setName] = useState(hero.title)
    const [titleColor, setTitleColor] = useState(hero.titleColor)
    const [subtitle, setSubtitle] = useState(hero.subtitle)
    const [subtitleColor, setSubtitleColor] = useState(hero.subtitleColor)
    const [buttonText, setButtonText] = useState(hero.buttonText)
    const [alignment, setAlignment] = useState(hero.textAlignment)
    const [backgroundImage, setBackgroundImage] = useState(hero.backgroundImage)
    const [logo, setLogo] = useState(hero.logo)
    
    const fileInput = useRef();
    const [imageSrc, setImageSrc] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [imageLoading, setImageLoading] = useState(false)
    const [imageType, setImageType] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    const btnFlex = alignment === 'center' ? 'center' : alignment === 'left' ? 'flex-start' : 'flex-end'

    const logoSize = (width === 'xs' || width === 'sm' || width === 'md') ? 130 : 215    

    const handleChange = (event) => {
        setAlignment(event.target.value);
      };

      const select = () => {
        fileInput.current.click()
      }

    const handleModalOpen = () => {
      setModalOpen(!modalOpen)
    }
    const closeModal = () => {
        setImageSrc(null)
        setModalOpen(!modalOpen)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0]
          let imageDataUrl = await readFile(file)
    
          // apply rotation if needed
          const orientation = await getOrientation(file)
          const rotation = ORIENTATION_TO_ANGLE[orientation]
          if (rotation) {
            imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
          }
    
          setImageSrc(imageDataUrl)
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => resolve(reader.result), false)
          reader.readAsDataURL(file)
        })
      }

      const uploadCroppedImage = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation
          )
    
          var xhr = new XMLHttpRequest();
          xhr.open('GET', `${croppedImage}`, true);
          xhr.responseType = 'blob';
          xhr.onload = function(e) {
            if (this.status === 200) {
              var myBlob = this.response;
              // myBlob is now the blob that the object URL pointed to.
                
                  let fileurl = ''
                  var uploadTask = imageType === 'logo' ? storage.ref(`BrandAmbassadorInfo/${brandAm.email}-${brandAm.name}/images/hero/logo`).put(myBlob,{
                    contentType: 'image/png'}) :
                  storage.ref(`BrandAmbassadorInfo/${brandAm.email}-${brandAm.name}/images/hero/backgroundImage`).put(myBlob)
                  uploadTask.on('state_changed', 
                    (snapshot) => {
                      //Handle progress...
                      setImageLoading(true)
                    }, 
                    (error) => {
                      // Handle unsuccessful uploads...
                    }, 
                    async () => {
                      // Handle successful uploads on complete...
                      await uploadTask.snapshot.ref.getDownloadURL().then( async (url) => {
                        fileurl = url
                        // console.log('File available at', fileurl)
                        if(imageType==='logo'){
                          setLogo(url)
                        }else{
                          setBackgroundImage(url)
                        }
                        setImageLoading(false)
                        closeModal()
                      })
                    }
                  )
                
            }
          };
          xhr.send();
    
          // console.log(croppedImage)
          setCroppedImage(croppedImage)
    
    
        } catch (e) {
          console.error(e)
        }
      }, [imageSrc, croppedAreaPixels, rotation, brandAm.email, brandAm.name])
    


    const handleSave = () => {   
        firestore.collection('brandAms').doc(uid).collection('design').doc('designInfo').update({
            'hero.title': title,
            'hero.titleColor': titleColor,
            'hero.subtitle': subtitle,
            'hero.subtitleColor': subtitleColor,
            'hero.buttonText': buttonText,
            'hero.textAlignment': alignment,
            'hero.backgroundImage': backgroundImage,
            'hero.logo': logo,

        }).then(setImageType(null))       
    };

    const handleCancel = () => {
      setName(hero.title)
      setTitleColor(hero.titleColor)
      setSubtitle(hero.subtitle)
      setSubtitleColor(hero.subtitleColor)
      setButtonText(hero.buttonText)
      setAlignment(hero.textAlignment)
      handleModalOpen()
    }



// 
  return (
    <Grid item contianer xs={12} style={{height:'calc(80vh - 50px)', paddingLeft: paddingLeft, paddingRight:20, backgroundColor:'lightgray', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', position:'relative', overflow:'hidden', marginTop:-350}}>
      <img alt="" src={backgroundImage} style={{position:'absolute', width:'100%', height:'100%', objectFit:'cover'}}/>
        {/* {(userData.email === brandAm.email && edit===false) && <Button variant='contained' style={{position:'absolute', top:20, right:20, zIndex:1}} onClick={handleEdit}>Edit</Button>} */}
{/* 
        {(userData.email === brandAm.email && edit===true) && 
        <div style={{position:'absolute', top:20, right:20, zIndex:1, display:'flex'}}>
          <Button variant='contained' style={{marginRight:5}} onClick={()=> setThemeEditorOpen(!themeEditorOpen)}>Edit Theme</Button>
          <Button variant='contained' style={{backgroundColor:'red', color:'white'}} onClick={handleEdit}>Close</Button>
        </div>} */}


        {userData.email === brandAm.email && edit===true ?
        <div style={{zIndex:1, display:'flex', flexFlow:'column', width:'100%', textAlign:alignment, alignItems:btnFlex}}>
          <div style={{display:'flex', width:'100%', alignItems:'center', justifyContent:alignment}}>
            <Typography style={{fontSize:50, fontWeight:'bold', color: titleColor, textShadow:'2px 2px 3px black'}}>{title}</Typography>
            <Avatar className={classes.circBtn} onClick={handleModalOpen}><EditIcon/></Avatar>
          </div>
          <Typography style={{width:'100%', textAlign:alignment, color: subtitleColor, textShadow:'1px 1px 3px black'}}>{subtitle}</Typography>
          <Button variant='contained' style={{zIndex:1, marginTop:40, backgroundColor:theme.primary, color:theme.secondary, width:'fit-content'}} disabled>{buttonText}</Button>
        </div>

        :

        <div style={{zIndex:1, width:'100%', display:'flex',flexFlow:'column', textAlign:alignment, alignItems:btnFlex}}>
          <Typography style={{fontSize:50, fontWeight:'bold', color: titleColor, textShadow:'2px 2px 3px black'}}>{title}</Typography>
          <Typography style={{width:'100%', textAlign:alignment, color: subtitleColor, textShadow:'1px 1px 3px black'}}>{subtitle}</Typography>
          <Button variant='contained' style={{zIndex:1, marginTop:40, backgroundColor:theme.primary, color:theme.secondary, width:'fit-content'}} onClick={handleScroll}>{buttonText}</Button>
        </div>
        }

        <img alt="" src={logo} style={{position:'absolute', width:logoSize, height:logoSize, objectFit:'contain', top:50, objectPosition:'top', left:70, zIndex:1}}/>
        <div style={{position:'absolute', width:'100%', height:'100%', backgroundColor:'#0000004d'}}></div>
        {/* <div style={{width:'100%', height:200, background:themeLight, position:'absolute', bottom:0}}></div> */}




        {/*---------------------------- MODAL----------------------------- */}


        <Modal open={!!modalOpen} onClose={handleModalOpen}>
          <Grid container item xs={10} sm={6} md={5} style={{outline:'none', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px'}}>

                <Grid item container justify='space-between' style={{backgroundColor: theme.primary, color: 'white', height: '50px', borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px'}}>
                        Edit Title/Subtitle
                    </Typography>
                    <IconButton onClick={handleModalOpen} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid container item xs={12} style={{padding:20}}>
                  <TextField
                    label="Business/Display Name"
                    placeholder={title}
                    name="name"
                    value={title}
                    onChange={(e)=> setName(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                  />

                  <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>Title Color</Typography>
                        <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                            <input type='color' defaultValue={titleColor} value={titleColor} style={{border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                            <input type='text' defaultValue={titleColor} value={titleColor} style={{width:'100%', border:'none'}} onChange={(e)=>setTitleColor(e.target.value)}/>
                        </span>
                  </Grid>

                  <TextField
                    label="Tagline/Slogan (optional)"
                    style={{width:'inherit', borderRadius:4, borderColor:'silver', fontSize: 16, font:'inherit'}}
                    margin="normal"
                    variant="outlined"
                    placeholder={subtitle}
                    value={subtitle}
                    onChange={(e)=> setSubtitle(e.target.value)}
                  />

                <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Title Color</Typography>
                      <span style={{display:'inline-flex', alignItems:'center', width:150, maxWidth:150, padding:'4x 12px', border:'1px solid lightgray', borderRadius:4}}>
                          <input type='color' defaultValue={subtitleColor} value={subtitleColor} style={{border:'none'}} onChange={(e)=>setSubtitleColor(e.target.value)}/>
                          <input type='text' defaultValue={subtitleColor} value={subtitleColor} style={{width:'100%', border:'none'}} onChange={(e)=>setSubtitleColor(e.target.value)}/>
                      </span>
                </Grid>

                  <TextField
                      label="Button"
                      style={{width:'inherit', borderRadius:4, borderColor:'silver', fontSize: 16, font:'inherit'}}
                      margin="normal"
                      variant="outlined"
                      placeholder={buttonText}
                      value={buttonText}
                      onChange={(e)=> setButtonText(e.target.value)}
                />

                  <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">Alignment</InputLabel>
                      <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={alignment}
                      onChange={handleChange}
                      label={`Alignment: ${alignment}`}
                      >                      
                      <MenuItem value='left'>left</MenuItem>
                      <MenuItem value='center'>center</MenuItem>
                      <MenuItem value='right'>right</MenuItem>
                      </Select>
                  </FormControl>

              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Logo</Typography>
                  <input style={{display: 'none'}}
                      type="file"
                      id="image"
                      name="image"
                      onChange={onFileChange}
                      ref={fileInput}
                      accept=".gif,.jpg,.jpeg,.png"
                  /> 
                  <Avatar onClick={()=> {select(); setImageType('logo')}}><AddAPhotoIcon/></Avatar> 
              </Grid>

              <Grid item container xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:10, padding:'0px 20px'}}>
                  <Typography style={{fontWeight:'bold'}}>Background Image</Typography>
                  <input style={{display: 'none'}}
                      type="file"
                      id="image"
                      name="image"
                      onChange={onFileChange}
                      ref={fileInput}
                      accept=".gif,.jpg,.jpeg,.png"
                  /> 
                  <Avatar onClick={()=> {select(); setImageType('background')}}><AddAPhotoIcon/></Avatar> 
              </Grid>   

                <div style={{marginTop: "1rem", display:'flex', justifyContent:'center', width:'inherit', paddingBottom:20 }}>
                    <Button
                    variant="contained"
                    style={{  
                    letterSpacing: '2px',marginRight: "1rem" }}
                    onClick={handleCancel}
                    >
                    Cancel
                    </Button>

                    <Button
                    style={{letterSpacing: '2px', backgroundColor:theme.primary, color:theme.secondary}}
                    variant="contained"
                    onClick={handleSave}
                    >
                    Save
                    </Button>
                </div>

                </Grid>


                <Modal open={imageSrc !== null} onClose={closeModal}>
                  <Grid container item xs={10} sm={6} md={4}
                  style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px', padding:'30px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none', maxHeight:'90%'}}>
                      <div style={{width:'inherit', height:'inherit', position:'relative'}}>
                      <div className={classes.cropContainer}>
                          {imageType === 'logo' &&
                          <Cropper
                          image={imageSrc}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={1 / 1}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                          />
                          }
                          {imageType === 'background' &&
                          <Cropper
                          image={imageSrc}
                          crop={crop}
                          rotation={rotation}
                          zoom={zoom}
                          aspect={16 / 9}
                          onCropChange={setCrop}
                          onRotationChange={setRotation}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                          />
                          }
                      </div>
                      </div>
                      <div className={classes.controls}>
                      <div className={classes.sliderContainer}>
                          <Typography
                          variant="overline"
                          classes={{ root: classes.sliderLabel }}
                          >
                          Zoom
                          </Typography>
                          <Slider
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          aria-labelledby="Zoom"
                          classes={{ root: classes.slider }}
                          onChange={(e, zoom) => setZoom(zoom)}
                          />
                      </div>
                      <div className={classes.sliderContainer}>
                          <Typography
                          variant="overline"
                          classes={{ root: classes.sliderLabel }}
                          >
                          Rotation
                          </Typography>
                          <Slider
                          value={rotation}
                          min={0}
                          max={360}
                          step={1}
                          aria-labelledby="Rotation"
                          classes={{ root: classes.slider }}
                          onChange={(e, rotation) => setRotation(rotation)}
                          />
                      </div>
                      </div>
                      <Grid item container xs={12} style={{display:'flex', justifyContent:'space-evenly'}}>
                          <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={closeModal}>Cancel</Button>
                          {imageLoading === false ?
                          <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} onClick={uploadCroppedImage}>Upload</Button>
                          :
                          <div className={classes.wrapper}>
                          <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:20}} disabled>Uploading...</Button>
                          <CircularProgress size={24} className={classes.buttonProgress} />
                      </div> 
                          }

                      </Grid>
                  </Grid>
              </Modal>                       
          </Grid>

        </Modal>

    </Grid>
  );
}


Hero.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Hero);
