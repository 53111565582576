import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import {Typography, Grid, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Checkbox, Button, Avatar, Snackbar, withWidth} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import { firestore } from '../../../config/fbConfig';

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    title: {
      color: theme.palette.primary.main,
      padding: "8px 0 0 20px",
      fontWeight:'bold',
    },
    btn: {  
      fontWeight: 'bold',
      border: '3px #413e68 solid',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    info: {
        fontSize: 12,
        color: 'grey',
    },
    infoIcon: {
        fontSize: 16,
        color: 'grey',
        verticalAlign: 'middle'
    },
    infoContainer: {
        display:'flex',
        alignItems: 'center',
    }

  }));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  
const Review = ({ formData, navigation, width, userData, appt, handleModalOpen, modalOpen, handleMenuClose}) => {
  const { service, serviceCost, bookingFee, travelFee, dateTime, address, havePets, petTypes, pro } = formData;
  const [checked, setChecked] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openErr, setOpenErr] = useState(false)
  const severity = ["error", "success"]

  const handleToggle = (id) => () => {
    const newChecked = [...checked];

    if (!checked.includes(id)) {
      newChecked.push(id);
      setChecked(newChecked);
    } else {
      setChecked(checked.filter(apptId => apptId !== id));
    }

  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenErr(false);
  };

  const handleClose = () => {
    formData = {
      service: "",
      serviceCost: "",
      dateTime: null,
      address: "",
      havePets: '',
      petTypes: "",
      notes: "",
      client: null,
      pro: null,
      bookingFee: "",
      travelFee: "",
    }
    handleModalOpen()
  };

  const handleConfirm = () => {

    if(formData.selectedTime !== undefined && formData.apptsToPush){
        // console.log('Updating Time!')
        firestore.collection('appointments').doc(`${formData.apptId}`).update({
            serviceTimeReq: new Date(formData.selectedTime),
            serviceTimeEnd: new Date((formData.serviceTimeEnd.seconds*1000)+ formData.amtOfTime.amtOfTime),
            serviceRescheduled: true,
            serviceRescheduledAt: new Date(),
            serviceRescheduledBy: userData.firstName + " " + userData.lastName,
  
        }).then(() => {
            if(checked.length > 0){
                // console.log('Updating the checked appts too!')
                const updateAppts = formData.apptsToPush.filter(appt => checked.includes(appt.id))
                updateAppts.forEach(appt => {
                    firestore.collection('appointments').doc(`${appt.id}`).update({
                        serviceTimeReq: new Date((appt.data.serviceTimeReq.seconds*1000)+ formData.amtOfTime.amtOfTime),
                        serviceTimeEnd: new Date((appt.data.serviceTimeEnd.seconds*1000)+ formData.amtOfTime.amtOfTime),
                        serviceRescheduled: true,
                        serviceRescheduledAt: new Date(),
                        serviceRescheduledBy: userData.firstName + " " + userData.lastName,
                    }).catch((error) => {
                        // console.log("ERROR HERE:", error)
                    })
                })
            }else{
              // console.log('Nothing here to update..')
            }
        }).then(() => {
          // console.log("Document successfully updated!");
          // setChecked(!checked)
          setOpenSuccess(!openSuccess)
      })
      // .then(() => window.location.reload())
      .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          setOpenErr(!openErr)
      });
    }else{
        // console.log('Change Date!!')
        firestore.collection('appointments').doc(`${formData.apptId}`).update({
            serviceTimeReq: new Date(formData.dateTime),
            serviceTimeEnd: new Date (new Date(formData.dateTime).getTime() + ((appt.docData.serviceTimeEnd.seconds*1000) - (appt.docData.serviceTimeReq.seconds*1000))),
            serviceRescheduled: true,
            serviceRescheduledAt: new Date(),
            serviceRescheduledBy: userData.firstName + " " + userData.lastName,
  
        }).then(() => {
          // console.log("Document successfully updated!");
          // setChecked(!checked)
          setOpenSuccess(!openSuccess)
          setTimeout(() => {
            handleMenuClose()
          }, 2000);
          setTimeout(() => {
            handleModalOpen()
          }, 2500);
      })
      // .then(() => window.location.reload())
      .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
          setOpenErr(!openErr)
      });
    }

  }
//   const firstName = pro.name.split(' ')[0]
//   const lastInitial = pro.name.split(' ')[1].charAt(0)
//   const firstNameLastInitial = firstName + ' ' + lastInitial + '.'
  
//   const dateTimeArry = dateTime.toString().split(' ');

//   const weekday = dateTimeArry.slice(0, 1)
//   const monthDay = dateTimeArry.slice(1, 3).join(' ')
//   const date = weekday + " - " + monthDay + ", " + dateTimeArry.slice(3,4)

//   const timeZoneArr = dateTimeArry.slice(6,9).join(' ').split('(').join(' ').split(')').join(' ').trim().split(' ')
//   const timeZone = timeZoneArr.map((word) => word.charAt(0)).join("")
//   const time = dateTimeArry.slice(4,5).join('') + " (" + timeZone + ")"

//   const total = (parseFloat(serviceCost) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)
  //const deposit = (parseFloat(serviceCost / 4) + parseFloat(travelFee) + parseFloat(bookingFee)).toFixed(2)

  const avatarSize = (width === 'xs' || width === 'sm') ? 30 : 40
  const priceSize = (width === 'xs' || width === 'sm') ? 22 : 26
  const boxHgt = (width ==='xs' ||width === 'sm' ||width === 'md') ? '280px' : '340px'

  useEffect(() => {
      // // console.log('checked appts:', checked)
      // console.log(formData)
  }, [checked, formData])



  const classes = useStyles();

//   const handleToken = async (token) => {
//     const response = await axios.post('https://oyfkz.sse.codesandbox.io/checkout', {
//       token,
//       formData
//     })
//     const {status} = response.data
//     if (status === 'success') {
//       // console.log(status)
//       navigation.next()
//     }
//   }
  
  return (
    <Grid item xs={11} sm={10} md={8} lg={6} xl={5} container style={{outline:'none', position:'absolute', height:600, top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
      <Grid item xs={12}>
          <Typography variant='h5' className={classes.title}>Reschedule</Typography>
      </Grid>
      <Grid container item xs={12} style={{ padding:'10px 20px', textAlign: 'center'}}>
            <Typography style={{fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))", fontWeight:'bold', width:'inherit'}}>Original Time: {new Date(appt.docData.serviceTimeReq.seconds*1000).toLocaleString()}</Typography>
        
        </Grid>

      {formData.apptsToPush ?
      <Grid container item xs={12} style={{padding:'0px 20px'}}>
        <Typography variant='h6' style={{color:"#413e68", width:'100%'}}>You have other appointments later today.</Typography>
        <Typography style={{width:"100%"}}>Select all other appointments you would like to push back {formData.amtOfTime.start}.</Typography>
        <Grid item container xs={12} style={{height:boxHgt}}>
        <SimpleBarReact autoHide={false} style={{maxHeight: '99%', width: 'inherit', padding:'0px 15px 5px 5px'}}>
            <List style={{width:'100%'}}>
                {formData.apptsToPush.length > 0 && formData.apptsToPush.map((appt) => {
                const {id, data } = appt
                const {clientName, service, serviceTimeReq, clientAvatar} = data
                return(
                    <ListItem key={id} button>
                        <ListItemAvatar>
                        <Avatar
                            alt={`Avatar`}
                            src={clientAvatar}
                        />
                        </ListItemAvatar>
                        <ListItemText id={id} primary={clientName}
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" style={{display:"inline"}} color="textPrimary">{service}</Typography>
                                {` - ${new Date(serviceTimeReq.seconds*1000).toLocaleTimeString()}`}
                            </React.Fragment>
                        }/>
                        <ListItemSecondaryAction>
                        <Checkbox
                            edge="start"
                            onChange={handleToggle(id)}
                            checked={checked.includes(id)}
                            style={checked.includes(id) ? {color:'#413e68'} : {color:'silver'}}
                            inputProps={{ 'aria-labelledby': id }}
                        />
                        </ListItemSecondaryAction>
                    </ListItem>
                ) 
                })}     
            </List>
        </SimpleBarReact>

        </Grid>
      </Grid>
      :
      <Grid container item xs={12} style={{padding:'0px 20px'}}>
        <Typography style={{width:"100%"}}>Does everything look right?</Typography>
        <Grid item container xs={12} style={{height:boxHgt}}>
            <List style={{width:'100%'}}>
                    <ListItem key={appt.Id}>
                        <ListItemAvatar>
                        <Avatar
                            alt={`Avatar`}
                            src={appt.docData.clientAvatar}
                        />
                        </ListItemAvatar>
                        <ListItemText id={appt.id} primary={appt.docData.clientName}
                        secondary={
                            <React.Fragment>
                                <Typography component="span" variant="body2" style={{display:"inline"}} color="textPrimary">{service}</Typography>
                                {` - Updated Time: ${new Date(formData.dateTime).toLocaleTimeString()}`}
                            </React.Fragment>
                        }/>
                        <ListItemSecondaryAction>
                        </ListItemSecondaryAction>
                    </ListItem>
    
            </List>

        </Grid>
        <Typography style={{color:"#413e68", width:'100%', textAlign:'center'}}>Your client will be notified via email.</Typography>
      </Grid>
      }



      <Grid container item xs={6} style={{ padding: '0px 10px 50px', justifyContent: 'flex-end'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ backgroundColor: 'white',  
              color: '#413e68'}}
            onClick={() => navigation.previous()}>
            Back
        </Button>

      </Grid>

      <Grid container item xs={6} style={{ padding: '0px 10px 50px', justifyContent: 'left'}}> 
          <Button
          fullWidth
            variant="contained"
            className={classes.btn}
            style={{ backgroundColor: '#413e68',  
              color: 'white'}}
            // onClick={handleConfirm}
            onClick={handleConfirm}
            >
            Confirm
        </Button>

      </Grid>

      <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleCloseSnackbar} severity={severity[1]}>
                'Successfully Updated! 🎉'
                </Alert>
            </Snackbar>
            <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleCloseSnackbar} severity={severity[0]}>
                    Oops, something went wrong...
                </Alert>
            </Snackbar>
    </Grid>
  );
};


Review.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Review)
