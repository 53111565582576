import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment, { weekdays } from "moment";
import { format } from "date-fns";

//Put inside its own component?
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.secondary.dark}`,
    height: "100%",
  },
  title: {
    fontWeight: "bold",
    fontSize: 10,
    color: theme.palette.secondary.dark,
  },
  balance: {
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: 1,
    marginTop: 10,
  },
  balanceWrapper: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    paddingLeft: 20,
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    padding: "10px 5px 0px 5px",
  },
  arrow: {
    color: theme.palette.secondary.dark,
    borderRadius: "6px",
    background: "#EDEEF2",
    padding: 6,
    "&:hover": {
      cursor: "pointer",
    },
  },
  infoWrapper: {
    borderTop: `1px solid ${theme.palette.secondary.dark}`,
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 20px",
    padding: "5px 0px",
  },
  info: {
    fontWeight: "bold",
  },
}));

export default function WeeklyOverview({ appointments }) {
  //const appointments = useSelector(state => state.appt.appointments)
  const classes = useStyles();
  const thisSunday = moment().startOf("week").toDate();
  const [weekDates, setWeekDates] = useState([]);
  const [prevButtonClicked, setPrevClicked] = useState(false);
  const [nextButtonClicked, setNextClicked] = useState(false);
  const [todayClicked, setTodayClicked] = useState(true);
  const [currWeekAppointments, setCurrWeekAppointments] = useState([]);
  const [tips, setTips] = useState("0.00");
  const [loadingTips, setLoadingTips] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCurrent, setIsCurrent] = useState(true);

  const earnings = currWeekAppointments.reduce((accumulator, currentValue) => {
    return (
      accumulator +
      (parseFloat(currentValue.serviceFee) +
        parseFloat(currentValue.serviceFeeTravel))
    );
  }, 0);
  const travelFee = currWeekAppointments.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue.serviceFeeTravel);
  }, 0);

  var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [data, setData] = useState([]);
  const [dayAmts, setDayAmts] = useState([]);
  const [reducedDayAmts, setReducedDayAmts] = useState([]);
  const startOfWeek =
    weekDates.length === 8 ? format(weekDates[0], "MM/dd/yy") : "";
  const endOfWeek =
    weekDates.length === 8 ? format(weekDates[6], "MM/dd/yy") : "";

  useEffect(() => {
    var week = [];
    let initDayAmtArr = [];
    let dayAmtObjsArr = [];
    // Starting Sunday
    if (weekDates.length <= 0 || todayClicked === true) {
      //// console.log("1")

      setLoading(true);
      setData([]);
      setDayAmts([]);
      setReducedDayAmts([]);
      thisSunday.setDate(thisSunday.getDate() - thisSunday.getDay());
      for (var i = 0; i < 8; i++) {
        week.push(new Date(thisSunday));
        thisSunday.setDate(thisSunday.getDate() + 1);
        setWeekDates(week);
        setTodayClicked(false);
        setIsCurrent(true);
        setCurrWeekAppointments([]);
        setTips("0.00");
        setLoadingTips(true);
      }
    } else {
      if (prevButtonClicked === true) {
        //// console.log("alt 1")

        let currentSunday = weekDates[0];
        setLoading(true);
        setCurrWeekAppointments([]);
        setData([]);
        setDayAmts([]);
        setReducedDayAmts([]);
        setTips("0.00");
        setLoadingTips(true);
        currentSunday.setDate(
          currentSunday.getDate() - currentSunday.getDay() - 7
        );
        for (var i = 0; i < 8; i++) {
          week.push(new Date(currentSunday));
          currentSunday.setDate(currentSunday.getDate() + 1);
          setWeekDates(week);
          setPrevClicked(false);
          if (new Date(weekDates[0]) !== new Date(thisSunday)) {
            setIsCurrent(false);
          }
        }
      }
      if (nextButtonClicked === true) {
        //// console.log("alt 1")

        let currentSunday = weekDates[0];

        currentSunday.setDate(
          currentSunday.getDate() - currentSunday.getDay() + 7
        );
        setLoading(true);
        setCurrWeekAppointments([]);
        setData([]);
        setDayAmts([]);
        setReducedDayAmts([]);
        setTips("0.00");
        setLoadingTips(true);
        for (var i = 0; i < 8; i++) {
          week.push(new Date(currentSunday));
          currentSunday.setDate(currentSunday.getDate() + 1);
          setWeekDates(week);
          setNextClicked(false);
          if (new Date(weekDates[0]) !== new Date(thisSunday)) {
            setIsCurrent(false);
          }
        }
      }
    }

    if (
      loading === true &&
      weekDates.length === 8 &&
      appointments.length > 0 &&
      currWeekAppointments.length === 0
    ) {
      console.log(
        "2",
        appointments.filter(
          (x) =>
            new Date(x.serviceTimeEnd.seconds * 1000) >=
              new Date(weekDates[0]) &&
            new Date(x.serviceTimeEnd.seconds * 1000) < new Date(weekDates[7])
        )
      );
      setCurrWeekAppointments(
        appointments.filter(
          (x) =>
            new Date(x.serviceTimeEnd.seconds * 1000) >=
              new Date(weekDates[0]) &&
            new Date(x.serviceTimeEnd.seconds * 1000) < new Date(weekDates[7])
        )
      );
      setLoading(false);
    }

    if (currWeekAppointments.length > 0 && dayAmts.length === 0) {
      //// console.log("3")
      initDayAmtArr = [];
      currWeekAppointments.forEach(async (appt) => {
        const day = new Date(appt.serviceTimeEnd.seconds * 1000)
          .toString()
          .split(" ")
          .shift();
        const totalAmt =
          parseFloat(appt.serviceFee) + parseFloat(appt.serviceFeeTravel);
        // const completedAmt = parseFloat(appt.serviceFee)
        await initDayAmtArr.push({ name: day, amt: totalAmt });
        setDayAmts(initDayAmtArr);
      });
    }

    if (dayAmts.length > 0 && data.length === 0) {
      //// console.log("4")
      setLoading(null);
      dayAmtObjsArr = [];

      //-------------CLEAN TIHS UP!!!!---------------------- -antony @ 8/10/21

      const sun = dayAmts.filter((element) => element.name === "Sun");
      if (sun.length > 0) {
        let dailySum = sun.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.amt;
        }, 0);
        dayAmtObjsArr.push({ name: sun[0].name, amt: dailySum });
      } else {
        dayAmtObjsArr.push({ name: "Sun", amt: 0 });
      }

      const mon = dayAmts.filter((element) => element.name === "Mon");
      if (mon.length > 0) {
        let dailySum = mon.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.amt;
        }, 0);
        dayAmtObjsArr.push({ name: mon[0].name, amt: dailySum });
      } else {
        dayAmtObjsArr.push({ name: "Mon", amt: 0 });
      }

      const tue = dayAmts.filter((element) => element.name === "Tue");
      if (tue.length > 0) {
        let dailySum = tue.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.amt;
        }, 0);
        dayAmtObjsArr.push({ name: tue[0].name, amt: dailySum });
      } else {
        dayAmtObjsArr.push({ name: "Tue", amt: 0 });
      }

      const wed = dayAmts.filter((element) => element.name === "Wed");
      if (wed.length > 0) {
        let dailySum = wed.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.amt;
        }, 0);
        dayAmtObjsArr.push({ name: wed[0].name, amt: dailySum });
      } else {
        dayAmtObjsArr.push({ name: "Wed", amt: 0 });
      }

      const thu = dayAmts.filter((element) => element.name === "Thu");
      if (thu.length > 0) {
        let dailySum = thu.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.amt;
        }, 0);
        dayAmtObjsArr.push({ name: thu[0].name, amt: dailySum });
      } else {
        dayAmtObjsArr.push({ name: "Thu", amt: 0 });
      }
      const fri = dayAmts.filter((element) => element.name === "Fri");
      if (fri.length > 0) {
        let dailySum = fri.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.amt;
        }, 0);
        dayAmtObjsArr.push({ name: fri[0].name, amt: dailySum });
      } else {
        dayAmtObjsArr.push({ name: "Fri", amt: 0 });
      }
      const sat = dayAmts.filter((element) => element.name === "Sat");
      if (sat.length > 0) {
        let dailySum = sat.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.amt;
        }, 0);
        dayAmtObjsArr.push({ name: sat[0].name, amt: dailySum });
      } else {
        dayAmtObjsArr.push({ name: "Sat", amt: 0 });
      }

      if (dayAmtObjsArr.length === 7) {
        setReducedDayAmts(dayAmtObjsArr);
      }

      //-----------------------------------
    }
    //const res = data.map(obj => dayAmtObjsArr.find(o => o.name === obj.name) || obj);

    if (
      (currWeekAppointments.length !== 0 || reducedDayAmts.length === 7) &&
      JSON.stringify(reducedDayAmts) !== JSON.stringify(data)
    ) {
      //// console.log("5")
      //initDayAmtArr = []
      setData(reducedDayAmts);
      setLoading(false);
      //setReducedDayAmts([])
    }

    if (currWeekAppointments.length > 0 && loadingTips === null) {
      setLoadingTips(true);
    }

    if (loadingTips === true && currWeekAppointments.length !== 0) {
      setTips(
        currWeekAppointments
          .filter((apt) => apt.tip !== undefined)
          .reduce(function (accumulator, currentValue) {
            return accumulator === undefined
              ? 0 + parseFloat(currentValue.tip)
              : accumulator + parseFloat(currentValue.tip);
          }, 0)
          .toFixed(2)
      );
      setLoadingTips(false);
    }
    //if(JSON.stringify(res) !== JSON.stringify(data)){
    // initDayAmtArr =[]
    //    setData(res)
    //}else{
    // }

    // // console.log(data, reducedDayAmts, currWeekAppointments, loading)
    //// console.log(dayAmts)
  }, [
    days,
    weekDates,
    todayClicked,
    loading,
    currWeekAppointments,
    data,
    thisSunday,
    prevButtonClicked,
    nextButtonClicked,
    appointments,
    dayAmts,
    reducedDayAmts,
    isCurrent,
  ]);

  return (
    <Grid container item xs={12} className={classes.root}>
      <Grid container item xs={12} className={classes.navigation}>
        <ArrowForwardIosIcon
          className={classes.arrow}
          style={{ transform: "rotate(180deg)" }}
          onClick={() => setPrevClicked(true)}
        />
        <Grid>
          {/* {(current === dateRange) && 
                    <Typography className={classes.title}>Current Week</Typography>
                    } */}
          {weekDates.length === 8 && (
            <Typography className={classes.title}>
              {isCurrent === true ? null : (
                <span
                  className={classes.arrow}
                  style={{ marginRight: 4 }}
                  onClick={() => setTodayClicked(true)}
                >
                  Today
                </span>
              )}
              {startOfWeek + " - " + endOfWeek}
            </Typography>
          )}
          {currWeekAppointments.length <= 0 ? (
            <Typography className={classes.balance}>$0.00</Typography>
          ) : (
            <Typography className={classes.balance}>
              ${earnings.toFixed(2)}
            </Typography>
          )}
        </Grid>
        <ArrowForwardIosIcon
          className={classes.arrow}
          onClick={() => setNextClicked(true)}
        />
      </Grid>
      <Grid container item xs={12}>
        <ResponsiveContainer width="100%" height="100%">
          {currWeekAppointments.length > 0 ? (
            <BarChart
              width={100}
              height={40}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
            >
              <XAxis
                dataKey="name"
                scale="point"
                padding={{ left: 10, right: 10 }}
              />
              {/* <YAxis /> */}
              <Tooltip />
              <Bar dataKey="amt" fill="#413e68" />
            </BarChart>
          ) : (
            <BarChart
              width={100}
              height={40}
              data={[
                { name: days[0], amt: 0 },
                { name: days[1], amt: 0 },
                { name: days[2], amt: 0 },
                { name: days[3], amt: 0 },
                { name: days[4], amt: 0 },
                { name: days[5], amt: 0 },
                { name: days[6], amt: 0 },
              ]}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              barSize={20}
            >
              <XAxis
                dataKey="name"
                scale="point"
                padding={{ left: 10, right: 10 }}
              />
              {/* <YAxis /> */}
              <Tooltip />
              <Bar dataKey="amt" fill="#413e68" />
            </BarChart>
          )}
        </ResponsiveContainer>
      </Grid>
      <Grid container item xs={12} className={classes.infoWrapper}>
        <Grid>
          <Typography className={classes.title}>Appts</Typography>
          <Typography className={classes.info}>
            {currWeekAppointments.length}
          </Typography>
        </Grid>
        <Grid>
          <Typography className={classes.title}>Mileage</Typography>
          {currWeekAppointments.length <= 0 ? (
            <Typography className={classes.info}>$0.00</Typography>
          ) : (
            <Typography className={classes.info}>
              ${travelFee.toFixed(2)}
            </Typography>
          )}
        </Grid>
        <Grid>
          <Typography className={classes.title}>Tips</Typography>
          <Typography className={classes.info}>
            ${isNaN(tips) === true ? "0.00" : tips}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
