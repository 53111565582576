import React, {useRef, useState, useEffect, useCallback} from "react";
import {Typography, Container, Button, Accordion, AccordionSummary,
  ListItemText, Avatar, Grid, Slide, Paper, InputBase, Snackbar} from "@material-ui/core";
import {makeStyles} from '@material-ui/styles';
import AccordionDetail from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import firebase, {firestore} from '../../../config/fbConfig';
import { useHistory } from "react-router";
// import Slider from '@material-ui/core/Slider'
// import Cropper from 'react-easy-crop'
import {CopyToClipboard} from 'react-copy-to-clipboard';

var generator = require('generate-password');
// var randomKey = generator.generate({
// 	length: 18,
// 	numbers: true
// });


const useStyles = makeStyles((theme) => ({
  profileContainer:{
    position: 'relative',
    display:"flex",
    flexFlow:'column',
    alignItems:'center',
    padding: 10,
    marginBottom: 12,
  },
  avatar: {
    height: 140,
    width: 140,
  },
  imgSelecter:{
    position:'absolute',
    top: 120,
    left: '52%',
    border: '2px solid white',
    backgroundColor: theme.palette.primary.main,
    '&:hover':{
      cursor:'pointer',
      opacity: .8,
    },  
  },
  accordianDetails:{
    width:'100%',
    display:'flex',
    flexFlow:'column',
    textAlign: 'left'
  },
  accordianEditBtn:{
    alignSelf:'flex-end'
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    width:'100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
  input: {
    flex: 1,
    display: 'flex',
    height: 50,
    alignItems: 'center',
    '&:hover':{
        cursor:'pointer'
    },
  },
}));


export const Review = ({ formData, navigation, handleProspectModal }) => {
  // const history = useHistory();
  const classes = useStyles();
  console.log("FORM DATA:", formData)
  const [dbServices, setDBServices] = React.useState([])
  const [loading, setLoading] = React.useState([])
  const [randomKey, setRandomKey] = useState('')
  const value = 'www.cutiapp.com/claim-account/'+randomKey
  const [copied, setCopied] = useState(false)
  const [transition, setTransition] = useState(undefined);

  useEffect(() => {

    if(dbServices.length === 0 && loading===true){
        firestore.collection('services').doc('allServices').get()
        .then((doc) => {
            if (doc.exists) {
               setDBServices(doc.data().listOfServices);
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        setLoading(false)
      }

      if(randomKey === ""){
        setRandomKey(generator.generate({
          length: 18,
          numbers: true
        }))
      }
      
    console.log(randomKey)
    console.log(value)

  }, [dbServices, loading, randomKey, value])
  

  
  const { go } = navigation;
  const {
    avatar,
    firstName,
    lastName,
    displayName,
    title,
    bio,
    services,
    address,
    coordinates,
    phone,
    email,
    status,

  } = formData;


  const handleSubmit = async () => {
    // console.log(formData)
  
        firestore.collection('prospects').doc(randomKey).set({
          accountStatus: status,
          collection: 'prospects',
          createdAt: new Date(),
          coordinates: coordinates,
          url: randomKey,
          firstName: firstName.replace(/\s/g, ''),
          lastName: lastName.replace(/\s/g, ''),
          displayName: displayName,
          title: title === "" ? title : title.length > 1 ? title.join(' | ') : title[0],
          address: address,
          phone: phone,
          email: email,
          showDisplay: true,
          services: services,
          images: {avatar: avatar},
          lastUpdated: new Date(),
        }).then(()=> {
            if(services.length !== 0){
                services.forEach(service => {
                    if(dbServices.find(dbService => dbService.name.toLowerCase() === service.name.toLowerCase()) === undefined){
                        firestore.collection('services').doc('allServices').update({
                            listOfServices: firebase.firestore.FieldValue.arrayUnion({name: service.name, typeOfService: service.serviceType})
                        })
                    }       
                })
            }
        }).then(()=> {
            handleProspectModal()
        })

  }

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setCopied(true);
    setTimeout(() => {  setCopied(false); }, 2000);
  };

  return (
    <Container maxWidth="xs" style={{marginTop:'20px', textAlign: "center"}}>
      <Typography variant='h4'>Review</Typography>

      <Grid container item xs={12} className={classes.profileContainer}>
       
            <Avatar className={classes.avatar} src={avatar}>
            </Avatar>

      </Grid>

      <Grid item contianer xs={12}>
        <CopyToClipboard text={value} onCopy={handleClick(TransitionUp)}>
            <Paper component="form" className={classes.input} style={{margin:'30px 0px 10px', paddingLeft:'10px'}}>
            
                <InputBase aria-controls="simple-menu" className={classes.input} value={value}/>
                <Button color='primary'>copy</Button> 
            </Paper>
        </CopyToClipboard>

      </Grid>


      <RenderAccordion summary="Names" go={ go } details={[
        { 'First Name': firstName },
        { 'Last Name': lastName },
        { 'Display Name': displayName },
        { 'Title': title },
      ]} />
      <RenderAccordion summary="Account Info" go={ go } details={[
          { 'Email': email },
          { 'Phone': phone },
          { 'Address': address },
          { 'About': bio },
        ]} />
      <RenderAccordion summary="Services" go={ go } details={[
        { 'Services': services.length + "Service(s) added!" },

      ]} />

      <CopyToClipboard text={value} onCopy={handleClick(TransitionUp)}>
      <Button
        color="primary"
        variant="contained"
        style={{ backgroundColor: '#413e68',  
        backgroundSize: "400px",
        borderRadius: '0',
        color: 'white',
        fontWeight: 'bold',
        border: '3px #413e68 solid',
        letterSpacing: '2px',
        marginTop: '1.5rem' }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
      </CopyToClipboard>

      <Snackbar
          open={copied}
          // onClose={handleClose}
          TransitionComponent={transition}
          message="Copied to clipboard!"
          key={transition ? transition.name : ''}
      />

    </Container>
  );
};


export const RenderAccordion = ({ summary, details, go }) => (
  <Accordion>
    <AccordionSummary style={{fontSize:22}}
      expandIcon={<ExpandMoreIcon/>}
    >{summary}</AccordionSummary>
    <AccordionDetail>
      <div style={{width:'100%', display:'flex', flexFlow:'column', textAlign:'left'}}>
        { details.map((data, index) => {
          const objKey = Object.keys(data)[0];
          const objValue = data[Object.keys(data)[0]];

          return <ListItemText key={index}><span style={{fontWeight:'bold'}}>{objKey}: </span> {`${objValue}`}</ListItemText>

        }) }
        <IconButton style={{alignSelf:'flex-end'}}
          color="primary"
          component="span"
          onClick={() => go(`${summary.replace(/ /g,'').toLowerCase()}`)}
        ><EditIcon /></IconButton>
      </div>
    </AccordionDetail>
  </Accordion>
)