import React from 'react'
import {Container, Grid, Typography, CircularProgress, withWidth, Hidden, Button} from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';
import ReactPlayer from 'react-player/lazy'

const useStyles = makeStyles((theme) => ({
    infoContainer:{display:"flex",
      justifyContent:"center",
      padding: "10px",
      overflowY: "hidden scroll",
      borderTop: 'solid 1px #d3d3d3',
    },
    title:{
        width:'100%',
        textAlign:'center',
        fontWeight:'bold',
    },
    header:{

    },
    section:{
      borderRadius: 12,
      border:'1px solid lightgray',
      padding:10,
      marginBottom: 10,
      textDecoration:'none',
      color: 'black',
      "&:hover":{
        cursor:'pointer',
        border:`2px solid ${theme.palette.primary.main}`,
        backgroundColor:'#e6e6e6',
      },
    },
    link: {
      textDecoration:'none',
      color:'black',
      fontSize:24,
      '&:hover':{
          cursor:'pointer',
          color: theme.palette.primary.main
      }
    },
  }));

function AboutUs() {
    const classes = useStyles()
    const width = 'inherit'
    const height = width*0.5625
    // console.log('height:', height)
    return (
        <Grid item container xs={12} className={classes.infoContainer}>
            <Typography variant="h2" className={classes.title}>About Us</Typography>

            <Grid item container xs={12} md={6} style={{display:'flex', justifyContent:'center'}}>
            <Typography variant='h5' style={{fontWeight:'bold', marginTop:30, width:'100%', textAlign:'center'}}>Enjoy The Freedom</Typography>
              <ReactPlayer url='https://youtu.be/xVLHXKCB5-Y' width={width} playing={true} loop={true} volume={0.15} controls={true}/>

            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>
              Your Time Is Valuable
              </Typography>
            <Typography style={{width:'100%', textAlign:'center'}}>
              When asked what we all need more of, time is a pretty common answer.  Instead of sitting in traffic to make your appointment across town, allow our beauty professionals to come to you.
            </Typography>
            
            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>
              Quality & Comfort
              </Typography>
            <Typography style={{width:'100%', textAlign:'center'}}>
              When asked what we all need more of, time is a pretty common answer.  Instead of sitting in traffic to make your appointment across town, allow our beauty professionals to come to you.
            </Typography>
            
            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>
              Just Right For You
              </Typography>
            <Typography style={{width:'100%', textAlign:'center'}}>
              With Cuti, the process of finding a beauty professional that fits your needs is simple. Each beauty pro receives ratings from previous clients, helping you to understand their individual quality instead of the general overall opinion clients have of one shop. 
            </Typography>

            <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:50, marginBottom:80, textAlign:'center'}}onClick={() => window.location.assign('/signup')}>Get Started</Button>
            
            
            </Grid>
        </Grid>
    )
}

export default AboutUs
