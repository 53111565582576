import React, {useState, useEffect} from "react";
import {Typography, Grid, ClickAwayListener, TextField, Button, Tooltip} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {MiuPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    timeSlotRoot: {
        padding: 10,
        borderRadius:10,
        width:'90%',
        marginBottom:5,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        '&:hover':{
            cursor: 'pointer',
            padding:9,
            width: 90,
        },
    },

  }));

function TimeSlotCard(props) {
    const {time, setSelectedDate, pushBack} = props
    const classes = useStyles()
    const [selected, setSelected] = useState(false)

    const bgClr = selected === true ? '#413e68' : 'lightgray'
    const color = selected === true ? 'white' : 'black'

    const handleSelected = () => {
        setSelected(true)
        setSelectedDate(time)
    }
    
    return (
        <ClickAwayListener onClickAway={()=> setSelected(false)}>
            <Grid item container className={classes.timeSlotRoot} onClick={handleSelected} style={{backgroundColor:bgClr}}>
                {pushBack ? 
                <Typography style={{color:color, fontSize:13}}>
                    {time.start}
                </Typography>
                :
                <Typography style={{color:color, fontSize:13}}>
                    {time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                </Typography>
                }
            </Grid>
        </ClickAwayListener>
    )
}

export default TimeSlotCard
