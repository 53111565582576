import 'date-fns';
import React, {useState, useEffect} from "react";
import {Typography, Grid, Container, TextField, Button, Tooltip} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {MiuPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeSlotCard from '../BookAppointmentModal/TimeSlotCard';
import { firestore } from '../../../config/fbConfig';
import {useSelector} from 'react-redux'

const availability = require('timeslot-availability');




const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 12, 
      border:'1px solid #e0e0e0',
      position: 'relative',
      marginTop:'20px', // -10% for mobile
      minHeight: 510,
      boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)',
      textAlign: 'left'
    },
    titleWrapper:{
      borderRadius: '8px 8px 0 0',
      background: theme.palette.primary.main,
      height: "50px"
    },
    title: {
      color: theme.palette.primary.main,
      padding: "8px 0 0 20px",
      fontWeight:'bold',
    },
    question: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))"
    },
    sAPWrapper: {
      position: 'relative',
      height:30
    },
    sAPBtn: {
      position: 'absolute',
      top: 12,
      right: 12
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    dateTimePicker: {
      width:'100%'
    },
    iconButton: {
     color: theme.palette.primary.main,
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      //border: `3px ${theme.palette.primary.main} solid`,
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160,
    },

  }));

export const TimeSelect = ({ formData, setForm, navigation, userData, appt, handleModalOpen}) => {
  const  {service, dateTime} = formData
  const scheduledAppointment = useSelector(state => state.appt.appointments)
  const [pro, setPro] = useState(null);
  const [value, setValue] = useState(service);
  const [pushBack, setPushBack] = useState(null);
  const [changeDate, setChangeDate] = useState(false);
  const [date, onChange] = useState(dateTime);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null)
  const [loading, setLoading] = useState(false);
  const [start, setAvailabilityStart] = useState(null)
  const [end, setAvailabilityEnd] = useState(null) // + 8 hrs
  const [start2, setAvailabilityStart2] = useState(null)
  const [end2, setAvailabilityEnd2] = useState(null) // + 8 hrs
  const [start3, setAvailabilityStart3] = useState(null)
  const [end3, setAvailabilityEnd3] = useState(null) // + 8 hrs
  const [appointments, setAppointments] = useState([])
  const classes = useStyles();
  const disabled = (service === '' || selectedDate === null) ? true : false
  const disabled2 = (pushBack === true || changeDate === true) ? true : false
  const backgroundColor = (pushBack === true || changeDate === true) ? '#413e68' : null
  const color = (pushBack === true || changeDate === true) ? 'white' : null
  const variant = (pushBack === true || changeDate === true) ? 'contained' : 'outlined'

  const timespan = 1800; //time in seconds + 30 min buffer

  const bookable = (start!==null && end!==null ) && availability(start, end, timespan, appointments)
  const bookable2 = (start2!==null && end2!==null ) && availability(start2, end2, timespan, appointments)
  const bookable3 = (start3!==null && end3!==null ) && availability(start3, end3, timespan, appointments)

  const pushTimes = [{start: "15 mins", amtOfTime:900000},
                     {start: "30 mins", amtOfTime:1800000},
                     {start: "45 mins", amtOfTime:2700000},
                     {start: "60 mins", amtOfTime:3600000},
]
  
  useEffect(() => {

    formData.service = appt.docData.service
    formData.apptId = appt.id
    formData.serviceCost = appt.docData.serviceFee
    formData.dateTime = selectedDate

    formData.client = {name: appt.docData.clientName, avatar: appt.docData.clientAvatar,
    email: appt.docData.clientEmail, userId: appt.docData.clientUID, accountStatus: appt.docData.clientStatus}
    
    formData.pro = {name: appt.docData.proName , address:appt.docData.serviceLocation,
    email: appt.docData.proEmail, userId: appt.docData.proUID, avatar: appt.docData.proAvatar}

    formData.serviceTimeEnd = appt.docData.serviceTimeEnd

    if(pushBack === true && date===null){
      // console.log('pushing back')
      onChange(new Date())
      setLoading(true)
      setSelectedDate(new Date(appt.docData.serviceTimeReq*1000).toISOString())
    //   // console.log(appt.docData.serviceTimeReq)

    }

    if(changeDate===true && userData.collection==='professionals' && formData.dateTime !== null && formData.dateTime < new Date(new Date().getTime() + 7140000)){
      //formData.dateTime = new Date(`${selectedDate}`)
      // console.log('1')
      setLoading(true)
      setSelectedDate('')
      formData.dateTime = null
    }

    if(changeDate===true && userData.collection==='customers' && pro===null){
      firestore.collection('professionals').doc(`${appt.docData.proUID}`)
            .get().then((doc) => {
                const pro = doc.data()
                setPro(pro)
              }).then(()=> {
                setLoading(true)
              })
      setSelectedDate('')
      formData.dateTime = null
    }

    if(date !== null && loading===true){
      setAvailabilityStart(null)
      setAvailabilityEnd(null)
      setAvailabilityStart2(null)
      setAvailabilityEnd2(null)
      setAvailabilityStart3(null)
      setAvailabilityEnd3(null)
      if(userData.collection === 'professionals'){
        // setLoading(false)
          userData.availability.map(dayAvailable => {
            if((dayAvailable.day === date.toString().split(' ')[0].toLowerCase()) && dayAvailable.slots.length > 0){
              const startTime = new Date(date).toString().split(' ')
              const startTime2 = new Date(date).toString().split(' ')
              const startTime3 = new Date(date).toString().split(' ')
    
              const endTime = new Date(date).toString().split(' ')
              const endTime2 = new Date(date).toString().split(' ')
              const endTime3 = new Date(date).toString().split(' ')
              // console.log('start times', startTime, endTime)
              
              if(dayAvailable.slots.length === 1){
                startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
                endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
                setAvailabilityStart(startTime.join(' '))
                setAvailabilityEnd(endTime.join(' '))
              }
              if(dayAvailable.slots.length === 2){   
                startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
                endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
                startTime2.splice(4,1,`${dayAvailable.slots[1].from}`)
                endTime2.splice(4,1,`${dayAvailable.slots[1].to}`)
          
                setAvailabilityStart2(startTime2.join(' '))
                setAvailabilityEnd2(endTime2.join(' '))
                setAvailabilityStart(startTime.join(' '))
                setAvailabilityEnd(endTime.join(' '))
                
              }
              if(dayAvailable.slots.length === 3){
                startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
                endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
                startTime2.splice(4,1,`${dayAvailable.slots[1].from}`)
                endTime2.splice(4,1,`${dayAvailable.slots[1].to}`)
                startTime3.splice(4,1,`${dayAvailable.slots[2].from}`)
                endTime3.splice(4,1,`${dayAvailable.slots[2].to}`)
                setAvailabilityStart(startTime.join(' '))
                setAvailabilityEnd(endTime.join(' '))
                setAvailabilityStart2(startTime2.join(' '))
                setAvailabilityEnd2(endTime2.join(' '))
                setAvailabilityStart3(startTime3.join(' '))
                setAvailabilityEnd3(endTime3.join(' '))
              }        
            }
            else if((dayAvailable.day === date.toString().split(' ')[0].toLowerCase()) && dayAvailable.slots.length === 0){
              // console.log('Not Avaialable')
            }
            //const formattedTime = 
          })
        }
        else if(userData.collection === 'customers'){
              pro.availability.map(dayAvailable => {
                  if((dayAvailable.day === date.toString().split(' ')[0].toLowerCase()) && dayAvailable.slots.length > 0){
                    const startTime = new Date(date).toString().split(' ')
                    const startTime2 = new Date(date).toString().split(' ')
                    const startTime3 = new Date(date).toString().split(' ')
          
                    const endTime = new Date(date).toString().split(' ')
                    const endTime2 = new Date(date).toString().split(' ')
                    const endTime3 = new Date(date).toString().split(' ')
                    // console.log('start times', startTime, endTime)
                    
                    if(dayAvailable.slots.length === 1){
                      startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
                      endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
                      setAvailabilityStart(startTime.join(' '))
                      setAvailabilityEnd(endTime.join(' '))
                    }
                    if(dayAvailable.slots.length === 2){   
                      startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
                      endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
                      startTime2.splice(4,1,`${dayAvailable.slots[1].from}`)
                      endTime2.splice(4,1,`${dayAvailable.slots[1].to}`)
                
                      setAvailabilityStart2(startTime2.join(' '))
                      setAvailabilityEnd2(endTime2.join(' '))
                      setAvailabilityStart(startTime.join(' '))
                      setAvailabilityEnd(endTime.join(' '))
                      
                    }
                    if(dayAvailable.slots.length === 3){
                      startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
                      endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
                      startTime2.splice(4,1,`${dayAvailable.slots[1].from}`)
                      endTime2.splice(4,1,`${dayAvailable.slots[1].to}`)
                      startTime3.splice(4,1,`${dayAvailable.slots[2].from}`)
                      endTime3.splice(4,1,`${dayAvailable.slots[2].to}`)
                      setAvailabilityStart(startTime.join(' '))
                      setAvailabilityEnd(endTime.join(' '))
                      setAvailabilityStart2(startTime2.join(' '))
                      setAvailabilityEnd2(endTime2.join(' '))
                      setAvailabilityStart3(startTime3.join(' '))
                      setAvailabilityEnd3(endTime3.join(' '))
                    }        
                  }
                  else if((dayAvailable.day === date.toString().split(' ')[0].toLowerCase()) && dayAvailable.slots.length === 0){
                    // console.log('Not Avaialable')
                  }
                  //const formattedTime = 
                })    
                
        }
      // console.log('step 2')
    } 
    
    if(userData.collection ==='professionals' && appointments.length === 0 && scheduledAppointment){
        const startBuffer = appt.docData.serviceTimeEnd.seconds - appt.docData.serviceTimeReq.seconds + 1800
        // console.log('START BUFFER:', startBuffer)
          setAppointments(scheduledAppointment.map(doc => {
            const data = doc.docData
            return({
                start: new Date((data.serviceTimeReq.seconds - startBuffer) * 1000).toISOString(), 
                end: new Date((data.serviceTimeEnd.seconds + 1800) * 1000).toISOString()}
                )}
            ))
     
    }

    if(userData.collection ==='customers' && appointments.length === 0){
        const startBuffer = appt.docData.serviceTimeEnd.seconds - appt.docData.serviceTimeReq.seconds + 1800
        // console.log('START BUFFER:', startBuffer)
        firestore.collection('appointments').where('proUID', '==', `${appt.docData.proUID}`)
        .where('serviceComplete', '==', false).where('serviceCanceled', '==', false)
        .get().then((querySnapshot) => {
            setAppointments(querySnapshot.docs.map(doc => {
              const data = doc.data()
              return({
                start: new Date((data.serviceTimeReq.seconds - startBuffer) * 1000).toISOString(),
                end: new Date((data.serviceTimeEnd.seconds + 1800) * 1000).toISOString()})}))
        })
        .catch((error) => {
            // console.log("Error getting documents: ", error);
        });    
    }

    

    


    // console.log(formData)

    if(selectedTime){
        formData.selectedTime = new Date((appt.docData.serviceTimeReq.seconds*1000) + selectedTime.amtOfTime)
        formData.amtOfTime = selectedTime
        
        firestore.collection('appointments').where('proUID', '==', `${userData.userId}`)
        .where('serviceComplete', '==', false).where('serviceCanceled', '==', false)
        .get().then((querySnapshot) => {
            formData.apptsToPush = querySnapshot.docs.map((doc) => {
                const apptToPush = {id: doc.id, data: doc.data()}
                const {data} = apptToPush               
                if((new Date(data.serviceTimeReq.seconds*1000).toLocaleDateString() === new Date(appt.docData.serviceTimeReq.seconds*1000).toLocaleDateString())
                && (new Date(data.serviceTimeReq.seconds*1000).getTime() > new Date(appt.docData.serviceTimeReq.seconds*1000).getTime())){
                    return apptToPush
                }  
            }).filter(data => data !== undefined)
        })
        .catch((error) => {
            // console.log("Error getting documents: ", error);
        });
        

    }

    // if(bookable!== false && bookable.length > 0 && window.innerWidth < 960){
    //   var myElement = document.getElementById('calendar');
    //   myElement.scrollTo({top: 250, behavior: 'smooth'})
    //   // console.log(window.innerWidth)
    //   // console.log("Bookable:",bookable)
    // }

    // console.log('formData.dateTime', formData.dateTime)
    // // console.log('FD.selectedTime', formData.selectedTime)
    // // console.log('SelectedDate', selectedDate)
    // console.log("start:", start, "end:", end)
    // console.log(timespan)
    // console.log(bookable)
    // console.log(date)
 

  }, [scheduledAppointment, pro, selectedTime, selectedDate, formData, pushBack, changeDate, appt, userData, date, bookable, bookable2, bookable3, service, loading, start, start2, start3, end, end2, end3, appointments, timespan, dateTime])

  const handleServiceChange = (event, newValue) => {
    setValue(newValue);
    formData.service = newValue
  }
  
  const handleDateChange = () => {
      setLoading(false)

  };

  const handleNow = () => {
      const amtInMins = ( new Date(appt.docData.serviceTimeReq.seconds*1000).getTime() - new Date().getTime())/60
    if(amtInMins >= 60){
        // // console.log('You can Reschedule!!!')
        setSelectedDate(new Date(new Date().getTime() + 900000))
        setPushBack(true)
        setChangeDate(false)
    }else{
        alert('You must give at least 1 hour notice in order to push this appointment back.')
    }
  }

  const handleLater = () => {
    setPushBack(false)
    setChangeDate(true)
    if(userData.collection === 'professionals'){
      setLoading(true)
    }
    // formData.dateTime = null
    // setSelectedDate('')
  }

  const handleCancel = () => {
    handleModalOpen()
    setSelectedDate(null)
    setPro(null)
    formData={
        service: "",
        serviceCost: "",
        dateTime: null,
        address: "",
        havePets: '',
        petTypes: "",
        notes: "",
        client: null,
        pro: null,
        bookingFee: "",
        travelFee: "",
    }
  }

  return (
    <Grid item xs={11} sm={10} md={8} lg={6} xl={5} container style={{outline:'none', height:600, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
      <Grid item xs={12}>
        <Typography variant='h5' className={classes.title}>Reschedule</Typography>
      </Grid>
      
      <Grid container item xs={12} style={{ padding:'10px 20px', textAlign: 'center'}}>
        <Typography style={{fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))", fontWeight:'bold', width:'inherit'}}>Original Time: {new Date(appt.docData.serviceTimeReq.seconds*1000).toLocaleString()}</Typography>
       
      </Grid>

      <Grid item xs={12} style={{padding:'10px 20px'}}>
          <Typography className={classes.question}>1. When are you available?</Typography>
      </Grid>
      
      <Grid container item xs={12} style={{ padding:'10px 20px', justifyContent: 'space-around', overflowY:'scroll', height:'320px'}}> 

        
            <Grid item xs={12} container justify="space-around">

                {(changeDate !== true && userData.collection === 'professionals') &&
                <Grid item xs={12} sm={5}>
                <Button fullWidth variant={variant} color='primary' disabled={disabled2} style={{backgroundColor:backgroundColor, color:color}} onClick={handleNow}>Push It Back</Button>
                </Grid>
                }
                {pushBack !== true &&
                <Grid item xs={12} sm={5}>
                <Button fullWidth variant={variant} color='primary' disabled={disabled2} style={{backgroundColor:backgroundColor, color:color}} onClick={handleLater}>Change Day/Time</Button>
                </Grid>
                }

            </Grid>

            {(pushBack && pushTimes && pushTimes.length !== 0)  &&
                <Grid item xs={4} md={2} style={{marginTop:10, display:'flex', flexFlow:'column', alignItems:'center'}}>
                {pushTimes.map(timeslot => {
                  const time = timeslot
                  return(
                    <TimeSlotCard time={time} setSelectedDate={setSelectedTime} pushBack={pushBack}/>
                  )
                })
                }
              </Grid>
            }

            {changeDate &&
                <Grid item xs={12} md={6} style={{marginTop:10}}>
                  <Calendar
                    minDate={new Date()}
                    onChange={onChange}
                    value={date}
                  />
                </Grid>
            }
  
            {(changeDate && bookable && bookable.length !== 0)  &&
                <Grid item xs={4} md={2} style={{marginTop:10, display:'flex', flexFlow:'column', alignItems:'center'}}>
                  {bookable.map(timeslot => {
                    const time = timeslot.start
                    return(
                      <TimeSlotCard time={time} setSelectedDate={setSelectedDate}/>
                    )
                  })
                  }
                </Grid>
            }

            {(changeDate && bookable2 && bookable2.length !== 0)  &&
                <Grid item xs={4} md={2} style={{marginTop:10, display:'flex', flexFlow:'column', alignItems:'center'}}>
                  {bookable2.map(timeslot => {
                    const time = timeslot.start
                    return(
                      <TimeSlotCard time={time} setSelectedDate={setSelectedDate}/>
                    )
                  })
                  }
                </Grid>
            }
            {(changeDate && bookable3 && bookable3.length !== 0)  &&
                <Grid item xs={4} md={2} style={{marginTop:10, display:'flex', flexFlow:'column', alignItems:'center'}}>
                  {bookable3.map(timeslot => {
                    const time = timeslot.start
                    return(
                      <TimeSlotCard time={time} setSelectedDate={setSelectedDate}/>
                    )
                  })
                  }
                </Grid>
            }

            
       


        

      </Grid>

      <Grid
        container
        item
        xs={6}
        style={{ padding: "0px 10px 36px 10px", justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          style={{ backgroundColor: "white", color: "#413e68" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Grid>

      <Grid
        container
        item
        xs={6}
        style={{ padding: "0px 10px 36px 10px", justifyContent: "left" }}
      >

        <Tooltip title="Please select a Time." disableHoverListener={!disabled}>
            <Button
                disabled={disabled}
                variant="contained"
                className={classes.btn}
                color='primary'
                onClick={() => navigation.next()}>
                Next
            </Button>
        </Tooltip> 
      </Grid>
    </Grid>
  );
};