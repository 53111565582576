import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Typography, Grid, Button, Tooltip, FormControl, InputLabel, Select} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SelectProCard from '../../../../components/appointments/SelectProCard'
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import {firestore} from '../../../../config/fbConfig'

//import FilterListIcon from '@material-ui/icons/FilterList';
//import { distance } from 'mathjs'
import haversine from "haversine-distance"

const { ms, s, m, h, d } = require('time-convert')

const useStyles = makeStyles((theme) => ({
  root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: "inherit",
      height: '60px',
      boxShadow: 'none',
      border: '1px solid lightgray',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
     color: '#413e68',
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160
    },
    track:{
      backgroundColor: 'blue'
    },
    
  }));
  
  
  const SelectAPro = ({ formData, navigation, userData, width }) => {
    
    //const { pro } = formData;
    const classes = useStyles();
    const [loading, setLoading] =React.useState(false)
    const [loading2, setLoading2] =React.useState(false)
    const [activePros, setActivePros] = React.useState([])
    const [serviceMatches, setServiceMatches] =React.useState([])
    const [nearbyPros, setNearbyPros] =React.useState([])
    const [availablePros, setAvailablePros] =React.useState([])
    const [sortBy, setSortBy] =React.useState('')
    const [sortByy, setSortByy] =React.useState('')
    const state = formData.address.split(', ').slice(-2, -1)[0]
    const margin = (width==='sm' || width==='xs')? null : '0px 20px';
    const padding = (width==='sm' || width==='xs')? null : '0px 15px 5px 5px';

    const handleChange = (event) => {
      const name = event.target.value;
      // console.log(name)
      setSortBy(name)
      setSortByy(name)
    };
    
    
    useEffect(()=> {
    let dbPros = []
    let prosInRadius = []
    let prosWithNoUpcomingAppts = []
    let prosWithNoApptsOnReqDate = []
    let prosWithNoScheduleConflictsWhoHaveApptsOnServReqDate = []
    let unique1 = []
    let unique2 = []
    let unique3 = []
    let allAvaialablePros = []
    var prosRef = firestore.collection("professionals");
    var query = prosRef.where("accountStatus", "==", 'active').where("state", "==", `${state}`).where('travel', '==', true).where('test', '==', false);
    var onlineQuery = prosRef.where("accountStatus", "==", 'active').where('online', '==', true).where('busy', '==', false).where("state", "==", `${state}`).where('travel', '==', true).where('test', '==', false);
    var queryNoTravel = prosRef.where("accountStatus", "==", 'active').where("state", "==", `${state}`).where('test', '==', false);
    var onlineQueryNoTravel = prosRef.where("accountStatus", "==", 'active').where('online', '==', true).where('busy', '==', false).where("state", "==", `${state}`).where('test', '==', false);
    var queryTest = prosRef.where("accountStatus", "==", 'active').where("state", "==", `${state}`).where('travel', '==', true).where('test','==',true);
    var onlineQueryTest = prosRef.where("accountStatus", "==", 'active').where('online', '==', true).where('busy', '==', false).where("state", "==", `${state}`).where('travel', '==', true).where('test','==',true);
    var queryNoTravelTest = prosRef.where("accountStatus", "==", 'active').where("state", "==", `${state}`).where('test','==',true);
    var onlineQueryNoTravelTest = prosRef.where("accountStatus", "==", 'active').where('online', '==', true).where('busy', '==', false).where("state", "==", `${state}`).where('test','==',true);
    
    if(formData.when === 'now'){
      if(formData.location !== 'pro location'){
        if(activePros.length <= 0 && loading===false){
           //// console.log("1")
           if(userData && userData.test===false){
             onlineQuery.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                dbPros.push(doc.data())
              })
              setActivePros(dbPros)
              setLoading(true)
            })
           }else if(userData && userData.test===true){
            onlineQueryTest.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                dbPros.push(doc.data())
              })
              setActivePros(dbPros)
              setLoading(true)
            })
           }
        }
      }else if(formData.location === 'pro location'){
        if(activePros.length <= 0 && loading===false){
          //// console.log("1")
          if(userData && userData.test===false){
            onlineQueryNoTravel.get().then((querySnapshot) => {
             querySnapshot.forEach((doc) => {
               console.log("PRO:", doc.data())
               dbPros.push(doc.data())
             })
             setActivePros(dbPros)
             setLoading(true)
           })
          }else if(userData && userData.test===true){
            onlineQueryNoTravelTest.get().then((querySnapshot) => {
             querySnapshot.forEach((doc) => {
               console.log("PRO:", doc.data())
               dbPros.push(doc.data())
             })
             setActivePros(dbPros)
             setLoading(true)
           })
          }
      }
    }
    }else if(formData.when === 'later'){
      if(formData.location !== 'pro location'){
        if(activePros.length <= 0 && loading===false){
          // console.log("1")
          if(userData && userData.test===false){
            query.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                dbPros.push(doc.data())
              })
              setActivePros(dbPros)
              setLoading(true)
            })
          }else if(userData && userData.test===true){
            queryTest.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                dbPros.push(doc.data())
              })
              setActivePros(dbPros)
              setLoading(true)
            })
          }
        }
      }else if(formData.location === 'pro location'){
        if(activePros.length <= 0 && loading===false){
          // console.log("1")
          if(userData && userData.test===false){
            queryNoTravel.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                dbPros.push(doc.data())
              })
              setActivePros(dbPros)
              setLoading(true)
            })         
          }else if(userData && userData.test===true){
            queryNoTravelTest.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                dbPros.push(doc.data())
              })
              setActivePros(dbPros)
              setLoading(true)
            })
          }
        }
      }
    }
    
    if(activePros.length !== 0 && loading === true && serviceMatches.length === 0){
       // console.log('2', activePros.length)
      
      const result = activePros.filter(pro =>{
        if(pro.services.some(s => s.name.toLowerCase().includes(formData.service.toLowerCase()))){
          return pro
          }
        });
      setServiceMatches(result)
      setLoading(false)
            
    }

    if((serviceMatches.length !== 0) && (loading === false) && (loading2 === false)){
       // console.log('3')
       if(formData.location !== 'pro location'){
         serviceMatches.map(pro => {
           var haversine_m = haversine(formData.startingCoordinates, pro.coordinates)
           var distance = (haversine_m / 1609.344)
           if(distance <= 15){
             prosInRadius.push(pro)
           }
            // console.log('4')
           setNearbyPros(prosInRadius)
           setLoading2(true)
         })
       }else if(formData.location === 'pro location'){
        serviceMatches.map(pro => {
          var haversine_m = haversine(formData.startingCoordinates, pro.coordinates)
          var distance = (haversine_m / 1609.344)
          console.log(distance)
          if(distance <= 30){
            prosInRadius.push(pro)
          }
           console.log('4')
          setNearbyPros(prosInRadius)
          setLoading2(true)
        })
       }

    }

    if(nearbyPros.length !== 0 && !!loading2){
       // console.log('5')
      nearbyPros.forEach(pro => {
        pro.availability.map(daysAvailable => {
  
//Step 6: Check if any pro provides services on the requested day
          if(daysAvailable.day === new Date(formData.dateTime).toString().split(' ')[0].toLowerCase()){
             // console.log('6')
            daysAvailable.slots.map(slot => {
              
              const formattedTime = new Date(formData.dateTime).toString().split(' ')[4].split(':').slice(0,2).join(':') //format -> 00:00

              //set a 30 min buffer on the availability ending slot time (slot.to)
              var MS_PER_MINUTE = 60000;
              var durationInMinutes = 30
              const dateTo = new Date(formData.dateTime).toString().split(' ')
              dateTo.splice(4,1,`${slot.to}:00`).join(' ')
              const bufferDateTo = dateTo.join(' ')
              const dateToInMilliseconds = new Date(bufferDateTo).getTime();
              var slotToWithbuffer = new Date(dateToInMilliseconds - (durationInMinutes * MS_PER_MINUTE));

//Step 7: Check if the time of service is within the slot ranges of selected day
              if((formattedTime >= slot.from) && (new Date(formData.dateTime).getTime() <= new Date(slotToWithbuffer).getTime())){
                 // console.log("6.a: This service is within ",pro.firstName+" "+pro.lastName+'s', " Availability!")

                nearbyPros.forEach(pro => {
                  if(pro.apptsScheduled <= 0){
                    prosWithNoUpcomingAppts.push(pro)
                    if(prosWithNoUpcomingAppts.length !== 0){
                      setAvailablePros(prosWithNoUpcomingAppts)
                    }
                  }else{
                     // console.log(`${pro.firstName} has ${pro.apptsScheduled} appointment(s) scheduled!`)
//Step 8: query against each professionals' upcoming appointments to begin search of any conficting times
                                    firestore.collection("appointments").where("proUID", "==", pro.userId).where("serviceComplete", '==', false)
                                    .where("serviceCanceled", "==", false)
                                    .get()
                                    .then((querySnapshot) => {
                                      const serviceDate = new Date(formData.dateTime).toString().split(' ').slice(0,4) //ex. 'Mon Dec 01 2021'
                                        querySnapshot.forEach((doc) => {
                                          if(!doc.exists){
                                            
                                            // console.log('8.a: ', pro.firstName, 'DOES NOT have any upcoming appointments at all...')
                                            prosWithNoUpcomingAppts.push(pro)
                    
                                          }else{
                                             // console.log('8.b:', pro.firstName, ' ServiceTimeReq: ',doc.data().serviceTimeReq.seconds*1000, ' serviceDate: ', serviceDate )
                    
                                            if(JSON.stringify((new Date(doc.data().serviceTimeReq.seconds*1000)).toDateString().split(' ')) === JSON.stringify(serviceDate)){
                                               // console.log('8.c' , pro.firstName, 'HAS an upcoming scheduled appointment on this day')
//Step 9: Get the serviceLengthInMins + 30 min buffer => convert to milliseconds and add to serviceTimeReq / This will be used to add a buffer end
                      
//Step 10: Get the timeLength of pro service + 30 min buffer / The will be the used to set a buffer begining
                                              pro.services.filter(service => {
                                                if(service.name.toLowerCase() === formData.service.toLowerCase()){
                                                  const t = (m.to(ms)(service.time))
                                                   //// console.log(service.time)
                                                   //// console.log(t)
                                                  const serviceLengthAndBuffer = (service.time*60000) + (durationInMinutes * MS_PER_MINUTE) 
                                                   //// console.log(serviceLengthAndBuffer)
                                                  const datewLengthOfServiceAndBuffer = new Date((new Date(formData.dateTime).getTime() + serviceLengthAndBuffer)).getTime() 
                                                   //// console.log(datewLengthOfServiceAndBuffer)
                                                   //// console.log(new Date(datewLengthOfServiceAndBuffer).getTime())
                                                   //// console.log(new Date(doc.data().serviceTimeReq.seconds*1000).getTime())
                                                   //// console.log(new Date ((doc.data().serviceTimeEnd.seconds * 1000) + (durationInMinutes * MS_PER_MINUTE)).getTime())
                                                  if(
                                                    (new Date(datewLengthOfServiceAndBuffer).getTime() <= new Date(doc.data().serviceTimeReq.seconds*1000).getTime())
                                                  || (new Date ((doc.data().serviceTimeEnd.seconds * 1000).getTime() + (durationInMinutes * MS_PER_MINUTE)).getTime() <= new Date(formData.dateTime).getTime()))
                                                  {
                                                     // console.log('9:', pro.firstName, 'has no conflicts and can perform this service!')
                                                    prosWithNoScheduleConflictsWhoHaveApptsOnServReqDate.push(pro)
                                                  }
                                                }
                                              });
                                            }else{
                                               // console.log('8.c.alt', pro.firstName, 'DOES NOT upcoming scheduled appointment on this day')
                                              prosWithNoApptsOnReqDate.push(pro)
                                            }
                                            allAvaialablePros.concat(prosWithNoUpcomingAppts, prosWithNoApptsOnReqDate, prosWithNoScheduleConflictsWhoHaveApptsOnServReqDate)
                                             // console.log('10', prosWithNoUpcomingAppts, prosWithNoApptsOnReqDate, prosWithNoScheduleConflictsWhoHaveApptsOnServReqDate )

                                          }
                                        });
                                      }).then(() => {
                                        unique1 = [...new Set(prosWithNoUpcomingAppts)]
                                        unique2 = [...new Set(prosWithNoApptsOnReqDate)]
                                        unique3 = [...new Set(prosWithNoScheduleConflictsWhoHaveApptsOnServReqDate)]
                                        //let allUnique = unique1.concat(unique2, unique3)
                                        //let allUnique = [...new Set(unique1.concat(unique2, unique3))]
                                        //// console.log(allUnique)
                                      }).then(()=>{
                                      // // console.log('10')
                                      // console.log('10', '1',unique1, '2',unique2, '3',unique3 )
                                      setAvailablePros([... new Set(allAvaialablePros.concat(unique1, unique2, unique3))])
                                    })
                                    .catch((error) => {    
                                         // console.log("Error getting documents: ", error);
                                    });

                  }
                  
                })
                
              }
            })
          }
          setLoading(null)
          setLoading2(false)
        })
        
      })
    }


    switch (sortBy) {
      case "Distance":
        setAvailablePros(availablePros.sort((a,b) =>  haversine(formData.startingCoordinates, a.coordinates) > haversine(formData.startingCoordinates, b.coordinates) && 1 || -1))
        setSortBy('')
        break;
      case "Name":
        setAvailablePros(availablePros.sort((a,b) => a.fisrtName < b.fisrtName && 1 || -1))
        setSortBy('')
          break;
      case "Rating":
        setAvailablePros(availablePros.sort((a,b) =>  (a.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / a.ratings.length).toFixed(2) < (b.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / b.ratings.length).toFixed(2) && 1 || -1))
        setSortBy('')
          break;
      case "Price":
        setAvailablePros(availablePros.sort((a,b) =>  {
          const serviceA = a.services.filter(serv => serv.name.toLowerCase() === formData.service.toLowerCase())
          const serviceB = b.services.filter(serv => serv.name.toLowerCase() === formData.service.toLowerCase())
          const priceA = parseFloat(serviceA.shift().price)
          const priceB = parseFloat(serviceB.shift().price)
          if (priceA < priceB) //sort string ascending
            return -1;
          if (priceA > priceB)
            return 1;
          return 0;
        })
        )
        setSortBy('')
          break;
  
      default:
        setAvailablePros(availablePros.sort((a,b) => new Date(b.serviceTimeReq) - new Date(a.serviceTimeReq)))
          break;
  }

    console.log(state)
    // console.log(serviceMatches)
    // console.log(availablePros)
    console.log('Pros within radius:', nearbyPros)
     ////// console.log('available pros:', availablePros)


   },[formData, loading, loading2, activePros, serviceMatches, nearbyPros, sortBy, availablePros, state, ])

  //  const orderByPrice = () => {
  //   var serviceInfo = nearbyPros.map(pro => 
  //     pro.services.filter(service => service.name.toLowerCase() === formData.service.toLowerCase())
  //     ).flat()
  //      ////// console.log(serviceInfo)
  //     serviceInfo.sort((a,b) => parseFloat(b.price) - parseFloat(a.price))
  //  }

  const displayPros = availablePros && availablePros.map((pro,idx) => {
    const haversine_m = haversine(formData.startingCoordinates, pro.coordinates)
    const distance = (haversine_m / 1609.344).toFixed(2)
    const rating = (pro.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / pro.ratings.length).toFixed(2)
    return(
      <SelectProCard
        userData={userData}
        key={idx}
        pro={pro}
        distance={distance}
        rating={rating}
        service={pro.services.filter(service => service.name.toLowerCase() === formData.service.toLowerCase()).pop()}
        formData={formData}
      />
    )
  }) 


  
  return (
    <Grid container item xs={12} style={{borderRadius: 12, border:'1px solid #e0e0e0', position: 'relative', marginTop:'20px', height: 510, boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)', textAlign: 'left'}}>
      <Grid item xs={12} style={{borderRadius: '8px 8px 0 0', background: '#413e68', height: "50px"}}>
        <Typography variant='h6' style={{color:"white", padding: "8px 0 0 20px"}}>Schedule A Service</Typography>
        
      </Grid>

      <Grid container item xs={12} style={{padding:'10px 20px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <Typography variant='h6' style={{color:"#413e68"}}>5. Select A Pro.</Typography>
        {availablePros.length !== 0 && 
          <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Order by</InputLabel>
              <Select
              native
              value={sortByy}
              onChange={handleChange}
              label="Sort"
              inputProps={{
                  name: 'sortBy',
                  id: 'outlined-age-native-simple',
              }}
              >
              <option aria-label="None" value="" />
              <option value={'Name'}>Name</option>
              <option value={'Price'}>Price</option>
              <option value={'Rating'}>Rating</option>
              <option value={'Distance'}>Distance</option>
              </Select>
          </FormControl>
            }
      </Grid>



      <Grid container item xs={12} style={{height:300, margin: margin, padding: 5, justifyContent:'center'}}>
        <SimpleBarReact autoHide={false} style={{maxHeight: 300, width: 'inherit', padding:padding}}>
          {displayPros}
        </SimpleBarReact>

          {availablePros.length === 0 && 
          <Grid item container xs={12} md={10}>
            <Typography style={{textAlign:'center'}}>🤷‍♀️ Oops, Looks like no there is no one available in your area at this time!</Typography>
            <Typography style={{textAlign:'center'}}>Try choosing another time or scheduling with a pro directly! 👍</Typography>
            {/* <Button fullWidth variant='contained' color='primary' onClick={() => window.location.reload()}>Start Over</Button> */}
          </Grid> 
          }
      </Grid>


    
      <Grid container item xs={6} style={{ padding: '0px 10px 36px 10px', justifyContent: 'flex-end'}}> 
        <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            style={{ backgroundColor: 'white',  
              color: '#413e68'}}
            onClick={() => navigation.previous()}>
            Back
        </Button>

      </Grid>
      
      
      <Grid container item xs={6} style={{ padding: '0px 10px 36px 10px', justifyContent: 'left'}}> 
     
          {availablePros.length===0 ?
          <Button
            variant='contained'
            className={classes.btn}
            color='primary'
            onClick={() => window.location.reload()}
            >Start Over
            </Button>
          :
          <Button
              variant="contained"
              className={classes.btn}
              color='primary'
              onClick={() => navigation.next()}>
              Next
          </Button>
          }
        

      </Grid>
    </Grid>
  );
};

SelectAPro.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};


export default withWidth()(SelectAPro)
