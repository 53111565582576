import 'date-fns';
import React, {useState, useEffect} from "react";
import {CssBaseline, Typography, Grid, Container, TextField, Button, Tooltip, Modal, Link} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import {MiuPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TimeSlotCard from './TimeSlotCard';
import { auth, firestore } from '../../../config/fbConfig';
import LoginModal from '../LoginModal';
import { forEach } from 'mathjs';
const availability = require('timeslot-availability');

var generator = require('generate-password');
var randomKey = generator.generate({length: 19, numbers: true, lowercase: true, uppercase: true, excludeSimilarCharacters: true});



const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 12, 
      border:'1px solid #e0e0e0',
      position: 'relative',
      marginTop:'20px', // -10% for mobile
      minHeight: 510,
      boxShadow: '5px 5px 7px -1px rgba(0,0,0,0.26)',
      textAlign: 'left'
    },
    titleWrapper:{
      borderRadius: '8px 8px 0 0',
      background: theme.palette.primary.main,
      height: "50px"
    },
    title: {
      color: theme.palette.primary.main,
      padding: "8px 0 0 20px",
      fontWeight:'bold',
    },
    question: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))"
    },
    sAPWrapper: {
      position: 'relative',
      height:30
    },
    sAPBtn: {
      position: 'absolute',
      top: 12,
      right: 12
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    dateTimePicker: {
      width:'100%'
    },
    iconButton: {
     color: theme.palette.primary.main,
     fontSize: '40px',
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btn: {  
      fontWeight: 'bold',
      //border: `3px ${theme.palette.primary.main} solid`,
      letterSpacing: '2px',
      marginTop: "1rem",
      height:'48px',
      width: 160,
    },

  }));

export const TimeSelect = ({ formData, setForm, navigation, userData, pro, selectedService, handleBookingModalOpen}) => {
  const  {service, dateTime} = formData
  const [guest, setGuest] = useState(null)
  const [signInModal, setSignInModal] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [value, setValue] = useState(service);
  const [now, setNow] = useState(false);
  const [later, setLater] = useState(false);
  const [date, onChange] = useState(formData.dateTime);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [start, setAvailabilityStart] = useState(null)
  const [end, setAvailabilityEnd] = useState(null) // + 8 hrs
  const [start2, setAvailabilityStart2] = useState(null)
  const [end2, setAvailabilityEnd2] = useState(null) // + 8 hrs
  const [start3, setAvailabilityStart3] = useState(null)
  const [end3, setAvailabilityEnd3] = useState(null) // + 8 hrs
  const [appointments, setAppointments] = useState([])
  const [apptCheckr, setApptCheckr] = useState(false)
  const classes = useStyles();
  const disabled = (service === '' || selectedDate === null) ? true : false
  const disabled2 = (now === true || later === true) ? true : false
  const backgroundColor = (now === true || later === true) ? '#413e68' : null
  const color = (now === true || later === true) ? 'white' : null
  const variant = (now === true || later === true) ? 'contained' : 'outlined'

  const timespan = 1800; //time in seconds + 30 min buffer

  const bookable = (start!==null && end!==null ) && availability(start, end, timespan, appointments)
  const bookable2 = (start2!==null && end2!==null ) && availability(start2, end2, timespan, appointments)
  const bookable3 = (start3!==null && end3!==null ) && availability(start3, end3, timespan, appointments)
  
  useEffect(() => {

    formData.service = selectedService.name
    formData.serviceCost = selectedService.price
    formData.amtOfTime = selectedService.time
    formData.dateTime = selectedDate
    formData.serviceTimeEnd = selectedService.time
    formData.reqDeposit = (selectedService.reqDeposit !== null && selectedService.reqDeposit !== undefined) ? selectedService.reqDeposit : false
    formData.priceVaries = (selectedService.priceVaries !== undefined) ? selectedService.priceVaries : false
    
    formData.pro = {name: pro.firstName +" " +pro.lastName, phone: pro.phone, coordinates: pro.coordinates, address:pro.address, convenienceFee: pro.convenienceFee,
     convenienceFeeReq: pro.convenienceFeeReq, email: pro.email, userId: pro.userId, ratings: pro.ratings, avatar: pro.images.avatar, stripeId: pro.stripeId, travel:pro.travel}


    if(userData.userId && guest === null){
      setGuest(false)
      console.log(userData.userId)
      formData.client = {name: userData.firstName +" " +userData.lastName, avatar: userData.images && userData.images.avatar, customerId: userData.customerId,
      email: userData.email, userId: userData.userId, accountStatus: userData.accountStatus, phone: userData.phone}
    }else if(userData.userId === undefined && guest === null ){
      setGuest(true)
      handleOpenGuestModal()
    }
    
    
    if(selectedService.zoomLink && selectedService.zoomLink !== ""){
      formData.bookingFee = "0.00"
    }else{
      if(parseFloat(selectedService.price) <= 99.99){
        formData.bookingFee = "5.99"
      }else{
        formData.bookingFee = (parseFloat(selectedService.price)*.06).toFixed(2)
      }
  
    if(now === true){
      formData.dateTime = new Date(new Date().getTime() + 900000)
    }

    }

    if(later===true && formData.dateTime !== null && formData.dateTime < new Date(new Date().getTime() + 7140000)){
      //formData.dateTime = new Date(`${selectedDate}`)
      // console.log('1')
      alert('Please select a time at lease 2 hours from now!')
      setSelectedDate('')
      formData.dateTime = null
    }

    if(date !== null){
      console.log('test 2')
      setAvailabilityStart(null)
      setAvailabilityEnd(null)
      setAvailabilityStart2(null)
      setAvailabilityEnd2(null)
      setAvailabilityStart3(null)
      setAvailabilityEnd3(null)
      pro.availability.map(dayAvailable => {
        if((dayAvailable.day === date.toString().split(' ')[0].toLowerCase()) && dayAvailable.slots.length > 0){
          const startTime = new Date(date).toString().split(' ')
          const startTime2 = new Date(date).toString().split(' ')
          const startTime3 = new Date(date).toString().split(' ')
          
          const endTime = new Date(date).toString().split(' ')
          const endTime2 = new Date(date).toString().split(' ')
          const endTime3 = new Date(date).toString().split(' ')
          // console.log('start times', startTime, endTime)
          
          if(dayAvailable.slots.length === 1){
            console.log('test 2.b')
            startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
            endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
            setAvailabilityStart(startTime.join(' '))
            setAvailabilityEnd(endTime.join(' '))
          }
          if(dayAvailable.slots.length === 2){   
            console.log('test 2.c')
            startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
            endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
            startTime2.splice(4,1,`${dayAvailable.slots[1].from}`)
            endTime2.splice(4,1,`${dayAvailable.slots[1].to}`)
            
            setAvailabilityStart2(startTime2.join(' '))
            setAvailabilityEnd2(endTime2.join(' '))
            setAvailabilityStart(startTime.join(' '))
            setAvailabilityEnd(endTime.join(' '))
            
          }
          if(dayAvailable.slots.length === 3){
            console.log('test 2.d')
            startTime.splice(4,1,`${dayAvailable.slots[0].from}`)
            endTime.splice(4,1,`${dayAvailable.slots[0].to}`)
            startTime2.splice(4,1,`${dayAvailable.slots[1].from}`)
            endTime2.splice(4,1,`${dayAvailable.slots[1].to}`)
            startTime3.splice(4,1,`${dayAvailable.slots[2].from}`)
            endTime3.splice(4,1,`${dayAvailable.slots[2].to}`)
            setAvailabilityStart(startTime.join(' '))
            setAvailabilityEnd(endTime.join(' '))
            setAvailabilityStart2(startTime2.join(' '))
            setAvailabilityEnd2(endTime2.join(' '))
            setAvailabilityStart3(startTime3.join(' '))
            setAvailabilityEnd3(endTime3.join(' '))
          }        
        }
        else if((dayAvailable.day === date.toString().split(' ')[0].toLowerCase()) && dayAvailable.slots.length === 0){
          // console.log('Not Avaialable')
        }
        //const formattedTime = 
      })
      //setLoading(true)
    } 
    
    if(appointments.length === 0 && apptCheckr === false){
      const startBuffer = selectedService.time * 60

      firestore.collection('appointments').where('proUID', '==', pro.userId)
      .where('serviceComplete', '==', false).where('serviceCanceled', '==', false)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => console.log(doc.id, " => ", doc.data()))
        setAppointments(querySnapshot.docs.map(doc => {
          const data = doc.data()
          return({
            start: new Date((data.serviceTimeReq.seconds - startBuffer) * 1000).toISOString(),
            end: new Date((data.serviceTimeEnd.seconds + 1800) * 1000).toISOString()})})
            )
            // querySnapshot.forEach((doc) => {
              //     const data = doc.data()
              //     // console.log('SERVICE', data.service,);
              //     appointments.push({start: new Date(data.serviceTimeReq.seconds * 1000).toISOString(), end: new Date(data.serviceTimeEnd.seconds * 1000).toISOString()})
              // });
            })
            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
            setApptCheckr(true)
          }
          
          
          
    if(bookable!== false && bookable.length > 0 && window.innerWidth < 960){
      console.log('test 4')
      var myElement = document.getElementById('calendar');
      myElement.scrollTo({top: 250, behavior: 'smooth'})
      // console.log(window.innerWidth)
      // console.log("Bookable:",bookable)
    }
    // // console.log("Bookable:",bookable)
    // // console.log('Avalialble From:',start, ' to ', end)
    // // console.log('Avalialble From2:',start2, ' to ', end2)
    // // console.log('Avalialble From3:',start3, ' to ', end3)

    // console.log("APPTS:", appointments)
    // console.log('user is guest: ', guest)

  }, [selectedDate, guest, formData, now, later, apptCheckr, selectedService, userData, pro, date, bookable, bookable2, bookable3, service, loading, start, start2, start3, end, end2, end3, appointments, timespan, dateTime])



  const handleOpenGuestModal = () => {
    setSignInModal(true)
  }
  
  const handleServiceChange = (event, newValue) => {
    setValue(newValue);
    formData.service = newValue
  }
  
  const handleDateChange = () => {
      setLoading(false)

  };

  const handleNow = () => {
    setNow(true)
    setLater(false)
  }

  const handleLater = () => {
    setNow(false)
    setLater(true)
    // formData.dateTime = null
    // setSelectedDate('')
  }

  const handleCancel = () => {
    handleBookingModalOpen()
    setSelectedDate(null)
    formData.dateTime = null
    formData.service = ''

  }

  const handleSubmit = (e) => {
    e.preventDefault()
      auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        if(user) {
          setSignInModal(false)
        }
      })
      .catch(err => console.log(err))

    
  }

  const handleGuestAuth = () => {
        formData.client = {name: '', avatar: 'https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Images%2FguestUser.jpeg?alt=media&token=322014d9-e2db-4b05-97a4-42f2fcb28d94',
        email: '', userId: 'guestUser' + randomKey, accountStatus: 'guest'}
        setSignInModal(false)
      
  }

  return (
    <Grid item xs={11} sm={10} md={8} lg={6} xl={5} container style={{outline:'none', height:600, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '12px', padding:10}}>
      <Grid item xs={12}>
        <Typography variant='h5' className={classes.title}>Booking with: {pro.firstName + ' ' + pro.lastName}</Typography>
      </Grid>
      
      <Grid container item xs={12} style={{ padding:'10px 20px'}}>
        <Typography style={{fontSize:"calc(12px + 3 * ((100vw - 320px) / 680))", fontWeight:'bold'}}>Requested Service: {selectedService.name}</Typography>
       
      </Grid>

      <Grid item xs={12} style={{textAlign: 'center'}}>
          <Typography className={classes.question}>2. When are you available?</Typography>
      </Grid>
      
      <Grid container item xs={12} id='calendar' style={{ padding:'10px 20px', justifyContent: 'space-around', overflowY:'scroll', height:'320px'}}> 

            {(pro.online === true && pro.busy === false) ?
                <Grid item xs={12} container justify="space-around">

                    {later !== true &&
                    <Grid item xs={12} sm={5}>
                    <Button fullWidth variant={variant} color='primary' disabled={disabled2} style={{backgroundColor:backgroundColor, color:color}} onClick={() => {handleNow(); setSelectedDate(new Date(new Date().getTime() + 900000))}}>NOW</Button>
                    </Grid>
                    }
                    {now !== true &&
                    <Grid item xs={12} sm={5}>
                    <Button fullWidth variant={variant} color='primary' disabled={disabled2} style={{backgroundColor:backgroundColor, color:color}} onClick={handleLater}>LATER</Button>
                    </Grid>
                    }

                </Grid>
            :
            <Grid item xs={12} md={6} container justify="space-around">
                <Grid item xs={12} style={{marginTop:10, display:'flex', justifyContent:'center'}}>
                  <Calendar
                    minDate={new Date()}
                    onChange={onChange}
                    value={date}
                  />
                </Grid>
            </Grid>
            }

            {later === true &&
                <Grid item xs={12} md={6} style={{marginTop:10, display:'flex', justifyContent:'center'}}>
                  <Calendar
                    minDate={new Date()}
                    onChange={onChange}
                    value={date}
                  />
                </Grid>
            }
            {(bookable && bookable.length !== 0)  &&
                <Grid item xs={4} md={2} style={{marginTop:10, display:'flex', flexFlow:'column', alignItems:'center'}}>
                  {bookable.map(timeslot => {
                    const time = timeslot.start
                    console.log(timeslot)
                    if(time > new Date()){
                      return(
                        <TimeSlotCard key={time.start} time={time} setSelectedDate={setSelectedDate}/>
                      )
                    }
                  })
                  }
                </Grid>
            }
            {(bookable2 && bookable2.length !== 0)  &&
                <Grid item xs={4} md={2} style={{marginTop:10, display:'flex', flexFlow:'column', alignItems:'center'}}>
                  {bookable2.map(timeslot => {
                    const time = timeslot.start
                    if(time > new Date()){
                      return(
                        <TimeSlotCard key={time.start} time={time} setSelectedDate={setSelectedDate}/>
                      )
                    }
                  })
                  }
                </Grid>
            }
            {(bookable3 && bookable3.length !== 0)  &&
                <Grid item xs={4} md={2} style={{marginTop:10, display:'flex', flexFlow:'column', alignItems:'center'}}>
                  {bookable3.map(timeslot => {
                    const time = timeslot.start
                    if(time > new Date()){
                      return(
                        <TimeSlotCard key={time.start} time={time} setSelectedDate={setSelectedDate}/>
                      )
                    }
                  })
                  }
                </Grid>
            }

            
       


        

      </Grid>

      <Grid container item xs={6} style={{ padding: "0px 10px 36px 10px", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          style={{ backgroundColor: "white", color: "#413e68" }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Grid>

      <Grid container item xs={6} style={{ padding: "0px 10px 36px 10px", justifyContent: "left" }}>

        <Tooltip title="Please select a Time." disableHoverListener={!disabled}>
          {/* <div style={{width:'fit-content'}}> */}
            <Button
                disabled={disabled}
                variant="contained"
                className={classes.btn}
                color='primary'
                onClick={() => navigation.next()}>
                Next
            </Button>
          {/* </div> */}
        </Tooltip> 
      </Grid>


      <Modal open={!!signInModal}>
      <Grid container xs={11} md={4} lg={3} display='column' style={{display:'flex', justifyContent:'center', outline:'none', position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', padding: '10px', borderRadius: '15px'}}>
             <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Sign in to Schedule!
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    value={email}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    // autoFocus
                  />
                  <TextField
                    onChange={(e) => setPassword(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    value={password}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign In
                  </Button>

                  <Button
                    onClick={handleGuestAuth}
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                    style={{margin:'40px 0px 20px'}}
                  >
                    Continue As Guest
                  </Button>

                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        {"No account? Sign Up!"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
        </Grid>
      </Modal>
    </Grid>
  );
};