import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { Names } from "./stepForm/Names";
import { Address } from "./stepForm/Address";
import { Contact } from "./stepForm/Contact";
import { Review } from "./stepForm/Review";

const defaultData = {
  firstName: "",
  lastName: "",
  address: "",
  coordinates:{lat:null,lng:null},
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
  password: "",
  ratings: [{createdAt: new Date(), rating: 5}],
  favorites: [],
};

const steps = [
  { id: "names" },
  { id: "address" },
  { id: "contact" },
  { id: "review" },
];

export const CustomerSignUp = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setForm, navigation };

  switch (step.id) {
    case "names":
      return <Names {...props} />;
    case "address":
      return <Address {...props} />;
    case "contact":
      return <Contact {...props} />;
    case "review":
      return <Review {...props} />;
    default:
      return (
          <div>
            <h1>Multi step form</h1>
          </div>
        );
  }

  
};