import React from 'react'
import { Modal} from '@material-ui/core';
import { Scheduler } from './RescheduleModal/Scheduler';

function RescheduleModal({modalOpen, handleModalOpen, appt, userData, scheduledAppointments, handleMenuClose}) {
    return (
        <Modal open={!!modalOpen} onClose={handleModalOpen}>
            <Scheduler userData={userData} appt={appt} handleModalOpen={handleModalOpen} modalOpen={modalOpen} scheduledAppointments={scheduledAppointments} handleMenuClose={handleMenuClose} />
        </Modal>
    )
}

export default RescheduleModal
