import React, {useEffect, useState} from 'react'
import {Container, Grid, Typography, CircularProgress, withWidth, Hidden, Button} from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    infoContainer:{
        display:"flex",
        justifyContent:"center",
        padding: "10px",
        borderTop: 'solid 1px #d3d3d3',
    },
    title:{
        width:'100%',
        textAlign:'center',
        fontWeight:'bold',
        heigh:'fit-content'
    },
    header:{
        fontWeight:'bold',
        width:'100%',
        margin:'50px 0px 0px',
        fontSize:30,
    },
    section:{
      borderRadius: 12,
      border:'1px solid lightgray',
      padding:10,
      marginBottom: 10,
      textDecoration:'none',
      color: 'black',
      "&:hover":{
        cursor:'pointer',
        border:`2px solid ${theme.palette.primary.main}`,
        backgroundColor:'#e6e6e6',
      },
    },
    important:{
        fontWeight:'bold',
        testTransformation:'uppercase',
    },
    link: {
      textDecoration:'none',
      color:'black',
      fontSize:24,
      '&:hover':{
          cursor:'pointer',
          color: theme.palette.primary.main
      }
    },
  }));

function Terms() {
    const classes = useStyles()
    
    return (
        <Grid item container xs={12} className={classes.infoContainer}>
            <Typography variant='h2' className={classes.title}>Terms Of Service</Typography>
            <Typography variant='caption' style={{width:'100%', marginTop:20}}>Last revised November 2, 2021</Typography>
            <Typography variant='caption' style={{width:'100%', marginTop:20}}>These services (as defined herein) and Terms of Use are provided by Cuti App.</Typography>
            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                Welcome to Cuti, a website located at www.CutiApp.com (the “Site”) and operated by Cuti App,llc. Cuti provides the Site and mobile applications (the “Applications”) to facilitate on-demand and in-home beauty & wellness services and the ability to purchase beauty products (collectively, with the Site and Applications, the “Services”). Your use of the Services is also subject to any additional terms, conditions, and policies that we separately post on the Services or provide to you, and any agreements that you have separately executed with Cuti (“Supplemental Terms”), including without limitation our COVID-19 Safety Policy. All Supplemental Terms are incorporated by reference into this Agreement. Additionally, for the avoidance of doubt, if you are a service provider who has executed a Membership Services Agreement (“Member Agreement”), the terms of that Member Agreement will control and supersede this Agreement with respect to the subject matter of such Member Agreement. To the extent there is any conflict between this Agreement and the Supplemental Terms, the Supplemental Terms will control with respect to the subject matter of such agreement.
                <br/><br/>
                These Terms of Use (“Agreement”) set forth the legally binding terms for your use of the Services. By accessing or using the Services, you are accepting this Agreement (on behalf of yourself or the entity that you represent) and you represent and warrant that you have the right, authority, and capacity to enter into this Agreement (on behalf of yourself or the entity that you represent). You may not accept this Agreement or access or use the Services if you are not at least 18 years old. You may use the Services on behalf of individuals under 18 only if you are the parent or legal guardian of such individuals. If you do not agree with all of the provisions of this Agreement, do not access and/or use the Services.
                <br/><br/>
                <span className={classes.important}>
                    THIS AGREEMENT INCLUDES A CLASS ACTION WAIVER AND A WAIVER OF JURY TRIALS, AND REQUIRES BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL, NON-CLASS BASIS TO RESOLVE MOST DISPUTES BETWEEN US.
                </span>
                <br/><br/>
                PLEASE NOTE THAT THE SERVICES ARE INTENDED TO BE USED TO FACILITATE THE CONNECTION OF SERVICE PROVIDERS AND CLIENTS FOR THE PURPOSES OF ARRANGING IN-HOME BEAUTY & WELLNESS SERVICES WITH EACH OTHER THROUGH THE SERVICES, BUT YOU AGREE THAT CUTI HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY APPOINTMENT OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT. CUTI CANNOT AND DOES NOT CONTROL THE INFORMATION OR CONTENT CONTAINED IN ANY LISTINGS (OTHER THAN THE ARRANGEMENT AND DISPLAY OF SUCH CONTENT) OR THE CONDITION, LEGALITY, SAFETY, OR SUITABILITY OF ANY APPOINTMENTS OR ACCOMMODATIONS. CUTI IS NOT RESPONSIBLE FOR, AND DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY AND ALL APPOINTMENTS AND ACCOMMODATIONS. ACCORDINGLY, ANY APPOINTMENTS WILL BE MADE OR ACCEPTED AT A USER’S OWN RISK, AND, AMONG OTHER THINGS, CLIENTS MUST SATISFY THEMSELVES WITH ANY SERVICE PROVIDER AND THEIR SERVICE PROVIDER’S CREDENTIALS, AND SERVICE PROVIDERS MUST SATISFY THEMSELVES WITH CLIENTS AND THE CLIENT’S ACCOMMODATIONS. <span style={{fontWeight:'bold'}}>USERS SHOULD NOT COMPLETE, AND SHOULD IMMEDIATELY CANCEL, APPOINTMENTS IF THEY FEEL UNSAFE OR UNCOMFORTABLE WITH ANY CONDITIONS RELATING TO THE APPOINTMENT IN ANY WAY.</span>  YOU ACKNOWLEDGE AND AGREE THAT YOUR ABILITY TO OBTAIN IN-HOME BEAUTY & WELLNESS SERVICES THROUGH THE USE OF THE SERVICES DOES NOT ESTABLISH CUTI AS A PROVIDER OF BEAUTY & WELLNESS SERVICES.
            </Typography>

            <Typography className={classes.header}>Accounts</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                Account Creation. In order to use certain features of the Services, you must register for an account with us (your “Account”) and provide certain information about yourself as prompted by the registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You agree not to create an account on behalf of someone other than yourself.  You further agree that you shall not maintain more than one account at any given time.  You may delete your Account at any time, for any reason, by following the instructions in the Services. We may suspend or terminate your Account in accordance with the section entitled “Term and Termination.” You agree not to create an account or use our Services if you have been previously removed by Cuti, or if you have been previously barred from any of the Services.
                <br/><br/>
                Account Responsibilities. You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify us of any unauthorized use, or suspected unauthorized use, of your Account or any other breach of security. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
            </Typography>

           
            <Typography className={classes.header}>Payment Terms</Typography>
            
            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                Booking Service Providers.  You must provide us with a valid credit card (issued by Visa, MasterCard, or any other issuer accepted by our payment provider) (<span className={classes.important}>“Payment Provider”</span>) as a condition to making an appointment with a service provider, including free or promotional services Cuti may arrange from time to time as part of the Services.
                <br/><br/>
                Cancellation of Appointments. You may cancel any appointment in accordance with our cancellation policy set forth at [INSERT URL]. We reserve the right to cancel free promotional Services at any time for any reason. No credits or promotional value adjustments will be made in the event we cancel a free promotional Service. If the service provider cancels an appointment for any reason, you will receive a refund, unless you are at fault for the delay/cancelation of the service. If service providers cancel or terminate a paid appointment for any reason, we may, in our sole and reasonable discretion, issue you a refund of any fees/deposits prepaid for such appointment with the exception of the Service Fee. All service fees are non refundable.
                <br/><br/>
                <span className={classes.important}>Supplemental COVID-19 Cancellation Policy.</span> In addition to the cancellation rights set forth in the Cuti cancellation policy set forth at [INSERT URL], you must cancel an appointment through the Services if you have a cough, fever or other symptoms of COVID-19, if you have tested positive or COVID-19, or if you generally feel unwell and may do so at any time until 1 hour before the start time of the appointment without any penalty. Cuti reserves the right to revise or update this supplemental cancellation policy at any time, including to cover other pandemics or illnesses.
                <br/><br/>
                Both clients and service providers should also be aware that the other party to an appointment is required to cancel the appointment in accordance with this Supplemental COVID-19 Cancellation Policy and may do so at any time. Cuti cannot guarantee replacements for such cancellations and does not take any responsibility or liability for, or in connection with, any cancelled appointments or any results thereof.
                <br/><br/>
                If Cuti discovers that you, whether a client or a service provider, has abused this supplemental cancellation policy, Cuti has the right to, immediately, without notice, and in its sole discretion, suspend or terminate your Account.
                <br/><br/>
                Payment Process. If you order an in-home beauty or wellness service or any Product, you agree to pay the then-current applicable service fee listed in the Services or the website of our third party booking agent (<span className={classes.important}>“Booking Agent”</span>) as linked to from the Site or Application. We, or our booking agent, will manually bill your credit card for the appointment balance on the date the appointment is booked, after the appointment has been started, completed or product ordered, and each time you book an appointment or order a product thereafter. Unless otherwise expressly set forth in this Agreement, all payments to the service providers are non-refundable, except in the event the service provider, or we cancel your appointment. Fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you are responsible for payment of all such taxes, levies or duties. Any amounts not paid when due will bear interest at the rate of one and one half percent (1.5%) per month, or the maximum legal rate if less. If any fee cannot be charged to your credit card for any reason, we may provide you, via email, notice of such non-payment and a link for you to update your payment information.
                <br/><br/>
                General Payment Terms. By providing us with your credit card number and associated payment information, you agree that Cuti is authorized to immediately invoice your Account for all fees and charges due and payable to us hereunder and that no additional notice or consent is required. You agree to immediately notify Cuti of any change in your billing address or the credit card used for payment hereunder. We may change the pricing for in-home beauty & wellness appointments or Products (from time to time in our sole discretion, and subject only to the right of service providers to review and accept said pricing changes) by updating the price list included on the Site and Application and without any additional notice to you, provided that any changes will not affect any items or services purchased prior to the notice.
                <br/><br/>
                Promotional Codes. We may, in our sole discretion, create promotional codes that may be redeemed for credit in your Account, or other features or benefits related to the Services, subject to any additional terms that we establish on a per promotional code basis (<span className={classes.important}>“Promo Codes”</span>). Unless otherwise expressly stated in additional terms we establish for a particular Promo Code, Promo Codes may only be used once per person, multiple Promo Codes cannot be used in a single transaction or appointment, and each Promo Code benefit may only be redeemed once per account. Only Promo Codes sent to you through official Cuti communications channels are valid. You further agree: (i) to use Promo Codes only for their intended purpose, and in a lawful manner; (ii) you will not alter, duplicate, sell, transfer or redistribute Promo Codes in any manner (including, without limitation, by posting Promo Codes to a website or other public forum), unless expressly permitted by us; (iii) Promo Codes may be disabled by us at any time for any reason without liability to us; (iv) Promo Codes may only be used pursuant to this Agreement and any specific additional terms that we establish for a Promo Code; (v) Promo Codes have no cash value; and (vi) Promo Codes may expire prior to your use.
                <br/><br/>
                Reservation of Rights. We reserve the right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you or any other user in the event we determine in our sole discretion that the use or redemption of the Promo Code in question or the provision of any referral credit was in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or the terms of this Agreement. Once you have redeemed a Promo Code or used any credit in your Account, no further promotional value adjustments or credits will be issued for any reason, including but not limited to, dissatisfaction with the Services.
            </Typography>



            <Typography className={classes.header}>Your Responsibilities</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                As the recipient of in-home beauty & wellness services provided by service providers, you agree to assume certain responsibilities. When you book an appointment, you agree to comply with any rules or requirements of the service provider applicable to the service purchased and that you are made aware of, including as part of any confirmation email (the <span className={classes.important}>“Requirements”</span>).
                <br/><br/>
                Whether or not there are Requirements, you agree to: (a) create in your home (or other space where our service providers are invited to perform their services) a safe, clean, and reasonably comfortable workspace that is free of conditions that would make it difficult for our service providers to perform their jobs (all as determined in each of our service provider’s sole discretion); (b) provide a workspace that has access to bathroom facilities and is in close proximity to an electrical outlet and a sink with hot and cold running water; and (c) refrain, and cause other individuals present in your household to refrain, from any speech, conduct, or personal displays that a reasonable person would find offensive, intimidating, hostile, harassing, indecent or abusive while our service providers are present. If you have pets, you agree to confine all of your pets to a room where our service providers will not be working. If you have a child or children under the age of 13, you agree that childcare (provided by someone other than you and our service provider) will be present. This is important as the service provider will have equipment that can harm a child and our service provider will be focused on you. During the COVID-19 pandemic, you agree that, to the extent possible, either (a)no other individuals will be present in the space for the entirety of your appointment, 0r (b)everyone present is wearing a mask. Service providers reserve the right to terminate or refuse to provide their services to anyone at any time in the event you do not fulfill your responsibilities as set forth in this Section or if a service provider feels unsafe or uncomfortable with the in-home work environment or conditions for any reason.  You understand, acknowledge and agree that service providers are not employees or agents of Cuti, but are independent contractors who operate business enterprises separate and distinct from Cuti.
                <br/><br/>
                <span className={classes.important}>COVID-19 Safety Policy.</span>  Without limiting the requirements and responsibilities set forth above, all recipients acknowledge and agree that the Requirements include a recipient’s responsibility for taking all appropriate health, safety and hygiene measures to protect yourself and any service provider from the spread or potential spread of infectious diseases or conditions, or any other unhealthy, unsafe, or unsanitary conditions, including without limitation by following all steps set forth in Cuti’s COVID-19 Safety Policy set forth at [INSERT URL] (the “Safety Policy”). Cuti may update or supplement this Safety Policy from time to time, including to respond to or incorporate additional or updated guidance, so you must check the Safety Policy frequently and comply with the most recent version of the Safety Policy at the time of your appointment. For more information on how to keep your home safe, please review the <a className={classes.important}  href='https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public'>WHO</a> and/or <a className={classes.important} href='https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html'>CDC</a>‘s guidance. While the Safety Policy sets the minimum requirements for the receipt of in-home beauty & wellness service appointments made through the Services, there is no substitute for common sense, and Cuti cannot, and does, not guarantee any services or conditions or take any responsibility or liability for (a) a user’s failure to provide safe, healthy, and sanitary services or accommodations for the services, (b) unsafe, unhealthy, or unsanitary conditions even If the Safety Policy is followed, or (c) any spread or potential spread of infectious diseases or conditions or any other unhealthy, unsafe, or unsanitary conditions resulting from use of the Services in any manner.
            </Typography>

            <Typography className={classes.header} style={{color:'red'}}>Your Representations</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20, color:'red'}}>
                If you are utilizing in-home beauty & wellness services in the state of Florida, you represent that the purpose of your request for the Services is in connection with: the motion picture, fashion photography, theatre or TV industry; photography studio salon work, i.e., hair arranging and cosmetics in preparation for a photo session; a special event, e.g. wedding, fashion show or other similar event; a trade show demonstration or educational seminar; or because of ill health you are unable to go to a licensed salon.  If you are utilizing in-home beauty & wellness services in the state of California, you represent that the purpose of your request for the Services is: incidental to the theatrical, radio, television or motion picture production industry; necessary due to your illness or other physical or mental incapacitation; or for the purpose of receiving recommendations and/or live demonstrations about products as well as potential purchase of products.
            </Typography>

            <Typography className={classes.header}>Ownership; Rights and Licenses</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                License to Use Site. We grant you a non-transferable, non-exclusive, right to access and use the Services for your personal use.
                <br/><br/>
                <span style={{color:'red'}}>License to Use Application. We grant you a non-exclusive, non-transferable right to download, install and use a copy of the Application on a single mobile device or computer that you own or control solely for your personal use, subject at all times to the terms of this Agreement. Furthermore, with respect to any Application accessed through or downloaded from the Apple App Store (an “App Store Sourced Application”), you will only use the App Store Sourced Application (a) on an Apple-branded product that runs iOS (Apple’s proprietary operating system) and (b) as permitted by the “Usage Rules” set forth in the Apple App Store Terms of Service. Use of the Application is also subject to the provisions of the section entitled “Acceptable Use Policy.”</span>
                <br/><br/>
                Certain Restrictions. The rights granted to you in this Agreement are subject to the following restrictions: (a) you will not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Services; (b) you will not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Services; (c) you will not access the Services in order to build a similar or competitive service; and (d) except as expressly stated herein, no part of the Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Any future release, update, or other addition to functionality of the Services will be subject to the terms of this Agreement. All copyright and other proprietary notices on any Services content must be retained on all copies thereof.
                <br/><br/>
                Modification. We reserve the right, at any time, to modify, suspend, or discontinue your access to the Services with or without notice. You agree that we will not be liable to you or to any third party for any modification, suspension, or discontinuance of your access to the Services or any part thereof, except and if otherwise expressly set forth in Section entitled “Term and Termination.”
                <br/><br/>
                No Support or Maintenance. You acknowledge and agree that we will have no obligation to provide you with any support or maintenance in connection with the Services.
                <br/><br/>
                Ownership of the Services. Excluding your User Content (defined below), you acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Services, including the Site and Applications, are owned by us or our licensors. The provision of the Services does not transfer to you or any third party any rights, title or interest in or to such intellectual property rights. We and our suppliers reserve all rights not granted in this Agreement.
                <br/><br/>
                Trademarks. Cuti’s stylized name and other related graphics, logos, trademarks, service marks and trade names used on or in connection with the Services are the property of Cuti and may not be used without our written permission in connection with any third-party products or services. Other trademarks, service marks and trade names that may appear on or in the Services are the property of their respective owners. You will not remove, alter or obscure any copyright notice, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Services. 
            </Typography>

            <Typography className={classes.header}>User Content</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                User Content. <span className={classes.important}>“User Content”</span> means any and all information and content that a user submits to or posts on: (a) the Services and (b) on social networking sites where we have a page or presence (collectively <span className={classes.important}>“SNS Pages”</span>). You will own your User Content, with the understanding that you agree that we may use and reproduce the User Content you make available on our SNS Pages and on the Services. You assume all risks associated with the use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any third party personally identifiable. You hereby represent and warrant that your User Content does not violate the Acceptable Use Policy (defined below). You may not state or imply that your User Content is in any way provided, sponsored or endorsed by us. Because you alone are responsible for your User Content (and not Cuti), you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy. We are not obligated to backup any User Content and User Content may be deleted at any time. You are solely responsible for creating backup copies of your User Content if you desire.
                <br/><br/>
                License. You hereby grant, and you represent and warrant that you have the right to grant, to us an irrevocable, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use your User Content, and to grant sublicenses of the foregoing, solely for the purposes of including your User Content in the Site and Services. You agree to irrevocably waive (and cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.
                <br/><br/>
                Acceptable Use Policy. The following sets forth our “Acceptable Use Policy”:
                <br/><br/>
                You agree not to use the Services to collect, upload, transmit, display, or distribute any User Content: (i) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual or is otherwise objectionable; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.
                <br/><br/>
                In addition, you agree not to use the Services to: (i) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; (ii) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii) harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Services or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Services, other computer systems or networks connected to or used together with the Services, through password mining or other means; (vi) harass or interfere with another user’s use and enjoyment of the Services; or (vii) introduce software or automated agents or scripts to the Services so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the Services (except that we grant the operators of public search engines revocable permission to use spiders to copy materials from the Site for the sole purpose of, and solely to the extent necessary, for creating publicly available searchable indices of the materials, but not caches or archives of such materials).
                <br/><br/>
                Enforcement. We reserve the right (but have no obligation) to review any User Content, investigate, and/or take appropriate action against you in our sole discretion if, we determine in our sole discretion, that you violated the Acceptable Use Policy or any other provision of this Agreement or otherwise created liability for us or any other person. Such acts may include, but are not limited to, removing or modifying your User Content, terminating your Account in accordance with Section entitled “Term and Termination” and/or reporting you to, and cooperating with, law enforcement authorities. We are entitled, except to the extent prohibited by applicable law, to disclose any information or materials on or in the Services, including User Content, in our possession in connection with your use of the Services, to (i) comply with applicable laws, legal process or governmental requests; (ii) enforce this Agreement; (iii) respond to any claims that User Content violates the rights of third parties; or (iv) protect the rights, property or personal safety of Cuti, its users, employees or the public, and all law enforcement or other government officials, as Cuti in its sole discretion believes to be necessary or appropriate.
                <br/><br/>
                Feedback. If you provide us any feedback or suggestions regarding the Services (<span className={classes.important}>“Feedback”</span>), then you grant us a perpetual, royalty-free, transferable, sublicensable, worldwide license to all rights in the Feedback and agree that we will have the right to use such Feedback and related information in any manner we deem appropriate. We will treat any Feedback you provide to us as non-confidential and non-proprietary. You agree that you will not submit to us any information or ideas that you consider to be confidential or proprietary.
            </Typography>

            <Typography className={classes.header}>Indemnity</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                To the fullest extent permitted by law, you agree to indemnify and hold us (and our officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Services in an unauthorized manner; (b) your User Content; (c) your violation of this Agreement;  (d) your violation of applicable laws or regulations; or (e) your violation of any of the Requirements. We reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without our prior written consent. We will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
                <br/><br/>
            </Typography>

            <Typography className={classes.header}>Third Party Services</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                Third Party Sites, Apps and Ads. The Site might contain links to third party websites, applications, services, and advertisements for third parties (collectively, “Third Party Sites, Apps & Ads”). Such Third Party Sites, Apps & Ads are not under our control and we are not responsible for any Third Party Sites, Apps & Ads. We provide these Third Party Sites, Apps & Ads only as a convenience and do not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Sites, Apps & Ads. You use all Third Party Sites, Apps & Ads at your own risk. When you link to a Third Party Site, App & Ad, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third Party Sites, Apps & Ads. We may also work with advertising partners that may deliver advertisements to you on or off our Site based on your activities on the Site or within the Services.
                <br/><br/>
                Other Users. Each Service user is solely responsible for any and all of their User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content and we make no guarantees regarding the accuracy, currency, suitability, or quality of any User Content, and we assume no responsibility for any User Content. Your interactions with other Site or Service users are solely between you and such user. You agree that we will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Service user, we are under no obligation to become involved.
                <br/><br/>
                <span style={{color:'red'}}>App Store. When you download our Applications, you may do so through Apple Corporation’s App Store, another third party. You acknowledge that this Agreement is between you and us and not the App Store or Apple. As between the App Store and us, we, not the App Store, are solely responsible for the Services, including the Application, the content, maintenance, support services, and warranty, and addressing any claims relating thereto (e.g., product liability, legal compliance or intellectual property infringement). In order to use the Application, you must have access to a wireless network, and you agree to pay all fees associated with such access. You also agree to pay all fees (if any) charged by the App Store in connection with the Services, including the Application. You acknowledge that the App Store (and its subsidiaries) are third-party beneficiaries of the Agreement and will have the right to enforce its terms. The following applies to any Application accessed through or downloaded from the Apple App Store (<span className={classes.important}>“App Store Sourced Application”</span>):
                <br/><br/>
                You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App Store Sourced Application.
                <br/><br/>
                In the event of any failure of the App Store Sourced Application to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App Store Sourced Application to you, and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App Store Sourced Application.
                <br/><br/>
                You and we acknowledge that, in the event of any third-party claim that the App Store Sourced Application or your possession and use of that App Store Sourced Application infringes that third party’s intellectual property rights, as between Cuti and Apple, Cuti, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by the Agreement.
                <br/><br/>
                You and we acknowledge that, in the event of any third-party claim that the App Store Sourced Application or your possession and use of that App Store Sourced Application infringes that third party’s intellectual property rights, as between Cuti and Apple, Cuti, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by the Agreement.
                </span>
            </Typography>

            <Typography className={classes.header}>Interactions with Other Users</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                Users (whether clients or service providers) are solely responsible for their interactions with each other (including, in the case of clients, Interactions with service providers, and In the case of service providers, Interactions with clients), and any other parties with whom such user interacts; provided, however, that Cuti reserves the right, but has no obligation, to intercede in disputes. You agree that Cuti will not be responsible for any liability incurred as the result of such interactions including without limitation any result of receiving or not receiving any in-home beauty & wellness services through or in connection with the Services. YOU UNDERSTAND THAT CUTI DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS OR EVALUATE THE CREDENTIALS OF ANY USER. Cuti ALSO DOES NOT INQUIRE INTO THE BACKGROUNDS OF ITS USERS OR ATTEMPT TO VERIFY THE STATEMENTS OF ITS USERS. CUTI MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS, CONDITIONS OR ACCOMMODATIONS FOR RECEIVING OR PROVIDING SERVICES, OR ANY USERS’ COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS. Cuti RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES) AT ANY TIME AND USING AVAILABLE PUBLIC RECORDS.
            </Typography>

            <Typography className={classes.header}>Disclaimers</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                As Is. TO THE FULLEST EXTENT PERMITTED BY LAW, THE SERVICES, INCLUDING THE SITE AND THE APPLICATIONS, ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND WE (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SERVICES: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE OR (D) THAT THE IN-HOME beauty & wellness serviceS PROVIDED BY SERVICE PROVIDERS OR PRODUCTS WILL BE TO YOUR SATISFACTION. WE ARE NOT LIABLE FOR ANY DAMAGE A SERVICE PROVIDER MIGHT CAUSE WHILE ONSITE OR AT YOUR HOME OF OFFICE. YOU AGREE THAT APPOINTMENTS MAY CARRY INHERENT RISK, AND BY REQUESTING THE IN-HOME beauty & wellness serviceS, YOU CHOOSE TO ASSUME THOSE RISKS VOLUNTARILY. FOR EXAMPLE, THE IN-HOME beauty & wellness serviceS MAY CARRY RISK OF ILLNESS, BODILY INJURY, DISABILITY OR DEATH, AND YOU FREELY AND WILLFULLY ASSUME THOSE RISKS BY REQUESTING SUCH IN-HOME beauty & wellness serviceS. YOU ASSUME FULL RESPONSIBILITY FOR THE CHOICES YOU MAKE BEFORE, DURING AND AFTER YOUR APPOINTMENT.
                <br/><br/>
                No Liability for Conduct of Other Users. YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES, WHETHER ONLINE OR IN PERSON. YOU UNDERSTAND THAT CUTI DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS OF THE SERVICES. CUTI DOES NOT GUARANTEE OR WARRANT ANY EXPERT’S PERFORMANCE IN THE APPOINTMENT OR QUALITY OF THE IN-HOME BEAUTY & WELLNESS SERVICES. 
            </Typography>


            <Typography className={classes.header}>Limitation on Liability</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WE (AND OUR SUPPLIERS), OR THE SERVICE PROVIDERS, BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SERVICES OR PRODUCTS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SERVICES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHERWISE, OR LOSS OF DATA RESULTING THEREFROM.
                <br/><br/>
                TO THE FULLEST EXTENT PERMITTED BY LAW, OUR LIABILITY (AND THAT OF OUR SERVICE PROVIDERS AND SUPPLIERS) TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO THE GREATER OF (a) FIFTY US DOLLARS ($50) OR (b) AMOUNTS YOU’VE PAID CUTI IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT.
            </Typography>



            <Typography className={classes.header}>Term and Termination</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                Subject to this Section, this Agreement will remain in full force and effect while you use the Services. We may (a) suspend your rights to use the Site and/or Services (including your Account) or (b) terminate this Agreement, at any time for any reason at our sole discretion, including but not limited to, any use of the Services in violation of this Agreement or our discontinuance of any Services. Upon termination of this Agreement, your Account and right to access and use the Services will terminate immediately. You understand that any termination of your Account involves deletion of your User Content associated therewith from our live databases. We will not have any liability whatsoever to you for any termination of this Agreement, including for termination of your Account or deletion of your User Content. Upon termination of this Agreement, all of the provisions will terminate except those that by their nature should survive.
            </Typography>



            <Typography className={classes.header}>Copyright Policy</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                We respect the intellectual property of others and ask that users of our Site and Services do the same. In connection with our Site and Services, we have adopted and implemented a policy respecting copyright laws that provide for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our online Services who are repeat infringers of intellectual property rights, including copyrights. A repeat infringer is any user for whom we have received two or more takedown notices (however, we reserve the right to terminate any user who submits infringing content at any time). If you believe that one of our users is, through the use of our Services, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:
                <br/><br/>
                <ul>
                    <li>your physical or electronic signature;</li>
                    <li>identification of the copyrighted work(s) that you claim to have been infringed;</li>
                    <li>identification of the material on our Services that you claim is infringing and that you request us to remove;</li>
                    <li>sufficient information to permit us to locate such material;</li>
                    <li>your address, telephone number, and e-mail address;</li>
                    <li>a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</li>
                    <li>a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</li>
                </ul>
                <br/><br/>
                Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.
                <br/><br/>
                Our designated Copyright Agent is:
                <br/><br/>
                Antony Sanders
                <br/><br/>
                Cuti App, LLC
                <br/><br/>
                [INSERT STREET]
                <br/><br/>
                [INSERT CITY, STATE & ZIP]
                <br/><br/>
                [INSERT PHONE]
                <br/><br/>
                support@cutiapp.com
                <br/><br/>
            </Typography>



            <Typography className={classes.header}>Dispute Resolution</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>
                <strong><em>Please read the following arbitration agreement in this Section (“Arbitration Agreement”) carefully.  It requires you to arbitrate disputes with Cuti and limits the manner in which you can seek relief from us.</em></strong>
                <br/><br/>
                Applicability of Arbitration Agreement. You agree that any dispute, claim, or request for relief relating in any way to your access or use of the Website, to any products sold or distributed through the Website, or to any aspect of your relationship with Cuti, will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims or seek relief in small claims court if your claims qualify; and (2) you or Cuti may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents). <strong>This Arbitration Agreement shall apply, without limitation, to all disputes or claims and requests for relief that arose or were asserted before the effective date of this Agreement or any prior version of this Agreement.</strong>
                <br/><br/>
                Arbitration Rules and Forum. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.  To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your dispute or claim or request for relief to our registered agent: United Corporate Services, Inc., 874 Walker Road, Suite C, Dover, Delaware 19904.  The arbitration will be conducted by JAMS, an established alternative dispute resolution provider.  Disputes involving claims, counterclaims, or request for relief under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other disputes shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/.  JAMS’s rules are also available at www.jamsadr.com or by calling JAMS at 800-352-5267.  If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum.  If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, Cuti will pay them for you.  In addition, Cuti will reimburse all such JAMS’s filing, administrative, hearing and/or other fees for disputes, claims, or requests for relief totaling less than $10,000 unless the arbitrator determines the claims are frivolous. 
                <br/><br/>
                You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the country where you live or at another mutually agreed location. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                <br/><br/>
                Authority of Arbitrator. The arbitrator shall have exclusive authority to (a) determine the scope and enforceability of this Arbitration Agreement and (b) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to, any assertion that all or any part of this Arbitration Agreement is void or voidable.  The arbitration will decide the rights and liabilities, if any, of you and Cuti.  The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and the Agreement (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and us. 
                <br/><br/>
                Waiver of Jury Trial. YOU AND CUTI HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY.  You and Cuti are instead electing that all disputes, claims, or requests for relief shall be resolved by arbitration under this Arbitration Agreement, except as specified above.  An arbitrator can award on an individual basis the same damages and relief as a court and must follow this Agreement as a court would.  However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.  
                <br/><br/>
                Waiver of Class or Other Non-Individualized Relief. ALL DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.  If a decision is issued stating that applicable law precludes enforcement of any of this subsection’s limitations as to a given dispute, claim, or request for relief, then such aspect must be severed from the arbitration and brought into the State or Federal Courts located in the State of New York.  All other disputes, claims, or requests for relief shall be arbitrated. 
                <br/><br/>
                30-Day Right to Opt Out. You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt out to: info@CutiApp.com, within 30 days after first becoming subject to this Arbitration Agreement. Your notice must include your name and address, your Cuti username (if any), the email address you used to set up your Cuti account (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement.    If you opt out of this Arbitration Agreement, all other parts of this Agreement will continue to apply to you.  Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.
                <br/><br/>
                Severability. Except as provided in the “Waiver of Class or Other Non-Individualized Relief” subsection, if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect.
                <br/><br/>
                Survival of Agreement. This Arbitration Agreement will survive the termination of your relationship with Cuti.
                <br/><br/>
                Modification. Notwithstanding any provision in this Agreement to the contrary, we agree that if Cuti makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective by writing Cuti at the following address: Cuti App, llc., [INSERT ADDRESS HERE].
            </Typography>



            <Typography className={classes.header}>General</Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20, marginBottom:50}}>
                Changes to Agreement. This Agreement is subject to occasional revision, and if we make any material changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any) and/or by prominently posting notice of the changes on our Services. Any changes to this Agreement will be effective upon posting on the Services, or the date identified in the email or notice (if later). You are responsible for providing us with your most current e-mail address. In the event that the last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice. Continued use of our Services following notice of such changes will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.
                <br/><br/>
                Electronic Communications. The communications between you and Cuti may take place via electronic means, whether you visit the Services or send Cuti e-mails, or whether Cuti posts notices on the Services or communicates with you via e-mail.  For contractual purposes, you (a) consent to receive communications from Cuti in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Cuti provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be in writing.  The foregoing does not affect your statutory rights, including but not limited to the Electronic Signatures in Global and National Commerce Act at 15 U.S.C. §7001 et seq. (“E-Sign”).
                <br/><br/>
                Questions, Complaints, Claims.  If you have any questions, complaints or claims with respect to the Services, please contact us at: support@cutiapp.com.  We will do our best to address your concerns.  If you feel that your concerns have been addressed incompletely, we invite you to let us know for further investigation.
                <br/><br/>
                Choice of Law. This Agreement is made under and will be governed by and construed in accordance with the laws of the State of New York, consistent with the Federal Arbitration Act, without giving effect to any principles that provide for the application of the law of another jurisdiction.
                <br/><br/>
                Notice.  Where Cuti requires that you provide an e-mail address, you are responsible for providing Cuti with your most current e-mail address.  In the event that the last e-mail address you provided to Cuti is not valid, or for any reason is not capable of delivering to you any notices required/ permitted by the Agreement, Cuti’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice.  You may give notice to Cuti at the following address: Cuti App, llc, [INSERT ADDRESS HERE].  Such notice shall be deemed given when received by Cuti by letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail at the above address.
                <br/><br/>
                Entire Agreement. This Agreement constitutes the entire agreement between you and us regarding the use of the Services. Our failure to exercise or enforce any right or provision of this Agreement will not operate as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word including means including without limitation. If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of this Agreement will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Your relationship to us is that of an independent contractor, and neither party is an agent or partner of the other. This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without our prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. We may freely assign this Agreement. The terms of this Agreement will be binding upon assignees.
            </Typography>



            <Typography className={classes.header}></Typography>

            <Typography variant='body1' style={{width:'100%', marginTop:20,}}>

                <br/><br/>
            </Typography>




        </Grid>

    )
}

export default Terms

