import React, {useEffect} from "react";
import { useForm, useStep } from "react-hooks-helper";
import { Names } from "./stepForm/Names";
import { Account } from "./stepForm/Account";
import { ProDetails } from "./stepForm/ProDetails";
import { LicenseInfo } from "./stepForm/LicenseInfo";
import { Address } from "./stepForm/Address";
import { W9 } from "./stepForm/W9";
import { Banking } from "./stepForm/Banking";
import { FastPay } from "./stepForm/FastPay";
import { Review } from "./stepForm/Review";
import { Submit } from "./stepForm/Submit";

import {firestore} from '../../../config/fbConfig'
import {useSelector, useDispatch} from 'react-redux'

const defaultData = {
  firstName: "",
  lastName: "",
  title: "",
  address:"",
  coordinates:{lat:null, lng:null},
  phone: "",
  displayName:'',
  email: "",
  password: "",
  routingNumber: "",
  accountNumber:"",
  debitCard:"",
  expiration:"",
  cvc:"",
  ratings: [{createdAt: new Date(), rating: 5}],
  typesOfServices: [],
  services: [],
  accountStatus: "not active", //Available Statuses: onboarding, needs review, active, paused, not active, suspended
  isLicensed: "",
  isPartner: false,
  avatar: "",
  works:[],
  travel: false,
};

const steps = [
  // { id: "names" },
  { id: "account" },
  { id: "pro details" },
  // { id: "license info" },
  // { id: "address" },
  // { id: "w9" },
  // { id: "banking" },
  // { id: "fastpay" },
  { id: "review" },
  // { id: "submit" },
];

export const ProSignUp = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });
  
  const dispatch = useDispatch();
  const typesOfServices = useSelector(state => state.services.typesOfServices)
  const props = { formData, setForm, navigation };

  useEffect(()=>{
    let serv = []
    if(typesOfServices <= 0){
      firestore.collection("typesOfServices").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          serv.push(doc.id)
          dispatch({
            type: 'SET_TYPES_OF_SERVICES',
            payload: serv
          });
        })
    });

    }
  })

  switch (step.id) {
    // case "names":
    //   return <Names {...props} />;
    case "account":
      return <Account {...props} />;
    case "pro details":
      return <ProDetails {...props} />;
    // case "license info":
    //   return <LicenseInfo {...props} />;
    // case "address":
    //   return <Address {...props} />;
    // case "w9":
    //   return <W9 {...props} />;
    // case "banking":
    //   return <Banking {...props} />;
    //   case "fastpay":
    //   return <FastPay {...props} />;
    case "review":
      return <Review {...props} />;
    case "submit":
      return <Submit {...props} />;
    default:
      return (
          <div>
            <h1>Multi step form</h1>
          </div>
        );
  }

  
};