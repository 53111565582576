import React, {useState, useEffect} from 'react'
import {Container, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Button, TextField, TextareaAutosize} from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { firestore } from '../../../config/fbConfig';
const useStyles = makeStyles((theme) => ({
    infoContainer:{
        display:"flex",
        justifyContent:"center",
        padding: "10px",
        borderTop: 'solid 1px #d3d3d3',
    },
    title:{
        width:'100%',
        textAlign:'center',
        fontWeight:'bold',
        heigh:'fit-content'
    },
    header:{
        fontWeight:'bold',
        width:'100%',
        margin:'50px 0px 0px',
        fontSize:30,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary.main,
      },
    section:{
      borderRadius: 12,
      border:'1px solid lightgray',
      padding:10,
      marginBottom: 10,
      textDecoration:'none',
      color: 'black',
      "&:hover":{
        cursor:'pointer',
        border:`2px solid ${theme.palette.primary.main}`,
        backgroundColor:'#e6e6e6',
      },
    },
    important:{
        fontWeight:'bold',
        testTransformation:'uppercase',
    },
    link: {
      textDecoration:'none',
      color:'black',
      fontSize:24,
      '&:hover':{
          cursor:'pointer',
          color: theme.palette.primary.main
      },
      
    },
  }));

function FAQs() {
    const classes = useStyles()
    const [expanded, setExpanded] = useState(false);
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [success, setSuccess] = useState(false);
    const disabled = (email === '' || question === '' || success===true) ? true : false

    const handleSubmit = () => {
        setSuccess(true)
        firestore.collection('adminNotifications').add({
            content:question,
            user:email,
            createdAt: new Date(),
            typeOfUser:'Inquirer',

        }).then(() => {
            setEmail('')
            setQuestion('')
        })

    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

      useEffect(() => {
      }, [success])

    return (
        <Grid item container xs={12} className={classes.infoContainer}>
            <Typography variant='h2' className={classes.title}>FAQs</Typography>

            <Grid item container xs={12} md={6}>
                <Typography variant='h5' style={{fontWeight:'bold', marginTop:30}}>Getting Started</Typography>
                <Accordion expanded={expanded === 'panel-gs-1'} onChange={handleChange('panel-gs-1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography >What is Cuti App?</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: Cuti App is a FREE, instant and on demand Beauty and Wellness Services platform to help independent professionals book, organize, and find new clients.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel-gs-2'} onChange={handleChange('panel-gs-2')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography >How much does Cuti App cost?</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: While listed service prices may vary from professional to professional, our platform, is 100% free to use. No subscriptions, no hidden fees. As a client/customer, you may however incure a small booking fee.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Typography variant='h5' style={{fontWeight:'bold', marginTop:30}}>Appointments</Typography>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography >How do I schedule an appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: Scheduling is made easy. Just fill out the “Schedule an Appointment” form located on your homepage, entering information like time, date, location and other details.  
                                You may also book directly from a professional's profile by selecting the service, then filling out the pop up form.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >How do I cancel my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: Once you've scheduled an appointment, it will appear in the Upcoming Appointments section on the homepage. To cancel, simply click view details and select cancel my appointment. You'll be prompted to leave a reason for the cancellation. It's not mandatory, but it's a courtesy to inform your professional about the reason for cancellation.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >What if nobody shows up to my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: Please allow professionals a 15-minute window to arrive for the scheduled appointment. We understand that time is of the essence, unfortunately we can't control unexpected occurrences like traffic. If 15 minutes has passed and no one has yet to show, you may cancel your appointment without penalty By selecting the option “My professional has not arrived”.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >How do I modify my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: As it stands, the only thing you can modify is the time and/or date of an appointment that has been scheduled. 
                            </Typography>
                        </AccordionDetails>
                </Accordion>
                
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >How do I reschedule my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: In order to modify your appointment time, select “view details” and then select “reschedule appointment”. If your appointment is less than 12 hours away, you will not have the ability to reschedule.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >Will I receive a notification when the Professional is on their way?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: If your calendar is connected, You will receive a notification via your calendar that your appointment is about to start.  You may check with your professional via the message tab for updates on their location and arrival time.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >Will I receive a confirmation email for my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: Yes. Once your appointment has been confirmed, you will receive an email with all of the appointment details. 
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >Will I receive a reminder before my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: If your calendar is connected, You will receive a notification via your calendar/email that your appointment is about to start.
                            </Typography>
                        </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >What if I’m running late for my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: We get it, life happens. That's why we allow you a 15 minute window to arrive to your appointment without penalty. Use the message tab to communicate with your professional and let them know that you'll be arriving late. If however you will be later than 15 minutes, the professional reserves the right to cancel your appointment, so it's important to be on time! If your appointment is cancelled, you risk losing your deposit. We review these instances on a case-by-case basis. 
                            </Typography>
                        </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >How do I prepare for my appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: Safety and efficiency are our utmost concern. If a professional is providing services at your location, ensure that the workspace is clean and well-lit with access to a nearby outlet. If you have pets and or children, ensure that  they will not interrupt service as this could cause a safety hazard.  Refer to your professional via messages for any additional preparations that you will need to make. 
                            </Typography>
                        </AccordionDetails>
                </Accordion>



                <Typography variant='h5' style={{fontWeight:'bold', marginTop:30}}>Account Management</Typography>
                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography>I’m not seeing my email notifications.</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A:  The first thing to do is to  Ensure you have email notifications turned “On” inside the notifications settings. All email notifications come from support@cutiapp.com. Check your ‘spam’ and ‘promotions’ folder(s).  If you find that your emails have been sent to the spam folder in error, mark them as ‘not spam’. For gmail users: If your emails are being sent to the promotions folder, from your browser, simply click and drag that email into the primary folder. Gmail will then ask you if you wish for all future emails from this sender to come to your primary folder, select ‘yes’. 
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >How do I turn on/off email notifications?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: To enable or disable email notifications, simply navigate to your notification settings from the sidebar menu and click the toggle button on or off for the particular notifications you wish to receive.
                            </Typography>
                        </AccordionDetails>
                </Accordion>



                <Typography variant='h5' style={{fontWeight:'bold', marginTop:30}}>Notifications</Typography>
                <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography>I’m not seeing my email notifications.</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A:  The first thing to do is to  Ensure you have email notifications turned “On” inside the notifications settings. All email notifications come from support@cutiapp.com. Check your ‘spam’ and ‘promotions’ folder(s).  If you find that your emails have been sent to the spam folder in error, mark them as ‘not spam’. For gmail users: If your emails are being sent to the promotions folder, from your browser, simply click and drag that email into the primary folder. Gmail will then ask you if you wish for all future emails from this sender to come to your primary folder, select ‘yes’. 
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                     <Typography >How do I turn on/off email notifications?</Typography>
                    </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A: To enable or disable email notifications, simply navigate to your notification settings from the sidebar menu and click the toggle button on or off for the particular notifications you wish to receive.
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Typography variant='h5' style={{fontWeight:'bold', marginTop:30}}>Payments, Refunds & More</Typography>
                <Accordion expanded={expanded === 'refunds-panel-1'} onChange={handleChange('refunds-panel-1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography>Payments for Service</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A:  You can pay your professional directly via credit card. When your appointment has been started you will be able to check out by paying all balances due, and you'll be good to go! 
                                {/* If you'd prefer to pay in cash, just let your pro know before your appointment ends and they will handle it from there. */}
                                <br/><br/>
                                {/* <strong>Adding a credit card when you book:</strong>
                                <br/><br/>
                                <div style={{marginLeft:5}}>
                                - When you book a service, your credit card information is stored in your profile. 
                                - Professionals can manually charge your card on file after the appointment. 
                                - If you pay CASH, your pro needs to "check out" the appointment as CASH. 
                                </div> */}

                                <strong>PLEASE NOTE:</strong> 
                                <br/><br/>
                                <div style={{marginLeft:5}}>
                                - Per our No-Show/Late Cancelation policy, you may lose your deposit if you attempt to cancel your appointment within 24 hours of your appointment time.
                                <br/><br/>
                                - To prevent this, ensure that you cancel your appointment through the app with more than 24 hours notice.
                                <br/><br/>
                                - All booking fees are non-refundable and will not be applied to future appointments in the case of a canceled appointment.
                                </div> 
                                <br/><br/>
                                <strong>Tipping</strong>
                                <br/><br/>
                                <em>Although tipping is encouraged. Cuti App does not support in app tipping at this time, but we assure you that this is a feature soon to come!</em>
                            </Typography>
                        </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'refunds-panel-2'} onChange={handleChange('refunds-panel-2')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography>What If I Cancel Late Or Do Not Show Up To My Appointment?</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                A:  If you cancel late or do not show up to your appointment, you will forfeit your deposit and will not be eligible for a refund. 
                                <br/><br/>
                                <strong>NOTE:</strong> 
                                <br/><br/>
                                <div style={{marginLeft:5}}>
                                - Cuti App only provides the software that allows professionals to set up a list of services, manage appointments, accept online bookings, and charge or not charge for a service etc.
                                <br/><br/>
                                - All charges are directly collected by Cuti and redistributed to your professional. Cuti may issue refunds in it's sole disgression prior to the closing of an appointment. After an appointment has been closed, Cuti will no longer be responsible for your refund. Please contact your professional directly to handle all refunds at that time.
                                </div> 
                                <br/><br/>
                            </Typography>
                        </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'refunds-panel-3'} onChange={handleChange('refunds-panel-3')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography>Booking Fees</Typography>
                    </AccordionSummary>
                        <AccordionDetails style={{display:'flex', flexFlow:'column'}}>
                            <Typography style={{margin:'20px 20px 0px 20px'}}>
                                In order to power new and innovative features, all appointments paid through Cuti App will include a small service fee. The fee will be a percentage of the total cost of the service, excluding tax and you can see the fees listed on the checkout screen so you know exactly what you’re paying before you’re charged. 
                                
                                <br/><br/>
                            </Typography>
                        </AccordionDetails>
                </Accordion>


                <Grid item container xs={12} style={{margin:'50px 0px', display:'flex', justifyContent:'flex-end'}}>
                    <Typography style={{width:'inherit', marginBottom:20}}>Don't see your question? Submit one here:</Typography>
                    <TextField
                        label="Email"
                        inputMode="email"
                        //onKeyPress={(e)=>{validateCC(e)}}
                        placeholder='Email Address'
                        name="email"
                        value={email}
                        onChange={(e)=> setEmail(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                    />
                    <Typography style={{width:'100%'}}>Question:</Typography>
                    <TextareaAutosize
                        label="question"
                        style={{width:'inherit', borderRadius:4, borderColor:'silver', padding:10, fontSize: 16, font:'inherit'}}
                        rowsMax={2}
                        rowsMin={2}
                        placeholder='Type your inquiry here...'
                        value={question}
                        onChange={(e)=> setQuestion(e.target.value)}
                    
                    />
                    <Button onClick={() => {handleSubmit(); setTimeout(() => {setSuccess(false)}, 3000)}} disabled={disabled} variant='contained' color='primary' style={{marginTop: 10}}>Submit</Button>
                    {success === true &&
                    <Typography style={{width:'100%', color:'green', marginTop:10, textAlign:'center'}}>Success, Your query has been submitted!</Typography>
                    }
                </Grid>

                
                
            </Grid>
        </Grid>
    )
}

export default FAQs

