import React, {useEffect} from "react";
import { useForm, useStep } from "react-hooks-helper";
import { Account } from "./stepForm/Account";
import { ProDetails } from "./stepForm/ProDetails";
import { Review } from "./stepForm/Review";

import {firestore} from '../../../../config/fbConfig'
import {useSelector, useDispatch} from 'react-redux'

const defaultData = {
  firstName: "",
  lastName: "",
  title: "",
  address:"",
  coordinates:{lat:null, lng:null},
  phone: "",
  displayName:'',
  email: "",
  password: "",
  routingNumber: "",
  accountNumber:"",
  debitCard:"",
  expiration:"",
  cvc:"",
  ratings: [{createdAt: new Date(), rating: 5}],
  typesOfServices: [],
  services: [],
  accountStatus: "not active", //Available Statuses: onboarding, needs review, active, paused, not active, suspended
  isLicensed: "",
  isPartner: false,
  avatar: "",
  works:[],
  travel: false,
};

const steps = [
  { id: "account" },
  { id: "pro details" },
  { id: "review" },
];

export const ProSignUp = ({pro}) => {
  const defaultData = {
    firstName: pro.firstName,
    lastName: pro.lastName,
    title: pro.title,
    address:"",
    coordinates:{lat:null, lng:null},
    phone: pro.phone,
    displayName: pro.displayName,
    email: pro.email,
    password: "",
    ratings: [{createdAt: new Date(), rating: 5}],
    typesOfServices: [],
    services: [],
    accountStatus: "not active", //Available Statuses: onboarding, needs review, active, paused, not active, suspended
    isLicensed: "",
    isPartner: false,
    avatar: pro.images.avatar,
    works:[],
    travel: false,
  };

  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });
  
  const dispatch = useDispatch();
  const typesOfServices = useSelector(state => state.services.typesOfServices)
  const props = { formData, setForm, navigation, pro };

  useEffect(()=>{
    let serv = []
    if(typesOfServices <= 0){
      firestore.collection("typesOfServices").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          serv.push(doc.id)
          dispatch({
            type: 'SET_TYPES_OF_SERVICES',
            payload: serv
          });
        })
    });

    }
  })

  switch (step.id) {
    case "account":
      return <Account {...props} />;
    case "pro details":
      return <ProDetails {...props} />;
    case "review":
      return <Review {...props} />;
    default:
      return (
          <div>
            <h1>Multi step form</h1>
          </div>
        );
  }

  
};