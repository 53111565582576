import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Typography, Grid, Button, Avatar, Modal, ClickAwayListener, withWidth, IconButton} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import haversine from "haversine-distance"
//Imports for the Profressional Profile Preview
import Slider from '../dashboard/settings/Account/proProfile/Slider'
import CloseIcon from '@material-ui/icons/Close';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StarIcon from '@material-ui/icons/Star';
import SimpleBarReact from 'simplebar-react';
import "simplebar/src/simplebar.css";
import { firestore } from '../../config/fbConfig';
import firebase from 'firebase'

const useStyles = makeStyles((theme) => ({
    root: {
      border:'1px solid gray',
      // height: 76,
      marginBottom: 5,
      background: 'white',
      '&:hover': {
        cursor: 'pointer',
      }
      },

  }));
  


function SelectProCard({userData, service, pro, formData, rating, distance, width}) {

  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(false)
  const [favorite, setFavorite] = useState(null)
  const [isBorderPink, setIsBorderPink] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const selectedBorder = !!isBorderPink ? "3px solid #413e68" : "1px solid grey"
  
  const avatarSize = (width === 'xs' || width === 'sm') ? 40 : 60
  const fontSize = (width === 'xs' || width === 'sm') ? 12 : 16
  const boxHeight = (width === 'xs' || width === 'sm') ? null : 76
  const priceAlign = (width === 'xs' || width === 'sm') ? 'center' : 'right'

  const avatarSize2 = (width === 'xs' || width === 'sm') ? 40 : 50
  const fontSize2 = (width === 'xs' || width === 'sm') ? 12 : 16
  const boxHeight2 = (width === 'xs' || width === 'sm') ? 300 : 152
  const height = (width === 'xs' || width === 'sm') ? '50%' : '100%'
  const padding = (width === 'xs' || width === 'sm') ? '2px 15px' : '4px'

  const handleSelect = () => {
    if(isSelected === false){
      setIsSelected(true)
      setIsBorderPink(true)
      formData.priceVaries = service.priceVaries !== undefined ? service.priceVaries : false
      formData.pro = {
          name: pro.firstName + " " + pro.lastName,
          email: pro.email,
          phone: pro.phone,
          address: pro.address,
          userId: pro.userId,
          title: pro.title,
          coordinates: pro.coordinates,
          rating: pro.ratings.reduce(function (previousValue, currentValue) {return previousValue + currentValue.rating}, 0) / pro.ratings.length,
          avatar: pro.images.avatar,
          stripeId: pro.stripeId}
          formData.includeBooking = pro.includeBooking
          formData.serviceCost = service.price
          formData.serviceTimeEnd = service.time
          formData.reqDeposit = (service.reqDeposit !== null && service.reqDeposit !== undefined) ? service.reqDeposit : false
      if(parseFloat(service.price) <= 99.99){
        formData.bookingFee = "5.99"
      }else{
        formData.bookingFee = (parseFloat(service.price)*.06).toFixed(2)
      }

      if(formData.location === 'pro location'){
        formData.address = pro.address
        formData.startingCoordinates2 = pro.coordinates
        // console.log(formData.startingCoordinates2)
      }

      if(formData.startingCoordinates === pro.coordinates || formData.location === 'pro location'){
        formData.travelFee = '0.00'
      }
      else if(formData.startingCoordinates !== pro.coordinates && pro.convenienceFeeReq === true){
        if(pro.convenienceFee.includes('$')){
          formData.travelFee = pro.convenienceFee.split('$')[1]
        }else if(pro.convenienceFee.includes('%')){
          formData.travelFee = (parseFloat("." + pro.convenienceFee.split('%')[0]) * parseFloat(service.price)).toFixed(2)
        }
      }
      else{
        formData.travelFee = (parseFloat(distance)*0.75).toFixed(2)
      }
     console.log(formData)
    } else {
      setIsSelected(false)
      //formData.pro = null
    }
    // console.log(`the Distance in miles is: ${distance}. and the price is ${formData.travelFee}`)
  }

  const handleClickAway = () => {
    setIsBorderPink(false)
    setIsSelected(false)
  }

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen)
  }

  const addToFaves = () => {
    
    firestore.collection(userData.collection).doc(userData.userId).update({
      favorites: firebase.firestore.FieldValue.arrayUnion(pro.email)
    }).then(
      setFavorite(true)
    )
  }

  const removeFromFaves = () => {
    firestore.collection(userData.collection).doc(userData.userId).update({
      favorites: firebase.firestore.FieldValue.arrayRemove(pro.email)
    }).then(
      setFavorite(false)
    )
  }

  useEffect(() => {
    const {favorites} = userData
    if(favorite===null && favorites.includes(pro.email)){
      setFavorite(true)
    }
    if(favorite===null && !favorites.includes(pro.email)){
      setFavorite(false)
    }

  }, [userData, favorite, pro])



    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        
        <Grid container item xs={12} style={{border: selectedBorder, borderRadius:12, height:boxHeight2, padding:3, marginBottom:10}}>

          <Grid item container xs={12} md={6} style={{position:'relative', padding: 2, backgroundColor:'white', height:height}}>
            <Grid container item xs={12} style={{height:'50%'}}>
              
            <Grid item xs={4} onClick={handleSelect} style={{display:'flex', justifyContent:'center', alignItems:'center', padding:5}}>
              <Avatar style={{width:avatarSize2, height:avatarSize2}} src={pro.images.avatar}/>
            </Grid>

            <Grid item xs={8} onClick={handleSelect} style={{display:'flex', flexFlow:'column', justifyContent:'center'}}>
              {pro.showDisplay !== undefined && pro.displayName !== undefined && pro.showDisplay === false ?
                  <Typography style={{fontWeight: 'bold', fontSize:fontSize}}>{pro.firstName+' '+pro.lastName.charAt(0)+'.'}   <span style={{fontWeight: 'normal'}}>({rating})</span> </Typography>
                :
                  <Typography style={{fontWeight: 'bold', fontSize:fontSize}}>{pro.displayName}   <span style={{fontWeight: 'normal'}}>({rating})</span> </Typography>
                }
              {/* <Typography style={{fontWeight: 'bold', fontSize:fontSize}}>{pro.firstName + " " + pro.lastName.charAt(0)+'.'}  <span style={{fontWeight: 'normal'}}>({rating})</span> </Typography> */}
              <Typography style={{fontSize: 10}}>{pro.title}</Typography>
              <Typography style={{fontSize: 10}}>{distance} mi(s).</Typography>
            </Grid>
              
            </Grid>
            <Grid container item xs={12} style={{height:'50%', display:'flex', alignItems:'center'}}>
              <Grid container item xs={6} stlye={{justifyContent:'space-around', display:'flex'}}>
                {favorite === true ?
                  <IconButton onClick={removeFromFaves}><FavoriteIcon style={{color:'red'}}/></IconButton>
                  :
                  <IconButton onClick={addToFaves}><FavoriteBorderIcon/></IconButton>
                }
                <IconButton onClick={handleModalOpen}><VisibilityIcon/></IconButton>
              </Grid>

              <Grid container item xs={6} onClick={handleSelect} style={{display:'flex', justifyContent:'center'}}>
                <Typography style={{fontSize: 26, fontWeight: 'bold', width:"100%", textAlign:'center'}}>${service.price}</Typography>
                {(service.priceVaries !== undefined && service.priceVaries === true) &&
                <Typography style={{fontSize: 12, width:"100%", textAlign:'right'}}>( and up )</Typography>
                }
              </Grid>
            </Grid>
            
            {(formData.location !=="pro location" && pro.convenienceFee !== '') &&
            <Typography variant='caption' style={{position:'absolute', bottom:0, right:20}}>Travel Fee: {pro.convenienceFee}</Typography>
            }

          </Grid>

          <Grid item container xs={12} md={6} style={{height:height, padding:padding}}>
              {pro.images.works &&
                  <Slider images={pro.images.works}/>    
              }
          </Grid>

          <Modal open={!!isModalOpen} onClose={handleModalOpen}>
            <Grid item container xs={11} md={8} lg={5} style={{width: 845, height: 'fit-contnet', maxHeight:600, background: 'white', position: 'absolute', display: 'flex', flexFlow:'column',
                          top: '50%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius:'12px', outline:'none', overflowY:'scroll', padding:20}}>
              <Grid item container xs={12} style={{display:'flex', flexFlow:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Typography style={{fontSize:'24px', fontWeight:'bold'}}>Services</Typography>
                <IconButton onClick={handleModalOpen}><CloseIcon/></IconButton>
              </Grid>
              {pro && pro.services.map((service,idx) => {
                return(
                  <Grid key={idx} container item xs={12} style={{marginBottom:10, display:'flex', height:'fitcontent', alignItems:'center', backgroundColor:'white', borderRadius:5, padding:8, border:'1px solid lightgray'}}>
                    <Grid item contianer xs={12}>
                      <Typography style={{fontWeight:'bold'}}>{service.name} - ${service.price}</Typography>
                      <Typography style={{fontSize:12, color:'grey'}}>{service.typeOfService} - {service.time}mins</Typography>
                      <Typography style={{width:'inherit', fontSize:'10px', color:'silver'}}>{service.details}</Typography>
                    </Grid>


                </Grid>
                )
              })

              }
            </Grid>
          </Modal>
        </Grid>

      
      </ClickAwayListener>
    )
}

SelectProCard.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(SelectProCard)
