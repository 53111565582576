import React, { useState, useEffect } from 'react'
import {Typography, Grid, Button, Snackbar, CircularProgress} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { numberDependencies } from 'mathjs';
import moment, { weekdays } from 'moment';
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useStripe, useElements } from '@stripe/react-stripe-js';


const useStyles = makeStyles((theme) => ({
    root: {
      border:`1px solid ${theme.palette.secondary.dark}`,
      minHeight: 90,
    },
    title:{
        fontWeight: 'bold',
        fontSize: 10,
        color: theme.palette.secondary.dark
    },
    balance:{
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: 1,
    },
    balanceWrapper:{
        display: 'flex',
        flexFlow:'column',
        justifyContent: 'center',
        paddingLeft: 20,
    },
    btnWrapper:{
        display: 'flex',
        justifyContent:'flex-end',
        alignSelf: 'center',
        paddingRight: 20
    },
    btn:{
        color: 'white',
        fontWeight:'bold',
        backgroundColor: theme.palette.primary.main,
        height: 'fit-content',
        padding: 10
    },
    wrapper: {
        height: '100%',
        width: 'fit-content',
        position: 'relative',
      },
    buttonDisabled: {
        backgroundColor: theme.palette.primary.secondary,
      },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
  }));



  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function CurrentBalance({appointments, userData}) {
    const classes = useStyles()
    //const appointments = useSelector(state => state.appt.appointments)
    const [amount, setAmount] = useState(0)
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const thisSunday = moment().startOf('week').toDate()
    const [weekDates, setWeekDates] = useState([])
    const [stripeBalance, setBalance] = useState(null)
    const [stripePayoutType, setPayoutType] = useState(null)
    const [loading, setLoading] = useState(false)
    const [currWeekAppointments, setCurrWeekAppointments] = useState([])
    const [revealed, setReveal] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openErr, setOpenErr] = useState(false)
    const severity = ["error", "success"]
    const stripe = useStripe();
    const elements = useElements();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSuccess(false);
        setOpenErr(false);
        setBalance(null)
      };


    
    useEffect(() => {
        let nums = []
        let week =[]

        const getBalance = async () => {
            try {
                const response = (userData && userData.test === false) ? await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/get-balance', {
                    params:  {stripeId: userData.stripeId}
                })
                :
                await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/get-balance-test', {
                    params:  {stripeId: userData.stripeId}
                })
                if(response.data.success){
                    // console.log(response.data)
                    setBalance(response.data.balance.instant_available[0].amount / 100)
                }else{
                    console.log("Failure Balance retrieval")
                    setBalance(0)
                }       
            } 
            catch (error) {
                console.log("This is the Error:", error)
            }
        }

        const getPayoutType = async () => {
            try {
                const response = (userData && userData.test === false) ? await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/retrieve', {
                    params:  {acctId: userData.stripeId}
                })
                :
                await axios.get('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/retrieve-test', {
                    params:  {acctId: userData.stripeId}
                })
                if(response.data.success){
                    // console.log(response.data)
                    setPayoutType(response.data.account.external_accounts.data[0].object)
                }else{
                //   console.log("Failure Balance retrieval")
                    setBalance(0)
                }   
            } catch (error){
                // console.log('error getting account:', error)
            }
        }


        if(stripeBalance === null){
            getBalance()
        }

        if(stripePayoutType === null){
            getPayoutType()
        }

        if(appointments.length !== 0 && loading === false && revealed === false){
            //// console.log('a')
            setLoading(true)
        }

        if(appointments.length !== 0 && loading === true && weekDates.length <= 0){
           // // console.log('b')
            setLoading(false)
            setReveal(true)
            for (var i = 0; i < 8; i++) {
                week.push(
                    new Date(thisSunday)
                ); 
                thisSunday.setDate(thisSunday.getDate() +1);
                setWeekDates(week);
            }
        }

    if((loading === false) && (weekDates.length === 8) && revealed===true){
        console.log('c')
            const completedappts = appointments.filter(appt => appt.serviceComplete === true && appt.clientStatus === "paid")
            setCurrWeekAppointments(completedappts.filter(x => (new Date(x.serviceTimeEnd.seconds*1000) >= new Date(weekDates[0])) && (new Date(x.serviceTimeEnd.seconds*1000) < new Date(weekDates[7]))))
            setLoading(true)
        
    }
    
    if (currWeekAppointments.length > 0 && loading===true){
        //// console.log('d')
        setLoading(false)
        setReveal(null)
        currWeekAppointments.map((appt) => {
            const {serviceFee, serviceFeeTravel, serviceComplete} = appt
            if(serviceComplete === true){
                    const total = parseFloat(serviceFee) + parseFloat(serviceFeeTravel)
                    nums.push(total)
                    setAmount(nums.reduce(reducer))
            }
        })
    }

    // console.log("CURR WEEK APPTS",currWeekAppointments)
    // console.log("STRIPE BALANCE",stripeBalance)
    // console.log("STRIPE PAYOUT TYPE",stripePayoutType)

    },[appointments, currWeekAppointments, loading, thisSunday, weekDates, revealed, stripeBalance, stripePayoutType])


    const handleWithdraw = async () => {
        const response = await axios.post('https://us-central1-cuti-app-7c963.cloudfunctions.net/app/withdraw', {
            amount: stripeBalance * 100,
            stripeId: userData.stripeId
        })

        if(response.data.success){
            // console.log(response.data)
            setOpenSuccess(true)
        }else{
            setOpenSuccess(true)
            // console.log('something went wrong:', response.data)
        }

        
    }
    

    return (
        <Grid container item xs={12} className={classes.root}>
            <Grid item xs={6} className={classes.balanceWrapper}>
                <Typography className={classes.title}>Current Available Balance</Typography>
                {stripeBalance !== null ?
                <Typography className={classes.balance}>${stripeBalance.toFixed(2)}</Typography>
                :
                <Typography className={classes.balance}>loading...</Typography>
                }
    
            </Grid>
            <Grid item xs={6} className={classes.btnWrapper}>       
                    {/* <Button className={classes.btn} component={Link} to="/home/earnings">View</Button>     */}
                    {stripePayoutType !== null ?
                    stripePayoutType === 'bank_account' ?
                    <Button className={classes.btn} component={Link} to="/home/earnings">View</Button>    
                    :
                    <Button className={classes.btn} onClick={handleWithdraw} disabled={stripeBalance === 0 || stripeBalance === null}>Withdraw</Button> 
                    :
                    <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonDisabled}
                          fullWidth
                          disabled
                        >
                          Loading..
                        </Button>
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      </div> 
                    }
            </Grid>
            {stripeBalance !== null &&
            <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[1]}>
                    ${stripeBalance} has successfull been transfered! 💸
                </Alert>
            </Snackbar>
            }
            <Snackbar open={openErr} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity[0]}>
                Oops, something went wrong...
                </Alert>
            </Snackbar>
        </Grid>
    )
}
