import React from 'react'
import {Container, Grid, Typography, CircularProgress, withWidth, Hidden, Button} from '@material-ui/core'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router';
import ReactPlayer from 'react-player/lazy'

const useStyles = makeStyles((theme) => ({
    infoContainer:{display:"flex",
      justifyContent:"center",
      padding: "10px",
      overflowY: "hidden scroll",
      borderTop: 'solid 1px #d3d3d3',
    },
    title:{
        width:'100%',
        textAlign:'center',
        fontWeight:'bold',
    },
    header:{

    },
    section:{
      borderRadius: 12,
      border:'1px solid lightgray',
      padding:10,
      marginBottom: 10,
      textDecoration:'none',
      color: 'black',
      "&:hover":{
        cursor:'pointer',
        border:`2px solid ${theme.palette.primary.main}`,
        backgroundColor:'#e6e6e6',
      },
    },
    link: {
      textDecoration:'none',
      color:'black',
      fontSize:24,
      '&:hover':{
          cursor:'pointer',
          color: theme.palette.primary.main
      }
    },
  }));

function ProOnboarding() {
    const classes = useStyles()
    const width = 'inherit'
    const height = width*0.5625
    // console.log('height:', height)
    return (
        <Grid item container xs={12} className={classes.infoContainer}>
            <Typography variant="h2" className={classes.title}>Pro Onboarding</Typography>

            <Typography style={{width:'100%', textAlign:'center', marginTop:40}}>
              Cuti App was designed to allow professionals in the Beauty and Wellness spaces to list their services, get booked, and manage their appointments with increased flexibilty.
              Use the video guides below to help you get started!
            </Typography>

            <Grid item container xs={12} md={6} style={{display:'flex', justifyContent:'center'}}>
            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>Getting Started: Video</Typography>
              <ReactPlayer url='https://youtu.be/9ZhvPeGja6w' width={width} playing={false} loop={true} volume={0.15} controls={true}/>

            <Typography style={{width:'100%', textAlign:'center', marginTop:20}}>
              Onboarding with Cuti App was made simple. Use the video reference as a simple guide to help you navigate the process.
             <br/>
             <br/>
              <strong>Note:</strong> We use Stripe as our payment processor. When signing up, you can provide Bank Account or Debit Card details. 
            Accounts with Debit Cards attached will have instant withdrawl capabilities. Those with Bank Accounts will be on a weekly payout schedule. Our platform 
            also allows you to accept cash payments if you prefer. 
            </Typography>

            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>Managing Avaialability & Services</Typography>
              <ReactPlayer url='https://youtu.be/Z4Wb-EFBxSA' width={width} playing={false} loop={true} volume={0.15} controls={true}/>

            <Typography style={{width:'100%', textAlign:'center', marginTop:20}}>
              Editing your availabilty is seamless. By simply navigate to <strong>Settings > Availability</strong>, or clicking "Edit" from the Profile page, 
              click on any day (that's not grayed out) and add a new "interval". You can add multiple intervals per day, and can even apply the changes to multple days. 
              Watch the video above for all the tips and tricks!
            </Typography>

            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>Manage Your Profile</Typography>
              <ReactPlayer url='https://youtu.be/9Y4Z2s9ARbM' width={width} playing={false} loop={true} volume={0.15} controls={true}/>

            <Typography style={{width:'100%', textAlign:'center', marginTop:20}}>
              Your profile is your bread and butter! Most of the editing you will do to your profile will be done on the profile page, however, there are other features you can 
              add to your page from the <strong>My Account</strong> page <em>(i.e. displaying your work location, phone number, and email)</em>. Watch the video above 
              for all the tips and tricks!
            </Typography>

            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>Managing Appointments</Typography>
              <ReactPlayer url='https://youtu.be/muZHCHyKk40' width={width} playing={false} loop={true} volume={0.15} controls={true}/>

            <Typography style={{width:'100%', textAlign:'center', marginTop:20}}>
              Our goal is to make managing your appointments as simple as possilble. You can view, start and complete appointments right from the home screeen. If you need
               reschedule or cancel an appointment, you can do that with a click of a button as well! 
            </Typography>

            <Typography variant='h5' style={{fontWeight:'bold', marginTop:50, width:'100%', textAlign:'center'}}>Earnings & Payouts</Typography>
              <ReactPlayer url='https://youtu.be/O7IY18ZcFOc' width={width} playing={false} loop={true} volume={0.15} controls={true}/>

            <Typography style={{width:'100%', textAlign:'center', marginTop:20}}>
              You can view your weekly earnings right from the home screen. You can see a more in depth view by navigating to <strong>Earnings {'>'} View Stripe Dashboard</strong>.
              <br/>
              <br/>
              <strong>Note:</strong> We use Stripe as our payment processor. All transactions take 2 business days to process and will be in a pending status until then. 
            Accounts with Debit Cards attached will have to manually initiate withdrawls (payouts are instant). Those with Bank Accounts will be on a weekly payout schedule. Our platform 
            also allows you to accept cash payments if you prefer. 
            </Typography>


            <Button variant='contained' color='primary' style={{fontWeight:'bold', marginTop:50, marginBottom:80, textAlign:'center'}}onClick={() => window.location.assign('/signup')}>Get Started</Button>
            
            
            </Grid>
        </Grid>
    )
}

export default ProOnboarding
