export const createAppointment = (appointment) => {
    return (dispatch, getState) => {
        //make async call to database
        dispatch({ type: 'CREATE_APPOINTMENT', appointment })
    }

};

export const setAppointments = (appointments) => {
    return {
        type: 'SET_APPOINTMENTS',
        payload: appointments
    }

}

export const setConversationId = (conversationId) => {
    return {
        type: 'SET_CONVERSATION_ID',
        payload: conversationId
    }

}