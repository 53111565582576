import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { ServiceSelect } from "./01ServiceSelect";
import { Address } from "./02Address";
import { Pets } from "./03Pets";
import  Notes from "./03Notes";
import  SelectAPro  from "./04SelectAPro";
import Review from "./05Review";
import { Checkout } from "./06Submit";
import AppointmentTips from "./07AppointmentTips";

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripePromiseTest = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)

const defaultData = {
  service: "",
  serviceCost: "",
  dateTime: null,
  address: "",
  havePets: '',
  petTypes: "",
  notes: "",
  client: null,
  pro: null,
  bookingFee: "",
  travelFee: "",
  location: '',
  reqDeposit: false,
  priceVaries: false,
};

const steps = [
  { id: "service select" },
  { id: "address" },
  { id: "pets" },
  { id: "notes" },
  { id: "select a pro" },
  { id: "review" },
  { id: "submit" },
  { id: "close" },
];

export const ScheduleAppointment = ({userData, services}) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { userData, formData, setForm, navigation, services, defaultData };
  
  switch (step.id) {
    case "service select":
      return <ServiceSelect {...props} />;
    case "address":
      return <Address {...props} />;
    case "pets":
      return <Pets {...props} />;
    case "notes":
      return <Notes {...props} />;
    case "select a pro":
      return <SelectAPro {...props} />;
    case "review":
      return <Review {...props} />;
     case "submit":
       return (
       <>
       {userData && userData.test===false &&
       <Elements stripe={stripePromise}>
         <Checkout {...props} />
       </Elements>
       }
       {userData && userData.test===true &&
       <Elements stripe={stripePromiseTest}>
         <Checkout {...props} />
       </Elements>
       }
       </>);
    case "close":
      return <AppointmentTips {...props} />;
    default:
      return (
        <ServiceSelect {...props} />
        );
  }
};