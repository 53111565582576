import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, Avatar, IconButton, Modal, TextField, TextareaAutosize, Snackbar} from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import  QRCode  from 'qrcode';


function BusinessCardModal(props) {
    const {pro, handleOpenBizCard, bcModalOpen, width} = props
    const [isClientSide, setIsClientSide] = useState(true)
    const[src, setSrc] = useState()
    const[src2, setSrc2] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const setWidth = (width === 'xs' || width === 'sm') ? 306 : 408
    const setHeight = (width === 'xs' || width === 'sm') ? 534 : 712
    const subTitle = (width === 'xs' || width === 'sm') ? 16 : 20
    const callToAction = (width === 'xs' || width === 'sm') ? 13 : 16 
    const marginTop = (width === 'xs' || width === 'sm') ? '38%' : '45%'
    const qrBox = (width === 'xs' || width === 'sm') ? 250 : 350
    
    const csSubTitle = (width === 'xs' || width === 'sm') ? 11 : 14
    const csSubTitleTop = (width === 'xs' || width === 'sm') ? 95 : 130
    const csSubTitleRight = (width === 'xs' || width === 'sm') ? -22 : -30
    const csCallToAction = (width === 'xs' || width === 'sm') ? 13 : 16 
    const csCallToActionTop = (width === 'xs' || width === 'sm') ? 98 : 130 
    const csCallToActionLeft = (width === 'xs' || width === 'sm') ? 135 : 180 
    const cs = (width === 'xs' || width === 'sm') ? 40 : 50
    const csTop = (width === 'xs' || width === 'sm') ? 75 : 100
    const csRight = (width === 'xs' || width === 'sm') ? -20 : -26
    const csQrBox = (width === 'xs' || width === 'sm') ? 170 : 220
    const csQrBoxMargin = (width === 'xs' || width === 'sm') ? 20 : 30

    const proAvatar = (width === 'xs' || width === 'sm') ? 40 : 70
    const avatar = (width === 'xs' || width === 'sm') ? 30 : 50
    const nameSize = (width === 'xs' || width === 'sm') ? 20 : 25
    const titleSize = (width === 'xs' || width === 'sm') ? 10 : 13
    const numberSize = (width === 'xs' || width === 'sm') ? 18 : 22
    const webAddressSize = (width === 'xs' || width === 'sm') ? 13 : 17
    const infoWidth = (width === 'xs' || width === 'sm') ? 242 : 300
    const qrPadding = (width === 'xs' || width === 'sm') ? 8 : 7
    

    const background = isClientSide === true ? 
    'https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Downloadables%2FbusinessCards%2FBizzyBookPromoCardFrontBLANCK.png?alt=media&token=859a2889-1f6f-4e2d-8460-eb54e4babcea'
    :
    'https://firebasestorage.googleapis.com/v0/b/cuti-app-7c963.appspot.com/o/Downloadables%2FbusinessCards%2FBizzyBookPromoCardBackBLANCK.png?alt=media&token=7fbcae06-338c-46c4-9347-51b4be6ad4b6'

    useEffect(() => {
        QRCode.toDataURL(`bizzybook.app/signup`).then((data)=> {
            setSrc(data)
        }).then(() => {
            QRCode.toDataURL(`bizzybook.app/pro/profile/${pro.url}`).then((data)=> {
            setSrc2(data)
        }).then(() => {
                var cleaned = ('' + `${pro.phone}`).replace(/\D/g, '');
                var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
                if (match) {
                  var intlCode = (match[1] ? '+1 ' : '');
                  setPhoneNumber([intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(''));
                }
                return null;
              })
            })


        
    }, [])
    
    
    const handleChangeSide = () => {
        setIsClientSide(!isClientSide)
    }
    
    return (
        <Modal open={!!bcModalOpen}>
            <Grid container style={{outline:'none', height:setHeight, width:setWidth, position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', background: 'white', borderRadius: '30px'}}>
                <IconButton onClick={handleOpenBizCard} style={{position:'absolute', right:-20, top:-20, zIndex:100, backgroundColor:'white'}}><HighlightOffIcon /> </IconButton>
                <img src={background} alt="biz card" style={{objectFit:'scale-down', height:'100%', borderRadius: 30}}/>

                {!!isClientSide ?
                <Button onClick={handleChangeSide} variant='contained' color='primary' fullWidth style={{position: 'absolute', bottom:-45}}>Show Signup info</Button>
                :
                <Button onClick={handleChangeSide} variant='contained' fullWidth style={{position: 'absolute', bottom:-45}}>Show Biz info</Button>
                }

                {!!isClientSide ? 
                    <Grid item xs={12} style={{position:'absolute', height: '100%', width:'100%', display:'flex', flexFlow:'column', alignItems:'center'}}>
                        <Grid item container style={{width:'100%', height:'60%', position:'relative'}}>
                            <Grid item container style={{display:'flex', alignItems:'center', padding:'5px 0px 5px 20px', position:'absolute', top:'37%', right:'-30%', width: '100%', transform:'rotate(90deg)', height: '22%'}}>
                                
                                <Avatar src={pro.images.avatar} style={{width:proAvatar, height:proAvatar}}></Avatar>

                                <Grid item container style={{maxWidth: infoWidth, paddingLeft: 5}}>
                                    <Grid item xs={12}>
                                        <Typography style={{textShadow:'0 0 white', fontWeight:'bold', color:'#413e68', fontSize:nameSize}}>{pro.firstName+' '+pro.lastName}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{fontSize:titleSize, color:'#878787'}}>{pro.title.split(' | ').length >= 2 ? pro.title.split(' | ')[0] + " & more" : pro.title}</Typography>
                                    </Grid>
                                </Grid> 
                            </Grid>

                            <Grid item container style={{display:'flex', alignItems:'center', padding:'5px 0px 5px 30px', position:'absolute', top:'37%', right:'-10%', width: '100%', transform:'rotate(90deg)', height: '22%'}}>
                                
                                <Avatar style={{width:avatar, height:avatar}}><PhoneIcon/></Avatar>
                                
                                <Grid item container style={{maxWidth: infoWidth, paddingLeft: 5}}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontSize:numberSize}} >{phoneNumber}</Typography>
                                    </Grid>
                                </Grid> 
                            </Grid>

                            <Grid item container style={{display:'flex', alignItems:'center', padding:'5px 0px 5px 30px', position:'absolute', top:'37%', right:'10%', width: '100%', transform:'rotate(90deg)', height: '22%'}}>
                              
                                <Avatar style={{width:avatar, height:avatar}}><MailOutlineIcon/></Avatar>
                                
                                <Grid item container style={{maxWidth: infoWidth, paddingLeft: 5}}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontSize:numberSize}}>{pro.email}</Typography>
                                    </Grid>
                                </Grid> 
                            </Grid>

                            <Grid item container style={{display:'flex', alignItems:'center', padding:'5px 0px 5px 30px', position:'absolute', top:'37%', right:'30%', width: '100%', transform:'rotate(90deg)', height: '22%'}}>
                                
                                <Avatar style={{width:avatar, height:avatar}}><LanguageIcon/></Avatar>
                                
                                <Grid item container style={{maxWidth: infoWidth, paddingLeft: 5}}>
                                    
                                    <Typography style={{linebreak:'anywhere', fontSize:webAddressSize}}>www.bizzybook.app/pro/profile/</Typography>
                                    <Typography style={{linebreak:'anywhere', fontSize:webAddressSize, marginTop:-3}}>{pro.url}</Typography>
                                    
                                </Grid> 
                            </Grid>
                        </Grid>
                        <Grid item container style={{width:'100%', height:'40%', position:'relative'}}>
                            <Typography style={{position:'absolute', transform:'rotate(90deg)', fontSize:csCallToAction, top: csCallToActionTop, left:csCallToActionLeft,}}> Scan to Book with Me:</Typography>
                            <div style={{display:'flex', padding:qrPadding, width: csQrBox, height: csQrBox, backgroundColor: 'white', marginTop:csQrBoxMargin, marginLeft: csQrBoxMargin, borderRadius:25}}>
                                <img  src={src2} alt='pro profile' style={{width:'100%', height:'100%'}}/>
                            </div>

                        </Grid>

                    </Grid>                   

                :
                <Grid item xs={12} style={{position:'absolute', height:'100%', width:'100%', display:'flex', flexFlow:'column', alignItems:'center'}}>
                    <div style={{display:'flex', padding:qrPadding, width: qrBox, height: qrBox, backgroundColor: 'white', marginTop:250, borderRadius:25}}>
                    <img  src={src} alt='pro signup' style={{width:'100%', height:'100%'}}/>
                    </div>

                </Grid>
                }
            


                
            </Grid>  
        </Modal>
    )
}

BusinessCardModal.propTypes = {
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
  };
  
export default withWidth()(BusinessCardModal)
