import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import {Grid, Typography, Button, FormControl, IconButton, Modal, TextField,
        MenuItem, Select, InputLabel, Snackbar} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

function ContactUsModal({modalOpen, handleModalOpen, brandAm, designData}) {
    const {theme} = designData
    
  return (
    <Modal open={!!modalOpen} onClose={handleModalOpen}>
            <Grid container item xs={10} sm={6} md={4}
            style={{ position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '15px',  display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center', outline:'none'}}>
                <Grid item container justify='space-between' style={{backgroundColor: theme.primary, height: '50px',  borderRadius: '14px 14px 0px 0px'}}>
                    <Typography variant='h6' style={{padding: '10px', color:'#ffffff'}}> Contact Info</Typography>
                    <IconButton onClick={handleModalOpen} style={{color: 'white'}}>
                        <ClearIcon />
                    </IconButton>
                </Grid>

                <Grid item container xs={12} style={{padding:20}}>
                    <Typography style={{width:'100%'}}><strong>Email:</strong>  {brandAm.emailSupport}</Typography>
                    <Typography style={{marginTop:30, width:'100%'}}><strong>Phone:</strong> {brandAm.phone}</Typography>
                    <Typography style={{marginTop:30, width:'100%'}}><strong>Address:</strong> {brandAm.address}</Typography>
                </Grid>
        
                       


            </Grid>
            
        </Modal>
  )
}

export default ContactUsModal